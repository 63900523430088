// react
import { FC, useState, useEffect } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import CompanySettingsSection from '../../../components/general/settings/companySettingsSection/companySettingsSection'

// types
import { button } from '../../../types/general/generalTypes'
import { company, companyResponse } from '../../../types/general/settings/companySectionTypes'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setSettingsActiveCompanyId } from '../../../redux/general/settings/general'
import { setCompanies } from '../../../redux/general/general'

// network
import { authorizedRequest } from '../../../utils/queries'
import { whoAmIUrl } from '../../../utils/old_urls/general/generalUrls'

const Settings: FC = () => {
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)
	const { companies } = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const [companiesDataList, setCompaniesDataList] = useState<company[]>([])

	useEffect(() => {
		authorizedRequest(whoAmIUrl, 'GET').then((response) => {
			const companiesDataList: company[] = []
			const { result: { companies } } = response

			companies.forEach((company: companyResponse) => {
				companiesDataList.push({
					avatar: company.avatar,
					companyId: company.company_id,
					employeeId: company.employee_id,
					name: company.name,
					ownerId: company.owner_id,
					position: company.position
				})
			})

			const parsCompanies = companies.map((company: companyResponse) => {
				return {
					avatar: company.avatar,
					companyId: company.company_id,
					employeeId: company.employee_id,
					name: company.name,
					ownerId: company.owner_id,
					position: company.position
				}
			})

			dispatch(setCompanies(parsCompanies))

			setCompaniesDataList(companiesDataList)
			dispatch(setSettingsActiveCompanyId(companies[0].company_id))
		})
	}, [])


	const createTabButton = (text: string, companyId: number | null) => {
		return {
			active: companyId === settingsActiveCompanyId,
			text: text,
			onClick: () => {
				dispatch(setSettingsActiveCompanyId(companyId))
			}
		}
	}

	const settingsTabButton: button[] = companies.map(company => createTabButton(company.name, company.companyId))

	return (
		<Layout header={{
			avatar: true,
			title: 'Settings'
		}} tabButtons={settingsTabButton} >
			{settingsActiveCompanyId ? <CompanySettingsSection /> : null}
		</Layout>
	)
}

export default Settings