//styles and icons
import './chatBar.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import ChatBarCard from './chatBarCard/chatBarCard'
import ChatBarHeader from './chatBarHeader/chatBarHeader'
import CreateGroupChat from '../modals/createGroupChat/createGroupChat'
import Loading from '../../loading/loading'

//types
import { chatBarCard, chatResponse } from '../../../../types/employees/chatTypes'
import { dropdownOption } from '../../../../types/general/generalTypes'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//network
import { getChatListUrl } from '../../../../utils/old_urls/employees/chatsUrls'
import { authorizedRequest } from '../../../../utils/queries'

//other 
import { formatChat } from '../../../../assets/employees/chat/chatFunctions'

const liveChatsUpdating = async (oldChatsList: chatBarCard[], query: string, inboxOption: dropdownOption | undefined) => {
	const { result } = await authorizedRequest(getChatListUrl + `?needle=${query}&page=1&per_page=10` + (inboxOption ? `&inbox_id=${Number(inboxOption.key)}` : ''), 'GET')
	const newChatList: chatBarCard[] = []

	result.forEach((chat: chatResponse) => {
		newChatList.push(formatChat(chat))
	})

	const match = JSON.stringify(newChatList) === JSON.stringify(oldChatsList.slice(0, 10))

	if(!match){
		oldChatsList.splice(0, 10, ...newChatList)
		oldChatsList = oldChatsList.filter((chat, index, array) => array.findIndex(obj => chat.chatId !== null ? obj.chatId === chat.chatId : obj.userId === chat.userId) === index)

		return oldChatsList
	}else{
		return oldChatsList
	}
}

type chatBarProps = {
	open: boolean
}

const ChatBar: FC<chatBarProps> = ({ open }) => {
	const { chatSearchInput, activeInboxOption } = useAppSelector((state) => state.chatBar)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const [ chatsList, setChatsList ] = useState<chatBarCard[]>([])
	const [ page, setPage ] = useState(1)	
	const [ lastPage, setLastPage ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	const [ mobile, setMobile ] = useState(screen.width <= 768)

	const getChats = async (page: number, query?: string) => {
		const { result } = await authorizedRequest(getChatListUrl + `?needle=${query}&page=${page}&per_page=10` + (activeInboxOption ? `&inbox_id=${Number(activeInboxOption.key)}` : ''), 'GET')
		const chatList: chatBarCard[] = []
	
		if(result.length === 0){
			setLastPage(true)
			return result
		}
	
		result.forEach((chat: chatResponse) => {
			if(chat.last_message !== undefined)
				chatList.push(formatChat(chat))
		})
		return chatList		
	}


	useEffect(() => {
		setLoading(true)
		getChats(companyId)
			.then((chats)=>{
				setChatsList([...chatsList, ...chats])
				setLoading(false)
			})
	}, [])

	useEffect(()=>{
		const interval = setInterval(() => {
			liveChatsUpdating(chatsList, chatSearchInput, activeInboxOption)
				.then((chats)=>{
					setChatsList([...chats])
				})
			
		}, 3000)

		return () => {
			clearInterval(interval)
		}
	}, [chatsList])


	useEffect(() => {
		const searchTimer = setTimeout(() => {
			setLoading(true)
			getChats(1, chatSearchInput)
				.then((chats) => {
					setPage(1)
					setChatsList([...chats])
					setLoading(false)
				})
		}, 500)
		return () => clearTimeout(searchTimer)

	}, [chatSearchInput, activeInboxOption])

	const addNewChats = (e:React.UIEvent<HTMLDivElement>) => {
		if(!lastPage && e.currentTarget.scrollHeight - 10 <=  e.currentTarget.clientHeight + e.currentTarget.scrollTop){
			getChats(page + 1, chatSearchInput)
				.then((chats) => {
					if(chats.length > 0){
						setPage(page+1)
						setChatsList([...chatsList, ...chats])
					}
				})
		}
	}

	const handleResize = () => {
		setMobile(screen.width <= 768)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className="chat-bar-container"  style={{
			width: mobile && !open ? '0' : undefined, 
			minWidth: mobile && !open ? '0' : undefined
		}}>
			<ChatBarHeader />
			<div className="chat-bar-chats-container" onScroll = {(e)=> addNewChats(e)}>
				{
					!loading ? chatsList.map((chat: chatBarCard, index) => {
						return <ChatBarCard chatsList={chatsList} setChatsList={(value) => setChatsList(value)} {...chat} key={`chat-bar-card-${index}`}/>
					}): <div className='chat-card-wrapper'><Loading/></div>
				}
			</div>
			<CreateGroupChat />
		</div>
	)
}

export default ChatBar
