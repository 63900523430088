//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'
import {useNavigate} from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { deleteBillUrl } from '../../../../../utils/old_urls/general/generalUrls'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteBillId, setDeleteBillModalIsOpen } from '../../../../../redux/general/modals'

//types
import { bill } from '../../../../../types/finance/general'

//translaction
import { useTranslation } from 'react-i18next'

type deleteBillConfirmationModalProps = {
	bills?: bill[]
	setBills?: (value: bill[]) => void
}

const DeleteBillConfirmationModal: FC<deleteBillConfirmationModalProps> = ({bills, setBills}) => {
	const { t } = useTranslation('', {keyPrefix:'general.modals.bill.deleteBillModal'})

	const { modalIsOpen, billId } = useAppSelector((state) => state.generalModals.deleteBillModal)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const closeModal = (): void => {
		dispatch(setDeleteBillModalIsOpen(false))
		dispatch(setDeleteBillId(null))
	}

	const handleSubmit = (): void => {
		if(billId){
			setLoading(true)
			authorizedRequest(deleteBillUrl(billId), 'DELETE')
				.then((response) => {
					
					if (bills && setBills){
						setBills([...bills.filter((bill) => bill.id !== billId)])
					} else {
						navigate(-1)
					}

					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal 
			title={t('title')} 
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={modalIsOpen}
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteBillConfirmationModal