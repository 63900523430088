// react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteInventoryItemProviderOfferingId, setDeleteInventoryItemProviderOfferingModalIsOpen } from '../../../../../../redux/warehouse/inventoryItem/modals'

// translation
import { useTranslation } from 'react-i18next'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { inventoryItemProviderOfferingsUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

//types
import { offering } from '../../../../../../types/general/generalTypes'

type deleteInventoryItemProviderOfferingModalProps = {
	inventroyItemOfferings: offering[]
	setInventroyItemOfferings: (value: offering[]) => void
}

const DeleteInventoryItemProviderOfferingModal: FC<deleteInventoryItemProviderOfferingModalProps> = ({ inventroyItemOfferings, setInventroyItemOfferings }) => {
	const { modalIsOpen, offeringId } = useAppSelector(state => state.inventoryItemModal.deleteInventoryItemProviderOfferingModal)

	const dispatch = useAppDispatch()
	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryItem.inventoryItemProviderOfferingList.modals.deleteOfferingInventoryItemModal' })
	
	const [loading, setLoading] = useState<boolean>(false)
	
	const { inventoryItemId } = useParams()

	const closeModal = () => {
		dispatch(setDeleteInventoryItemProviderOfferingModalIsOpen(false))
		dispatch(setDeleteInventoryItemProviderOfferingId(null))
	}

	const deletePosition = () => {
		if (offeringId && inventoryItemId) {
			setLoading(true)
			authorizedRequest(inventoryItemProviderOfferingsUrl(Number(inventoryItemId)), 'DELETE')
				.then(response => {
					setInventroyItemOfferings(inventroyItemOfferings.filter(item => item.offeringId !== offeringId))
					setLoading(false)
					closeModal()
				})
		}
	}
	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[{ text: t('yes'), onClickHandler: deletePosition }, { text: t('no'), onClickHandler: closeModal }]}
			loading={loading}
		/>
	)
}

export default DeleteInventoryItemProviderOfferingModal