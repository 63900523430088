//styles and icons
import './layout.scss'
import { arrowIcon, basicUserAvatar, checkMark, deleteIcon } from '../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//components
import NavBar from './navBar/navBar'
import CompaniesList from '../modals/companiesList/companiesList'
import CreateCompany from '../modals/createCompany/createCompany'
import Button from '../button/button'
import Notification from '../notification/notification'
import FilterBar from './filterBar/filterBar'
import InfoPopup from '../infoPopup/infoPopup'
import SkeletonFilterBar from '../skeletons/filterbar/filterbarSkeleton'

//types
import { button, filterBar, infoPopup, infoPopupTypes, notification, notificationResponse } from '../../../types/general/generalTypes'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import {
	setBirthday,
	setEmail,
	setFirstName,
	setGender,
	setLastName,
	setPhoneNumber
} from '../../../redux/general/profile/general'
import { setUserCompanyData } from '../../../redux/general/general'

//network
import { authorizedRequest } from '../../../utils/queries'
import { whoAmIUrl, refreshUrl, updateStatusUrl } from '../../../utils/old_urls/generalUrls'
import { deleteUserNotificationUrl, markUserNotificationAsReadUrl } from '../../../utils/old_urls/profileUrls'

//other
import { blobStringToBase64, getNotificationType } from '../../../assets/general/generalFunctions'

type layoutProps = {
	children?: React.ReactNode
	header?: {
		avatar?: boolean
		title?: string
	},
	titleUrls?: titleUrl[],
	filterBar?: filterBar
	tabButtons?: button[]
	titlePopup?: infoPopup
	isFilterBarLoading?: boolean
}

type titleUrl = {
	title: string
	url: string
}

const Layout: FC<layoutProps> = ({ children, header, tabButtons, filterBar, titleUrls, titlePopup, isFilterBarLoading = false }) => {
	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const [userAvatar, setUserAvatar] = useState(localStorage.getItem('avatar') || null)
	const [notifications, setNotifications] = useState<notification[]>([])
	const [visible, setVisible] = useState<boolean>(true)

	const markUserNotification = (id: number) => {
		authorizedRequest(markUserNotificationAsReadUrl(id), 'PUT')
		const updatedNotifications = notifications.filter(notification => notification.id !== id)
		setNotifications(updatedNotifications)
	}

	const deleteUserNotification = (id: number) => {
		authorizedRequest(deleteUserNotificationUrl(id), 'DELETE')
		const updatedNotifications = notifications.filter(notification => notification.id !== id)
		setNotifications(updatedNotifications)
	}

	useEffect(() => {
		authorizedRequest(whoAmIUrl, 'GET')
			.then((responce) => {
				const { result } = responce
				if (responce === 401) {
					dispatch(setUserCompanyData(undefined))
					navigate('/login')
				}
				blobStringToBase64(result.avatar, 'image/png')
					.then((avatar) => {
						setUserAvatar(avatar)
					})
				dispatch(setFirstName(result.first_name))
				dispatch(setLastName(result.last_name ?? ''))
				dispatch(setEmail(result.email))
				dispatch(setPhoneNumber(result.phone_number))
				dispatch(setGender(result.gender))
				dispatch(setBirthday(result.birthday))
			})

	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			const accessToken = localStorage.getItem('accessToken')
			const refreshToken = localStorage.getItem('refreshToken')

			if (accessToken) {
				authorizedRequest(updateStatusUrl, 'PUT')
					.then((response) => {
						if (response.result.nots.length !== 0) {
							console.warn(response)
							const updateStatusMessages: notification[] = response.result.nots.map((item: notificationResponse) => {
								return {
									date: new Date(item.date * 1000),
									description: item.description,
									id: item.id,
									isRead: item.is_read,
									title: item.title,
									type: getNotificationType(item.type),
									userId: item.user_id,
									avatar: item.avatar,
									url: item.url
								}
							})
							setNotifications(updateStatusMessages)
							setVisible(true)
						}
						else {
							setTimeout(() => { setNotifications([]) }, 1000)
							setVisible(false)
						}
					})
			}

			if (refreshToken) {
				authorizedRequest(refreshUrl, 'GET', 'refreshToken').then((data) => {
					localStorage.setItem('accessToken', data.result.access_token)
				})
			}
		}, 5000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<div className="layout">
			{notifications.length !== 0 &&
				<div className={`notifications-container ${visible ? 'visible' : 'unvisible'}`}>

					{notifications.map(notification =>
						<Notification
							key={`notification-${notification.id}`}
							title={notification.title}
							fullWidth={false}
							type={notification.type}
							description={notification.description}
							actions={[
								{ id: 1, icon: checkMark, onClick: () => markUserNotification(notification.id) },
								{ id: 2, icon: deleteIcon, onClick: () => deleteUserNotification(notification.id) }
							]}
							date={notification.date}
							avatar={notification.avatar}
							url={notification.url}
							id={notification.id}
							userId={notification.userId}
							isRead={notification.isRead}
						/>
					)}

				</div>}
			<NavBar />

			<div className={`layout-container ${header || tabButtons ? 'layout-container-padding' : ''}`}>

				{
					header ?
						<div className="layout-header-container">
							<div className="navigation">
								<div className="navigation-buttons">
									<div className="navigation-buttons-btn"
										onClick={() => navigate(-1)}>{arrowIcon}</div>
									<div className="navigation-buttons-btn"
										onClick={() => navigate(1)}>{arrowIcon}</div>
								</div>

								<div className='layout-title-container'>
									{
										titleUrls ? titleUrls.map((titleUrl, index) =>
											<>
												<Link className='layout-title' key={`title-url-${index}`} to={titleUrl.url}>{titleUrl.title}</Link>
												{index < titleUrls.length - 1 ? <span> / </span> : null}
												<div className='layout-popup-container'>
													{index === titleUrls.length - 1 && titlePopup ? <InfoPopup type={infoPopupTypes.INFO} content={titlePopup.content.map((content) => {
														return {
															title: content.title,
															description: content.description,
															link: content.link
														}
													})} /> : null}
												</div>
											</>
										) : null
									}
								</div>

							</div>

							{
								header.avatar ?
									<Link className="header-profile-link"
										to={`/ri-business/profile/${parseInt(localStorage.getItem('userId') || '-1')}`}>
										{userAvatar ? <img src={userAvatar} alt="" /> : basicUserAvatar}
									</Link>
									: null
							}
						</div>
						: null
				}
				{
					tabButtons ?
						<div className='layout-tab-buttons-container'>
							{tabButtons.map(({ text, active, onClick }) => (
								<div className="layout-tab-button">
									<Button text={text} active={active} onClick={onClick} />
								</div>
							))}
						</div>
						: null
				}
				{
					isFilterBarLoading ? <SkeletonFilterBar /> : filterBar ? <FilterBar {...filterBar} /> : null
				}
				<div className="layout-content">
					{children ?? <></>}
				</div>
			</div>
			<CompaniesList />
			<CreateCompany />
		</div>
	)
}

export default Layout