// styles
import './myTasksCalendarWeekly.scss'

// react
import { CSSProperties, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

// types 
import { task } from '../../../../../types/general/generalTypes'

// other
import { convertTo12HourFormat } from '../../../../../assets/projects/projectsFunctions'
import {useTranslation} from 'react-i18next'

type myTasksCalendarWeeklyProps = {
    tasks: task<Date | undefined> []
    date: Date
}


const hoursCell: ReactNode[] = []
for(let i = 0; i <=24; i++) {
	hoursCell.push(
		<div key={`hour-${i+1}`} className='my-tasks-calendar-weekly-table-cell'>
			<p>{convertTo12HourFormat(i)}</p>
			<hr/>
		</div>
	)
}

const rowHeight = 2.49
const weekName = ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const formateDateToKey = (date:Date) => {
	return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
}

type weeklyTaskRenderData = {
	isStartDate:boolean,
	isEndDate:boolean,
	task:task<Date | undefined>
}
const MyTasksCalendarWeekly: FC<myTasksCalendarWeeklyProps> = ({ tasks, date }) => {
	const [weeklyTasks, setWeeklyTasks] = useState<Map<string,weeklyTaskRenderData[]>>(new Map([]))

	const [startingWeekDate, setStartingWeekDate] = useState(new Date())
	const [visibleTask, setVisibleTask] = useState<number>(-1)
	const [endingWeekDate, setEndingWeekDate] = useState(new Date())
	const {t}=useTranslation('', {keyPrefix:'general.timeSelector.days'})


	const generateWeeklyTask = () => {

		const taskWeekMap = new Map<string,weeklyTaskRenderData[]>([])

		// Calculate the range for this week
		const startingWeekDate = new Date(date)
		const endingWeekDate = new Date(date)
		endingWeekDate.setDate(startingWeekDate.getDate() + 7)

		// Initialize date map
		for(let i = 0; i < 7; i++) {
			const targetDate = new Date(startingWeekDate)
			targetDate.setDate(targetDate.getDate() + i)
			taskWeekMap.set(formateDateToKey(targetDate),[])
		}

		// Reset the time
		startingWeekDate.setHours(0,0,0,0)
		endingWeekDate.setHours(0,0,0,0)

		setStartingWeekDate(startingWeekDate)
		setEndingWeekDate(endingWeekDate)


		// Filter and place each task to each day
		tasks.forEach((task) => {
			if(!(task.startDate && task.endDate)) return 			

			// Remove the task that isn't this week
			if(task.startDate >= endingWeekDate || task.endDate <= startingWeekDate) return

			// Clamp the date to this week only
			const clampEndDate = task.endDate >= endingWeekDate 
			const clampStartDate = task.startDate <= startingWeekDate

			const visibleStart = new Date(clampStartDate ? startingWeekDate : task.startDate)
			const visibleEnd = new Date(clampEndDate ? endingWeekDate : task.endDate)

			visibleStart.setHours(0,0,0,0)
			if(clampEndDate){
				visibleEnd.setDate(visibleEnd.getDate() - 1)
			}

			// Place the task on each day the task is still active
			while(visibleStart <= visibleEnd) {
				const dateKey = formateDateToKey(visibleStart)
				const nextDateKey = new Date(visibleStart)
				nextDateKey.setDate(nextDateKey.getDate()+1)

				if(taskWeekMap.has(dateKey)){
					const data = {
						isStartDate:formateDateToKey(visibleStart) === formateDateToKey(task.startDate),
						isEndDate:nextDateKey >= task.endDate,
						task:task,
					}
					console.log(data)
					taskWeekMap.get(dateKey)?.push(data)
				} 

				visibleStart.setDate(visibleStart.getDate() + 1)
			}

		})

		setWeeklyTasks(taskWeekMap)
		console.table(taskWeekMap)

	}

	useEffect(() => {
		generateWeeklyTask()
	}, [date])


	const formatTasks = useCallback((tasks:weeklyTaskRenderData[],dayNumber:number) => {
		return tasks.map((taskRenderData,index) => {
			const task = taskRenderData.task
			let duration = 4
			let startingHours = 0
			let endingHours = 24

			if(task.startDate && task.endDate ){
				const endDate = new Date(task.endDate)
				endDate.setDate(endDate.getDate() - 1)

				if(taskRenderData.isStartDate) startingHours =  task.startDate.getHours() + (task.startDate.getMinutes() / 60) 
				if(taskRenderData.isEndDate) endingHours = task.endDate.getHours() + (task.endDate.getMinutes() / 60) 

				duration = 23 - startingHours
				duration = Math.max(endingHours - startingHours ,1)
			} 

			const offsetTop = (((startingHours) * rowHeight) + .35)
			const taskHeight = (duration) * rowHeight

			// const isVisibleTask = visibleTasks[dayNumber] === task.id
			const isVisibleTask = visibleTask === task.id
			const isLastRenderedTask = index === tasks.length - 1
			const hasMoreTask =  tasks.length > 3

			const toRender = (
				<>
					<div
						className={`day-task ${isVisibleTask ? 'visible' : ''}`} 
						style={{top: `${offsetTop}vw`, height:`${taskHeight}vw`, '--taskColor':task.status.color} as CSSProperties} 
						key={`day-${dayNumber}-task-${index}`}
						onClick={(e) => {
							e.stopPropagation()
							setVisibleTask((visibleTask) => {
								return visibleTask === task.id ? -1 : task.id
							})
						}} 
					>

						<div className="day-task-header">
							<p>{task.title}</p>
						</div>
						<div className="day-task-body">
							<h2 className='task-title'>{task.title}</h2>
							<p>{task.description}</p>
						</div>

					</div>
					<>
						{
							isLastRenderedTask && hasMoreTask && (
								<div className="extra-task-indicator" >
									<p style={{top:`${offsetTop + taskHeight + 5}vw`}}>+{tasks.length - 3} More Task</p>
								</div>
							)
						}
					</>
				</>
			)

			const taskElement = {
				startingHours:startingHours + endingHours,
				element:toRender
			}

			return taskElement
		}).map((task)=>task.element)
	},[visibleTask])

	const dateList = useMemo(() => { 
		const list:string[] = [] 
		weeklyTasks.forEach((_,key)=>{ list.push(key) }) 
		return list 
	}, [weeklyTasks])

	return (
		<div className='my-tasks-calendar-weekly'>
			<hr/>
			<div className="my-tasks-calendar-weekly-table">
				<div className='my-tasks-calendar-weekly-table-container'>
					<div className='my-tasks-calendar-weekly-tasks'>
						{dateList.map((dayId,dayNumber) => {
							const tasks = weeklyTasks.get(dayId) ?? []
							const hasSelected = tasks.findIndex((task)=>task.task.id === visibleTask) !== -1

							const renderedTask = [...tasks].slice(0,3)

							const rowDate = new Date(startingWeekDate)
							rowDate.setDate(startingWeekDate.getDate() + dayNumber)

							return (
								<div className='days-row' key={dayId} onClick={() => setVisibleTask(-1)}>
									<div className="days-indicator">
										<h2>{rowDate.getDate()} <span className='day-name'> {t(weekName[rowDate.getDay()].toLowerCase())}</span></h2>
									</div>
									<div className={`day-tasks ${hasSelected ? 'selected':''}`}>
										{formatTasks(renderedTask,dayNumber)}
									</div>
								</div>
							)
						})}
					</div>
					{hoursCell}
				</div>
			</div>
			<hr/>
		</div>
	)
}

export default MyTasksCalendarWeekly