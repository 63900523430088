// styles and icons
import './listSummaryCard.scss'
import { multiplePipelinesIcon } from '../../../assets/projects/pipeline/pipelineIcons'

// react
import { FC } from 'react'

//translaction
import { useTranslation } from 'react-i18next'

type listSummaryCardProps = {
	title: string
	data: {
		title: string
		text: string
	}[]
}
const ListSummaryCard : FC<listSummaryCardProps> = ({title, data}) => {
	const { t } = useTranslation('', {keyPrefix:'general.listSummaryCard'})

	return (
		<div className='list-summary-card'>
			<div className='list-summary-card-title'>
				{multiplePipelinesIcon}
				<p>{t('summary')}</p>
			</div>
			<div className='list-summary-card-content'>
				<div className='list-summary-card-content-title'><p>{title}</p></div>
				<div className='list-summary-card-content-data'>
					{
						data.map((dataElem) => {
							return (
								<div className='list-summary-card-content-data-item'>
									<p className='title'>{dataElem.title}</p>
									<p className='text'>{dataElem.text}</p>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default ListSummaryCard