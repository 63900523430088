//import
import './taskDetails.scss'

//react
import { useState, useEffect, FC } from 'react'
import { useParams } from 'react-router-dom'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Button from '../../../general/button/button'
import CloseTaskModal from '../../../general/modals/task/closeTaskModal/closeTaskModal'
import DeleteTaskModal from '../../../general/modals/task/deleteTaskModal/deleteTaskModal'
import Loading from '../../../general/loading/loading'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteTaskModalIsOpen, setDeleteTaskId, setCloseTaskId, setCloseTaskModalIsOpen } from '../../../../redux/projects/task/modals'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { getLabelsUrl } from '../../../../utils/old_urls/general/generalUrls'
import { companySearchEmployeesUrl } from '../../../../utils/urls/employees/search'
import { singleTaskUrl } from '../../../../utils/urls/projects/task'
import { companyProjectsUrl } from '../../../../utils/urls/projects/project'
import { getSkillsUrl } from '../../../../utils/urls/general/positions'
import { createLabelUrl } from '../../../../utils/urls/general/labels/labels'

//translation
import { useTranslation } from 'react-i18next'

// types
import { dropdownOption, infoDetailsTableDataItem, taskResponse, selectionOption, labelResponse, status, employeeResponse, skillResponse, error } from '../../../../types/general/generalTypes'

//other
import { createDropdownOption, formatDate, formatErrorResponse, translateDate } from '../../../../assets/general/generalFunctions'

type taskDetails = {
	title: string
	description: string
	executorsSearchRequest: string
	selectedExecutors: selectionOption[]
	labelsSearchRequest: string
	selectedLabels: selectionOption[]
	skillsSearchRequest: string
	selectedSkills: selectionOption[]
	statusId: number
	status: string
	selectedProject: dropdownOption
	startDate: Date | undefined
	dueDate: Date | undefined
	editAccess: boolean
	closeAccess: boolean
	deleteAccess: boolean
	isClosed: boolean
}

type taskDetailsProps = {
	setTitle: (value: string) => void
	setProjectId: (value: number) => void
	setErrors: (value: error[]) => void
	setProjectName: (value: string) => void
	setPipelineId: (value: number) => void
	setPipelineName: (value: string) => void
}

const TaskDetails: FC<taskDetailsProps> = ({ setTitle, setProjectId, setErrors, setProjectName, setPipelineId, setPipelineName }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.task.taskDetails' })
	const tStatus = useTranslation('', { keyPrefix: 'general.status' }).t
	const tSkill = useTranslation('', { keyPrefix: 'general.skills' }).t

	const [details, setDetails] = useState<taskDetails>()
	const [infoDetailsTable, setInfoDetailsTable] = useState<infoDetailsTableDataItem[]>([])

	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [lastOpen, setLastOpen] = useState(false)

	const { userCompanyData } = useAppSelector((state) => state.general)

	const [loading, setLoading] = useState<boolean>(false)

	const companyId: number = userCompanyData?.companyId || -1
	const taskId = parseInt(useParams().taskId || '-1')

	const dispatch = useAppDispatch()

	const getStatusId = (activeStatus: string) => {
		const index = Object.values(status).findIndex((key) => key === activeStatus)
		return index
	}

	const getEmployees = (query: string, page: number) => {
		return authorizedRequest(companySearchEmployeesUrl(companyId) + `?needle=${query}&page=${page}&per_page=5`, 'GET')
			.then((response) => {
				const { result }: { result: employeeResponse[] } = response

				const employeesOptionList: selectionOption[] = result.map(employee => {
					return {
						id: employee.employee_id,
						text: employee.name,
						avatar: employee.avatar
					}
				})

				return employeesOptionList

			})
	}

	const getLabels = (query: string, page: number) => {
		return authorizedRequest(createLabelUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				console.log(response)
				const { result }: { result: labelResponse[] } = response

				const labelsOptionList: selectionOption[] = result.map(label => {
					return {
						id: label.id,
						text: label.text,
						bg: label.color,
						color: '#fff'
					}
				})

				return labelsOptionList
			})
	}

	const getSkills = (query: string, page: number) => {
		return authorizedRequest(getSkillsUrl() + `?needle=${query}&page=${page}`, 'GET')
			.then((response) => {
				const { result }: { result: skillResponse[] } = response
				const skillsOptionList: selectionOption[] = result.map(skill => {
					return {
						id: skill.id,
						text: tSkill(skill.name),
					}
				})

				return skillsOptionList

			})
	}

	const getProjects = (query: string, page: number) => {
		return authorizedRequest(companyProjectsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				const { result } = response
				console.log(result)

				return result.map((project: { title: string, project_id: number }) => {
					return {
						title: project.title,
						key: `${project.project_id}`
					}
				})

			})
	}

	useEffect(() => {
		if (details) {
			setInfoDetailsTable([
				{
					title: t('title'),
					data: {
						inputField: {
							type: 'text',
							value: details.title,
							onChange: (e) => {
								setDetails({ ...details, title: e.target.value })
								setEditDetails(true)
							}
						}
					}

				},
				{
					title: t('description'),
					data: {
						textArea: {
							value: details.description,
							setValue: (value) => {
								setDetails({ ...details, description: value })
								setEditDetails(details.description!==value)
							}
						}
					}
				},
				{
					title: t('executors'),
					data: {
						inputField: {
							type: 'text',
							value: details.executorsSearchRequest,
							onChange: (e) => setDetails({ ...details, executorsSearchRequest: e.target.value }),
							selection: {
								loadSelectionOptions: getEmployees,
								selectedList: details.selectedExecutors,
								setSelectedList: (options) => {
									setDetails({ ...details, selectedExecutors: options })
									setEditDetails(JSON.stringify(details.selectedExecutors)!==JSON.stringify(options))
								}
							},
							disabled: !details.editAccess
						}
					}
				},
				{
					title: t('lables'),
					data: {
						inputField: {
							type: 'text',
							value: details.labelsSearchRequest,
							onChange: (e) => setDetails({ ...details, labelsSearchRequest: e.target.value }),
							selection: {
								loadSelectionOptions: getLabels,
								selectedList: details.selectedLabels.map((selectedLabel) => {
									return {
										...selectedLabel,
										bg: selectedLabel.bg,
										color: '#fff',
									}
								}),
								setSelectedList: (options) => {
									setDetails({ ...details, selectedLabels: options })
									setEditDetails(JSON.stringify(details.selectedLabels)!==JSON.stringify(options))
								}
							},
							disabled: !details.editAccess
						}
					}
				},
				{
					title: t('skills'),
					data: {
						inputField: {
							type: 'text',
							value: details.skillsSearchRequest,
							onChange: (e) => setDetails({ ...details, skillsSearchRequest: e.target.value }),
							selection: {
								loadSelectionOptions: getSkills,
								selectedList: details.selectedSkills.map((selectedSkill) => {
									return {
										...selectedSkill,
										text: tSkill(selectedSkill.text)
									}
								}),
								setSelectedList: (options) => {
									setDetails({ ...details, selectedSkills: options })
									setEditDetails(JSON.stringify(details.selectedSkills)!==JSON.stringify(options))
								}
							},
							disabled: !details.editAccess
						}
					}
				},
				{
					title: t('project'),
					data: {
						dropdown: {
							placeholder: t('project'),
							dropdownOptions: [],
							selectedOption: details.selectedProject,
							onSelect: (option) => {
								setDetails({ ...details, selectedProject: option })
								setEditDetails(details.selectedProject.key!==option.key)
							},
							loadOptions: getProjects,
							disabled: !details.editAccess
						}
					}
				},
				{
					title: t('status'),
					data: {
						dropdown: {
							dropdownOptions: createDropdownOption(tStatus, status),
							onSelect: (value) => {
								setDetails({ ...details, statusId: getStatusId(value.key), status: value.key })
								setEditDetails(details.status!==value.key)
							},
							disabled: !details.editAccess,
							selectedOption: {
								title: tStatus(Object.values(status)[details.statusId]),
								key: Object.values(status)[details.statusId]
							},
							placeholder: tStatus(Object.values(status)[details.statusId])
						}
					}
				},
				{
					title: t('startDate'),
					data: {
						calendar: {
							startDate: {
								date: details.startDate,
								setDate: (value: Date | undefined) => {
									setDetails({ ...details, startDate: value })
									setEditDetails(details.startDate?.toISOString()!==value?.toISOString())
								}
							},
							disabled: !details.editAccess,
							popup: true,
							hasTime: true
						}
					}
				},
				{
					title: t('endDate'),
					data: {
						calendar: {
							dueDate: {
								date: details.dueDate,
								setDate: (value: Date | undefined) => {
									setDetails({ ...details, dueDate: value })
									console.log(details.dueDate?.toISOString(), value?.toISOString())
									setEditDetails(details.dueDate?.toISOString() !== value?.toISOString())
								}
							},
							disabled: !details.editAccess,
							popup: true,
							hasTime: true
						}
					}
				}
			])
		}
	}, [details])


	const loadData = async () => {
		try {
			setLoading(true)
			const { result }: { result: taskResponse } = await authorizedRequest(singleTaskUrl(Number(taskId)), 'GET')

			setTitle(result.title)
			setProjectId(result.project?.id)

			setPipelineId(result.pipeline_id)
			setPipelineName(result.pipeline_name)

			setErrors(result.errors.map(formatErrorResponse))
			setProjectName(result.project?.title)

			setLastOpen(result.last_open)

			setDetails({
				title: result.title,
				description: result.description,
				executorsSearchRequest: '',
				selectedExecutors: result.task_executors.map((executor) => {
					return {
						text: executor.name,
						avatar: executor.avatar,
						id: executor.employee_id
					}
				}),
				labelsSearchRequest: '',
				selectedLabels: result.task_labels.map((label) => {
					return {
						color: label.color,
						text: label.text,
						id: label.id
					}
				}),
				skillsSearchRequest: '',
				selectedSkills: result.skills.map((skill) => {
					return {
						text: skill.name,
						id: skill.id
					}
				}),
				selectedProject: {
					title: result.project?.title,
					key: `${result.project?.id}`
				},
				statusId: getStatusId(result.status),
				status: result.status,
				startDate: result.date ? translateDate(new Date(result.date * 1000)) : undefined,
				dueDate: result.due_date ? translateDate(new Date(result.due_date * 1000)) : undefined,
				editAccess: result.edit_access,
				closeAccess: result.close_access,
				deleteAccess: result.delete_access,
				isClosed: result.is_closed
			})
		} finally {
			setLoading(false)
		}
	}

	const editData = async () => {
		if (details) {
			await authorizedRequest(singleTaskUrl(Number(taskId)), 'PUT', 'accessToken', {
				task_id: taskId,
				project_id: parseInt(details.selectedProject.key),
				title: details.title,
				description: details.description,
				executor_ids: details.selectedExecutors.map(({ id }) => id),
				label_ids: details.selectedLabels.map(({ id }) => id),
				date: details.startDate,
				due_date: details.dueDate,
				skill_ids: details.selectedSkills.map(({ id }) => id),
				status: details.status
			})
		}

		setEditDetails(false)
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (editDetails) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<div className="task-details">
			{
				details ? <>
					<InfoDetailsTable data={infoDetailsTable} />
					{
						details.deleteAccess || details.closeAccess ?
							<div className="task-details-actions">
								{
									details.deleteAccess ?
										<Button text={t('delete')} active={true} onClick={() => {
											dispatch(setDeleteTaskModalIsOpen(true))
											dispatch(setDeleteTaskId(taskId))
										}} />
										: null
								}

								{
									details.closeAccess && !details.isClosed ?
										<Button text={t('close')} active={true} onClick={() => {
											dispatch(setCloseTaskModalIsOpen(true))
											dispatch(setCloseTaskId(taskId))
										}} />
										: null
								}
							</div>
							: null
					}
				</> : <Loading style={{ top: '40vh', left: '50vw' }} />
			}
			<CloseTaskModal lastOpen={lastOpen} />
			<DeleteTaskModal />
		</div>
	)
}

export default TaskDetails