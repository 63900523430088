import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

import { file } from '../../../types/general/generalTypes'

type financeGeneralModalState = {
    createTransactionModal: {
        modalIsOpen: boolean
    }
    deleteTransactionModal: {
        modalIsOpen: boolean
        transactionId: number | null
    }
    importTransactionFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    transactionTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportTransactionFileModal: {
        modalIsOpen: boolean
    }
}

const initialState: financeGeneralModalState = {
	createTransactionModal: {
		modalIsOpen: false
	},
	deleteTransactionModal: {
		modalIsOpen: false,
		transactionId: null
	},
	importTransactionFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	transactionTablePreviewModal: {
		modalIsOpen: false
	},
	exportTransactionFileModal: {
		modalIsOpen: false
	}
}

export const hierarchyGeneralSlice = createSlice({
	name: 'hierarchyGeneralReducer',
	initialState,
	reducers : {
		setCreateTransactionModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createTransactionModal.modalIsOpen = action.payload
		},
		setDeleteTransactionModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteTransactionModal.modalIsOpen = action.payload
		},
		setDeleteTransactionId: ( state, action: PayloadAction<number | null>) => {
			state.deleteTransactionModal.transactionId = action.payload
		},
		setImportTransactionFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importTransactionFileSelectorModal.modalIsOpen = action.payload
		},
		setImportTransactionFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importTransactionFileSelectorModal.file = action.payload
		},
		setTransactionTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.transactionTablePreviewModal.modalIsOpen = action.payload
		},
		setExportTransactionFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportTransactionFileModal.modalIsOpen = action.payload
		}
	}
})

export const {
	setCreateTransactionModalIsOpen,
	setDeleteTransactionModalIsOpen,
	setDeleteTransactionId,
	setImportTransactionFileSelectorModalIsOpen,
	setImportTransactionFileSelectorModalFile,
	setTransactionTablePreviewModalIsOpen,
	setExportTransactionFileModalIsOpen
} = hierarchyGeneralSlice.actions

export default hierarchyGeneralSlice.reducer