//react
import { FC, useState } from 'react'


//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// types
import { document } from '../../../../../../types/general/generalTypes'

//redux
import { setDeleteProviderDocumentId, setDeleteProviderDocumentModalIsOpen } from '../../../../../../redux/purchase/provider/modals'
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderDocumentUrl } from '../../../../../../utils/urls/purchases/provider'

//translation
import { useTranslation } from 'react-i18next'

type deleteProviderDocumentConfirmationModalProps = {
	setDocumentsData: (value: document[]) => void
	documentsData: document[]
}

const DeleteProviderDocumentConfirmationModal: FC<deleteProviderDocumentConfirmationModalProps> = ({ documentsData, setDocumentsData }) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerDocuments.modals.deleteProviderDocumentConfirmationModal' })

	const { deleteProviderDocumentModal } = useAppSelector((state) => state.providerModal.providerDocuments)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteProviderDocumentModalIsOpen(false))
		dispatch(setDeleteProviderDocumentId(null))
	}

	const delDocument = (): void => {
		setLoading(true)
		authorizedRequest(singleProviderDocumentUrl(deleteProviderDocumentModal.documentId!), 'DELETE')
			.then((response) => {
				console.log(response)
				const updatedDocumentsData = documentsData.filter(document => document.id !== deleteProviderDocumentModal.documentId)
				setDocumentsData(updatedDocumentsData)
				setLoading(false)
				dispatch(setDeleteProviderDocumentModalIsOpen(false))
			})
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisDocument')}
			buttons={[{ text: t('yes'), onClickHandler: delDocument }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={deleteProviderDocumentModal.modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteProviderDocumentConfirmationModal