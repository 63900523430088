//components
import Tree from '../tree/tree'
import TaskTrackingNode from './taskTrackingNode/taskTrackingNode'

//react
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//network
import { formatTaskResponse, formatTaskTreeResponse } from '../../../assets/projects/projectsFunctions'
import { authorizedRequest } from '../../../utils/queries'
import { singleTaskUrl } from '../../../utils/urls/projects/task'

//types
import { dashboardCard, status, statusColor, task, taskResponse, treeElement } from '../../../types/general/generalTypes'
import DashboardCard from '../dashboard/dashboardStage/dashboardCard/dashboardCard'

// const dummyData: task<Date | undefined> = {
// 	id: 1,
// 	title: 'somedsfmdsjgoisdjgoidsjgoidsjgoidsgoisomedsfmdsjgoisdjgoidsjgoidsjgoidsgoisomedsfmdsjgoisdjgoidsjgoidsjgoidsgoi',
// 	description: 'sdgknfdnfiodngoifdngd',
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	executors: [],
// 	subtasks: [],
// 	status: {
// 		color: statusColor.accepted,
// 		text: status.accepted
// 	},
// 	labels: []
// }

type subtaskArray = taskTrackingNodeProps[]

type taskTrackingNodeProps = {
	id: number,
	element: JSX.Element,
	subElements: taskTrackingNodeProps[] | [],
}


const TaskTracking = () => {

	const [trackingElements, setTrackingElements] = useState<treeElement>()

	const { taskId } = useParams()
	const navigate = useNavigate()

	const getTrackingData = async () => {
		const response = await authorizedRequest(singleTaskUrl(Number(taskId)), 'GET')
		const { result } = response

		const taskTree = (): taskTrackingNodeProps => {
			const firstElement = { ...formatTaskResponse(result), errors: result.errors }
			return {
				id: firstElement.id,
				element: <DashboardCard {...firstElement} onClick={() => { () => navigate(`/ri-business/projects/task/${firstElement.id}`) }} />,
				subElements: generateSubtaskNodes(firstElement)
			}
		}

		const generateSubtaskNodes = (treeElement: task<Date | undefined>): subtaskArray => {
			const subtaskArr = treeElement.subtasks.map((subtask: task<Date | undefined>) => {
				return {
					id: subtask.id,
					element: <DashboardCard {...subtask} onClick={() => { () => navigate(`/ri-business/projects/task/${subtask.id}`) }} />,
					subElements: generateSubtaskNodes(subtask)
				}
			})

			return subtaskArr
		}

		setTrackingElements(taskTree())
	}

	useEffect(() => {
		getTrackingData()
	}, [])

	return (
		trackingElements ? <Tree treeElements={trackingElements} minZoom={0.3} maxZoom={4} controls={true} /> : null
	)
}

export default TaskTracking