import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type filePreviewModalsState = {
	filePreview: {
		modalIsOpen: boolean
		fileUrl?: string
	}
}

const initialState: filePreviewModalsState = {
	filePreview: {
		modalIsOpen: false
	}
}

export const filePreviewModalsSlice = createSlice({
	name: 'filePreviewModalsReducer',
	initialState,
	reducers: {
		setFilePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.filePreview.modalIsOpen = action.payload
		},
		setFilePreviewImg: (state, action: PayloadAction<string>) => {
			state.filePreview.fileUrl = action.payload
		}
	}
})

export const { setFilePreviewModalIsOpen, setFilePreviewImg } = filePreviewModalsSlice.actions

export default filePreviewModalsSlice.reducer