// styles
import './fileExportModal.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import InputField from '../../inputField/inputField'
import Modal from '../modal/modal'
import Dropdown from '../../dropdown/dropdown'
import FormError from '../../formError/formError'

//other
import { base64ToBlob } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'

type fileExportModalProps = {
	open: boolean
	closeModal: () => void
	exportFileTypes: { title: string, mime: string }[]
	getFileBlobString: (exportType: string) => Promise<string>
}

const FileExportModal: FC<fileExportModalProps> = ({ open, closeModal, exportFileTypes, getFileBlobString }) => {
	const [error, setError] = useState('')
	const [fileName, setFileName] = useState('')
	const [selectedExtension, setSelectedExtension] = useState<{ key: string, title: string }>()

	const { t } = useTranslation('', { keyPrefix: 'general.modals.fileExportModal' })

	const handleSubmit = async () => {

		if (selectedExtension === undefined || fileName === '') {
			setError(t('fillUpTheFieldsPlease'))
			return
		}

		const blobString = await getFileBlobString(selectedExtension?.title || '')

		const blob = base64ToBlob('base64,' + blobString, selectedExtension?.key || '')

		const url = window.URL.createObjectURL(blob)

		const downloadLink = document.createElement('a')
		downloadLink.download = fileName.slice(0, fileName.lastIndexOf('.'))
		downloadLink.innerHTML = 'Download File'
		downloadLink.download

		downloadLink.href = url

		document.body.appendChild(downloadLink)
		downloadLink.click()

		document.body.removeChild(downloadLink)
		window.URL.revokeObjectURL(url)
		closeModal()
	}

	const resetValues = () => {
		setError('')
		setFileName('')
		setSelectedExtension(undefined)
		closeModal()
	}

	useEffect(() => {
		setError('')
	}, [fileName, selectedExtension])

	return (
		<Modal
			open={open}
			title={t('title')}
			closeModal={resetValues}
			submitButton={{
				text: t('submitText'),
				onClick: handleSubmit
			}}
		>
			<div className="file-export-container">
				<FormError appear={error.length > 0} errorText={error} />
				<InputField name='file-name' label={t('fileName')} type='text' value={fileName} onChange={(e) => setFileName(e.target.value)} placeholder={t('fileName')} />
				<div className="file-export-field">
					<label htmlFor="file-name">{t('fileType')} </label>
					<Dropdown placeholder={t('fileType')} selectedOption={selectedExtension} dropdownOptions={exportFileTypes.map((fileType) => { return { key: fileType.mime, title: fileType.title } })} onSelect={(e) => setSelectedExtension({ key: e.key, title: e.title })}></Dropdown>
				</div>
			</div>
		</Modal>
	)
}

export default FileExportModal