// images
import employeeDetails1En from '../../../../assets/manual/employee/en/employeeDetails.png'
import employeeDetails2En from '../../../../assets/manual/employee/en/employeeDetails2.png'
import employeeDetails1Ua from '../../../../assets/manual/employee/ua/employeeDetails.png'
import employeeDetails2Ua from '../../../../assets/manual/employee/ua/employeeDetails2.png'
import employeeDetailsFile1En from '../../../../assets/manual/employee/en/employeeFile.png'
import employeeDetailsFile2En from '../../../../assets/manual/employee/en/employeeUploadFile.png'
import employeeDetailsFile1Ua from '../../../../assets/manual/employee/ua/employeeFile.png'
import employeeDetailsFile2Ua from '../../../../assets/manual/employee/ua/employeeUploadFile.png'
import employeeDetailsProject1En from '../../../../assets/manual/employee/en/employeeProject.png'
import employeeDetailsProject1Ua from '../../../../assets/manual/employee/ua/employeeProject.png'
import employeeDetailsTasksEn from '../../../../assets/manual/employee/en/employeeTask.png'
import employeeDetailsTasksUa from '../../../../assets/manual/employee/ua/employeeTask.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const SearchEmployeeManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.employees.searchEmployee' })
	const { language } = useAppSelector(state => state.general)
	
	const data: manualData[] = [
		{
			title: t('employeeDetailsTitle'),
			text: t('employeeDetailsText'),
			// images: language === 'en' ? [employeeDetails1En, employeeDetails2En] : [employeeDetails1Ua, employeeDetails2Ua]
		},
		{
			title: t('employeeDetailsFileTitle'),
			text: t('employeeDetailsFileText'),
			// images: language === 'en' ? [employeeDetailsFile1En, employeeDetailsFile2En] : [employeeDetailsFile1Ua, employeeDetailsFile2Ua]
		},
		{
			title: t('employeeDetailsProjectsTitle'),
			text: t('employeeDetailsProjectsText'),
			// images: language === 'en' ? [employeeDetailsProject1En] : [employeeDetailsProject1Ua]
		},
		{
			title: t('employeeDetailsTasksTitle'),
			text: t('employeeDetailsTasksText'),
			// images: language === 'en' ? [employeeDetailsTasksEn] : [employeeDetailsTasksUa]
		}
	]
	
	return (
		<ManualLayout 
			heading={t('employeeDetailsTitle')}
			data={data}
		/>
	)
}

export default SearchEmployeeManual
