//styles
import './createProviderAddressModal.scss'

//components
import Modal from '../../../../../general/modals/modal/modal'
import LocationSelector from '../../../../../general/locationSelector/locationSelector'

//react
import { useState, FC } from 'react'
import { useParams } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderAddressesUrl } from '../../../../../../utils/urls/purchases/provider'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setCreateProviderAddressModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

//types
import { address, addressResponse } from '../../../../../../types/sales/customerTypes'

//translation
import { useTranslation } from 'react-i18next'

type formErrors =  {
	location?: string
}

type createProviderAddressModalProps = {
	addresses: address[]
	setAddresses: (value: address[]) => void
}

const CreateProviderAddressModal: FC<createProviderAddressModalProps> = ({ addresses, setAddresses }) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerAddressList.modals.createWarehouseModal' })

	const [ location, setLocation ] = useState<{lat: number, lng: number}>()
	const [ address, setAddress ] = useState('')

	const [ errors, setErrors ] = useState<formErrors>({})

	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerAddresses.createProviderAddressModal)

	const { relationshipId } = useParams()

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateProviderAddressModalIsOpen(false))
		setAddress('')
		setLocation(undefined)
	}

	const checkErrors = () => {
		let result = true

		if(location === undefined){
			setErrors(prevErrors => ({...prevErrors, location: t('pleaseSelectTheLocation')}))
			result = false
		}
		return result
	}

	const createWarehouse = () => {
		// create the function
		if(checkErrors() && relationshipId && location){

			authorizedRequest(singleProviderAddressesUrl(parseInt(relationshipId)), 'POST', 'accessToken', {
				latitude: location.lat,
				longitude: location.lng,
				address: address
			})
				.then((response) => {
					const result: addressResponse = response.result

					setAddresses([result, ...addresses])

					closeModal()
				})
		}
	}

	return (
		<Modal
			title={t('createAddress')}
			open={modalIsOpen}
			closeModal={closeModal}
			submitButton={{text: t('create'), onClick: createWarehouse}}
		>
			<div className="create-address">
				<LocationSelector setAddress={setAddress} location={location} setLocation={setLocation} error={errors.location}/>
			</div>
		</Modal>
	)
}

export default CreateProviderAddressModal