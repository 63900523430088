// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportProviderFileSelectorModalFile, setImportProviderFileSelectorModalIsOpen, setProviderTablePreviewModalIsOpen } from '../../../../../redux/purchase/providersList/modals'




const ImportProviderFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.providerListModal.importProviderFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportProviderFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportProviderFileSelectorModalFile(files[0]))
		dispatch(setImportProviderFileSelectorModalIsOpen(false))
		dispatch(setProviderTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}			
		/>
	)
}

export default ImportProviderFileSelectorModal