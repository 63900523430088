//components
import Layout from '../../../components/general/layout/layout'
import ShoppingEfficiency from '../../../components/purchase/purchaseStats/shoppingEfficiency/shoppingEfficiency'
import SupplierAndVendorManagement from '../../../components/purchase/purchaseStats/supplierAndVendorManagement/supplierAndVendorManagement'

//react
import { useState } from 'react'

//translation
import { useTranslation } from 'react-i18next'

//types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

enum purchaseStatsSection {
	ShoppingEfficiency='Shopping Efficiency',
	SupplierAndVendorManagement='Supplier And Vendor Management',
}


const PurchaseStats = () => {

	const [activePurchaseStatsSection, setActivePurchaseStatsSection] = useState(purchaseStatsSection.ShoppingEfficiency)

	const { t } = useTranslation('', { keyPrefix: 'purchase.purchaseStats'})

	const purchaseStatsButtons: button[] = [
		{
			active: activePurchaseStatsSection === purchaseStatsSection.ShoppingEfficiency,
			text: t('shoppingEfficiency'),
			onClick: () => {setActivePurchaseStatsSection(purchaseStatsSection.ShoppingEfficiency)}
		},
		{
			active: activePurchaseStatsSection === purchaseStatsSection.SupplierAndVendorManagement,
			text: t('supplierAndVendorManagement'),
			onClick: () => {setActivePurchaseStatsSection(purchaseStatsSection.SupplierAndVendorManagement)}
		}
	]

	const titleUrls = [
		{
			url: '/ri-business/purchase/statistics',
			title: t('purchaseStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'purchase_stats_info_title',
			description: 'purchase_stats_info_description',
			link: '/ri-manual/purchases'
		}],
		type: infoPopupTypes.INFO
	}

	const renderTab = () => {
		switch(activePurchaseStatsSection) {
		case purchaseStatsSection.ShoppingEfficiency:
			return <ShoppingEfficiency/>
		case purchaseStatsSection.SupplierAndVendorManagement:
			return <SupplierAndVendorManagement />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Purchase Statiscts'
			}}
			tabButtons={purchaseStatsButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			{renderTab()}
		</Layout>
	)
}

export default PurchaseStats