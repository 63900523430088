
// react
import React, { CSSProperties, FC, ReactNode, useState } from 'react'

// context
import useDragAndDrop from '../context/dragAndDropHandlerContext'

// types
import { dragLocation, onDropSuccess } from '../../../../types/general/generalTypes'

type dropAreaProps =  {
	stageId: number
	stage:string
	index?:number 
	shouldCapture?:boolean
	onDropSuccess:onDropSuccess
	children:ReactNode
	className:string
	style?:CSSProperties
}

const DropArea:FC<dropAreaProps> = ({stageId, stage, index,shouldCapture,onDropSuccess,children,className,style}) => {
	
	const { dragData,dragInfo,setDragInfo,setDropInfo, } = useDragAndDrop()
	const [isDragOver,setIsDragOver] = useState(false)
	return (
		<div 
			className={className + ` ${isDragOver ? 'drag-over' : ''}`}
			style={style}
			onDragOver={(e)=>{
				e.preventDefault()
				setDropInfo({stageId, index:0, stage})
				setIsDragOver(true)
			}}
			onDragEnd={()=>{
				setIsDragOver(false)
			}}
			onDragExit={()=>{
				setIsDragOver(false)
				setDropInfo(null)
			}}
			onDragLeave={()=>{
				setDropInfo(null)
				setIsDragOver(false)
			}}
			onDrop={(e) => {
				if(shouldCapture) e.stopPropagation()

				const newDropInfo:dragLocation = {
					stageId:stageId,
					stage:stage,
					index:index ?? -1
				}
				const newDragInfo:dragLocation = {
					stageId: dragInfo?.stageId ?? -1,
					stage: dragInfo?.stage ?? '',
					index: dragInfo?.index ?? 0
				}
				
				if(!dragData){
					console.warn('No data being dragged')
					setDragInfo(null,null)
					return
				}
				onDropSuccess && onDropSuccess(e,newDragInfo,newDropInfo,dragData)
				setDragInfo(null,null)
				setIsDragOver(false)
			}}
		>
			{children}
		</div>	
	)
}

export default DropArea