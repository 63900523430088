// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportBillFileSelectorModalFile, setImportBillFileSelectorModalIsOpen, setBillTablePreviewModalIsOpen } from '../../../../../redux/general/modals'

const ImportBillFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.importBillFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportBillFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportBillFileSelectorModalFile(files[0]))
		dispatch(setImportBillFileSelectorModalIsOpen(false))
		dispatch(setBillTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}			
		/>
	)
}

export default ImportBillFileSelectorModal