//styles and icons
import './chatInputMessage.scss'
import { clip, microphone, sendMessageIcon } from '../../../../../../assets/employees/chat/chatIcons'

//react
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//components
import AttachmentBanner from './attachmentBanner/attachmentBanner'
import TextArea from '../../../../textArea/textArea'

//types
import { messageActions } from '../../../../../../types/employees/chatTypes'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//network
import { chatStatusUrl } from '../../../../../../utils/old_urls/employees/chatsUrls'
import { authorizedRequest } from '../../../../../../utils/queries'


type chatInputMessageProps = {
	setIsRecordingAudio: React.Dispatch<React.SetStateAction<boolean>>
	handleSubmit: () => void
}

const ChatInputMessage: FC<chatInputMessageProps> = ({ setIsRecordingAudio, handleSubmit }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.chatInput' })

	const { chatInfo, currentChatAction, attachFiles, chatInputValue, setChatInputValue } = useChatDesktop()
	const [ attachmentBannerIsOpened, setAttachmentBannerIsOpened ] = useState(false)

	useEffect(() => {
		if(chatInfo.data.chatId){
			authorizedRequest(chatStatusUrl(chatInfo.data.chatId), 'PUT')
		}
	}, [chatInputValue])

	useEffect(() => {
		const closeAttachementBanner = () => {
			setAttachmentBannerIsOpened(false)
		}

		window.addEventListener('click', closeAttachementBanner)

		return () => {
			window.removeEventListener('click', closeAttachementBanner)
		}
	})

	// Set the message if current action is editing
	useEffect(()=>{
		if(currentChatAction && currentChatAction.action === messageActions.EDIT){
			setChatInputValue(currentChatAction.message?.text ?? '')
		}
	}, [currentChatAction])


	return (
		<>
			<div className={`chat-input-container ${currentChatAction.action !== messageActions.SEND || attachFiles.attachedFiles.length > 0 ? 'chat-input-container-action' : ''}`}>
				<AttachmentBanner attachmentBannerIsOpened={attachmentBannerIsOpened}  setAttachmentBannerIsOpened={setAttachmentBannerIsOpened} />
				<TextArea
					enterForNewTab={false}
					placeholder={t('message')}
					value={chatInputValue}
					setValue={setChatInputValue}
					handleSubmit={handleSubmit}
					leftAddon={
						<div className='chat-input-tools' onClick={(e) => {
							e.stopPropagation()
							setAttachmentBannerIsOpened(!attachmentBannerIsOpened)}
						}>
							{clip}
						</div>
					}
					rightAddon={attachFiles.attachedFiles.length === 0 && chatInputValue.length === 0
						? <div className='chat-input-tools tools-microphone' onClick={() => { setIsRecordingAudio(true)}}>
							{microphone}
						</div>:
						attachFiles.attachedFiles.length > 0 || chatInputValue.length > 0 
							?<div className='chat-input-tools tools-microphone tools-send-message' onClick={handleSubmit}>
								{sendMessageIcon}
							</div>:''
					}
				/>
			</div>
		</>
	)
}

export default ChatInputMessage
