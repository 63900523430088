// react
import { FC } from 'react'

// component
import FileExportModal from '../../../modals/fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportReportFileModalIsOpen } from '../../../../../redux/general/modals'

type exportBillFileModalProps = {
    reportUrl: string
}

const ExportReportFileModal: FC<exportBillFileModalProps> = ({ reportUrl }) => {
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.exportReportFileModal)
	const { language } = useAppSelector((state) => state.general)
	
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setExportReportFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(reportUrl + `?type=data&export=True&export_type=${exportType}&language=${language}&time_zone_offset=${new Date().getTimezoneOffset()}`, 'GET')
		
		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}	
			exportFileTypes={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportReportFileModal