import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'


type salesOrderListModalsState = {
	createSalesOrderModal: {
		modalIsOpen: boolean
	}
}

const initialState: salesOrderListModalsState = {
	createSalesOrderModal: {
		modalIsOpen: false
	}
}

export const salesOrderListModalsSlice = createSlice({
	name: 'salesOrderListModalsSlice',
	initialState,
	reducers : {
		setCreateSalesOrderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createSalesOrderModal.modalIsOpen = action.payload
		}
	}
})

export const {
	setCreateSalesOrderModalIsOpen
} = salesOrderListModalsSlice.actions

export default salesOrderListModalsSlice.reducer