// styles
import './pieChart.scss'

// react
import { FC, useEffect, useState } from 'react'

// other
import { vwToPixel } from '../../../../assets/general/generalFunctions'

//types
import { legendItem, chartItem } from '../../../../types/general/generalTypes'

// chart library
import { Chart, ArcElement } from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
Chart.register(ArcElement)

type pieChartProps = {
	chartData: {
		xAxisLabels: string[]
		chartItems: chartItem[]
	}
	activeData: legendItem[]
	centerLabel?:string
	circumference?:number
}

const PieChart : FC<pieChartProps> = ({chartData, centerLabel, activeData, circumference}) => {

	// Data filtering
	// let pieData = data
	const filterData = (activeChartItems: legendItem[])=>{
		return chartData.chartItems.filter((chartItem) => activeChartItems.findIndex((activeItem) => activeItem.label === chartItem.label) !== -1)
	}

	const pieChartFilteredData = filterData(activeData)


	// Responsivity
	const [responsiveSize, setResponsiveSize] = useState({
		hoverOffset:0,
		spacing:0,
	})

	useEffect(()=>{
		const resize = ()=>{
			setResponsiveSize({
				hoverOffset:vwToPixel(2),
				spacing:vwToPixel(.2),
			})
		}
		window.addEventListener('resize',resize)

		resize()
		return ()=>{
			window.removeEventListener('resize',resize)
		}
	},[])


	const stylingOptions = {
		spacing: responsiveSize.spacing,
		hoverOffset: responsiveSize.hoverOffset,
		hoverBorderWidth: 0	
	}
	
	const formatData = (chartItems: chartItem[]) => {
		const pieChartValues: number[] = []
		const pieCharColors: string[] = []

		for(let i = 0; i < chartItems.length; i++){

			const initialValue = 0
			const sumValue = chartItems[i].data.reduce((accumulator, currentValue) => accumulator + currentValue.value, initialValue)

			pieChartValues.push(sumValue)
			pieCharColors.push(chartItems[i].color)
		}


		return {
			labels: chartData.xAxisLabels,
			datasets: [{
				label: 'my label',
				data: pieChartValues,
				backgroundColor: pieCharColors,
				...stylingOptions
			}]
		}
	
	}

	// Options
	const pieChartData = formatData(pieChartFilteredData)
	
	return (
		<div className='pie-container'>
			<Doughnut data={pieChartData} options={
				{
					cutout: '75%',
					radius: '80%',
					rotation: 175,
					maintainAspectRatio: false,
					circumference: circumference ?? 360
				}
			}/>
			<p className='pie-center-text'>{centerLabel}</p>
		</div>
	)
}

export default PieChart