import { baseUrl } from '../../network'

export const getBillFiltersUrl = (comapnyId: number) => baseUrl + `api/company/${comapnyId}/bills/filters`

// account
export const getAccountUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}`
export const updateAccountUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}`

// account transactions
export const getAccountTransactionsUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}/transactions`
export const createAccountTransactionUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}/transactions`
export const getAccountTransactionFiltersUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}/transactions/filters`
export const getAccountTransactionsOverviewUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}/transactions/overview`