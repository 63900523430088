// styles
import './infoDetailsTable.scss'

// react
import { FC, ReactNode } from 'react'

// types 
import { infoDetailsTableDataItem } from '../../../types/general/generalTypes'

// components
import InfoDetailsTableField from './infoDetailsTableField/infoDetailsTableField'

type infoDetailsTableProps = {
	data: infoDetailsTableDataItem[]
}

const InfoDetailsTable: FC<infoDetailsTableProps> = ({ data }) => {
	return (
		<div className='info-details-table-container'>
			{data.map((item: infoDetailsTableDataItem, index: number) => {
				return (
					<InfoDetailsTableField key={`datails-field-${index}`} title={item.title} data={item.data} fullWidth={item.fullWidth}/>
				)
			})}
		</div>
	)
}


export default InfoDetailsTable