// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportOfferingFileSelectorModalFile, setImportOfferingFileSelectorModalIsOpen, setTableOfferingPreviewModalIsOpen } from '../../../../../redux/sales/offeringList/modals'


const ImportOfferingFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.offeringListModal.importOfferingFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportOfferingFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportOfferingFileSelectorModalFile(files[0]))
		dispatch(setImportOfferingFileSelectorModalIsOpen(false))
		dispatch(setTableOfferingPreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}			
		/>
	)
}

export default ImportOfferingFileSelectorModal