//styles
import './offeringCard.scss'

// icons
import {
	pieChart,
	linearDiagram,
	calendar,
	deleteIcon,
	productOffer,
	warehouseProduct
} from '../../../assets/general/generalIcons'

// components
import Avatar from '../avatar/avatar'
import SwitchButtons from '../switchButtons/switchButtons'
import ForecastElement from './forecastElement/forecastElement'

// react
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

// types
import { offering, inventoryItem, itemTypes, moneyValue } from '../../../types/general/generalTypes'

//other
import { currencyToFormattedString, getCost, getOfferingCost } from '../../../assets/general/generalFunctions'

//translaction
import { useTranslation } from 'react-i18next'

type forecastItem = {
	title: string
	value: number
}

type offeringCardProps = (offering | inventoryItem) & {
	forecast?: forecastItem[]
	link?: string
	costs?: moneyValue[]
	price?: moneyValue
	vat?: number
	items?: inventoryItem[]
	handleDelete?: ()=> void
}

const OfferingCard: FC<offeringCardProps> = ({ items,avatar, name, costs, price, vat, quantity, description, type, forecast, link, handleDelete }) => {
	const { t } = useTranslation('', {keyPrefix:'general.offeringCard'})
	const [activeRepresentationSwitchButtonId, setActiveRepresentationSwitchButtonId] = useState(0)
	const [activeTimeSwitchButtonId, setActiveTimeSwitchButtonId] = useState(0)

	return (
		<div className='offering-card-container'>
			{
				forecast ?
					<div className="offering-card-forecast-options-container">
						<SwitchButtons
							switchButtons={
								[
									{ id: 0, icon: pieChart },
									{ id: 1, icon: linearDiagram }
								]
							}
							activeSwitchButtonId={activeRepresentationSwitchButtonId}
							setActiveSwitchButtonId={setActiveRepresentationSwitchButtonId}
							size='small'
						/>

						<SwitchButtons
							switchButtons={
								[
									{ id: 0, text: t('day') },
									{ id: 1, text: t('week') },
									{ id: 2, text: t('month') },
									{ id: 3, icon: calendar }
								]
							}
							activeSwitchButtonId={activeTimeSwitchButtonId}
							setActiveSwitchButtonId={setActiveTimeSwitchButtonId}
							size='small'
						/>
					</div>
					:
					null

			}

			<Link to={link ?? '#'} className='offering-card-content-container'>
				<div className="offering-card-content">
					<div className="offering-card-info-container">
						<div className="offering-avatar-container">
							<Avatar blobAvatar={avatar}  placeholder={type === itemTypes.inventory_item ? warehouseProduct : productOffer}/>
						</div>
						<p className="offering-name">{name}</p>
					</div>
					{ 	
						type === itemTypes.inventory_item ?
							getCost(costs).filter(item => item.minAmount || item.maxAmount).map((item) => {
								return <p className="offering-price">{t('cost')}: {item.minAmount ? currencyToFormattedString(item.minAmount, item.currency) : ''}{item.minAmount && item.maxAmount ? ' - ' : ''}{item.maxAmount ? currencyToFormattedString(item.maxAmount, item.currency) : ''}</p>
							})
							:
							getOfferingCost(items).filter(item => item.minAmount || item.maxAmount).map((item) => {
								return <p className="offering-price">{t('cost')}: {item.minAmount ? currencyToFormattedString(item.minAmount, item.currency) : ''}{item.minAmount && item.maxAmount ? ' - ' : ''}{item.maxAmount ? currencyToFormattedString(item.maxAmount, item.currency) : ''}</p>
							})
					}
					{
						type === itemTypes.offering ?
							<>
								<p className="offering-price">{t('priceWithVat')}: {currencyToFormattedString(price.amount, price.currency)}</p>
								<p className="offering-price">{t('priceWithoutVat')}: {currencyToFormattedString(price.amount / (1 + vat / 100), price.currency)}</p>
								<p className="offering-price">{t('vat')}: {vat}%</p>
							</>
							: null
					}
					{
						type === itemTypes.inventory_item ?
							<p className="offering-price">{t('quantity')}: {quantity}</p> 
							: null
					}
					<p className="offering-description">{description}</p>
				</div>
				{handleDelete && <div className='offering-delete'>
					<SwitchButtons
						switchButtons={[
							{
								id: 1,
								onClick: (e) => {
									e.preventDefault()
									handleDelete()
								},
								icon: deleteIcon
							}
						]}
						orientation='vertical'
					/>
				</div>}
			</Link>

			{
				forecast ?
					<div className="offering-card-forecast-data-container">
						{
							forecast.map((forecastItem) => {
								return <ForecastElement title={forecastItem.title} value={forecastItem.value} />
							})
						}
					</div>
					:
					null
			}
		</div>
	)
}

export default OfferingCard