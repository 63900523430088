import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

type ordereModalsState = {
    deleteOrderModal: {
        modalIsOpen: boolean
        orderId: number | null
    }
    closeOrderModal: {
        modalIsOpen: boolean
        orderId: number | null
		isOrderClosed: boolean | null
    }, 
	refundOrderModal: {
		modalIsOpen: boolean
		orderId: number | null
	}
}

const initialState: ordereModalsState = {
	deleteOrderModal: {
		modalIsOpen: false,
		orderId:  null
	},
	closeOrderModal: {
		modalIsOpen: false,
		orderId:  null,
		isOrderClosed: null
	},
	refundOrderModal: {
		modalIsOpen: false,
		orderId:  null,
	}
}

export const orderModalsSlice = createSlice({
	name: 'orderModalsReducer',
	initialState,
	reducers: {
		setDeleteOrdernModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteOrderModal.modalIsOpen = action.payload
		},
		setDeleteOrderId: (state, action: PayloadAction<number | null>) => {
			state.deleteOrderModal.orderId = action.payload
		},
		setCloseOrdernModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.closeOrderModal.modalIsOpen = action.payload
		},
		setCloseOrderId: (state, action: PayloadAction<number | null>) => {
			state.closeOrderModal.orderId = action.payload
		},
		setIsOrderClosed: (state, action: PayloadAction<boolean | null>) => {
			state.closeOrderModal.isOrderClosed = action.payload
		},
		setRefundOrderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.refundOrderModal.modalIsOpen = action.payload
		},
		setRefundOrderId: (state, action: PayloadAction<number | null>) => {
			state.refundOrderModal.orderId = action.payload
		},
	}
})

export const {
	setDeleteOrdernModalIsOpen,
	setDeleteOrderId,
	setCloseOrdernModalIsOpen,
	setCloseOrderId,
	setIsOrderClosed,
	setRefundOrderModalIsOpen,
	setRefundOrderId,
} = orderModalsSlice.actions

export default orderModalsSlice.reducer