// styles
import './deleteWarehouseModal.scss'

//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

//react
import { FC, useState, useEffect } from 'react'

//types
import { warehouse } from '../../../../../types/warehouse/generalTypes'
import { detailedErrorObject } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleWarehouseUrl } from '../../../../../utils/urls/warehouses/warehouses/warehouses'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setDeleteWarehouseModalIsOpen, setDeleteWarehouseId } from '../../../../../redux/warehouse/warehouseList/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

type deleteWarehouseModalProps = {
	warehouses: warehouse[]
	setWarehouses: (value: warehouse[]) => void
}

const DeleteWarehouseModal: FC<deleteWarehouseModalProps> = ({ warehouses, setWarehouses }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouseList.modals.deleteWarehouseModal' })

	const { modalIsOpen, warehouseId } = useAppSelector((state) => state.warehouseListModal.deleteWarehouseModal)

	const dispatch = useAppDispatch()

	const [buttons, setBusttons] = useState<{
		text: string
		onClickHandler: () => void
			}[]>([])
	const [description, setDescription] = useState<string>()
	const [errorObjects, setErrorObjects] = useState<detailedErrorObject[]>()
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		if(modalIsOpen){
			setErrorObjects(undefined)
			setDescription(undefined)
			setBusttons([])
			setLoading(true)
			authorizedRequest(singleWarehouseUrl(Number(warehouseId)), 'PATCH')
				.then((response) => {
					const { result } = response

					const { description, objects } = result
				
					if(description){
						setDescription(t(description))
					}

					if(objects.length > 0){
						setErrorObjects(objects.map(formatDetailedErrorObjectResponse))

					}else{
						const buttons = [{ text: t('yes'), onClickHandler: handleDeleteWarehouse }, { text: t('no'), onClickHandler: closeModal }]
						setBusttons([...buttons])
					}
					setLoading(false)
				})
		}
	}, [modalIsOpen])

	const closeModal = () => {
		dispatch(setDeleteWarehouseModalIsOpen(false))
		dispatch(setDeleteWarehouseId(null))
	}

	const handleDeleteWarehouse = () => {
		if (warehouseId) {
			setLoading(true)
			authorizedRequest(singleWarehouseUrl(warehouseId), 'DELETE')
				.then(() => {
					setWarehouses([...warehouses.filter((warehouse) => warehouse.id !== warehouseId)])

					setLoading(false)
					dispatch(setDeleteWarehouseId(null))
					dispatch(setDeleteWarehouseModalIsOpen(false))
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('deleteThisWarehouse')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={buttons}
			errorObjects={errorObjects}
			description={description}
			loading={loading}
		/>
	)

}

export default DeleteWarehouseModal