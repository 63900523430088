// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setWarehouseTablePreviewModalIsOpen, setImportWarehouseFileSelectorModalFile, setImportWarehouseFileSelectorModalIsOpen } from '../../../../../redux/warehouse/warehouseList/modals'

//types
import { warehouseResponse } from '../../../../../types/general/generalTypes'
import { warehouse } from '../../../../../types/warehouse/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyWarehousesUrl } from '../../../../../utils/urls/warehouses/warehouses/warehouses'

//translation
import { useTranslation } from 'react-i18next'

type warehouseTablePreviewModalProps = {
	warehouses: warehouse[]
	setWarehouses: (value: warehouse[]) => void
}

const WarehouseTablePreviewModal: FC<warehouseTablePreviewModalProps> = ({ warehouses, setWarehouses }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouseList.modals.warehouseTablePreviewModal' })

	const { file } = useAppSelector((state) => state.warehouseListModal.importWarehouseFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.warehouseListModal.warehouseTablePreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportWarehouseFileSelectorModalIsOpen(false))
		dispatch(setWarehouseTablePreviewModalIsOpen(false))
		dispatch(setImportWarehouseFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => {
				authorizedRequest(companyWarehousesUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map((item) => {
						return {
							company_id: companyId,
							address: item.address,
							name: item.name
						}
					})
				})
					.then((response) => {
						const result: warehouseResponse[] = response.result

						const formatedWarehouses: warehouse[] = result.map((warehouse) => {
							return {
								id: warehouse.id,
								name: warehouse.name,
								coords: {
									lat: warehouse.coords.lat,
									lng: warehouse.coords.lng
								},
								address: warehouse.address,
								shipmentAmount: warehouse.shipment_amount,
								movingAmount: warehouse.moving_amount,
								worth: warehouse.worth,
								editAccess: warehouse.edit_access,
								deleteAccess: warehouse.delete_access
							}

						})

						setWarehouses([...formatedWarehouses, ...warehouses])

						closeModal()
					})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('address'),
					key: 'address',
					default: '',
					type: String
				}
			]}
		/>
	)
}

export default WarehouseTablePreviewModal