// react
import { Dispatch, FC, SetStateAction } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCustomerTablePreviewModalIsOpen, setImportCustomerFileSelectorModalFile, setImportCustomerFileSelectorModalIsOpen } from '../../../../../redux/sales/customerList/modals'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyCustomersUrl } from '../../../../../utils/urls/sales/customer'

// types
import { relationshipTypes, customerResponse } from '../../../../../types/general/generalTypes'
import { customer } from '../../../../../types/sales/customerTypes'

//translation
import { useTranslation } from 'react-i18next'

type customerTablePreviewModalProps = {
	customers: customer[]
	setCustomers: Dispatch<SetStateAction<customer[]>>
}

const CustomerTablePreviewModal : FC<customerTablePreviewModalProps> = ({customers, setCustomers}) => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customerList.modals.customerTablePreviewModal'})

	const { file } = useAppSelector((state) => state.customerListModal.importCustomerFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.customerListModal.customerTablePreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setImportCustomerFileSelectorModalIsOpen(false))
		dispatch(setCustomerTablePreviewModalIsOpen(false))
		dispatch(setImportCustomerFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}	
			open={modalIsOpen}
			closeModal={closeModal}	
			onTableProcessed={(tableData) => {
				authorizedRequest(companyCustomersUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map(( item ) => {
						return {
							name: String(item.name),
							phone: String(item.phone),
							email: String(item.email),
							company_id: companyId,
							relationship_type: relationshipTypes.CUSTOMER
						}
					})
				}).then(({result}) => {
					if(result.length > 0){
						const formatedCustomers: customer[] = result.map((customer: customerResponse) => {
							console.log(customer)
							return {
								id: customer.relationship_id,
								chatId: customer.chat_id,
								name: customer.name,
								avatar: customer.avatar,
								labels: customer.labels
							}
						})
						setCustomers([...formatedCustomers,...customers])
					}
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('phoneNumber'),
					key: 'phone',
					default: '',
					type: String
				},
				{
					title: t('email'),
					key: 'email',
					default: '',
					type: String
				}
			]}
		/>
	)
}

export default CustomerTablePreviewModal