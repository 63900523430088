
//styles and icons
import './attachmentBanner.scss'
import { filesIcon, videoMessageIcon } from '../../../../../../../assets/employees/chat/chatIcons'

//react
import { FC, useEffect } from 'react'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//other
import { useTranslation } from 'react-i18next'

type attachmentBannerProps = {
	attachmentBannerIsOpened: boolean
	setAttachmentBannerIsOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const AttachmentBanner: FC<attachmentBannerProps> = ({ attachmentBannerIsOpened, setAttachmentBannerIsOpened }) => {

	const { setAttachModalIsOpen, setIsVideoInitialized } = useChatDesktop()

	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.chatInput.chatInputMessage.attachmentBanner' })

	useEffect(() => {
		const handler = () => {
			setAttachmentBannerIsOpened(false)
		}

		window.addEventListener('click', handler)

		return () => {
			window.removeEventListener('click', handler)
		}
	}, [])

	return (
		<div className={`attachments-banner ${attachmentBannerIsOpened ? '' : 'attachments-banner-closed' }`}>
			<div className="attachments-banner-item" onClick={() => {
				setAttachModalIsOpen(true)
				setAttachmentBannerIsOpened(false)
			}}>
				<div className="attachments-banner-icon">{filesIcon}</div>
				<p className="attachhments-banner-text">{t('files')}</p>
			</div>
			<div className="attachments-banner-item" onClick={() => {
				setIsVideoInitialized(true)
				setAttachmentBannerIsOpened(false)
			}}>
				<div className="attachments-banner-icon">{videoMessageIcon}</div>
				<p className="attachhments-banner-text">{t('recordVideo')}</p>
			</div>
		</div>
	)
}

export default AttachmentBanner
