//styles
import './phoneNumberList.scss'
import { deleteIcon } from '../../../../../../assets/general/generalIcons'

//react
import { useState, useEffect, FC } from 'react'

//components
import ColumnLayout from '../../../../columnLayout/columnLayout'
import CounterpartyCard from '../../../../counterpartyCard/counterpartyCard'
import FilterBar from '../../../../layout/filterBar/filterBar'
import CreatePhoneNumberModal from './modals/createPhoneNumberModal/createPhoneNumberModal'
import DeletePhoneNumberModal from './modals/deletePhoneNumberModal/deletePhoneNumberModal'

//redux
import { useAppDispatch } from '../../../../../../customHooks/redux'
import { setCreatePhoneNumberModalIsOpen, setDeletePhoneNumberId, setDeletePhoneNumberModalIsOpen } from '../../../../../../redux/general/settings/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { getItemsPerColumn } from '../../../../../../assets/general/generalFunctions'

//types
import { phoneNumber } from '../../../../../../types/general/generalTypes'

type phoneNumberListProps = {
    phoneNumbers: phoneNumber[]
	setPhoneNumbers: ( value: phoneNumber[] ) => void
}

const PhoneNumberList: FC<phoneNumberListProps> = ({ phoneNumbers, setPhoneNumbers }) => {
	const [ itemsPerColumn, setItemsPerColumn ] = useState(getItemsPerColumn(3))

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection'})

	const dispatch = useAppDispatch()

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<div className="phone-list">
			<h2 className='phone-heading'>{t('phoneNumberList')}</h2>
			<FilterBar 
				addButton={{
					text: t('add'),
					active: true,
					onClick: ()=> {
						dispatch(setCreatePhoneNumberModalIsOpen(true))
					}
				}}
			/>
			<ColumnLayout amount={itemsPerColumn}>
				{phoneNumbers.map(number => {					
					return <CounterpartyCard 
						name={number.phoneNumber} 
						actions={[{id: 1, icon: deleteIcon, onClick: () => {
							dispatch(setDeletePhoneNumberModalIsOpen(true))
							dispatch(setDeletePhoneNumberId(number.id))
						}}]}
					/>
				}
					
				)}
			</ColumnLayout>
			<CreatePhoneNumberModal phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
			<DeletePhoneNumberModal phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
		</div>
	)

}

export default PhoneNumberList