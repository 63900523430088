import { messageType, messageActions, chatMember, messagesSliderEnum } from '../../../../../types/employees/chatTypes'
import { file } from '../../../../../types/general/generalTypes'

export enum chatDesktopActionTypes {
    setChatInfoSliderIsOpen,
    setChatData,
    setChatStatus,
    setCurrentChatAction,
    setContextMenu,
    setInputBannerIsOpen,
    setChatInputValue,
    setAttachModalIsOpen,
    setAttachedFiles,
    setMessages,
    setMessagesPage,
    setLastPage,
    setMessagesSliderIsOpen,
    setMessagesSliderInputValue,
    setChatFilesSliderIsOpen,
    setChatFiles,
    setIsVideoInitialized,
    setIsVideoRecording,
    setVideoBlob,
    setVideoTimer,
    setForwardModalIsOpen,
    setForwardMessageId,
    setDeleteModalIsOpen,
    setDeleteMessageId,
    setScrollToTheLastMessage
}

export type chatStatusType = {
	online: number,
	offline: number,
	typing: string[]
}

export type chatDataType = {
    chatId: number | null
    name: string
    description: string
    avatar: string | null
    date: Date
    group: boolean
    chatMembers: chatMember[]
    ownerId: number
}

export type chatInfoType = {
	sliderIsOpen: boolean
    data: chatDataType
	status: chatStatusType
}

export type currentChatActionType = {
    action: messageActions
    message: messageType | null
}


export type contextMenuType = {
    coords: {
        x: number
        y: number
    }
    message: messageType
} | null

export type messagesSliderType = {
    sliderIsOpen: messagesSliderEnum,
    inputValue: string
}

export type chatFilesType = {
	sliderIsOpen: boolean,
	chatFiles: file[]
}

export type messagesType = {
    messages: messageType[]
    page: number
    lastPage: boolean
}

export type inputBannerType = {
    inputBannerIsOpen: boolean
}

export type attachFilesType = {
    modalIsOpen: boolean
    attachedFiles: file[]
}

export type videoRecordingType = {
    isVideoInitialized: boolean
    isVideoRecording: boolean
    videoBlob: Blob | null
    videoTimer: number
}

export type forwardMessageType = {
    modalIsOpen: boolean
    messageId: null | number
}

export type deleteMessageType = {
    modalIsOpen: boolean
    messageId: null | number
}

export type chatDesktopState = {
    chatInfo: chatInfoType
    currentChatAction: currentChatActionType
    contextMenu: contextMenuType
    inputBanner: inputBannerType
    chatInputValue: string
    attachFiles: attachFilesType
    messages: messagesType
	messagesSlider: messagesSliderType
	chatFiles: chatFilesType
    videoRecording: videoRecordingType
    forwardMessage: forwardMessageType
    deleteMessage: deleteMessageType,
    scrollToTheLastMessage: boolean
}