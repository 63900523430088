//styles
import './title.scss'

//react
import { FC, ReactNode } from 'react'  

type titleProps = {
    children: ReactNode
}

const Title: FC<titleProps> = ({ children }) => {

	return <h1 className='title'>{children}</h1>

}

export default Title