// styles and icons
import './checkBox.scss'

// react
import { FC } from 'react'

type checkBoxProps = {
	isChecked: boolean;
	onClick: () => void;
	label?: string
};

const CheckBox: FC<checkBoxProps> = ({
	isChecked=false,
	onClick,
	label
}) => {

	return (
		<div className="checkbox-container">
			<div className="checkbox" onClick={onClick}>
				<span className={isChecked ? 'checked' : 'unchecked'}></span>
			</div>
			{ label ? <p className='checkbox-label' onClick={onClick}>{label}</p> : null}
		</div>
	)
}

export default CheckBox