// react 
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useParams } from 'react-router'

// components
import CreateOrderModal from '../../../../../general/modals/createOrderModal/createOrderModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setCreateCustomerOrderModalIsOpen } from '../../../../../../redux/sales/customer/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { createSalesOrderUrl } from '../../../../../../utils/old_urls/sales/salesOrderListUrls'
import { companyOfferingsUrl } from '../../../../../../utils/urls/sales/offerings'
import { pipelinesManagePipelineResourceUrl } from '../../../../../../utils/urls/projects/pipelines'

// types
import {
	createOrderErrors,
	dropdownOption,
	infoPopupTypes,
	offering,
	order
} from '../../../../../../types/general/generalTypes'
import { pipelineResponse } from '../../../../../../types/projects/pipelineTypes'

//translation
import { useTranslation } from 'react-i18next'

// other
import { formatOfferingResponse, formatOrderResponse } from '../../../../../../assets/general/generalFunctions'

type createCustomerOrderModalProps  = {
	orders: order[]
	setOrders: (value: order[]) => void
}

const CreateCustomerOrderModal: FC<createCustomerOrderModalProps> = ({orders, setOrders}) => {
	const dispatch = useAppDispatch()
	
	const { modalIsOpen } = useAppSelector((state) => state.customerModal.customerOrders.createCustomerOrderModal)
	const { userCompanyData, language } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const {relationshipId} = useParams()

	const [selectedPipelineOption, setSelectedPipelineOption] = useState<dropdownOption | null>(null)
	const [errors, setErrors] = useState<createOrderErrors>({})
	const { t } = useTranslation('', { keyPrefix: 'general.modals.createOrderModal' })

	const closeModal = () => {
		dispatch(setCreateCustomerOrderModalIsOpen(false))
	}

	const pipelinePopup = {
		content: [
			{
				title: 'create_pipeline_info_title',
				description: 'create_pipeline_info_description',
				link: '/ri-business/projects/pipelines',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const loadProducts = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(companyOfferingsUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if(result && result.length > 0) {
			const offeringsData: offering[] = result.map(formatOfferingResponse)
			return offeringsData
		}
		return []
		
	}

	const loadPipelinesOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId) + `?page=${page}&per_page=${10}&needle=${search}`, 'GET')

		return result.map((pipeline: pipelineResponse) => {
			return {
				title: pipeline.name,
				key: `${pipeline.id}`
			}
		})
	}


	const checkErrors = () => {
		let hasErrors = false

		if (!selectedPipelineOption) {
			hasErrors = true
			setErrors({ ...errors, pipeline: 'pleaseSelectPipeline' })
		}

		return hasErrors
	}

	const addOrder = async (item: offering[])=> {
		closeModal()
		const hasErrors = checkErrors()
		if (hasErrors) return

		if (selectedPipelineOption && relationshipId) {

			const offerings = item.map((offering) => {
				return {
					offering_id: offering.offeringId,
					offering_quantity: offering.quantity
				}
			})

			const body = {
				language: language,
				offerings: offerings,
				address_id: null,
				warehouse_id: null,
				pipeline_id: Number(selectedPipelineOption.key),
			}


			const {result} = await authorizedRequest(createSalesOrderUrl(Number(relationshipId)), 'POST', 'accessToken', body)
			if (result) {
				const formatedOrder: order = formatOrderResponse(result)
				setOrders([formatedOrder, ...orders])
			}
		}
	}

	return (
		<CreateOrderModal 
			isOpen={modalIsOpen} 
			closeModal={closeModal}
			loadItems={loadProducts} 
			createOrder={addOrder}
			hasErrors={checkErrors}
			relationshipId={Number(relationshipId)}
			dropdowns={[
				{
					label: t('pipeline'),
					popup: pipelinePopup,
					loadOptions: loadPipelinesOptions,
					selectedOption: selectedPipelineOption,
					onSelect:({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedPipelineOption(null)
						setSelectedPipelineOption({ key, title, avatar })
					},
					dropdownOptions:[],
					placeholder: 'selectPipeline',
					error: errors.pipeline ? errors.pipeline : undefined
				}
			]}
		/>
	)
}

export default CreateCustomerOrderModal