// react
import { Dispatch, FC, SetStateAction, useState } from 'react'

// components
import ConfirmationModal from '../../../../../modals/confirmationModal/confirmationModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../../customHooks/redux'
import { setDeletePositionId, setDeletePositionModalIsOpen } from '../../../../../../../redux/general/settings/modals'

// translation
import { useTranslation } from 'react-i18next'

// types
import { position } from '../../../../../../../types/general/settings/companySectionTypes'

// network
import { authorizedRequest } from '../../../../../../../utils/queries'
import {modifyPositionUrl} from '../../../../../../../utils/urls/general/positions'

type deletePositionModalProps = {
	positions: position[]
	setPositions: Dispatch<SetStateAction<position[]>>
}
const DeletePositionModal: FC<deletePositionModalProps> = ({positions, setPositions}) => {
	const {modalIsOpen, positionId} = useAppSelector(state => state.settingsModals.deletePosition)
	const dispatch = useAppDispatch()
	const {t} = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.deletePositionModal'})

	const [loading, setLoading] = useState<boolean>(false)

	const closeModal = () => {
		dispatch(setDeletePositionModalIsOpen(false))
		dispatch(setDeletePositionId(null))
	}
	const deletePosition = () => {
		setLoading(true)
		authorizedRequest(modifyPositionUrl(positionId!), 'DELETE')
			.then(response => {
				const updatedPositions = positions.filter(position => position.id !== positionId)
				setPositions(updatedPositions)
				setLoading(false)
				closeModal()
			})	
	}
	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deletePosition }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeletePositionModal