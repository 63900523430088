// images
import TrackByCompletionEn from '../../../../assets/manual/project/en/myTaskList.png'
import SortTaskEn from '../../../../assets/manual/project/en/pipelineFilter.png'
import TaskCalenderEn from '../../../../assets/manual/project/en/myTaskCalendar.png'
import TrackByCompletionUa from '../../../../assets/manual/project/ua/myTaskList.png'
import SortTaskUa from '../../../../assets/manual/project/ua/pipelineFilter.png'
import TaskCalenderUa from '../../../../assets/manual/project/ua/myTaskCalendar.png'


// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const MyTaskManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.projects.myTasks' })
	const { language } = useAppSelector(state => state.general)

	const data: manualData[] = [
		{
			title: t('taskDetailTitle'),
			text: t('taskDetailsText'),
			// images: language === 'en' ? [TrackByCompletionEn] : [TrackByCompletionUa]
			
		},
		{
			title: t('allCompanyTasksTitle'),
			text: t('allCompanyTasksText'),
			// images: language === 'en' ? [SortTaskEn] : [SortTaskUa]
			
		},
		{
			title: t('dashboardViewTitle'),
			text: t('dashboardViewText'),
			// images: language === 'en' ? [TaskCalenderEn] : [TaskCalenderUa]	
		},
		{
			title: t('listViewTitle'),
			text: t('listViewText'),
			// images: language === 'en' ? [TaskCalenderEn] : [TaskCalenderUa]	
		},
		{
			title: t('calendarViewTitle'),
			text: t('calendarViewText')
			// images: language === 'en' ? [TaskCalenderEn] : [TaskCalenderUa]	
		}
	]
	
	return (
		<ManualLayout 
			heading={t('myTasksManualTitle')}
			data={data}
		/>
	)
}

export default MyTaskManual
