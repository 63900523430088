// react
import { FC, ReactNode } from 'react'

// components
import DropAreaIndicator from '../dropAreaIndicator/dropAreaIndicator'

// types
import { dragLocation } from '../../../../types/general/generalTypes'

// context
import useDragAndDrop from '../context/dragAndDropHandlerContext'

type dropStageProps = {
	stageId: number
	stage:string
	dropAreaSize?: string
	children:ReactNode
	className?:string
	onDrop?:(dragInfo:dragLocation,dropInfo:dragLocation)=>void
}


const DropStageArea : FC<dropStageProps> = ({stageId, stage, dropAreaSize, onDrop, children, className}) => {

	const { setDropInfo,dropInfo,dragInfo,dragData } = useDragAndDrop()

	const getDropInfo = (stageId: number, mousePositionY:number, stage:string) => {
		const allDragItems = document.getElementsByClassName(`drag-item-${stage.replaceAll(' ','-')}`)
		const allDragItemElement:Element[] = []
		for(let i = 0; i < allDragItems.length;i++){
			allDragItemElement.push(allDragItems[i])
		}
		const allOffsetTop = allDragItemElement.map((dragItem)=>{
			return dragItem.getClientRects()[0].top
		})
		const dropIndex = allOffsetTop.filter((dragItemTop)=>dragItemTop < mousePositionY).length
		const dropState: dragLocation = {stageId, stage, index: dropIndex-1}
		
		return dropState
	}
	return (
		<div
			className={className}
			onDragOver={(e)=>{
				const dropInfo = getDropInfo(stageId, e.clientY,stage)
				setDropInfo(dropInfo)
				e.preventDefault()
			}} 
			onDragEnd={()=>{
				if((dragData || dragData === null) && dropInfo && dragInfo ){
					const newDragInfo: dragLocation = {
						stageId: dragInfo.stageId,
						stage: dragInfo.stage,
						index: dragInfo.index
					}

					onDrop && onDrop(newDragInfo, dropInfo)
				}
				setDropInfo(null)
			}}
		>
			<DropAreaIndicator stage={stage} index={-1} dropAreaSize={dropAreaSize}/>
			{children}
		</div>
	)
}

export default DropStageArea