// styles
import './inventoryItem.scss'

// react
import { FC, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import InventoryItemDetails from '../../../components/warehouse/inventoryItem/inventoryItemDetails/inventoryItemDetails'
import InventoryItemProviderOfferingList from '../../../components/warehouse/inventoryItem/InventoryItemOfferingList/inventoryItemProviderOfferingList'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'


enum inventoryItemSection {
	Details = 'Details',
	Offerings = 'Offerings',
}

const InventoryItem = () => {
	const [inventoryItemName, setInventoryItemName] = useState('')
	const [activeInventoryItemSection, setActiveInventoryItemSection] = useState<inventoryItemSection>(inventoryItemSection.Details)
	
	const [ filterBar, setFilterBar ] = useState<filterBar>()

	const {t} = useTranslation('', {keyPrefix: 'warehouse.inventoryItem'})

	const titleUrls = [
		{
			url: '/ri-business/warehouse/inventory',
			title: t('inventoryItems')
		}, 
		{
			url: location.pathname,
			title: inventoryItemName
		}
	]

	const infoPopup = {
		content: [{
			title: 'inventory_item_info_title',
			description: 'inventory_item_info_description',
			link: '/ri-manual/warehouses/single-inventory-item'
		}],
		type: infoPopupTypes.INFO
	}

	const inventoryItemTabButtons: button[] = [
		{
			active: activeInventoryItemSection === inventoryItemSection.Details,
			text: t('details'),
			onClick: () => setActiveInventoryItemSection(inventoryItemSection.Details),
		},
		{
			active: activeInventoryItemSection === inventoryItemSection.Offerings,
			text: t('offerings'),
			onClick: () => setActiveInventoryItemSection(inventoryItemSection.Offerings),
		},
	]

	const renderTab = ()=> {
		switch (activeInventoryItemSection) {
		case inventoryItemSection.Details:
			return <InventoryItemDetails setInventoryItemName={setInventoryItemName}/>
		case inventoryItemSection.Offerings:
			return <InventoryItemProviderOfferingList setFilterBar={setFilterBar}/>
		default:
			return null
		}
	}
	
	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={inventoryItemTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="inventory-container">
				{renderTab()}
			</div>
		</Layout>
	)
}

export default InventoryItem