// styles
import './dashboardCard.scss'

// react
import { FC } from 'react'

// icon
import { clockIcon, taskListIcon } from '../../../../../assets/projects/project/projectIcons'
import { activeStatus, closedStatus, warehouseProduct } from '../../../../../assets/general/generalIcons'

// types
import { dashboardCard, infoPopupTypes } from '../../../../../types/general/generalTypes'

// component
import Label from '../../../label/label'
import Avatar from '../../../avatar/avatar'
import AvatarStack from '../../../avatarStack/avatarStack'
import InfoPopup from '../../../infoPopup/infoPopup'
import Table from '../../../table/table'

// others
import { formatDate, currencyToFormattedString, getDeadlineData } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type dashboardCardProps = dashboardCard & {
	onClick?: () => void
	isInsideReactFlow?: boolean
}


const DashboardCard: FC<dashboardCardProps> = ({ title, counterparty, labels, endDate, startDate, executors, revenue, onClick, isClosed, errors, warehouseAction, isInsideReactFlow }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.dashboard.dashboardCard' })

	const { daysLeft, isDue } = getDeadlineData(endDate)

	console.log(daysLeft, isDue)

	return (
		<div className='dashboard-card-container' onClick={onClick} >
			{
				counterparty ? (
					<div className="dashboard-card-header">
						<Avatar blobAvatar={counterparty?.avatar || null} />
						<h2>{counterparty?.name}</h2>
					</div>
				) : null
			}
			<div className="dashboard-card-body">
				{taskListIcon}
				<p>{title}</p>
				{
					isClosed ? closedStatus : activeStatus
				}
				{errors.length > 0 && !isInsideReactFlow ?
					<InfoPopup
						isInsideReactFlow={isInsideReactFlow}
						content={errors.map((error) => {
							return {
								title: error.title,
								description: error.description,
								link: error.errorRootLink
							}
						})}
						type={infoPopupTypes.ERROR}
					/>
					: null}
			</div>
			{labels && (
				<div className='dashboard-labels-container'>
					{labels.map((label, i) => (
						<Label {...label} key={i} />
					))}
				</div>
			)}
			{revenue && (
				<div className='dashboard-budget'>
					{
						revenue.map((revenueItem) => {
							return <p>{currencyToFormattedString(revenueItem.amount, revenueItem.currency)}</p>
						})
					}
				</div>
			)}

			{(startDate || endDate || executors) && (
				<div className="dashboard-card-footer">
					<div className={`date-container ${isDue ? 'isDue' : ''}`}>
						{(startDate || endDate) && (
							<>
								<div className="dates-data">
									<div className="icon">
										{clockIcon}
									</div>
									<div className="date">
										<p className='start-date'>{startDate && formatDate(startDate, true, true)} </p>
										<p className='due-date'>{endDate && formatDate(endDate, true, true)} </p>
									</div>
								</div>
								<p className='days-left'>{daysLeft && daysLeft > 0 ? `${t('daysLeft')}: ${daysLeft}` : daysLeft && daysLeft <= 0 ? `${t('lateFor')} ${Math.abs(daysLeft)} ${t('days')}` : null}</p>
							</>
						)}
					</div>

					{executors && (
						<AvatarStack avatars={executors.map(executor => executor.avatar)} maxAvatar={2}></AvatarStack>
					)}
				</div>
			)}
			{
				warehouseAction ?
					<Table
						columns={
							[
								{ key: 'inventoryItems', title: t('items') },
								{ key: 'quantity', title: t('quantity') }
							]
						}
						data={
							warehouseAction.inventoryItems.map((item) => {
								return {
									inventoryItems: (
										<div className='dashboard-card-warehouse-action-item-container'>
											<Avatar blobAvatar={item.avatar} placeholder={warehouseProduct} />
											<p>{item.name}</p>
										</div>
									),
									quantity: `x${item.quantity}`
								}
							})
						}
						showHeader={true}
					/> : null
			}
		</div>
	)
}
export default DashboardCard