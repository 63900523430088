// react
import { Dispatch, FC, SetStateAction } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportProviderFileSelectorModalFile, setImportProviderFileSelectorModalIsOpen, setProviderTablePreviewModalIsOpen } from '../../../../../redux/purchase/providersList/modals'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { createProvidersUrl } from '../../../../../utils/old_urls/purchase/providerListUrls'

// types
import { relationshipTypes } from '../../../../../types/general/generalTypes'
import { provider, providerResponse } from '../../../../../types/purchase/providerTypes'

//translation
import { useTranslation } from 'react-i18next'

type providerTablePreviewModalProps = {
	providerList: provider[]
	setProviderList: Dispatch<SetStateAction<provider[]>>
}

const ProviderTablePreviewModal : FC<providerTablePreviewModalProps> = ({providerList, setProviderList}) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.providerList.modals.providerTablePreviewModal'})

	const { file } = useAppSelector((state) => state.providerListModal.importProviderFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.providerListModal.providerTablePreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setImportProviderFileSelectorModalIsOpen(false))
		dispatch(setProviderTablePreviewModalIsOpen(false))
		dispatch(setImportProviderFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}	
			open={modalIsOpen}
			closeModal={closeModal}	
			onTableProcessed={(tableData) => {
				authorizedRequest(createProvidersUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map(( item ) => {
						return {
							name: item.name,
							phone: item.phone,
							email: item.email,
							description: item.description,
							company_id: companyId,
							relationship_type: relationshipTypes.PROVIDER
						}
					})
				}).then(({result}: {result: providerResponse[]}) => {
					if(result.length > 0){
						const formatedProviders: provider[] = result.map((provider: providerResponse) => {
							return {
								id: provider.relationship_id,
								relationshipId: provider.relationship_id,
								description: provider.description,
								name: provider.name,
								email: provider.email,
								phone: provider.phone,
								editAccess: provider.edit_access,
								deleteAccess: provider.delete_access,
								avatar: provider.avatar,
								labels: provider.labels
							}
						})
						setProviderList([...formatedProviders, ...providerList])
					}
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('phoneNumber'),
					key: 'phone',
					default: '',
					type: String
				},
				{
					title: t('email'),
					key: 'email',
					default: '',
					type: String
				}
			]}
		/>
	)
}

export default ProviderTablePreviewModal