//styles
import './myTasksCalendar.scss'

//react
import { FC, useEffect, useState } from 'react'

// components
import Calendar from '../../../general/calendar/calendar'
import TaskPreviewCard from './taskPreviewCard/taskPreviewCard'
import MyTasksCalendarMonth from './myTasksCalendarMonth/myTasksCalendarMonth'
import MyTasksCalendarDay from './myTasksCalendarDay/myTasksCalendarDay'
import MyTasksCalendarWeekly from './myTasksCalendarWeekly/myTasksCalendarWeekly'
import SwitchButtons from '../../../general/switchButtons/switchButtons'

//types
import { task } from '../../../../types/general/generalTypes'

//translation
import {useTranslation} from 'react-i18next'


type myTasksCalendarProps = {
    loadTasks: () => Promise<task<Date>[]>
	showClosed?:boolean
}

const MyTasksCalendar: FC<myTasksCalendarProps> = ({ loadTasks, showClosed }) => {
	const {t}=useTranslation('',{keyPrefix:'general.calendar.switchButtons'})

	const switchButtons = [
		{
			id: 0,
			text: t('daily')
		},
		{
			id: 1,
			text: t('weekly')
		},
		{
			id: 2,
			text: t('monthly')
		}
	]
	const [activeSwitchButtonId, setActiveSwitchButtonId] = useState(0)
	const [ date, setDate ] = useState(new Date())
	const [ tasks, setTasks ] = useState<task<Date>[]>([] )
	const [ filteredTasks, setFilteredTasks ] = useState<task<Date>[]>([])

	useEffect(()=>{
		setFilteredTasks(tasks.filter((task) => task.startDate !== undefined && task.endDate !== undefined ).filter((task) => showClosed ? true : !task.isClosed))
	}, [showClosed, tasks])

	useEffect(() => {
		loadTasks()
			.then((tasks) => {
				setTasks([...tasks])
			})
	}, [date, activeSwitchButtonId])

	return (
		<div className="my-tasks-calendar">
			<div className="my-tasks-calendar-preveiw-container">
				<div className="my-tasks-calendar-preveiw-calendar-container">
					<SwitchButtons switchButtons={switchButtons} activeSwitchButtonId={activeSwitchButtonId} setActiveSwitchButtonId={setActiveSwitchButtonId} />
					<Calendar onSelectDate={(date) => {setDate(date)}} calendarDate={date} />
				</div>
				<div className="my-tasks-calendar-preveiw-tasks-container">
					{
						filteredTasks.map((task) => {
							return <TaskPreviewCard {...task} />
						})
					}
				</div>
			</div>

			{
				activeSwitchButtonId === 0 ?
					<MyTasksCalendarDay date={date} tasks={filteredTasks}/>
					: activeSwitchButtonId === 1 ?
						<MyTasksCalendarWeekly date={date} tasks={filteredTasks} />
						: <MyTasksCalendarMonth date={date} tasks={filteredTasks}/>


			}

		</div>
	)
}

export default MyTasksCalendar