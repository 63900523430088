// styles
import './appFeature.scss'

// react
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router'

// components
import ScrollAnimation from '../../../general/scrollAnimation/scrollAnimation'
import Button from '../../../../../../components/general/button/button'
import Title from '../../../general/title/title'
import Subtitle from '../../../general/subtitle/subtitle'
import Text from '../../../general/text/text'

type appFeatureProps = {
	smallText: string,
	heading: string,
	text: string,
	buttons: {
		text: string
		link: string
		variant: 'solid' | 'outlined'
	}[]
	contentRight?: boolean
	image: string
	extras: React.ReactNode[]
}

const AppFeature: FC<appFeatureProps> = ({smallText, heading, text, buttons, contentRight = false, image, extras}) => {
	const ref = useRef(null)
	const navigate = useNavigate()
	return (
		<div className="app-feature-wrapper">
			<div className={`app-feature ${contentRight ? 'app-feature-rtl' : 'app-feature-ltr'}`} ref={ref}>
					
				<div className='app-feature-content'>
					<ScrollAnimation direction={contentRight ? 'to-left' : 'to-right'}>
						<div className='app-feature-content-container'>
							<Subtitle>{smallText}</Subtitle>
							<Title>{heading}</Title>
							<Text>{text}</Text>
							<div className='app-feature-buttons'>
								{buttons.map((button, idx) => 
									<div key={`app-feature-button-${idx}`} className={`app-feature-button-${idx+1}`}>
										<Button
											text={button.text}
											onClick={()=> navigate(button.link)}
											active={true}
											outlined={button.variant === 'outlined'}
										/>
									</div>
								)}
							</div>
						</div>
					</ScrollAnimation>
				</div>
					
				
				<div className='app-feature-img-container'>
					<ScrollAnimation direction={contentRight ? 'to-right' : 'to-left'}>
						<img src={image}/>
					</ScrollAnimation>
				</div>
			</div>
			{extras.map((elem) => {
				return elem
			})}
		</div>
	)
}

export default AppFeature