//styles
import './authenticationHeader.scss'

//react
import { FC } from 'react'
import { Link } from 'react-router-dom'

type authenticationHeaderProps = {
    title: string
    subtitle:{
        text: string
        link: {
            url: string
            text: string
        }
    } 
}

const AuthenticationHeader: FC<authenticationHeaderProps> = ({ title, subtitle }) => {
	return (
		<div className="authentication-header">
			<h2 className="authentication-header-title">{title}</h2>
			<div className="authentication-subtitle">
				<p>{subtitle.text}</p>
				<Link to={subtitle.link.url}>
					<p className='authentication-link-text'>{subtitle.link.text}</p>
				</Link>
			</div>
		</div>
	)
}

export default AuthenticationHeader