export const forecastIcon = <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11.89 10.1C10.11 9.51 9.25 9.14 9.25 8.2C9.25 7.18 10.36 6.81 11.06 6.81C12.37 6.81 12.85 7.8 12.96 8.15L14.54 7.48C14.39 7.03 13.72 5.56 12 5.24V4H10V5.26C7.52 5.82 7.51 8.12 7.51 8.22C7.51 10.49 9.76 11.13 10.86 11.53C12.44 12.09 13.14 12.6 13.14 13.56C13.14 14.69 12.09 15.17 11.16 15.17C9.34 15.17 8.82 13.3 8.76 13.08L7.1 13.75C7.73 15.94 9.38 16.53 10 16.71V18H12V16.76C12.4 16.67 14.9 16.17 14.9 13.54C14.9 12.15 14.29 10.93 11.89 10.1ZM2 20H0V14H6V16H3.52C5.13 18.41 7.88 20 11 20C13.3869 20 15.6761 19.0518 17.364 17.364C19.0518 15.6761 20 13.3869 20 11H22C22 17.08 17.08 22 11 22C7.28 22 3.99 20.15 2 17.33V20ZM0 11C0 4.92 4.92 0 11 0C14.72 0 18.01 1.85 20 4.67V2H22V8H16V6H18.48C16.87 3.59 14.12 2 11 2C8.61305 2 6.32387 2.94821 4.63604 4.63604C2.94821 6.32387 2 8.61305 2 11H0Z" fill="#444444" />
</svg>


export const justifyMiddleIcon = <svg viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="20" height="2" fill="#444444" />
	<rect x="3" y="4" width="14" height="2" fill="#444444" />
	<rect x="5" y="8" width="10" height="2" fill="#444444" />
</svg>

export const forwardIcon = <svg  viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0.251302 10.4216C0.12283 10.2931 0.0585938 10.141 0.0585938 9.96528C0.0585938 9.78987 0.12283 9.63793 0.251302 9.50946L4.01554 5.74522L0.238455 1.96814C0.118547 1.84823 0.0585938 1.69835 0.0585938 1.51849C0.0585938 1.33863 0.12283 1.18446 0.251302 1.05599C0.379774 0.927517 0.531885 0.863281 0.707635 0.863281C0.883043 0.863281 1.03498 0.927517 1.16345 1.05599L5.48012 5.3855C5.53151 5.43689 5.568 5.49256 5.58958 5.55252C5.61082 5.61247 5.62144 5.67671 5.62144 5.74522C5.62144 5.81374 5.61082 5.87798 5.58958 5.93793C5.568 5.99789 5.53151 6.05356 5.48012 6.10495L1.15061 10.4345C1.0307 10.5544 0.883043 10.6143 0.707635 10.6143C0.531885 10.6143 0.379774 10.5501 0.251302 10.4216Z" fill="white" />
</svg>


export const alignPipeline = <svg viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0 1H12M0 5H12" stroke="#444444" strokeWidth="1.5" />
</svg>

export const tickIcon = <svg viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.33268 5.33333L8.24935 0.416666C8.40213 0.263889 8.59657 0.1875 8.83268 0.1875C9.06879 0.1875 9.26324 0.263889 9.41601 0.416666C9.56879 0.569444 9.64518 0.763888 9.64518 1C9.64518 1.23611 9.56879 1.43056 9.41601 1.58333L3.91602 7.08333C3.74935 7.25 3.5549 7.33333 3.33268 7.33333C3.11046 7.33333 2.91602 7.25 2.74935 7.08333L0.582682 4.91667C0.429904 4.76389 0.353516 4.56944 0.353516 4.33333C0.353516 4.09722 0.429904 3.90278 0.582682 3.75C0.73546 3.59722 0.929904 3.52083 1.16602 3.52083C1.40213 3.52083 1.59657 3.59722 1.74935 3.75L3.33268 5.33333Z" fill="#4970B5" />
</svg>

export const multiplePipelinesIcon = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
	<g clip-path="url(#clip0_4521_33089)">
		<path d="M2.8125 0C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.8125C0 12.5584 0.296316 13.2738 0.823762 13.8012C1.35121 14.3287 2.06658 14.625 2.8125 14.625H11.8125C12.5584 14.625 13.2738 14.3287 13.8012 13.8012C14.3287 13.2738 14.625 12.5584 14.625 11.8125V2.8125C14.625 2.06658 14.3287 1.35121 13.8012 0.823762C13.2738 0.296316 12.5584 0 11.8125 0H2.8125ZM1.125 11.8125V4.5H13.5V11.8125C13.5 12.2601 13.3222 12.6893 13.0057 13.0057C12.6893 13.3222 12.2601 13.5 11.8125 13.5H2.8125C2.36495 13.5 1.93572 13.3222 1.61926 13.0057C1.30279 12.6893 1.125 12.2601 1.125 11.8125ZM1.125 3.375V2.8125C1.125 2.36495 1.30279 1.93572 1.61926 1.61926C1.93572 1.30279 2.36495 1.125 2.8125 1.125H11.8125C12.2601 1.125 12.6893 1.30279 13.0057 1.61926C13.3222 1.93572 13.5 2.36495 13.5 2.8125V3.375H1.125ZM6.1875 18C5.53913 18.0001 4.91063 17.7762 4.40837 17.3662C3.9061 16.9562 3.56092 16.3853 3.43125 15.75H4.59562C4.712 16.0792 4.92759 16.3641 5.2127 16.5656C5.4978 16.7671 5.83838 16.8752 6.1875 16.875H13.5C14.3951 16.875 15.2536 16.5194 15.8865 15.8865C16.5194 15.2536 16.875 14.3951 16.875 13.5V6.1875C16.8752 5.83838 16.7671 5.4978 16.5656 5.2127C16.3641 4.92759 16.0792 4.712 15.75 4.59562V3.43125C16.3853 3.56092 16.9562 3.9061 17.3662 4.40837C17.7762 4.91063 18.0001 5.53913 18 6.1875V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H6.1875Z" fill="#E8EBEE"/>
	</g>
	<defs>
		<clipPath id="clip0_4521_33089">
			<rect width="18" height="18" fill="white"/>
		</clipPath>
	</defs>
</svg>


