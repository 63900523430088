
// react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import BudgetBillList from '../../../components/finance/budget/budgetBillList/budgetBllList'
import ErrorList from '../../../components/general/errorList/errorList'
import PayBillConfirmationModal from '../../../components/general/modals/bill/payBillConfirmationModal/payBillConfirmationModal'
import BudgetDetails from '../../../components/finance/budget/budgetDetails/budgetDetails'

// translation
import { useTranslation } from 'react-i18next'

// types
import { button, error, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

enum budgetSection {
	BudgetDetails = 'BudgetDetails',
	BudgetBillList = 'BudgetBillList',
	BudgetErrorsList = 'BudgetErrorsList'
}


const Budget: FC = () => {
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeBudgetSection, setActiveBudgetSection] = useState<string>('BudgetDetails')
	const [ errors, setErrors ] = useState<error[]>([])
	const [ budgetName, setBudgetName ] = useState('')
	const {t} = useTranslation('', {keyPrefix: 'finance.budget'})

	const { budgetId } = useParams()

	const titleUrls = [
		{
			url: '/ri-business/finance/budget-list',
			title: t('budgets')
		}, 
		{
			url: location.pathname,
			title: budgetName
		}
	]

	const infoPopup = {
		content: [{
			title: 'budget_info_title',
			description: 'budget_info_description',
			link: '/ri-manual/finance'
		}],
		type: infoPopupTypes.INFO
	}

	const accountTabButtons: button[] = [
		{
			active: activeBudgetSection === budgetSection.BudgetDetails,
			text: t('details'),
			onClick: () => setActiveBudgetSection(budgetSection.BudgetDetails)
		},
		{
			active: activeBudgetSection === budgetSection.BudgetBillList,
			text: t('bills'),
			onClick: () => setActiveBudgetSection(budgetSection.BudgetBillList),
		},
		{
			active: activeBudgetSection === budgetSection.BudgetErrorsList,
			text: t('errors'),
			onClick: () => setActiveBudgetSection(budgetSection.BudgetErrorsList),
		},
	]

	const renderTab = () => {
		switch (activeBudgetSection) {
		case budgetSection.BudgetDetails: 
			return <BudgetDetails setErrors={setErrors} setBudgetName={setBudgetName}/>
		case budgetSection.BudgetBillList:
			return <BudgetBillList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} /> 
		case budgetSection.BudgetErrorsList:
			return <ErrorList errors={errors}/> 
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true		
			}}
			tabButtons={accountTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="account-container">
				{
					renderTab()
				}
			</div>

			<PayBillConfirmationModal/>

		</Layout>
	)
}

export default Budget