//icons
import { deleteIcon } from '../../general/generalIcons'
import { purchase, sale } from '../../general/generalIcons'

//components
import StatusLabel from '../../../components/general/statusLabel/statusLabel'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'

//types
import { transaction, transactionType, transactionStatusColor, expenseCategory, billResponse, bill} from '../../../types/finance/general'

//other
import { currencyToFormattedString, formatAccountResponse, formatDate, formatEmployeeResponse, formatErrorResponse } from '../../general/generalFunctions'

export const formatTransactionTableData = (transactions: transaction[], handleDelete: (transactionId: number) => void) => {
	if(transactions[0]?.deleteAccess){
		return transactions.map(transaction => {
			return {
				icon: <div className='account-transaction-icon'>{transaction.transactionType === transactionType.expense ? purchase : sale}</div>,
				description: transaction.description,
				amount: currencyToFormattedString(transaction.amount, transaction.currency),
				date: formatDate(transaction.date, true, true),
				transaction_type: <StatusLabel
					color={transactionStatusColor[transaction.transactionType].color}
					bg={transactionStatusColor[transaction.transactionType].bg}
					text={transaction.transactionType} />,
				delete: <SwitchButtons
					switchButtons={[
						{
							id: 1,
							onClick: () => {
								handleDelete(transaction.id)
							},
							icon: deleteIcon
						}
					]}
					orientation='vertical'
				/>
			}
		})
	}else{
		return transactions.map(transaction => {
			return {
				icon: <div className='account-transaction-icon'>{transaction.transactionType === transactionType.expense ? purchase : sale}</div>,
				description: transaction.description,
				amount: currencyToFormattedString(transaction.amount, transaction.currency),
				date: formatDate(transaction.date, true, true),
				transaction_type: <StatusLabel
					color={transactionStatusColor[transaction.transactionType].color}
					bg={transactionStatusColor[transaction.transactionType].bg}
					text={transaction.transactionType} />,
                
			}
		})
	}
}

export const formatBillResponse = (bill: billResponse): bill => {
	return {
		id: bill.id,
		amount: bill.amount,
		currency: bill.currency,
		endDate: bill.due_date ? new Date(bill.due_date * 1000) : null,
		title: bill.title,
		description: bill.description,
		category: expenseCategory[bill.category as keyof typeof expenseCategory],
		status: bill.status,
		deleteAccess: bill.delete_access,
		transactionType: transactionType[bill.bill_type as keyof typeof transactionType],
		account: bill.account ? formatAccountResponse(bill.account) : null,
		employee: bill.employee ? formatEmployeeResponse(bill.employee) : null,
		errors: bill.errors.map(formatErrorResponse)
	}
}