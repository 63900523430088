//styles
import { deleteIcon } from '../../../../assets/general/generalIcons'

//components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import Pagination from '../../../general/pagination/pagination'
import CounterpartyCard from '../../../general/counterpartyCard/counterpartyCard'
import CreateProviderAddressModal from './modals/createProviderAddressModal/createProviderAddressModal'
import DeleteProviderAddressModal from './modals/deleteProviderAddressModal/deleteProviderAddressModal'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router'

//types
import { filterBar } from '../../../../types/general/generalTypes'
import { address, addressResponse } from '../../../../types/sales/customerTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleProviderAddressesUrl } from '../../../../utils/urls/purchases/provider'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateProviderAddressModalIsOpen, setDeleteProviderAddressId, setDeleteProviderAddressModalIsOpen } from '../../../../redux/purchase/provider/modals'

//other
import { getItemsPerColumn } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'


type providerAddressListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const ProviderAddressList: FC<providerAddressListProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerDocuments' })

	const [addresses, setAddresses] = useState<address[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const {relationshipId} = useParams()

	const dispatch = useAppDispatch()

	const loadFilterBar = () => {
		setFilterBar({
			addButton: {
				text: t('add'),
				active: true,
				onClick: () => {
					dispatch(setCreateProviderAddressModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string) => {
		const { result } = await authorizedRequest(singleProviderAddressesUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}`, 'GET')

		const formatedAddresses: address[] = result.map((address: addressResponse) => {
			return address
		})
		return formatedAddresses
	}

	const onSearch = (searchValue: string) => {
		setPage(1)
		setSearchRequest(searchValue)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue)
			.then((result) => {
				if (result.length > 0) {
					setAddresses([...addresses, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest)
				.then((result) => {
					if (result.length > 0) {
						setAddresses([...addresses, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (

		<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading}>
			<ColumnLayout amount={itemsPerColumn}>
				{addresses.map(address => 
					(<CounterpartyCard 
						key={`provider-address-${address.id}`} 
						name={address.address}
						actions={[
							{id: 1, icon: deleteIcon, onClick: ()=> {
								dispatch(setDeleteProviderAddressModalIsOpen(true))
								dispatch(setDeleteProviderAddressId(address.id))
							}}]}
						{...address}
					/>)
				)}
			</ColumnLayout>
			<CreateProviderAddressModal addresses={addresses} setAddresses={setAddresses} />
			<DeleteProviderAddressModal addresses={addresses} setAddresses={setAddresses} />
		</Pagination>
	)
}

export default ProviderAddressList