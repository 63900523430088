//styles and icons
import './statusLabel.scss'

//react
import { FC } from 'react'  

//translation
import { useTranslation } from 'react-i18next'

type statusLabelProps = {
	color: string
	bg: string
	text: string
}

const StatusLabel: FC<statusLabelProps> = ({ color, bg, text }) => {
	return (
		<div className='status-label' style={{
			color: color,
			background: bg
		}}>
			{text}
		</div>
	)
}

export default StatusLabel