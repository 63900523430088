//components
import Layout from '../../../components/general/layout/layout'
import InventoryManagementMetrics from '../../../components/warehouse/warehouseStats/inventoryManagementMetrics/inventoryManagementMetrics'

//react
import { useState } from 'react'

//types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

enum warehouseStatsSection {
	InventoryManagementMetrics='Inventory Management Metrics',
}

const WarehouseStats = () => {
	const [activeWarehouseStatsSection, setActiveWarehouseStatsSection] = useState(warehouseStatsSection.InventoryManagementMetrics)

	const {t} = useTranslation('', {keyPrefix: 'warehouse.statistics'})

	const titleUrls = [
		{
			url: '/ri-business/warehouse/statistics',
			title: t('warehouseStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'warehouse_stats_info_title',
			description: 'warehouse_stats_info_description',
			link: '/ri-manual/warehouse/statistics'
		}],
		type: infoPopupTypes.INFO
	}

	const warehouseStatsButtons: button[] = [
		{
			active: activeWarehouseStatsSection === warehouseStatsSection.InventoryManagementMetrics,
			text: t('InventoryManagementMetrics'),
			onClick: () => {setActiveWarehouseStatsSection(warehouseStatsSection.InventoryManagementMetrics)}
		},
	]

	const renderTab = () => {
		switch(activeWarehouseStatsSection) {
		case warehouseStatsSection.InventoryManagementMetrics:
			return <InventoryManagementMetrics/>
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: t('warehouseStatistics')
			}}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			tabButtons={warehouseStatsButtons}
		>
			{renderTab()}
		</Layout>
	)
}

export default WarehouseStats