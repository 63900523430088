// styles
import './columnLayout.scss'
import { FC, ReactNode } from 'react'

type ColumnLayoutProps = {
	children: ReactNode
	amount?: number	
	gap?: string
}

const ColumnLayout: FC<ColumnLayoutProps> = ({children, amount}) => {
	return (
		<div className='column-layout' style={{gridTemplateColumns: `repeat(${amount ?? 2}, ${100/(amount ?? 2)-1}%)`}}>
			{children}
		</div>
	)
}

export default ColumnLayout 