//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { averageSales, salesGrowthRate, totalSales } from '../../../../utils/urls/sales/saleStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const SalesFigures = () => {
	const { t } = useTranslation('', { keyPrefix: 'sales.salesStats.salesFiguresTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadTotalSales = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(totalSales(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadAverageSales = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(averageSales(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadSalesGrowthRate = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(salesGrowthRate(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('total_sales')} loadData={loadTotalSales} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('average_sales_order')} loadData={loadAverageSales} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('sales_growth_rate')} loadData={loadSalesGrowthRate} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>

		</div>

	)
}

export default SalesFigures