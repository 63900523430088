//styles
import './headerWithLines.scss'

//react
import { FC, ReactNode } from 'react'

//components
import AnimatedLines from '../animatedLines/animatedLines'

type headerWithLinesProps = {
    children: ReactNode
}

const HeaderWithLines: FC<headerWithLinesProps> = ({ children }) => {
	return (
		<div className="header-with-lines">
			<div className='header-with-lines-item'>
				<AnimatedLines numberOfLines={1} width='18vw' />
				<AnimatedLines numberOfLines={1} width='12vw' />
			</div>
			{children}
			<div className='header-with-lines-item'>
				<AnimatedLines numberOfLines={1} width='18vw' />
				<AnimatedLines numberOfLines={1} width='12vw' />
			</div>
		</div>
	)
}
export default HeaderWithLines