// styles and icons
import './createTaskModal.scss'

//react
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Modal from '../../modal/modal'
import InputField from '../../../inputField/inputField'
import CalendarContainer from '../../../calendarContainer/calendarContainer'
import Dropdown from '../../../dropdown/dropdown'
import TextArea from '../../../textArea/textArea'
import CreateWarehouseAction from '../../../warehouseAction/createWarehouseAction/createWarehouseAction'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateTaskModalIsOpen, setCreateTaskModalStageId } from '../../../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { getLabelsUrl } from '../../../../../utils/old_urls/general/generalUrls'
import { companySearchEmployeesUrl } from '../../../../../utils/urls/employees/search'
import { createTaskUrl, singleTaskUrl } from '../../../../../utils/urls/projects/task'
import { companyProjectsUrl, singleProjectUrl } from '../../../../../utils/urls/projects/project'
import { getSkillsUrl } from '../../../../../utils/urls/general/positions'

// types
import {
	selectionOption,
	dropdownOption,
	labelResponse,
	task,
	taskResponse,
	inventoryItem,
	shipment,
	dragStage,
	employeeResponse,
	skillResponse,
	infoPopupTypes,
} from '../../../../../types/general/generalTypes'

//other
import { formatTaskResponse } from '../../../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'

type taskErrors = {
	title?: string
	destination?: string
	origin?: string
	items?: string
	project?: string
}

type createTaskModalProps = {
	stages?: dragStage<task<Date | undefined>>[]
	setStages?: (value: dragStage<task<Date | undefined>>[]) => void
	tasks?: task<Date | undefined>[]
	setTasks?: (value: task<Date | undefined>[]) => void
	shipments?: shipment[]
	setShipments?: (value: shipment[]) => void
	movingStages?: dragStage<task<Date | undefined>>[]
	setMovingStages?: (value: dragStage<task<Date | undefined>>[]) => void
	isWarehouseSectionShow?: boolean
}

const CreateTaskModal: FC<createTaskModalProps> = ({ movingStages, setMovingStages, shipments, setShipments, stages, setStages, tasks, setTasks, isWarehouseSectionShow = false }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.modals.task.createTaskModal' })
	const tSkill = useTranslation('', { keyPrefix: 'general.skills' }).t

	const { projectId, taskId } = useParams()
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [startDate, setStartDate] = useState<Date>()
	const [endDate, setEndDate] = useState<Date>()
	const [projectIdState, setProjectId] = useState<number | undefined>(Number(projectId))
	const [executorSearch, setExecutorSearch] = useState('')
	const [selectedExecutors, setSelectedExecutors] = useState<selectionOption[]>([])

	const [labelSearchRequest, setLabelSearchRequest] = useState('')
	const [selectedLabels, setSelectedLabels] = useState<selectionOption[]>([])

	const [selectedProject, setSelectedProject] = useState<dropdownOption>()

	const [errors, setErrors] = useState<taskErrors>({})

	const [skillSearchRequest, setSkillSearchRequest] = useState<string>('')
	const [selectedSkillOptions, setSelectedSkillOptions] = useState<selectionOption[]>([])

	const [selectedWarehouseOriginOption, setSelectedWarehouseOriginOption] = useState<dropdownOption | null>(null)
	const [selectedWarehouseDestinationOption, setSelectedWarehouseDestinationOption] = useState<dropdownOption | null>(null)

	const [selectedAddressOriginOption, setSelectedAddressOriginOption] = useState<dropdownOption | null>(null)
	const [selectedAddressDestinationOption, setSelectedAddressDestinationOption] = useState<dropdownOption | null>(null)

	const [selectedInventoryItems, setSelectedInventoryItems] = useState<inventoryItem[]>([])
	
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { modalIsOpen, stageId } = useAppSelector(state => state.generalModals.createTaskModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateTaskModalStageId(undefined))
		dispatch(setCreateTaskModalIsOpen(false))
		setTitle('')
		setStartDate(undefined)
		setEndDate(undefined)
		setDescription('')
		setSelectedWarehouseOriginOption(null)
		setSelectedWarehouseDestinationOption(null)
		setSelectedInventoryItems([])
		setSelectedExecutors([])
		setErrors({})

		
	}

	const projectPopup = {
		content: [
			{
				title: 'create_project_info_title',
				description: 'create_project_info_description',
				link: '',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const labelsPopup = {
		content: [
			{
				title: 'create_label_info_title',
				description: 'create_label_info_description',
				link: '/ri-business/settings',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const executorsPopup = {
		content: [
			{
				title: 'create_executor_info_title',
				description: 'create_executor_info_description',
				link: '/ri-business/employees/hierarchy',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const loadTaskData = async () => {
		if (!taskId) {
			return
		}
		const { result } = await authorizedRequest(singleTaskUrl(Number(taskId)), 'GET')

		setProjectId(result.project.id)
	}

	useEffect(() => {
		loadTaskData()
	}, [taskId])


	const getEmployees = (query: string, page: number) => {

		return authorizedRequest(companySearchEmployeesUrl(companyId) + `?needle=${query}&page=${page}&per_page=${10}`, 'GET')
			.then((response) => {
				const { result }: { result: employeeResponse[] } = response

				const employeesOptionList: selectionOption[] = result.map(employee => {
					return {
						id: employee.employee_id,
						text: employee.name,
						avatar: employee.avatar
					}
				})

				return employeesOptionList

			})

	}

	const getLabels = (query: string, page: number) => {
		return authorizedRequest(getLabelsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				const { result }: { result: labelResponse[] } = response

				const labelsOptionList: selectionOption[] = result.map(label => {
					return {
						id: label.id,
						text: label.text,
						bg: label.color,
						color: '#fff'
					}
				})

				return labelsOptionList

			})
	}

	const getProjects = (query: string, page: number) => {
		return authorizedRequest(companyProjectsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				const { result } = response

				return result.map((project: { title: string, project_id: number }) => {
					return {
						title: project.title,
						key: `${project.project_id}`
					}
				})

			})
	}

	const getProject = () => {
		if (projectIdState) {
			return authorizedRequest(singleProjectUrl(projectIdState), 'GET')
				.then((response) => {
					const { result } = response

					setSelectedProject({
						key: result.project_id,
						title: result.title
					})	
				})
		}
	}

	useEffect(()=>{
		getProject()
	}, [projectIdState])


	const getSkills = (query: string, page: number) => {
		return authorizedRequest(getSkillsUrl() + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				const { result }: { result: skillResponse[] } = response
				const skillsOptionList: selectionOption[] = result.map(skill => {
					return {
						id: skill.id,
						text: tSkill(skill.name),
					}
				})

				return skillsOptionList

			})
	}

	const checkErrors = () => {
		let hasErrors = false

		if (!selectedProject && projectId === undefined) {
			hasErrors = true
			setErrors({ ...errors, project: t('enterTheProjectPlease') })
		}

		if (title === '') {
			hasErrors = true
			setErrors({ ...errors, title: t('enterTheTitlePlease') })
		}

		
		if (isWarehouseSectionShow && (selectedWarehouseOriginOption === undefined && selectedAddressOriginOption === undefined)) {
			setErrors(prevErrors => ({ ...prevErrors, origin: t('pleaseSelectTheOriginWarehouse') }))
			hasErrors = true
		}
		if (isWarehouseSectionShow && (selectedWarehouseDestinationOption === undefined && selectedAddressDestinationOption === undefined)) {
			setErrors(prevErrors => ({ ...prevErrors, destination: t('pleaseSelectDestinationWarehouse') }))
			hasErrors = true
		}
		if (isWarehouseSectionShow && selectedInventoryItems.length === 0) {
			setErrors(prevErrors => ({ ...prevErrors, destination: t('pleaseSelectTheItemsYouArePlanningToShip') }))
			hasErrors = true
		}

		return hasErrors
	}

	const createTask = async () => {
		const hasErrors = checkErrors()
		if (hasErrors) return
		
		setIsLoading(true)

		const body = {
			project_id: projectIdState ? Number(projectIdState) : Number(selectedProject?.key),
			stage_id: stageId || 1,
			title: title,
			description: description,
			date: startDate,
			due_date: endDate,
			executors: selectedExecutors.map(executor => executor.id),
			skills: selectedSkillOptions.map(({ id }) => id),
			labels: selectedLabels.map(({ id }) => id),
			origin_warehouse_id: selectedWarehouseOriginOption ? Number(selectedWarehouseOriginOption.key) : null,
			destination_warehouse_id: selectedWarehouseDestinationOption ? Number(selectedWarehouseDestinationOption.key) : null,
			origin_address_id: selectedAddressOriginOption ? Number(selectedAddressOriginOption.key) : null,
			destination_address_id: selectedAddressDestinationOption ? Number(selectedAddressDestinationOption.key) : null,
			items: selectedInventoryItems.map((item) => {
				return {
					id: item.inventoryItemId,
					quantity: item.quantity,
					cost: item.cost?.amount,
					currency: item.cost?.currency
				}
			}),
			parent_task_id: taskId ? taskId : null
		}


		const response = await authorizedRequest(createTaskUrl, 'POST', 'accessToken', body)

		const result = response.result as taskResponse

		if(tasks && setTasks && stages && setStages){

			setStages([...stages.map((stage) => ({...stage, quantity: stage.stageId === stageId ? stage.quantity + 1 : stage.quantity }))])

			setTasks([...tasks, formatTaskResponse(result)])
		}

		setIsLoading(false)
		closeModal()
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('createATask')}
			submitButton={{
				text: t('createATask'),
				onClick: createTask
			}}
			isLoading={isLoading}
		>
			<div className="add-task-modal-wrapper">
				<div className="add-task-modal-inputs-container">
					<InputField
						type='text'
						value={title}
						label={t('title')}
						placeholder={t('title')}
						onChange={(e) => {
							setErrors({})
							setTitle(e.target.value)
						}}
						error={errors.title}
					/>
					<InputField
						type='text'
						popup={executorsPopup}
						value={executorSearch}
						label={t('selectExecutors')}
						onChange={(e) => {
							setExecutorSearch(e.target.value)
						}}
						selection={{
							loadSelectionOptions: getEmployees,
							selectedList: selectedExecutors,
							setSelectedList: (options) => {
								setSelectedExecutors(options)
							}
						}}
					/>
					<InputField
						type='text'
						label={t('skills')}
						value={skillSearchRequest}
						onChange={(e: ChangeEvent<HTMLInputElement>) => setSkillSearchRequest(e.target.value)}
						selection={{
							loadSelectionOptions: getSkills,
							selectedList: selectedSkillOptions,
							setSelectedList: setSelectedSkillOptions
						}}
					/>
					<InputField
						popup={labelsPopup}
						type='text'
						label={t('labels')}
						value={labelSearchRequest}
						onChange={(e) => setLabelSearchRequest(e.target.value)}
						selection={{
							loadSelectionOptions: getLabels,
							selectedList: selectedLabels,
							setSelectedList: setSelectedLabels
						}}
					/>
					<Dropdown
						label={t('project')}
						popup={projectPopup}
						placeholder={t('project')}
						dropdownOptions={[]}
						defaultSelectedOption={selectedProject}
						onSelect={(option) => {
							setSelectedProject(option)
							setErrors({})
						}}
						loadOptions={getProjects}
						disabled={!!projectIdState}
						selectedOption={selectedProject}
						error={errors.project}
					/>

					<div className="add-task-modal-description">
						<TextArea value={description} setValue={(value) => {
							setDescription(value)
						}} label={t('description')} />
					</div>
				</div>

				<div className="add-task-modal-inputs-container">
					<CalendarContainer
						label={t('timeline')}
						popup={false}
						hasTime={true}
						startDate={{ date: startDate, setDate: setStartDate }}
						dueDate={{ date: endDate, setDate: setEndDate }}
					/>
				</div>
			</div>

			{isWarehouseSectionShow &&
				<CreateWarehouseAction
					selectedWarehouseDestinationOption={selectedWarehouseDestinationOption}
					selectedAddressOriginOption={selectedAddressOriginOption}
					selectedWarehouseOriginOption={selectedWarehouseOriginOption}
					selectedAddressDestinationOption={selectedAddressDestinationOption}
					selectedInventoryItems={selectedInventoryItems}
					setSelectedWarehouseOriginOption={setSelectedWarehouseOriginOption}
					setSelectedWarehouseDestinationOption={setSelectedWarehouseDestinationOption}
					setSelectedAddressOriginOption={setSelectedAddressOriginOption}
					setSelectedAddressDestinationOption={setSelectedAddressDestinationOption}
					setSelectedInventoryItems={setSelectedInventoryItems}
					originError={errors.origin}
					destinationError={errors.destination}
					itemsError={errors.items}
				/>
			}
		</Modal>
	)
}

export default CreateTaskModal