import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type providerListModalsState = {
    createProviderModal: {
        modalIsOpen: boolean
    }
	deleteProviderModal: {
		modalIsOpen: boolean
		relationshipId: number | null
	}
	importProviderFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    providerTablePreviewModal: {
        modalIsOpen: boolean
    }
	exportProviderFileModal : {
		modalIsOpen: boolean
	}
}

const initialState: providerListModalsState = {
	createProviderModal: {
		modalIsOpen: false
	},
	deleteProviderModal: {
		modalIsOpen: false,
		relationshipId: null
	},
	importProviderFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	providerTablePreviewModal: {
		modalIsOpen: false
	},
	exportProviderFileModal : {
		modalIsOpen: false
	}
}

export const providerListModalsSlice = createSlice({
	name: 'providerListModalsSlice',
	initialState,
	reducers : {
		setCreateProviderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createProviderModal.modalIsOpen = action.payload
		},
		setDeleteProviderModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteProviderModal.modalIsOpen = action.payload
		},
		setDeleteProviderRelationshipId: ( state, action: PayloadAction<number | null>) => {
			state.deleteProviderModal.relationshipId = action.payload
		},
		setImportProviderFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importProviderFileSelectorModal.modalIsOpen = action.payload
		},
		setImportProviderFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importProviderFileSelectorModal.file = action.payload
		},
		setProviderTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerTablePreviewModal.modalIsOpen = action.payload
		},
		setExportProviderFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportProviderFileModal.modalIsOpen = action.payload
		},
	}
})

export const { 
	setCreateProviderModalIsOpen, 
	setDeleteProviderModalIsOpen, 
	setDeleteProviderRelationshipId,
	setImportProviderFileSelectorModalIsOpen,
	setImportProviderFileSelectorModalFile,
	setProviderTablePreviewModalIsOpen,
	setExportProviderFileModalIsOpen
} = providerListModalsSlice.actions

export default providerListModalsSlice.reducer
