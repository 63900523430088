//styles
import './createWarehouseModal.scss'

//components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import LocationSelector from '../../../../general/locationSelector/locationSelector'

//react
import { useState, FC } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyWarehousesUrl } from '../../../../../utils/urls/warehouses/warehouses/warehouses'

//redux
import { setCreateWarehouseModalIsOpen } from '../../../../../redux/warehouse/warehouseList/modals'
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'

//types
import { warehouseResponse } from '../../../../../types/general/generalTypes'
import { warehouse } from '../../../../../types/warehouse/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type formErrors = {
	name?: string
}

type createWarehouseModalProps = {
	warehouses: warehouse[]
	setWarehouses: (value: warehouse[]) => void
}

const CreateWarehouseModal: FC<createWarehouseModalProps> = ({ warehouses, setWarehouses }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouseList.modals.createWarehouseModal' })

	const [warehouseName, setWarehouseName] = useState('')
	const [location, setLocation] = useState<{ lat: number, lng: number }>()
	const [address, setAddress] = useState('')
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [errors, setErrors] = useState<formErrors>({})

	const { modalIsOpen } = useAppSelector((state) => state.warehouseListModal.createWarehouseModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateWarehouseModalIsOpen(false))
		setWarehouseName('')
		setLocation(undefined)
	}

	const checkErrors = () => {
		let result = true

		if (warehouseName === '') {
			setErrors(prevErrors => ({ ...prevErrors, name: t('pleaseEnterTheName') }))
			result = false
		}

		if (location === undefined) {
			setErrors(prevErrors => ({ ...prevErrors, name: t('pleaseChooseTheLocation') }))
			result = false
		}

		return result
	}

	const createWarehouse = async () => {
		setErrors({})
		setIsLoading(true)

		if (checkErrors()) {
			const { result } = await authorizedRequest(companyWarehousesUrl(companyId), 'POST', 'accessToken', {
				data: [{
					name: warehouseName,
					latitude: location?.lat,
					longitude: location?.lng,
					address: address
				}]
			})
			if (result.length > 0) {
				const formatedWarehouses: warehouse[] = result.map((warehouse: warehouseResponse) => {
					return {
						id: warehouse.id,
						name: warehouse.name,
						coords: {
							lat: warehouse.coords.lat,
							lng: warehouse.coords.lng
						},
						address: warehouse.address,
						shipmentAmount: warehouse.shipment_amount,
						movingAmount: warehouse.moving_amount,
						worth: warehouse.worth || [],
						editAccess: warehouse.edit_access,
						deleteAccess: warehouse.delete_access
					}
				})

				setWarehouses([...formatedWarehouses, ...warehouses])
			}
			setIsLoading(false)
			closeModal()
		}
	}

	return (
		<Modal
			title={t('createWarehouse')}
			open={modalIsOpen}
			closeModal={closeModal}
			submitButton={{ text: t('create'), onClick: createWarehouse }}
			isLoading={isLoading}
		>
			<div className="create-warehouse">
				<InputField error={errors.name} type="text" label={t('name')} placeholder={t('name')} value={warehouseName} onChange={(e) => setWarehouseName(e.target.value)} />
				<div className="warehouse-location-selector">
					<LocationSelector setAddress={setAddress} location={location} setLocation={setLocation} />
				</div>
			</div>
		</Modal>
	)
}

export default CreateWarehouseModal