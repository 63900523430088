// react
import { FC } from 'react'

// component
import FileExportModal from '../../../../general/modals/fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyBudgetsUrl } from '../../../../../utils/urls/finance/budget/budget'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportBudgetFileModalIsOpen } from '../../../../../redux/finance/budgetList/modals'

const ExportBudgetFileModal: FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.budgetListModal.exportBudgetFileModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setExportBudgetFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(companyBudgetsUrl(companyId) + `?export=True&export_type=${exportType}`, 'GET')

		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportBudgetFileModal