// styles and icons
import './confirmation.scss'
import { errorAlert } from '../../../assets/general/generalIcons'

// react
import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

// components
import Button from '../../../components/general/button/button'
import OTPInput from '../../../components/authentication/confirmation/otpInput/otpInput'
import AuthenticationHeader from '../../../components/authentication/general/authenticationHeader/authenticationHeader'
import Loading from '../../../components/general/loading/loading'

// network
import { unauthorizedRequest } from '../../../utils/queries'
import { VerifyRegUrl } from '../../../utils/old_urls/registrationUrls'

//translation
import { useTranslation } from 'react-i18next'

const AcceptInvitationConfirmation = () => {
	const { t } = useTranslation('', { keyPrefix: 'authentication.confirmation' })

	const [loading, setLoading] = useState(false)

	const { email, token } = useParams()
	const [error, setError] = useState<string>()
	const [otp, setOtp] = useState(['', '', '', '', '', ''])
	const navigate = useNavigate()

	useEffect(() => {
		setError(undefined)
	}, [otp])


	const handleSubmit = () => {
		const emptyField = otp.find(field => field === '')
		if (emptyField === '') {
			setError(t('enterTheCodePlease'))
			return
		}
		const otpCode = parseInt(otp.join(''))
		if (otpCode) {
			const confirm = unauthorizedRequest(VerifyRegUrl, 'POST', { token: localStorage.getItem('verificationToken'), code: otpCode })

			setLoading(true)
			confirm.then((responce) => {
				if (!responce.ok) {
					setError(t('somethingWentWrong'))
				} else {
					setError(undefined)
					if (token) {
						navigate(`/accept-invitation/login/${token}`)
					} else {
						navigate('/login')
					}
				}

			}).finally(() => {
				setLoading(false)
			})
		}
	}

	return (
		<div className="verification">
			<form
				onSubmit={(e) => {
					e.preventDefault()
					handleSubmit()
				}}
				className="verification-form">
				<div>
					<AuthenticationHeader
						title={t('verifyYourEmailAddress')}
						subtitle={{
							text: t('doYouWantToGoBack'),
							link: {
								text: t('return'),
								url: token ? `/accept-invitation/register/${token}` : '/register'
							}
						}}
					/>
					<div className="error-container">
						{error ? (
							<>
								{errorAlert}
								<p>{t('wrongVerificationCode')}</p>
							</>
						) : null}
					</div>
					<div className='form-email-info'>
						<p>{t('weSentAConfirmationCodeTo')}</p>
						<p className='email'>{email}</p>
					</div>
				</div>
				<div className="form-control">
					<OTPInput otp={otp} setOtp={setOtp} />
				</div>

				<Button onClick={handleSubmit} active={true} text={t('send')} />
			</form>
			{loading ? <Loading /> : null}
		</div>
	)
}

export default AcceptInvitationConfirmation
