// styles and icons
import './warehouseMoving.scss'
import {calendar, dashboardIcon, listIcon} from '../../../../assets/general/generalIcons'

// react
import {FC, useState} from 'react'
import { useParams } from 'react-router-dom'

// components
import TaskDashboard from '../../../general/taskDashboard/taskDashboard'
import TaskList from '../../../projects/general/taskList/taskList'
import MyTasksCalendar from '../../../projects/myTasks/myTasksCalendar/myTasksCalendar'
import SwitchButtons from '../../../general/switchButtons/switchButtons'
import CheckBox from '../../../general/checkBox/checkBox'
import FilterBar from '../../../general/layout/filterBar/filterBar'

// types
import {taskResponse, stageResponse, switchButton,filterBar} from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import {
	companyWarehouseActionsUrl, singleWarehouseActionsCalendarUrl,
	singleWarehouseActionsUrl
} from '../../../../utils/urls/warehouses/warehouseActions/warehouseActions'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

// other
import { formatTaskResponse, formatStageResponse } from '../../../../assets/projects/projectsFunctions'

//translation
import {useTranslation} from 'react-i18next'

const WarehouseMoving: FC = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { t } = useTranslation('', { keyPrefix: 'projects.myTasks' })
	const { warehouseId } = useParams()

	const companyId: number = userCompanyData?.companyId || -1

	const [activeToolBarIconId, setActiveToolBarIcon] = useState(0)

	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		}
	]

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()
	const [showClosed, setShowClosed] = useState(false)

	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singleWarehouseActionsUrl(Number(warehouseId) || -1) + `?type=moving&needle=${searchRequest}&page=${page}&status=${stageId}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(companyWarehouseActionsUrl(companyId) + `?type=moving&warehouse_id=${Number(warehouseId)}&show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadCalendar = async ()=>{
		const response = await authorizedRequest(singleWarehouseActionsCalendarUrl(Number(warehouseId) || -1)+'?type=moving', 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const renderToolbar = (activeId: number, showClosedTasks:boolean)=> {
		switch(activeId) {
		case 0:
			return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks}/>
		case 1:
			return <TaskList setFilterBar={setFilterBar} showClosed={showClosedTasks} loadTasks={loadTasks}/>
		case 2:
			return <MyTasksCalendar showClosed={showClosedTasks} loadTasks={loadCalendar} />
		}
	}

	return (
		<div className='moving-container'>
			{filterBar ? <FilterBar {...filterBar} /> : null}
			<div className='my-tasks-tool-bar'>
				<div className='my-tasks-tool-bar-left'>
					<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium'/>
					<CheckBox label={t('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev => !prev)}/>
				</div>
			</div>
			{renderToolbar(activeToolBarIconId, showClosed)}
		</div>
	)
}

export default WarehouseMoving