//styles and icons
import './videoPlayer.scss'

//react
import { FC, useEffect, useRef, useState } from 'react'

//components
import VideoPlayerPlayButton from './videoPlayerPlayButton/videoPlayerPlayButton'
import VideoPlayerProgressbar from './videoPlayerProgressbar/videoPlayerProgressbar'
import VideoPlayerVolumeControls from './videoPlayerVolumeControls/videoPlayerVolumeControls'
import VideoPlayerTimer from './videoPlayerTimer/videoPlayerTimer'
import VideoPlayerFullscreenButton from './videoPlayerFullscreenButton/videoPlayerFullscreenButton'

//other
import { blobToBase64 } from '../../../assets/general/generalFunctions'

type videoPlayerProps = {
	videoBlob: Blob | null,
	onLoaded?: () => void
}

const VideoPlayer: FC<videoPlayerProps> = ({ videoBlob, onLoaded }) => {

	const videoRef = useRef<HTMLVideoElement | null>(null)
    
	const [isPlaying, setIsPlaying] = useState(false)
    
	const [videoDuration, setVideoDuration] = useState(0)
	const [videoTimer, setVideoTimer] = useState(0)
	const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>() // interval for updating the timer
	const [showCentralButton, setShowCentralButton] = useState(false)
	const [centralButtonInterval, setCentralButtonInterval] = useState<NodeJS.Timer>()
	const [showControls, setShowControls] = useState(false)
	const [controlsTimerInterval, setControlsTimerInterval] = useState<NodeJS.Timer>()
	const [isFullscreen, setIsFullscreen] = useState(false)
	const [volumeBarIsOpen, setVolumeBarIsOpen] = useState(false)
	const [volumeBarTimerInterval, setVolumeTimerInterval] = useState<NodeJS.Timer>()
	const [volume, setVolume] = useState(0.5)

	const [isVolumeControlsOpen, setIsVolumeControlsOpen] = useState(false)

	const [isDragging, setIsDragging] = useState(false)

	useEffect(() => {
		const video = videoRef.current
        
		if (video && videoBlob){
			blobToBase64(videoBlob)
				.then((base64) => {
					video.src = base64
					video.load()

				}) 
		}
	}, [])

	useEffect(() => {
		const video = videoRef.current

		if(video){
			clearTimeout(centralButtonInterval)

			setShowCentralButton(true)

			setCentralButtonInterval(setTimeout(() => {
				setShowCentralButton(false)
			}, 750))

			if(isPlaying){
				video.play()
				setTimerInterval(setInterval(() => {
					setVideoTimer(video.currentTime)
				}, 10))
			}else{
				video.pause()
				clearInterval(timerInterval)
			}
		}
	}, [isPlaying])
    
	useEffect(() => {
		const video = videoRef.current

		if(video && volume >= 0 && volume <= 1){
			video.volume = volume
		}
	}, [volume])

	const loadedVideoDataHandler = () => {
		if(onLoaded){
			onLoaded()
		}

		const video = videoRef.current

		if(video){

			const getDuration = () => {
				video.currentTime = 0
				video.removeEventListener('timeupdate', getDuration)
				setVideoDuration(video.duration)
			}
			
			if(video.duration === Infinity){
				video.currentTime = 1e101
				video.addEventListener('timeupdate', getDuration)
			}
		}
	}

	return (
		<div className={`video-player-wrapper ${isFullscreen ? 'video-player-fullscreen' : ''}`}>
			<div 
				className='video-player-container'
				onMouseOver={() => {
					clearTimeout(controlsTimerInterval)
					setShowControls(true)
					setControlsTimerInterval(setTimeout(() => {
						setShowControls(false)
					}, 3000))
				}}
				
				onClick={
					isDragging ? 
						undefined
						: 
						() => {
							setIsPlaying(!isPlaying)
						}
				}
			>

				<video ref={videoRef} onEnded={() => setIsPlaying(false)} onLoadedData={loadedVideoDataHandler}></video>
				<div className="video-player-controls-container">
					<div className={`video-player-controls-central-play-button-container ${showControls ? 'show-central-play-button' : ''}`}>
						<VideoPlayerPlayButton isPlaying={isPlaying} />
					</div>
					<div className={`video-player-controls-interaction-container ${showControls ? 'show-video-player-controls' : ''}`}>
						<VideoPlayerProgressbar progress={videoTimer/videoDuration*100} setCurrentTime={setVideoTimer} duration={videoDuration} setIsPlaying={setIsPlaying} video={videoRef.current} setIsDragging={setIsDragging} />
						<div className="video-player-controls-interaction-buttons-container">
							<div className="video-player-controls-interaction-buttons-item">
								<VideoPlayerPlayButton isPlaying={isPlaying}/>
								<VideoPlayerVolumeControls isVolumeControlsOpen={isVolumeControlsOpen} setIsVolumeControlsOpen={setIsVolumeControlsOpen} videoVolume={volume} setVideoVolume={setVolume} setIsDragging={setIsDragging}/>
								<VideoPlayerTimer currentTime={videoTimer} duration={videoDuration}/>
							</div>
							<div className="video-player-controls-interaction-buttons-item">
								<VideoPlayerFullscreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VideoPlayer