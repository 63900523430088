//styles and icons
import './notFound.scss'

// components
import Layout from '../../../components/general/layout/layout'

function NotFound() {
	return (
		<Layout 
			header={{
				avatar: true
			}}
		>
			<div className='not-found'>
				<h3>Page Not Found | 404</h3>
			</div>
		</Layout>
	)
}

export default NotFound
