//react
import { FC, useState } from 'react'


//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// types
import { document } from '../../../../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteProjectDocumentId, setDeleteProjectDocumentModalIsOpen } from '../../../../../../redux/projects/pipeline/modals'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProjectDocumentUrl } from '../../../../../../utils/urls/projects/project'

//translation
import { useTranslation } from 'react-i18next'

type deleteProviderDocumentConfirmationModalProps = {
	setDocumentsData: (value: document[]) => void
	documentsData: document[]
}

const DeleteProjectDocumentConfirmationModal: FC<deleteProviderDocumentConfirmationModalProps> = ({ documentsData, setDocumentsData }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.project.projectDocuments.modals.deleteProjectDocumentConfirmationModal' })

	const { deleteProjectDocumentModal } = useAppSelector((state) => state.pipelineModals.projectDocuments)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteProjectDocumentModalIsOpen(false))
		dispatch(setDeleteProjectDocumentId(null))
	}

	const delDocument = (): void => {
		if(deleteProjectDocumentModal.documentId){
			setLoading(true)
			authorizedRequest(singleProjectDocumentUrl(deleteProjectDocumentModal.documentId), 'DELETE')
				.then((response) => {
					console.log(response)
					const updatedDocumentsData = documentsData.filter(document => document.id !== deleteProjectDocumentModal.documentId)
					setDocumentsData(updatedDocumentsData)
					setLoading(false)
					dispatch(setDeleteProjectDocumentModalIsOpen(false))
					dispatch(setDeleteProjectDocumentId(null))
				})
		}	
	}

	return (
		<ConfirmationModal
			title={t('deleteThisDocument')} 
			buttons={[{ text: t('yes'), onClickHandler: delDocument }, { text: t('no'), onClickHandler: closeModal }]}
			isOpen={deleteProjectDocumentModal.modalIsOpen}
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteProjectDocumentConfirmationModal