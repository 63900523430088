import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { employee } from '../../../types/employees/hierarchyTypes'

type employeesGeneralModalsState = {
    hireEmployee: {
        modalIsOpen: boolean
        leaderId: number | undefined
		employeeId: number | undefined
    },
    fireEmployee: {
        modalIsOpen: boolean
        employee: employee | undefined
    },
	resignEmployee: {
		modalIsOpen: boolean
	},
    moveEmployee: {
        modalIsOpen: boolean
        confirmationModalIsOpen: boolean
        employeeId: number | undefined
        newLeaderId: number | undefined
        team: boolean
    }
}

const initialState: employeesGeneralModalsState = {
	hireEmployee: {
		modalIsOpen: false,
		leaderId: undefined,
		employeeId: undefined
	},
	fireEmployee: {
		modalIsOpen: false,
		employee: undefined
	},
	resignEmployee: {
		modalIsOpen: false,
	},
	moveEmployee: {
		modalIsOpen: false,
		confirmationModalIsOpen: false,
		employeeId: undefined,
		newLeaderId: undefined,
		team: false
	}
}

export const employeesGeneralModalsSlice = createSlice({
	name: 'employeesGeneralModalsReducer',
	initialState,
	reducers: {
		setHireEmployeeModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.hireEmployee.modalIsOpen = action.payload
		},
		setHireEmployeeLeaderId: (state, action: PayloadAction<number | undefined>) => {
			state.hireEmployee.leaderId = action.payload
		},
		setHireEmployeeEmployeeId: (state, action: PayloadAction<number | undefined>) => {
			state.hireEmployee.employeeId = action.payload
		},
		setFireEmployeeModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.fireEmployee.modalIsOpen = action.payload
		},
		setFireEmployee: (state, action: PayloadAction<employee | undefined>) => {
			state.fireEmployee.employee = action.payload
		},
		setMoveEmployeeModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.moveEmployee.modalIsOpen = action.payload
		},
		setMoveEmployeeConfirmationModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.moveEmployee.confirmationModalIsOpen = action.payload
		},
		setMoveEmployeeId: (state, action: PayloadAction<number | undefined>) => {
			state.moveEmployee.employeeId = action.payload
		},
		setMoveEmployeeNewLeaderId: (state, action: PayloadAction<number | undefined>) => {
			state.moveEmployee.newLeaderId = action.payload
		},
		setMoveEmployeeTeam: (state, action: PayloadAction<boolean>) => {
			state.moveEmployee.team = action.payload
		},
		setResignEmployeeModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.resignEmployee.modalIsOpen = action.payload
		},
	}
})

export const {setHireEmployeeModalIsOpen, setHireEmployeeLeaderId, setHireEmployeeEmployeeId, setFireEmployeeModalIsOpen, setFireEmployee, setMoveEmployeeModalIsOpen, setMoveEmployeeConfirmationModalIsOpen, setMoveEmployeeId, setMoveEmployeeNewLeaderId, setMoveEmployeeTeam, setResignEmployeeModalIsOpen } = employeesGeneralModalsSlice.actions

export default employeesGeneralModalsSlice.reducer