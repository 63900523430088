// images
import hierarchyHomeEn from '../../../assets/manual/employee/en/hierarchyHome.png'
import hierarchyTableEn from '../../../assets/manual/employee/en/hierarchyTable.png'
import hierarchyOrganizationChartEn from '../../../assets/manual/employee/en/hierarchyOrganizationChart.png'
import hierarchyHomeUa from '../../../assets/manual/employee/ua/hierarchyHome.png'
import hierarchyTableUa from '../../../assets/manual/employee/ua/hierarchyTable.png'
import hierarchyOrganizationChartUa from '../../../assets/manual/employee/ua/hierarchyOrganizationChart.png'
import searchEmployeeEn from '../../../assets/manual/employee/en/searchEmployee.png'
import searchEmployeeHomeEn from '../../../assets/manual/employee/en/searchEmployeeHome.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const GeneralEmployeeManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.employees' })
	const { language } = useAppSelector(state => state.general)
	

	const data: manualData[] = [
		{
			title: t('hierarchyTitle'),
			text: t('hierarchyText'),
			// images: language === 'en' ?
			// 	[hierarchyHomeEn, hierarchyTableEn, hierarchyOrganizationChartEn] :
			// 	[hierarchyHomeUa, hierarchyTableUa, hierarchyOrganizationChartUa],
			link: '/ri-manual/employees/hierarchy'
		},
		{
			title: t('searchEmployeeTitle'),
			text: t('searchEmployeeText'),
			link: '/ri-manual/employees/search-employee'
			// images: [searchEmployeeHomeEn, searchEmployeeEn]
		}
	]
	
	return (
		<ManualLayout 
			heading={t('employeesManualTitle')}
			data={data}
		/>
	)
}

export default GeneralEmployeeManual
