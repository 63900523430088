// images
import Pipeline1En from '../../../assets/manual/project/en/pipelinesHome.png'
import Pipeline2En from '../../../assets/manual/project/en/pipeline.png'
import Pipeline3En from '../../../assets/manual/project/en/pipelineCreate.png'
import MyTask1En from '../../../assets/manual/project/en/myTasksHome.png'
import MyTask2En from '../../../assets/manual/project/en/myTaskDashboard.png'
import MyTask3En from '../../../assets/manual/project/en/myTaskCalendar.png'
import Pipeline1Ua from '../../../assets/manual/project/ua/pipelinesHome.png'
import Pipeline2Ua from '../../../assets/manual/project/ua/pipeline.png'
import Pipeline3Ua from '../../../assets/manual/project/ua/pipelineCreate.png'
import MyTask1Ua from '../../../assets/manual/project/ua/myTasksHome.png'
import MyTask2Ua from '../../../assets/manual/project/ua/myTaskDashboard.png'
import MyTask3Ua from '../../../assets/manual/project/ua/myTaskCalendar.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const GeneralProjectManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.projects' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('pipelinesTitle'),
			text: t('pipelinesText'),
			link: '/ri-manual/projects/single-pipeline'
		},
		{
			title: t('myTasksTitle'),
			text: t('myTasksText'),
			link: '/ri-manual/projects/my-tasks'
		},
		{
			title: t('singleProjectTitle'),
			text: t('singleProjectText'),
			link: '/ri-manual/projects/single-project'
		},
		{
			title: t('singleTaskTitle'),
			text: t('singleTaskText'),
			link: '/ri-manual/projects/single-task'
		}
	]
	
	return (
		<ManualLayout 
			heading={t('projectsManualTitle')}
			data={data}
		/>
	)
}

export default GeneralProjectManual
