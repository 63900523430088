// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'


const GeneralWarehouseManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.warehouses' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('warehouseListTitle'),
			text: t('warehouseListText'),			
			link: '/ri-manual/warehouses/warehouses'
		},
		{
			title: t('singleWarehouseTitle'),
			text: t('singleWarehouseText'),		
			link: '/ri-manual/warehouses/single-warehouse'	
		},
		{
			title: t('inventoryTitle'),
			text: t('inventoryText'),
			link: '/ri-manual/warehouses/inventory'	
		},
		{
			title: t('inventoryItemTitle'),
			text: t('inventoryItemText'),
			link: '/ri-manual/warehouses/single-inventory-item'	
		},
		{
			title: t('shipmentsTitle'),
			text: t('shipmentsText'),
		},
		{
			title: t('movingListTitle'),
			text: t('movingListText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('warehousesManualTitle')}
			data={data}
		/>
	)
}

export default GeneralWarehouseManual
