import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'


type themeContextType = {
	theme:string
	setTheme:(theme:string)=> void
}

const ThemeContext = createContext<themeContextType>({theme:'default',setTheme:()=>{console.log('')}})

type themeContextProviderProps = {
	children:ReactNode
	defaultTheme?:string
}

export const ThemeContextProvider : FC<themeContextProviderProps>  = ({ children, defaultTheme }) => {

	const savedTheme = localStorage.getItem('siteTheme')
	const [activeTheme,setTheme] = useState(savedTheme ?? 'default')

	const setNewTheme = (theme:string)=>{
		setTheme(theme)
		localStorage.setItem('siteTheme', theme)
		applyTheme(theme,activeTheme)
	}

	const applyTheme = (currTheme:string,prevTheme?:string)=>{
		prevTheme && document.body.classList.remove(prevTheme)
		document.body.classList.add(currTheme)
	}
	
	useEffect(()=>{
		applyTheme(activeTheme,'')
	},[])
	
	
	return (
		<ThemeContext.Provider value={{theme:activeTheme,setTheme:setNewTheme}}>
			{children}
		</ThemeContext.Provider>
	)
} 

const useTheme = ()=>{
	const context = useContext(ThemeContext)
	if(context === undefined){
		throw new Error('Theme Provider has not been set yet, Please check the top level container for ThemeContextProvider')
	}
	return context

}

export default useTheme