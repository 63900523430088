// images
import tableViewEn from '../../../../assets/manual/employee/en/hierarchyTable.png'
import organizationChartEn from '../../../../assets/manual/employee/en/hierarchyOrganizationChart.png'
import tableViewUa from '../../../../assets/manual/employee/ua/hierarchyTable.png'
import organizationChartUa from '../../../../assets/manual/employee/ua/hierarchyOrganizationChart.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const HierarchyEmployeeManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.employees.hierarchy' })
	const { language } = useAppSelector(state => state.general)
	
	const data: manualData[] = [
		{
			title: t('hireTitle'),
			text: t('hireText')
			// images: language === 'en' ? [tableViewEn] : [tableViewUa]
		},
		{
			title: t('fireTitle'),
			text: t('fireText')
		},
		{
			title: t('moveEmployeeTitle'),
			text: t('moveEmployeeText')
		},
		{
			title: t('organizationChartTitle'),
			text: t('organizationChartText')
			// images: language === 'en' ? [organizationChartEn] : [organizationChartUa]
		}
	]


	
	return (
		<ManualLayout 
			heading={t('hierarchyTitle')}
			data={data}
		/>
	)
}

export default HierarchyEmployeeManual
