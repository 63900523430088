//styles
import './notifications.scss'

// react
import { useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Notification from '../../../components/general/notification/notification'
import UnreadNotifications from '../../../components/general/notifications/unreadNotifications/unreadNotifications'
import ReadNotifications from '../../../components/general/notifications/readNotifications/readNotifications'

// types
import {
	button,
	dropdown,
	notification,
	notificationResponse,
	notificationType,
	filterBar,
	infoPopupTypes
} from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'


enum notificationsSection {
	Unread='Unread',
	Read='Read'
}


const Notifications = () => {
	const { t } = useTranslation('', { keyPrefix: 'notifications' })

	const [activeNotificationsTypeSection, setactiveNotificationsTypeSection] = useState('Unread')

	const titleUrls = [
		{
			url: '/ri-business/notifications',
			title: t('title')
		}
	]

	const infoPopup = {
		content: [{
			title: 'notifications_info_title',
			description: 'notifications_info_description',
			link: '/ri-manual/notifications'
		}],
		type: infoPopupTypes.INFO
	}

	const notificationsSections: button[] = [
		{
			active: activeNotificationsTypeSection === notificationsSection.Unread,
			text: t('content.unread'),
			onClick: () => { setactiveNotificationsTypeSection(notificationsSection.Unread) }
		},
		{
			active: activeNotificationsTypeSection === notificationsSection.Read,
			text: t('content.read'),
			onClick: () => { setactiveNotificationsTypeSection(notificationsSection.Read) }
		}

	]

	const renderTab = () => {
		switch(activeNotificationsTypeSection) {
		case notificationsSection.Unread:
			return <UnreadNotifications />
		case notificationsSection.Read:
			return <ReadNotifications/>
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: t('title'),
			}}
			tabButtons={notificationsSections}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="user-notifications-container">
				{
					renderTab()
				}
			</div>
		</Layout>
	)
}

export default Notifications