//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import {singleProjectUrl, singleProjectCloseUrl} from '../../../../../utils/urls/projects/project'

//react
import { FC, useEffect, useState } from 'react'

//types
import { detailedErrorObject, dragStage, project } from '../../../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setCloseProjectModalIsOpen, setCloseProjectId } from '../../../../../redux/projects/pipeline/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

type closeProjectConfirmationModalProps = {
	stagesList: dragStage<project>[]
	setStagesList: (value: dragStage<project>[]) => void
}

const CloseProjectConfirmationModal: FC<closeProjectConfirmationModalProps> = ({ stagesList, setStagesList }) => {

	const { t } = useTranslation('', { keyPrefix: 'projects.pipeline.modals.closeProjectConfirmationModal'} )

	const { modalIsOpen, projectId, action } = useAppSelector((state) => state.pipelineModals.closeProjectModal)
	const { language } = useAppSelector((state) => state.general)

	const [loading, setLoading] = useState(false)

	const dispatch = useAppDispatch()

	const [ description, setDescription ] = useState<string>()
	const [ buttons, setButtons ] = useState<{
		text: string
		onClickHandler: () => void
			}[]>([])
	const [errorObjects, setErrorObjects] = useState<detailedErrorObject[]>()

	useEffect(() => {
		if(modalIsOpen){
			authorizedRequest(singleProjectCloseUrl(projectId || -1) + `?language=${language}`, 'GET')
				.then((response) => {

					const { result } = response

					const { description, objects } = result
				
					setDescription(description ? t(description) : undefined)

					setErrorObjects(objects.map(formatDetailedErrorObjectResponse))

					if(result.description === 'bill_created' || result.description === null){
						if(result.description === null){
							setErrorObjects(undefined)
						}
						setButtons([{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }])
					}
				})
		}
	}, [modalIsOpen])

	const closeModal = (): void => {
		dispatch(setCloseProjectModalIsOpen(false))
		dispatch(setCloseProjectId(null))
		setDescription(undefined)
		setButtons([])
	}

	const handleSubmit = (): void => {
		if(projectId){
			setLoading(true)
			authorizedRequest(singleProjectUrl(projectId || -1), 'PUT', 'accessToken', {
				is_closed: action === 'close',
				language: language
			})
				.then((response) => {
					const updatedStages = stagesList.map((stage) => {
						return {
							...stage,
							items: stage.items.map((item) => {
								if (item.isClosed){
									return item
								}

								return {
									...item,
									isClosed: projectId === item.id
								}
							})
						}
					})

					setStagesList([...updatedStages])
					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={action === 'close' ? t('closeThisProject') : t('activateThisProject')}
			buttons={buttons}
			isOpen={modalIsOpen}
			closeModal={closeModal}
			errorObjects={errorObjects}
			description={description}
			loading={loading}
		/>
	)
}

export default CloseProjectConfirmationModal