import { baseUrl } from '../../network'

export const getOfferingFiltersUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/offerings/filters`
export const companyOfferingsUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/offerings`
export const singleOfferingUrl = (offeringId: number) => baseUrl + `/api/offerings/${offeringId}`

export const offeringImagesUrl = (offeringId: number) => baseUrl + `/api/offerings/${offeringId}/images`

export const getOfferingItemFiltersUrl = (offeringId: number) => baseUrl + `/api/offering/${offeringId}/items/filters`
export const offeringItemsUrl = (offeringId: number) => baseUrl + `/api/offering/${offeringId}/items`
export const singleOfferingItemUrl = (offeringItemId: number) => baseUrl + `/api/offering/items/${offeringItemId}`
