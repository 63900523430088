// style
import './provider.scss'

// react
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import ProviderDetails from '../../../components/purchase/provider/providerDetails/providerDetails'
import ProviderDocuments from '../../../components/purchase/provider/providerDocuments/providerDocuments'
import ProviderOrderList from '../../../components/purchase/provider/providerOrderList/providerOrderList'
import ProviderBillsList from '../../../components/purchase/provider/providerBillsList/providerBillsList'
import ProviderOfferingList from '../../../components/purchase/provider/providerOfferingList/providerOfferingList'
import ProviderAddressList from '../../../components/purchase/provider/customerAddressList/providerAddressList'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

enum providerSection {
	Details = 'Details',
	Addresses = 'Addresses',
	Documents = 'Documents',
	Offering = 'Offering',
	Orders = 'Orders',
	Chat = 'Chat',
	Statistic = 'Statistic',
	Bills = 'Bills',
}

const Provider = () => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider' })

	const [filterBar, setFilterBar] = useState<filterBar>()
	const [providerName, setProviderName] = useState('')

	const [activeProviderSection, setActiveProviderSection] = useState('Details')

	const location = useLocation()

	const titleUrls = [
		{
			url: '/ri-business/purchase/provider-list',
			title: t('providers')
		}, 
		{
			url: location.pathname,
			title: providerName
		}
	]

	const infoPopup = {
		content: [{
			title: 'provider_info_title',
			description: 'provider_info_description',
			link: '/ri-manual/purchases/single-provider'
		}],
		type: infoPopupTypes.INFO
	}

	const providerTabButtons: button[] = [
		{
			active: activeProviderSection === providerSection.Details,
			text: t('details'),
			onClick: () => setActiveProviderSection(providerSection.Details),
		},
		{
			active: activeProviderSection === providerSection.Addresses,
			text: t('addresses'),
			onClick: () => setActiveProviderSection(providerSection.Addresses),
		},
		{
			active: activeProviderSection === providerSection.Documents,
			text: t('documents'),
			onClick: () => setActiveProviderSection(providerSection.Documents),
		},
		{
			active: activeProviderSection === providerSection.Offering,
			text: t('offerings'),
			onClick: () => setActiveProviderSection(providerSection.Offering),
		},
		{
			active: activeProviderSection === providerSection.Orders,
			text: t('orders'),
			onClick: () => setActiveProviderSection(providerSection.Orders),
		},
		{
			active: activeProviderSection === providerSection.Bills,
			text: t('bills'),
			onClick: () => setActiveProviderSection(providerSection.Bills),
		},
	]

	const renderTab = () => {
		switch (activeProviderSection) {
		case providerSection.Details:
			return <ProviderDetails setProviderName={setProviderName} />
		case providerSection.Addresses:
			return <ProviderAddressList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case providerSection.Documents:
			return <ProviderDocuments setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case providerSection.Offering:
			return <ProviderOfferingList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case providerSection.Orders:
			return <ProviderOrderList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case providerSection.Bills:
			return <ProviderBillsList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={providerTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="provider-container">
				{
					renderTab()
				}
			</div>

		</Layout>
	)
}

export default Provider