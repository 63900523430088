//styles and icons
import './timeline.scss'

//react
import { useState, useEffect, FC } from 'react'

//components 
import TaskCalendarDateItem from './taskCalendarDateItem/taskCalendarDateItem'
import TaskTimelineCard from './taskTimelineCard/taskTimelineCard'

//types
import { task } from '../../../../types/general/generalTypes'

type tebleMonthListType = {
	year: number,
	month: number,
	days: number[]
}

type timeLineProps = {
    loadTasks: () => Promise<task<Date>[]>
}

const Timeline: FC<timeLineProps> = ({ loadTasks }) => {

	const [firstDate, setFirstDate] = useState<Date>(new Date())
	const [lastDate, setLastDate] = useState<Date>(new Date())
	const [dateData, setDateData] = useState<tebleMonthListType[]>([])
	const [tasks, setTasks] = useState<task<Date>[]>([])

	const [ filteredTasks, setFilteredTasks ] = useState<task<Date>[]>([])

	const sortByFirstDate = (task1: task<Date>, task2: task<Date>) => {
		return task1.startDate.getTime() - task2.startDate.getTime()
	}

	const sortByLastDate = (task1: task<Date>, task2: task<Date>) => {
		return task2.endDate.getTime() - task1.endDate.getTime()
	}


	useEffect(() => {
		const filteredTasks: task<Date>[] = tasks.filter((task) => task.startDate !== undefined && task.endDate !== undefined) as task<Date>[]
		setFilteredTasks([...filteredTasks])
	}, [tasks])


	useEffect(() => {
		loadTasks()
			.then((tasks) => {
				setTasks([...tasks])
			})
	}, [])


	useEffect(() => {
		console.log(filteredTasks)
		if(filteredTasks.length > 0){

			const startDate = new Date((filteredTasks.sort(sortByFirstDate)[0].startDate.getTime()) - 24*60*60*1000)
			const monthsData: tebleMonthListType[] = []
			
			while (startDate <= lastDate) {
				const year = startDate.getFullYear()
				const month = startDate.getMonth()+1
				const day = startDate.getDate()
				const monthIndex = monthsData.findIndex((date) => date.year == year && date.month == month)
	
				if(monthIndex == -1){
					monthsData.push({
						year,
						month,
						days: [day]
					})
				}else{
					monthsData[monthIndex].days.push(day)
				}
				startDate.setDate(startDate.getDate()+1)
			}
			setDateData(monthsData)
		}
	}, [firstDate, lastDate])
	
	useEffect(() => {
		if(filteredTasks.length > 0){
			const timeConversion = 24*60*60*1000

			setFirstDate(new Date((filteredTasks.sort(sortByFirstDate)[0].startDate.getTime()) - timeConversion))
			setLastDate(new Date((filteredTasks.sort(sortByLastDate)[0].endDate.getTime()) + timeConversion))
		}
	}, [filteredTasks])

	const getDaysDifference = ()=> {
		return Math.ceil((lastDate.getTime() - firstDate.getTime()) / (24 * 3600 * 1000))+1
	}

	const getDaysDifferenceLeft = (task: task<Date>)=> {
		return Math.ceil((task.startDate.getTime() - firstDate.getTime()) / (24 * 3600 * 1000))+1
	}

	const getDaysDifferenceWidth = (task: task<Date>)=> {
		return Math.ceil((task.endDate.getTime() - task.startDate.getTime()) / (24 * 3600 * 1000))+1
	}

	return (
		<div className="timeline">
			<div className="timeline-calendar-table-date-container" >
				{dateData.map((item: tebleMonthListType, index)=>(
					<TaskCalendarDateItem key={`timeline-calendar-month-${index}`} {...item}/>
				))}
			</div>

                
			<div className="timeline-calendar-table-time-interval-container" id={'timeline-calendar-table-time-interval-container'}>
				{filteredTasks.map((task: task<Date>, index)=>(
					<TaskTimelineCard key={`task-timeline-${index}`} task={task} daysDiff={getDaysDifference()} daysDiffLeft={getDaysDifferenceLeft(task)} daysDiffwidth={getDaysDifferenceWidth(task)}/>
				))}  
			</div> 
		</div>
	)
}

export default Timeline

