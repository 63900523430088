//styles
import './statusCircle.scss'

//react
import { FC } from 'react'

//types
import { userStatus } from '../../../types/general/generalTypes'

type statusCircleProps = {
    status: userStatus
}

const StatusCircle: FC<statusCircleProps> = ({ status }) => {
	return (
		<div className={`status ${status}`}></div>
	)
}

export default StatusCircle