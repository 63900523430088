// styles
import './linearDiagram.scss'

// react
import { FC, useEffect, useRef, useState } from 'react'

// components
import {Line} from 'react-chartjs-2'

// other
import { vwToPixel } from '../../../../assets/general/generalFunctions'

//types
import { chartItem, legendItem } from '../../../../types/general/generalTypes'

// chart library
import { Chart,PointElement, LineElement,LinearScale,CategoryScale} from 'chart.js'

Chart.register(PointElement)
Chart.register(LineElement)
Chart.register(LinearScale)
Chart.register(CategoryScale)

type linearDiagramProps = {
	// data: {
	// 	values:number[]
	// 	color:string
	// 	label:string
	// }[]
	chartData: {
		xAxisLabels: string[]
		chartItems: chartItem[]
	}
	activeData: legendItem[]
}

type datasetType = {
	data:number[]
	colorList:string[]
}

const LinearDiagram : FC<linearDiagramProps> = ({ chartData, activeData }) => {

	// Data filter
	const filterData = (activeChartItems: legendItem[])=>{
		return chartData.chartItems.filter((chartItem) => activeChartItems.findIndex((activeItem) => activeItem.label === chartItem.label) !== -1)
	}

	const filteredLinearChartData = filterData(activeData)
	
	const containerRef = useRef<HTMLDivElement>(null)
	
	const [ratio,setRatio] = useState(0)

	const [ max, setMax ] = useState(0)
	const [ min, setMin ] = useState(0)

	const [responsiveSize, setResponsiveSize] = useState({
		fontSize:0,
		borderWidth:0,
		barThickness:0,
		borderDash:0,
	})
	
	useEffect(()=>{
		const resize = ()=>{
			setResponsiveSize({
				fontSize:vwToPixel(0.7),
				borderWidth:vwToPixel(.15),
				barThickness:vwToPixel(.5),
				borderDash:vwToPixel(.5),
			})
		}
		window.addEventListener('resize',resize)

		resize()
		return ()=>{
			window.removeEventListener('resize',resize)
		}
	},[])

	const lineStyling = {
		borderRadius:100,
		tension:.3,
		pointRadius:0,
	}

	const formatData = (chartItems: chartItem[]) => {

		const formattedData: datasetType[]  = []

		for(let i = 0; i < chartItems.length; i++){
			const dataset: number[] = []
			const colorList: string[] = []
			for(let j = 0; j < chartItems[i].data.length; j++){
				dataset.push(chartItems[i].data[j].value ?? 0)
				colorList.push(chartItems[i].color)
			}

			const currentData : datasetType = {
				data:dataset,
				colorList
			}
			formattedData.push(currentData)
			
		}

		const datasets = formattedData.map((dataset, index)=>{
			return {
				label: chartItems[index].label,
				data: dataset.data,
				borderColor: dataset.colorList,
				...lineStyling
			}
		})

		return {
			labels: chartData.xAxisLabels,
			datasets
		}
	
	}

	const linearChartData = formatData(filteredLinearChartData)
	


	// Calculate the correct ratio based on parent
	useEffect(()=>{
		if(containerRef.current){
			setRatio(containerRef.current.clientWidth / containerRef.current.clientHeight)
		}
	},[containerRef])

	useEffect(() => {
		const allValues: number[] = []

		for(let i=0; i<chartData.chartItems.length; i++){
			for(let j=0; j<chartData.chartItems[i].data.length; j++){
				allValues.push(chartData.chartItems[i].data[j].value)
			}
		}

		setMax(Math.max(...allValues))
		setMin(Math.min(...allValues))
	}, [chartData])

	return (
		<div className='linear-container' ref={containerRef}>
			<Line 
				updateMode='resize'
				data={linearChartData}
				options={{
					aspectRatio:ratio,
					scales:{
						y:{
							max:max,
							min:min,
							border:{
								dash:[responsiveSize.borderDash],
								dashOffset:responsiveSize.borderDash,
								color:'#E5E5EF',
								width:responsiveSize.borderWidth,
							},
							ticks:{
								font:{
									family:'Poppins',
									size:responsiveSize.fontSize
								},
							},
							grid:{
								tickLength:0,
							}
						},
						x:{
							ticks:{
								font:{
									family:'Poppins',
									size:responsiveSize.fontSize
								}
							},
							grid:{
								color:'transparent'
							},
							border:{
								z:10,
								width:responsiveSize.borderWidth,
								color:'#E5E5EF'
							}
						}
					},
					transitions:{
						show: {
							animations: {
								x: {
									from: 0
								},
								y: {
									from: 0
								}
							}
						},
						hide: {
							animations: {
								x: {
									to: 0
								},
								y: {
									to: 0
								}
							}
						}
					},
					elements:{
						point:{	
							hitRadius:0
						},
						line:{
							borderCapStyle:'round',
							borderWidth:responsiveSize.borderWidth
						}
					},
					plugins:{
						legend:{
							display:true,
						},
						tooltip:{
							enabled:true
						}
					}
					
				}}
			/>
		</div>
	)
}

export default LinearDiagram