// images
import warehouseDetailsEn from '../../../../assets/manual/warehouse/en/warhoseDetails.png'
import warehouseDetailsInventoryEn from '../../../../assets/manual/warehouse/en/warhouseDetailsInventory.png'
import warehouseDetailsShipmentsEn from '../../../../assets/manual/warehouse/en/warhouseDetailsShipments.png'
import warehouseDetailsShipmentsCreateEn from '../../../../assets/manual/warehouse/en/warhauseDetailsCreateShipment.png'
import warehouseDetailsMovingEn from '../../../../assets/manual/warehouse/en/warhauseDetailsMoving.png'
import warehouseDetailsMovingCreateEn from '../../../../assets/manual/warehouse/en/warhouseDetailsMovingCreate.png'
import warehouseDetailsUa from '../../../../assets/manual/warehouse/ua/warhoseDetails.png'
import warehouseDetailsInventoryUa from '../../../../assets/manual/warehouse/ua/warhouseDetailsInventory.png'
import warehouseDetailsShipmentsUa from '../../../../assets/manual/warehouse/ua/warhouseDetailsShipments.png'
import warehouseDetailsShipmentsCreateUa from '../../../../assets/manual/warehouse/ua/warhauseDetailsCreateShipment.png'
import warehouseDetailsMovingUa from '../../../../assets/manual/warehouse/ua/warhauseDetailsMoving.png'
import warehouseDetailsMovingCreateUa from '../../../../assets/manual/warehouse/ua/warhouseDetailsMovingCreate.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'


const SingleWarehouseManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.warehouses.singleWarehouse' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('warehouseInventoryTitle'),
			text: t('warehouseInventoryText')

		},
		{
			title: t('warehouseShipmentsTitle'),
			text: t('warehouseShipmentsText')
		},
		{
			title: t('warehouseMovingTitle'),
			text: t('warehouseMovingText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singleWarehouseManualTitle')}
			data={data}
		/>
	)
}

export default SingleWarehouseManual
