import { baseUrl } from '../../network'  

// offerings
export const getOfferingsFilter = (companyId: number)=> baseUrl + `api/company/${companyId}/offerings/filters`
export const getOfferingsOfInventoryItemFilter = (inventoryItemId: number)=> baseUrl + `api/inventory/item/${inventoryItemId}/offerings`

export const getOfferingsUrl = (companyId: number)=> baseUrl + `api/company/${companyId}/offerings`
export const getOfferingsByInventoryItemUrl = (inventoryItemId: number)=> baseUrl + `api/inventory/items/${inventoryItemId}/offerings`

export const createOfferingUrl = (companyId: number)=> baseUrl + `api/company/${companyId}/offerings`
export const addOfferingInventoryItemUrl = (offeringId: number)=> baseUrl + `api/offering/${offeringId}/items`

export const deleteOfferingUrl = (offeringId: number)=> baseUrl + `api/offerings/${offeringId}`


