// styles
import './otpInput.scss'

// react
import { ChangeEvent, ClipboardEvent, Dispatch, FC, MutableRefObject, SetStateAction, useRef } from 'react'


type otpInputProps = {
	otp: string[]
	setOtp: Dispatch<SetStateAction<string[]>>
}

const OTPInput: FC<otpInputProps> = ({ otp, setOtp }) => {
	const otpInputRefs: MutableRefObject<HTMLInputElement | null>[] = otp.map(() => useRef(null))

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		const data = e.clipboardData?.getData('text').split('').splice(0, 6)

		if (data.length < 6) {
			while (data.length < 6) {
				data.push('')
			}
		}

		setOtp(data)
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value
		if (value === '') {
			if (index === otp.length - 1) {
				otpInputRefs[index - 1].current!.focus()
			}
			else if (index > 0 && otp[index + 1] === '') {
				otpInputRefs[index - 1].current!.focus()
			}
		}
		if (!isNaN(Number(value))) {
			otp[index] = value
			setOtp([...otp])
			if (index < otpInputRefs.length - 1 && value !== '') {
				otpInputRefs[index + 1].current!.focus()
			}
		}
	}
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
			otpInputRefs[index - 1].current!.focus()
		}
	}

	return (
		<div className='otp-input-container'>
			{otp.map((_, index) => (
				<input
					onPaste={handlePaste}
					key={`otp-${index}`}
					className='otp-input'
					type='text'
					maxLength={1}
					value={otp[index]}
					onChange={(e) => handleInputChange(e, index)}
					onKeyDown={(e) => handleKeyDown(e, index)}
					ref={otpInputRefs[index]}
				/>
			))}
		</div>
	)
}

export default OTPInput