//styles and icons
import './navBarSlide.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import Subtitle from '../../subtitle/subtitle'
import Text from '../../text/text'

//types
import { subCategorySlide } from '../../../../../../types/general/generalTypes'

type navBarSlideProps = {
    slides: subCategorySlide[],
	sliderHeight: number,
}

const NavBarSlide: FC<navBarSlideProps> = ({ slides, sliderHeight }) => {
	const [fade, setFade] = useState(true)

	const [newSlides, setNewSlides] = useState(slides)

	useEffect(() => {
		setFade(false)
		const timer = setTimeout(() => {
			setFade(true)
			setNewSlides(slides)
		}, 500) // Match the duration with CSS fade-out time

		return () => clearTimeout(timer)
	}, [slides])

	return (
		<div className={`navbar-slides ${fade ? 'fade-in' : 'fade-out'}`} 
			style={sliderHeight ? {height: sliderHeight} : {}}
		>
			{newSlides.map((slide, index) => (
				<div className="slide" key={`navbar-slide-${index}`} style={{marginLeft: index%2 != 0 ? 'auto' : '0'}}>
					<div className="slide-number">
						<Subtitle>{index+1}</Subtitle>
					</div>
					<div className="slide-content">
						<Text>{slide.title}</Text>
					</div>
					{ slide.image && <img src={slide.image} alt={slide.title} /> }
				</div>
			))}
		</div>
	)
}

export default NavBarSlide