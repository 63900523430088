import { file } from '../general/generalTypes'

export enum messageActions {
    REPLY = 'REPLY',
    EDIT = 'EDIT',
    SEND = 'SEND',
    DELETE = 'DELETE',
    FORWARD = 'FORWARD'
}

export type audioWave = {
    height: number 
    filling: number
}

export type chatBarCard = {
    avatar: string | null
    message: messageCard | null
    name: string
    unreadMessages: number | null
    chatId: number
    userId: number | null
    isGroup: boolean
}

export type inboxResponse = {
    id: number
    name: string
    description: string
}

export type inbox = {
    id: number
    name: string
    description: string
}

export enum messageTypes {
    USER = 'USER',
    STRANGER = 'STRANGER'
}

export enum messageStatus {
    SENDING = 'SENDING',
    SENT = 'SENT',
    READ = 'READ'
}

export enum messagesSliderEnum {
    searchedMessages = 'searchedMessagesSlider',
    pinnedMessages = 'pinnedMessagesSlider',
    closed = 'closed'
}

export type messageType = {
    userId: number
    date: Date
    text: string
    file: file[] 
    messageId: number
    edited: boolean
    pinned: boolean
    forwarded: messageType | null
    replied: messageType | null
    type: messageTypes
    status: messageStatus
}


export type chatMember = {
    userId: number | null
    inboxId: number | null
    avatar: string | null
    name: string
}


export type chatMessagesResponse = {
    user_id: number
    chat_id: number
    message_id: number
    date: number
    is_edited: boolean
    is_pinned: boolean
    forwarded: chatMessagesResponse | null
    replied: chatMessagesResponse | null
    is_read: boolean
    text: string
    files: boolean
    readers: {
        user_id: number
        is_read: boolean
        is_delivered: boolean
        is_deleted: boolean
    }[]
}

export type chatResponse = {
    avatar: string
    chat_id: number
    last_message: chatMessagesResponse
    name: string
    un_read_messages: number
    user_id: number
    is_group: boolean
}

export type messageCard = {
    userId: number
    messageId: number
    status: messageStatus
    text: string
    file: boolean

}