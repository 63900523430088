// styles
import './editPositionModal.scss'

// react
import {Dispatch, FC, FormEvent, SetStateAction, useEffect, useRef, useState} from 'react'

// types
import {selectionOption, skillResponse} from '../../../../../../../types/general/generalTypes'
import {position, positionResponse} from '../../../../../../../types/general/settings/companySectionTypes'

// components
import InputField from '../../../../../inputField/inputField'
import Modal from '../../../../../modals/modal/modal'

// redux
import {useAppDispatch, useAppSelector} from '../../../../../../../customHooks/redux'
import {
	setEditPositionModalIsOpen,
	setEditPositionId
} from '../../../../../../../redux/general/settings/modals'

// network
import { authorizedRequest } from '../../../../../../../utils/queries'
import {getSkillsUrl, modifyPositionUrl} from '../../../../../../../utils/urls/general/positions'

// translation
import { useTranslation } from 'react-i18next'

type editPositionModalProps = {
    positions: position[]
    setPositions: Dispatch<SetStateAction<position[]>>
}

const EditPositionModal: FC<editPositionModalProps> = ({positions, setPositions}) => {
	const {t} = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.editPositionModal'})
	const tSkill = useTranslation('', { keyPrefix: 'general.skills' }).t

	const [name, setName] = useState('')
	const [ skillSearchRequest, setSkillSearchRequest ] = useState('')
	const [ selectedSkills, setSelectedSkills ] = useState<selectionOption[]>([])

	const formRef = useRef<HTMLFormElement | null>(null)

	const dispatch = useAppDispatch()

	const {modalIsOpen, positionId} = useAppSelector(state => state.settingsModals.editPosition)

	const getSkills = async (searchQuery: string, page: number)=> {
		const {result} = await authorizedRequest(getSkillsUrl() + `?needle=${searchQuery}&page=${page}&per_page=10` , 'GET')
		const skillsOptionList: selectionOption[] = result.map((skill: skillResponse) => {
			return {
				id: skill.id,
				text: tSkill(skill.name),
			}
		})

		return skillsOptionList
	}

	const closeModal = ()=> {
		setName('')
		setSkillSearchRequest('')
		setSelectedSkills([])
		dispatch(setEditPositionModalIsOpen(false))
		dispatch(setEditPositionId(null))
	}
    
	const handleSubmit = async (e: FormEvent<HTMLFormElement>)=> {
		e.preventDefault()
		const {result}: {result: positionResponse} = await authorizedRequest(modifyPositionUrl(Number(positionId)), 'PUT', 'accessToken', {
			name: name,
			skills: selectedSkills.map(skill => { return {id: skill.id} })
		})

		const editedPosition: position = result

		setPositions(prevPositions => {
			return prevPositions.map(position => {
				if (position.id === positionId) {
					return editedPosition
				}
				return position
			})
		})
        
		closeModal()
	}

	useEffect(() => {
		setName(positions.find(({id})=> id === positionId)?.name || '')
		setSelectedSkills( positions.find(({id})=> id === positionId)?.skills.map(({id, name})=>{
			return {
				id: id,
				text: name,
			}
		}) || [])
	},[positions, positionId])
    
	return (
		<Modal
			title={t('editPosition')}
			open={modalIsOpen}
			closeModal={closeModal}
			submitButton={{
				text: t('edit'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>
			<form className='edit-position-form' ref={formRef} onSubmit={handleSubmit}>
				<InputField
					label={t('positionName')}
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<InputField
					type='text'
					label={t('skills')}
					value={skillSearchRequest}
					onChange={(e) => setSkillSearchRequest(e.target.value)}
					selection={{
						loadSelectionOptions: getSkills,
						selectedList: selectedSkills,
						setSelectedList: setSelectedSkills
					}}
				/>
			</form>
		</Modal>
	)
}

export default EditPositionModal
