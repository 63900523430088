import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type offeringModalsState = {
	details: {
		importOfferingImageSelectorModal: {
			modalIsOpen: boolean
		}
		deleteOfferingImageModal: {
			modalIsOpen: boolean
			imageId: number | null
		}
	}
	offeringInventoryItemList: {
		addOfferingInventoryItemModal: {
			modalIsOpen: boolean
		}
		deleteOfferingInventoryItemModal: {
			modalIsOpen: boolean
			inventoryItemId: number | null
		}
	}
}

const initialState: offeringModalsState = {
	details: {
		importOfferingImageSelectorModal: {
			modalIsOpen: false
		},
		deleteOfferingImageModal: {
			modalIsOpen: false,
			imageId: null
		}
	},
	offeringInventoryItemList: {
		addOfferingInventoryItemModal: {
			modalIsOpen: false
		},
		deleteOfferingInventoryItemModal: {
			modalIsOpen: false,
			inventoryItemId: null
		}
	}
}

export const offeringModalsSlice = createSlice({
	name: 'offeringModalReducer',
	initialState,
	reducers : {
		setImportOfferingImageSelectorModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.details.importOfferingImageSelectorModal.modalIsOpen = action.payload
		},
		setDeleteOfferingImageModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.details.deleteOfferingImageModal.modalIsOpen = action.payload
		},
		setDeleteOfferingImageId: (state, action: PayloadAction<number | null>)=> {
			state.details.deleteOfferingImageModal.imageId = action.payload
		},

		setAddOfferingInventoryItemModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.offeringInventoryItemList.addOfferingInventoryItemModal.modalIsOpen = action.payload
		},
		setDeleteOfferingInventoryItemModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.offeringInventoryItemList.deleteOfferingInventoryItemModal.modalIsOpen = action.payload
		},
		setDeleteOfferingInventoryItemId: (state, action: PayloadAction<number | null>)=> {
			state.offeringInventoryItemList.deleteOfferingInventoryItemModal.inventoryItemId = action.payload
		}
	}
})

export const {
	setImportOfferingImageSelectorModalIsOpen, 
	setDeleteOfferingImageModalIsOpen,
	setDeleteOfferingImageId,

	setAddOfferingInventoryItemModalIsOpen,
	setDeleteOfferingInventoryItemModalIsOpen,
	setDeleteOfferingInventoryItemId
} = offeringModalsSlice.actions

export default offeringModalsSlice.reducer