//styles and icons
import './chatInfoParticipantCard.scss'
import { basicUserAvatar } from '../../../../../../../../assets/general/generalIcons'

//components
import Avatar from '../../../../../../avatar/avatar'

//react
import { FC } from 'react'

type chatInfoParticipantCardProps = {
    avatar: string | null,
    name: string
}

const ChatInfoParticipantCard: FC<chatInfoParticipantCardProps> = ({name, avatar}) => {

	return (
		<div className="chat-info-slider-participant-card-container">
			<div className="chat-info-slider-particioant-avatar">
				<Avatar blobAvatar={avatar}/>
			</div>
			<p className="chat-info-slider-particioant-name">{name}</p>
		</div>
	)
}

export default ChatInfoParticipantCard