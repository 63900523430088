import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

type profileGeneralState = {
    avatar: string | null
    firstName: string
    lastName: string
    email: string
    position: string
    phoneNumber: string
		gender: string
		birthday?: Date
}

const initialState: profileGeneralState = {
	avatar: null,
	firstName: '',
	lastName: '',
	email: '',
	position: '',
	phoneNumber: '',
	gender: '',
	birthday: undefined,
}

export const profileGeneralSlice = createSlice({
	name: 'profileGeneralReducer',
	initialState,
	reducers: {
		setAvatar: (state, action: PayloadAction<string | null>) => {
			state.avatar = action.payload
		},
		setFirstName: (state, action: PayloadAction<string>) => {
			state.firstName = action.payload
		},
		setLastName: (state, action: PayloadAction<string>) => {
			state.lastName = action.payload
		},
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload
		},
		setPosition: (state, action: PayloadAction<string>) => {
			state.position = action.payload
		},
		setPhoneNumber: (state, action: PayloadAction<string>) => {
			state.phoneNumber = action.payload
		},
		setGender: (state, action: PayloadAction<string>) => {
			state.gender = action.payload
		},
		setBirthday: (state, action: PayloadAction<Date | undefined>) => {
			state.birthday = action.payload
		}
	}
})

export const { setFirstName, setLastName, setEmail, setPosition, setPhoneNumber, setGender , setBirthday } = profileGeneralSlice.actions

export default profileGeneralSlice.reducer