import { baseUrl } from '../../../network'

export const companyBudgetFiltersUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/budgets/filters`
export const companyBudgetOverviewUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/budgets/overview`
export const companyBudgetsUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/budgets`

export const singleBudgetBillFiltersUrl = (budgetId: number) => baseUrl + `/api/budget/${budgetId}/bills/filters`
export const singleBudgetUrl = (budgetId:number)=> baseUrl + `/api/budget/${budgetId}`
export const singleBudgetBillsUrl = (budgetId:number)=> baseUrl + `/api/budget/${budgetId}/bills`
export const singleBudgetBillOverviewwUrl = (budgetId:number)=> baseUrl + `/api/budget/${budgetId}/bills/overview`
