//react

//components
import Layout from '../../../components/general/layout/layout'
import FinancialPerformanceMetrics from '../../../components/finance/financeStats/financialPerformanceMetrics/financialPerformanceMetrics'

//types
import { infoPopupTypes } from '../../../types/general/generalTypes'
// translation
import { useTranslation } from 'react-i18next'


const FinanceStats = () => {
	const { t } = useTranslation('', {keyPrefix:'finance.financeStats'})

	const titleUrls = [
		{
			url: '/ri-business/finance/statistics',
			title: t('financeStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'finance_stats_info_title',
			description: 'finance_stats_info_description',
			link: '/ri-manual/finance/statistics'
		}],
		type: infoPopupTypes.INFO
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<FinancialPerformanceMetrics />
		</Layout>
	)
}

export default FinanceStats