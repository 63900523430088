// styles
import './forecastElement.scss'

//react
import { FC } from 'react'

type forecastElementProps = {
    title: string
    value: number
}

const ForecastElement: FC<forecastElementProps> = ({ title, value }) => {
	return (
		<div className={`forecast-element-container ${value > 0 ? 'positive' : 'negative'}`}>
			<p className="forecast-element-title">{title}</p>
			<p className="forecast-element-value">{value}</p>
		</div>
	)
}

export default ForecastElement