//styles and icons
import './navBarItem.scss'
import { arrowIcon } from '../../../../../assets/general/generalIcons'

//react
import { FC } from 'react'
import { Link } from 'react-router-dom'

//components
import InfoPopup from '../../../infoPopup/infoPopup'

//types
import { infoPopup, navbarCategory } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type navBarItemProps = navbarCategory & {
	index: number,
	activeCategory: number,
	infoPopup?: infoPopup,
	setActiveCategory: React.Dispatch<React.SetStateAction<number>>
}

const NavBarItem: FC<navBarItemProps> = ({ name, icon, subCategories, index, activeCategory, setActiveCategory, infoPopup}) => {
	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })
	
	const onClickCallback = (index: number) => {
		if(activeCategory != index){
			setActiveCategory(index) 
		}else{
			setActiveCategory(0)
		}
	}

	// console.log(infoPopup)

	return (
		<div onClick={() => onClickCallback(index)} className={`navitem ${activeCategory == index  ? 'selected' : ''}`}>
			<div className="navitem-header">
				<div className="navitem-header-text">
					<span className="navitem-header-icons">{icon}</span>
					<p>{t(name)}</p>
				</div>
				<div className="navitem-icons">
					{infoPopup? <InfoPopup {...infoPopup}/> : <div/>}
					<span className="navitem-icons-arrow">{arrowIcon}</span>
				</div>		
			</div>
			<div className='navitem-category-container'>
				{subCategories.map((item, i) => 
				{	 
					return (
						<Link
							className='navbar-link'
							to={item.url!}
							key={i}
						>
							<p>{t(item.name)}</p>
						</Link>
					)
				})
				}
			</div>
		</div>
	)
}

export default NavBarItem
