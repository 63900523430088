// styles and icons
import './navbar.scss'
import { logo } from '../../../../assets/icons/icons'

// react
import { Link, useNavigate } from 'react-router-dom'

// components
import Button from '../../../../../../components/general/button/button'
import LanguageSelector from '../../../../../../components/general/languageSelector/languageSelector'

//translation
import { useTranslation } from 'react-i18next'

const Navbar = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.navbar' })

	const navigate = useNavigate()
	
	const links = [
		{title: t('apps'), href: '/product-description'},
		{title: t('aboutUs'), href: '/about-us'}
	]
	return (
		<div className="landing-navbar">
			<Link to='/' className='navnar-logo-link'>
				<div className='navbar-logo'>
					{logo}
				</div>
			</Link>
			<div className="navbar-actions">
				<ul className="navbar-links">
					<LanguageSelector/>
					{links.map((link,idx) => 
						<li key={`navlink-${idx}`} className='navbar-link-item'>
							<Link to={link.href}>{link.title}</Link>
						</li>
					)}
				</ul>
				<div className="navbar-buttons">
					<Button active={true} inverted={true} text={t('login')} onClick={()=> navigate('/login')}/>
					<Button active={false} inverted={true} outlined={true}  text={t('signUp')}  onClick={()=> navigate('/register')}/>
				</div>
			</div>
		</div>
	)
}

export default Navbar