// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportAccountFileSelectorModalFile, setImportAccountFileSelectorModalIsOpen, setAccountTablePreviewModalIsOpen } from '../../../../../redux/general/modals'


const ImportAccountFileSelectorModal: FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.accontListModal.importAccountFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportAccountFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {
		if (files.length === 0) return
		dispatch(setImportAccountFileSelectorModalFile(files[0]))
		dispatch(setImportAccountFileSelectorModalIsOpen(false))
		dispatch(setAccountTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal
			isOpen={modalIsOpen}
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
		/>
	)
}

export default ImportAccountFileSelectorModal