// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const SingleProjectManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.projects.singleProject' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('createTaskTitle'),
			text: t('createTaskText')	
		},
		{
			title: t('timelineTitle'),
			text: t('timelineText')
		},
		{
			title: t('reportTitle'),
			text: t('reportText')
		},
		{
			title: t('chatTitle'),
			text: t('chatText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singleProjectManualTitle')}
			data={data}
		/>
	)
}

export default SingleProjectManual
