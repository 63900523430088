import { FC } from 'react'

//other
import { formatTime } from '../../../../assets/employees/chat/chatFunctions'

type videoPlayerTimerProps = {
    currentTime: number,
    duration: number
}

const VideoPlayerTimer: FC<videoPlayerTimerProps> = ({ currentTime, duration }) => {
	return (
		<p className="video-player-timer">
			{`${formatTime(currentTime)}/${formatTime(duration)}`}
		</p>
	)
}

export default VideoPlayerTimer