//react
import { Dispatch, FC, SetStateAction, useState } from 'react'


//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// type
import { document } from '../../../../../../types/general/generalTypes'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setDeleteEmployeeFileId, setDeleteEmployeeFileModalIsOpen } from '../../../../../../redux/general/employee/modals'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import {singleEmployeeFileUrl} from '../../../../../../utils/urls/employees/employee'

//translation
import { useTranslation } from 'react-i18next'

type deleteEmployeeFileConfirmationModalProps = {
	setFiles: Dispatch<SetStateAction<document[]>>
	files: document[]
}

const DeleteEmployeeFileConfirmationModal: FC<deleteEmployeeFileConfirmationModalProps> = ({ files, setFiles }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.employee.employeeFiles.modals.deleteEmployeeFiletConfirmationModal' })

	const { deleteEmployeeFileModal } = useAppSelector((state) => state.employeeModals.employeeFiles)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteEmployeeFileModalIsOpen(false))
		dispatch(setDeleteEmployeeFileId(null))
	}

	const deleteFile = (): void => {
		setLoading(true)
		authorizedRequest(singleEmployeeFileUrl(deleteEmployeeFileModal.fileId!), 'DELETE')
			.then((response) => {
				console.log(response)
				const updatedFiles = files.filter(file => file.id !== deleteEmployeeFileModal.fileId)
				setFiles(updatedFiles)
				dispatch(setDeleteEmployeeFileModalIsOpen(false))
				setLoading(false)
			})
	}

	return (
		<ConfirmationModal
			title={t('deleteThisFile')} 
			buttons={[{ text: t('yes'), onClickHandler: deleteFile }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={deleteEmployeeFileModal.modalIsOpen} 
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteEmployeeFileConfirmationModal