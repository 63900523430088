//styles and icons
import './navBarSliderItem.scss'
import { arrowIcon } from '../../../../../../assets/general/generalIcons'

//react
import { FC } from 'react'

//types
import { navBarSliderCaregory, navBarSliderSubCategory } from '../../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type navBarItemProps = navBarSliderCaregory & {
	index: number,
	activeCategory: number,
	setActiveCategory: (value: number) => void
	selectedSubCategory: navBarSliderSubCategory,
	setSelectedSubCategory: (subCategory: navBarSliderSubCategory) => void;
}

const NavBarSliderItem: FC<navBarItemProps> = ({ name, icon, subCategories, index, activeCategory, setActiveCategory, selectedSubCategory, setSelectedSubCategory
}) => {
	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })
	
	const onClickCallback = (index: number) => {
		if(activeCategory != index){
			setActiveCategory(index) 
		}else{
			setActiveCategory(0)
		}
	}

	const onSubCategoryClick = (event: React.MouseEvent, subCategory: navBarSliderSubCategory) => {
		event.stopPropagation()
		setSelectedSubCategory(subCategory)
	}
		
	return (
		<div onClick={() => onClickCallback(index)} className={`navitem ${activeCategory == index  ? 'selected' : ''}`}>
			<div className="navitem-header">
				<div className="navitem-header-text">
					<span className="navitem-header-icons">{icon}</span>
					<p>{t(name)}</p>
				</div>
				<span className="navitem-icons-arrow">{arrowIcon}</span>
			</div>
			<div className='navitem-category-container'>
				{subCategories.map((item, i) => 
				{	 
					return <div 
						key={i}
						className={`navbar-link ${item.id===selectedSubCategory.id? 'active' : ''}`}
						onClick={(event) => onSubCategoryClick(event, item)}
					> 
						<p>{t(item.name)}</p>
					</div>
				})
				}
			</div>
		</div>
	)
}

export default NavBarSliderItem
