//styles and icons
import './cookiesPopup.scss'

//react
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import Button from '../../../../../components/general/button/button'

//translation
import { useTranslation } from 'react-i18next'

const CookiesPopup = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.cookies' })

	const navigate = useNavigate()

	const [showPopup, setShowPopup] = useState<boolean>(false)
	const [displayHidden, setDisplayHidden] = useState(true)

	useEffect(() => {
		const cookiesAccepted = localStorage.getItem('cookiesAccepted')
		if (!cookiesAccepted) {
			setShowPopup(true)
		}
	}, [])

	useEffect(() => {
		console.log(showPopup)
		let timeout: NodeJS.Timeout

		if(showPopup){
			setDisplayHidden(false)
		}else{
			timeout = setTimeout(() => {
				setDisplayHidden(true)
			}, 400)
		}

		return () => clearTimeout(timeout)
 	}, [showPopup])

	const handleAgree = () => {
		localStorage.setItem('cookiesAccepted', 'true')
		setShowPopup(false)
	}


	return (
		<div className='cookies-popup' style={{
			display: displayHidden ? 'none' : 'flex',
			opacity: showPopup ? '1' : '0'
		}}>
			<div className="cookies-popup-text">
				{t('cookiesText')}
			</div>
			<div className="buttons-container">
				<Button active={true} inverted={true} text={t('gotIt')} onClick={()=> handleAgree()}/>
				<Button active={false} inverted={true} outlined={true}  text={t('privacy')}  onClick={()=> navigate('/privacy-policy')}/>
			</div>			
		</div>
	)
}

export default CookiesPopup