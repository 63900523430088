//components
import ExtensionFileItem from './extensionFileItem/extensionFileItem'

//react
import { FC } from 'react'

//types
import { file } from '../../../../../../../types/general/generalTypes'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//other
import { formatFiles } from '../../../../../../../assets/general/generalFunctions'

const ExtensionFilesContainer: FC = () => { 

	const { attachFiles, setAttachedFiles } = useChatDesktop()

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		
		const fileList = e.dataTransfer.files
		const files: File[] = []


		for(let i=0; i<fileList.length; i++){
			files.push(fileList[i])
		}

		formatFiles(files)
			.then(formattedFiles => {
				setAttachedFiles([...attachFiles.attachedFiles, ...formattedFiles])
			})
	}

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}


	return (
		<div className='extension-banner-files-container' 
			onDrop={(e) => handleDrop(e)}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}>
			{

				attachFiles.attachedFiles?.map((file: file, index: number) => {
					return <ExtensionFileItem file={file} index={index} key={`file-list-modal-file-${index}`}/>
				})
			}
		</div>
	)
							
}

export default ExtensionFilesContainer
