//styles and icons
import './dashboardStage.scss'
import { plusIcon } from '../../../../assets/general/generalIcons'

//react
import { FC } from 'react'

// components
import Draggable from '../../dragAndDropHandler/draggable/draggable'
import DropStageArea from '../../dragAndDropHandler/dropStageArea/dropStage'
import DashboardCard from './dashboardCard/dashboardCard'
import DashboardStageInfo from './dashboardStageInfo/dashboardStageInfo'
import Pagination from '../../pagination/pagination'

// types
import { dragLocation, dashboardCard } from '../../../../types/general/generalTypes'

type dashboardStageProps = {
	stageId: number
	color?: string
	cardList: dashboardCard[]
	moveProject?: (dragInfo: dragLocation, dropinfo: dragLocation) => void
	onCardClick?: (data: dashboardCard) => void
	onStageAdd?: (stageName: string, stageId: number) => void
	createAccess: boolean
	isLoading: boolean
	onLoadMore?: (stageId: number) => void
	quantity: number
	stageName: string
}

const DashboardStage: FC<dashboardStageProps> = ({ stageId, cardList, color, onStageAdd, moveProject, onCardClick, createAccess, isLoading, quantity, stageName, onLoadMore }) => {

	const handleOnStageAdd = () => {
		onStageAdd && onStageAdd(stageName, stageId)
	}

	return (
		<div className='dashboard-stage-container'>
			<div className="dashboard-stage-header" style={{ borderTop: `0.3vw solid ${color ?? 'var(--accentPrimary)'}` }}>
				<DashboardStageInfo cardsLength={quantity} stage={stageName} isLoading={isLoading} />
				{
					createAccess && onStageAdd ?
						<div className="icon" onClick={handleOnStageAdd}>
							{plusIcon}
						</div>
						: null
				}
			</div>
			<Pagination showLoader={false} loading={isLoading} loadAxis="vertical" onLoadMore={() => {
				onLoadMore && onLoadMore(stageId)
			}}>
				<div className='dashboard-stage'>
					<DropStageArea
						stageId={stageId}
						stage={stageName}
						className='dashboard-items'
						onDrop={moveProject}
					>
						{cardList.map((card, index: number) => {
							return (
								<Draggable
									className="dashboard-item-container"
									dragData={card}
									index={index}
									stageId={stageId}
									stage={stageName}
									key={'project-card' + index}
								>
									<DashboardCard
										{...card}
										onClick={() => {
											onCardClick && onCardClick(card)
										}}
										executors={card.executors}
									/>
								</Draggable>
							)
						})}
					</DropStageArea>
				</div>

			</Pagination>
		</div>
	)
}

export default DashboardStage
