//components
import Tree from '../../tree/tree'

//react
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//network
import { formatProjectResponse, formatTaskResponse } from '../../../../assets/projects/projectsFunctions'
import { authorizedRequest } from '../../../../utils/queries'
import { getProjectTreeUrl, singleProjectUrl } from '../../../../utils/urls/projects/project'

//types
import { dashboardCard, projectResponse, task, taskResponse, treeElement } from '../../../../types/general/generalTypes'

//other
import DashboardCard from '../../dashboard/dashboardStage/dashboardCard/dashboardCard'

type subtaskArray = taskTrackingNodeProps[]

type taskTrackingNodeProps = {
	id: number,
	element: JSX.Element,
	subElements: taskTrackingNodeProps[] | [],
}

type orderTrackingProps = {
	projectId: number | undefined
}


const OrderTracking: FC<orderTrackingProps> = ({ projectId }) => {

	const [trackingElements, setTrackingElements] = useState<treeElement>()

	const navigate = useNavigate()

	const getTrackingData = async () => {
		const response = await authorizedRequest(getProjectTreeUrl(Number(projectId)), 'GET')
		const { result: projectResult }: { result: projectResponse } = await authorizedRequest(singleProjectUrl(Number(projectId)), 'GET')
		const formatedProjectResponse = formatProjectResponse(projectResult)

		const result: taskResponse[] = response.result

		const projectTree = (): taskTrackingNodeProps => {
			return {
				id: 0,
				element: <DashboardCard endDate={formatedProjectResponse.dueDate} startDate={formatedProjectResponse.date} isInsideReactFlow={true} {...formatedProjectResponse} onClick={() => { () => navigate(`/ri-business/projects/project/${projectId}`) }} />,
				subElements: [...result].map((task: taskResponse) => taskTree(task))
			}
		}

		const taskTree = (initialTask: taskResponse): taskTrackingNodeProps => {
			const firstElement = formatTaskResponse(initialTask)
			return {
				id: firstElement.id,
				element: <DashboardCard key={firstElement.id} isInsideReactFlow={true} {...firstElement} onClick={() => { () => navigate(`/ri-business/projects/task/${firstElement.id}`) }} />,
				subElements: generateSubtaskNodes(firstElement)
			}
		}

		const generateSubtaskNodes = (treeElement: task<Date | undefined>): subtaskArray => {
			const subtaskArr = treeElement.subtasks.map((subtask: task<Date | undefined>) => {
				return {
					id: subtask.id,
					element: <DashboardCard key={subtask.id} isInsideReactFlow={true} {...subtask} onClick={() => { () => navigate(`/ri-business/projects/task/${subtask.id}`) }} />,
					subElements: generateSubtaskNodes(subtask)
				}
			})
			return subtaskArr
		}

		setTrackingElements(projectTree())
	}

	useEffect(() => {
		getTrackingData()
	}, [projectId])

	return (
		trackingElements ? <Tree treeElements={trackingElements} minZoom={0.3} maxZoom={4} controls={true} /> : null
	)
}

export default OrderTracking