// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// react
import { Dispatch, FC, SetStateAction, useState } from 'react'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setDeleteCustomerAddressId, setDeleteCustomerAddressModalIsOpen } from '../../../../../../redux/sales/customer/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleCustomersAddressUrl } from '../../../../../../utils/urls/sales/customer'

// translation
import { useTranslation } from 'react-i18next'

// types
import { address } from '../../../../../../types/sales/customerTypes'

type deleteCustomerAddressModalProps = {
	addresses: address[]
	setAddresses: Dispatch<SetStateAction<address[]>>
}
const DeleteCustomerAddressModal : FC<deleteCustomerAddressModalProps> = ({addresses, setAddresses}) => {
	const { modalIsOpen, addressId } = useAppSelector( state  => state.customerModal.customerAddresses.deleteCustomerAddressModal )

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const {t} = useTranslation('', {keyPrefix: 'sales.customer.customerAddressList.modals.deleteCustomerAddressModal'})

	const closeModal = () => {
		dispatch( setDeleteCustomerAddressModalIsOpen(false) )
		dispatch( setDeleteCustomerAddressId(null) )
	}

	const deleteOffering = () => {
		if(addressId){
			setLoading(true)
			authorizedRequest( singleCustomersAddressUrl(addressId), 'DELETE' )
				.then(({ result }) => {
					const updatedOfferings = addresses.filter(address => address.id !== addressId)
					setAddresses(updatedOfferings)
					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteOffering }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeleteCustomerAddressModal