// styles and icons
import './createBillModal.scss'

// react
import { FC, FormEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Modal from '../../modal/modal'
import InputField from '../../../inputField/inputField'
import TextArea from '../../../textArea/textArea'
import Dropdown from '../../../dropdown/dropdown'
import CalendarContainer from '../../../calendarContainer/calendarContainer'


// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateBillModalIsOpen } from '../../../../../redux/general/modals'

// types 
import { currency, dropdownOption, employeeResponse, infoPopupTypes } from '../../../../../types/general/generalTypes'
import { bill, billStatus, expenseCategory, transactionType } from '../../../../../types/finance/general'
import { budgetResponse } from '../../../../../types/finance/budgetTypes'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { createBillUrl } from '../../../../../utils/old_urls/general/generalUrls'
import { singleBudgetUrl, companyBudgetsUrl } from '../../../../../utils/urls/finance/budget/budget'
import { companySearchEmployeesUrl } from '../../../../../utils/urls/employees/search'

// other
import { createDropdownOption, formatStringIntoTwoDigitsFloat, getEnumKeyFromValue, translateEnum } from '../../../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../../../assets/finance/general/generalFunctions'

//translaction
import { useTranslation } from 'react-i18next'
import FloatInput from '../../../floatInput/floatInput'


type formErrors = {
	title?: string
	amount?: string
	budget?: string
}

type createBillModalProps = {
	setBills: (value: bill[]) => void
	bills: bill[]
}

const CreateBillModal: FC<createBillModalProps> = ({ setBills, bills }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.modals.bill.createBillModal' })
	const tExpense = useTranslation('', { keyPrefix: 'general.expenseCategory' }).t
	const tStatus = useTranslation('', { keyPrefix: 'general.billStatus' }).t
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t
	const tTransaction = useTranslation('', { keyPrefix: 'general.transactionType' }).t

	const [selectedBudgetOption, setSelectedBudgetOption] = useState<dropdownOption | null>(null)
	const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<dropdownOption | null>(null)
	const [selectedCurrencyOption, setSelectedCurrencyOption] = useState<dropdownOption | null>(null)
	const [selectedTransactionTypeOption, setSelectedTransactionTypeOption] = useState<dropdownOption | null>(null)
	const [selectedStatusOption, setSelectedStatusOption] = useState<dropdownOption | null>(null)
	const [selectedCategoryOption, setSelectedCategoryOption] = useState<dropdownOption | null>(null)
	const [amount, setAmount] = useState<number>(0)
	const [description, setDescription] = useState<string>('')
	const [endDate, setEndDate] = useState<Date | undefined>(new Date)
	const [title, setTitle] = useState<string>('')
	const [defaultBudget, setDefaultBudget] = useState<dropdownOption>()

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.createBillModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)
	const [errors, setErrors] = useState<formErrors>({})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { relationshipId } = useParams()
	const { budgetId } = useParams()

	const amountInputHandle = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setAmount(numberValue)
	}

	const createBill = async () => {
		setIsLoading(true)
		const formatedDate = (date: Date | undefined) =>
			date ? date.toISOString() : undefined

		const { result } = await authorizedRequest(createBillUrl(companyId), 'POST', 'accessToken', {
			data: [{
				company_id: companyId,
				relationship_id: Number(relationshipId),
				budget_id: selectedBudgetOption?.key,
				employee_id: selectedEmployeeOption?.key,
				title: title,
				status: selectedStatusOption?.key,
				description: description,
				category: selectedCategoryOption?.key,
				amount: amount,
				currency: selectedCurrencyOption?.key,
				type: selectedTransactionTypeOption?.key,
				due_date: formatedDate(endDate)
			}]
		})

		const updatedBills: bill[] = result.map((formatBillResponse))

		setBills([...bills, ...updatedBills])
		closeModal()
		setIsLoading(false)
	}

	const infoPopup = {
		content: [{
			title: 'create_bill_title',
			description: 'create_bill_description',
		}],
		type: infoPopupTypes.INFO
	}

	const budgetPopup = {
		content: [
			{
				title: 'create_budget_info_title',
				description: 'create_budget_info_description',
				link: '/ri-business/finance/budget-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const loadBudgets = async (request: string, page: number) => {
		const { result } = await authorizedRequest(companyBudgetsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}`, 'GET')

		if (result.length > 0) {
			const formatedBudgets: dropdownOption[] = result.map((budget: budgetResponse) => {
				return {
					key: budget.id,
					title: budget.title,
				}
			})
			return formatedBudgets
		}
		return []
	}

	const loadEmployees = async (query: string, page: number) => {

		const { result }: { result: employeeResponse[] } = await authorizedRequest(companySearchEmployeesUrl(companyId) + `?needle=${query}&page=${page}&per_page=${10}`, 'GET')

		if (result.length > 0) {

			const formatedEmployees: dropdownOption[] = result.map(employee => {
				return {
					key: `${employee.employee_id}`,
					title: employee.name,
					avatar: employee.avatar
				}
			})

			return formatedEmployees
		}

		return []

	}

	const getBudget = async () => {
		if(budgetId){

			const { result } = await authorizedRequest(singleBudgetUrl(Number(budgetId)), 'GET')
			if (result) {
				const budgetResponse: dropdownOption = {
					key: result.id,
					title: result.title,
				}
				return budgetResponse
			}
	
			return undefined
		}
	}

	const closeModal = () => {
		setSelectedCategoryOption(null)
		setSelectedTransactionTypeOption(null)
		setSelectedCurrencyOption(null)
		setSelectedBudgetOption(null)
		setAmount(0)
		setDescription('')
		setEndDate(new Date())
		setTitle('')
		setErrors({})
		setSelectedStatusOption(null)

		dispatch(setCreateBillModalIsOpen(false))
	}

	const checkErrors = () => {
		let result = true

		if (title === '') {
			setErrors(prevErrors => ({ ...prevErrors, title: t('pleaseEnterTitle') }))
			result = false
		}
		if (amount === 0) {
			setErrors(prevErrors => ({ ...prevErrors, amount: t('pleaseEnterBillAmount') }))
			result = false
		}
		console.log('Бюджет ID', selectedBudgetOption !== undefined)
		if (!selectedBudgetOption) {
			setErrors(prevErrors => ({ ...prevErrors, budget: t('pleaseSelectBudget') }))
			result = false
		}

		return result
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (checkErrors()) {
			createBill()
			setErrors({})
		}
	}

	useEffect(() => {
		getBudget()
			.then((response) => {
				setDefaultBudget(response)
			})
	}, [modalIsOpen])

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			titlePopup={infoPopup}
			title={t('title')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
			isLoading={isLoading}
		>

			<form
				className='create-bill-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className='first-column-form-elements-wrapper'>
					<InputField
						type='text'
						placeholder={t('titleInput')}
						label={t('titleInput')}
						value={title}
						onChange={e => setTitle(e.target.value)}
						error={errors.title}
					/>
					<TextArea
						label={t('description')}
						placeholder={t('description')}
						value={description}
						setValue={value => setDescription(value)}

					/>
					<FloatInput
						label={t('total')}
						error={errors.amount}
						amountInputHandle={amountInputHandle}
						value={`${amount}`}
					/>
					<Dropdown
						label={t('employee')}
						placeholder={t('employee')}
						loadOptions={loadEmployees}
						onSelect={({ key, title }) => setSelectedEmployeeOption({ key, title })}
						dropdownOptions={[]}
						selectedOption={selectedEmployeeOption}
					/>
					<Dropdown
						label={t('payStatus')}
						placeholder={translateEnum(tStatus, selectedStatusOption?.key || '')}
						dropdownOptions={createDropdownOption(tStatus, billStatus)}
						onSelect={({ key, title }) => setSelectedStatusOption({ key, title })}
						selectedOption={selectedStatusOption}
						defaultSelectedOption={{ key: 'pending', title: translateEnum(tStatus, 'pending') }}
					/>
					<Dropdown
						label={t('currency')}
						placeholder={selectedCurrencyOption?.key || ''}
						dropdownOptions={createDropdownOption(tCurrency, currency)}
						onSelect={({ key, title }) => setSelectedCurrencyOption({ key, title })}
						selectedOption={selectedCurrencyOption}
						defaultSelectedOption={{ key: 'UAH', title: translateEnum(tCurrency, 'UAH') }}
					/>
					<Dropdown
						label={t('expenseCategory')}
						placeholder={translateEnum(tExpense, getEnumKeyFromValue(expenseCategory, selectedCategoryOption?.key || ''))}
						dropdownOptions={createDropdownOption(tExpense, expenseCategory)}
						onSelect={({ key, title }) => setSelectedCategoryOption({ key, title })}
						selectedOption={selectedCategoryOption}
						defaultSelectedOption={{ title: translateEnum(tExpense, 'miscellaneous_expenses'), key: 'miscellaneous_expenses' }}
					/>
					<Dropdown
						label={t('transactionType')}
						placeholder={translateEnum(tTransaction, selectedTransactionTypeOption?.key || '')}
						dropdownOptions={createDropdownOption(tTransaction, transactionType)}
						onSelect={({ key, title }) => setSelectedTransactionTypeOption({ key, title })}
						selectedOption={selectedTransactionTypeOption}
						defaultSelectedOption={{ title: translateEnum(tTransaction, 'expense'), key: 'expense' }}
					/>
					<Dropdown
						popup={budgetPopup}
						label={t('budget')}
						placeholder={t('budget')}
						loadOptions={loadBudgets}
						onSelect={({ key, title }) => setSelectedBudgetOption({ key, title })}
						dropdownOptions={[]}
						disabled={budgetId ? true : false}
						defaultSelectedOption={defaultBudget}
						error={errors.budget}
						selectedOption={selectedBudgetOption}
					/>
				</div>
				<div className='second-colums-form-elements-wrapper'>
					<CalendarContainer
						label={t('timeline')}
						dueDate={{
							date: endDate,
							setDate: (value) => { setEndDate(value) }
						}}
						size='medium'
					/>
				</div>
			</form>
		</Modal>
	)
}

export default CreateBillModal