// styles and icons
import './riBusinessDescription.scss'
import { arrowIcon } from '../../../../../assets/general/generalIcons'

//images
import Image1UA from '../../../assets/images/riBusinessDescription/ua/image1UA.png'
import Image2UA from '../../../assets/images/riBusinessDescription/ua/image2UA.png'
import Image3UA from '../../../assets/images/riBusinessDescription/ua/image3UA.png'
import Image4UA from '../../../assets/images/riBusinessDescription/ua/image4UA.png'
import Image5UA from '../../../assets/images/riBusinessDescription/ua/image5UA.png'
import Image6UA from '../../../assets/images/riBusinessDescription/ua/image6UA.png'
import Image7UA from '../../../assets/images/riBusinessDescription/ua/image7UA.png'
import Image8UA from '../../../assets/images/riBusinessDescription/ua/image8UA.png'
import Image9UA from '../../../assets/images/riBusinessDescription/ua/image9UA.png'
import Image10UA from '../../../assets/images/riBusinessDescription/ua/image10UA.png'
import Image11UA from '../../../assets/images/riBusinessDescription/ua/image11UA.png'
import Image12UA from '../../../assets/images/riBusinessDescription/ua/image12UA.png'
import Image13UA from '../../../assets/images/riBusinessDescription/ua/image13UA.png'
import Image14UA from '../../../assets/images/riBusinessDescription/ua/image14UA.png'
import Image15UA from '../../../assets/images/riBusinessDescription/ua/image15UA.png'
import Image16UA from '../../../assets/images/riBusinessDescription/ua/image16UA.png'
import Image17UA from '../../../assets/images/riBusinessDescription/ua/image17UA.png'
import Image18UA from '../../../assets/images/riBusinessDescription/ua/image18UA.png'
import Image19UA from '../../../assets/images/riBusinessDescription/ua/image19UA.png'
import Image20UA from '../../../assets/images/riBusinessDescription/ua/image20UA.png'
import Image21UA from '../../../assets/images/riBusinessDescription/ua/image21UA.png'
import Image22UA from '../../../assets/images/riBusinessDescription/ua/image22UA.png'
import Image23UA from '../../../assets/images/riBusinessDescription/ua/image23UA.png'

import Image1EN from '../../../assets/images/riBusinessDescription/en/image1EN.png'
import Image2EN from '../../../assets/images/riBusinessDescription/en/image2EN.png'
import Image3EN from '../../../assets/images/riBusinessDescription/en/image3EN.png'
import Image4EN from '../../../assets/images/riBusinessDescription/en/image4EN.png'
import Image5EN from '../../../assets/images/riBusinessDescription/en/image5EN.png'
import Image6EN from '../../../assets/images/riBusinessDescription/en/image6EN.png'
import Image7EN from '../../../assets/images/riBusinessDescription/en/image7EN.png'
import Image8EN from '../../../assets/images/riBusinessDescription/en/image8EN.png'
import Image9EN from '../../../assets/images/riBusinessDescription/en/image9EN.png'
import Image10EN from '../../../assets/images/riBusinessDescription/en/image10EN.png'
import Image11EN from '../../../assets/images/riBusinessDescription/en/image11EN.png'
import Image12EN from '../../../assets/images/riBusinessDescription/en/image12EN.png'
import Image13EN from '../../../assets/images/riBusinessDescription/en/image13EN.png'
import Image14EN from '../../../assets/images/riBusinessDescription/en/image14EN.png'
import Image15EN from '../../../assets/images/riBusinessDescription/en/image15EN.png'
import Image16EN from '../../../assets/images/riBusinessDescription/en/image16EN.png'
import Image17EN from '../../../assets/images/riBusinessDescription/en/image17EN.png'
import Image18EN from '../../../assets/images/riBusinessDescription/en/image18EN.png'
import Image19EN from '../../../assets/images/riBusinessDescription/en/image19EN.png'
import Image20EN from '../../../assets/images/riBusinessDescription/en/image20EN.png'
import Image21EN from '../../../assets/images/riBusinessDescription/en/image21EN.png'
import Image22EN from '../../../assets/images/riBusinessDescription/en/image22EN.png'
import Image23EN from '../../../assets/images/riBusinessDescription/en/image23EN.png'

//react
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

// components
import ScrollAnimation from '../../general/scrollAnimation/scrollAnimation'
import Title from '../../general/title/title'
import Subtitle from '../../general/subtitle/subtitle'
import HeaderWithLines from '../../general/headerWithLines/headerWithLines'
import Button from '../../../../../components/general/button/button'
import NavBarSlider from '../../general/navBarSlider/navBarSlider'

//translation
import { useTranslation } from 'react-i18next'
import { bag, clock, spark } from '../../../assets/icons/icons'


const RIBusinessDescription = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.productDescription.riBusinessDescription' })

	const { language } = useAppSelector((state) => state.general)

	const slides = [
		{
			name: t('expensiveApplication'),
			number: 1,
			description: t('completelyFreeToUseBusinessManagementApp'),
			image: language === 'ua' ? Image1UA : Image1EN,
			color: '#6248FF'
		},
		{
			name: t('documentChaos'),
			number: 2,
			description: t('organizedDocumentStorage'),
			image: language === 'ua' ? Image2UA : Image2EN,
			color: '#6248FF'
		},
		{
			name: t('teamMiscommunication'),
			number: 3,
			description: t('unifiedTransparentAndOrganizedCommunication'),
			image: language === 'ua' ? Image3UA : Image3EN,
			color: '#0ACF83'
		},
		{
			name: t('taskConfusionAndOversight'),
			number: 4,
			description: t('accurateTaskAllocation'),
			image: language === 'ua' ? Image4UA : Image4EN,
			color: '#6248FF'
		},
		{
			name: t('dataSecurityIssues'),
			number: 5,
			description: t('flexibleUserPermissions'),
			image: language === 'ua' ? Image5UA : Image5EN,
			color: '#1ABCFE'
		},
		{
			name: t('ineffectiveWorkforceDeployment'),
			number: 6,
			description: t('efficientWorkforceManagementRecommendation'),
			image: language === 'ua' ? Image6UA : Image6EN,
			color: '#6248FF'
		},
		{
			name: t('uncertainFinancialFuture'),
			number: 7,
			description: t('helpfulFinancialInsights'),
			image: language === 'ua' ? Image7UA : Image7EN,
			color: '#0ACF83'
		},
		{
			name: t('sophisticatedManualFinancialTracking'),
			number: 8,
			description: t('automatedFinancialTracking'),
			image: language === 'ua' ? Image8UA : Image8EN,
			color: '#6248FF'
		},
		{
			name: t('employeeExpenseTracking'),
			number: 9,
			description: t('detailedEmployeeExpenseReports'),
			image: language === 'ua' ? Image9UA : Image9EN,
			color: '#0ACF83'
		},
		{
			name: t('accountStateTrackingComplications'),
			number: 10,
			description: t('bankAccountTracking'),
			image: language === 'ua' ? Image10UA : Image10EN,
			color: '#6248FF'
		},
		{
			name: t('budgetOversights'),
			number: 11,
			description: t('budgetOptimization'),
			image: language === 'ua' ? Image11UA : Image11EN,
			color: '#0ACF83'
		},
		{
			name: t('chaoticWarehouseOperations'),
			number: 12,
			description: t('organizationOfWarehouseMovingsAndShipments'),
			image: language === 'ua' ? Image12UA : Image12EN,
			color: '#6248FF'
		},
		{
			name: t('projectTrackingDifficulties'),
			number: 13,
			description: t('comprehensiveProjectOrganizingAndTracking'),
			image: language === 'ua' ? Image13UA : Image13EN,
			color: '#1ABCFE'
		},
		{
			name: t('employeeTaskAndProjectTracking'),
			number: 14,
			description: t('organizedTaskAndProjectOverview'),
			image: language === 'ua' ? Image14UA : Image14EN,
			color: '#1ABCFE'
		},
		{
			name: t('scatteredProviderInformationAndOrders'),
			number: 15,
			description: t('centralizedProviderAndPurchaseOrdersDatabase'),
			image: language === 'ua' ? Image15UA : Image15EN,
			color: '#0ACF83'
		},
		{
			name: t('inefficientPurchasingProcesses'),
			number: 16,
			description: t('enhancedPurchasingProcess'),
			image: language === 'ua' ? Image16UA : Image16EN,
			color: '#1ABCFE'
		},
		{
			name: t('warehouseInefficiencies'),
			number: 17,
			description: t('optimizedWarehouseLogistics'),
			image: language === 'ua' ? Image17UA : Image17EN,
			color: '#1ABCFE'
		},
		{
			name: t('missedOrdersAndProjects'),
			number: 18,
			description: t('ensureYourOrdersAreAlwaysOnTrack'),
			image: language === 'ua' ? Image18UA : Image18EN,
			color: '#6248FF'
		},
		{
			name: t('lackOfFinancialVisibility'),
			number: 19,
			description: t('graphicalFinancialStatistics'),
			image: language === 'ua' ? Image19UA : Image19EN,
			color: '#1ABCFE'
		},
		{
			name: t('manualIncomeAndExpenseTracking'),
			number: 20,
			description: t('detailedIncomeAndExpenseReports'),
			image: language === 'ua' ? Image20UA : Image20EN,
			color: '#6248FF'
		},
		{
			name: t('unclearInventoryStatus'),
			number: 21,
			description: t('completeInventoryManagement'),
			image: language === 'ua' ? Image21UA : Image21EN,
			color: '#0ACF83'
		},
		{
			name: t('sophisticatedInventoryRelationships'),
			number: 22,
			description: t('cleanAndFlexibleInventoryItems'),
			image: language === 'ua' ? Image22UA : Image22EN,
			color: '#1ABCFE'
		},
		{
			name: t('inefficientPurchasingDecisions'),
			number: 23,
			description: t('efficientPurchasingAlgorithms'),
			image: language === 'ua' ? Image23UA : Image23EN,
			color: '#0ACF83'
		}
	]		

	const cards = [
		{
			title: t('free'),
			icon:bag,
			color: '#1ABCFE',
			background: 'rgb(237, 250, 254)',
			description: t('noPayment')
		},
		{
			title: t('quick'),
			icon:clock,
			color: '#0ACF83',
			background: 'rgb(240, 251, 248)',
			description: t('takeLess')
		},
		{
			title: t('AIEmpowered'),
			icon:spark,
			background: 'rgb(248, 247, 254)',
			color: '#6248FF',
			description: t('letOur')
		}
	]

	const [currentBox, setCurrentBox] = useState(slides[0])
	const [currentPage, setCurrentPage] = useState(1)
	const [cardsPerPage, setCardsPerPage] = useState(6)
	const [totalPages, setTotalPages] = useState(Math.ceil(slides.length/cardsPerPage))

	const navigate = useNavigate()

	const handleClick = (number:number)=>{
		slides.forEach((slide)=>{
			if(slide.number === number){
				setCurrentBox(slide)
			}
		})
	}

	const prevPage = () => {
		if(currentPage > 1){
			setCurrentPage(currentPage-1)
		}
	}

	const nextPage = () => {
		if(currentPage < Math.ceil(slides.length/cardsPerPage)){
			setCurrentPage(currentPage+1)
		}
	}

	const handleResize = () => {
		setCardsPerPage(screen.width > 425 ? 6 : 2)
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		setTotalPages(Math.ceil(slides.length/cardsPerPage))
	}, [cardsPerPage])


	return (
		<div className="ri-business-description">
			<HeaderWithLines>
				<ScrollAnimation direction='to-top' distance={2}>
					<Title>
						{t('spikeUpYour')}
						<span className='text-green'>
							{` ${t('profitability')} `}
						</span>
						{t('and')}
						<span className='text-blue'>
							{` ${t('efficiency')} `}
						</span>
						{t('with')}
						<span className="text-cyan">
							{` ${t('riBusiness')} `}
						</span>
					</Title>
					<Subtitle>
						{t('designed')}<span className='text-green'>{` ${t('you')} `}</span>{t('andYour')}<span className='text-blue'>{` ${t('business')} `}</span>{t('inMind')}
					</Subtitle>
				</ScrollAnimation>
			</HeaderWithLines>
			<ScrollAnimation direction='to-top' distance={2}>
				<div className="ri-business-description-wrapper">
					<Title>
						{t('oneAppTo')}<span className='text-cyan'>{` ${t('solve')} `}</span>{t('allOfYour')}<span className='text-blue'>{` ${t('problems')} `}</span>
					</Title>
					<div className="ri-business-description-container">
						{slides.slice((currentPage-1)*cardsPerPage, (currentPage)*cardsPerPage).map((slide)=>{
							return (
								<div className={`ri-business-description-item ${currentBox.number===slide.number? 'active': ''}`} onClick={()=>{handleClick(slide.number)}}>
									<h2 className="item-number">{slide.number}</h2>
									<h3 className="item-name">{slide.name}</h3>
									<div className="item-description">{slide.description}</div>
								</div>
							)
						})}
						<div className="ri-business-description-item description-image" style={{backgroundColor:`${currentBox.color}`}}>
							<img src={currentBox.image} alt={currentBox.name}/>
						</div>
					</div>
					<div className="ri-business-description-pagination-container">
						<div className="arrow" onClick={prevPage}>
							{arrowIcon}
						</div>
						<div className="pages">
							{currentPage}/{totalPages}
						</div>
						<div className="arrow" onClick={nextPage}>
							{arrowIcon}
						</div>
					</div>
				</div>
			</ScrollAnimation>
			<ScrollAnimation direction='to-top' distance={2}>
				<div className="ri-business-benefits-cards-wrapper">
					<Title>
						{t('explore')}<span className='text-green'>{` ${t('some')} `}</span>{t('functionality')}<span className='text-blue'>{` ${t('features')} `}</span>
					</Title>
					<div className="ri-business-benefits-cards-container">
						<NavBarSlider/>
					</div>
				</div>
			</ScrollAnimation>
			<ScrollAnimation direction='to-top' distance={2}>
				<div className="ri-business-benefits-cards-wrapper">
					<Title>
						{t('whyNot')}<span className='text-green'>{` ${t('give')} `}</span>{t('it')}<span className='text-blue'>{` ${t('aShot')} `}</span>
					</Title>
					<div className="ri-business-benefits-cards-container">
						{cards.map((card)=>{
							return (
								<div className="ri-business-benefits-card" style={{backgroundColor: `${card.background}`, border: `1px solid ${card.color}`}}>
									<div className="card-icon" style={{color:`${card.color}`}}>{card.icon}</div>
									<div className="card-separation" style={{backgroundColor: `${card.color}`}}></div>
									<h3 className="card-title" style={{color:`${card.color}`}}>{card.title}</h3>
									<div className="card-description">{card.description}</div>
								</div>
							)
						})}
					</div>
				</div>
			</ScrollAnimation>
			<ScrollAnimation direction='to-top' distance={2}>
				<div className="ri-business-video-wrapper">
					<Title>
						{t('lookAt')}<span className='text-green'>{` ${t('how')} `}</span>{t('itVideo')}<span className='text-blue'>{` ${t('works')} `}</span>
					</Title>
					<div className="ri-business-video-container">
						<iframe
							width="853"
							height="480"
							src={`https://www.youtube.com/embed/${language === 'ua' ? 'EVHN6XAz--8' : 'u7CPh7JeEpg'}`}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							title="Embedded youtube"
						/>
					</div>
				</div>
			</ScrollAnimation>
			<ScrollAnimation direction='to-top' distance={2}>
				<div className="ri-business-manual-wrapper">
					<Title>
						{t('dontKnow')}<span className='text-green'>{` ${t('how')} `}</span>{t('to')}<span className='text-blue'> {t('setUp')}</span>
					</Title>
					<Subtitle>
						{t('followTheLinkBelowToGetThe')}<span className='text-green'>{` ${t('setUpInstructions')} `}</span>{t('orContactOur')}<span className='text-blue'>{` ${t('customerSupport')} `}</span>
					</Subtitle>
					<div className="ri-business-manual-container">
						<Button text={t('instractions')} active onClick={() => {
							navigate('/ri-manual/set-up')
						}} />
						<p className='ri-business-manual-text'>{t('or')}</p>
						<div className="ri-business-manual-customer-support">
							<p className='ri-business-manual-text'>{t('ourCustomerSupport')}</p>
							<div className="ri-business-manual-customer-support-container">
								<a href='https://www.facebook.com/profile.php?id=61559840792929' target='_blank'>Facebook</a>
								<a href='https://www.instagram.com/ri.software' target='_blank'>Instagram</a>
								<a href='https://www.linkedin.com/company/ri-software/' target='_blank'>LinkedIn</a>
								<a href="mailto: support@ri-software.com.ua">support@ri-software.com.ua</a>
							</div>
						</div>
					</div>
				</div>
			</ScrollAnimation>
		</div>
	)
}

export default RIBusinessDescription