// styles
import './home.scss'

// components
import Layout from '../../components/general/layout/layout'
import HomeHeroSection from '../../components/home/homeHeroSection/homeHeroSection'
import AppsList from '../../components/home/appsList/appsList'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import AISection from '../../components/home/aiSection/aiSection'

const Home = () => {
	
	return (
		<Layout>
			<HomeHeroSection/>
			
			<AppsList/>
			<AISection/>
			<ParticlesBg/>
		</Layout>
	)
}

export default Home