// styles
import './dashboardActions.scss'

// react
import { CSSProperties, FC } from 'react'

// component
import DropArea from '../../dragAndDropHandler/dropArea/dropArea'

// context
import useDragAndDrop from '../../dragAndDropHandler/context/dragAndDropHandlerContext'

// types
import { dashboardAction, onDropSuccess } from '../../../../types/general/generalTypes'

type dashboardActionsProps = {
	actions: dashboardAction[]
}

const DashboardActions : FC<dashboardActionsProps> = ({actions}) => {
	const dragAndDropContext = useDragAndDrop()
	return (
		<div className={`dashboard-actions-container ${dragAndDropContext.isDragging ? 'show' : ''} `}>
			{
				actions.map((action, index) => {
					return <DropArea
						className={`dashboard-action ${action.slug}`}
						onDropSuccess={action.onDrop as onDropSuccess}
						stageId={-1}
						stage={action.slug}
						key={`dashboard-action-${index}-${action.slug}`}
						style={{'--accent': action.color || 'var(--accentPrimary)'} as CSSProperties}
					>
						{action.label}
					</DropArea>
				})
			}
		</div>
	)
}

export default DashboardActions