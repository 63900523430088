// styles and icons
import './productDescription.scss'

// components
import Layout from '../../components/general/layout/layout'
import RIBusinessDescription from '../../components/productDescription/riBusinessDescription/riBusinessDescription'
import SectionWithLines from '../../components/general/sectionWithLines/sectionWithLines'
import ScrollAnimation from '../../components/general/scrollAnimation/scrollAnimation'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import Title from '../../components/general/title/title'
import Subtitle from '../../components/general/subtitle/subtitle'

// custom hooks
import useScreenSize from '../../customHooks/useScreenSize'

//translation
import { useTranslation } from 'react-i18next'

type section = {
	heading: string
	text: string
	image: any
}

const divideForChunks = (array: section[], chunkSize: number) => {
	const result: section[][] = []
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize))
	}
	return result
}

const ProductDescription = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.productDescription' })

	const { screenWidth } = useScreenSize()

	return (
		<Layout>
			<SectionWithLines 
				sectionHeight={screenWidth <= 768 ? '70vw' : '60vw'}
				leftLineStyles={
					screenWidth < 768 ? {width: '30vw',left: '-23%', top: '-11%', transform: 'rotateZ(20deg)', hidden: false}: undefined}
				rightLineStyles={
					screenWidth < 768 ? {width: '30vw',left: '93%', top: '5%', transform: 'rotateZ(200deg)', hidden: false}: undefined}

			>
				<div className='product-description-hero'>
					<ScrollAnimation direction='to-bottom'>
						<div className='product-description-hero-content'>
							<Title>
								{t('meetRIBusiness')}
							</Title>
							<Subtitle>{t('subHeading')}</Subtitle>
						</div>
					</ScrollAnimation>
				</div>
			</SectionWithLines>

			<RIBusinessDescription/>
			<ParticlesBg/>
		</Layout>
	)
}

export default ProductDescription