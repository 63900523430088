// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import EditableTable from '../../../general/editableTable/editableTable'

// type
import { editableTableItem, inventoryItem } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleWarehouseInventoryUrl } from '../../../../utils/urls/warehouses/warehouses/warehouses'

// other
import { formatInventoryItemResponse } from '../../../../assets/general/generalFunctions'

type warehouseInventoryListProps = {
	editAccess: boolean
}

const WarehouseInventoryList: FC<warehouseInventoryListProps> = ({ editAccess }) => {
	const { warehouseId } = useParams()

	const [inventoryItems, setInventoryItems] = useState<inventoryItem[]>([])
	const [editItems, setEditItems] = useState<boolean>(false)

	const loadData = async (request: string, page: number) => {
		const { result } = await authorizedRequest(singleWarehouseInventoryUrl(Number(warehouseId)) + `?page=${page}&per_page=${10}&needle=${request}`, 'GET')
		return result.map(formatInventoryItemResponse)
	}

	const editData = async () => {
		if (Number(warehouseId)) {
			await authorizedRequest(singleWarehouseInventoryUrl(Number(warehouseId)), 'PUT', 'accessToken', {
				inventory_items: inventoryItems
			})
			setEditItems(false)
		}
	}

	useEffect(() => {

		let timeout: NodeJS.Timeout
		if (editItems) {
			timeout = setTimeout(() => {
				editData()
			}, 500)

		}
		return () => clearTimeout(timeout)

	}, [editItems])

	return (
		<EditableTable
			items={inventoryItems}
			setItems={(value) => {
				setInventoryItems(value as inventoryItem[])
				setEditItems(true)
			}}
			loadItems={loadData}
			disabled={!editAccess}
			limitMaxQuantity={false}
		/>
	)
}

export default WarehouseInventoryList


