// styles
import './footer.scss'

// react
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

// components
import ScrollAnimation from '../../scrollAnimation/scrollAnimation'
import Button from '../../../../../../components/general/button/button'
import Title from '../../title/title'
import Subtitle from '../../subtitle/subtitle'
import Text from '../../text/text'

//translation
import { useTranslation } from 'react-i18next'

const Footer: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.footer' })

	const navigate = useNavigate()
	return (
		<div className="footer">
			<div className="footer-info">
				<div className="footer-headline-container">
					<ScrollAnimation direction='to-right'>
						<Title>
							{t('builtWithSimplicity')} <br/> {t('designedForResults')}
						</Title>
					</ScrollAnimation>
				</div>
				<div className="footer-details">
					<ScrollAnimation direction='to-left'>
						<Text>{t('details')}</Text>
						<div className="footer-actions">
							<div className="footer-actions-button">
								<Button text={t('createAccount')} onClick={()=> navigate('/register')} active={true} inverted={true}/>
							</div>
							<Subtitle>{t('noCreditCardRequired')}</Subtitle>
						</div>
					</ScrollAnimation>
				</div>
			</div>
			<div className='footer-customer-support'>
				<Text>{t('suggestions')}</Text> 
				<div className="footer-customer-support-container">
					<a href='https://www.facebook.com/profile.php?id=61559840792929' target='_blank'>Facebook</a>
					<a href='https://www.instagram.com/ri.software' target='_blank'>Instagram</a>
					<a href='https://www.linkedin.com/company/ri-software/' target='_blank'>LinkedIn</a>
					<a href="mailto: support@ri-software.com.ua">support@ri-software.com.ua</a>
				</div>
			</div>
			<div className='footer-terms-privacy'>
				<Link to='/terms-of-use'>{t('termsOfUser')}</Link>
				<Text>
					{t('and')}
				</Text>
				<Link to='/privacy-policy'>{t('privacyPolicy')}</Link>
			</div>
			<div className='footer-copyright-text'>
				<Text>{t('copyRight')}</Text>
			</div>
		</div>
	)
}

export default Footer