// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

const SingleOrderManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.singleOrder' })

	const data: manualData[] = [
		{
			title: t('deleteTitle'),
			text: t('deleteText')
		},
		{
			title: t('refundTitle'),
			text: t('refundText')
		},
		{
			title: t('trackingTitle'),
			text: t('trackingText')
		},
		{
			title: t('errorTitle'),
			text: t('errorText')
		},
		{
			title: t('projectTitle'),
			text: t('projectText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singleOrderManualTitle')}
			data={data}
		/>
	)
}

export default SingleOrderManual
