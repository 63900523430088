import { baseUrl } from '../../../network'

export const companyAccountFiltersUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/accounts/filters`
export const companyAccountOverviewUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/accounts/overview`
export const companyAccountUrl = (companyId:number) => baseUrl + `/api/company/${companyId}/accounts`

export const singleAccountUrl = (accountId:number)=>baseUrl + `/api/accounts/${accountId}`

export const singleAccountTransactionFiltersUrl = (accountId:number)=> baseUrl + `/api/accounts/${accountId}/transactions/filters`
export const singleAccountTransactionOverviewUrl = (accountId:number)=> baseUrl + `/api/accounts/${accountId}/transactions/overview`
export const singleAccountTransactionsUrl = (accountId:number)=> baseUrl + `/api/accounts/${accountId}/transactions`