//styles
import './labelList.scss'
import { deleteIcon } from '../../../../../../assets/general/generalIcons'

//react
import { useState, useEffect, FC } from 'react'

//components
import ColumnLayout from '../../../../columnLayout/columnLayout'
import CounterpartyCard from '../../../../counterpartyCard/counterpartyCard'
import FilterBar from '../../../../layout/filterBar/filterBar'

//network
import CreateLabelModal from './modals/createLabelModal/createLabelModal'
import DeleteLabelModal from './modals/deleteLabelModal/deleteLabelModal'

//redux
import { useAppDispatch } from '../../../../../../customHooks/redux'
import {  setCreateLabelModalIsOpen, setDeleteLabelId, setDeleteLabelModalIsOpen } from '../../../../../../redux/general/settings/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { getItemsPerColumn } from '../../../../../../assets/general/generalFunctions'

//types
import { label } from '../../../../../../types/general/generalTypes'

type emailListProps = {
    labels: label[]
	setLabels: ( value: label[] ) => void
}

const LabelList: FC<emailListProps> = ({ labels, setLabels }) => {
	const [ itemsPerColumn, setItemsPerColumn ] = useState(getItemsPerColumn(3))

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection'})

	const dispatch = useAppDispatch()

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<div className="label-list">
			<h2 className='label-heading'>{t('labelList')}</h2>
			<FilterBar 
				addButton={{
					text: t('add'),
					active: true,
					onClick: ()=> {
						dispatch(setCreateLabelModalIsOpen(true))
					}
				}}
			/>
			<ColumnLayout amount={itemsPerColumn}>
				{labels.map(label => 
					<CounterpartyCard 
						name=''
						label={label} 
						actions={[{id: 1, icon: deleteIcon, onClick: () => {
							dispatch(setDeleteLabelModalIsOpen(true))
							dispatch(setDeleteLabelId(label.id))
						}}]}
					/>
				)}
			</ColumnLayout>
			<CreateLabelModal labels={labels} setLabels={setLabels} />
			<DeleteLabelModal labels={labels} setLabels={setLabels} />
		</div>
	)

}

export default LabelList