//styles and icons
import './audioMessage.scss'
import { playAudioMessage, pauseAudioMessage } from '../../../../../../../../assets/employees/chat/chatIcons'

//react
import { useState, useRef, FC, useEffect } from 'react'

//components
import ChatAudioTimer from '../../../../chatInput/chatInputAudioMessage/chatAudioTimer/chatAudioTimer'
import AudioWave from '../../../../chatInput/chatInputAudioMessage/audioWave/audioWave'

//types
import { audioWave } from '../../../../../../../../types/employees/chatTypes'

//other
import { wavesGenerator } from '../../../../../../../../assets/employees/chat/chatFunctions'

type audioMessageProps = {
	audioBlob: Blob
	onLoaded?: () => void
}

const AudioMessage: FC<audioMessageProps> = ({audioBlob, onLoaded}) => {
	const audioMessageWavesContainerRef = useRef<HTMLDivElement | null>(null)
	const audioTagRef = useRef<HTMLAudioElement | null>(null)

	const [time, setTime] = useState(0)
	const [timeInterval, setTimeInterval] = useState<NodeJS.Timer>()
	const [waves, setWaves] = useState<audioWave[]>([])
	const [isPlaying, setIsPlaying] = useState(false)
        

	useEffect(() => {
		const audioMessageWavesContainer = audioMessageWavesContainerRef.current
		const audioTag = audioTagRef.current

		if(audioMessageWavesContainer && audioTag){
			audioTag.src = URL.createObjectURL(audioBlob)
			audioTag.load()

			wavesGenerator(audioBlob, audioMessageWavesContainer, setWaves)
		}
	}, [])

	useEffect(() => {
		const audioTag = audioTagRef.current

		if(audioTag){
			if(isPlaying){
				audioTag.play()

				setTime(audioTag.currentTime)

				setTimeInterval(setInterval(() => {
					if(audioTag){
						setTime(audioTag.currentTime)
					}
				}, 10))

			}else{
				audioTag.pause()
				clearInterval(timeInterval)
			}
		}
	}, [isPlaying])

	useEffect(() => {
		const audioTag = audioTagRef.current

		if(audioTag){
			if(time === audioTag.duration){
				setIsPlaying(false)
				audioTag.currentTime = 0
			}

			let progressPercentile
			if(isNaN(audioTag.duration) || audioTag.duration === Infinity){
				progressPercentile = 100
			}else{
				progressPercentile = time/audioTag.duration * 100
			}


			if(progressPercentile){
				const activeWaveIndex = Math.floor(waves.length*progressPercentile/100) === waves.length ? waves.length-1 : Math.floor(waves.length*progressPercentile/100)

				if(waves[activeWaveIndex]){

					for(let i=0; i<activeWaveIndex; i++){
						waves[i].filling = activeWaveIndex - i > 1 ? 100 : (activeWaveIndex - i) * 100
					}

					for(let i=Math.ceil(activeWaveIndex); i<waves.length; i++){
						waves[i].filling = 0
					}

					waves[activeWaveIndex].filling = (waves.length*progressPercentile/100 - activeWaveIndex)*100
					setWaves([...waves])
				}
			}
		}
	}, [time])

	const rewind = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

		const rewindPercentile = (e.clientX - e.currentTarget.getBoundingClientRect().left)/e.currentTarget.clientWidth
		const audioTag = audioTagRef.current

		if(audioTag && audioTag.duration && audioTag.duration != Infinity){
			audioTag.currentTime = audioTag.duration * rewindPercentile
			setTime(audioTag.currentTime)
			setIsPlaying(true)
		}
	}

	return (
		<div className="audio-message-container">
			<div className="audio-message-play-button" onClick={() => setIsPlaying(!isPlaying)}>
				{isPlaying ? pauseAudioMessage : playAudioMessage}
			</div>
			<div className="audio-message-data-container">
				<div className="audio-message-waves-container" ref={audioMessageWavesContainerRef} onClick={(e) => {rewind(e)}} >
					<audio src="" ref={audioTagRef} style={{display: 'none'}} onLoadedData={() => {
						if(onLoaded){
							onLoaded()
						}
						const audioTag = audioTagRef.current

						if(audioTag){

							const getDuration = () => {
								audioTag.currentTime = 0
								audioTag.removeEventListener('timeupdate', getDuration)
								setTime(audioTag.duration)
							}
                            
							if(audioTag.duration === Infinity){
								audioTag.currentTime = 1e101
								audioTag.addEventListener('timeupdate', getDuration)
							}
						}

					}}></audio>
					{
						waves.map((wave, index) => {
							return <AudioWave key={index} height={wave.height} filling={wave.filling}/>
						})
					}
				</div>
				<ChatAudioTimer time={time}/>
			</div>
		</div>
	)
}

export default AudioMessage