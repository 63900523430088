import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type pipelineListModalsState = {
    createPipeline: {
        modalIsOpen: boolean
    },
	editPipeline:  {
        modalIsOpen: boolean
        pipelineId: number | null
    }
    deletePipeline: {
        modalIsOpen: boolean
        pipelineId: number | null
    }
}

const initialState: pipelineListModalsState = {
	createPipeline: {
		modalIsOpen: false,
	},
	editPipeline: {
		modalIsOpen: false,
		pipelineId: null
	},
	deletePipeline: {
		modalIsOpen: false,
		pipelineId: null
	}
}

export const pipelineListModalsSlice = createSlice({
	name: 'pipelineListModalsReducer',
	initialState,
	reducers: {
		setCreatePipelineModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createPipeline.modalIsOpen = action.payload
		},
		setEditPipelineModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.editPipeline.modalIsOpen = action.payload
		},
		setEditPipelineId: (state, action: PayloadAction<number | null>) => {
			state.editPipeline.pipelineId = action.payload
		},
		setDeletePipelineModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deletePipeline.modalIsOpen = action.payload
		},
		setDeletePipelineId: (state, action: PayloadAction<number | null>)=> {
			state.deletePipeline.pipelineId = action.payload
		}
	}
})

export const {
	setCreatePipelineModalIsOpen,
	setEditPipelineModalIsOpen,
	setEditPipelineId,
	setDeletePipelineModalIsOpen,
	setDeletePipelineId 
} = pipelineListModalsSlice.actions

export default pipelineListModalsSlice.reducer