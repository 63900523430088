// react
import { FC, useState } from 'react'

// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setDeleteOfferingImageId, setDeleteOfferingImageModalIsOpen } from '../../../../../../redux/sales/offering/modals'

// translation
import { useTranslation } from 'react-i18next'

type deleteOfferingImageModalProps = {
	handleDelete : (id: number) => void
}
const DeleteOfferingImageModal: FC<deleteOfferingImageModalProps> = ({handleDelete}) => {
	const {modalIsOpen, imageId} = useAppSelector(state => state.offeringModal.details.deleteOfferingImageModal)
	const dispatch = useAppDispatch()
	const {t} = useTranslation('', {keyPrefix: 'sales.offering.offeringDetails.modals.deleteOfferingImageModal'})

	const [loading, setLoading] = useState<boolean>(false)

	const closeModal = () => {
		dispatch(setDeleteOfferingImageModalIsOpen(false))
		dispatch(setDeleteOfferingImageId(null))
	}
	const deleteImage = () => {
		setLoading(true)
		handleDelete(imageId!)

		setLoading(false)
		closeModal()
	}
	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteImage }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeleteOfferingImageModal