//components
import DocumentList from '../../../general/documentList/documentList'
import Pagination from '../../../general/pagination/pagination'
import DeleteProviderDocumentConfirmationModal from './modals/deleteProviderDocumentConfirmationModal/deleteProviderDocumentConfirmationModal'
import ImportProviderDocumentFileSelectorModal from './modals/ImportProviderDocumentFileSelectorModal/ImportProviderDocumentFileSelectorModal'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router'

//types
import { filterBar, filters, document } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	singleProviderDocumentsUrl,
	singleProviderDocumentFiltersUrl
} from '../../../../utils/urls/purchases/provider'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteProviderDocumentId, setDeleteProviderDocumentModalIsOpen, setImportProviderDocumentFileSelectorModalIsOpen } from '../../../../redux/purchase/provider/modals'

//other
import { blobStringToBlob, createFilteringRequest, formatFilters } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type providerDocumentsProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

type providerDocumentsResponse = {
	file: string
	file_name: string
	file_type: string
	id: number
}


const ProviderDocuments: FC<providerDocumentsProps> = ({ setFilterBar }) => {

	const { t } = useTranslation('', {keyPrefix:'purchase.provider.providerDocuments'})

	const dispatch = useAppDispatch()

	const [documents, setDocuments] = useState<document[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)

	const {relationshipId} = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleProviderDocumentFiltersUrl(Number(relationshipId)), 'GET')

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: {
				text: t('add'),
				active: true,
				onClick: () => {
					dispatch(setImportProviderDocumentFileSelectorModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)
		const { result } = await authorizedRequest(singleProviderDocumentsUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		console.log(result, result.length)
		if (result.length > 0) {
			const formatedDocumentsData: document[] = result.map((document: providerDocumentsResponse) => {
				console.log(document)
				return {
					file: blobStringToBlob(document.file, document.file_type),
					fileName: document.file_name,
					fileType: document.file_type,
					id: document.id
				}
			})
			return formatedDocumentsData
		}
		return []
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				if (result.length > 0) {
					setDocuments([...documents, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setDocuments([...documents, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleDeleteProviderDocument = (id: number) => {
		console.log(id)
		dispatch(setDeleteProviderDocumentId(id))
		dispatch(setDeleteProviderDocumentModalIsOpen(true))
	}

	return (
		<Pagination onLoadMore={handleLoadMore}>
			<DocumentList
				fileList={documents.map((doc) => ({ ...doc }))}
				handleDelete={handleDeleteProviderDocument}
			/>
			<ImportProviderDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments}/>
			<DeleteProviderDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments}/>
		</Pagination>
	)
}

export default ProviderDocuments