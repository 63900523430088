import { baseUrl } from '../../network'

export const getInventoryItemsUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/warehouses/inventory`
export const getInventoryItemsForOfferingUrl = (offeringId: number) =>  baseUrl + `api/offering/${offeringId}/items`
export const addOfferingsToInventoryItemUrl = (inventoryItemId: number) => baseUrl + `api/inventory/item/${inventoryItemId}/offerings`

export const getWarehouseActionsFiltersUrl = (companyId: number) => baseUrl + `api/company/${companyId}/warehouse_actions/filters`
export const getWarehouseActionsUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/warehouse_actions`
export const createWarehouseActionsUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/warehouse_actions`


export const createShipmentUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/warehouses/actions`
export const deleteShipmentUrl = (shipmentId: number) => baseUrl + `api/warehouse/actions/${shipmentId}`

export const createMovingUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/warehouses/inventory`
export const deleteMovingUrl = (movingId: number) =>  baseUrl + `api/company/${movingId}/warehouses/inventory`

export const createInventoryItemUrl = (companyId: number) =>  baseUrl + `api/company/${companyId}/inventory/items`
export const deleteInventoryItemUrl = (inventoryItemId: number) =>  baseUrl + `api/inventory/items/${inventoryItemId}`