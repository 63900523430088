//components
import ConfirmationModal from '../../../../modals/confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { closeOrderUrl } from '../../../../../../utils/old_urls/general/generalUrls'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setCloseOrderId, setCloseOrdernModalIsOpen } from '../../../../../../redux/general/order/modals'

//translation
import { useTranslation } from 'react-i18next'


const CloseOrderModal: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'order.orderDetails.modals.closeOrderModal' })
	const { modalIsOpen, orderId, isOrderClosed } = useAppSelector((state) => state.orderModals.closeOrderModal)

	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCloseOrdernModalIsOpen(false))
		dispatch(setCloseOrderId(null))
	}

	const handleSubmit = () => {
		if(orderId){
			setLoading(true)
			authorizedRequest(closeOrderUrl(orderId), 'PUT', 'accessToken', {
				'is_closed': !isOrderClosed
			})
				.then((response) => {
					navigate(-1)
					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={isOrderClosed ? t('activateThisOrder') : t('closeThisOrder')}
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={modalIsOpen} 
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default CloseOrderModal