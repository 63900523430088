//styles
import './text.scss'

//react 
import { FC } from 'react'

type textProps = {
    children: React.ReactNode
}

const Text: FC<textProps> = ({ children }) => {
	return (
		<p className='landing-text'>{children}</p>
	)
}

export default Text