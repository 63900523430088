//styles
import './navBarBurger.scss'

//react
import { FC } from 'react'

type navBarBurgerProps = {
    open: boolean
    setOpen: ( value: boolean ) => void
}

const NavBarBurger: FC<navBarBurgerProps> = ({ open, setOpen }) => {


	return (
		<div className={`navbar-burger ${open ? 'open' : ''}`} onClick={() => {
			setOpen(!open)
		}}>
			<div className="navbar-burger-item"></div>
			<div className="navbar-burger-item"></div>
			<div className="navbar-burger-item"></div>
		</div>
	)
}

export default NavBarBurger