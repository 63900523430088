// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'
// type
import { file } from '../../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'

import { setImportInventoryItemFileSelectorModalIsOpen, setImportInventoryItemFileSelectorModalFile, setInventoryItemTablePreviewModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

const ImportInventoryItemFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.importInventoryItemFileSelectorModal)
	const dispatch = useAppDispatch()
	const closeModal = () => {
		dispatch(setImportInventoryItemFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportInventoryItemFileSelectorModalFile(files[0]))
		dispatch(setImportInventoryItemFileSelectorModalIsOpen(false))
		dispatch(setInventoryItemTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
		/>
	)
}

export default ImportInventoryItemFileSelectorModal