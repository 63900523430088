//styles
import './subtitle.scss'

//react
import { FC, ReactNode } from 'react'

type subtitleProps = {
    children: ReactNode
}

const Subtitle: FC<subtitleProps> = ({children}) => {

	return <h2 className='subtitle'>{children}</h2>

}

export default Subtitle