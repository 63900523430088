//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleTaskUrl } from '../../../../../utils/urls/projects/task'

//redux
import {
	useAppSelector,
	useAppDispatch,
} from '../../../../../customHooks/redux'
import {
	setDeleteTaskModalIsOpen,
	setDeleteTaskId,
} from '../../../../../redux/projects/task/modals'

//types
import { dragStage, task } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type deleteTaskModalProps = {
	navigateBack?: boolean
	stages?: dragStage<task<Date | undefined>>[]
	setStages?: (value: dragStage<task<Date | undefined>>[]) => void
	tasks?: task<Date | undefined>[]
	setTasks?: (value: task<Date | undefined>[]) => void
}

const DeleteTaskModal: FC<deleteTaskModalProps> = ({ navigateBack = true, stages, setStages, tasks, setTasks }) => {
	const { t } = useTranslation('', {
		keyPrefix: 'general.modals.task.deleteTaskModal',
	})
	const { modalIsOpen, taskId } = useAppSelector(
		(state) => state.taskModals.deleteTaskModal
	)

	const [loading, setLoading] = useState<boolean>(false)

	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const updateTasks = () => {
		if (setTasks && tasks) {
			if(tasks && stages && setStages){
				const task = tasks.find((task) => {
					return task.id === taskId
				})
	
				const statuses: { [key: string]: number } = {
					open: 1,
					in_progress: 2,
					completed: 3,
					accepted: 4,
					rejected: 5
				}
	
				setStages([...stages.map((stage) => ({...stage, quantity: task && stage.stageId === statuses[task.status.text.toLowerCase()] ? stage.quantity -1 : stage.quantity }))])	
			}
			setTasks([...tasks.filter((task) => task.id !== taskId)])
		}
	}

	const closeModal = () => {
		dispatch(setDeleteTaskModalIsOpen(false))
		dispatch(setDeleteTaskId(null))
	}

	const handleSubmit = () => {
		if (taskId) {
			setLoading(true)
			authorizedRequest(singleTaskUrl(taskId), 'DELETE').then((response) => {
				if (navigateBack) {
					navigate(-1)
				}
				setLoading(false)
				closeModal()
				updateTasks()
			})
		}
	}

	return (
		<ConfirmationModal
			title={t('deleteThisTask')}
			buttons={[
				{ text: t('yes'), onClickHandler: handleSubmit },
				{ text: t('no'), onClickHandler: closeModal },
			]}
			isOpen={modalIsOpen}
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteTaskModal
