//styles and icons
import './chatAudioRecorder.scss'
import { redCircle } from '../../../../../../../assets/employees/chat/chatIcons'
import { recordingAudioIcon } from '../../../../../../../assets/employees/chat/chatIcons'

//react
import { FC, useState, useCallback, useRef, useEffect } from 'react'
import RecordRTC from 'recordrtc'

//components
import ChatAudioTimer from '../chatAudioTimer/chatAudioTimer'

type chatAudioRecorderProps = {
	setAudioBlob: React.Dispatch<React.SetStateAction<Blob | null>>
}

const ChatAudioRecorder: FC<chatAudioRecorderProps> = ({setAudioBlob}) => {
	const [waves, setWaves] = useState<number[]>([]) // Array to hold audio levels
	const [timer, setTimer] = useState(0) // Time elapsed during recording
	const [audioStream, setAudioStream] = useState<MediaStream>()
	const [wavesGenerationInterval, setWavesGenerationInterval] = useState<NodeJS.Timer>()
  
	// Initializing ref variables for the component
	const recorder = useRef<RecordRTC | null>(null) // Ref variable for RecordRTC object
	const audioRecordingContainerRef = useRef<null | HTMLDivElement >(null) // Ref variable for container element
    
	// Function to increment the recording timer
	const incrementTimer = useCallback(() => {
		setTimer((prevTimer) => prevTimer + 0.1)
	}, [])
  
	useEffect(() => {
		const container = audioRecordingContainerRef.current
		const wrapper = container?.closest('.audio-recorder-waves-wrapper')
		if(container && wrapper) {
			const difference = wrapper.clientWidth/document.documentElement.clientWidth * 100 - waves.length*0.54
			if(difference < 0){
				container.style.transform = `translateX(-${difference*-1}vw)`
			}
		}
	}, [waves])
  
	useEffect(() => {
		startRecording()
	}, [])
    
	// Function to start recording audio
	const startRecording = () => {
		navigator.mediaDevices.getUserMedia({ audio: true })
			.then((stream) => {
				setAudioStream(stream)
				recorder.current = new RecordRTC(stream, { type: 'audio' })
				// Start recording the audio
				recorder.current.startRecording()
  
				const audioCtx = new AudioContext()
				const source = audioCtx.createMediaStreamSource(stream)
				const analyser = audioCtx.createAnalyser()
  
				// Connect the audio source to the analyser
				source.connect(analyser)
  
				// Set the minimum and maximum decibels for the analyser
				analyser.minDecibels = -150
				analyser.maxDecibels = -5
				// Set the FFT size for the analyser
				analyser.fftSize = 2048
  
				const bufferLength = analyser.frequencyBinCount
				const dataArray = new Uint8Array(bufferLength)
  
				const draw = () => {
					// Get the frequency data from the analyser
					analyser.getByteFrequencyData(dataArray)
  
					// Calculate the average value of the frequency data
					const sum = dataArray.reduce((acc, curr) => acc + curr, 0)
					const avg = sum / dataArray.length
  
					// Scale the average value to a percentage value
					const scaledLevel = Math.floor((avg / 255) * 100)
  
					// Add the scaled level to the levels array
					setWaves((prevWaves) => [...prevWaves, scaledLevel])
				}
  
				// Start drawing the audio levels every 500 milliseconds
				setWavesGenerationInterval(setInterval(() => {
					draw()
					incrementTimer()
				}, 100))
			})
      
	}
  

	// Function to stop recording audio
	const stopRecording = async () => {
		// Stop recording the audio and get the audio blob
		recorder.current?.stopRecording(() => {
			const blob = recorder.current?.getBlob()
			setAudioBlob(blob as Blob)
			// handleAddAudioBlob(blob as Blob, formatTime(timer));
			audioStream?.getTracks()[0].stop()
		})

		// Clear the audio level animation interval
		if(wavesGenerationInterval){
			clearInterval(wavesGenerationInterval)
		}
	}

	return (
		<div className="audio-recorder-container">
			<div className="audio-recorder-data-container">
				<div className='audio-recorder-data-icon'>{redCircle}</div>
				<ChatAudioTimer time={timer}/>
			</div>
            
			<div className="audio-recorder-stop-container" onClick={() => {
				stopRecording()
			}}>
				{recordingAudioIcon}
			</div>
		</div>
	)
}

export default ChatAudioRecorder