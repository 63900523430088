//styles and icons
import './termsOfUse.scss'

//react
import { useNavigate } from 'react-router-dom'

//components
import LanguageSelector from '../../../components/general/languageSelector/languageSelector'

//translation
import { useTranslation } from 'react-i18next'

const TermsOfUse = () => {
	const { t } = useTranslation('', { keyPrefix: 'termsAndAgreement.termsOfUse' })

	const navigate = useNavigate()

	const handlePrivacyNavigation = () => {
		navigate('/privacy-policy')
	}

	return (
		<div className='terms'>
			<div className='terms-container'>
				<div className='terms-header'>
					<div className='terms-title-and-subtitle'>
						<h2 className='terms-title'>{t('title')}</h2>
						<div className='terms-subtitle'>{t('subtitle')}</div>
					</div>
					<div className='language-selector'>
						<LanguageSelector/>
					</div>
				</div>
				<div className='terms-section'>
					<p className='terms-paragraph'>{t('paragraphs.paragraph1')}</p>
					<p className='terms-paragraph'>{t('paragraphs.paragraph2')}</p>
					<p className='terms-paragraph'>{t('paragraphs.paragraph3')}</p>
					<p className='terms-paragraph'>{t('paragraphs.paragraph4')}</p>
					<p className='terms-paragraph'>{t('paragraphs.paragraph5')}</p>
					<div className='terms-list-container'>
						<div className='terms-list'>
							<ol>
								<li>{t('lists.list1.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list1.content.paragraph1')}</p>
						</div>
						<div className='terms-list'>
							<ol start={2}>
								<li>{t('lists.list2.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list2.content.paragraph1')}</p>
							<p className='terms-paragraph'>{t('lists.list2.content.paragraph2')}</p>
							<p className='terms-paragraph'>{t('lists.list2.content.paragraph3')}</p>
							<p className='terms-paragraph'>{t('lists.list2.content.paragraph4')}</p>
							<p className='terms-paragraph'>{t('lists.list2.content.paragraph5')}</p>
						</div>
						<div className='terms-list'>
							<ol start={3}>
								<li>{t('lists.list3.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list3.content.paragraph1')}</p>
							<p className='terms-paragraph'>{t('lists.list3.content.paragraph2')}</p>
							<ul>
								<li>{t('lists.list3.content.unorderedList.item1')}</li>
								<li>{t('lists.list3.content.unorderedList.item2')}</li>
								<li>{t('lists.list3.content.unorderedList.item3')}</li>
								<li>{t('lists.list3.content.unorderedList.item4')}</li>
								<li>{t('lists.list3.content.unorderedList.item5')}</li>
								<li>{t('lists.list3.content.unorderedList.item6')}</li>
							</ul>
							<p className='terms-paragraph'>{t('lists.list3.content.paragraph3')}</p>
						</div>
						<div className='terms-list'>
							<ol start={4}>
								<li>{t('lists.list4.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list4.content.paragraph1')}</p>
						</div>
						<div className='terms-list'>
							<ol start={5}>
								<li>{t('lists.list5.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list5.content.paragraph1')}</p>
							<p className='terms-paragraph'>{t('lists.list5.content.paragraph2')}</p>
							<p className='terms-paragraph'>{t('lists.list5.content.paragraph3')}</p>
							<p className='terms-paragraph'>{t('lists.list5.content.paragraph4')}</p>
						</div>
						<div className='terms-list'>
							<ol start={6}>
								<li>{t('lists.list6.title')}</li>
							</ol>
							<p className='terms-paragraph'>
								{t('lists.list6.content.paragraph1')}{' '}
								<span className='terms-link' onClick={handlePrivacyNavigation}>{t('lists.list6.content.link')}</span>{' '}
								{t('lists.list6.content.paragraph2')}
							</p>
						</div>
						<div className='terms-list'>
							<ol start={7}>
								<li>{t('lists.list7.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list7.content.paragraph1')}</p>
						</div>
						<div className='terms-list'>
							<ol start={8}>
								<li>{t('lists.list8.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list8.content.paragraph1')}</p>
						</div>
						<div className='terms-list'>
							<ol start={9}>
								<li>{t('lists.list9.title')}</li>
							</ol>
							<p className='terms-paragraph'>{t('lists.list9.content.paragraph1')}</p>
							<p className='terms-paragraph'>{t('lists.list9.content.paragraph2')}</p>
							<p className='terms-paragraph'>{t('lists.list9.content.paragraph3')}</p>
							<p className='terms-paragraph'>{t('lists.list9.content.paragraph4')}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TermsOfUse