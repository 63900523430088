/* eslint-disable indent */
// styles
import './pagination.scss'

// react
import {FC, ReactNode} from 'react'

// components
import Loading from '../loading/loading'

type loadDirection = 'top' | 'bottom' | 'right' | 'left'

type paginationProps = {
	children: ReactNode
	onLoadMore: (direction: loadDirection) => void
	loadOffset?: number
	loadAxis?: 'vertical' | 'horizontal'
	loading?: boolean
	onlyLoadOn?: loadDirection
	showLoader?:boolean
	styles?:object
}


const Pagination : FC<paginationProps> = ({onLoadMore, children, loadOffset, loadAxis = 'vertical', onlyLoadOn = 'bottom', loading, showLoader = true, styles}) =>{
	let directionLoad:loadDirection = 'bottom'

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		if(loading) return

		const scrollHeight = e.currentTarget?.scrollHeight
		const scrollWidth = e.currentTarget?.scrollWidth

		const scrollTop = e.currentTarget?.scrollTop
		const scrollLeft = e.currentTarget?.scrollLeft

		const clientHeight = e.currentTarget?.clientHeight
		const clientWidth = e.currentTarget?.clientWidth

		const offset = loadOffset ?? 100
		
		const handleLoadMore = (direction: loadDirection) =>{
			if(onLoadMore){
				if(onlyLoadOn){
					onlyLoadOn === direction && onLoadMore(direction)	
				}else{
					onLoadMore(direction)	
				}
			}
		}

		switch(loadAxis){
			case 'vertical':
				if (scrollHeight - scrollTop <= clientHeight + offset) {
					directionLoad = 'bottom'
					handleLoadMore(directionLoad)
				}
				if (scrollTop <= offset) {
					directionLoad = 'top'
					handleLoadMore(directionLoad)
				}
				break
			case 'horizontal':
				if (scrollWidth - scrollLeft <= clientWidth + offset) {
					directionLoad = 'right'
					handleLoadMore(directionLoad)
				}
				if (scrollLeft <= offset) {
					directionLoad = 'left'
					handleLoadMore(directionLoad)
				}
				break
		}
	
	}

	return (
		<>
			<div
				onScroll={handleScroll}
				className='pagination-container'
				style={styles}
			>
				{children}
				{
					loading && showLoader&& <Loading/>
				}
			</div>
		</>
	)
}

export default Pagination