// style
import './avatarPicker.scss'
import { navlogo, whitePencilFill } from '../../../assets/general/generalIcons'

// react
import { createPortal } from 'react-dom'
import { FC, useEffect, useState } from 'react'

// component
import AvatarCustomizerModal from '../modals/avatarCustomizerModal/avatarCustomizerModal'
import FileSelectorModal from '../modals/fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../types/general/generalTypes'


type avatarPickerProps = {
	currenctAvatar: string | null
	submitAvatar: (avatar: string | null) => void
	defaultAvatar?: JSX.Element
}

const AvatarPicker: FC<avatarPickerProps> = ({ currenctAvatar, submitAvatar, defaultAvatar=navlogo }) => {
	const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false)
	const [isAvatarCustomizerOpen, setIsAvatarCustomizerOpen] = useState(false)

	const [avatarFiles,setAvatarFiles] = useState<file[]>([])
	const [imgUrl,setImgUrl] = useState('')

	useEffect(() => {
		if (avatarFiles && avatarFiles.length > 0) {
			const reader = new FileReader()
			reader.onload = () => {
				const imageUrl = reader.result as string
				setImgUrl(imageUrl)

				setIsFileSelectorOpen(false)
				setIsAvatarCustomizerOpen(true)
			}

			reader.readAsDataURL(avatarFiles[0].file)
		}
	}, [avatarFiles])

	const modals = <>
		<FileSelectorModal isOpen={isFileSelectorOpen} setIsOpen={setIsFileSelectorOpen} setFiles={setAvatarFiles} />
		<AvatarCustomizerModal
			image={imgUrl}
			isOpen={isAvatarCustomizerOpen}
			closeModal={() => {
				setIsAvatarCustomizerOpen(false)
				setAvatarFiles([])
			}}
			submitAvatar={(avatar)=>{
				submitAvatar(avatar)
				setIsAvatarCustomizerOpen(false)
			}}
		/>
	</>

	const renderModals = createPortal(modals,document.body)
	return (
		<>
			<div className="avatar-picker">
				<div className="avatar-picker-avatar">
					{currenctAvatar ? (
						<img src={`${currenctAvatar}`} alt="" className="avatar" />
					) : (
						<div className="avatar">
							{defaultAvatar}
						</div>
					)}
					
					<button
						onClick={()=>{
							setIsFileSelectorOpen(true)
						}}
					>
						{whitePencilFill}
					</button>
				</div>
			</div>
			
			{renderModals}
		</>
	)
}

export default AvatarPicker