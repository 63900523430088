// Define a custom node of employee

// styles
import './employeeNode.scss'

// react
import { FC } from 'react'

// component
import { Handle, Position } from 'reactflow'
import CounterpartyCard from '../../../../../components/general/counterpartyCard/counterpartyCard'

// types
import { employee } from '../../../../../types/employees/hierarchyTypes'
import { switchButton } from '../../../../../types/general/generalTypes'

type employeeNodeProps = {
	data: {
		isConnectable: boolean
		employee: employee
		actions: switchButton[]
	}
}
const employeeNode : FC<employeeNodeProps> = ({ data }) => {

	const employeeData = data.employee
	const fired = employeeData.userId === null
	return (
		<div className={`employee-node ${fired ? 'fired' :''}`}>
			{/* Arrow Source */}
			<Handle type="target" position={Position.Top} isConnectable={data.isConnectable} />

			{/* The element to be shown inside the node */}
			{data.employee && <CounterpartyCard avatar={employeeData.userId ? employeeData.avatar : undefined} description={employeeData.position} name={employeeData.name} actions={data.actions} tags={employeeData.tags} />}
	
			{/* Arrow source */}
			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={data.isConnectable}
			/>
		</div>
	)
}

export default employeeNode