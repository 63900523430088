// style
import './avatarCustomizerModal.scss'

// react
import { useEffect, useRef, useState,MouseEventHandler, ChangeEventHandler, FC } from 'react'

// component
import Modal from '../modal/modal'
import InputRange from '../../inputRange/inputRange'

// other
import { blobToBase64 } from '../../../../assets/general/generalFunctions'

type mouseState = {
	isDragging:boolean
	startY:number
	startX:number
	offsetX:number
	offsetY:number
}

const initialMouseState = {
	isDragging:false,
	startX:500,
	startY:500,
	offsetX:0,
	offsetY:0
}

type imageData = {
	naturalHeight:number
	naturalWidth:number
	scalingFactor:number
	isLandscape:boolean
	height:number,
	width:number
}

const intialImageData:imageData = {
	naturalHeight:0,
	naturalWidth:0,
	scalingFactor:1,
	isLandscape:false,
	height:0,
	width:0
}

type avatarCustomizerModal = {
	image:string | null
	closeModal:() => void
	isOpen:boolean
	submitAvatar: (avatar: string) => void
}

const AvatarCustomizerModal : FC<avatarCustomizerModal> = ({image, closeModal, isOpen, submitAvatar}) => {

	
	// Cropper States
	const [mousePosition,setMousePosition] = useState<mouseState>(initialMouseState) 
	const [zoomFactor,setZoomFactor] = useState(1000) 
	const [imageSize,setImageSize] = useState(intialImageData) 
	const [imageBaseResolution,setImageBaseResolution] = useState<{w:number,h:number}>({w:0,h:0}) 

	// DOM References
	const containerRef = useRef<HTMLDivElement>(null)
	const avatarRef = useRef<HTMLImageElement>(null)
	const canvasRef = useRef<HTMLCanvasElement>(null)
	
	// Function to clamp the image position when screen changes/resizes
	const repositionImage = ()=>{
		if (avatarRef.current) {
			const bounds = resizeBound(mousePosition.offsetX, mousePosition.offsetY)
			avatarRef.current.style.left = `${bounds[0] / window.innerWidth * 100}vw`
			avatarRef.current.style.top = `${bounds[1] / window.innerHeight * 100}vh`
			setMousePosition(prev=>{
				return {
					...prev,
					offsetX:bounds[0],
					offsetY:bounds[1]
				}
			})
		}
	}
	const resizeBound = (offsetX:number,offsetY:number)=>{
		if(avatarRef.current && containerRef.current){
			const gapWidth = containerRef.current.clientWidth / 4.05
			const minX = gapWidth
			const maxX = -avatarRef.current.width  + (containerRef.current.clientWidth / 2) + gapWidth
			offsetX = Math.min(minX,offsetX)
			offsetX = Math.max(maxX,offsetX)

			const minY = 0
			const maxY = containerRef.current.clientHeight - avatarRef.current.height
			offsetY = Math.min(offsetY,minY)
			offsetY = Math.max(offsetY,maxY)
			
		}
		return [offsetX,offsetY]
	}
	const resizeImage = ()=>{
		if(avatarRef.current && containerRef.current){
			const containerHeight = containerRef.current.clientHeight
			const containerWidth = containerRef.current.clientWidth
			if(imageSize.isLandscape){
				// Set the height to 100% of the circle
				
				// Calculate the ratio of the width 
				const imageWidthRatio = imageSize.naturalWidth / imageSize.naturalHeight
				const imageScaledWidth = imageWidthRatio * containerHeight

				avatarRef.current.style.width = `${imageScaledWidth / window.innerWidth * 100}vw`
				avatarRef.current.style.height = `${containerHeight / window.innerHeight * 100}vh`
				setImageBaseResolution({w:imageScaledWidth,h:containerHeight})
				
			}else{
				// Set the width to 100% of the circle
				const circleWidth = containerWidth * 0.495
				const imageHeightRatio =  imageSize.naturalHeight / imageSize.naturalWidth
				const imageScaledHeight =  circleWidth * imageHeightRatio
				
				avatarRef.current.style.height = `${imageScaledHeight / window.innerHeight * 100}vh`
				avatarRef.current.style.width = `${circleWidth / window.innerWidth * 100 }vw`
				setImageBaseResolution({w:circleWidth,h:imageScaledHeight})
				
			}
		}
	}

	useEffect(()=>{
		resizeImage()
	},[imageSize])
	
	useEffect(()=>{
		return ()=>{
			setMousePosition(initialMouseState)
		}
	},[])

	// Display the image in img
	useEffect(()=>{
		if(image){
			const img = new Image()
			img.src = image
			if(img){
				img.onload = ()=>{

					if(avatarRef.current){
						avatarRef.current.src = image
					}
					
					setImageSize({
						naturalHeight:img.naturalHeight,
						naturalWidth:img.naturalWidth,
						scalingFactor:img.naturalHeight / img.naturalWidth,
						isLandscape: img.naturalHeight < img.naturalWidth,
						width:img.width,
						height:img.height
					})
				}
			}
			return ()=>{
				if(avatarRef.current){
					avatarRef.current.src = ''
				}
				setZoomFactor(1000)
			}
		}
	}, [image, avatarRef])
	
	// Update the position of the image when mouse position change
	useEffect(()=>{
		if(avatarRef.current && mousePosition.isDragging){
			avatarRef.current.style.left = `${mousePosition.offsetX / window.innerWidth * 100}vw`
			avatarRef.current.style.top = `${mousePosition.offsetY / window.innerHeight * 100}vh`
		}
		
	},[mousePosition])
	
	// Zoom the image when slider change
	useEffect(()=>{
		if(avatarRef.current){
			const currentWidth = imageBaseResolution.w
			const currentHeight = imageBaseResolution.h
			
			const scalingFactor = zoomFactor / 1000
			const newWidth = currentWidth * scalingFactor
			const newHeight = currentHeight * scalingFactor

			avatarRef.current.style.width = `${newWidth / window.innerWidth * 100 }vw`
			avatarRef.current.style.height = `${newHeight / window.innerHeight * 100 }vh`
		}
	},[zoomFactor])

	// Mouse events
	const handleMouseMove:MouseEventHandler = (e) => {
		
		if (!mousePosition.isDragging || !containerRef.current ) return
		containerRef.current.style.cursor = 'grabbing'
		setMousePosition(prev =>{
			let offsetX = prev.offsetX
			let offsetY = prev.offsetY
			
			const deltaX = e.clientX - mousePosition.startX
			const deltaY = e.clientY - mousePosition.startY
			offsetX = prev.offsetX + deltaX,offsetY
			offsetY = prev.offsetY + deltaY

			const bounds = resizeBound(offsetX,offsetY)
			offsetX = bounds[0]
			offsetY = bounds[1]
			return {
				...prev,
				startX:e.clientX,
				startY:e.clientY,
				offsetX:offsetX,
				offsetY:offsetY
			}
		})
				
	}

	const handleMouseDown:MouseEventHandler<HTMLDivElement> = (e)=>{
		setMousePosition(prev=>{
			return {...prev,isDragging:true,startX:e.clientX,startY:e.clientY}
		})
	}
		
	const handleMouseStop = ()=>{
		setMousePosition(prev =>{
			return {
				...prev,
				isDragging:false
			}
		})
		repositionImage()
		if(containerRef.current)containerRef.current.style.cursor = 'auto'
	}

	// Zoom events
	const handleZoom:ChangeEventHandler<HTMLInputElement> = (event)=>{
		const newZoomFactor = event.target.valueAsNumber
		setZoomFactor(newZoomFactor)
		repositionImage()
	}
	
	// Get results
	const handleCroppedImage = () => {
		if(canvasRef.current && containerRef.current && canvasRef.current && avatarRef.current){
			repositionImage()
			
			const viewportToOutputResolution = (viewportValue:number,viewportLength:number,outputResolution:number)=>{
				const percentage = viewportValue / viewportLength * 100
				return (outputResolution / 100) * percentage
			}

			// Setup canvas
			const croppedImageResolution = 500
			const gapWidth = containerRef.current.clientWidth / 4.05
			canvasRef.current.width = croppedImageResolution
			canvasRef.current.height = croppedImageResolution
			canvasRef.current.style.background = 'white'
			canvasRef.current.style.display = 'none'

			const img = avatarRef.current

			const imageOffsetX = viewportToOutputResolution(mousePosition.offsetX - gapWidth,containerRef.current.clientWidth - gapWidth * 2,croppedImageResolution)
			const imageWidth = viewportToOutputResolution(img.width,containerRef.current.clientWidth - gapWidth * 2 ,croppedImageResolution)
			const imageHeight = viewportToOutputResolution(img.height,containerRef.current.clientHeight,croppedImageResolution)
			
			
			const ctx = canvasRef.current.getContext('2d')
			if(ctx){
				ctx.drawImage(img,imageOffsetX,mousePosition.offsetY,imageWidth,imageHeight)
			}

			// const imgUrl = canvasRef.current.toDataURL('image/png')
			canvasRef.current.toBlob((blob)=>{
				if(blob){
					blobToBase64(blob).then((result)=>{
						submitAvatar(result)
					})
				}
			})
		}
	}

	return (
		<Modal closeModal={closeModal} title='Update Profile Picture' open={isOpen} submitButton={{text:'Apply',onClick:handleCroppedImage}} >
			<div className="avatar-customizer-container">
				<div
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseStop}
					onMouseEnter={repositionImage}
					onClick={repositionImage}
					onMouseUp={handleMouseStop}
					
					ref={containerRef}
					className='image-cropper-container'
				>
					<img src="" alt="" ref={avatarRef} className={'avatar rotate'} onResize={repositionImage} onDragStart={()=>false} draggable={false}/>
					<div className='cropping-area'></div>
				</div>
				<div className="zoom-slider-container">
					<div className='side-button' onClick={()=>{setZoomFactor(prev => Math.max(prev - 100, 1000))}}>-</div>
					<InputRange 
						min={1000}
						max={1500}
						setValue={(value) => {
							setZoomFactor(value)
						}}
						value={zoomFactor}
					/>
					<div className='side-button' onClick={()=>{setZoomFactor(prev=> Math.min(prev + 100, 1500))}}>+</div>
				</div>
				<canvas ref={canvasRef} style={{display:'none'}}></canvas>
			</div>
		</Modal>
	)
}

export default AvatarCustomizerModal

