//react
import { FC, useEffect, useState } from 'react'

//components
import ChatParticipantCard from './chatInfoParticipantCard/chatInfoParticipantCard'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//types
import { chatMember } from '../../../../../../../types/employees/chatTypes'

type searchChatMembersType = {
	inputValue: string,
	setMembersListLenght: (value: number)=> void
}

const SearchChatMembers: FC<searchChatMembersType> = ({inputValue, setMembersListLenght}) => {

	const { chatInfo } = useChatDesktop()
	const [chatMembersListSearch, setChatMembersListSearch] = useState<chatMember[]>([])

	useEffect(()=>{
		setChatMembersListSearch(chatInfo.data.chatMembers)
	}, [chatInfo.data.chatMembers])

	useEffect(() => {
		setMembersListLenght(chatMembersListSearch.length)
	}, [chatMembersListSearch])

	useEffect(() => {
		inputValue = inputValue.toLowerCase()
		const newChatMembersList: chatMember[] = []

		chatMembersListSearch.forEach((chatMember: chatMember)=>{
			if(chatMember.name.toLowerCase().includes(inputValue)){
				newChatMembersList.push(chatMember)
			}
		})  
		setChatMembersListSearch(inputValue ? newChatMembersList : chatInfo.data.chatMembers)
	}, [inputValue])

	return (
		<div className="chat-info-slider-participants-container">
			{
				chatMembersListSearch.map((memberCard: chatMember)=>{
					return(
						<ChatParticipantCard {...memberCard} key={`chat-info-participant-card-${memberCard.userId}`}/>
					)
				})
			}
		</div>

	)
}

export default SearchChatMembers