// react
import { FC, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'

// network 
import { authorizedRequest } from '../../../../utils/queries'
import { singleCustomerUrl } from '../../../../utils/urls/sales/customer'

// types
import { infoDetailsTableDataItem, customerResponse } from '../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type details = {
	name: string
	email: string
	description: string
	phoneNumber: string
	editAccess: boolean
}

const CustomerDetails: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customer.customerDetails' })

	const navigate = useNavigate()
	const { relationshipId } = useParams()
	const [details, setDetails] = useState<details>({
		name: '',
		email: '',
		phoneNumber: '',
		description: '',
		editAccess: false
	})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const infoDetailsTableData: infoDetailsTableDataItem[] =
		[
			{
				title: t('name'),
				data: {
					inputField: {
						type: 'text',
						value: details.name,
						onChange: (e) => {
							setDetails({ ...details, name: e.target.value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('email'),
				data: {
					inputField: {
						type: 'text',
						value: details.email,
						onChange: (e) => {
							setDetails({ ...details, email: e.target.value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('phoneNumber'),
				data: {
					inputField: {
						type: 'text',
						value: details?.phoneNumber || '',
						onChange: (e) => {
							setDetails({ ...details, phoneNumber: e.target.value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('description'),
				data: {
					textArea: {
						value: details.description,
						setValue: (value) => {
							setDetails({ ...details, description: value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			}
		]


	const loadData = async () => {
		if (isNaN(Number(relationshipId))) {
			navigate('/ri-business/404')
		}
		try {
			setLoading(true)
			const { result }: { result: customerResponse } = await authorizedRequest(singleCustomerUrl(Number(relationshipId)), 'GET')

			if (result === undefined) {
				navigate('/ri-business/404')
			}
			setDetails({
				name: result.name,
				email: result.email,
				phoneNumber: result.phone,
				description: result.description,
				editAccess: result.edit_access
			})
		} finally {
			setLoading(false)
		}
	}

	const editData = async () => {
		if (Number(relationshipId)) {
			await authorizedRequest(singleCustomerUrl(Number(relationshipId)), 'PUT', 'accessToken', {
				name: details.name,
				email: details.email,
				description: details.description,
				phone: details.phoneNumber
			})
		}
	}

	useEffect(() => {

		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}

	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return <>
		<InfoDetailsTable data={infoDetailsTableData} />
		{loading && <Loading style={{ top: '40vh', left: '50vw' }} />}
	</>
}

export default CustomerDetails