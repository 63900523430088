// images
import customer1En from '../../../assets/manual/sale/en/customersHome.png'
import customer2En from '../../../assets/manual/sale/en/customersList.png'
import customer3En from '../../../assets/manual/sale/en/createCustomers.png'
import customer1Ua from '../../../assets/manual/sale/ua/customersHome.png'
import customer2Ua from '../../../assets/manual/sale/ua/customersList.png'
import customer3Ua from '../../../assets/manual/sale/ua/createCustomers.png'
import saleOrder1En from '../../../assets/manual/sale/en/salesOrderHome.png'
import saleOrder2En from '../../../assets/manual/sale/en/salesOrderList.png'
import saleOrder3En from '../../../assets/manual/sale/en/createSalesOrder.png'
import offering1En from '../../../assets/manual/sale/en/offeringHone.png'
import offering2En from '../../../assets/manual/sale/en/offeringsList.png'
import offering3En from '../../../assets/manual/sale/en/addNewOffering.png'
import saleOrder1Ua from '../../../assets/manual/sale/ua/salesOrderHome.png'
import saleOrder2Ua from '../../../assets/manual/sale/ua/salesOrderList.png'
import saleOrder3Ua from '../../../assets/manual/sale/ua/createSalesOrder.png'
import offering1Ua from '../../../assets/manual/sale/ua/offeringHone.png'
import offering2Ua from '../../../assets/manual/sale/ua/offeringsList.png'
import offering3Ua from '../../../assets/manual/sale/ua/addNewOffering.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const GeneralSaleManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.sales' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('customersTitle'),
			text: t('customersText'),
			link: '/ri-manual/sales/customers'
			// images: language === 'en' ? [customer1En, customer2En, customer3En] : [customer1Ua, customer2Ua, customer3Ua]
		},
		{
			title: t('singleCustomerTitle'),
			text: t('singleCustomerText'),
			link: '/ri-manual/sales/single-customer'
			// images: language === 'en' ? [customer1En, customer2En, customer3En] : [customer1Ua, customer2Ua, customer3Ua]
		},
		{
			title: t('salesOrdersTitle'),
			text: t('salesOrdersText'),
			link: '/ri-manual/general/single-order'
			// images: language === 'en' ? [saleOrder1En, saleOrder2En, saleOrder3En] : [saleOrder1Ua, saleOrder2Ua, saleOrder3Ua]

		},
		{
			title: t('offeringsTitle'),
			text: t('offeringsText'),
			link: '/ri-manual/sales/offerings'
			// images: language === 'en' ? [offering1En, offering2En, offering3En] : [offering1Ua, offering2Ua, offering3Ua]
		},
		{
			title: t('singleOfferingTitle'),
			text: t('singleOfferingText'),
			link: '/ri-manual/sales/single-offering'
			// images: language === 'en' ? [offering1En, offering2En, offering3En] : [offering1Ua, offering2Ua, offering3Ua]
		}
	]
	
	return (
		<ManualLayout 
			heading={t('salesManualTitle')}
			data={data}
		/>
	)
}

export default GeneralSaleManual
