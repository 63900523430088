// styles
import './appsList.scss'

// images
import ImageOne from '../../../assets/images/riLanding.png'
import ImageTwo from '../../../assets/images/riLanding2.png'
import ImageThree from '../../../assets/images/riLanding3.png'

// components
import AppFeature from './appFeature/appFeature'
import ScrollAnimation from '../../general/scrollAnimation/scrollAnimation'
import HeaderWithLines from '../../general/headerWithLines/headerWithLines'
import Title from '../../general/title/title'
import Subtitle from '../../general/subtitle/subtitle'
import NavBarSlider from '../../general/navBarSlider/navBarSlider'

//translation
import { useTranslation } from 'react-i18next'

enum button  {
	SOLID = 'solid',
	OUTLINED = 'outlined',
}

const AppsList = () => {

	const { t } = useTranslation('', { keyPrefix: 'apps.landing.home.appList' })

	const apps = [
		{
			smallText: t('riBusiness.title'),
			headline: t('riBusiness.headline'),
			text: t('riBusiness.text'),
			buttons: [
				{
					text: t('riBusiness.buttons.learnMore.text'),
					link: '/product-description',
					variant: button.SOLID
				},
				{
					text: t('riBusiness.buttons.setUpYourBusiness.text'),
					link: '/register',
					variant: button.OUTLINED
				}
			],
			contentRight: false,
			image: ImageOne,
			extras: [<NavBarSlider subtitle='Check out some of the cool features' />]
		},
		// {
		// 	smallText: 'RI Store',
		// 	headline: 'Beautiful products for every need ',
		// 	text: 'Explore unique sellers and find a product for every need and want. RI Store connects thousands of businesses together, giving you a massive marketplace for whatever you can think of.',
		// 	buttons: [
		// 		{
		// 			text: 'Learn more',
		// 			link: '/landing/product-description',
		// 			variant: button.SOLID
		// 		},
		// 		{
		// 			text: 'Look for products',
		// 			link: '/register',
		// 			variant: button.OUTLINED
		// 		}
		// 	],
		// 	contentRight: true,
		// 	image: ImageTwo
		// },
		// {
		// 	smallText: 'RI Wholesale',
		// 	headline: 'Fill your warehouse in just 1 click ',
		// 	text: 'RI Wholesale gives your business a cutting edge advantage to find leading wholesalers of every industry and product type. Now, you can easily fill your stores with quality products in just 1 click through RI Wholesale. ',
		// 	buttons: [
		// 		{
		// 			text: 'Learn more',
		// 			link: '/landing/product-description',
		// 			variant: button.SOLID
		// 		},
		// 		{
		// 			text: 'Find wholesalers',
		// 			link: '/register',
		// 			variant: button.OUTLINED
		// 		}
		// 	],
		// 	contentRight: false,
		// 	image: ImageTwo
		// },
		// {
		// 	smallText: 'RI Jobs',
		// 	headline: 'Find and share talent with the entire world ',
		// 	text: 'A next-gen platform for employees wanting to showcase their talent and skills, and for employers to find the perfect candidate for their projects. RI Jobs connects passionate businesses with top-tier talent. ',
		// 	buttons: [
		// 		{
		// 			text: 'Learn more',
		// 			link: '/landing/product-description',
		// 			variant: button.SOLID
		// 		},
		// 		{
		// 			text: 'Explore vacancies',
		// 			link: '/register',
		// 			variant: button.OUTLINED
		// 		}
		// 	],
		// 	contentRight: true,
		// 	image: ImageThree
		// }
	]

	return (
		<div className='apps-list'>
			<HeaderWithLines>
				<ScrollAnimation direction='to-top' distance={2}>
					<Title>
						{t('meetOur')} <span className="text-blue">{t('helpful')}</span> {t('apps')}
					</Title>
					<Subtitle>
						{t('designedFor')} <span className='text-green'>{t('you')}</span> {t('andWithYour')} <span className='text-cyan'>{t('business')}</span> {t('inMind')}
					</Subtitle>
				</ScrollAnimation>
			</HeaderWithLines>
			{apps.map((app, idx)=> 
				<AppFeature 
					key={`app-feature-${idx}`}
					smallText={app.smallText}
					heading={app.headline}
					text={app.text}
					buttons={app.buttons}
					image={app.image}
					contentRight={app.contentRight}
					extras={app.extras}
				/>
			)}
		</div>
	)
}

export default AppsList