import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type warehouseGeneralModalsState = {
    importShipmentFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    shipmentTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportShipmentFileModal: {
        modalIsOpen: boolean
    }
    importMovingFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    movingTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportMovingFileModal: {
        modalIsOpen: boolean
    }

    createInventoryItemModal: {
		modalIsOpen: boolean
	}
	deleteInventoryItemModal: {
		modalIsOpen: boolean
		inventoryItemId: number | null
	}
    importInventoryItemFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    inventoryItemTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportInventoryItemFileModal: {
        modalIsOpen: boolean
    }
	importInventoryItemImageSelectorModal: {
		modalIsOpen: boolean
	}
	deleteInventoryItemImageModal: {
		modalIsOpen: boolean
		imageId: number | null
	}
}

const initialState: warehouseGeneralModalsState = {
	importShipmentFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	shipmentTablePreviewModal: {
		modalIsOpen: false
	},
	exportShipmentFileModal: {
		modalIsOpen: false
	},
	importMovingFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	movingTablePreviewModal: {
		modalIsOpen: false
	},
	exportMovingFileModal: {
		modalIsOpen: false
	},

	createInventoryItemModal: {
		modalIsOpen: false
	},
	deleteInventoryItemModal: {
		modalIsOpen: false,
		inventoryItemId: null
	},
	importInventoryItemFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	inventoryItemTablePreviewModal: {
		modalIsOpen: false
	},
	exportInventoryItemFileModal: {
		modalIsOpen: false
	},
	importInventoryItemImageSelectorModal: {
		modalIsOpen: false
	},
	deleteInventoryItemImageModal: {
		modalIsOpen: false,
		imageId: null
	}
}

export const warehouseGeneralModalsSlice = createSlice({
	name: 'warehouseGeneralModalReducer',
	initialState,
	reducers : {
		setImportShipmentFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importShipmentFileSelectorModal.modalIsOpen = action.payload
		},
		setImportShipmentFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importShipmentFileSelectorModal.file = action.payload
		},
		setShipmentTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.shipmentTablePreviewModal.modalIsOpen = action.payload
		},
		setExportShipmentFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportShipmentFileModal.modalIsOpen = action.payload
		},

		setImportMovingFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importMovingFileSelectorModal.modalIsOpen = action.payload
		},
		setImportMovingFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importMovingFileSelectorModal.file = action.payload
		},
		setMovingTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.movingTablePreviewModal.modalIsOpen = action.payload
		},
		setExportMovingFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportMovingFileModal.modalIsOpen = action.payload
		},


		setCreateInventoryItemModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createInventoryItemModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteInventoryItemModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemId: (state, action: PayloadAction<number | null>) => {
			state.deleteInventoryItemModal.inventoryItemId = action.payload
		},
		setImportInventoryItemFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importInventoryItemFileSelectorModal.modalIsOpen = action.payload
		},
		setImportInventoryItemFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importInventoryItemFileSelectorModal.file = action.payload
		},
		setInventoryItemTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.inventoryItemTablePreviewModal.modalIsOpen = action.payload
		},
		setExportInventoryItemFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportInventoryItemFileModal.modalIsOpen = action.payload
		},
		setImportInventoryItemImageSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importInventoryItemImageSelectorModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemImageModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.deleteInventoryItemImageModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemImageId: (state, action: PayloadAction<number | null>)=> {
			state.deleteInventoryItemImageModal.imageId = action.payload
		},
        
	}
})

export const {
	setImportShipmentFileSelectorModalIsOpen,
	setImportShipmentFileSelectorModalFile,
	setShipmentTablePreviewModalIsOpen,
	setExportShipmentFileModalIsOpen,

	setImportMovingFileSelectorModalIsOpen,
	setImportMovingFileSelectorModalFile,
	setMovingTablePreviewModalIsOpen,
	setExportMovingFileModalIsOpen,

	setCreateInventoryItemModalIsOpen,
	setDeleteInventoryItemModalIsOpen,
	setDeleteInventoryItemId,
	setImportInventoryItemFileSelectorModalIsOpen,
	setImportInventoryItemFileSelectorModalFile,
	setInventoryItemTablePreviewModalIsOpen,
	setExportInventoryItemFileModalIsOpen,
	setImportInventoryItemImageSelectorModalIsOpen,
	setDeleteInventoryItemImageModalIsOpen,
	setDeleteInventoryItemImageId
} = warehouseGeneralModalsSlice.actions

export default warehouseGeneralModalsSlice.reducer
