//styles and icons
import './notAuthorised.scss'

// components
import Layout from '../../../components/general/layout/layout'

function NotAuthorised() {
	return (
		<Layout 
			header={{
				avatar: true
			}}
		>
			<div className='not-authorised'>
				<h3>Not Authorised | 403</h3>
			</div>
		</Layout>
	)
}

export default NotAuthorised
