//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { topProvidersByAmount } from '../../../../utils/urls/purchases/providerStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const SupplierAndVendorManagement = () => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.purchaseStats.supplierAndVendorManagementTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadTopProvidersByAmount = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(topProvidersByAmount(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
		
	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('top_providers_by_amount')} loadData={loadTopProvidersByAmount} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default SupplierAndVendorManagement