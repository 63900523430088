// images
import customerDetailsEn from '../../../../assets/manual/sale/en/customerDetails.png'
import customerDetailsAddress1En from '../../../../assets/manual/sale/en/customerDetailsAddress.png'
import customerDetailsAddress2En from '../../../../assets/manual/sale/en/createAddress.png'
import customerDetailsDocuments1En from '../../../../assets/manual/sale/en/customerDetailsDocuments.png'
import customerDetailsDocuments2En from '../../../../assets/manual/sale/en/createDocuments.png'
import customerDetailsOrders1En from '../../../../assets/manual/sale/en/customerDetailsOrders.png'
import customerDetailsOrders2En from '../../../../assets/manual/sale/en/customerDetailsOrderCreate.png'
import customerDetailsBill1En from '../../../../assets/manual/sale/en/customerDetailsBill.png'
import customerDetailsBill2En from '../../../../assets/manual/sale/en/customerDetailsCreateBill.png'
import customerDetailsUa from '../../../../assets/manual/sale/ua/customerDetails.png'
import customerDetailsAddress1Ua from '../../../../assets/manual/sale/ua/customerDetailsAddress.png'
import customerDetailsAddress2Ua from '../../../../assets/manual/sale/ua/createAddress.png'
import customerDetailsDocuments1Ua from '../../../../assets/manual/sale/ua/customerDetailsDocuments.png'
import customerDetailsDocuments2Ua from '../../../../assets/manual/sale/ua/createDocuments.png'
import customerDetailsOrders1Ua from '../../../../assets/manual/sale/ua/customerDetailsOrders.png'
import customerDetailsOrders2Ua from '../../../../assets/manual/sale/ua/customerDetailsOrderCreate.png'
import customerDetailsBill1Ua from '../../../../assets/manual/sale/ua/customerDetailsBill.png'
import customerDetailsBill2Ua from '../../../../assets/manual/sale/ua/customerDetailsCreateBill.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const SingleCustomerManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.sales.singleCustomer' })
	const { language } = useAppSelector(state => state.general)
	
	const data: manualData[] = [
		{
			title: t('addressesTitle'),
			text: t('addressesText')			
		},
		{
			title: t('billsTitle'),
			text: t('billsText')			
		}
	]
	
	return (
		<ManualLayout 
			heading={t('customersTitle')}
			data={data}
		/>
	)
}

export default SingleCustomerManual
