//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { avarageTimeToHire } from '../../../../utils/urls/employees/employeeStat'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const HRAnalysis = () => {
	const { t } = useTranslation('', { keyPrefix: 'employees.statistics.hiring' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadAvarageTimeToHire = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(avarageTimeToHire(userCompanyData?.companyId || -1), startDate, endDate, duration)

	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('timeSpentHiring')} loadData={loadAvarageTimeToHire} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default HRAnalysis