//reaact
import React, { FC, useCallback, useEffect, useState } from 'react'

//types
import { treeElement } from '../../../types/general/generalTypes'

//components
import Loading from '../loading/loading'
import NodesContainer from './nodesContainer/nodesContainer'
import TreeNode from './treeNode/treeNode'

//others
import { vwToPixel } from '../../../assets/general/generalFunctions'
import ReactFlow, { Background, BackgroundVariant, Edge, Node, OnEdgesChange, OnNodesChange, applyEdgeChanges, applyNodeChanges, Controls, NodeTypes } from 'reactflow'
import Dagre from '@dagrejs/dagre'

const edgeStyle = () => ({
	style: {
		strokeWidth: vwToPixel(0.35),
		stroke: '#1E1B39'
	},
	type: 'smoothstep',
	offset: vwToPixel(0.2)
})

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

type heightMap = Record<number, number>;

const getParentIds = (tree: treeElement, parentIds: Record<number, number[]> = {}, currentPath: number[] = []): Record<number, number[]> => {
	currentPath.push(tree.id)

	for (const subElement of tree.subElements) {
		if (!parentIds[subElement.id]) {
			parentIds[subElement.id] = currentPath.slice()
		} else {
			parentIds[subElement.id].push(...currentPath.slice())
		}
		getParentIds(subElement, parentIds, currentPath)
	}

	currentPath.pop()
	return parentIds
}

const generateLayout = (nodes: Node[], edges: Edge[], nodeDims: { width: number, height: number, rank: string }[], treeElements: treeElement) => {
	if (nodeDims.length < 1) {
		return
	}
	const width: number = (Math.max(...nodeDims.map(dim => dim.width)) || 300) * 1.1
	const childParentsRelations = getParentIds({ ...treeElements })

	const heightMap: heightMap = {}

	nodeDims.forEach((e) => {
		heightMap[Number(e.rank.split('-')[1])] = e.height
	})

	g.setGraph({ rankdir: 'TB', edgesep: width, ranker: 'network-simplex' })


	edges.forEach((edge) => g.setEdge(edge.source, edge.target))
	nodes.forEach((node) => g.setNode(node.id, node.data))

	Dagre.layout(g)

	return {
		nodes: nodes.map((node) => {
			const nodeKey = Number([node.id.split('-')[1]])

			const parentsHeight = childParentsRelations[nodeKey] ? (childParentsRelations[nodeKey].reduce((acc, e) => acc += heightMap[e], 0)) * 1.2 : heightMap[nodeKey] / 20

			const { x, y } = g.node(node.id)
			return { ...node, position: { x, y: parentsHeight + y } }
		}),
		edges
	}
}

const nodeType: NodeTypes = {
	tree: TreeNode
}

type TreeProps = {
	treeElements: treeElement;
	minZoom?: number;
	maxZoom?: number;
	controls?: boolean;
	panOnDrag?: boolean;
};

const Tree: FC<TreeProps> = ({ treeElements, minZoom, maxZoom, controls, panOnDrag }) => {
	const [nodes, setNodes] = useState<Node[]>([])
	const [edges, setEdges] = useState<Edge[]>([])
	const [nodeDims, setDims] = useState<{ width: number, height: number, rank: string }[]>([])
	const [flowNodes, setFlowNodes] = useState<Node[]>([])
	const [flowEdges, setFlowEdges] = useState<Edge[]>([])

	const onNodesChange: OnNodesChange = useCallback((changes) => {
		setNodes((nds) => applyNodeChanges(changes, nds))
	}, [])

	const onEdgesChange: OnEdgesChange = useCallback((changes) => {
		setEdges((eds) => applyEdgeChanges(changes, eds))
	}, [])

	const generateNodes = (data: treeElement) => {
		const stack = [data]
		const treeNode: Node[] = []
		const treeEdges: Edge[] = []

		while (stack.length !== 0) {
			const lastElem = stack.pop()

			if (lastElem) {
				treeNode.push({
					id: `tree-${lastElem.id}`,
					type: 'tree',
					position: { x: 0, y: 0 },
					data: { element: lastElem.element }
				})
			}

			if (lastElem?.subElements && lastElem?.subElements.length > 0) {
				stack.push(...lastElem.subElements)
				lastElem.subElements.forEach(subs => {
					treeEdges.push({
						id: `tree-${subs.id}-treeSub-${subs.id}`,
						source: `tree-${lastElem.id}`,
						target: `tree-${subs.id}`,
						...edgeStyle()
					})
				})
			}
		}

		setEdges(treeEdges)
		setNodes(treeNode)
	}

	const hadnleNodeDimsUpdate = (nodeDims: { width: number, height: number, rank: string }[]) => {
		setDims(nodeDims)
	}

	useEffect(() => {
		if (treeElements) {
			generateNodes(treeElements)
		}
	}, [treeElements])

	useEffect(() => {
		if (nodes.length > 0 && nodeDims.length > 0) {
			const layoutedData = generateLayout(nodes, edges, nodeDims, treeElements)
			if (layoutedData) {
				setFlowEdges(layoutedData.edges)
				setFlowNodes(layoutedData.nodes)
			}
		}
	}, [nodeDims, nodes, edges])

	const handleResize = () => {
		setEdges([])
		setNodes([])
		setFlowEdges([])
		setFlowNodes([])
		generateNodes(treeElements)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div id='organization-chart-container' className='organization-chart-container'>
			{flowNodes.length > 0 && (
				<ReactFlow
					nodes={flowNodes}
					edges={flowEdges}
					maxZoom={maxZoom}
					minZoom={minZoom}
					onEdgesChange={onEdgesChange}
					onNodesChange={onNodesChange}
					nodeTypes={nodeType}
					nodesDraggable={false}
					fitView
					preventScrolling
					zoomOnDoubleClick={false}
					panOnDrag={panOnDrag}
					defaultEdgeOptions={{ style: { alignSelf: 'center' } }}
				>
					{controls ? <Controls showZoom={false} position='top-right' showInteractive={false} /> : null}
					<Background size={1} variant={BackgroundVariant.Cross} />
				</ReactFlow>
			)}
			{
				flowNodes.length < 1 && <Loading style={{ marginTop: '45vh', marginLeft: '10%' }} />
			}
			<div style={{ position: 'absolute', visibility: 'hidden' }}>
				{nodes.length > 0 && flowNodes.length <= 0 && <NodesContainer handleNodeDimsUpdate={hadnleNodeDimsUpdate} nodes={nodes} />}
			</div>
		</div>
	)
}

export default Tree
