// react
import { Dispatch, FC, SetStateAction } from 'react'

// components
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportOfferingImageSelectorModalIsOpen } from '../../../../../../redux/sales/offering/modals'

// types
import { document } from '../../../../../../types/general/generalTypes'


type importOfferingImageSelectorModalProps = {
	images: document[]
	setImages: Dispatch<SetStateAction<document[]>>
	setEditDetails: Dispatch<SetStateAction<boolean>>
}
const ImportOfferingImageSelectorModal: FC<importOfferingImageSelectorModalProps> = ({ images, setImages, setEditDetails}) => {
	const { modalIsOpen } = useAppSelector(state => state.offeringModal.details.importOfferingImageSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = ()=> {
		dispatch(setImportOfferingImageSelectorModalIsOpen(false))
	}
	
	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal} 
			setFiles={(files)=> {
				const addedImages: document[] = files.map((file,idx) => {
					return {
						fileName: `offering-image-${images.length + idx}`,
						fileType: 'image/png',
						id: images.length + idx,
						file: file.file
					}
				})
				setImages([...images, ...addedImages])
				setEditDetails(true)
				closeModal()
			}}
		/>
	)
}

export default ImportOfferingImageSelectorModal