//styles
import './taskTimelineCard.scss'

// icons
import { basicBlackAvatar } from '../../../../../assets/general/generalIcons'

// components
import Avatar from '../../../../general/avatar/avatar'

// types
import { task } from '../../../../../types/general/generalTypes'

//react
import { FC, useEffect } from 'react'

//translation
import {useTranslation} from 'react-i18next'

type taskTimelineCardProps = {
	daysDiff: number,
	daysDiffLeft: number,
	daysDiffwidth: number,
	task: task<Date>
}

const TaskTimelineCard : FC<taskTimelineCardProps> = ({daysDiff, daysDiffLeft, daysDiffwidth, task}) => {
	const {t}=useTranslation('',{keyPrefix:'general.shortDays'})
    
	const getDateAndMonth = (date: Date)=> {
		return (`${date.toLocaleString('EN', {month: 'short'})} ${date.getDate()}`).toLowerCase()
	}

	useEffect(() => {
		console.log(task)
	}, [task])

	return(
		<div className="timeline-calendar-table-time-interval-row-container" style={{gridTemplateColumns: `repeat(${daysDiff},4.86vw)`}}>
			<div className="timeline-calendar-table-time-interval-container-item" style={{
				gridColumnStart: daysDiffLeft,
				gridColumnEnd: daysDiffwidth+daysDiffLeft,
				flexDirection: daysDiffwidth > 3  ? 'row' : 'column'
			}}>
				<div className='info'>
					<div className='title'>
						<div style={{background: `${task.status.color}`}} className='top-line' />
						<p>{task.title}</p>
					</div>
					<div className='date-container'>
						{t(getDateAndMonth(task.startDate).split(' ')[0])} {getDateAndMonth(task.startDate).split(' ')[1]} - {t(getDateAndMonth(task.endDate).split(' ')[0])} {getDateAndMonth(task.endDate).split(' ')[1]}
					</div>
				</div>
				<div className="actions" style={{
					flexDirection: daysDiffwidth > 3 ? 'column-reverse' : 'row'
				}}>
					<div className="executors-container">
						<div className='executors'>
							{task.executors.length > 3 ? (
								<>
									{task.executors.slice(0, 3).map((executor) => {
										console.log(task.executors.slice(0, 3), executor)
										return <div className='executor-avatar-container'><Avatar blobAvatar={executor.avatar} placeholder={basicBlackAvatar} /></div>
									})}
								</>
							) : (
								<>
									{task.executors.map((executor) => (
										<div className='executor-avatar-container'><Avatar blobAvatar={executor.avatar} placeholder={basicBlackAvatar} /></div>
									))}
								</>
							)}
						</div>
						{task.executors.length > 3 && <p>{`+${task.executors.length - 3}`}</p>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TaskTimelineCard
