// styles
import './barChart.scss'

// react
import { FC, useEffect, useRef, useState } from 'react'

//types
import { charItemData, chartItem, legendItem } from '../../../../types/general/generalTypes'

//other
import { vwToPixel } from '../../../../assets/general/generalFunctions'

// chart library
import { Chart, CategoryScale, LinearScale,BarElement} from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(LinearScale)
Chart.register(CategoryScale)
Chart.register(BarElement)

type datasetType = {
	data:number[]
	colorList:string[]
}

type barChartProps = {
	chartData: {
		xAxisLabels: string[]
		chartItems: chartItem[]
	}
	activeData: legendItem[]
}

const BarChart : FC<barChartProps> = ({ chartData, activeData }) => {

	const [responsiveSize, setResponsiveSize] = useState({
		fontSize:0,
		borderWidth:0,
		barThickness:0,
		borderDash:0
	})

	const stylingOptions = {
		borderRadius: 100,
		offset:-15,
		barPercentage:.7,
		maxBarThickness:responsiveSize.barThickness,
		categoryPercentage:0.3,
		spacing:2,
	}

	const filterData = (activeChartItems: legendItem[])=>{
		return chartData.chartItems.filter((chartItem) => activeChartItems.findIndex((activeItem) => activeItem.label === chartItem.label) !== -1)
	}
	
	const formatData = (chartItems: chartItem[]) => {

		const formattedData: datasetType[]  = []

		const completeChartItems: chartItem[] = chartItems.map((item) => {
			const completeData: charItemData[] = []

			for(let i=0; i<chartData.xAxisLabels.length; i++){

				const [day, month, year] = chartData.xAxisLabels[i].split('.')

				const date = new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0, 0)
				let value = 0


				for(let j=0; j<item.data.length; j++){
					const itemDate = new Date(item.data[j].date.getFullYear(), item.data[j].date.getMonth(), item.data[j].date.getDate(), 0, 0, 0, 0)
					if(itemDate.getTime() === date.getTime()){
						value = item.data[j].value
						break
					}
				}

				completeData.push({
					date: date,
					value: value
				})
			}

			return {
				...item,
				data: completeData
			}
		})

		for(let i = 0; i < completeChartItems.length; i++){
			const dataset: number[] = []
			const colorList: string[] = []
			for(let j = 0; j < completeChartItems[i].data.length; j++){
				dataset.push(completeChartItems[i].data[j].value ?? 0)
				colorList.push(completeChartItems[i].color)
			}

			const currentData: datasetType = {
				data:dataset,
				colorList
			}

			formattedData.push(currentData)
		}

		const datasets = formattedData.map((dataset, index)=>{
			return {
				label: completeChartItems[index].label,
				data: dataset.data,
				backgroundColor: dataset.colorList,
				...stylingOptions
			}
		})

		return {
			labels: chartData.xAxisLabels,
			datasets
		}
	
	}

	const containerRef = useRef<HTMLDivElement>(null)
	const [ ratio, setRatio ] = useState(0)
	const [ max, setMax ] = useState(0)
	const [ min, setMin ] = useState(0)

	const [barChartData, setBarChartData] = useState(formatData(filterData(activeData)))

	useEffect(() => {
		setBarChartData(formatData(filterData(activeData)))
	}, [chartData, activeData])
	
	// Calculate the correct ratio based on parent
	useEffect(()=>{
		if(containerRef.current){
			setRatio(containerRef.current.clientWidth / containerRef.current.clientHeight)
		}
	},[containerRef, chartData])

	useEffect(()=>{
		const resize = ()=>{
			setResponsiveSize({
				fontSize: vwToPixel(.7),
				borderWidth: vwToPixel(.2),
				barThickness: vwToPixel(.9),
				borderDash: vwToPixel(.5)
			})
		}

		window.addEventListener('resize', resize)

		resize()

		return () => {
			window.removeEventListener('resize', resize)
		}
	},[])

	useEffect(() => {
		const allValues: number[] = []

		for(let i=0; i<chartData.chartItems.length; i++){
			for(let j=0; j<chartData.chartItems[i].data.length; j++){
				allValues.push(chartData.chartItems[i].data[j].value)
			}
		}

		setMax(Math.max(...allValues))
		setMin(Math.min(...allValues, 0))
	}, [chartData])	

	return (
		<div className='bar-container' ref={containerRef}>
			<Bar
				data={barChartData} 
				updateMode='resize'
				options={{
					color:'#615E83',
					aspectRatio: ratio,
					scales:{
						x:{
							ticks:{
								font: {
									family:'Poppins',
									size:responsiveSize.fontSize,
								}
							},
							offset:true,
							border:{
								width:responsiveSize.borderWidth,
								z:1,
								color:'#E5E5EF'
							},
							beginAtZero:true,
							grid:{
								color:'transparent',
								tickLength:0
							},
						
						},
						y:{
							ticks:{
								font: {
									family:'Poppins',
									size:responsiveSize.fontSize,
								},
							},
							border:{
								dash:[responsiveSize.borderDash],
								dashOffset:responsiveSize.borderDash,
								color:'#E5E5EF',
								width: responsiveSize.borderWidth,
							},
							grid:{
								tickLength:0
							},
						
							max:max,
							min:min,
							beginAtZero:true,
						
						},
					},
					plugins:{
						legend:{
							position:'top',
							display:true
						}
					}
				}}
			/>	
		</div>
	)
}

export default BarChart