// styles
import './documentList.scss'
import { close } from '../../../assets/general/generalIcons'
// react
import { FC, useEffect, useState } from 'react'
// components
import ColumnLayout from '../columnLayout/columnLayout'
import FileItem from '../fileItem/fileItem'
import FilePreviewModal from '../modals/filePreview/filePreviewModal'

// types
import { document } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type documentListProps = {
	fileList: document[]
	handleDelete: (id: number) => void
}
const DocumentList: FC<documentListProps> = ({ fileList, handleDelete }) => {
	const { t } = useTranslation('', {keyPrefix: 'general.documentList'})
	const [itemsPerRow, setItemsPerRow] = useState(window.innerWidth > 768 ? 5 : window.innerWidth > 425 ? 3 : 2)

	useEffect(() => {

		const handler = () => {
			setItemsPerRow(window.innerWidth > 768 ? 5 : window.innerWidth > 425 ? 3 : 2)
		}

		window.addEventListener('resize', handler)

		return () => {
			window.removeEventListener('resize', handler)
		}

	}, [])

	return (
		<div className="document-list">
			{fileList.length === 0 ? <div className='document-list-empty'>{t('noFiles')}</div> :
				<ColumnLayout amount={itemsPerRow}>
					{fileList.map(file => (
						<div className='document-item-container'>
							<div className='file-item-button-delete' onClick={() => handleDelete(file.id)}>
								{close}
							</div>
							<FileItem
								file={file}
							/>
						</div>
					))}

				</ColumnLayout>

			}
			<FilePreviewModal/>
		</div>
	)
}

export default DocumentList