// styles
import './infoDetailsTableField.scss'

// react
import { FC, useState } from 'react'

// components
import InputField from '../../inputField/inputField'
import Dropdown from '../../dropdown/dropdown'
import StatusLabel from '../../statusLabel/statusLabel'
import TextArea from '../../textArea/textArea'
import CalendarContainer from '../../calendarContainer/calendarContainer'
import LocationSearchInput from '../../locationSearchInput/locationSearchInput'
import EditableTable from '../../editableTable/editableTable'
import Loading from '../../loading/loading'
import TimeSelector from '../../timeSelector/timeSelector'
import FloatInput from '../../floatInput/floatInput'

// types 
import { infoDetailsTableDataItem } from '../../../../types/general/generalTypes'


type infoDetailsTableFieldProps = infoDetailsTableDataItem

const InfoDetailsTableField: FC<infoDetailsTableFieldProps> = ({ title, data, fullWidth }) => {
	const [loading, setLoading] = useState<boolean>(false)

	const dataMap = {

		inputField: data.inputField && (
			<InputField
				{...data.inputField}
			/>
		),
		floatInput: data.floatInput && (
			<FloatInput
				{...data.floatInput}
			/>
		),
		dropdown: data.dropdown && (
			<Dropdown
				{...data.dropdown}
			/>
		),
		label: data.label && (
			<StatusLabel {...data.label} />
		),
		textArea: data.textArea && (
			<TextArea
				{...data.textArea}
			/>
		),
		calendar: data.calendar && (
			<CalendarContainer
				{...data.calendar}
			/>
		),
		locationSearchInput: data.locationSearchInput && (
			<LocationSearchInput
				{...data.locationSearchInput}
				setLoading={setLoading}
			/>
		),
		editableTable: data.editableTable && (
			<EditableTable
				{...data.editableTable}
			/>
		),
		shiftSelector: data.shiftSelector && (
			<TimeSelector {...data.shiftSelector} />
		)
	}

	const content = Object.values(dataMap).filter(Boolean)

	// console.log(fullWidth)

	return (
		<>
			<div className={`info-details-table-field ${fullWidth ? 'full' : ''}`}>
				<p className='info-details-table-field-title'>{title}</p>
				<div className="info-details-table-field-content">
					{
						content
					}
				</div>
			</div>
			{loading ? <Loading /> : null}
		</>
	)
}

export default InfoDetailsTableField