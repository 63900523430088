// styles and icons
import './offeringList.scss'

//react
import { FC, useState, useEffect } from 'react'

//components
import Pagination from '../../../components/general/pagination/pagination'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import CreateOfferingModal from '../../../components/sales/offeringList/modals/createOfferingModal/createOfferingModal'
import DeleteOfferingModal from '../../../components/sales/offeringList/modals/deleteOfferingModal/deleteOfferingModal'
import ImportOfferingFileSelectorModal from '../../../components/sales/offeringList/modals/importOfferingFileSelectorModal/importOfferingFileSelectorModal'
import OfferingTablePreviewModal from '../../../components/sales/offeringList/modals/offeringTablePreviewModal.tsx/offeringTablePreviewModal'
import ExportOfferingFileModal from '../../../components/sales/offeringList/modals/exportOfferingFileModal/exportOfferingFileModal'
import OfferingCard from '../../../components/general/offeringCard/offeringCard'
import Layout from '../../../components/general/layout/layout'
import OfferingCardSkeleton from '../../../components/general/skeletons/offeringCard/offeringCardSkeleton'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateOfferingModalIsOpen, setDeleteOfferingId, setDeleteOfferingModalIsOpen, setExportOfferingFileModalIsOpen, setImportOfferingFileSelectorModalIsOpen } from '../../../redux/sales/offeringList/modals'

//types
import { filters, filterBar, offering, infoPopupTypes } from '../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../utils/queries'
import { companyOfferingsUrl, getOfferingFiltersUrl } from '../../../utils/urls/sales/offerings'

// other
import { createFilteringRequest, formatFilters, formatOfferingResponse, getItemsPerColumn } from '../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'

//hooks
import { useNavigate } from 'react-router-dom'

const OfferingList: FC = () => {
	const dispatch = useAppDispatch()
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const [offerings, setOfferings] = useState<offering[]>([])


	const [filterBar, setFilterBar] = useState<filterBar>()
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(2))
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const { t } = useTranslation('', { keyPrefix: 'sales.offeringList' })

	const titleUrls = [
		{
			url: '/ri-business/sales/offering-list',
			title: t('name')
		}
	]

	const infoPopup = {
		content: [{
			title: 'offering_list_info_title',
			description: 'offering_list_info_description',
			link: '/ri-manual/sales/offerings'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(getOfferingFiltersUrl(companyId), 'GET')
			const filterResult = result.filters

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				importButton: () => {
					dispatch(setImportOfferingFileSelectorModalIsOpen(true))
				},
				// exportButton: () => {
				// 	dispatch(setExportOfferingFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: {
					text: t('add'),
					active: true,
					onClick: () => {
						dispatch(setCreateOfferingModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}

	}
	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyOfferingsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedOfferings: offering[] = result.map(formatOfferingResponse)
				return formatedOfferings
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const handleDelete = (id: number) => {
		dispatch(setDeleteOfferingId(id))
		dispatch(setDeleteOfferingModalIsOpen(true))
	}
	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				if (result.length > 0) {
					setOfferings([...offerings, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setOfferings([...offerings, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn(2))
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<Layout
			header={{
				avatar: true
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="offering-list-container">
				<Pagination loading={loading} onLoadMore={handleLoadMore} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn}>
						{
							offerings.map((offeringItem: offering, index) => {
								return (
									<OfferingCard
										{...offeringItem}
										link={`/ri-business/sales/offering/${offeringItem.offeringId}`}
										handleDelete={offeringItem.deleteAccess ? () => handleDelete(offeringItem.offeringId) : undefined}
										key={`products-${index}`}
									/>
								)
							})
						}
						{loading ? <OfferingCardSkeleton /> : null}
					</ColumnLayout>
					<CreateOfferingModal offerings={offerings} setOfferings={setOfferings} />
					<DeleteOfferingModal offerings={offerings} setOfferings={setOfferings} />
					<ImportOfferingFileSelectorModal />
					<OfferingTablePreviewModal offerings={offerings} setOfferings={setOfferings} />
					<ExportOfferingFileModal />
				</Pagination>
			</div>
		</Layout>
	)
}

export default OfferingList