import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

type chatModalsState = {
	createGroupChat: {
		modalIsOpen: boolean
	}	
}

const initialState: chatModalsState = {
	createGroupChat: {
		modalIsOpen: false
	}
}

export const generalModalsSlice = createSlice({
	name: 'generalModalsSlice',
	initialState,
	reducers: {
		setCreateGroupChatIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createGroupChat.modalIsOpen = action.payload
		}
	}
})

export const { setCreateGroupChatIsOpen } = generalModalsSlice.actions

export default generalModalsSlice.reducer