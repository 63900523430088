//styles
import './progressCircle.scss'

//react
import { FC, useEffect, useState } from 'react'

type progressCircleProps = {
    stamps: {start: number, end: number}[]
	color: string
	width: string
	height: string
}

const ProgressCircle: FC<progressCircleProps> = ({ stamps, color, width, height }) => {
   
	const getConicGradient = (stamps: {start: number, end: number}[]): string => {
		if(stamps.length > 0){

			const gradientContent = `${stamps.map((stamp) => (
				`
					#fff ${stamp.start*15}deg,
					${color} ${stamp.start*15}deg ${stamp.end*15}deg,
					#fff ${stamp.end*15}deg
				`
			)).join(',')}`
	
			return`conic-gradient(
				${gradientContent}
			)`

		}
		return '#fff'
	}

	const [styles, setStyles] = useState({background: '', width: width, height: height})

	useEffect(() => {
		setStyles({background: getConicGradient(stamps), width: width, height: height})
	}, [stamps])

	useEffect(() => {
		console.log(stamps, getConicGradient(stamps))
	}, [styles])

	return <div className="progress-circle" style={styles}></div>
}
  
  
export default ProgressCircle