// styles and icons
import {eye, eyeoff} from '../../../../../assets/general/generalIcons'

// images
import TaskDetailEn from '../../../../assets/manual/project/en/taskDetailsEn.png'
import TaskDashboardEn from '../../../../assets/manual/project/en/taskDashboardEn.png'
import TaskTrackingEn from '../../../../assets/manual/project/en/taskTrackingEn.png'
import TaskWarehouseActionEn from '../../../../assets/manual/project/en/taskWarehouseActionEn.png'
import TaskErrorEn from '../../../../assets/manual/project/en/taskErrorEn.png'
import TaskDetailUa from '../../../../assets/manual/project/ua/taskDetailsUa.png'
import TaskDashboardUa from '../../../../assets/manual/project/ua/taskDashboardUa.png'
import TaskTrackingUa from '../../../../assets/manual/project/ua/taskTrackingUa.png'
import TaskWarehouseActionUa from '../../../../assets/manual/project/ua/taskWarehouseActionUa.png'
import TaskErrorUa from '../../../../assets/manual/project/ua/taskErrorUa.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const SingleTaskManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.projects.singleTask' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('subtaskTitle'),
			text: t('subtaskText')			
		},
		{
			title: t('trackingTitle'),
			text: t('trackingText')
			
		},
		{
			title: t('warehouseActionTitle'),
			text: t('warehouseActionText')
			
		},
		{
			title: t('errorsTitle'),
			text: t('errorsText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singleTaskManualTitle')}
			data={data}
		/>
	)
}

export default SingleTaskManual
