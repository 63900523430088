//styles and icons
import './restrictFileSizeModal.scss'
import { restrictModalFile } from '../../../../assets/general/generalIcons'


//components
import Modal from '../modal/modal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setRestrictModalOpen } from '../../../../redux/general/modals'

// translation
import { useTranslation } from 'react-i18next'

const RestrictFileSizeModal = () => {
	const dispatch = useAppDispatch()
	
	const {modalIsOpen} = useAppSelector((state) => state.generalModals.restrictUserModal)

	const {t} = useTranslation('', { keyPrefix: 'general.modals.restrictModal' })

	const closeModal = () => {
		dispatch(setRestrictModalOpen(false))
	}
	
	return (
		<div className='restrict-modal'>
			<Modal
				closeModal={closeModal}
				open={modalIsOpen}
			>
				<div className='restrict-modal-container'>
					<p>{t('info1')}</p>
					<div className='restrict-modal-icon'>
						{restrictModalFile}
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default RestrictFileSizeModal