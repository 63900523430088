//react
import { FC, useState } from 'react'

//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleCustomerDocumentUrl } from '../../../../../../utils/urls/sales/customer'

// type
import { document } from '../../../../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteCustomerDocumentModalIsOpen, setDeleteCustomerDocumentId } from '../../../../../../redux/sales/customer/modals'

//translation
import { useTranslation } from 'react-i18next'

type deleteCustomerDocumentConfirmationModalProps = {
	setDocumentsData: (value: document[]) => void
	documentsData: document[]
}

const DeleteCustomerDocumentConfirmationModal: FC<deleteCustomerDocumentConfirmationModalProps> = ({ documentsData, setDocumentsData }) => {

	const { deleteCustomerDocumentModal } = useAppSelector((state) => state.customerModal.customerDocuments)

	const [loading, setLoading] = useState<boolean>(false)

	const { t } = useTranslation('', { keyPrefix: 'sales.customer.customerDocuments.modals.deleteCustomerDocumentConfirmationModal' })

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteCustomerDocumentModalIsOpen(false))
		dispatch(setDeleteCustomerDocumentId(null))
	}

	const delDocument = (): void => {
		setLoading(true)
		authorizedRequest(singleCustomerDocumentUrl(deleteCustomerDocumentModal.documentId!), 'DELETE')
			.then((response) => {
				console.log(response)
				const updatedDocumentsData = documentsData.filter(document => document.id !== deleteCustomerDocumentModal.documentId)
				setDocumentsData(updatedDocumentsData)
				dispatch(setDeleteCustomerDocumentModalIsOpen(false))
				setLoading(false)
			})
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisDocument')} 
			buttons={[{ text: t('yes'), onClickHandler: delDocument }, { text: t('no'), onClickHandler: closeModal }]}
			isOpen={deleteCustomerDocumentModal.modalIsOpen} 
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteCustomerDocumentConfirmationModal