// styles
import './offering.scss'

// react
import { useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import OfferingDetails from '../../../components/sales/offering/offeringDetails/offeringDetails'
import OfferingInventoryItemList from '../../../components/sales/offering/offeringInventoryItemList/offeringInventoryItemList'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'


enum offeringSection {
	Details = 'Details',
	InventoryItems = 'InventoryItems'
}

const Offering = () => {
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [offeringName, setOfferingName] = useState('')
	const [activeOfferingSection, setActiveOfferingSection] = useState<offeringSection>(offeringSection.Details)
	const { t } = useTranslation('', { keyPrefix: 'sales.offering' })

	const titleUrls = [
		{
			url: '/ri-business/sales/offering-list',
			title: t('offerings')
		},
		{
			url: location.pathname,
			title: offeringName
		}
	]

	const infoPopup = {
		content: [{
			title: 'offering_info_title',
			description: 'offering_info_description',
			link: '/ri-manual/sales/single-offering'
		}],
		type: infoPopupTypes.INFO
	}

	const offeringTabButtons: button[] = [
		{
			active: activeOfferingSection === offeringSection.Details,
			text: t('details'),
			onClick: () => setActiveOfferingSection(offeringSection.Details),
		},
		{
			active: activeOfferingSection === offeringSection.InventoryItems,
			text: t('inventoryItems'),
			onClick: () => setActiveOfferingSection(offeringSection.InventoryItems),
		}
	]

	const renderTab = () => {
		switch (activeOfferingSection) {
		case offeringSection.Details:
			return <OfferingDetails setOfferingName={setOfferingName} />
		case offeringSection.InventoryItems:
			return <OfferingInventoryItemList setFilterBar={setFilterBar} />
		default:
			return null
		}

	}
	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={offeringTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="product-container">
				{renderTab()}
			</div>
		</Layout>
	)
}

export default Offering