import { dragState, dragAction, dragActionType } from './type'

export const initialState : dragState = {
	dropInfo: null,
	dragInfo: null,
	dragData: null,
	isDragging: false
} 

export const dragAndDropReducer = (state:dragState,action:dragAction): dragState => {

	const { type , payload } = action

	switch(type){
	case dragActionType.setDragInfo:
		return { ...state, ...payload }	
	case dragActionType.setDropInfo:
		return { ...state, ...payload }
	case dragActionType.setIsDragging:
		return { ...state, isDragging:payload }
	default:
		return state	
	}
}