//components
import Pagination from '../../../../components/general/pagination/pagination'
import Notification from '../../../../components/general/notification/notification'
import ColumnLayout from '../../../../components/general/columnLayout/columnLayout'
import Loading from '../../loading/loading'

//react
import { useState, useEffect } from 'react'

//types
import { filters, notification, notificationResponse } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { deleteUserNotificationUrl, getUserNotificationsUrl, markUserNotificationAsReadUrl } from '../../../../utils/old_urls/profileUrls'

//other
import { checkMark, deleteIcon } from '../../../../assets/general/generalIcons'
import { getNotificationType } from '../../../../assets/general/generalFunctions'

const UnreadNotifications = () => {
	const [unreadNotifications, setUnreadNotifications] = useState<notification[]>([])
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1)
			.then((result) => {
				if (result.length > 0) {
					setUnreadNotifications([...unreadNotifications, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}



	const loadData = async (page: number) => {
		try {
			setLoading(true)
			const { result } = await authorizedRequest(getUserNotificationsUrl(false) + `&page=${page}&per_page=${10}`, 'GET')
			if (result.length > 0) {
				const readNotifications: notification[] = result.map((notification: notificationResponse) => {
					return {
						date: new Date(notification.date * 1000),
						description: notification.description,
						id: notification.id,
						isRead: notification.is_read,
						title: notification.title,
						type: getNotificationType(notification.type),
						userId: notification.user_id,
						avatar: notification.avatar,
						url: notification.url
					}
				})
				return readNotifications
			}
			return []
		} finally {
			setLoading(false)
		}
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {
					if (result.length > 0) {
						setUnreadNotifications([...unreadNotifications, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	const markUserNotification = (id: number) => {
		authorizedRequest(markUserNotificationAsReadUrl(id), 'PUT')
		const updatedUnreadNotifications = unreadNotifications.filter(notification => notification.id !== id)
		setUnreadNotifications(updatedUnreadNotifications)
	}

	const deleteUserNotification = (id: number) => {
		authorizedRequest(deleteUserNotificationUrl(id), 'DELETE')
		const updatedUnreadNotifications = unreadNotifications.filter(notification => notification.id !== id)
		setUnreadNotifications(updatedUnreadNotifications)
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
	}, [])

	return (
		<Pagination onLoadMore={handleLoadMore}>
			<ColumnLayout amount={1}>
				{unreadNotifications.map(notification =>
					<Notification
						key={`notification-${notification.id}`}
						title={notification.title}
						fullWidth={true}
						type={notification.type}
						description={notification.description}
						actions={[
							{ id: 1, icon: checkMark, onClick: () => markUserNotification(notification.id) },
							{ id: 2, icon: deleteIcon, onClick: () => deleteUserNotification(notification.id) }
						]}
						date={notification.date}
						avatar={notification.avatar}
						url={notification.url}
						id={notification.id}
						userId={notification.userId}
						isRead={notification.isRead}
					/>
				)}
				{
					loading && unreadNotifications.length < 1 && <Loading style={{ top: '40vh', left: '50vw' }} />
				}
			</ColumnLayout>
		</Pagination>
	)
}

export default UnreadNotifications