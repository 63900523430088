// Main Library
import i18n from 'i18next'

// Middleware
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Translation Data
import translationEN from './en/translation.json'
import translationUA from './ua/translation.json'
import translationRU from './ru/translation.json'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug:true,
		resources:{
			// English
			en:{
				translation:translationEN
			},
			// Ukrainian
			ua:{
				translation:translationUA
			},
			// Russian
			ru:{
				translation:translationRU
			}
		},
		fallbackLng:'en',
		interpolation:{
			escapeValue:true
		}
	})

export default i18n