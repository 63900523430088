// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileExportModal from '../../fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { getBillsUrl } from '../../../../../utils/old_urls/finance/billListUrls'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportBillFileModalIsOpen } from '../../../../../redux/general/modals'

const ExportBillFileModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.exportBillFileModal)
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { relationshipId } = useParams()

	const companyId: number = userCompanyData?.companyId || -1
	
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setExportBillFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		let url = ''

		if(relationshipId){
			url = getBillsUrl(companyId) + `?relationship_id=${relationshipId}&export=True&export_type=${exportType}`
		}else{
			url = getBillsUrl(companyId) + `?export=True&export_type=${exportType}`
		}

		const { result } = await authorizedRequest(url, 'GET')
		
		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}	
			exportFileTypes={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportBillFileModal