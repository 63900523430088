// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

// components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

// types
import { pipeline } from '../../../../../types/projects/pipelineTypes'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import {pipelinesModifyPipelineResourceUrl} from '../../../../../utils/urls/projects/pipelines'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setDeletePipelineId, setDeletePipelineModalIsOpen } from '../../../../../redux/projects/pipelineList/modals'

//types
import { detailedErrorObject } from '../../../../../types/general/generalTypes'

//translate
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

type deletePipelineModalProps = {
	pipelines: pipeline<Date | undefined>[]
	setPipelines: Dispatch<SetStateAction<pipeline<Date | undefined>[]>>
}

const DeletePipelineConfirmationModal: FC<deletePipelineModalProps> = ({pipelines, setPipelines}) => {
	const { t } = useTranslation('', {keyPrefix: 'projects.pipelineList.modals.deletePipelineConfirmationModal'})  
	
	const {modalIsOpen, pipelineId} = useAppSelector(state=> state.pipelineListModals.deletePipeline)
	const dispatch = useAppDispatch()
	
	const [buttons, setBusttons] = useState<{
		text: string
		onClickHandler: () => void
			}[]>([])
	const [description, setDescription] = useState<string>()
	const [errorObjects, setErrorObjects] = useState<detailedErrorObject[]>()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if(modalIsOpen){
			setErrorObjects(undefined)
			setDescription(undefined)
			setBusttons([])
			setLoading(true)
			authorizedRequest(pipelinesModifyPipelineResourceUrl(Number(pipelineId)), 'PATCH')
				.then((response) => {
					const { result } = response

					const { description, objects } = result
				
					setDescription(t(description))

					if(objects.length > 0){
						setErrorObjects(objects.map(formatDetailedErrorObjectResponse))

					}else{
						const buttons = [{text: t('yes'), onClickHandler:handleDeletePipeline}, {text: t('no'), onClickHandler: closeModal}]
						setBusttons([...buttons])
					}
					setLoading(false)
				})
		}
	}, [modalIsOpen])

	const closeModal = ()=>{
		dispatch(setDeletePipelineId(null))
		dispatch(setDeletePipelineModalIsOpen(false))
	}

	const handleDeletePipeline = async ()=> {
		try {
			await authorizedRequest(pipelinesModifyPipelineResourceUrl(Number(pipelineId)), 'DELETE')
			
			const updatedPipelines = pipelines.filter(pipelineItem => pipelineItem.id !== pipelineId)
			setPipelines(updatedPipelines)
			closeModal()
			
		} catch(err) {
			console.log(err)
		}
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisPipeline')}
			closeModal={closeModal} 
			isOpen={modalIsOpen}
			buttons={buttons}
			description={description}
			errorObjects={errorObjects}
			loading={loading}
		/>
	)
}

export default DeletePipelineConfirmationModal