// styles and icons
import './attachedFilesPreview.scss'
import { close } from '../../../../assets/general/generalIcons'

// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

// components
import FileItem from '../../fileItem/fileItem'

// types
import { file } from '../../../../types/general/generalTypes'

//other
import { formatFiles } from '../../../../assets/general/generalFunctions'

type attachedFilesPreviewProps = {
	attachedFiles: file[]
	setAttachedFiles: Dispatch<SetStateAction<file[]>>
}

const AttachedFilesPreview: FC<attachedFilesPreviewProps> = ({attachedFiles, setAttachedFiles}) => {
	const [ style, setStyle ] = useState<{
		height: string,
		padding: string
	}>({
		height: '0',
		padding: '0'
	})

	useEffect(() => {

		let height = 0
		let padding = '0'

		if(attachedFiles.length > 0){
			height = 11.9
			padding = '0.78vw 1.17vw'
		}

		setStyle({
			height: `${height}vw`,
			padding: padding
		})

	}, [attachedFiles])

	const clearAttachedFiles = () => {
		setAttachedFiles([])
	}

	const removeFile = (index: number)=> {
		const files = attachedFiles.filter((_,idx)=> {
			return index !==idx
		})
		setAttachedFiles(files)
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		
		const fileList = e.dataTransfer.files
		const files: File[] = []


		for(let i=0; i<fileList.length; i++){
			files.push(fileList[i])
		}

		formatFiles(files)
			.then(formattedFiles => {
				setAttachedFiles([...attachedFiles, ...formattedFiles])
			})
	}

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}


	return (
		<div className={'attached-files-preview'} style={style}>
			<div
				className="attached-files-preview-container"
				onDrop={(e) => handleDrop(e)}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
			>
				<div className='attached-files-preview-container-files'>
					{

						attachedFiles?.map((file: file, index: number) => {
							return (
								<div className="file-item-wrapper" draggable={false} key={`attached-file-${index+1}`}>
									<div className="file-item-close-button" onClick={()=> {removeFile(index)}} >
										{close}
									</div>
									<FileItem file={file} />
								</div>
							)
						})
					}
				</div>
				<div className="attached-files-preview-close-icon" onClick={clearAttachedFiles}>{close}</div>
			</div>
		</div>
	)
}

export default AttachedFilesPreview