//styles and icons
import './chatInputAudioMessage.scss'

//react
import { FC } from 'react'

//components
import ChatAudioPreview from './chatAudioPreview/chatAudioPreview'
import ChatAudioRecorder from './chatAudioRecorder/chatAudioRecorder'

type chatInputAudioMessageProps = {
	handleSubmit: () => void,
	setIsRecordingAudio: React.Dispatch<React.SetStateAction<boolean>>,
	audioBlob: Blob | null
	setAudioBlob: React.Dispatch<React.SetStateAction<Blob | null>>
}

const ChatInputAudioMessage: FC<chatInputAudioMessageProps> = ({audioBlob, setAudioBlob, setIsRecordingAudio, handleSubmit}) => {
	return (
		<div className='audio-container'>
			{
				!audioBlob ? (
					<ChatAudioRecorder setAudioBlob={setAudioBlob} />
          
				) : (
					<ChatAudioPreview handleSubmit={handleSubmit} setIsRecordingAudio={setIsRecordingAudio} audioBlob={audioBlob} setAudioBlob={setAudioBlob}/>
				)
			}
		</div>
	)

}

export default ChatInputAudioMessage
