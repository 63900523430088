//styles
import './filterBar.scss'
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Button from '../../button/button'
import InputField from '../../inputField/inputField'
import FilterBarSlider from './filterBarSlider/filterBarSlider'

//react
import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

//types
import { filters, filterBar, filterTypes, dropdownOption } from '../../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type filterBarProps = filterBar

export type selectedDropdown = {
	key: string
	selected: dropdownOption
}

const FilterBar: FC<filterBarProps> = ({ importButton, exportButton, filters, addButton, onSearch, notTranslated }) => {
	const [searchRequest, setSearchRequest] = useState('')
	const [previousSearch, setPreviousSearch] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [visibleFilters, setVisibleFilters] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState<selectedDropdown[]>([])
	const [touched, setTouched] = useState<boolean>(false)
	const { t } = useTranslation('', { keyPrefix: 'general.layout.filterBar' })

	useEffect(() => {
		if (filters) {
			setActiveFilters([...filters])
		}
	}, [filters])

	const handleFilters = (filters: filters[]) => {
		setActiveFilters([...filters])
		setTouched(true)
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (touched || previousSearch !== searchRequest) {
			timeout = setTimeout(() => {
				onSearch && onSearch(searchRequest, activeFilters)
				setPreviousSearch(searchRequest)
				setTouched(false)
			}, 500)
		}
		return () => {
			clearTimeout(timeout)
		}
	}, [activeFilters, searchRequest])

	const clearFilter = () => {
		setSearchRequest('')
		if (filters) {
			filters.forEach((filter) => {
				if (filter.filter.type === filterTypes.DROPDOWN) {
					filter.filter.selectedOption = undefined
				} else if (filter.filter.type === filterTypes.RANGE) {
					filter.filter.selectedOption = filter.filter.data
				} else {
					filter.filter.selectedOption = {
						startDate: undefined,
						endDate: undefined
					}
				}
			})
			setActiveFilters([...filters])
		}
	}

	return (
		<div className='filter-bar-container'>
			<div className="filter-bar-buttons">
				<div className="filter-bar-buttons-container">
					{
						importButton ?
							<div className="filter-bar-data-import">
								<Button active={true} icon={arrowIcon} onClick={importButton}/>
							</div>
							: null
					}

					{
						exportButton ?
							<div className="filter-bar-data-export">
								<Button active={true} icon={arrowIcon} onClick={exportButton}/>
							</div>
							: null
					}
				</div>
				{filters && <div className="filter-bar-buttons-container">
					<div className={`filter-bar-show-button ${visibleFilters ? 'open' : ''}`} onClick={() => {
						// console.log(visibleFilters)
						setVisibleFilters(!visibleFilters)
					}}>
						{arrowIcon}
					</div>
				</div>}
				<div className="filter-bar-buttons-container">
					{filters && <Button active={false} onClick={clearFilter} text={t('clearFilter')} />}
					{addButton ? <Button active={true} onClick={addButton.onClick} text={t('create')} /> : null}
				</div>
			</div>
			{onSearch && <InputField onSearch={() => { !filters && onSearch && onSearch(searchRequest, []) }} name='Search' type='text' placeholder={t('search')} value={searchRequest} onChange={(e) => setSearchRequest(e.target.value)} />}
			<FilterBarSlider setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} visible={visibleFilters} setVisible={(value) => { setVisibleFilters(value) }} filters={filters || []} activeFilters={activeFilters} setActiveFilters={handleFilters} notTranslated={notTranslated} />
		</div>
	)
}

export default FilterBar