import { baseUrl } from '../../network'

export const singleTaskUrl = (taskId: number)=> baseUrl + `/api/task/${taskId}`
export const createTaskUrl = baseUrl + '/api/task/create'

export const singleTaskStages = (taskId: number)=> baseUrl + `/api/task/${taskId}/stages`
export const singleTaskSubtasks = (taskId: number)=> baseUrl + `/api/task/${taskId}/subtasks`
export const singleTaskSubtasksCalendar = (taskId: number)=> baseUrl + `/api/task/${taskId}/subtasks/calendar`

export const singleTaskCostUrl = (taskId: number)=> baseUrl + `/api/task/${taskId}/cost`
export const singleTaskRevenueUrl = (taskId: number)=> baseUrl + `/api/task/${taskId}/revenue`