//styles
import './billListItem.scss'

//icons
import { forecastIcon } from '../../../assets/projects/pipeline/pipelineIcons'

//components
import ListItemCard from '../../general/listItemCard/listItemCard'
import StatusLabel from '../../general/statusLabel/statusLabel'
import BillDate from './billDate/billDate'

//react
import { FC } from 'react'

// translation
import { useTranslation } from 'react-i18next'

// redux
import { useAppDispatch } from '../../../customHooks/redux'
import {
	setDeleteBillId,
	setDeleteBillModalIsOpen,
	setPayBillId,
	setPayBillModalIsOpen
} from '../../../redux/general/modals'

//types
import { bill, billStatus } from '../../../types/finance/general'

//network
import { currencyToFormattedString, formatDate, getDeadlineData } from '../../../assets/general/generalFunctions'
import Avatar from '../avatar/avatar'


type billListItemProps = bill & {
	deleteAccess: boolean
}

const BillListItem: FC<billListItemProps> = ({id, title, description, amount, status, currency, endDate, category, deleteAccess, account, employee, errors}) => {

	const { t } = useTranslation('', { keyPrefix: 'general.billListItem' })
	const tExpense = useTranslation('', { keyPrefix: 'general.expenseCategory' }).t

	const { daysLeft, isDue } = getDeadlineData(endDate)

	const dispatch = useAppDispatch()

	const handleDelete = (id: number) => {
		dispatch(setDeleteBillModalIsOpen(true))
		dispatch(setDeleteBillId(id))
	}

	const handlePay = (id: number) => {
		dispatch(setPayBillModalIsOpen(true))
		dispatch(setPayBillId(id))
	}
	

	return (
		<ListItemCard
			title={title}
			description={description}
			data={[
				{
					icon: forecastIcon,
					title: t('total'),
					value: currencyToFormattedString(amount, currency)
				},
				{
					title: t('status'),
					value: <StatusLabel color={status === billStatus.paid ? 'var(--colorSuccessText)' : 'var(--accentPrimary)'} bg={status === billStatus.paid ? 'var(--colorSuccess)' : 'var(--accentTertiary)'} text={t(status)} />
				},
				{
					title: t('dueDate'),
					value: <BillDate isDue={isDue} daysLeft={daysLeft} date={endDate ? formatDate(endDate, true, true) : t('na')} />
				},
				{
					title: t('employee'),
					value:  employee ? <div className='bill-employee'><Avatar blobAvatar={employee.avatar}/> <p>{employee.name}</p></div> : t('na')
				},
				{
					title: t('account'),
					value: account ? account.accountNumber : t('na')
				},
				{
					title: t('category'),
					value: tExpense(category)
				}
			]}
			errors={errors}
			footer={{
				viewLink: id > 0 ? {
					text: t('view'),
					url: `/ri-business/finance/bill/${id}`
				} : undefined,
				deleteFunction: deleteAccess && id > 0 ? () => { handleDelete(id) } : undefined,
				payFunction: status === billStatus.pending && id > 0 ? () => { handlePay(id) } : undefined
			}}
		/>
	)
}

export default BillListItem
