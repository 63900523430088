// react
import { FC } from 'react'

// components
import ConfirmationModal from '../../../../../../general/modals/confirmationModal/confirmationModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../../customHooks/redux'
import { setDeleteInventoryItemImageId, setDeleteInventoryItemImageModalIsOpen } from '../../../../../../../redux/warehouse/general/modals'

// translation
import { useTranslation } from 'react-i18next'

//types
import { document } from '../../../../../../../types/general/generalTypes'

type deleteInventoryItemImageModalProps = {
	images: document[]
	setImages: (value: document[]) => void
}
const DeleteInventoryItemImageModal: FC<deleteInventoryItemImageModalProps> = ({ images, setImages }) => {
	const {modalIsOpen, imageId} = useAppSelector(state => state.warehouseGeneralModal.deleteInventoryItemImageModal)
	const dispatch = useAppDispatch()
	const {t} = useTranslation('', {keyPrefix: 'warehouse.inventoryItem.inventoryItemDetails.modals.deleteInventoryItemImageModal'})

	const closeModal = () => {
		dispatch(setDeleteInventoryItemImageModalIsOpen(false))
		dispatch(setDeleteInventoryItemImageId(null))
	}
	const deleteImage = () => {
		const updatedImages = images.filter((_, idx)=> idx !== imageId)
		setImages(updatedImages)
		
		closeModal()
	}
	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteImage }, { text: t('no'), onClickHandler: closeModal } ]}
		/>
	)
}

export default DeleteInventoryItemImageModal