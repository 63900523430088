// style
import './shipmentList.scss'

// react
import { FC, useEffect, useState } from 'react'

// component
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import ShipmentCard from '../../../components/general/shipmentCard/shipmentCard'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import ImportShipmentFileSelectorModal from '../../../components/warehouse/general/modals/shipmentModals/importShipmentFileSelectorModal/importShipmentFileSelectorModal'
import ExportShipmentFileModal from '../../../components/warehouse/general/modals/shipmentModals/exportShipmentFileModal/exportShipmentFileModal'
import ShipmentTablePreviewModal from '../../../components/warehouse/general/modals/shipmentModals/shipmentTablePreviewModal/shipmentTablePreviewModal'
import CreateTaskModal from '../../../components/general/modals/task/createTaskModal/createTaskModal'
import OrderItemSkeleton from '../../../components/general/skeletons/orderItem/orderItemSkeleton'
import CheckBox from '../../../components/general/checkBox/checkBox'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateTaskModalIsOpen } from '../../../redux/general/modals'

// types
import { filterBar, filters, infoPopupTypes, shipment, shipmentResponse } from '../../../types/general/generalTypes'

// network
import { companyWarehouseActionsFiltersUrl, companyWarehouseActionsUrl } from '../../../utils/urls/warehouses/warehouseActions/warehouseActions'
import { authorizedRequest } from '../../../utils/queries'

// other
import { createFilteringRequest, formatFilters, formatShipmentResponse } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const ShipmentList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.shipmentList' })

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()
	const [shipmentList, setShipmentList] = useState<shipment[]>([])
	const [filteredShipmentList, setFilteredShipmentList] = useState<shipment[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [showClosed, setShowClosed] = useState(true)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/warehouse/shipments',
			title: t('shipments')
		}
	]

	const infoPopup = {
		content: [{
			title: 'shipment_list_info_title',
			description: 'shipment_list_info_description',
			link: '/ri-manual/warehouses'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyWarehouseActionsFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				filters: formatedFilters,
				addButton: createAccess && {
					text: 'Add Shipment',
					active: true,
					onClick: handleCreateShipment
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const handleCreateShipment = () => {
		dispatch(setCreateTaskModalIsOpen(true))
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		setLoading(true)
		const filteringRequest = createFilteringRequest(filters)
		return authorizedRequest(companyWarehouseActionsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}&type=shipment` + filteringRequest, 'GET')
			.then((response) => {
				const { result }: { result: shipmentResponse[] } = response
				const shipmentData: shipment[] = result.map(formatShipmentResponse)
				setLoading(false)

				return shipmentData					
			})
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setShipmentList([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setShipmentList([...shipmentList, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
	}, [])
	
	useEffect(() => {
		setFilteredShipmentList(shipmentList.filter((shipment) => showClosed ? shipment : !shipment.isClosed))
	}, [shipmentList, showClosed])

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="shipment-list">
				<CheckBox
					isChecked={showClosed}
					onClick={() => {
						setShowClosed(!showClosed)
					}} 
					label={t('showClosed')}
				/>

				<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
					<ColumnLayout>
						{filteredShipmentList.map((shipment) => {
							return <ShipmentCard {...shipment} key={'shipment' + shipment.id} />
						})}
						{loading ? <OrderItemSkeleton /> : null}
					</ColumnLayout>
				</Pagination>
			</div>
			<CreateTaskModal shipments={shipmentList} setShipments={setShipmentList} isWarehouseSectionShow={true} />
			<ImportShipmentFileSelectorModal />
			<ExportShipmentFileModal />
			<ShipmentTablePreviewModal />
		</Layout>
	)
}

export default ShipmentList

