//styles
import './myTasksCalendarMonth.scss'

//react
import { FC, useEffect, useState } from 'react'

//types
import { task } from '../../../../../types/general/generalTypes'

//other
import { hexToRgb } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type myTasksCalendarMonthProps = {
	date: Date
	tasks: task<Date | undefined>[]
}

type calendarItemType = {
	date: number
	tasks: task<Date>[] | null
}

const daysInMonth = (month: number, year: number): number => {
	return new Date(year, month, 0).getDate()
}

const MyTasksCalendarMonth: FC<myTasksCalendarMonthProps> = ({ date, tasks }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.calendar' })

	const [calendarItems, setCalendarItems] = useState<calendarItemType[]>([])

	useEffect(() => {
		const amountOfDays = daysInMonth(date.getMonth() + 1, date.getFullYear())

		for (let i = 1; i <= amountOfDays; i++) {
			calendarItems.push({
				date: i,
				tasks: null
			})
		}

		setCalendarItems([...calendarItems])

	}, [])

	const renderCalendarDays = () => {
		const daysInMonth = new Date(
			date.getFullYear(),
			date.getMonth() + 1,
			0
		).getDate()

		const firstDayOfMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			1
		).getDay()

		const lastDayOfMonth = new Date(
			date.getFullYear(),
			date.getMonth() + 1,
			0
		).getDay()

		const daysInPrevMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			0
		).getDate()

		// Calculate the number of days to display from the previous month in the first row
		const daysInPrevMonthToDisplay = firstDayOfMonth
		console.log(firstDayOfMonth)

		// Calculate the number of days to display from the next month in the last row
		const daysInNextMonthToDisplay = 6 - lastDayOfMonth

		// Calculate the total number of days in the first and last rows combined
		const totalDaysInFirstAndLastRows =
			daysInPrevMonthToDisplay + daysInNextMonthToDisplay

		const allDays = Array.from({ length: daysInMonth + totalDaysInFirstAndLastRows }, (_, index) => {
			let day
			let newDate
			let isToday = false
			let isCurrentMonth = false

			if (index < daysInPrevMonthToDisplay) {
				// Dates from the previous month
				day = daysInPrevMonth - (daysInPrevMonthToDisplay - index) + 1
				newDate = new Date(
					date.getFullYear(),
					date.getMonth() - 1,
					day
				)
			} else if (
				index >= daysInPrevMonthToDisplay &&
				index < daysInPrevMonthToDisplay + daysInMonth
			) {
				// Dates from the current month
				day = index - daysInPrevMonthToDisplay + 1
				newDate = new Date(date.getFullYear(), date.getMonth(), day)
				isToday = newDate.toDateString() === new Date().toDateString()
				isCurrentMonth = true
			} else {
				// Dates from the next month
				day = index - (daysInPrevMonthToDisplay + daysInMonth) + 1
				newDate = new Date(
					date.getFullYear(),
					date.getMonth() + 1,
					day
				)
			}
			return { day, isToday, newDate, isCurrentMonth }
		})

		return (
			<div className="my-tasks-calendar-month-cell-container">
				{allDays.map(({ day, isToday, newDate, isCurrentMonth }) => {
					const extraTasks = 0

					return (
						<div
							className={`my-tasks-calendar-month-cell ${isToday ? 'today' : ''} ${!isCurrentMonth ? 'other-month' : ''}`}
						>
							<p className='my-tasks-calendar-month-cell-day'>
								{
									day > 0 && day <= daysInMonth + totalDaysInFirstAndLastRows
										? day
										: ''
								}
							</p>
							<div className='my-tasks-calendar-month-cell-task-container' style={{ display: 'grid', gridTemplateRows: `repeat(${tasks.length},1fr)`, overflow: 'hidden' }}>
								{
									tasks.map((task, index) => {
										if (task.startDate && task.endDate) {
											if (newDate >= task.startDate && newDate <= task.endDate) {
												const rgb = hexToRgb(task.status.color)

												const style = {
													gridRow: index + 1,
													background: `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.2)`
												}
												if (newDate.toDateString() === task.startDate.toDateString()) {
													return (
														<div className="my-tasks-calendar-month-cell-task start" style={style}>
															<div className="task-circle" style={{
																background: `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.2)`
															}} ></div>
															<p className="task-title" style={{
																color: task.status.color
															}}>{task.title}</p>
														</div>
													)
												} else if (newDate.toDateString() === task.endDate.toDateString()) {
													return (
														<div className="my-tasks-calendar-month-cell-task end" style={style}></div>
													)
												} else {
													return (
														<div className="my-tasks-calendar-month-cell-task" style={style}></div>
													)
												}

											}
										}
									})
								}
							</div>
							{
								extraTasks > 0 ? <div className="my-tasks-calendar-month-cell-extra-tasks">{extraTasks} more tasks</div> : null
							}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div className="my-tasks-calendar-month-container">
			<div className="my-tasks-calendar-month-header">
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('su')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('mo')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('tu')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('we')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('th')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('fr')}</p>
				</div>
				<div className="my-tasks-calendar-month-header-item">
					<p>{t('sa')}</p>
				</div>
			</div>
			{
				renderCalendarDays()
			}
		</div>
	)
}

export default MyTasksCalendarMonth