//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { companySkillInDemandUrl } from '../../../../utils/urls/employees/employeeStat'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const SkillsAnalysis = () => {
	const { t } = useTranslation('', { keyPrefix: 'employees.statistics.skills' })
	const tSkill = useTranslation('', { keyPrefix: 'general.skills' }).t

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadSkillsInDemand = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(companySkillInDemandUrl(userCompanyData?.companyId || -1), startDate, endDate, duration, tSkill)

	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('skillsInDemand')} loadData={loadSkillsInDemand} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default SkillsAnalysis