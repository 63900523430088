import { budget, budgetResponse } from '../../types/finance/budgetTypes'
import { taskResponse, task, taskTreeResponse, taskTree, status, projectResponse, project, warehouseActionResponse, warehouseAction, stageResponse } from '../../types/general/generalTypes'
import { statusColor, dashboardCard, dragLocation, dragStage } from '../../types/general/generalTypes'
import { formatErrorResponse, formatInventoryItemResponse, formatLocationResponse } from '../general/generalFunctions'

// Function to move item from array to array to be used for drag and drop
export const moveItem = <dragItemType>(
	dragInfo: dragLocation,
	dropInfo: dragLocation,
	stagesList: dragStage<dragItemType>[],
	moveFunction?: (cardData: dragItemType, newStageId: number) => void,
	modifierFunction?:(data: dragItemType, stageId?: number) => dragItemType
) => {
	
	const {stageId: currentStage, index: currentIndex} = dragInfo
	const {stageId: targetStage, index: targetIndex} = dropInfo
	
	const modifiedItemsList = [...stagesList]

	// Don't do anything if current and target is the same
	if(currentStage === targetStage && currentIndex === targetIndex) return modifiedItemsList
	
	// Get current section
	const prevSectionIndex = stagesList.findIndex(section => section.stageId === currentStage)
	const newSectionIndex = stagesList.findIndex(section => section.stageId === targetStage)
	
	let prevItem = {...modifiedItemsList[prevSectionIndex].items[currentIndex]}
	
	// Remove the old item
	modifiedItemsList[prevSectionIndex].items = modifiedItemsList[prevSectionIndex].items.filter((data:dragItemType,index:number) => index !== currentIndex)
	
	if(moveFunction){
		moveFunction(prevItem, modifiedItemsList[newSectionIndex].stageId)
	}

	// Modify the data 
	if(modifierFunction){
		prevItem = modifierFunction(prevItem, modifiedItemsList[newSectionIndex].stageId)
	}

	// Place the new item in the position
	const modifiedNewSectionItems = [...modifiedItemsList[newSectionIndex].items]
	modifiedNewSectionItems.splice(targetIndex+1,0,{...prevItem})
	
	modifiedItemsList[newSectionIndex].items = modifiedNewSectionItems

	return modifiedItemsList
}

// Format dragItemType Stage to Dashboard Card to be used for Dashboard components
export const formatToDashboardCard = <dragItemType>(stages:dragStage<dragItemType>[], modifierFunction:(data:dragItemType) => dashboardCard) => {
	return stages.map((stage)=>{
		return {
			...stage,
			items:stage.items.map((data: dragItemType)=>{
				return modifierFunction(data)
			})
		}
	})
}

export const formatWarehouseActionResponse = (response: warehouseActionResponse): warehouseAction => {
	return {
		id: response.id,
		origin: response.origin ? formatLocationResponse(response.origin) : null,
		destination: response.destination ? formatLocationResponse(response.destination) : null,
		taskId: response.task_id,
		inventoryItems: response.inventory_items.map(formatInventoryItemResponse)
	}
}

export const formatStageResponse = (stageResponse: stageResponse): dragStage<task<Date | undefined>> => {
	return {
		stageId: stageResponse.id,
		stageName: stageResponse.title,
		color: stageResponse.color,
		items: [],
		quantity: stageResponse.quantity,
		page: 1,
		isLoading: false,
		lastPage: false,
		createAccess: stageResponse.create_access
	}
}

export const formatTaskResponse = (taskResponse: taskResponse): task<Date | undefined> => {
	return {
		id: taskResponse.id,
		title: taskResponse.title,
		executors: (taskResponse.task_executors) ? taskResponse.task_executors.map((executor) => {
			return {
				id: executor.id,
				name: executor.name,
				avatar: executor.avatar,
				employeeId: executor.employee_id
			}
		}): [],
		status: {
			color: Object.values(statusColor)[Object.keys(statusColor).map((status) => status.toLowerCase()).indexOf(taskResponse.status)],
			text: taskResponse.status
		},
		labels: taskResponse.task_labels.map((label) => {
			return {
				color: label.color,
				text: label.text,
				id: label.id
			}
		}),
		project: taskResponse.project,
		subtasks: (taskResponse.subtasks) ? taskResponse.subtasks.map(formatTaskResponse): [],
		description: taskResponse.description,
		startDate: taskResponse.date ? new Date(taskResponse.date * 1000) : undefined,
		endDate: taskResponse.due_date ? new Date(taskResponse.due_date * 1000) : undefined,
		isClosed: taskResponse.is_closed,
		errors: taskResponse.errors.map(formatErrorResponse),
		warehouseAction: taskResponse.warehouse_action ? formatWarehouseActionResponse(taskResponse.warehouse_action) : null,
		lastOpen: taskResponse.last_open,
		createAccess: taskResponse.create_access
	}
}

export const formatProjectResponse = (response: projectResponse): project => {
	return {
		id: response.project_id,
		title: response.title,
		counterparty: response.counterparty ? {
			name:  response.counterparty.name,
			avatar:  response.counterparty.avatar
		} : undefined,
		description: response.description,
		revenue: response.revenue.map((revenue) => {
			return {
				currency: revenue.currency,
				amount: revenue.amount
			}
		}),
		date: response.date ? new Date(response.date * 1000) : undefined,
		dueDate: response.due_date ? new Date(response.due_date * 1000) : undefined,
		activeTasks: response.active_tasks,
		budget: formatBudgetResponse(response.budget) ,
		errors: response.errors.map(formatErrorResponse),
		stageId: response.stage_id,
		isClosed: response.is_closed
	}
}

export const formatBudgetResponse = (response: budgetResponse): budget => {
	return {
		title: response.title,
		description: response.description,
		id: response.id,
		deleteAccess: response.delete_access,
		editAccess: response.edit_access,
		values: response.values,
		errors: response.errors.map(formatErrorResponse),
		isClosed: response.is_closed
	}
}

export const formatTaskTreeResponse = (taskTreeResponse: taskTreeResponse): taskTree<Date | undefined> => {
	return {
		id: taskTreeResponse.id,
		title: taskTreeResponse.title,
		status: {
			color: Object.values(statusColor)[Object.keys(statusColor).map((status) => status.toLowerCase()).indexOf(taskTreeResponse.status)],
			text: taskTreeResponse.status
		},
		subtasks: (taskTreeResponse.subtasks) ? taskTreeResponse.subtasks.map(formatTaskTreeResponse): [],
		description: taskTreeResponse.description,
		startDate: taskTreeResponse.date ? new Date(taskTreeResponse.date * 1000) : undefined,
		endDate: taskTreeResponse.due_date ? new Date(taskTreeResponse.due_date * 1000) : undefined,
		closed: taskTreeResponse.closed
	}
}

export const convertTo12HourFormat = (hour: number) => {
	if (hour === 0 || hour === 24) {
		return  '12am'
	} else if (hour === 12) {
		return '12pm' 
	} else if (hour > 12) {
		return `${hour - 12}pm`
	} else {
		return `${hour}am`
	}
}