//styles and icons
import './chatInput.scss'

//react
import { FC, useState, useEffect } from 'react'

//components
import ChatInputAudioMessage from './chatInputAudioMessage/chatInputAudioMessage'
import ChatInputMessage from './chatInputMessage/chatInputMessage'
import ChatInputVideoMessage from './chatInputVideoMessage/chatVideoBanner'
import ExtensionBanner from './extensionBanner/extensionBanner'

//context
import useChatDesktop from '../context/chatDesktopContext'

//types
import { messageType, messageTypes, messageStatus, messageActions } from '../../../../../types/employees/chatTypes'
import { file } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { chatMessageUrl, editMessageUrl } from '../../../../../utils/old_urls/employees/chatsUrls'

//other 
import { blobToBase64 } from '../../../../../assets/general/generalFunctions'
import { getMessageFiles } from '../../../../../assets/employees/chat/chatFunctions'

type sendingFileType = {
	fileName: string
	file: string
	fileType: string
}	

const ChatInput: FC = () => {

	const { chatInfo, messages, setMessages, currentChatAction, setCurrentChatAction, setAttachedFiles, attachFiles, videoRecording, chatInputValue, setChatInputValue, setScrollToTheLastMessage } = useChatDesktop()

	const [isRecordingAudio, setIsRecordingAudio] = useState(false)
	const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
	const userId = parseInt(localStorage.getItem('userId') || '-1')
	const [filesListData, setFilesListData] = useState<sendingFileType[]>([])

	const actionMessage = messages.messages.find((item) => item.messageId === currentChatAction.message?.messageId)

	const sendMessage = (filesList: sendingFileType[]) =>{

		const body: messageType = {
			messageId: -1,
			type: messageTypes.USER,
			userId: userId,
			date: new Date(),
			text: chatInputValue.replace(/^(\s*<br\s*\/?>)+|(\s*<br\s*\/?>)+$/gi, '').trim(),
			file: [],
			pinned: false,
			edited: false,
			forwarded: null,
			replied: null,
			status: messageStatus.SENDING
		}
		
		
		if(actionMessage){
			if (currentChatAction.action === messageActions.REPLY && currentChatAction.message) {
				body.replied = currentChatAction.message
				submitMessage(messageActions.REPLY, body, filesList)
			}
			else if (currentChatAction.action === messageActions.EDIT) {
				body.messageId = actionMessage.messageId
				body.edited = true
				body.text = chatInputValue
				submitMessage(messageActions.EDIT, body, filesList)
			}
		}else{
			submitMessage(messageActions.SEND, body, filesList)
		}

		setCurrentChatAction({ action: messageActions.SEND, message: null })

	}

	const submitMessage = (action: messageActions, message: messageType, files: sendingFileType[]) => {
		if(message.text != '' || files.length > 0 && chatInfo.data.chatId){

			if (action === messageActions.SEND || action === messageActions.REPLY) {
				
				setChatInputValue('')
				setAttachedFiles([])

				if(chatInfo.data.chatId){
					authorizedRequest(chatMessageUrl(chatInfo.data.chatId), 'POST', 'accessToken', {
						text: message.text,
						files: files,
						message_id: message.replied?.messageId || null
					}).then((response)=>{
						const { result } = response

						getMessageFiles(result.message_id)
							.then((files) => {
								message.messageId = result.message_id

								if(result.files){
									message.file = files
								}

								message.status = messageStatus.SENT
								
								setMessages([...messages.messages, message])
								setScrollToTheLastMessage(true)
							})
					})
				}
				
			}

			if (action === messageActions.EDIT) {


				const currentMessageIndex = messages.messages.findIndex((item) => item.messageId === message.messageId)
				const currentMessage = messages.messages[currentMessageIndex]

				if(currentMessage === undefined || userId !== currentMessage.userId) return

				currentMessage.edited = true

				messages.messages[currentMessageIndex] = currentMessage

				setMessages([...messages.messages])
				authorizedRequest(editMessageUrl(chatInfo.data.chatId || -1), 'PUT', 'accessToken',{
					message_id:currentMessage.messageId,
					text: currentMessage.text,
					files: filesListData,
				})

			}
		}

	}

	const handleSubmit = () => {

		const filesList: sendingFileType[] = []

		if(attachFiles.attachedFiles.length > 0){
			attachFiles.attachedFiles.forEach((file: file, index: number) => {
				blobToBase64(file.file)
					.then((stringBlob)=>{
						filesList.push({
							fileName: file.fileName,
							file: stringBlob,
							fileType: file.fileType
						})
						if(index == attachFiles.attachedFiles.length-1){
							setFilesListData(filesList)
						}
					})
			})
		}else if(audioBlob){
			blobToBase64(audioBlob)
				.then((stringBlob)=>{
					filesList.push({
						fileName: 'audio_message',
						file: stringBlob,
						fileType: audioBlob.type
					})
					setFilesListData(filesList)	
				})
		}else if(videoRecording.videoBlob){
			blobToBase64(videoRecording.videoBlob)
				.then((stringBlob)=>{
					if(videoRecording.videoBlob){
						filesList.push({
							fileName: 'video_message',
							file: stringBlob,
							fileType: videoRecording.videoBlob.type
						})
						setFilesListData(filesList)	
					}
				})
		}else{
			setFilesListData(filesList)	
		}
	}
	
	useEffect(()=>{
		sendMessage(filesListData)
	}, [filesListData])

	const sender = chatInfo.data.chatMembers.find((member) => member.userId === actionMessage?.userId)
	
	return (
		<form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className='chat-input-wrapper'>
			<ExtensionBanner
				text={actionMessage ? actionMessage.text : ''}
				sender={sender && sender.name ? sender.name : ''}
			/>

			{isRecordingAudio ? (
				<ChatInputAudioMessage handleSubmit={handleSubmit} audioBlob={audioBlob} setAudioBlob={setAudioBlob} setIsRecordingAudio={setIsRecordingAudio} />
			) : videoRecording.isVideoInitialized ? (
				<ChatInputVideoMessage handleSubmit={handleSubmit}/> 
			) : (
				<ChatInputMessage
					setIsRecordingAudio={setIsRecordingAudio}
					handleSubmit={handleSubmit} 
				/>
			)}
		</form>
	)
}

export default ChatInput