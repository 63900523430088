// styles
import './dashboard.scss'

// react
import { FC } from 'react'

// components
import DashboardStage from './dashboardStage/dashboardStage'
import DashboardActions from './dashboardActions/dashboardActions'

// context
import { DragAndDropHandlerProvider } from '../dragAndDropHandler/context/dragAndDropHandlerContext'

// types
import {
	dashboardAction,
	dashboardCard,
	dragLocation,
	dragStage,
} from '../../../types/general/generalTypes'

type dashboardProps = {
  stages: dragStage<dashboardCard>[]
  actions?: dashboardAction[]
  onMove?: (dragInfo: dragLocation, dropinfo: dragLocation) => void
  onCardClick?: (data: dashboardCard) => void
  onStageAdd?: (stageName: string, stageId: number) => void
  createAccess: boolean
  onLoadMore?: (stageId: number) => void
}

const Dashboard: FC<dashboardProps> = ({
	stages,
	onMove,
	actions,
	onCardClick,
	onStageAdd,
	createAccess,
	onLoadMore
}) => {
	return (
		<DragAndDropHandlerProvider>
			<div className="dashboard-container">
				{stages.map((stage) => {
					return (
						<DashboardStage
							stageName={stage.stageName}
							onLoadMore={onLoadMore}
							isLoading={stage.isLoading}
							stageId={stage.stageId}
							color={stage.color}
							cardList={stage.items}
							moveProject={onMove}
							onCardClick={onCardClick}
							key={stage.stageName}
							onStageAdd={onStageAdd}
							quantity={stage.quantity}
							createAccess={createAccess}
						/>
					)
				})}
			</div>
			{actions && <DashboardActions actions={actions} />}
		</DragAndDropHandlerProvider>
	)
}

export default Dashboard
