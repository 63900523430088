//styles and icons
import './filesContainer.scss'

//components
import FileItem from '../../../../../fileItem/fileItem'

//react
import { FC } from 'react'

// types
import { file } from '../../../../../../../types/general/generalTypes'


type filesContainerProps = {
	fileData: file[],
	messageId: number | undefined,
	onLoaded: () => void
}

const FilesContainer: FC<filesContainerProps> = ({ fileData, onLoaded }) => {
	return (
		<div className="chat-message-files-container">
			{
				fileData.map((file: file)=>{
					return (
						<FileItem file={file} onLoaded={onLoaded} />
					)
				})
			}
		</div>
	)
}

export default FilesContainer