//styles
import { multiplePipelinesIcon } from '../../../../assets/projects/pipeline/pipelineIcons'
import Loading from '../../loading/loading'

//components
import './overviewCardSkeleton.scss'

//translation
import { useTranslation } from 'react-i18next'

const OverviewCardSkeleton = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.listSummaryCard' })
	return (
		<div className='overview-skeleton-container'>
			<div className='overview-skeleton-title'>
				{multiplePipelinesIcon}
				<p>{t('summary')}</p>
			</div>
			<div className='overview-skeleton-content'>
				<Loading style={{ position: 'relative', top: '0', left: '0' }} />
			</div>
		</div>
	)
}

export default OverviewCardSkeleton