//styles
import './forwardMessages.scss'

//react
import { useState } from 'react'

//components
import Modal from '../../../../modals/modal/modal'
import InputField from '../../../../inputField/inputField'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { selectionOption } from '../../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { forwardMessageUrl, getChatListUrl } from '../../../../../../utils/old_urls/employees/chatsUrls'

//translation
import { useTranslation } from 'react-i18next'

const ForwardMessages = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.modals.forwardMessages' })

	const { forwardMessage, setForwardMessageId, setForwardModalIsOpen } = useChatDesktop() 
	
	const [selectedChats, setSelectedChats] = useState<selectionOption[]>([])
	const [searchRequest, setSearchRequest] = useState('')

	const getChats = (query: string, page: number) => {
		return authorizedRequest(getChatListUrl + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((respose) => {
				const { result } = respose

				const newChatList: selectionOption[] = []

				for(let i=0; i<result.length; i++){
					newChatList.push({
						avatar: result[i].avatar,
						text: result[i].name,
						id: result[i].chat_id
					})
				}

				return newChatList
			})
	}

	const closeModal = () => {
		setForwardModalIsOpen(false)
		setForwardMessageId(null)
	}

	const forwardMessageFunction = () => {
		if (forwardMessage.messageId && selectedChats.length > 0) {
			authorizedRequest(forwardMessageUrl(forwardMessage.messageId), 'POST', 'accessToken', {recipient_chat_ids: selectedChats.map((chat) => chat.id)})
		}
	}

	return (
		<Modal
			closeModal={closeModal}
			open={forwardMessage.modalIsOpen}
			title={t('title')}
			submitButton={{
				text: 'Send',
				onClick: forwardMessageFunction
			}}
		>
			<div className='forward-messages-container'>
				<InputField
					type='text'
					name='search'
					placeholder={t('search')}
					onChange={(e) => setSearchRequest(e.target.value)}
					value={searchRequest}
					selection={{
						selectedList: selectedChats,
						setSelectedList: setSelectedChats,
						loadSelectionOptions: getChats
					}}
					key='forward-message-searchbar'
				/>
			</div>
		</Modal>
	)
}

export default ForwardMessages