//styles
import './locationSelector.scss'

//components
import LocationSearchInput from '../locationSearchInput/locationSearchInput'
import Loading from '../loading/loading'

//react
import { useMemo, useRef, FC, useEffect, useCallback, useState } from 'react'

//other
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api'

type locationSelectorProps = {
	location: {lat: number, lng: number} | undefined
	setLocation: (value: {lat: number, lng: number} | undefined) => void
	setAddress: (value: string) => void
	error?: string
}

type Libraries = ('drawing' | 'geometry' | 'places' | 'visualization')[]
const libraries: Libraries = ['places']


const LocationSelector: FC<locationSelectorProps> = ({ location, setLocation, setAddress, error }) => {
	const mapRef = useRef<google.maps.Map>()

	const [ userLocationLoading, setUserLocationLoading ] = useState(false)

	const center = useMemo(() => ({ lat: 50.450001, lng:  30.523333 }), [])

	const options = useMemo(() => ({
		mapId: 'ce01c5ee78667d2',
		disableDefaultUI: true,
		clickableIcons: false
	}), [])

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'AIzaSyAbORCnXch9VguVCUOmL3b_yg800EStfNg',
		libraries: libraries
	})

	console.log(userLocationLoading)
	


	useEffect(() => {
		if(location){
			console.log(location, mapRef.current)
			mapRef.current?.panTo(location)
		}
	}, [location])

	const onLoad = useCallback((map: google.maps.Map) => {mapRef.current = map}, [])


	return (

		isLoaded ? 

			<div className="location-selector">
				<LocationSearchInput setLocation={setLocation} setLoading={setUserLocationLoading} setAddress={setAddress} error={error} />
				{
					!userLocationLoading ? 
						<GoogleMap
							zoom={9}
							center={center}
							mapContainerClassName='location-selector-map'
							options={options}
							onLoad={onLoad}
						>
							{ location &&  <Marker position={location} />}
						</GoogleMap>
						: <Loading/>
				}
				
			</div>
			: <Loading />
	)

}

export default LocationSelector