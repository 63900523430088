import { useState, useEffect } from 'react'

function useDebounce<t>(value:t, delay:number) {
	// State and setter for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		// Update debounced value after delay
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		// Cleanup function to clear timeout if the value changes before the delay
		return () => {
			clearTimeout(handler)
		}
	}, [value, delay]) // Only re-call effect if value or delay changes

	return debouncedValue
}

export default useDebounce