//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//react
import { FC, useEffect, useState } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { deleteAccountUrl } from '../../../../../utils/old_urls/finance/accountListUrl'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteAccountId, setDeleteAccountModalIsOpen } from '../../../../../redux/general/modals'

//types
import { account } from '../../../../../types/finance/accountTypes'
import { detailedErrorObject } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'


type deleteAccountModalProps = {
	setAccounts: (value: account[]) => void
	accounts: account[]
}

const DeleteAccountModal: FC<deleteAccountModalProps> = ({ accounts, setAccounts }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.accountList.modals.deleteAccountModal' })
	const { modalIsOpen, accountId } = useAppSelector((state) => state.accontListModal.deleteAccountModal)

	const [buttons, setBusttons] = useState<{
		text: string
		onClickHandler: () => void
			}[]>([])
	const [description, setDescription] = useState<string>()
	const [errorObjects, setErrorObjects] = useState<detailedErrorObject[]>()
	const [loading, setLoading] = useState(false)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setDeleteAccountModalIsOpen(false))
		dispatch(setDeleteAccountId(null))
	}

	useEffect(() => {
		if(modalIsOpen && accountId){

			setErrorObjects(undefined)
			setDescription(undefined)
			setBusttons([])
			authorizedRequest(deleteAccountUrl(accountId), 'PATCH')
				.then((response) => {
					const { result } = response

					const { description, objects } = result
					
					setDescription(t(description))

					if(objects.length > 0){
						setErrorObjects(objects.map(formatDetailedErrorObjectResponse))

					}else{
						const buttons = [{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]
						setBusttons([...buttons])
					}
				})
		}
	}, [modalIsOpen])

	const handleSubmit = () => {
		if (accountId) {
			setLoading(true)
			authorizedRequest(deleteAccountUrl(accountId), 'DELETE')
				.then((response) => {
					setAccounts([...accounts.filter((account) => account.id !== accountId)])

					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal 	
			title={t('deleteThisAccount')}
			buttons={buttons}
			isOpen={modalIsOpen}
			closeModal={closeModal}
			description={description}
			errorObjects={errorObjects}
			loading={loading}
		/>
	)
}

export default DeleteAccountModal