// react
import { Dispatch, FC, SetStateAction } from 'react'

// components
import FileSelectorModal from '../../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// types
import { document } from '../../../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../../customHooks/redux'
import { setImportInventoryItemImageSelectorModalIsOpen } from '../../../../../../../redux/warehouse/general/modals'

type importInventoryImageSelectorModalProps = {
	images: document[]
	setImages: Dispatch<SetStateAction<document[]>>
}
const ImportInventoryImageSelectorModal: FC<importInventoryImageSelectorModalProps> = ({images, setImages}) => {
	const { modalIsOpen } = useAppSelector(state => state.warehouseGeneralModal.importInventoryItemImageSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = ()=> {
		dispatch(setImportInventoryItemImageSelectorModalIsOpen(false))
	}
	
	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal} 
			setFiles={(files)=> {
				const addedImages: document[] = files.map((file,idx) => {
					return {
						fileName: `inventory-image-${images.length + idx}`,
						fileType: 'image/png',
						id: images.length + idx,
						file: file.file
					}
				})
				setImages([...images, ...addedImages])
				closeModal()
			}}
		/>
	)
}

export default ImportInventoryImageSelectorModal