// styles
import './orderDetails.scss'
import { productOffer } from '../../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../infoDetailsTable/infoDetailsTable'
import Button from '../../button/button'
import DeleteOrderModal from './modals/deleteOrderConfirmationModal/deleteOrderModal'
import CloseOrderModal from './modals/closeOrderModal/closeOrderModal'
import RefundOrderModal from './modals/refundOrderModal/refundOrderModal'
import Loading from '../../loading/loading'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteOrdernModalIsOpen, setDeleteOrderId, setCloseOrdernModalIsOpen, setCloseOrderId, setIsOrderClosed, setRefundOrderId, setRefundOrderModalIsOpen } from '../../../../redux/general/order/modals'

//translation
import { useTranslation } from 'react-i18next'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyOfferingsUrl, editOrderUrl, getOrderUrl, getWarehousesUrl } from '../../../../utils/old_urls/general/orderUrls'
import { companyCustomersUrl, singleCustomersAddressesUrl } from '../../../../utils/urls/sales/customer'
import { singleProviderOfferingsUrl } from '../../../../utils/urls/purchases/provider'

//types
import { infoDetailsTableDataItem, dropdownOption, orderResponse, orderTypes, warehouseResponse, customerResponse, offering, editableTableItem, error } from '../../../../types/general/generalTypes'

//other
import { formatDate, formatErrorResponse, formatOfferingResponse, getIdWithCurrency } from '../../../../assets/general/generalFunctions'

export type orderDetails = {
	id: number
	counterparty: dropdownOption
	startDate: Date | null
	endDate: Date | null
	destination: dropdownOption | undefined
	deleteAccess: boolean
	editAccess: boolean
	closeAccess: boolean
	type: orderTypes
	isClosed: boolean
}

type orderDetailsProps = {
	setProjectId: (value: number) => void
	setErrors: (value: error[]) => void
}

const OrderDetails: FC<orderDetailsProps> = ({ setProjectId, setErrors }) => {

	const { t } = useTranslation('', { keyPrefix: 'order.orderDetails' })

	const [infoDetailsTable, setInfoDetailsTable] = useState<infoDetailsTableDataItem[]>([])
	const [details, setDetails] = useState<orderDetails>()

	const [offerings, setOfferings] = useState<offering[]>([])
	const [selectedOfferings, setSelectedOfferings] = useState<offering[]>([])

	const [orderDetailsEdit, setOrderDetalsEdit] = useState<boolean>(false)

	const { userCompanyData, language } = useAppSelector((state) => state.general)

	const orderId = parseInt(useParams().orderId || '-1')
	const companyId: number = userCompanyData?.companyId || -1 // id of chosen company

	const dispatch = useAppDispatch()

	const loadWarehouseOptions = async () => {
		const { result } = await authorizedRequest(getWarehousesUrl(companyId), 'GET')
		return result.map((warehouse: warehouseResponse) => {
			return {
				title: warehouse.name,
				key: `${warehouse.id}`
			}
		})
	}

	const loadCustomerAddresses = async (search: string, page: number) => {
		if (details?.counterparty.key) {
			const { result } = await authorizedRequest(singleCustomersAddressesUrl(Number(details?.counterparty.key)) + `?needle=${search}&page=${page}`, 'GET')

			return result.map((customer: warehouseResponse) => {
				return {
					title: customer.address,
					key: `${customer.id}`
				}
			})
		}
	}

	const loadCustomers = async (search: string, page: number) => {
		const { result } = await authorizedRequest(companyCustomersUrl(companyId) + `?needle=${search}&page=${page}&per_page=10`, 'GET')


		return result.map((customer: customerResponse) => {
			return {
				title: customer.name,
				avatar: customer.avatar,
				key: `${customer.relationship_id}`
			}
		})
	}

	const updateSelectedItems = (selectedItems: editableTableItem[]) => {
		authorizedRequest(editOrderUrl(orderId), 'PUT', 'accessToken', {
			language,
			offerings: selectedItems.map((item) => {
				item = item as offering
				return {
					id: item.offeringId,
					name: item.name,
					original_price: item.price.amount,
					price: item.price.amount,
					currency: item.price.currency,
					quantity: item.quantity
				}
			})
		})
	}

	const loadCompanyOfferings = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(companyOfferingsUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if (result && result.length > 0) {
			const productsData: offering[] = result.map(formatOfferingResponse)


			return productsData.map(e => ({ ...e, placeholder: productOffer }))
		}
		return []
	}

	const loadProviderOfferings = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(singleProviderOfferingsUrl(Number(details?.counterparty.key)) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if (result && result.length > 0) {
			const productsData: offering[] = result.map(formatOfferingResponse)


			return productsData.map(e => ({ ...e, placeholder: productOffer }))
		}
		return []
	}


	useEffect(() => {
		if (details) {
			setInfoDetailsTable([
				{
					title: t('counterparty'),
					data: {
						dropdown: {
							loadOptions: details.type === orderTypes.sales ? loadCustomers : undefined,
							defaultSelectedOption: details.counterparty,
							dropdownOptions: [],
							onSelect: (value) => {
								setDetails({ ...details, counterparty: value })
							},
							disabled: true,
							placeholder: t('counterparty'),
							selectedOption: details.counterparty
						}
					}
				},
				{
					title: t('destination'),
					data: {
						dropdown: {
							placeholder: t('destination'),
							dropdownOptions: [],
							defaultSelectedOption: details.destination,
							loadOptions: details.type === orderTypes.purchase ? loadWarehouseOptions : loadCustomerAddresses,
							onSelect: (value) => {
								setDetails({ ...details, destination: value })
								setOrderDetalsEdit(true)
							},
							disabled: !details.editAccess,
							selectedOption: details.destination
						}
					}
				},
				{
					title: t('startDate'),
					data: {
						inputField: {
							value: details.startDate ? formatDate(details.startDate, false, false) : t('na'),
							disabled: true
						}
					}
				},
				{
					title: t('endDate'),
					data: {
						inputField: {
							value: details.endDate ? formatDate(details.endDate, false, false) : t('na'),
							disabled: true
						}
					}
				},
				{
					title: t('items'),
					fullWidth: true,
					data: {
						editableTable: {
							items: offerings,
							setItems: (value) => {
								setOfferings(value as offering[])
							},
							loadItems: details.type === orderTypes.sales ? loadCompanyOfferings : loadProviderOfferings,
							selected: {
								items: selectedOfferings,
								setItems: (value) => {
									updateSelectedItems(value)
									setSelectedOfferings(value as offering[])
								},
							},
							disabled: details.isClosed || !details.editAccess,
							updateAmount: !details.isClosed ? () => {} : undefined
						}
					}
				}
			])
		}
	}, [details, offerings, selectedOfferings])

	const loadData = async () => {
		const { result }: { result: orderResponse } = await authorizedRequest(getOrderUrl(Number(orderId)), 'GET')

		setProjectId(result.project_id)
		setErrors([...result.errors.map(formatErrorResponse)])

		dispatch(setIsOrderClosed(result.is_closed))
		setDetails({
			id: result.id,
			counterparty: {
				avatar: result.counterparty.avatar,
				title: result.counterparty.name,
				key: `${result.counterparty.relationship_id}`
			},
			startDate: result.start_date ? new Date(result.start_date * 1000) : null,
			endDate: result.end_date ? new Date(result.end_date * 1000) : null,
			destination: result.destination ? {
				title: result.destination.address,
				key: `${result.destination.id} ${result.destination.type}`
			} : undefined,
			type: result.type,
			editAccess: result.edit_access,
			deleteAccess: result.delete_access,
			closeAccess: result.close_access,
			isClosed: result.is_closed
		})

		setSelectedOfferings(result.items.map((item) => {
			return {
				...formatOfferingResponse(item),
				idWithCurrency: getIdWithCurrency(formatOfferingResponse(item)),
				placeholder: productOffer
			}
		}))
	}

	useEffect(() => {
		loadData()
	}, [])

	const editData = async () => {
		if (details && orderDetailsEdit) {
			let data

			if (details.type === orderTypes.sales) {
				data = {
					address_id: details.destination?.key,
					language
				}
			} else {
				data = {
					warehouse_id: details.destination?.key,
					language
				}
			}

			await authorizedRequest(getOrderUrl(Number(orderId)), 'PUT', 'accessToken', data)

			setOrderDetalsEdit(false)
		}
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (orderDetailsEdit) {
			timeout = setTimeout(() => {
				editData()
			}, 500)

		}
		return () => clearTimeout(timeout)
	}, [details])



	return (
		<div className="order-details">

			{
				details ?
					<>
						<InfoDetailsTable data={infoDetailsTable} />
						{
							details.deleteAccess || details.closeAccess ?
								<div className="order-details-actions">
									{
										details.deleteAccess ?
											<Button text={t('delete')} active={true} onClick={() => {
												dispatch(setDeleteOrdernModalIsOpen(true))
												dispatch(setDeleteOrderId(details.id))
											}} />
											: null
									}


									{
										details.closeAccess ?
											<Button text={details.isClosed ? t('activate') : t('close')} active={true} onClick={() => {
												dispatch(setCloseOrdernModalIsOpen(true))
												dispatch(setCloseOrderId(details.id))
											}} />
											: null
									}

									{
										details.isClosed ?
											<Button text={t('refund')} active={true} onClick={() => {
												dispatch(setRefundOrderModalIsOpen(true))
												dispatch(setRefundOrderId(details.id))
											}} />
											: null
									}
								</div>
								: null
						}
					</>
					: <Loading style={{ top: '40vh', left: '50vw' }} />
			}
			<DeleteOrderModal />
			<CloseOrderModal />
			{details && <RefundOrderModal 
				orderDetails={details} 
				selectedOfferings={selectedOfferings} 
				setSelectedOfferings={setSelectedOfferings}
			/>}
			
		</div>
	)

}

export default OrderDetails