// styles and icons
import './accountTransactionList.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Table from '../../../general/table/table'
import Pagination from '../../../general/pagination/pagination'
import TransactionTablePreviewModal from '../../general/modals/transaction/transactionTablePreviewModal/transactionTablePreviewModal'
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'
import ImportTransactionFileSelectorModal from '../../general/modals/transaction/importTransactionFileSelectorModal/importTransactionFileSelectorModal'
import ExportTransactionFileModal from '../../general/modals/transaction/exportTransactionFileModal/exportTransactionFileModal'
import CreateTransactionModal from '../../general/modals/transaction/createTransactionModal/createTransactionModal'
import DeleteTransactionConfirmationModal from '../../general/modals/transaction/deleteTransactionConfirmationModal/deleteTransactionConfirmationModal'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleAccountTransactionsUrl, singleAccountTransactionFiltersUrl, singleAccountTransactionOverviewUrl } from '../../../../utils/urls/finance/account/account'

// types
import { filterBar, filters, chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'
import { transaction, transactionResponse } from '../../../../types/finance/general'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateTransactionModalIsOpen, setDeleteTransactionId, setDeleteTransactionModalIsOpen, setExportTransactionFileModalIsOpen, setImportTransactionFileSelectorModalIsOpen } from '../../../../redux/finance/general/modals'

// other
import { formatFilters, createFilteringRequest, formatGraphResponse } from '../../../../assets/general/generalFunctions'
import { formatTransactionTableData } from '../../../../assets/finance/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type accountTransactionListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const AccountTransactionList: FC<accountTransactionListProps> = ({ setFilterBar }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.account.accountTransactionList' })
	const tTransactionType = useTranslation('', { keyPrefix: 'general.tansactionTypes' }).t

	const dispatch = useAppDispatch()

	const [accountTransactions, setAccountTransactions] = useState<transaction[]>([])
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)

	const { accountId } = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleAccountTransactionFiltersUrl(Number(accountId)), 'GET')

		const filterResult = result.filters
		const createAccess = result.create_access

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			// importButton: () => {
			// 	dispatch(setImportTransactionFileSelectorModalIsOpen(true))
			// },
			// exportButton: () => {
			// 	dispatch(setExportTransactionFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: createAccess && {
				text: 'Add',
				active: true,
				onClick: () => dispatch(setCreateTransactionModalIsOpen(true))
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(singleAccountTransactionsUrl(Number(accountId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

		if (result.length > 0) {
			const formatedTransactions: transaction[] = result.map((transaction: transactionResponse) => {
				return {
					id: transaction.id,
					accountId: transaction.account_id,
					amount: transaction.amount,
					currency: transaction.currency,
					transactionType: transaction.transaction_type,
					description: transaction.transaction_type,
					date: new Date(transaction.date * 1000),
					deleteAccess: false
				}
			})

			return formatedTransactions
		}

		return []
	}

	const loadOverview = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => {
		return formatGraphResponse(singleAccountTransactionOverviewUrl(Number(accountId)), startDate, endDate, duration, tTransactionType)
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(page, searchValue, filters)
			.then((result) => {
				setAccountTransactions([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setAccountTransactions([...accountTransactions, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	const handleDelete = (transactionId: number) => {
		dispatch(setDeleteTransactionId(transactionId))
		dispatch(setDeleteTransactionModalIsOpen(true))
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return (() => {
			setFilterBar(undefined)
		})
	}, [])

	return (
		<div>
			<div className='account-transaction-list'>
				<div className='account-transaction-list-chart'>
					<div className='chart-group'>
						<ChartContainer title='Turnover rate' loadData={loadOverview} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
					</div>
				</div>
				<div className='account-transaction-list-table'>
					<Pagination onLoadMore={handleLoadMore} loading={loading}>
						<Table
							columns={accountTransactions[0]?.deleteAccess ? [
								{ key: 'icon', title: '' },
								{ key: 'description', title: t('description') },
								{ key: 'amount', title: t('total') },
								{ key: 'date', title: t('date') },
								{ key: 'transaction_type', title: t('type') },
								{ key: 'delete', title: '' }
							] : [
								{ key: 'icon', title: '' },
								{ key: 'description', title: t('description') },
								{ key: 'amount', title: t('total') },
								{ key: 'date', title: t('date') },
								{ key: 'transaction_type', title: t('type') }
							]}
							data={formatTransactionTableData(accountTransactions, handleDelete)}
						/>
					</Pagination>
				</div>
			</div>
			<TransactionTablePreviewModal />
			<ImportTransactionFileSelectorModal />
			<ExportTransactionFileModal />
			<CreateTransactionModal setTransactions={setAccountTransactions} transactions={accountTransactions} />
			<DeleteTransactionConfirmationModal setTransactions={setAccountTransactions} transactions={accountTransactions} />
		</div>
	)
}

export default AccountTransactionList