import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type inventoryItemModalsState = {
	addInventoryItemProviderOfferingModal: {
		modalIsOpen: boolean
	}
	deleteInventoryItemProviderOfferingModal: {
		modalIsOpen: boolean
		offeringId: number | null
	}
}

const initialState: inventoryItemModalsState = {
	addInventoryItemProviderOfferingModal: {
		modalIsOpen: false
	},
	deleteInventoryItemProviderOfferingModal: {
		modalIsOpen: false,
		offeringId: null
	}
}

export const inventoryItemModalsSlice = createSlice({
	name: 'inventoryItemModalReducer',
	initialState,
	reducers : {
		setAddInventoryItemProviderOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.addInventoryItemProviderOfferingModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemProviderOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteInventoryItemProviderOfferingModal.modalIsOpen = action.payload
		},
		setDeleteInventoryItemProviderOfferingId: (state, action: PayloadAction<number | null>) => {
			state.deleteInventoryItemProviderOfferingModal.offeringId = action.payload
		}
	}
})

export const { setAddInventoryItemProviderOfferingModalIsOpen, setDeleteInventoryItemProviderOfferingModalIsOpen, setDeleteInventoryItemProviderOfferingId } = inventoryItemModalsSlice.actions

export default inventoryItemModalsSlice.reducer