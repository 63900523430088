//styles and icons
import './timeInput.scss'

// react
import React, { FC, useEffect, useRef, useState } from 'react'


type timeInputProps = {
	value: number
	setValue: (number: number) => void
	placeholder: string
}

const createArrayTimes = (): string[] => {
	const arr: string[] = []

	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 4; j++) {
			const minutes = j === 0 ? '00' : 15 * j

			arr.push(`${i === 0 ? '00' : i}:${minutes}`)
		}
	}

	return arr
}

const TimeInput: FC<timeInputProps> = ({ value, setValue, placeholder }) => {
	const [focusedIndexTimeline, setFocusedIndexTimeline] = useState(-1)
	const [showListOfTimelines, setShowListOfTimelines] = useState(false)
	const [searchRequest, setSearchRequest] = useState<string>('')
	const [hoursList] = useState<string[]>(createArrayTimes())
	const [filteredHourList, setFilteredHourList] = useState<string[]>(hoursList)
  
	const resultCounter = useRef<HTMLDivElement>(null)
	const convertTimeToData = (time: string | undefined) => {
		if (time) {
			let hours = 0
			let minutes = 0

			const timeParts = time.split(':')
			if (timeParts.length === 1) {

				// If only one part, assume it's hours
				hours = parseInt(timeParts[0], 10)
			} else if (timeParts.length === 2) {
			// If two parts, assume first part is hours and second part is minutes
				hours = parseInt(timeParts[0], 10)
				minutes = parseInt(timeParts[1], 10)
			}

			// Handle case when only hours are entered, assume minutes as 0
			if (isNaN(minutes) || minutes < 0 || minutes > 59) {
				minutes = 0
			}

			// Handle hours overflow
			if (hours < 0) {
				hours = 0
			} else if (hours > 23) {
				hours = 23
			}
        
			// Handle minutes overflow
			if (minutes < 0) {
				minutes = 0
			} else if (minutes > 59) {
				minutes = 59
			}

			return hours + minutes / 60
		}
	}

	const convertDecimalToTime = (decimalHours: number): string => {
		const hours = Math.floor(decimalHours)
		const minutes = Math.round((decimalHours - hours) * 60)
	
		const formattedHours = hours < 10 ? '0' + hours : hours.toString()
		const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString()
	
		return `${formattedHours}:${formattedMinutes}`
	}
  
	const resetSearchComplete = () => {
		setFocusedIndexTimeline(-1)
		setShowListOfTimelines(false)
	}
	
  
	// const onSelect = (item: string) => {
	// 	setSelectedValue(item)
	// }
  
	const handleSelection = (selectedIndex: number) => {
		const selectedItem = filteredHourList[selectedIndex]

		const decimal = convertTimeToData(selectedItem)

		if(decimal){
			setValue(decimal)
			setSearchRequest(`${decimal}`)
		}
    
		if (!selectedItem) return resetSearchComplete()

		resetSearchComplete()
	}
  
	const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const { target } = e
		const inputTime = target.value.trim()

		if (/^$|^\d{0,2}:\d{0,2}$|^\d{0,2}:$|^\d{0,2}$|^:$/.test(inputTime)) {
			setShowListOfTimelines(true)
			setSearchRequest(inputTime)
		
			if (inputTime === '') {
				setFilteredHourList(hoursList)
			} else {
				const filteredValues = hoursList.filter((hour) => hour.startsWith(inputTime))
				setFilteredHourList(filteredValues)
			}

			const decimal = convertTimeToData(inputTime)

			if(decimal){
				setValue(decimal)
			}
		}
	}

	const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		const { key } = e
		let nextIndexCount = 0

		if (key === 'ArrowDown') {
			nextIndexCount = (focusedIndexTimeline + 1) % hoursList.length
		}
		if (key === 'ArrowUp') {
			nextIndexCount = (focusedIndexTimeline + hoursList.length - 1) % hoursList.length
		}
		if (key === 'Escape') {
			setShowListOfTimelines(false)
		}
		if (key === 'Enter') {
			e.preventDefault()
			handleSelection(focusedIndexTimeline)
		}
		setFocusedIndexTimeline(nextIndexCount)
	}

	useEffect(() => {
		if (!resultCounter.current) return

		resultCounter.current.scrollIntoView({
			block: 'center',
		})
	}, [focusedIndexTimeline])
	
	useEffect(() => {
		if(!showListOfTimelines){
			setSearchRequest(convertDecimalToTime(value))
		}
	}, [showListOfTimelines])

	return (
		<div className='time-selector'>
			<div tabIndex={1} onKeyDown={handleKeyDown} className='time-selector-wrapper'>
				<div className='time-selector-wrapper-input'>
					<input
						className='time-selector-wrapper-search search'
						placeholder={placeholder}
						onChange={onChange}
						onFocus={() => setShowListOfTimelines(true)}
						onBlur={() => setShowListOfTimelines(false)}
						// value={convertDecimalToTime(value)}
						value={searchRequest}
					/>
				</div>
				<div className={`time-selector-wrapper-dropdown ${showListOfTimelines ? 'time-selector-wrapper-dropdown-open' : ''}`}>
					{filteredHourList.map((hour, index) => {
						return (
							<div
								ref={index === focusedIndexTimeline ? resultCounter : null}
								className='time-selector-wrapper-dropdown-item'
								key={`time-selector-wrapper-dropdown-item${index}`}
								onMouseDown={() => handleSelection(index)}
								style={{
									backgroundColor: index === focusedIndexTimeline ? 'rgba(0,0,0,0.1)' : '',
								}}
							>
								{hour}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default TimeInput