// styles and icons
import './extensionFileItem.scss'
import { close } from '../../../../../../../../assets/general/generalIcons'

//components
import FileItem from '../../../../../../fileItem/fileItem'

// react
import { FC } from 'react'

//context
import useChatDesktop from '../../../../context/chatDesktopContext'

//types
import { file } from '../../../../../../../../types/general/generalTypes'

type fileItemProps = {
	file: file,
	index: number
}

const ExtensionFileItem: FC<fileItemProps> = ({file, index}) => {

	const { attachFiles, setAttachedFiles } = useChatDesktop()

	const deleteFileFromList = (e:React.UIEvent<HTMLDivElement>) =>{
		const fileIndex = e.currentTarget.getAttribute('data-index')
		if(fileIndex){
			setAttachedFiles(attachFiles.attachedFiles.filter((element, index) => index !== parseInt(fileIndex)))
		}
	}
	
	
	return (
		<div className="file-item-wrapper" draggable={false} >
			<div className="file-item-close-button" data-index={index} onClick={(e)=>{deleteFileFromList(e)}} >
				{close}
			</div>
			<FileItem file={file} />
		</div>
	)
}

export default ExtensionFileItem
