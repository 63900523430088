//styles and icons
import './messagesSliderCard.scss'

//react
import { useEffect, useState, FC } from 'react'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//components
import Avatar from '../../../../../avatar/avatar'
import FileItem from '../../../../../fileItem/fileItem'

//types
import {messageType} from '../../../../../../../types/employees/chatTypes'
import { file } from '../../../../../../../types/general/generalTypes'

//other
import { getMessages } from '../../../../../../../assets/employees/chat/chatFunctions'

type messagesSliderCardProps = {
	message: messageType
}

const MessagesSliderCard: FC<messagesSliderCardProps> = ({ message }) => {

	const { chatInfo, messages, setMessages, setMessagesPage, setLastPage } = useChatDesktop()

	const [messageItemId, setMessageItemId] = useState<number | undefined>(undefined)
	const {userId, messageId} = message

	const user = chatInfo.data.chatMembers.find(item => item.userId === userId) || { avatar: null, name: '' }

	useEffect(() => {

		if(messageItemId && chatInfo.data.chatId){
			const foundItem = messages.messages.find(messageItem => messageItem.messageId == messageItemId)
			if(foundItem){
				const scrollOffsetChildElem = (document.querySelector(`.chat-message-container[data-message-id='${foundItem.messageId}']`) as HTMLDivElement ).offsetTop
				const scrollOffsetPerentElem = (document.querySelector('.message-area') as HTMLDivElement ).offsetTop
				const scrollOffset = scrollOffsetChildElem - scrollOffsetPerentElem

				document.querySelector('.message-area')?.scrollTo({
					top: scrollOffset,
					behavior: 'smooth',
				})

				setMessageItemId(undefined)


			}else{
				getMessages(chatInfo.data.chatId, messages.page+1, 10)
					.then((newMessages) => {
						
						if(newMessages.length > 0){

							setMessages([...newMessages, ...messages.messages])

							setMessagesPage(messages.page + 1)

						}else{
							setLastPage(true)
						}
					})
			}
		}
		

	}, [messages, messageItemId])
	
	return (
		<div className="pinned-message-card-container" data-message-id={messageId} onClick={(e)=>{
			const element = e.currentTarget as HTMLDivElement 
			
			if(element){
				const messageId = parseInt(element.getAttribute('data-message-id') || '-1')
				setMessageItemId(messageId)
			}
		}}>
			<div className="chat-card-container">
				<div className="chat-card-avatar-container">
					<Avatar blobAvatar={user.avatar ? user.avatar : null}/>
				</div>
				<div className="chat-card-information-container">
					<p className="chat-card-name">{user.name}</p>
					<div className="chat-card-files-container">
						{
							message.file.map((file: file)=>{
								return (
									<>
										<FileItem file={file} />
										
									</>
								)
							})
						}
					</div>
					<div className="chat-card-message-container">
						<div className="message" dangerouslySetInnerHTML={{__html: message.text}}></div >
					</div>
				</div>
			</div>
		</div>
	)
}

export default MessagesSliderCard