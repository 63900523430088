// style
import './inventoryList.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import OfferingCard from '../../../components/general/offeringCard/offeringCard'
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import CreateInventoryItemModal from '../../../components/warehouse/general/modals/inventoryItemModals/createInventoryItemModal/createInventoryItemModal'
import DeleteInventoryItemConfirmationModal from '../../../components/warehouse/general/modals/inventoryItemModals/deleteInventoryItemConfirmationModal/deleteInventoryItemConfirmationModal'
import ExportInventoryItemFileModal from '../../../components/warehouse/general/modals/inventoryItemModals/exportInventoryItemFileModal/exportInventoryItemFileModal'
import ImportInventoryItemFileSelectorModal from '../../../components/warehouse/general/modals/inventoryItemModals/importInventoryItemFileSelectorModal/importInventoryItemFileSelectorModal'
import InventoryItemTablePreviewModal from '../../../components/warehouse/general/modals/inventoryItemModals/inventryItemTablePreviewModal/inventryItemTablePreviewModal'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import OfferingCardSkeleton from '../../../components/general/skeletons/offeringCard/offeringCardSkeleton'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateInventoryItemModalIsOpen, setDeleteInventoryItemId, setDeleteInventoryItemModalIsOpen, setExportInventoryItemFileModalIsOpen, setImportInventoryItemFileSelectorModalIsOpen } from '../../../redux/warehouse/general/modals'

// type
import { filterBar, filters, infoPopupTypes, inventoryItem } from '../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../utils/queries'
import { inventoryItemFiltersUrl, inventoryItemsUrl } from '../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// other
import { createFilteringRequest, getItemsPerColumn, formatInventoryItemResponse, formatFilters } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const InventoryList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryList' })
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const [inventoryItems, setInventoryItems] = useState<inventoryItem[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/warehouse/inventory',
			title: t('inventory')
		}
	]

	const infoPopup = {
		content: [{
			title: 'inventory_list_info_title',
			description: 'inventory_list_info_description',
			link: '/ri-manual/warehouses/inventory'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(inventoryItemFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				importButton: () => {
					dispatch(setImportInventoryItemFileSelectorModalIsOpen(true))
				},
				// exportButton: () => {
				// 	dispatch(setExportInventoryItemFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => dispatch(setCreateInventoryItemModalIsOpen(true))
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(inventoryItemsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedItems: inventoryItem[] = result.map(formatInventoryItemResponse)
				return formatedItems
			}

			return []

		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setInventoryItems([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setInventoryItems([...inventoryItems, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])


	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	const handleDeleteInventoryItem = (inventoryItemId: number) => {
		dispatch(setDeleteInventoryItemModalIsOpen(true))
		dispatch(setDeleteInventoryItemId(inventoryItemId))
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Layout
			header={{
				avatar: true,
				title: t('inventory')
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="inventory-list">
				<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn}>
						{inventoryItems.map((item, index) => {
							return (
								
								<OfferingCard
									{...item}
									key={`inventory-product-${index}`}
									link={`/ri-business/warehouse/inventory-item/${item.inventoryItemId}`}
									handleDelete={item.deleteAccess ? () => {
										handleDeleteInventoryItem(item.inventoryItemId)
									} : undefined}
								/>
							)
						})}
						{loading ? <OfferingCardSkeleton /> : null}
					</ColumnLayout>
				</Pagination>
				<CreateInventoryItemModal inventoryItems={inventoryItems} setInventoryItems={setInventoryItems} />
				<DeleteInventoryItemConfirmationModal inventoryItems={inventoryItems} setInventoryItems={setInventoryItems} />
				<ExportInventoryItemFileModal />
				<ImportInventoryItemFileSelectorModal />
				<InventoryItemTablePreviewModal inventoryItems={inventoryItems} setInventoryItems={setInventoryItems} />
			</div>
		</Layout>
	)
}

export default InventoryList


