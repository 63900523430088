//styles and icons
import './chatMessage.scss'
import { pinMessageIcon } from '../../../../../../assets/employees/chat/chatIcons'

//react
import { FC, useEffect, useRef, useState } from 'react'

//components
import ContextMenu from './contextMenu/contextMenu'
import Loading from '../../../../loading/loading'
import AdditionalData from './additionalData/additionalData'
import FilesContainer from './filesContainer/filesContainer'
import Avatar from '../../../../avatar/avatar'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { messageStatus, messageTypes, chatMember, messageType } from '../../../../../../types/employees/chatTypes'

//other 
import { formatDateToHour, translateDate } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

export type chatMessageProps = {
	message: messageType,
	onLoaded: () => void
}

const ChatMessage: FC<chatMessageProps> = ({ message, onLoaded }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.messageArea.chatMessage' })

	const { file, text, date, forwarded, replied, edited, type, status, userId, messageId, pinned } = message

	const [ needToAnimate, setNeedToAnimateBlock ] = useState(true)

	const { chatInfo, contextMenu, setContextMenu } = useChatDesktop()

	const sender: chatMember = chatInfo.data.chatMembers.find((elem) => elem.userId === userId) || { userId: null, inboxId: null, avatar: null, name: '' }

	const messageRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		setNeedToAnimateBlock(false)
	}, [])


	const contextMenuHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault()

		setContextMenu({
			coords: {
				x: e.clientX,
				y: e.clientY
			},
			message: message
		})
	}
	

	return (
		<div className={`chat-message-container ${type === messageTypes.USER ? 'user-message-container' : 'stranger-message-container'} ${needToAnimate ? 'hidden-message' : ''}`} data-message-id={messageId}>
			<div className="chat-message-info-container">
				<p className="chat-message-info-item">{type !== messageTypes.USER ? sender?.name : ''}</p>
				<p className="chat-message-info-item">{formatDateToHour(translateDate(date))}</p>
			</div>
			<div
				ref={messageRef}
				onContextMenu={(e) => contextMenuHandler(e)}
				className='chat-message'
			>
				{type === messageTypes.STRANGER ? <div className='chat-message-avatar-container'>{<Avatar blobAvatar={sender.avatar} />}</div> : null}
				{contextMenu ? <ContextMenu /> : null}
				<div className="chat-message-content-wrapper">
					<div className={`chat-message-content  ${type === messageTypes.USER ? 'user-message' : 'stranger-message'}`}>
						<AdditionalData forwarded={forwarded} edited={edited} type={type} replied={replied} />
						{
							file ? <FilesContainer onLoaded={onLoaded} fileData={file} messageId={messageId}/> : null
						}
						{text && text.length > 0 ? <p className="chat-message-text" dangerouslySetInnerHTML={{__html: text ? text : forwarded ? forwarded.text : ''}}></p> : null}
						{
							pinned ? <div className="chat-message-pinned-container">{pinMessageIcon}</div> : null
						}
					</div>
					{
						type === messageTypes.USER ?
							<div className="chat-message-status">
								{status === messageStatus.SENT ? t('sent') : status === messageStatus.READ ? t('read') : status === messageStatus.SENDING ? <Loading /> : null}
							</div>
							: null
					}
				</div>
				
			</div>
		</div>
	)
}

export default ChatMessage
