//styles
import './warehouseShipment.scss'

//components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import ShipmentTablePreviewModal from '../../general/modals/shipmentModals/shipmentTablePreviewModal/shipmentTablePreviewModal'
import ImportShipmentFileSelectorModal from '../../general/modals/shipmentModals/importShipmentFileSelectorModal/importShipmentFileSelectorModal'
import ExportShipmentFileModal from '../../general/modals/shipmentModals/exportShipmentFileModal/exportShipmentFileModal'
import Pagination from '../../../general/pagination/pagination'
import ShipmentCard from '../../../general/shipmentCard/shipmentCard'
import CreateTaskModal from '../../../general/modals/task/createTaskModal/createTaskModal'
import CheckBox from '../../../general/checkBox/checkBox'
import OrderItemSkeleton from '../../../general/skeletons/orderItem/orderItemSkeleton'

//react
import { useEffect, useState, FC } from 'react'
import { useParams } from 'react-router-dom'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setCreateTaskModalIsOpen, setCreateTaskModalStageId } from '../../../../redux/general/modals'

//types
import { filters, shipment, shipmentResponse } from '../../../../types/general/generalTypes'
import { filterBar } from '../../../../types/general/generalTypes'

//network 
import { authorizedRequest } from '../../../../utils/queries'
import { companyWarehouseActionsFiltersUrl, companyWarehouseActionsUrl } from '../../../../utils/urls/warehouses/warehouseActions/warehouseActions'

//other 
import {
	createFilteringRequest,
	formatFilters,
	formatShipmentResponse
} from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type warehouseShipmentProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const WarehouseShipment: FC<warehouseShipmentProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouse.warehouseShipmentList' })

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()
	const { warehouseId } = useParams()

	const [shipmentList, setShipmentList] = useState<shipment[]>([])
	const [filteredShipmentList, setFilteredShipmentList] = useState<shipment[]>([])

	const [page, setPage] = useState(1)
	const [searchRequest, setSearchRequest] = useState<string>('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [lastPage, setLastPage] = useState<boolean>(false)
	const [showClosed, setShowClosed] = useState(true)

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(companyWarehouseActionsFiltersUrl(companyId), 'GET')

		const filterResult = result.filters
		const createAccess = result.create_access

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: createAccess && {
				text: 'Add Shipment',
				active: true,
				onClick: handleCreateShipment
			},
			onSearch: onSearch
		})
	}

	const handleCreateShipment = () => {
		dispatch(setCreateTaskModalStageId(1))
		dispatch(setCreateTaskModalIsOpen(true))
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		setLoading(true)
		const filteringRequest = createFilteringRequest(filters)
		return authorizedRequest(companyWarehouseActionsUrl(companyId) + `?page=${page}&per_page=${10}&needle${request}&type=shipment&warehouse_id=${Number(warehouseId)}` + filteringRequest, 'GET')
			.then((response) => {
				const { result }: { result: shipmentResponse[] } = response

				const shipmentData: shipment[] = result.map(formatShipmentResponse)

				setLoading(false)
				return shipmentData
			})
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setShipmentList([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setShipmentList([...shipmentList, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		loadFilterBar()

		onSearch(searchRequest, activeFilters)

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	useEffect(() => {
		setFilteredShipmentList(shipmentList.filter((shipment) => showClosed ? shipment : !shipment.isClosed))
	}, [shipmentList, showClosed])

	return (
		<div className="shipment-list">
			<CheckBox
				isChecked={showClosed}
				onClick={() => {
					setShowClosed(!showClosed)
				}} 
				label={t('showClosed')}
			/>
			<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
				<ColumnLayout>
					{
						filteredShipmentList.map((shipment: shipment, index: number) => {
							return (
								<ShipmentCard
									{...shipment}
									key={`shipment-${index}`}
								/>
							)
						})
					}
					{loading ? <OrderItemSkeleton /> : null}
				</ColumnLayout>
				<ImportShipmentFileSelectorModal />
				<ShipmentTablePreviewModal />
				<ExportShipmentFileModal />
				<CreateTaskModal shipments={shipmentList} setShipments={setShipmentList} isWarehouseSectionShow={true} />
			</Pagination>
		</div>
	)
}

export default WarehouseShipment