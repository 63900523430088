// components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

// react
import { Dispatch, FC, SetStateAction, useState } from 'react'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setDeleteOfferingId, setDeleteOfferingModalIsOpen } from '../../../../../redux/sales/offeringList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleOfferingUrl } from '../../../../../utils/urls/sales/offerings'

// translation
import { useTranslation } from 'react-i18next'

// types
import { offering } from '../../../../../types/general/generalTypes'

type deleteOfferingModalResponse = {
	offerings: offering[]
	setOfferings: Dispatch<SetStateAction<offering[]>>
}
const DeleteOfferingModal : FC<deleteOfferingModalResponse> = ({offerings, setOfferings}) => {
	const { modalIsOpen, offeringId } = useAppSelector( state  => state.offeringListModal.deleteOfferingModal )

	const dispatch = useAppDispatch()

	const {t} = useTranslation('', {keyPrefix: 'sales.offeringList.modals.deleteOfferingModal'})

	const [loading, setLoading] = useState<boolean>(false)

	const closeModal = () => {
		dispatch( setDeleteOfferingModalIsOpen(false) )
		dispatch( setDeleteOfferingId(null) )
	}

	const deleteOffering = () => {
		setLoading(true)
		authorizedRequest( singleOfferingUrl(offeringId!), 'DELETE' )
			.then( ( {result} ) => {
				const updatedOfferings = offerings.filter(offering => offering.offeringId !== offeringId)
				setOfferings(updatedOfferings)
				setLoading(false)
				closeModal()
			})
	}

	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteOffering }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeleteOfferingModal