import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

type settingsGeneralState = {
	settingsActiveCompanyId: number | null
}

const initialState: settingsGeneralState = {
	settingsActiveCompanyId: null
}

export const settingsGeneralSlice = createSlice({
	name: 'settingsGeneralReducer',
	initialState,
	reducers: {
		setSettingsActiveCompanyId: (state, action: PayloadAction<number | null>) => {
			state.settingsActiveCompanyId = action.payload
		}
	}
})

export const {setSettingsActiveCompanyId } = settingsGeneralSlice.actions

export default settingsGeneralSlice.reducer