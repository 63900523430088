// images
import provider1En from '../../../assets/manual/purchase/en/providersHome.png'
import provider2En from '../../../assets/manual/purchase/en/providerList.png'
import provider3En from '../../../assets/manual/purchase/en/createProvider.png'
import provider1Ua from '../../../assets/manual/purchase/ua/providersHome.png'
import provider2Ua from '../../../assets/manual/purchase/ua/providerList.png'
import provider3Ua from '../../../assets/manual/purchase/ua/createProvider.png'
import purchase1En from '../../../assets/manual/purchase/en/purchaseOrderHome.png'
import purchase2En from '../../../assets/manual/purchase/en/purchaseOrder.png'
import purchase3En from '../../../assets/manual/purchase/en/createOrder.png'
import purchase1Ua from '../../../assets/manual/purchase/ua/purchaseOrderHome.png'
import purchase2Ua from '../../../assets/manual/purchase/ua/purchasesOrderHome.png'
import purchase3Ua from '../../../assets/manual/purchase/ua/createOrder.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const GeneralPurchaseManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.purchase' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('providersTitle'),
			text: t('providersText'),
			link: '/ri-manual/purchases/providers'
			// images: language == 'en' ? [provider1En, provider2En, provider3En] : [provider1Ua, provider2Ua, provider3Ua]
			
		},
		{
			title: t('purchaseOrdersTitle'),
			text: t('purchaseOrdersText'),
			link: '/ri-manual/general/single-order'
			// images: language == 'en' ? [purchase1En, purchase2En, purchase3En] : [purchase1Ua, purchase2Ua, purchase3Ua]
		},
		{
			title: t('singleProviderTitle'),
			text: t('singleProviderText'),
			link: '/ri-manual/purchases/single-provider'
			// images: language == 'en' ? [purchase1En, purchase2En, purchase3En] : [purchase1Ua, purchase2Ua, purchase3Ua]
		}
	]
	
	return (
		<ManualLayout 
			heading={t('purchaseManualTitle')}
			data={data}
		/>
	)
}

export default GeneralPurchaseManual
