//styles
import './listCardSkeleton.scss'

//components
import Loading from '../../loading/loading'

const ListCardSkeleton = () => {
	return (
		<div className='list-card-skeleton-container'>
			<Loading style={{ position: 'relative', top: '0', left: '0' }} />
		</div>
	)
}

export default ListCardSkeleton