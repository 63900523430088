import { baseUrl } from '../../network'

export const companyProjectsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/projects`
export const singleProjectUrl = (projectId: number)=> baseUrl + `/api/projects/${projectId}`
export const singleProjectReportUrl = (projectId: number) => baseUrl + `/api/projects/${projectId}/report`
export const singleProjectMoveUrl = (projectId: number)=> baseUrl + `/api/projects/${projectId}/move`
export const singleProjectTasksUrl = (projectId: number)=> baseUrl + `/api/project/${projectId}/tasks`
export const getProjectTreeUrl = (projectId: number)=> baseUrl + `/api/projects/${projectId}/tree`
export const getProjectCreateAccessUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/projects/access`
export const singleProjectCloseUrl = (projectId: number) => baseUrl + `/api/projects/${projectId}/close`
export const singleProjectCalendarTasksUrl = (projectId: number)=> baseUrl + `/api/projects/${projectId}/calendar`

export const singleProjectDocumentFiltersUrl = (projectId: number) => baseUrl + `api/project/${projectId}/documents/filters`
export const singleProjectDocumentsUrl = (projectId: number) => baseUrl + `api/project/${projectId}/documents`
export const singleProjectDocumentUrl = (fileId: number) => baseUrl + `api/project/documents/${fileId}`
