//styles and icons
import './chatVideoBanner.scss'
import { deleteAudioMessageIcon, redCircle, videoConfirmIcon, videoStopIcon } from '../../../../../../assets/employees/chat/chatIcons'

// react
import { FC, useEffect, useRef, useState } from 'react'

// component
import Button from '../../../../button/button'
import VideoPlayer from '../../../../videoPlayer/videoPlayer'
import VideoMessageChatInput from './videoMessageChatInput/videoMessageChatInput'

//context
import useChatDesktop from '../../context/chatDesktopContext'

// types
import { file } from '../../../../../../types/general/generalTypes'

// other
import { formatTime } from '../../../../../../assets/employees/chat/chatFunctions'

type chatInputVideoMessageProps  = {
	handleSubmit:() => void
}

const ChatInputVideoMessage: FC<chatInputVideoMessageProps> = ({ handleSubmit }) => {
	const { setIsVideoInitialized, setAttachedFiles, attachFiles } = useChatDesktop()

	const [isRecording,setIsRecording] = useState(false)
	const [canRecord,setCanRecord] = useState(false)
	const [isPreviewVisible,setPreviewVisible] = useState(false)
	const [ isSubmitted, setIsSubmitted ] = useState(false)

	const videoRef = useRef<HTMLVideoElement>(null)
	const resultRef = useRef<HTMLVideoElement>(null)
	const stopRecordingButtonRef = useRef<HTMLButtonElement>(null)

	const [mediaStream,setMediaStream] = useState<MediaStream | null>(null)
	const [videoFileResult,setVideoFileResult] = useState<Blob | null>(null)

	const [timer, setTimer] = useState(0)


	const startWebcam = async () => {
		try{
			let stream:MediaStream 

			// Check if the user doesn't have any audio device 
			try{
				stream = await navigator.mediaDevices.getUserMedia({video:true,audio:true})
			}
			catch(err){
				stream = await navigator.mediaDevices.getUserMedia({video:true})
			}

			if(videoRef.current){
				videoRef.current.srcObject = stream
				await videoRef.current.play()
				setMediaStream(stream)
				setCanRecord(true)
			}

		}catch(err){
			console.error(err) // User doesn't have any recording devices or was blocked/not allowed
			setCanRecord(false)
			setIsVideoInitialized(false)
		}
	}
	const stopWebcam = ()=>{
		if(mediaStream){
			const tracks = mediaStream.getTracks()
			tracks.forEach((track)=>track.stop())
		}
	}

	const startRecording = () => {
		// Setup media recorder
		if(mediaStream && mediaStream.active && canRecord && stopRecordingButtonRef.current){   
			let chunks:Blob[] = []
			const constraints = {
				mimeType:'video/webm', 
			}
			const mediaRecorder = new MediaRecorder(mediaStream,constraints)

			const addChunks = (blob:BlobEvent)=>{
				chunks.push(blob.data)
			}

			const handleStopRecording =  () => {
				const blob = new Blob(chunks,{type:'video/webm'})
				setVideoFileResult(blob)

				mediaRecorder.removeEventListener('dataavailable',addChunks)
				chunks = []
			}

			mediaRecorder.addEventListener('dataavailable',addChunks)
			mediaRecorder.addEventListener('stop',handleStopRecording)


			// Start Recording
			mediaRecorder.start(100)

			stopRecordingButtonRef.current.addEventListener('click',()=>{
				if(mediaRecorder.stream.active === false){
					setIsVideoInitialized(false)
				}
				mediaRecorder.stop()
				stopWebcam()
			})

		}
	}

	const closeVideoMessage = ()=>{
		setPreviewVisible(false)
		setCanRecord(false)
		stopWebcam()

		setTimeout(() => {
			setIsVideoInitialized(false)
			
		},520)

	}
	const handleSubmitVideo = ()=>{
		if(videoFileResult){
			const videoFile : file = {
				file:videoFileResult,
				fileName:`Video Recording ${new Date().toISOString()}`,
				fileType:'video/webm'
			}
			setAttachedFiles([...attachFiles.attachedFiles, videoFile])
			setIsSubmitted(true)
		}
	}

	useEffect(() => {
		if(isSubmitted){
			handleSubmit()
			closeVideoMessage()
		}
	}, [attachFiles.attachedFiles])

	const handleDeleteVideo = ()=>{
		setIsRecording(false)
		setVideoFileResult(null)
		//startWebcam()
		setTimer(0)
		setPreviewVisible(false)
		closeVideoMessage()
	}



	useEffect(()=>{
		startWebcam()
		setPreviewVisible(true)
	},[])

	useEffect(()=>{
		let intervalId: number | null = null
		if(isRecording){
			startRecording()
			intervalId = window.setInterval(()=>{
				setTimer(prev=>prev+1)
			},1000)
		}

		return ()=>{
			if(intervalId){
				clearInterval(intervalId)
			}
		}
	},[isRecording])

	useEffect(()=>{
		if(resultRef.current && videoFileResult){
			resultRef.current.src = URL.createObjectURL(videoFileResult)
		}
	},[videoFileResult])

	return (
		<div className='chat-video-container'>
			<div className={`chat-video-preview ${isPreviewVisible ? 'open' : 'closed'}`}>
				{videoFileResult 
					? (
						<VideoPlayer videoBlob={videoFileResult}/>
					)
					:(
						<video ref={videoRef} muted></video>		
					) 
				}
			</div>
			<div className="chat-video-action">
				{!isRecording 
					? (
						<div className={`chat-video-action_start video-action-start ${canRecord && isPreviewVisible ? 'show' : ''}`}>
							<Button text='Cancel' active={false} onClick={()=>{closeVideoMessage()}}></Button>
							<Button text='Record' active={false}  onClick={()=>{setIsRecording(true)}}/>
						</div>
					)
					: (
						<div className="chat-video-action_recording video-action-end">
							{videoFileResult 
								?(
									<>
										<button className="btn-delete" type='button' onClick={handleDeleteVideo}>
											{deleteAudioMessageIcon}
										</button>
										<VideoMessageChatInput handleSubmit={handleSubmitVideo}/>
										<button className="btn-tool btn-send" type='button' onClick={handleSubmitVideo}>
											{videoConfirmIcon}
										</button>
									</>
								)
								:(
									<>
										<div className="chat-video-recording-status">
											{redCircle}
											<h2 className='timer'>{formatTime(timer)}</h2>
										</div>

										<button disabled={!isPreviewVisible} className="btn-tool btn-stop" type='button' ref={stopRecordingButtonRef}>
											{videoStopIcon}
										</button>
									</>
								)
							}
						</div>
					)
				}
			</div>
		</div>
	)
}

export default ChatInputVideoMessage