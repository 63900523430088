import { baseUrl } from '../../network'

export const hierarchyHireEmployeeInvitationSendUrl = baseUrl + '/api/hierarchy/hire/invite'
export const hierarchyHireEmployeeInvitationConfirmUrl = baseUrl + '/api/hierarchy/hire/confirm'

export const hierarchyFireEmployeeUrl = baseUrl + '/api/hierarchy/fire'
export const hierarchyResignUrl = (companyId: number)=> baseUrl + `/api/hierarchy/company/${companyId}/resign`

export const hierarchyEmployeesUrl = (employeeId: number)=> baseUrl + `/api/hierarchy/employees/${employeeId}`
export const hierarchyEmployeesLeadersUrl = (employeeId: number)=> baseUrl + `/api/hierarchy/employees/${employeeId}/leaders`
export const hierarchyGetTheLeaderUrl = (employeeId: number)=> baseUrl + `/api/hierarchy/employees/${employeeId}/leader`
export const hierarchyEmployeesOwnersUrl = (companyId: number)=> baseUrl + `/api/hierarchy/company/${companyId}/owners`

export const companyHierarchyTreeUrl = (companyId: number)=> baseUrl + `/api/hierarchy/company/${companyId}/tree`

export const hierarchyMoveEmployeesUrl = (employeeId: number) => baseUrl + `/api/hierarchy/employees/${employeeId}/move`