//components
import Navbar from '../../../../apps/landing/components/general/layout/navbar/navbar'

//icons
import { serverErrorImage } from '../../../../assets/general/generalIcons'

//styles
import './serverError.scss'

const ServerError = () => {
	return (
		<div className='server-error-container'>
			<div className="section-headline">
				<div>
					<div className="section-headline-left-lines">
						<div className="section-headline-long-line"></div>
						<div className="section-headline-short-line"></div>
					</div>
				</div>
				<div className='error-content'>
					{serverErrorImage}
					<div className='error-text'>
						<h1>Oops</h1>
						<p>Internal Server Error</p>
					</div>
				</div>
				<div>
					<div className="section-headline-right-lines">
						<div className="section-headline-short-line"></div>
						<div className="section-headline-long-line"></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ServerError