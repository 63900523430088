//styles
import './home.scss'

//react
import { useNavigate } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import Button from '../../../components/general/button/button'

//translation
import { useTranslation } from 'react-i18next'

const Home = () => {
	const { t } = useTranslation('', { keyPrefix: 'home' })

	const navigate = useNavigate()

	const titleUrls = [
		{
			url: '/ri-business/',
			title: t('home')
		}
	]

	return (
		<Layout
			header={{
				avatar: true,
			}}
			titleUrls={titleUrls}
		>
			<div className="home">
				<div className="home-text-wrapper">
					<h2 className='home-title'>{t('title')}</h2>
					<div className="home-text-container">
						<p className='home-text'>{t('text1')}</p>
						<p className='home-text'>{t('text2')} <span>{t(`quotes.${Math.floor(Math.random()*22)}`)}</span></p>
						<p className="home-text">{t('text3')}</p>
					</div>
				</div>
				<div className="home-set-up-text-wrapper">
					<h2 className='home-set-up-title'>{t('followTheLinkBelowToGetThe')}</h2>
					<div className="home-set-up-text-container">
						<div className="home-set-up-text-item-container">
							<Button text={t('instractions')} active onClick={() => {
								navigate('/manual/set-up')
							}} />
						</div>
						<p className='home-set-up-text'>{t('or')}</p>
						<div className="home-set-up-text-item-container">
							<p className='home-set-up-text'>{t('ourCustomerSupport')}</p>
							<div className="home-set-up-text-items">
								<a href='https://www.facebook.com/profile.php?id=61559840792929' target='_blank'>Facebook</a>
								<a href='https://www.instagram.com/ri.software' target='_blank'>Instagram</a>
								<a href='https://www.linkedin.com/company/ri-software/' target='_blank'>LinkedIn</a>
								<a href="mailto: support@ri-software.com.ua">support@ri-software.com.ua</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Home