//styles
import './purchaseOrderList.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Pagination from '../../../components/general/pagination/pagination'
import OrderCard from '../../../components/general/orderCard/orderCard'
import CreatePurchaseOrderModal from '../../../components/purchase/purchaseOrderList/modals/createPurchaseOrderModal/createPurchaseOrderModal'
import OrderItemSkeleton from '../../../components/general/skeletons/orderItem/orderItemSkeleton'

// types
import { filterBar, filters, infoPopupTypes, order } from '../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import { getPurchaseOrdersFilterUrl, getPurchaseOrdersUrl } from '../../../utils/old_urls/purchase/purchaseOrderListUrls'

// translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreatePurchaseOrderModalIsOpen } from '../../../redux/purchase/purchaseOrderList/modals'

//other
import { createFilteringRequest, formatFilters, formatOrderResponse, getItemsPerColumn } from '../../../assets/general/generalFunctions'

const PurchaseOrderList: FC = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1


	const dispatch = useAppDispatch()
	const { t } = useTranslation('', { keyPrefix: 'purchase.purchaseOrderList' })

	const [orders, setOrders] = useState<order[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(2))

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const titleUrls = [
		{
			url: '/ri-business/purchase/order-list',
			title: t('purchaseOrders')
		}
	]

	const infoPopup = {
		content: [{
			title: 'purchase_order_list_info_title',
			description: 'purchase_order_list_info_description',
			link: '/ri-manual/purchases'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(getPurchaseOrdersFilterUrl(companyId), 'GET')
			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)


			setFilterBar({
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreatePurchaseOrderModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(getPurchaseOrdersUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}&type=purchase` + filteringRequest, 'GET')
			if (result.length > 0) {
				const loadData: order[] = result.map(formatOrderResponse)

				return loadData
			} else {
				return []
			}
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setOrders([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setOrders([...orders, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}).finally(() => {
					setLoading(false)
				})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn(2))
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="purchase-order-list">
				<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn}>
						{orders.map((order, index) => {
							return <OrderCard {...order} isProvider key={'order' + index} />
						})
						}
						{loading ? <OrderItemSkeleton /> : null}
					</ColumnLayout>
				</Pagination>

			</div>
			<CreatePurchaseOrderModal orders={orders} setOrders={setOrders} />
		</Layout>
	)
}

export default PurchaseOrderList