// styles
import  './fileTableParserModal.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import Modal from '../modal/modal'
import InputField from '../../inputField/inputField'
import Table from '../../table/table'

// types
import { file } from '../../../../types/general/generalTypes'

//other
import { formatDate } from '../../../../assets/general/generalFunctions'

// library
import papa from 'papaparse'
import * as XLSX from 'xlsx'

// translation
import { useTranslation } from 'react-i18next'

type parsedTableData = {
	columns: {key:string, title:string}[]
	data: Record<string, string>[]
}

type column = {
	title: string
	key: string
	default: string
	type: any
}

type fileTableParserModalProps = {
	open: boolean
	file: file | null
	onTableProcessed: (table: parsedTableData) => void
	closeModal: () => void
	requiredColumns: column[]
}

const FileTableParserModal : FC<fileTableParserModalProps> = ({file, open, onTableProcessed, closeModal, requiredColumns}) => {

	const [tableData, setTableData] = useState<parsedTableData | null>(null)
	
	const { t } = useTranslation('', { keyPrefix: 'general.modals.fileTableParserModal' })

	const handleSubmit = () => {
		tableData && onTableProcessed(tableData)
	}

	const enumFormatedString = (enumObj: object, str: string) => {
		const index = Object.values(enumObj).map(key => key.toLowerCase()).indexOf(str.toString().toLowerCase())

		return Object.values(enumObj)[index]
	}

	const stringToColumnType = ( type: any, data: string | undefined): string | null => {
		const str = data || ''

		switch(type){
		case Number: 
			return !isNaN(parseInt(str)) ? str : null
		case String:
			return str
		case Date: {
			const dateStr = str.toString().replace(/[.,/]/g, ' ')
	
			const dateArr = dateStr.split(' ')
	
			const date = new Date(`${dateArr[1]} ${dateArr[0]} ${dateArr[2]}`)
			
			return !isNaN(date.getTime()) ? formatDate(date, true, true) : null
		}
		case Boolean: {
			const lowerCaseStr = `${str}`.toLowerCase()
			return lowerCaseStr === 'true' ? 'true' : 'false'
		}
		default: 
			return enumFormatedString(type, `${str}`) || undefined
		}
	}

	const formatTableData = (columns: column[], tableData: Record<string, string | undefined>[]) => {
		const data: Record<string, string>[] = []

		tableData.forEach((row, index) => {
			const renderedRow = {}
			columns.forEach((col, colIndex) => {

				const keys = Object.keys(row)

				const cellValue =  stringToColumnType(col.type, row[keys[colIndex] || -1]) || stringToColumnType(col.type, col.default) || col.default

				Object.assign(renderedRow, {[col.key]: cellValue})

			})

			if(Object.keys(renderedRow).length !== 0){
				data.push({id:'import-table-row-'+index, key: 'import-table-row-'+index, ...renderedRow})
			} 
		})

		return data
	}

	const parseCSV = () => {
		if(file){
			const tableFile = new File([file.file], file.fileName)

			const handleError = () => {
				console.error('Parsing File Error')
			}

			const handleComplete = (results: any)=>{
				setTableData({
					columns: requiredColumns,
					data: formatTableData(requiredColumns, results.data)
				})
			}

			papa.parse(tableFile, {
				header: true,
				complete: handleComplete,
				error: handleError
			})
		}
	}

	const parseXLSX = async () => {
		if(file){

			const tableFile = new File([file.file], file.fileName)
			const unparsedData = await tableFile.arrayBuffer()

			const workbook = XLSX.read(unparsedData)
			const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]]
			const parsedData = XLSX.utils.sheet_to_json(firstSheet, { defval: '' }) 
			
			setTableData({
				columns: requiredColumns,
				data: formatTableData(requiredColumns, parsedData as Record<string, string | undefined>[])
			})
		}

	}

	useEffect(() => {
		if(file){
			switch(file.fileType){
			case 'text/csv':
				parseCSV()
				break
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				parseXLSX()
				break
			default:
				throw new Error('Error: Unsupported File Type for File of type:' + file.fileType)
			}
		}
	},[file])

	return (
		<Modal
			open={open}
			title={t('title')}
			closeModal={closeModal}
			submitButton={{
				text: t('submitText'),
				onClick: handleSubmit
			}}
		>
			<div className="file-table-parser-container">
				<InputField name='filename' type='string' value={file?.fileName || ''} className='selected-file-input' disabled={true}/>
				{tableData && (
					<div className="parsed-table-container">
						<Table  
							columns={tableData.columns}
							data={tableData.data} 
						/>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default FileTableParserModal