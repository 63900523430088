import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

//types
import { dropdownOption } from '../../../types/general/generalTypes'

type chatBarState = {
    chatSearchInput: string
	activeInboxOption: dropdownOption | undefined
}

const initialState: chatBarState = {
	chatSearchInput: '',
	activeInboxOption: undefined
}

export const chatBarSlice = createSlice({
	name: 'chatBarReducer',
	initialState,
	reducers: {
		setChatSearchInput: (state, action: PayloadAction<string>) => {
			state.chatSearchInput = action.payload
		},
		setActiveInboxOption: (state, action: PayloadAction<dropdownOption | undefined>) => {
			state.activeInboxOption = action.payload
		}
	}
})

export const { setChatSearchInput, setActiveInboxOption } = chatBarSlice.actions

export default chatBarSlice.reducer