import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'


type purchaseOrderListModalsState = {
	createPurchaseOrderModal: {
		modalIsOpen: boolean
	}

}

const initialState: purchaseOrderListModalsState = {
	createPurchaseOrderModal: {
		modalIsOpen: false
	}
}

export const purchaseOrderListModalsSlice = createSlice({
	name: 'purchaseOrderListModalsSlice',
	initialState,
	reducers : {
		setCreatePurchaseOrderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createPurchaseOrderModal.modalIsOpen = action.payload
		},
	}
})

export const {
	setCreatePurchaseOrderModalIsOpen
} = purchaseOrderListModalsSlice.actions

export default purchaseOrderListModalsSlice.reducer