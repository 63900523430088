
// styles
import './taskList.scss'

// react
import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import DashboardCard from '../../../general/dashboard/dashboardStage/dashboardCard/dashboardCard'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import CounterpartySkeleton from '../../../general/skeletons/counterParty/counterPartySkeleton'
import Pagination from '../../../general/pagination/pagination'

// types
import { filterBar, task } from '../../../../types/general/generalTypes'

//other
import { getItemsPerColumn } from '../../../../assets/general/generalFunctions'

type taskListProps = {
	setFilterBar: (value: filterBar | undefined) => void
	loadTasks: (stageId: number, searchRequest: string, page: number, showClosed: boolean) => Promise<task<Date | undefined>[]>
	showClosed?: boolean
}

const TaskList: FC<taskListProps> = ({ setFilterBar, loadTasks, showClosed=false }) => {
	const navigate = useNavigate()
	const [tasks, setTasks] = useState<task<Date | undefined>[]>([])

	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [page, setPage] = useState<number>(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const loadMore = async () => {

		try {
			if (lastPage){
				return
			}
			setLoading(true)

			const tasksRes: task<Date | undefined>[] = await loadTasks(-1, searchQuery, page, showClosed)

			setTasks([...tasks, ...tasksRes])

			if (tasksRes.length<1){
				setLastPage(true)
				return
			}

			setPage(page+1)

		}finally {
			setLoading(false)
		}
	}

	const onSearch = async (query: string) => {
		setLoading(true)
		setPage(1)
		setSearchQuery(query)
		
		const tasksRes: task<Date | undefined>[] = await loadTasks(-1, query, 1, showClosed)

		setLoading(false)
		setPage(2)

		setTasks([...tasksRes])
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {

		setFilterBar({
			onSearch: onSearch
		})

		window.addEventListener('resize', handleResize)

		return () => {
			setFilterBar&&setFilterBar(undefined)
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		onSearch('')
	}, [showClosed])

	return (
		<div className='list-container'>
			<Pagination loadAxis={'vertical'} loading={loading} onLoadMore={loadMore} showLoader={false}>
				<ColumnLayout amount={itemsPerColumn} >
					{tasks.map((task) => {
						return (
							<DashboardCard {...task} onClick={() => {
								navigate(`/ri-business/projects/task/${task.id}`)
							}} />
						)
					})}
					{loading ? <CounterpartySkeleton/> : null}
				</ColumnLayout>
			</Pagination>
		</div>
	)
}

export default TaskList