import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type profileModalsState = {
	selecetAvatar: {
		modalIsOpen: boolean
	},
	customizeAvatar: {
		modalIsOpen: boolean
	},
	avatarFiles: file[],
	profileDocuments: {
		deleteProfileDocumentModal: {
			modalIsOpen: boolean
			documentId: number | null
		},
		importProfileDocumentFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
	}
}

const initialState: profileModalsState = {
	selecetAvatar: {
		modalIsOpen: false
	},
	customizeAvatar: {
		modalIsOpen: false
	},
	avatarFiles: [],
	profileDocuments: {
		deleteProfileDocumentModal: {
			modalIsOpen: false,
			documentId: null
		},
		importProfileDocumentFileSelectorModal: {
			modalIsOpen: false,
			file: null
		}
	}
}

export const profileModalsSlice = createSlice({
	name: 'profileModalsReducer',
	initialState,
	reducers: {
		setSelecetAvatarModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.selecetAvatar.modalIsOpen = action.payload
		},
		setCustomizeAvatarModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.customizeAvatar.modalIsOpen = action.payload
		},
		setAvatarFiles: (state, action: PayloadAction<file[]>) => {
			state.avatarFiles = action.payload
		},

		setDeleteProfileDocumentModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.profileDocuments.deleteProfileDocumentModal.modalIsOpen = action.payload
		},
		setDeleteProfileDocumentId: (state, action: PayloadAction<number | null>) => {
			state.profileDocuments.deleteProfileDocumentModal.documentId = action.payload
		},

		setImportProfileDocumentFileSelectorModalIsOpen : (state, action: PayloadAction<boolean>) => {
			state.profileDocuments.importProfileDocumentFileSelectorModal.modalIsOpen = action.payload
		},
		setImportProfileDocumentFileSelectorFile : (state, action: PayloadAction<file | null>) => {
			state.profileDocuments.importProfileDocumentFileSelectorModal.file = action.payload
		},
	}
})

export const {
	setSelecetAvatarModalIsOpen,
	setCustomizeAvatarModalIsOpen,
	setAvatarFiles,

	setDeleteProfileDocumentModalIsOpen,
	setDeleteProfileDocumentId,

	setImportProfileDocumentFileSelectorModalIsOpen,
	setImportProfileDocumentFileSelectorFile
} = profileModalsSlice.actions

export default profileModalsSlice.reducer