import { baseUrl } from '../../../network'

export const PLAnalysisUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/pnl`
export const cashFlowAnalysisUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/cashflow`
export const breakEvenAnalysisUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/breakeven`
export const customerProfitabilityUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/profitability`

export const revenueAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/revenue`
export const netIncomeAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/profitability`
export const operatingProfitAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/operating_profit`
export const grossProfitAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/gross_profit`
export const costOfOperationsAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/cost`
export const operatingExpensesAnalysis = (companyId:number)=> baseUrl + `/api/company/${companyId}/finance/stats/operating_expense`