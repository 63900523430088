// styles
import './animatedLines.scss'

// react
import { useEffect, FC, useRef } from 'react'

type animatedLinesProps = {
	numberOfLines: number
	width?: string
}

const AnimatedLines: FC<animatedLinesProps> = ({numberOfLines, width = '30vw'}) => {
	const ref = useRef(null)

	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
			
				const linesContainer = entry.target as HTMLElement
				if (entry.isIntersecting) {
					linesContainer.style.setProperty('--line-width', '100%')
				} else {
					linesContainer.style.setProperty('--line-width', '0%')
				}
			})
		}, {
			root: null,
			rootMargin: '0%',
			threshold: 0
		})
		observer.observe(ref.current!)
		
	}, [])
	return (
		<div className="lines-container" style={{width: width}} ref={ref}>
			{[...Array(numberOfLines)].map((_, index) => (
				<div
					key={index}
					className={'line'}
				>
					<div style={{ transitionDelay: `${index * 0.1}s`}}></div>
				</div>
			))}
		</div>
	)
}

export default AnimatedLines