//styles
import './messagesSlider.scss'

//components
import MessagesSliderCard from './messagesSliderCard/messagesSliderCard'

//react
import { FC, useEffect, useState } from 'react'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { messagesSliderEnum, messageType } from '../../../../../../types/employees/chatTypes'

//other
import { getMessages } from '../../../../../../assets/employees/chat/chatFunctions'

//translation
import { useTranslation } from 'react-i18next'



const MessagesSlider: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.sliders.messagesSlider' })

	const { messagesSlider, chatInfo } = useChatDesktop()

	const [messagesList, setMessagesList] = useState<messageType[]>([])
	const messagesPerPage = 10
	const [page, setPage] = useState(1)	


	useEffect(() => {
		const searchTimer = setTimeout(() => {

			const pinnedMessagesRequest = messagesSlider.sliderIsOpen == messagesSliderEnum.pinnedMessages ? true : undefined

			if(chatInfo.data.chatId){
				getMessages(chatInfo.data.chatId, 1, messagesPerPage, messagesSlider.inputValue, pinnedMessagesRequest)
					.then((newMessages) => {
						newMessages.reverse()
						setPage(1)
						setMessagesList([...newMessages])
					})
			}
			

		}, 500)
		return () => clearTimeout(searchTimer)

	}, [messagesSlider.inputValue, messagesSlider.sliderIsOpen])

	const addNewMessageCards = (e:React.UIEvent<HTMLDivElement>) => {
		if(chatInfo.data.chatId && e.currentTarget.scrollHeight - 10 <=  e.currentTarget.clientHeight + e.currentTarget.scrollTop){

			const pinnedMessagesRequest = messagesSlider.sliderIsOpen == messagesSliderEnum.pinnedMessages ? true : undefined

			getMessages(chatInfo.data.chatId, page+1, messagesPerPage, messagesSlider.inputValue, pinnedMessagesRequest)
				.then((newMessages) => {
					newMessages.reverse()
					if(newMessages.length > 0){
						setPage(page+1)
						setMessagesList([...messagesList, ...newMessages])
					}
				})
		}
	}

	return (
		<div  
			className={`search-messages-slider${messagesSlider.sliderIsOpen !== messagesSliderEnum.closed ? ' open-chat-info-slider': ''}`} 
			style={{width: messagesSlider.sliderIsOpen === messagesSliderEnum.closed ? '0': undefined}}>
			<div className="search-messages-slider-selectors-wrapper search-messages-slider-text">
				{
					messagesSlider.sliderIsOpen == messagesSliderEnum.pinnedMessages ? t('pinnedMessages') :
						messagesSlider.sliderIsOpen == messagesSliderEnum.searchedMessages ? t('foundMessages') : null
				}
			</div>
			<div className="search-items-container" onScroll = {(e)=> addNewMessageCards(e)}>
				{
					messagesList.map((messageCard: messageType)=>{
						return(
							<MessagesSliderCard message={messageCard} key={`messages-slider-message-${messageCard.messageId}`}/>
						)
					})
				}
			</div>
		</div>
	)
}

export default MessagesSlider