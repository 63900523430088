// styles and icons
import './chatAudioTimer.scss'

// react
import { FC } from 'react'

//other
import { formatTime } from '../../../../../../../assets/employees/chat/chatFunctions'

type chatTimerProps = {
  time: number
}

const ChatAudioTimer: FC<chatTimerProps> = ({time}) => {
	return <div className='audio-recorder-timer'>{formatTime(time)}</div>
}

export default ChatAudioTimer
