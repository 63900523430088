//styles and icons
import './fileSelectorModal.scss'
import { addFile } from '../../../../assets/employees/chat/chatIcons'

//react
import { FC, useState, DragEvent, ChangeEvent, useRef, useEffect } from 'react'

//components
import Modal from '../modal/modal'
import Button from '../../button/button'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setRestrictModalOpen } from '../../../../redux/general/modals'

//types
import { file } from '../../../../types/general/generalTypes'

//other
import { formatFiles } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'

type fileSelectorModalProps = {
	isOpen: boolean,
	setIsOpen: (value: boolean) => void,
	setFiles: (newFiles: file[]) => void
	supportedFormats?: {title: string, mime: string}[]
}

const FileSelectorModal: FC<fileSelectorModalProps> = ({ isOpen, setIsOpen, setFiles, supportedFormats }) => {
	const dispatch = useAppDispatch()

	const [ mobile, setMobile ] = useState(screen.width <= 768)

	const filesInputRef = useRef<HTMLInputElement | null>(null)

	const {t} = useTranslation('', { keyPrefix: 'general.modals.fileSelectorModal' })

	const maxFileSizeInBytes = 1024 * 1024 // 1 MB (you can adjust this value)

	const closeModal = () => setIsOpen(false)


	const settingFilesData = (files: File[]) => {
		for(let i=0; i<files.length; i++){
			if(files[i].size > maxFileSizeInBytes){
				closeModal()
				if (filesInputRef.current) {
					filesInputRef.current.value = ''
				}
				dispatch(setRestrictModalOpen(true))
				return
			}
		}

		formatFiles(files)
			.then((formattedFiles) => {
				setFiles(formattedFiles)
				if (filesInputRef.current) {
					filesInputRef.current.value = ''
				}
			})
	}

		
	const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
	}

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		const fileList = e.dataTransfer.files
		const files: File[] = []

		for(let i=0; i<fileList.length; i++) {
			files.push(fileList[i])
		}

		settingFilesData(files)
		
	}


	const handleBrowseFile = (e: React.ChangeEvent<HTMLInputElement> | Event) => {
		const fileList = (e.target as HTMLInputElement).files || []
		const files: File[] = []

		console.log(fileList)

		for(let i=0; i<fileList.length; i++) {
			files.push(fileList[i])
		}
		
		settingFilesData(files)
	}


	useEffect(() => {
		const handler = () => {
			setMobile(screen.width <= 768)
		}

		window.addEventListener('resize', handler)

		return () => {
			window.removeEventListener('resize', handler)
		}

	}, [])

	useEffect(() => {
		if(mobile && isOpen){
			const inputElement = document.createElement('input')
			inputElement.accept = supportedFormats?.map((format) => format.mime).join(', ') || ''
			inputElement.type = 'file'
			inputElement.style.display = 'none'
			inputElement.addEventListener('change', handleBrowseFile)
		
			// Append the input element to the body
			document.body.appendChild(inputElement)
		
			// Set the ref to the created input element
			filesInputRef.current = inputElement
		
			// Trigger the click event to open the file browser
			filesInputRef.current.click()
		
			// Cleanup function to remove the input element when the component unmounts
			return () => {
				inputElement.removeEventListener('change', handleBrowseFile)
				document.body.removeChild(inputElement)
			}
		}
	}, [isOpen])
	
	return (
		<Modal
			closeModal={closeModal}
			open={!mobile ? isOpen : false}
		>
			<div className='file-selector-container'
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>

				<div className='drop-file'>
					<div className="drop-file-icon">
						{addFile}
					</div>
					<div className="drop-file-text">
						{t('dropFiles')}
					</div>
					<div className="drop-file-format">
						<p>{supportedFormats?.map((format) => format.title).join(', ')}</p>
					</div>
				</div> 
				<div className="or">{t('or')}</div>
				<div className="browse-file-input-container">
					<Button text={t('browse')} active={false} onClick={() => { filesInputRef.current?.click() }}></Button>
					<input 
						ref={filesInputRef}
						type="file"
						id="file"
						multiple
						hidden
						name=""
						onChange={(e) => handleBrowseFile(e)}
						accept={supportedFormats?.map((format) => format.mime).join(', ')}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default FileSelectorModal