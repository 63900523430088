import { baseUrl } from '../../network'

// api.add_resource(CompanyTurnover, "/api/company/<int:company_id>/turnover")
// 	api.add_resource(CompanySkillsInDemand, "/api/company/<int:company_id>/skills-in-demand")
// 	api.add_resource(AverageTimeToHire, "/api/company/<int:company_id>/avg_time_to_hire")

export const employeeAgeStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/age/stats`
export const employeeGenderStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/gender/stats`
export const employeeEducationStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/education/stats`
export const employeeAttendanceStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/attendance`
export const manageEmployeeAttendanceUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/attendance`
export const employeeSatisfactionStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employee_satisfaction`
export const getCompanyChurnRateUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/churn`
export const employeePredictiveAnalysisUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/prediction`

export const companyTurnoverUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/turnover`
export const companySkillInDemandUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/skills-in-demand`
export const avarageTimeToHire = (companyId:number)=> baseUrl + `/api/company/${companyId}/avg_time_to_hire`
