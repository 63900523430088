//components
import Layout from '../../../components/general/layout/layout'
import HRAnalysis from '../../../components/employees/employeesStats/hrAnalysis/hrAnalysis'
import SkillsAnalysis from '../../../components/employees/employeesStats/skills/skills'
import Satisfaction from '../../../components/employees/employeesStats/satisfaction/satisfaction'

//react
import { useState } from 'react'

//types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

enum employeesStatsSection {
	Turnover = 'Turnover',
	Rating = 'Rating',
	Diversity = 'Diversity',
	PredictiveAnalysis = 'PredictiveAnalysis',
	HRAnalysis = 'HRAnalysis',
	Skills = 'Skills'
}
const EmployeesStats = () => {
	const {t} = useTranslation('', {keyPrefix: 'employees.statistics'})
	const {t:tSoon} = useTranslation('', {keyPrefix: 'general'})

	const [activeEmployeesStatsSection, setActiveEmployeesStatsSection] = useState(employeesStatsSection.Turnover)

	const titleUrls = [
		{
			url: '/ri-business/employees/statistics',
			title: t('employeesStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'employees_stats_info_title',
			description: 'employees_stats_info_description',
			link: '/ri-manual/employees'
		}],
		type: infoPopupTypes.INFO
	}

	const EmployeesStatsButtons: button[] = [
		{
			active: activeEmployeesStatsSection === employeesStatsSection.Turnover,
			text: t('satisfaction'),
			onClick: () => { setActiveEmployeesStatsSection(employeesStatsSection.Turnover) }
		},
		{
			active: activeEmployeesStatsSection === employeesStatsSection.HRAnalysis,
			text: t('HRanalysis'),
			onClick: () => { setActiveEmployeesStatsSection(employeesStatsSection.HRAnalysis) }
		},
		{
			active: activeEmployeesStatsSection === employeesStatsSection.Skills,
			text: t('skillsTab'),
			onClick: () => { setActiveEmployeesStatsSection(employeesStatsSection.Skills) }
		}
	]

	const renderTab = () => {
		switch (activeEmployeesStatsSection) {
		case employeesStatsSection.Turnover:
			return <Satisfaction />
		case employeesStatsSection.HRAnalysis:
			return <HRAnalysis />
		case employeesStatsSection.Skills:
			return <SkillsAnalysis />
		default:
			return null
		}
	}
	return (
		// <Layout
		// 	header={{
		// 		avatar: true,
		// 	}}
		// 	titleUrls={titleUrls}
		// 	titlePopup={infoPopup}
		// >
		// 	<p style={{
		// 		display: 'flex',
		// 		justifyContent:'center',
		// 		alignItems:'center',
		// 		height: '70vh'
		// 	}}>
		// 		{tSoon('comingSoon')}
		// 	</p>
		// </Layout>
		<Layout
			header={{
				avatar: true,
			}}
			tabButtons={EmployeesStatsButtons}
			titleUrls={titleUrls}
		>
			{renderTab()}
		</Layout>
	)
}

export default EmployeesStats