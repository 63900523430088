// react
import { FC, createContext, useContext, useReducer } from 'react'

//types
import { messageType, messagesSliderEnum } from '../../../../../types/employees/chatTypes'
import { chatDesktopActionTypes, currentChatActionType, contextMenuType, chatStatusType, chatDesktopState, chatDataType } from './types'

// other
import { initialState, chatDesktopReducer } from './chatDesktopReducer'
import { file } from '../../../../../types/general/generalTypes'

type chatDesktopContextType = chatDesktopState & {
    setChatInfoSliderIsOpen: ( payload: boolean ) => void
    setChatData: ( payload: chatDataType ) => void
    setChatStatus: ( payload: chatStatusType ) => void
    setCurrentChatAction: ( payload: currentChatActionType ) => void
    setContextMenu: ( payload: contextMenuType ) => void
    setInputBannerIsOpen: ( payload: boolean) => void
    setChatInputValue: ( payload: string ) => void
	setAttachModalIsOpen: (payload: boolean) => void
    setAttachedFiles: ( payload: file[] ) => void
    setMessages: (payload: messageType[] ) => void
    setMessagesPage: ( payload: number ) => void
    setLastPage: ( payload: boolean ) => void
    setMessagesSliderIsOpen: ( payload: messagesSliderEnum ) => void
	setMessagesSliderInputValue:  ( payload: string ) => void
    setChatFilesSliderIsOpen: ( payload: boolean ) => void
    setChatFiles: ( payload: file[] ) => void
	setIsVideoInitialized: ( payload: boolean ) => void 
	setIsVideoRecording: ( payload: boolean ) => void
	setVideoBlob: ( payload: Blob | null ) => void
	setVideoTimer: ( payload: number ) => void
	setForwardModalIsOpen: ( payload: boolean ) => void
	setForwardMessageId: ( payload: null | number ) => void
	setDeleteModalIsOpen: ( payload: boolean ) => void
	setDeletedMessageId: ( payload: null | number ) => void
	setScrollToTheLastMessage: ( payload: boolean ) => void
}

export const chatDesktopContext = createContext<chatDesktopContextType>(initialState as chatDesktopContextType)

type chatDesktopProviderProps = {
    children: React.ReactNode
}

export const ChatDesktopProvider: FC<chatDesktopProviderProps> = ({ children }) => {

	const [ state, dispatch ] = useReducer(chatDesktopReducer, initialState)

	const setChatInfoSliderIsOpen = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatInfoSliderIsOpen,
			payload: payload
		})
	}

	const setChatData = ( payload: chatDataType ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatData,
			payload: payload
		})
	}

	const setChatStatus = ( payload: chatStatusType ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatStatus,
			payload: payload
		})
	}

	const setCurrentChatAction = ( payload: currentChatActionType ) => {
		dispatch({
			type: chatDesktopActionTypes.setCurrentChatAction,
			payload: payload
		})
	}

	const setContextMenu = ( payload: contextMenuType ) => {
		dispatch({
			type: chatDesktopActionTypes.setContextMenu,
			payload: payload
		})
	}

	const setInputBannerIsOpen = ( payload: boolean) => {
		dispatch({
			type: chatDesktopActionTypes.setInputBannerIsOpen,
			payload: payload
		})
	}

	const setChatInputValue = ( payload: string ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatInputValue,
			payload: payload
		})
	}

	const setAttachModalIsOpen = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setAttachModalIsOpen,
			payload: payload
		})
	}

	const setAttachedFiles = ( payload: file[] ) => {
		dispatch({
			type: chatDesktopActionTypes.setAttachedFiles,
			payload: payload
		})
	}
    
	const setMessages = ( payload: messageType[] ) => {
		dispatch({
			type: chatDesktopActionTypes.setMessages,
			payload: payload
		})
	}

	const setMessagesPage = ( payload: number ) => {
		dispatch({
			type: chatDesktopActionTypes.setMessagesPage,
			payload: payload
		})
	}

	const setLastPage = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setLastPage,
			payload: payload
		})
	}
    
	const setMessagesSliderIsOpen = ( payload: messagesSliderEnum ) => {
		dispatch({
			type: chatDesktopActionTypes.setMessagesSliderIsOpen,
			payload: payload
		})
	}
	
	const setMessagesSliderInputValue = ( payload: string ) => {
		dispatch({
			type: chatDesktopActionTypes.setMessagesSliderInputValue,
			payload: payload
		})
	}
    
	const setChatFilesSliderIsOpen = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatFilesSliderIsOpen,
			payload: payload
		})
	}

	const setChatFiles = ( payload: file[] ) => {
		dispatch({
			type: chatDesktopActionTypes.setChatFiles,
			payload: payload
		})
	}

	const setIsVideoInitialized = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setIsVideoInitialized,
			payload: payload
		})
	}

	const setIsVideoRecording = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setIsVideoRecording,
			payload: payload
		})
	}

	const setVideoBlob = ( payload: Blob | null ) => {
		dispatch({
			type: chatDesktopActionTypes.setVideoBlob,
			payload: payload
		})
	}

	const setVideoTimer = ( payload: number ) => {
		dispatch({
			type: chatDesktopActionTypes.setVideoTimer,
			payload: payload
		})
	}

	const setForwardModalIsOpen = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setForwardModalIsOpen,
			payload: payload
		})
	}

	const setForwardMessageId = ( payload: null | number ) => {
		dispatch({
			type: chatDesktopActionTypes.setForwardMessageId,
			payload: payload
		})
	}

	const setDeleteModalIsOpen = ( payload: boolean ) => {
		dispatch({
			type: chatDesktopActionTypes.setDeleteModalIsOpen,
			payload: payload
		})
	}

	const setDeletedMessageId = ( payload: null | number ) => {
		dispatch({
			type: chatDesktopActionTypes.setDeleteMessageId,
			payload: payload
		})
	}

	const setScrollToTheLastMessage = ( payload: boolean ) => [
		dispatch({
			type: chatDesktopActionTypes.setScrollToTheLastMessage,
			payload: payload
		})
	]

	const value = {
		chatInfo: state.chatInfo,
		setChatInfoSliderIsOpen,
		setChatData,
		setChatStatus,
		currentChatAction: state.currentChatAction,
		setCurrentChatAction,
		contextMenu: state.contextMenu,
		setContextMenu,
		inputBanner: state.inputBanner,
		setInputBannerIsOpen,
		chatInputValue: state.chatInputValue,
		setChatInputValue,
		attachFiles: state.attachFiles,
		setAttachModalIsOpen,
		setAttachedFiles,
		messages: state.messages,
		setMessages,
		setMessagesPage,
		setLastPage,
		messagesSlider: state.messagesSlider,
		setMessagesSliderIsOpen,
		setMessagesSliderInputValue,
		chatFiles: state.chatFiles,
		setChatFilesSliderIsOpen,
		setChatFiles,
		videoRecording: state.videoRecording,
		setIsVideoInitialized,
		setIsVideoRecording,
		setVideoBlob,
		setVideoTimer,
		forwardMessage: state.forwardMessage,
		setForwardModalIsOpen,
		setForwardMessageId,
		deleteMessage: state.deleteMessage,
		setDeleteModalIsOpen,
		setDeletedMessageId,
		scrollToTheLastMessage: state.scrollToTheLastMessage,
		setScrollToTheLastMessage
	}

	return (
		<chatDesktopContext.Provider
			value={value}
		>{children}</chatDesktopContext.Provider>
	)
}

const useChatDesktop = () => {
	const context = useContext(chatDesktopContext)

	if(context === undefined){
		throw new Error('useChatDesktop must be used within chatDesktop')
	}

	return context
}

export default useChatDesktop