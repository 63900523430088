// styles
import './manualItem.scss'

// react
import {FC} from 'react'
import { Link } from 'react-router-dom'

// components
import Processing from '../processing/processing'

//types
import { manualData } from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

const ManualItem: FC<manualData> = ({ title, images, text, process, link }) => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.setUp' })
	
	return (
		<div className="manual-item-wrapper">
			{ title && <h1 className="manual-item-heading">{title}</h1>}
            
			<div className="manual-item-content">
				{ (images && images.length <= 3) &&
					(<div className="manual-item-images-wrapper">
						{
							images.map((image, index)=> (
								<div className="manual-item-images-img" key={index}>
									<img src={image} alt='manual-image'/>
								</div>
							))
						}
					</div>)
				}
                
				<div className="manual-item-text">
					{ text && <p>{text}</p> }
					{link ?
						<Link to={link}>
							<p className='manual-link-text'>{t('link')}</p>
						</Link> : null
					}
				</div>
			</div>

			{
				process && (<Processing content={process}/>)
			}
		</div>
	)
}

export default ManualItem
