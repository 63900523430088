// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../../components/general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'

// types
import { currency, infoDetailsTableDataItem, moneyValue } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleAccountUrl } from '../../../../utils/urls/finance/account/account'

// other
import { formatDate, formatStringIntoTwoDigitsFloat } from '../../../../assets/general/generalFunctions'

//translations
import { useTranslation } from 'react-i18next'

type accountDetailsProps = {
	setAccountName: (value: string) => void
}

type details = {
	name: string
	bank: string
	accountNumber: string
	balance: moneyValue[],
	description: string
	lastAction: number
	editAccess: boolean
}
const AccountDetails: FC<accountDetailsProps> = ({ setAccountName }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.account.accountDetails' })

	const [accountDetailsEdit, setAccountDetailsEdit] = useState<boolean>(false)
	const [details, setDetails] = useState<details>({
		name: '',
		bank: '',
		accountNumber: '',
		balance: [{
			amount: 0,
			currency: currency.UAH
		},
		{
			amount: 0,
			currency: currency.USD
		},
		{
			amount: 0,
			currency: currency.EUR
		}],
		description: '',
		lastAction: 0,
		editAccess: false
	})

	const amountInputHandleUAH = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.balance]
		updatedValues[0] = { ...updatedValues[0], amount: numberValue }
		setDetails({ ...details, balance: updatedValues })
		
		setAccountDetailsEdit(JSON.stringify(details.balance)!==JSON.stringify(updatedValues))
	}
	const amountInputHandleUSD = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.balance]
		updatedValues[1] = { ...updatedValues[1], amount: numberValue }
		setDetails({ ...details, balance: updatedValues })

		setAccountDetailsEdit(JSON.stringify(details.balance)!==JSON.stringify(updatedValues))
	}
	const amountInputHandleEUR = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.balance]
		updatedValues[2] = { ...updatedValues[2], amount: numberValue }
		setDetails({ ...details, balance: updatedValues })

		setAccountDetailsEdit(JSON.stringify(details.balance)!==JSON.stringify(updatedValues))
	}

	const { accountId } = useParams()

	const infoDetailsTableData: infoDetailsTableDataItem[] =
		[
			{
				title: t('accountNumber'), data: {
					inputField: {
						value: details.accountNumber,
						onChange: (e) => {
							setDetails({ ...details, accountNumber: e.target.value })
							setAccountDetailsEdit(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('name'), data: {
					inputField: {
						value: details.name,
						onChange: (e) => {
							setDetails({ ...details, name: e.target.value })
							setAccountName(e.target.value)
							setAccountDetailsEdit(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('bank'), data: {
					inputField: {
						value: details.bank,
						onChange: (e) => {
							setDetails({ ...details, bank: e.target.value })
							setAccountDetailsEdit(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('lastAction'),
				data: {
					inputField: {
						value: formatDate(new Date(details.lastAction * 1000), true, true),
						disabled: true
					}
				}
			},
			{
				title: t('description'), data: {
					textArea: {
						disabled: !details.editAccess,
						value: details.description,
						setValue: (value) => {
							setDetails({ ...details, description: value })
							setAccountDetailsEdit(details.description!==value)
						}
					}
				}
			},
			{
				title: `${t('total')} UAH`, data: {
					floatInput: {
						amountInputHandle: amountInputHandleUAH,
						value: `${details.balance[0].amount}`,
						disabled: !details.editAccess
					}
				},
			},
			{
				title: `${t('total')} USD`, data: {
					floatInput: {
						amountInputHandle: amountInputHandleUSD,
						value: `${details.balance[1].amount}`,
						disabled: !details.editAccess
					}
				},
			},
			{
				title: `${t('total')} EUR`, data: {
					floatInput: {
						amountInputHandle: amountInputHandleEUR,
						value: `${details.balance[2].amount}`,
						disabled: !details.editAccess
					}
				},
			}
		]


	const loadData = async () => {
		const { result } = await authorizedRequest(singleAccountUrl(Number(accountId)), 'GET')
		setDetails({
			name: result.name,
			bank: result.bank,
			accountNumber: result.account_number,
			balance: result.balance,
			description: result.description,
			lastAction: result.last_action_date,
			editAccess: result.update_access
		})
		setAccountName(result.name)
	}

	const editData = async () => {
		if (Number(accountId)) {
			const new_balances = {
				balance_uah: 0,
				balance_usd: 0,
				balance_eur: 0
			}

			details.balance.forEach((balanceItem) => {
				if(balanceItem.currency === currency.UAH){
					new_balances.balance_uah = balanceItem.amount
				}else if(balanceItem.currency === currency.USD){
					new_balances.balance_usd = balanceItem.amount
				}else if(balanceItem.currency === currency.EUR){
					new_balances.balance_eur = balanceItem.amount
				}
			})

			const { result } = await authorizedRequest(singleAccountUrl(Number(accountId)), 'PUT', 'accessToken', {
				id: Number(accountId),
				account_number: details.accountNumber,
				name: details.name,
				bank: details.bank,
				...new_balances,
				description: details.description,
			})
		}
		setAccountDetailsEdit(false)
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (accountDetailsEdit) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])


	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTableData} />
			{!details && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default AccountDetails