//styles
import './languageSelector.scss'

//network
import { authorizedRequest } from '../../../utils/queries'
import { profileEditResourceUrl } from '../../../utils/urls/general/profile/profile'

//redux
import { useAppSelector, useAppDispatch } from '../../../customHooks/redux'
import { setLanguage } from '../../../redux/general/general'

const LanguageSelector = () => {
	const languages: ('ua' | 'en')[] = [ 'ua', 'en' ]

	const { language } = useAppSelector((state) => state.general)
    
	const dispatch = useAppDispatch()

	return (
		<div className='language-selector'>
			{
				languages.map((lang) => {
					return (
						<div className={`language-selector-button ${lang === language ? 'active' : ''}`} onClick={() => {
							localStorage.setItem('lang', `${lang}`)
							dispatch(setLanguage(lang))

							authorizedRequest(profileEditResourceUrl, 'PUT', 'accessToken', {language: lang})
						}} >
							<p>{lang}</p>
						</div>
					)
				})
			}
		</div>
	)
}

export default LanguageSelector