//react
import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//types
import { bill } from '../../../../../types/finance/general'
import { detailedErrorObject } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import {billsPayBillResourceUrl} from '../../../../../utils/urls/finance/bill/bills'

//redux
import {useAppDispatch, useAppSelector} from '../../../../../customHooks/redux'
import {setPayBillId, setPayBillModalIsOpen} from '../../../../../redux/general/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

type payBillConfirmationModalProps = {
    bills?: bill[]
    setBills?: (value: bill[]) => void
}

const PayBillConfirmationModal: FC<payBillConfirmationModalProps> = ({bills, setBills}) => {
	const { t } = useTranslation('', {keyPrefix:'general.modals.bill.payBillModal'})

	const { modalIsOpen, billId } = useAppSelector((state) => state.generalModals.payBillModal)

	const [ description, setDescription ] = useState<string>()
	const [ buttons, setButtons ] = useState<{
		text: string
		onClickHandler: () => void
			}[]>([])
	const [ errorObjects, setErrorsObjects ] = useState<detailedErrorObject[]>() 
	const [ loading, setLoading ] = useState(false)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const closeModal = (): void => {
		dispatch(setPayBillModalIsOpen(false))
		dispatch(setPayBillId(null))
	}

	useEffect(() => {
		if(billId){
			setLoading(true)
			setDescription(undefined)
			setButtons([])
			setErrorsObjects(undefined)
			authorizedRequest(billsPayBillResourceUrl(billId), 'GET')
				.then((response) => {
					const { result } = response

					setDescription(t(result.description))

					if(result.objects.length > 0){
						const buttons = [{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]
						setButtons([...buttons])
						setErrorsObjects(result.objects.map(formatDetailedErrorObjectResponse))
					}

					setLoading(false)
				})
		}
	}, [modalIsOpen])
    
	const handleSubmit = ()=> {
		if(billId){
			setLoading(true)
			authorizedRequest(billsPayBillResourceUrl(billId), 'PUT', 'accessToken')
				.then(({result}) => {
					const editedBill: bill = result

					if (bills && setBills) {
						const updatedBills = bills.map((prevBill: bill) => {
							if(prevBill.id === billId) {
								return editedBill
							}
							return prevBill
						})
						setBills(updatedBills)
					} else {
						navigate(-1)
					}

					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('title')} 
			buttons={buttons}
			isOpen={modalIsOpen}
			description={description}
			errorObjects={errorObjects}
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default PayBillConfirmationModal
