//styles and icons
import './chatInfoSlider.scss'
import { groupChatBasicAvatar, pinMessageIcon, chatSearchSvg } from '../../../../../../assets/employees/chat/chatIcons'
import { basicUserAvatar, close } from '../../../../../../assets/general/generalIcons'
import { arrowIcon } from '../../../../../../assets/general/generalIcons'

//react
import { FC, useState } from 'react'

//components
import Avatar from '../../../../avatar/avatar'
import InputField from '../../../../inputField/inputField'
import SettingField from '../../../../settingField/settingField'
import SearchChatMembers from './searchChatMembers/searchChatMembers'


//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { messagesSliderEnum } from '../../../../../../types/employees/chatTypes'
import { settingFieldContent, settingFieldContentType } from '../../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

const ChatInfoSlider: FC = () => {

	const { chatInfo, setChatInfoSliderIsOpen, setChatFilesSliderIsOpen, setMessagesSliderIsOpen } = useChatDesktop()
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.sliders.chatInfoSlider' })

	const [inputValue, setInputValue] = useState('')
	const [searchMembersToggle, setSearchMembersToggle] = useState(false)
	const [membersListLenght, setMembersListLenght] = useState(0)

	const [notificationsAllowed, setNotificationsAllowed] = useState(false)

	const openPinnedMessagesSlider = () =>{
		setChatInfoSliderIsOpen(false)
		setMessagesSliderIsOpen(messagesSliderEnum.pinnedMessages)
	}

	const switchNotificationOfMessages : settingFieldContent[]  = [
		{
			type: settingFieldContentType.SWITCH,
			isChecked: notificationsAllowed,
			onClick: () => setNotificationsAllowed(!notificationsAllowed),
		}
	]

	return (
		<div className={`chat-info-slider${chatInfo.sliderIsOpen ? ' open-chat-info-slider': ''}`} style={{width: !chatInfo.sliderIsOpen ? '0': undefined }}>
			<div className="chat-info-slider-container chat-info-slider-header-container">
				<div className="chat-info-slider-header-avatar">
					{
						chatInfo.data.avatar ? <Avatar blobAvatar={chatInfo.data.avatar} /> : chatInfo.data.group ? groupChatBasicAvatar : basicUserAvatar
					}
				</div>
				<div className="chat-info-slider-header-text-container">
					<div className="chat-info-slider-header-title">
						<InputField
							value={chatInfo.data.name}
							onChange={() => {
								console.log('yes')
							}}
							disabled={!chatInfo.data.group}
						/>
					</div>
					<div className="chat-info-slider-header-subtitle">
						<InputField
							value={chatInfo.data.description}
							onChange={() => {
								console.log('yes')
							}}
							disabled={!chatInfo.data.group}
						/>
					</div>
				</div>
			</div>

			<div className="chat-info-slider-container" onClick={()=>{openPinnedMessagesSlider()}}>
				<div className="chat-info-pinned-messages-container">
					<div className="pinned-messages-text-container">
						{pinMessageIcon}
						<p className='chat-info-slider-text'>{t('pinnedMessages')}</p>
					</div>
					{arrowIcon}
				</div>
			</div>

			<div className="chat-info-slider-container">
				<SettingField label={t('notificationOfMessages')} content={switchNotificationOfMessages} />
			</div>

			<div className="chat-info-slider-container">
				<div className="chat-info-slider-more-data-container more-media-data-container" onClick={() => {
					setChatInfoSliderIsOpen(false)
					setChatFilesSliderIsOpen(true)
				}}>
					<p className="chat-info-slider-text">{t('media')}</p>
					<div className="chat-info-slider-more-data-arrow-container">
						<div className="chat-info-slider-more-data-arrow">
							{arrowIcon}
						</div>
					</div>
				</div>
			</div>

			<div className="chat-info-slider-container">
				<div className="chat-info-slider-participants-header">
					<p className="chat-info-slider-text">{t('peopleAmount', {count: membersListLenght})}</p>
					<div className='search-massage-input-container' style={{width : !searchMembersToggle ? '0': undefined, padding: !searchMembersToggle ? '0': undefined}}>
						<input type="text" placeholder={'search'} value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} className='chat-bar-header-input'/>
						<div className='search-massage-input-cross' onClick={() => {setSearchMembersToggle(!searchMembersToggle), setInputValue('')}}>{close}</div>
					</div>
					<div className="search-massage-button" onClick={()=>{setSearchMembersToggle(!searchMembersToggle)}} style={{
						width: searchMembersToggle ? '0' : undefined
					}}>{chatSearchSvg}</div>
				</div>
				<SearchChatMembers inputValue={inputValue} setMembersListLenght={(value: number)=>{setMembersListLenght(value)}}/>
			</div>

		</div>
	)
}

export default ChatInfoSlider