import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type customerModalsState = {
	customerOrders: {
		createCustomerOrderModal: {
			modalIsOpen: boolean
		}
	}
	customerDocuments: {
		deleteCustomerDocumentModal: {
			modalIsOpen: boolean
			documentId: number | null
		},
		importCustomerDocumentFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
	}
	customerAddresses: {
		createCustomerAddressModal: {
			modalIsOpen: boolean
		}
		deleteCustomerAddressModal: {
			modalIsOpen: boolean
			addressId: number | null
		}
	}
}

const initialState: customerModalsState = {
	customerOrders: {
		createCustomerOrderModal: {
			modalIsOpen: false
		}
	},
	customerDocuments: {
		deleteCustomerDocumentModal: {
			modalIsOpen: false,
			documentId: null
		},
		importCustomerDocumentFileSelectorModal: {
			modalIsOpen: false,
			file: null
		}
	},
	customerAddresses: {
		createCustomerAddressModal: {
			modalIsOpen: false
		},
		deleteCustomerAddressModal: {
			modalIsOpen: false,
			addressId: null
		}
	}
}

export const customerModalsSlice = createSlice({
	name: 'customerModalsSlice',
	initialState,
	reducers: {
		setCreateCustomerOrderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.customerOrders.createCustomerOrderModal.modalIsOpen = action.payload
		},

		setDeleteCustomerDocumentModalIsOpen:(state, action: PayloadAction<boolean>) => {
			state.customerDocuments.deleteCustomerDocumentModal.modalIsOpen = action.payload
		},
		setDeleteCustomerDocumentId: (state, action: PayloadAction<number | null>) => {
			state.customerDocuments.deleteCustomerDocumentModal.documentId = action.payload
		},

		setImportCustomerDocumentFileSelectorModalIsOpen : (state, action: PayloadAction<boolean>) => {
			state.customerDocuments.importCustomerDocumentFileSelectorModal.modalIsOpen = action.payload
		},
		setImportCustomerDocumentFileSelectorFile: (state, action: PayloadAction<file | null>) => {
			state.customerDocuments.importCustomerDocumentFileSelectorModal.file = action.payload
		},

		setCreateCustomerAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.customerAddresses.createCustomerAddressModal.modalIsOpen = action.payload
		},
		setDeleteCustomerAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.customerAddresses.deleteCustomerAddressModal.modalIsOpen = action.payload
		},
		setDeleteCustomerAddressId: (state, action: PayloadAction<number | null>) => {
			state.customerAddresses.deleteCustomerAddressModal.addressId = action.payload
		}
	}
})

export const {
	setCreateCustomerOrderModalIsOpen,

	setDeleteCustomerDocumentModalIsOpen,
	setDeleteCustomerDocumentId,

	setImportCustomerDocumentFileSelectorModalIsOpen,
	setImportCustomerDocumentFileSelectorFile,

	setCreateCustomerAddressModalIsOpen,
	setDeleteCustomerAddressModalIsOpen,
	setDeleteCustomerAddressId
} = customerModalsSlice.actions

export default customerModalsSlice.reducer
