//styles & icons
import './resetPassword.scss'

//react
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

//components
import InputField from '../../../components/general/inputField/inputField'
import Button from '../../../components/general/button/button'
import AuthenticationHeader from '../../../components/authentication/general/authenticationHeader/authenticationHeader'
import Loading from '../../../components/general/loading/loading'

//network
import { unauthorizedRequest } from '../../../utils/queries'
import { verifyResetPasswordUrl } from '../../../utils/old_urls/resetPassword'

//translation
import { useTranslation } from 'react-i18next'

type resetPasswordDataTypes = {
	password: string
	confirmPassword: string
}

type formErrors = {
	password?: {
		error: string;
		passwordInfo?: string[]
	}
	confirmPassword?: string
	verifed?: string
}

const resetPassword = () => {
	const { t } = useTranslation('', { keyPrefix: 'authentication.resetPassword' })

	const [loading, setLoading] = useState(false)

	const [resetPasswordData, setResetPasswordData] = useState<resetPasswordDataTypes>({
		password: '',
		confirmPassword: ''
	})

	const [passwordVisible, setPasswordVisible] = useState(false)
	const [errors, setErrors] = useState<formErrors>({})
	const navigate = useNavigate()

	const { token } = useParams()


	const checkErrors = () => {
		let result = true

		if (resetPasswordData.password === '' || resetPasswordData.password.length < 8 || /[A-Z]/g.test(resetPasswordData.password) === false || /[0-9]/g.test(resetPasswordData.password) === false) {
			setErrors(prevErrors => ({ ...prevErrors, password: { error: t('pleaseEnterYourPassword'), passwordInfo: [t('thePasswordHasToBe'), t('thePasswordHasToInclude')] } }))
			result = false
		}
		if (resetPasswordData.confirmPassword === '' || resetPasswordData.password !== resetPasswordData.confirmPassword) {
			setErrors(prevErrors => ({ ...prevErrors, confirmPassword: t('pleaseMakeSureYourPasswordMatch') }))
			result = false
		}

		return result
	}
	const handleSubmit = async () => {

		if (checkErrors()) {
			setLoading(true)
			try {
				const response = await unauthorizedRequest(verifyResetPasswordUrl, 'POST', { token: token, new_password: resetPasswordData.password, confirm_password: resetPasswordData.confirmPassword })
				setLoading(false)
				navigate('/login')
			} finally {
				setLoading(false)
			}
			// console.log('verify')
		}
	}


	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		setResetPasswordData({
			...resetPasswordData,
			[e.currentTarget.name]: e.currentTarget.value
		})
	}

	useEffect(() => {
		setErrors({})
	}, [resetPasswordData])

	return (
		<div className="reset-password">
			<form onSubmit={(e) => {
				e.preventDefault()
				handleSubmit()
			}} className="reset-password-form">
				<div className="reset-password-wrraper">

					<AuthenticationHeader
						title={t('changePassword')}
						subtitle={{
							text: t('doYouWantToGoBack'),
							link: {
								text: t('return'),
								url: '/reset-password-email'
							}
						}}
					/>

					<div className="reset-password-info">
						<div className="fields-container">
							<InputField
								type={passwordVisible ? 'text' : 'password'}
								name='password'
								placeholder={t('password')}
								minLength={6}
								value={resetPasswordData.password}
								onChange={handleChange}
								error={errors?.password?.error}
								errorInfoList={errors?.password?.passwordInfo}
								passwordVisibility={
									{
										onClick: () => { setPasswordVisible(!passwordVisible) },
										visible: passwordVisible
									}
								}
							/>

							<InputField
								minLength={6}
								name='confirmPassword'
								type={passwordVisible ? 'text' : 'password'}
								placeholder={t('repeatPassword')}
								value={resetPasswordData.confirmPassword}
								onChange={handleChange}
								error={errors.confirmPassword}
							/>

						</div>
						<Button active={true} text={t('create')} onClick={handleSubmit} />

					</div>
				</div>
			</form>
			{loading ? <Loading /> : null}
		</div>
	)
}

export default resetPassword
