//styles and icons
import './messageArea.scss'

//react
import { FC, useEffect, useState, useRef } from 'react'

//components
import ChatMessages from './chatMessage/chatMessage'
import Loading from '../../../loading/loading'

//types
import { messageType, messageStatus, chatMessagesResponse} from '../../../../../types/employees/chatTypes'

//context
import useChatDesktop from '../context/chatDesktopContext'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { chatMessageUrl,  getLiveChatUrl } from '../../../../../utils/old_urls/employees/chatsUrls'

//translation
import { useTranslation } from 'react-i18next'

//other
import { getMessages, formatMessage } from '../../../../../assets/employees/chat/chatFunctions'

// Let the server knows that the passed messagedIds has been delievered
const sendDeliveredMessages = (messagesIdToDeliver:number[],chatId:number) =>{
	if(messagesIdToDeliver.length === 0) return
	authorizedRequest(getLiveChatUrl(chatId),'PUT','accessToken',{
		message_ids:messagesIdToDeliver
	})
}

// Handle mutating New, Edited & Read Messages 
const liveUpdateEditedMessages = (editedMessages:chatMessagesResponse[], currentMessages: messageType[],chatId:number) => {
	const messagesIdToDeliver: number[] = []

	editedMessages.forEach((message)=>{
					
		const messageIndex = currentMessages.findIndex((item) => item.messageId === message.message_id)

		if(messageIndex !== -1){
			const editedMessage = formatMessage(message)
			currentMessages[messageIndex] = editedMessage
			messagesIdToDeliver.push(editedMessage.messageId)
		}

	})

	sendDeliveredMessages(messagesIdToDeliver,chatId)
	return [...currentMessages]
}
const liveUpdateNewMessages = (newMessages:chatMessagesResponse[], currentMessages: messageType[], chatId: number)=>{
	const updatedMessagesId: number[] = []

	newMessages.forEach((message)=>{
		const formattedNewMessage = formatMessage(message)
		if(currentMessages.findIndex(message => message.messageId === formattedNewMessage.messageId) !== -1) 
			return
		currentMessages.push(formattedNewMessage)
		updatedMessagesId.push(formattedNewMessage.messageId)
	})

	sendDeliveredMessages(updatedMessagesId, chatId)
	return [...currentMessages]
}
const liveUpdateReadMessages = (newMessages:chatMessagesResponse[], currentMessages: messageType[], chatId:number)=>{
	const updatedMessagesId:number[] = []
	newMessages.forEach((newMessage)=>{
		const readMessageId = currentMessages.findIndex((message) => message.messageId === newMessage.message_id) 
		if(readMessageId !== -1){
			currentMessages[readMessageId].status = messageStatus.READ
			updatedMessagesId.push(newMessage.message_id)
		}
	})

	sendDeliveredMessages(updatedMessagesId,chatId)
	return [...currentMessages]
}


const MessageArea: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.messageArea' })

	const { chatInfo, messages, setMessages, setMessagesPage, setLastPage, contextMenu, scrollToTheLastMessage, setScrollToTheLastMessage } = useChatDesktop()

	const messageAreaRef = useRef<null | HTMLDivElement>(null)
	const [ readMessagesWaitList, setReadMessagesWaitList ] = useState<number[]>([])

	const [ loading, setLoading ] = useState(false)
	const [ scrollPosition, setScrollPosition ] = useState(0)
	const [ messageAreaScrollHeight, setMessageAreaScrollHeight ] = useState(0)
	
	const [ dateText, setDateText ] = useState('')

	const [ date, setDate ] = useState<Date | null>(null)


	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december')
	]

	const scrollDown = () => {
		if(messageAreaRef.current){
			messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight
		}
	}
  
	useEffect(() => {
		if(date){
			const currentDate = new Date()
      
			if(currentDate.getDate() === date.getDate() && currentDate.getMonth() === date.getMonth() && currentDate.getFullYear() === date.getFullYear()){
				setDateText('Today')
			}else{
				setDateText(`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear() !== currentDate.getFullYear() ? date.getFullYear() : ''}`)
			}
		}
	}, [date])

	useEffect(() => {
		const options = {
			root: messageAreaRef.current,
			rootMargin: '0px',
			threshold: 0.5
		}
	
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const messageId = parseInt(entry.target.getAttribute('data-message-id') || '-1')

					const message = messages.messages.find((message) => message.messageId === messageId)

					if(message && message.status !== messageStatus.READ && readMessagesWaitList.indexOf(messageId) === -1){
						readMessagesWaitList.push(messageId)
						setReadMessagesWaitList([...readMessagesWaitList])
					}
				}
			})
		}, options)
	
		if (messageAreaRef.current) {
			const messageElements = messageAreaRef.current.querySelectorAll('.stranger-message-container[data-message-id]')
			messageElements.forEach((element) => {
				observer.observe(element)
			})
		}
		
	}, [messages.messages])

	// sends a list of messages that were read by the user
	useEffect(() => {
		if(chatInfo.data.chatId && readMessagesWaitList.length>0){
			authorizedRequest(chatMessageUrl(chatInfo.data.chatId), 'PATCH', 'accessToken', {message_ids: readMessagesWaitList})
				.then((response) => {
					setReadMessagesWaitList([])
				})
		}

	}, [readMessagesWaitList])

	useEffect(() => {
		setLoading(true)
		setLastPage(false)

		if(chatInfo.data.chatId){
			getMessages(chatInfo.data.chatId, 1, 10)
				.then((newMessages) => {
					setMessagesPage(1)
					setMessages([...newMessages])
					setLoading(false)
					setScrollToTheLastMessage(true)		
				})
		}
	}, [chatInfo.data.chatId])

	const scrollHandler = () => {
		const messageArea = messageAreaRef.current


		if(messageArea){
			const scrollPosition = messageArea.scrollTop

			setScrollPosition(scrollPosition)
		}
	}

	useEffect(() => {
		const messageArea = messageAreaRef.current

		if(messageArea){

			setMessageAreaScrollHeight(messageArea.scrollHeight)
			// load more messages
			if(scrollPosition < 10 && chatInfo.data.chatId && !loading && !messages.lastPage && messages.messages.length > 0){
				setLoading(true)
				getMessages(chatInfo.data.chatId, messages.page+1, 10)
					.then((newMessages) => {
						if(newMessages.length > 0){
							setMessages([...newMessages, ...messages.messages])
							setMessagesPage(messages.page+1)
						}else{
							setLastPage(true)
						}

						setLoading(false)
					})
			}

			//get the first message in view for date
		
			const messageElements = messageArea.querySelectorAll('.stranger-message-container[data-message-id]')

			for (let i = 0; i < messageElements.length; i++) {
				const element = messageElements[i] as HTMLElement
		
				if (element.offsetTop >= scrollPosition && element.offsetTop + element.clientHeight <= scrollPosition + messageArea.clientHeight) {
					const firstVisibleMessage = messages.messages.find((message) => message.messageId === parseInt(element.dataset.messageId || '-1'))
					setDate(firstVisibleMessage?.date || null)
					break
				}
			}
		}

		// if the user doesn't scroll the date is going to disappear
		const dateTimeout = setTimeout(() => {
			setDate(null)
		}, 3000)

		return () => {
			clearTimeout(dateTimeout)
		}

	}, [scrollPosition])

	// Live update messages
	useEffect(()=>{
		const messageArea = messageAreaRef.current

		const interval = setInterval(()=>{
			if(chatInfo.data.chatId && messageArea){
				authorizedRequest(getLiveChatUrl(chatInfo.data.chatId), 'GET')
					.then((response) => {

						const { result } = response

						const editedMessages:chatMessagesResponse[] = result.edited_messages[0] ?? []
						const readMessages:chatMessagesResponse[] = result.read_messages[0] ?? []
						const newMessages:chatMessagesResponse[] = result.new_messages[0] ?? []

						let currentMessages = messages.messages

						if(editedMessages.length !== 0 ){
							currentMessages = liveUpdateEditedMessages(editedMessages, messages.messages, chatInfo.data.chatId || -1)
						}
						if(readMessages.length !== 0){
							currentMessages = liveUpdateReadMessages(readMessages, messages.messages, chatInfo.data.chatId || -1)
						}
						if(newMessages.length !== 0){
							currentMessages = liveUpdateNewMessages(newMessages, messages.messages, chatInfo.data.chatId || -1)

							if(messageArea.scrollHeight - messageArea.clientHeight - 50 <= messageArea.scrollTop){
								setScrollToTheLastMessage(true)
							}
						}

						setMessages([...currentMessages])
					})
			}
		}, 2000)
	
		if(scrollToTheLastMessage){
			scrollDown()
			setScrollToTheLastMessage(false)
		}
		
		return ()=>{
			clearInterval(interval)
		}
	}, [messages.messages])

	useEffect(() => {
		const messageArea = messageAreaRef.current

		if(messageArea){
			messageArea.scrollTop = messageArea.scrollHeight - messageAreaScrollHeight 
			setMessageAreaScrollHeight(messageArea.scrollHeight)
		}
	}, [messages.page])


	return (
		<div className={`message-area ${contextMenu ? 'message-area-block-scroll' : ''}`} ref={messageAreaRef} style={{  overflowY: loading ? 'hidden' : 'scroll' }} onScroll={scrollHandler}>
			{loading ? <div className='loading-messages'><Loading /></div> : null}
			<div className={`date-banner ${!date ? 'hidden-date-banner' : null}`}>{dateText}</div>
			{messages.messages.map((message, index) => {
				return <ChatMessages
					message={message as messageType}
					onLoaded={() => {
						if(scrollToTheLastMessage){
							scrollDown()				

							if(index === messages.messages.length - 1){
								setScrollToTheLastMessage(false)
							}
						}
					}}
					key={`chat-message-${message.messageId}`}
				/>
			})}
		</div>
	)
}

export default MessageArea