// images
import accounts1En from '../../../assets/manual/finance/en/accaountListEn.png'
import accounts2En from '../../../assets/manual/finance/en/addNewAccountEn.png'
import accounts3En from '../../../assets/manual/finance/en/accountsDetailsEn.png'
import accounts1Ua from '../../../assets/manual/finance/ua/accaountListUa.png'
import accounts2Ua from '../../../assets/manual/finance/ua/addNewAccountUa.png'
import accounts3Ua from '../../../assets/manual/finance/ua/accountsDetailsUa.png'
import transactions1En from '../../../assets/manual/finance/en/transactionHome.png'
import transactions2En from '../../../assets/manual/finance/en/transactionsEn.png'
import transactions3En from '../../../assets/manual/finance/en/addNewTransactionEn.png'
import transactions1Ua from '../../../assets/manual/finance/ua/transactionHome.png'
import transactions2Ua from '../../../assets/manual/finance/ua/transactionsUa.png'
import transactions3Ua from '../../../assets/manual/finance/ua/addNewTransactionUa.png'
import bills1En from '../../../assets/manual/finance/en/billsHome.png'
import bills2En from '../../../assets/manual/finance/en/billsListEn.png'
import bills3En from '../../../assets/manual/finance/en/createBillEn.png'
import bills1Ua from '../../../assets/manual/finance/ua/billsHome.png'
import bills2Ua from '../../../assets/manual/finance/ua/billsListUa.png'
import bills3Ua from '../../../assets/manual/finance/ua/createBillUa.png'
import budget1En from '../../../assets/manual/finance/en/budgetsHome.png'
import budget2En from '../../../assets/manual/finance/en/budgetListEn.png'
import budget3En from '../../../assets/manual/finance/en/createBudgetEn.png'
import budget1Ua from '../../../assets/manual/finance/ua/budgetsHome.png'
import budget2Ua from '../../../assets/manual/finance/ua/budjetListUa.png'
import budget3Ua from '../../../assets/manual/finance/ua/createBudgetUa.png'
import reports1En from '../../../assets/manual/finance/en/reportsHome.png'
import reports2En from '../../../assets/manual/finance/en/financeReportsEn.png'
import reports1Ua from '../../../assets/manual/finance/ua/reportsHome.png'
import reports2Ua from '../../../assets/manual/finance/ua/financeReportsUa.png'


// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const GeneralFinanceManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.finance' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('accountsTitle'),
			text: t('accountsText'),
			link: '/ri-manual/finance/accounts'
			// images: language === 'en' ? [accounts1En, accounts2En, accounts3En] : [accounts1Ua, accounts2Ua, accounts3Ua]	
		},
		{
			title: t('transactionsTitle'),
			text: t('transactionsText'),
			link: '/ri-manual/finance/transactions'
			// images: language === 'en' ? [transactions1En, transactions2En, transactions3En] : [transactions1Ua, transactions2Ua, transactions3Ua]

		},
		{
			title: t('billsTitle'),
			text: t('billsText'),
			link: '/ri-manual/finance/bills'
			// images: language === 'en' ? [bills1En, bills2En, bills3En] : [bills1Ua, bills2Ua, bills3Ua]
		},
		{
			title: t('budgetsTitle'),
			text: t('budgetsText'),
			link: '/ri-manual/finance/budgets'
			// images: language === 'en' ? [budget1En, budget2En, budget3En] : [budget1Ua, budget2Ua, budget3Ua]
		},
		{
			title: t('reportsTitle'),
			text: t('reportsText'),
			// images: language === 'en' ? [reports1En, reports2En] : [reports1Ua, reports2Ua]
		},
		{
			title: t('statisticsTitle'),
			text: t('statisticsText'),
			link: '/ri-manual/finance/statistics'
			// images: language === 'en' ? [] : []
		}
	]
	
	return (
		<ManualLayout 
			heading={t('financeManualTitle')}
			data={data}
		/>
	)
}

export default GeneralFinanceManual
