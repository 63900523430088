// styles
import './settingField.scss'

// react
import { FC } from 'react'

// components
import CheckBox from '../checkBox/checkBox'
import Dropdown from '../dropdown/dropdown'
import InputField from '../inputField/inputField'
import Switch from '../switch/switch'

// types
import { settingFieldContentType, settingFieldContent, dropdownOption } from '../../../types/general/generalTypes'

type settingFieldProps = {
	label: string
	content: settingFieldContent[]
}

const SettingField: FC<settingFieldProps> = ({ label, content }) => {

	const renderOption = (fieldData: settingFieldContent) => {
		switch (fieldData.type) {
		case settingFieldContentType.CHECKBOX:
			return <div className="setting-field-checkbox">
				<CheckBox isChecked={fieldData.isChecked} onClick={fieldData.onClick} label={fieldData.label} />
			</div>
		case settingFieldContentType.DROPDOWN:
			return <div className="setting-field-dropdown">
				<Dropdown selectedOption={{} as dropdownOption} dropdownOptions={fieldData.dropdownOptions} icon={fieldData.icon} placeholder={fieldData.label || 'Dropdown'} onSelect={(value) => console.log(value)} />
			</div>
		case settingFieldContentType.INPUT:
			return <div className="setting-field-input">
				<InputField name={fieldData.label} type='text' value='test' placeholder={fieldData.label} />
			</div>
		case settingFieldContentType.SWITCH:
			return <div className="setting-field-input">
				<Switch isChecked={fieldData.isChecked} onClick={fieldData.onClick} />
			</div>
		default:
			return <></>
		}
	}

	return (
		<div className='setting-field'>
			<p>{label}</p>
			<div className='setting-field-content'>
				{content.map((content) => {
					return <div className="setting-field-options">
						{renderOption(content)}
					</div>
				})}
			</div>
		</div>
	)
}

export default SettingField