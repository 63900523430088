import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

import { hierarchyColumn } from '../../../types/employees/hierarchyTypes'

type hierarchyGeneralState = {
    hierarchy: hierarchyColumn[]
}

const initialState: hierarchyGeneralState = {
	hierarchy: []
}

export const hierarchyGeneralSlice = createSlice({
	name: 'hierarchyGeneralReducer',
	initialState,
	reducers: {
		setHierarchy: (state, action: PayloadAction<hierarchyColumn[]>) => {
			state.hierarchy = action.payload
		}
	}
})

export const { setHierarchy } = hierarchyGeneralSlice.actions

export default hierarchyGeneralSlice.reducer