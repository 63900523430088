// styles
import './replyBanner.scss'

//react
import { FC } from 'react'

//types
import { chatMember, messageType } from '../../../../../../../../types/employees/chatTypes'

type replyBannerProps = {
	message: messageType
	chatMembers: chatMember[]
}

const ReplyBanner: FC<replyBannerProps> = ({ message, chatMembers }) => {
	const { text, userId } = message


	const sender = chatMembers.find((member) => member.userId === userId)

	return (
		<div className="reply-banner-container">
			<p className="reply-banner-name">{sender ? sender.name : ''}</p>
			<p className="reply-banner-text">{text}</p>
		</div>
	)
}

export default ReplyBanner