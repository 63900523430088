//styles
import './aiSection.scss'

//components
import Title from '../../../components/general/title/title'
import Subtitle from '../../../components/general/subtitle/subtitle'
import SectionWithLines from '../../../components/general/sectionWithLines/sectionWithLines'
import ScrollAnimation from '../../../components/general/scrollAnimation/scrollAnimation'
import Button from '../../../../../components/general/button/button'
import Text from '../../general/text/text'

//react
import { useNavigate } from 'react-router-dom'

// custom hooks
import useScreenSize from '../../../customHooks/useScreenSize'

//translation
import { useTranslation } from 'react-i18next'

const AISection = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.home' })

	const navigate = useNavigate()

	const {screenWidth} = useScreenSize()

	return (
		<SectionWithLines 
			sectionHeight={screenWidth <= 425 ? '80vw': undefined}
			background='linear-gradient(180deg, #FFF 0%, #DFDFEF 15%, #FFF 135.99%)'
			leftLineStyles={screenWidth < 768 ? {width: '30vw',left: '-23%', top: '-6%', transform: 'rotateZ(20deg)', hidden: false} : {width: '30vw',left: '-20%', top: '-4%', transform: 'rotateZ(25deg)', hidden: false}}
			rightLineStyles={screenWidth < 768 ? {width: '30vw',left: '95%', top: '5%', transform: 'rotateZ(200deg)', hidden: false} : {width: '30vw',left: '80%', top: '35%', transform: 'rotateZ(205deg)', hidden: false}}
		>
			<div  className="ai-section-container">
				<ScrollAnimation direction='to-bottom'>
					<div className="ai-section">
						<Title>
							{t('leverageThePowerOf')} <span className='text-blue'>{t('AI')}</span> {t('forTheSakeOfYour')} <span className='text-cyan'>{t('business')}</span>
						</Title>
						<Text>{t('text')}</Text>
						<div className='ai-section-actions'>
							<Button active={true} outlined={true} text={t('learnMore')} onClick={()=> navigate('/product-description')}/>
						</div>
                        
					</div>
				</ScrollAnimation>
			</div>
		</SectionWithLines>
	)
}

export default AISection