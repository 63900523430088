// styles
import './inputRadio.scss'

// react
import { FC, ReactNode, useEffect, useState } from 'react'

type inputRadioProps = {
  label?: ReactNode
  value?: string
  color?: string
  rounded?: boolean
  checked: boolean
  onChange?: (checked: boolean) => void
}

const InputRadio: FC<inputRadioProps> = ({ label, value, checked, color, rounded, onChange }) => {

	return (
		<div className='radio'>
			<input
				type="radio"
				value={value}
				checked={checked}
				className={'radio-input'}
				style={{accentColor:color, borderColor:color}}
			/>
			<label className='radio-label' onClick={() => {
				onChange && onChange(!checked)
			}}>
				<div className={`radio-box ${rounded ? 'rounded' : ''}`} style={{borderColor:color}}>
					<div className={`radio-inner ${checked ? 'checked' : ''}`} style={{background:color}}></div>
				</div>
				{label && <span >{label}</span>}
			</label>
		</div>
	)
}

export default InputRadio