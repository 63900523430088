// images
import offeringDetailsEn from '../../../../assets/manual/sale/en/offeringDetails.png'
import offeringDetailsInventoryItemsEn from '../../../../assets/manual/sale/en/offeringDetailsInventoryItems.png'
import offeringDetailsInventoryItemsCreateEn from '../../../../assets/manual/sale/en/offeringDetalsCreateInventoryItem.png'
import offeringDetailsUa from '../../../../assets/manual/sale/en/offeringDetails.png'
import offeringDetailsInventoryItemsUa from '../../../../assets/manual/sale/en/offeringDetailsInventoryItems.png'
import offeringDetailsInventoryItemsCreateUa from '../../../../assets/manual/sale/en/offeringDetalsCreateInventoryItem.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const SingleOfferingManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.sales.singleOffering' })
	const { language } = useAppSelector(state => state.general)
	
	const data: manualData[] = [
		{
			title: t('inventoryItemsTitle'),
			text: t('inventoryItemsText'),
			// images: language === 'en' ? [offeringDetailsEn] : [offeringDetailsUa]
		},
		{
			title: t('createItemTitle'),
			text: t('createItemText'),
			// images: language === 'en' ? [offeringDetailsInventoryItemsEn, offeringDetailsInventoryItemsCreateEn] : [offeringDetailsInventoryItemsUa ,offeringDetailsInventoryItemsCreateUa]
		},
		{
			title: t('deleteItemTitle'),
			text: t('deleteItemText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singleOfferingManualTitle')}
			data={data}
		/>
	)
}

export default SingleOfferingManual
