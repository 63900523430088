//styles
import './moveProjectModal.scss'

//components
import Modal from '../../../../general/modals/modal/modal'
import Dropdown from '../../../../general/dropdown/dropdown'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setMoveProjectId, setMoveProjectModalIsOpen } from '../../../../../redux/projects/pipeline/modals'

//react
import { useState, useEffect, FC } from 'react'

//types
import { dropdownOption, dragStage, project } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleProjectMoveUrl } from '../../../../../utils/urls/projects/project'

//translation
import { useTranslation } from 'react-i18next'

type pipelineResponse = {
	id: number
	name: string
	stages: {
		stage_id: number
		name: string
	}[]
}

type formErrors = {
	selectedPipeline?: string
	selectedStage?: string
}

type moveProjectModalProps = {
	stagesList: dragStage<project>[]
	setStagesList: (value: dragStage<project>[]) => void
}

const MoveProjectModal: FC<moveProjectModalProps> = ({ stagesList, setStagesList }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.pipeline.modals.moveProjectModal' })

	const dispatch = useAppDispatch()
	const { modalIsOpen, projectId } = useAppSelector((state) => state.pipelineModals.moveProjectModal)

	const [pipelineOptions, setPipelineOptions] = useState<dropdownOption[]>([])
	const [selectedPipelineOption, setSelectedPipelineOption] = useState<dropdownOption | null>(null)

	const [stageOptions, setStageOptions] = useState<dropdownOption[]>([])
	const [selectedStageOption, setSelectedStageOption] = useState<dropdownOption | null>(null)

	const [errors, setErrors] = useState<formErrors>({})

	const [pipelineData, setPipelineData] = useState<{
		id: number
		name: string
		stages: {
			id: number
			name: string
		}[]
	}[]>([])

	const closeModal = () => {
		dispatch(setMoveProjectModalIsOpen(false))
		dispatch(setMoveProjectId(null))
		setSelectedPipelineOption(null)
		setSelectedStageOption(null)
	}

	const checkErrors = () => {
		let result = true

		if (!selectedPipelineOption) {
			setErrors(prevErrors => ({ ...prevErrors, selectedPipeline: t('chooseAPipelinePlease') }))
			result = false
		}

		if (!selectedStageOption) {
			setErrors(prevErrors => ({ ...prevErrors, selectedStage: t('chooseAStagePlease') }))
			result = false
		}

		return result
	}
	const moveProject = () => {
		setErrors({})

		if (selectedStageOption && projectId && checkErrors()) {
			authorizedRequest(singleProjectMoveUrl(projectId), 'PUT', 'accessToken', { stage_id: parseInt(selectedStageOption?.key) })
				.then(() => {
					setStagesList([...stagesList.map((item) => {
						return {
							...item,
							items: item.items.filter((project) => project.id !== projectId)
						}
					})])
					closeModal()
				})

		}
	}

	useEffect(() => {
		if (projectId) {
			authorizedRequest(singleProjectMoveUrl(projectId), 'GET')
				.then((response) => {

					const { result } = response

					setPipelineData(result.map((pipeline: pipelineResponse) => {
						return {
							id: pipeline.id,
							name: pipeline.name,
							stages: pipeline.stages.map((stage) => {
								return {
									id: stage.stage_id,
									name: stage.name
								}
							})
						}
					}))
				})
		}

	}, [projectId])

	useEffect(() => {
		setPipelineOptions(pipelineData.map((pipeline) => {
			return {
				title: pipeline.name,
				key: `${pipeline.id}`
			}
		}))
	}, [pipelineData])

	useEffect(() => {
		const stages = pipelineData.find((pipeline) => pipeline.id === parseInt(selectedPipelineOption?.key || '-1'))?.stages

		if (stages) {
			setStageOptions(stages.map((stage) => {
				return {
					title: stage.name,
					key: `${stage.id}`
				}
			}))
		}
	}, [selectedPipelineOption])

	return (
		<Modal title={t('moveProject')} open={modalIsOpen} closeModal={closeModal} submitButton={{ text: t('move'), onClick: moveProject }}>
			<div className="move-project">
				<Dropdown
					placeholder={t('newPipeline')}
					dropdownOptions={pipelineOptions}
					onSelect={(option) => {
						setSelectedPipelineOption(option)
					}}
					selectedOption={selectedPipelineOption}
					error={errors.selectedPipeline}
				/>
				<Dropdown
					placeholder={t('stage')}
					dropdownOptions={stageOptions}
					onSelect={(option) => {
						setSelectedStageOption(option)
					}}
					selectedOption={selectedStageOption}
					error={errors.selectedStage}
				/>
			</div>
		</Modal>
	)

}

export default MoveProjectModal