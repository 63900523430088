// styles
import './aboutUs.scss'

// components
import Layout from '../../components/general/layout/layout'
import SectionWithLines from '../../components/general/sectionWithLines/sectionWithLines'
import ScrollAnimation from '../../components/general/scrollAnimation/scrollAnimation'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'

//translation
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.aboutUs' })

	return (
		<Layout>
			<SectionWithLines
				sectionHeight='70vw' 
				leftLineStyles={{width: '30vw',left: '-25%', top: '-2%', hidden: false, transform: 'rotateZ(20deg)'}}
				rightLineStyles={{width: '30vw', left: '95%', top: '5%', hidden: false, transform:'rotateZ(200deg)',}}
			>
				<div className="about-us-hero">
					<ScrollAnimation direction='to-bottom'>
						<div className='about-us-hero-content'>
							<h1 className='hero-headline'>
								{t('diveIntoOurDeep')} <span className='text-blue'>{t('ideology')}</span> {t('andLong')} <span className='text-green'>{t('history')}</span> {t('withUs')} 
							</h1>
						</div>
					</ScrollAnimation>
				</div>
			</SectionWithLines>

			<ScrollAnimation direction='to-bottom' distance={2}>
				<div className='about-us'>
					<h1 className='about-us-heading'>
						{t('aboutRISoftware.title')}
					</h1>
					<div  className='about-us-text'>
						<p>
							{t('aboutRISoftware.text')}
						</p>
					</div>
				</div>
				<div className='about-us'>
					<h1 className='about-us-heading'>
						{t('historySection.title')}
					</h1>
					<div  className='about-us-text'>
						<p>
							{t('historySection.text')}
						</p>
					</div>
				</div>
			</ScrollAnimation>
			<ParticlesBg/>
		</Layout>
	)
}

export default AboutUs