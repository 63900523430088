//components
import Layout from '../../../components/general/layout/layout'
import ProductAndCustomerAnalysis from '../../../components/sales/salesStats/productAndCustomerAnalysis/productAndCustomerAnalysis'
import OperationalEfficiency from '../../../components/sales/salesStats/operationalEfficiency/operationalEfficiency'
import SalesFigures from '../../../components/sales/salesStats/salesFigures/salesFigures'
import StrategicAnalysisAndForecasting from '../../../components/sales/salesStats/strategicAnalysisAndForecasting/strategicAnalysisAndForecasting'

//react
import { useState } from 'react'

//types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

enum saleStatsSection {
	ProductAndCustomerAnalysis='Product And Customer Analysis',
	SalesFigures='Sales Figures',
	OperationalEfficiency='Operational Efficiency',
	StrategicAnalysisAndForecasting='Strategic Analysis And Forecasting',
}

const SalesStats = () => {
	const [activeSalesStatsSection, setActiveSalesStatsSection] = useState(saleStatsSection.ProductAndCustomerAnalysis)

	const {t} = useTranslation('', {keyPrefix: 'sales.salesStats'})

	const titleUrls = [
		{
			url: '/ri-business/sales/statistics',
			title: t('salesStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'sales_stats_info_title',
			description: 'sales_stats_info_description',
			link: '/ri-manual/sales'
		}],
		type: infoPopupTypes.INFO
	}

	const purchaseStatsButtons: button[] = [
		{
			active: activeSalesStatsSection === saleStatsSection.ProductAndCustomerAnalysis,
			text: t('productAndCustomerAnalysis'),
			onClick: () => {setActiveSalesStatsSection(saleStatsSection.ProductAndCustomerAnalysis)}
		},
		{
			active: activeSalesStatsSection === saleStatsSection.SalesFigures,
			text: t('salesFigures'),
			onClick: () => {setActiveSalesStatsSection(saleStatsSection.SalesFigures)}
		},
		{
			active: activeSalesStatsSection === saleStatsSection.OperationalEfficiency,
			text: t('operationalEfficiency'),
			onClick: () => {setActiveSalesStatsSection(saleStatsSection.OperationalEfficiency)}
		},
		{
			active: activeSalesStatsSection === saleStatsSection.StrategicAnalysisAndForecasting,
			text: t('strategicAnalysisAndForecasting'),
			onClick: () => {setActiveSalesStatsSection(saleStatsSection.StrategicAnalysisAndForecasting)}
		},
	]

	const renderTab = () => {
		switch(activeSalesStatsSection) {
		case saleStatsSection.ProductAndCustomerAnalysis:
			return <ProductAndCustomerAnalysis/>
		case saleStatsSection.SalesFigures:
			return <SalesFigures />
		case saleStatsSection.OperationalEfficiency:
			return <OperationalEfficiency />
		case saleStatsSection.StrategicAnalysisAndForecasting:
			return <StrategicAnalysisAndForecasting />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: t('salesStatistics')
			}}
			tabButtons={purchaseStatsButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			{renderTab()}
		</Layout>
	)
}

export default SalesStats
