//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { deleteTransactionUrl } from '../../../../../../utils/old_urls/general/generalUrls'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteTransactionId, setDeleteTransactionModalIsOpen } from '../../../../../../redux/finance/general/modals'

//types
import { transaction } from '../../../../../../types/finance/general'

//translation
import { useTranslation } from 'react-i18next'

type deleteTransactionConfirmationModalProps = {
	transactions: transaction[]
	setTransactions: (value: transaction[]) => void
}

const DeleteTransactionConfirmationModal: FC<deleteTransactionConfirmationModalProps> = ({transactions, setTransactions}) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.general.modals.transcation.deleteTransactionConfirmationModal' })
	const { modalIsOpen, transactionId } = useAppSelector((state) => state.financeGeneralModal.deleteTransactionModal)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setDeleteTransactionModalIsOpen(false))
		dispatch(setDeleteTransactionId(null))
	}

	const handleSubmit = () => {
		if(transactionId){
			setLoading(true)
			authorizedRequest(deleteTransactionUrl(transactionId), 'DELETE')
				.then((response) => {
					setTransactions([...transactions.filter((transaction) => transaction.id !== transactionId)])

					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisBill')} 
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]}
			isOpen={modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteTransactionConfirmationModal