//styles and icons
import './contextMenu.scss'
import { copyMessageIcon, pinMessageIcon, replyMessageIcon, forwardMessageIcon, editMessageIcon, deleteMessageIcon } from '../../../../../../../assets/employees/chat/chatIcons'

//react
import { FC, useEffect, useRef, useState } from 'react'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//types
import { messageActions, messageType } from '../../../../../../../types/employees/chatTypes'

//network
import { authorizedRequest } from '../../../../../../../utils/queries'
import { setPinnedMessageUrl } from '../../../../../../../utils/old_urls/employees/chatsUrls'

//translation
import { useTranslation } from 'react-i18next'

const ContextMenu: FC = () => {
	const { 
		contextMenu, 
		setContextMenu,
		setCurrentChatAction,
		setForwardModalIsOpen,
		setForwardMessageId,
		messages,
		setMessages,
		setDeleteModalIsOpen,
		setDeletedMessageId
	} = useChatDesktop()

	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.messageArea.chatMessage.contextMenu' })

	const userId = parseInt(localStorage.getItem('userId') || '-1')

	// Creating ref for popup element
	const contextMenuRef = useRef<HTMLDivElement | null>(null)
	const sizeInViewWidth = (px:number | undefined):number =>  {
		if(px === undefined) return 0 
		return px * 100 / screenWidth
	}

	const openForwardMessageModal = () => {
		setForwardModalIsOpen(true)
		setForwardMessageId(contextMenu?.message.messageId || null)
	}
  
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const [topPosition, setTopPosition] = useState(0)
	const [leftPosition, setLeftPosition] = useState(0)
	// Destructuring x and y properties from coords object
	// Destructuring text, ownerName and messageId properties from message object


	// Using useEffect hook to add 'context-menu-visible' that has opacity: 1 class to popup element after 10ms
	useEffect(() => {
		setForwardMessageId(contextMenu?.message.messageId || null)

		const contextMenuElement = contextMenuRef.current
        
		setTimeout(() => {
			if (contextMenuElement !== null) {
				contextMenuElement.classList.add('context-menu-visible')
			}
		}, 10)
	}, [])

	useEffect(() => {
		const handleResize = () => {
			const contextMenuContainer = contextMenuRef.current
			if(contextMenuContainer && contextMenu){
				setScreenWidth(window.innerWidth)
				setTopPosition(sizeInViewWidth(contextMenu.coords.y > document.documentElement.clientHeight/2? contextMenu.coords.y - contextMenuContainer.clientHeight : contextMenu.coords.y))
				setLeftPosition(sizeInViewWidth(contextMenu.coords.x))
			}
		}

		handleResize()
	
		window.addEventListener('resize', handleResize)
	
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [contextMenu])

	const togglePinMessage = (currentMessageId: number) =>{

		if(currentMessageId && contextMenu){
			authorizedRequest(setPinnedMessageUrl(currentMessageId), 'POST', 'accessToken', {})
			const currentMessageIndex = messages.messages.findIndex((message: messageType) => message.messageId === currentMessageId)
			
			messages.messages[currentMessageIndex].pinned = !contextMenu.message.pinned
			setMessages([...messages.messages])
			setContextMenu(null)
		}

	}
	
	return (
		<div ref={contextMenuRef} className='context-menu' style={{ top: `calc(${topPosition}vw + 9vw)`, left: `${leftPosition}vw` }}>
			<div className="context-menu-item" onClick={() => { setCurrentChatAction({ action: messageActions.REPLY, message: contextMenu?.message ? contextMenu.message : null }) }}>
				<div className="context-menu-icon">{replyMessageIcon}</div>
				<div className="context-menu-action">{t('reply')}</div>
			</div>
			{ contextMenu?.message.userId === userId &&
				(
					<div className="context-menu-item" onClick={() => { setCurrentChatAction({ action: messageActions.EDIT, message: contextMenu?.message ? contextMenu.message : null }) }}>
						<div className="context-menu-icon">{editMessageIcon}</div>
						<div className="context-menu-action">{t('edit')}</div>
					</div>
				)
			}
			<div className="context-menu-item">
				<div className="context-menu-icon">{copyMessageIcon}</div>
				<div className="context-menu-action">{t('copy')}</div>
			</div>
			<div className="context-menu-item" onClick={openForwardMessageModal}>
				<div className="context-menu-icon">{forwardMessageIcon}</div>
				<div className="context-menu-action">{t('forward')}</div>
			</div>
			<div className="context-menu-item" onClick={() => {
				if(contextMenu){
					togglePinMessage(contextMenu?.message.messageId)
				}
			}}>
				<div className="context-menu-icon">{pinMessageIcon}</div>
				{
					contextMenu?.message.pinned ? 
						(
							<div className="context-menu-action">{t('unpinMessage')}</div>
						):
						(
							<div className="context-menu-action">{t('pinMessage')}</div>
						)
				}
			</div>
			<div className="context-menu-item" onClick={() => {
				if(contextMenu?.message.messageId){
					setDeleteModalIsOpen(true)
					setDeletedMessageId(contextMenu.message.messageId)
				}
			}}>
				<div className="context-menu-icon">{deleteMessageIcon}</div>
				<div className="context-menu-action">
					{ contextMenu?.message.userId === userId ? t('delete') : t('deleteForMe')}
				</div>
			</div>
		</div>
	)
}

export default ContextMenu
