//components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import Pagination from '../../../general/pagination/pagination'
import OrderCard from '../../../general/orderCard/orderCard'
import CreateCustomerOrderModal from './modals/createCustomerOrderModal/createCustomerOrderModal'
import OrderItemSkeleton from '../../../general/skeletons/orderItem/orderItemSkeleton'

//react 
import { useState, useEffect, FC } from 'react'
import { useParams } from 'react-router'

//redux
import {useAppDispatch, useAppSelector} from '../../../../customHooks/redux'
import { setCreateCustomerOrderModalIsOpen } from '../../../../redux/sales/customer/modals'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleCustomerOrderFiltersUrl, singleCustomerOrdersUrl } from '../../../../utils/urls/sales/customer'

//types
import { order, filters, filterBar } from '../../../../types/general/generalTypes'

// other
import {createFilteringRequest, formatFilters, formatOrderResponse} from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type customerOrderListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const CustomerOrderList: FC<customerOrderListProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customer.customerOrderList' })
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const [ orders, setOrders ] = useState<order[]>([])

	const [ searchRequest, setSearchRequest ] = useState('')
	const [ activeFilters, setActiveFilters ] = useState<filters[]>([])
	const [ page, setPage ] = useState(1)
	const [ lastPage, setLastPage ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const{relationshipId} = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleCustomerOrderFiltersUrl(Number(relationshipId)), 'GET')

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: {
				text: t('create'),
				active: true,
				onClick: () => {
					dispatch(setCreateCustomerOrderModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async ( page: number, request: string, filters: filters[] ) => {
		const filteringRequest = createFilteringRequest(filters)
		const {result} = await authorizedRequest(singleCustomerOrdersUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		if(result.length > 0) {
			const formatedOrders = result.map(formatOrderResponse)
			return formatedOrders
		}
		return []
	}

	const onSearch =  (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				setOrders([...result])
				if(result.length > 0){
					setOrders([...orders, ...result])
					setPage(page + 1)
				}else{
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if(!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if(result.length > 0){
						setOrders([...orders, ...result])
						setPage(page + 1)
					}else{
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	return (
		<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
			<ColumnLayout>
				{
					orders.map((orderItem: order, index) => {
						return (
							<OrderCard
								{...orderItem}
								key={`order-${index}`}
							/>
						)
					})
				}
				{loading ? <OrderItemSkeleton /> : null}
			</ColumnLayout>
			<CreateCustomerOrderModal orders={orders} setOrders={setOrders}/>
		</Pagination>
	)
}

export default CustomerOrderList