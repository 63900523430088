//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router'

// components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import Pagination from '../../../general/pagination/pagination'
import CreateProviderOfferingModal from './modals/createProviderOfferingModal/createProviderOfferingModal'
import DeleteProviderOfferingModal from './modals/deleteProviderOfferingModal/deleteProviderOfferingModal'
import ExportProviderOfferingFileModal from './modals/exportProviderOfferingFileModal/exportProviderOfferingFileModal'
import ImportProviderOfferingFileSelectorModal from './modals/importProviderOfferingFileSelectorModal/importProviderOfferingFileSelectorModal'
import ProviderOfferingTablePreviewModal from './modals/providerOfferingTablePreviewModal.tsx/providerOfferingTablePreviewModal'
import OfferingCard from '../../../general/offeringCard/offeringCard'
import OfferingCardSkeleton from '../../../general/skeletons/offeringCard/offeringCardSkeleton'

//types
import { filterBar, filters, offering } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	singleProviderOfferingsUrl,
	singleProviderOfferingFiltersUrl
} from '../../../../utils/urls/purchases/provider'
//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setCreateProviderOfferingModalIsOpen, setDeleteProviderOfferingId, setDeleteProviderOfferingModalIsOpen, setExportProviderOfferingFileModalIsOpen, setImportProviderOfferingFileSelectorModalIsOpen } from '../../../../redux/purchase/provider/modals'

// other
import { createFilteringRequest, formatFilters, formatOfferingResponse, getItemsPerColumn } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type providerOfferingListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const ProviderOfferingList: FC<providerOfferingListProps> = ({ setFilterBar }) => { 
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerOfferingList' })

	const dispatch = useAppDispatch()

	const [ offerings, setOfferings ] = useState<offering[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [ searchRequest, setSearchRequest ] = useState('')
	const [ activeFilters, setActiveFilters ] = useState<filters[]>([])
	const [ page, setPage ] = useState(1)
	const [ lastPage, setLastPage ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	const {relationshipId} = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleProviderOfferingFiltersUrl(Number(relationshipId)), 'GET')
		console.log(result)

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			importButton: () => {
				dispatch(setImportProviderOfferingFileSelectorModalIsOpen(true))
			},
			// exportButton: () => {
			// 	dispatch(setExportProviderOfferingFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: {
				text: t('create'),
				active: true,
				onClick: () => {
					dispatch(setCreateProviderOfferingModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async ( page: number, request: string, filters: filters[] ) => {
		const filteredRequest = createFilteringRequest(filters)
		const {result} = await authorizedRequest(singleProviderOfferingsUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteredRequest, 'GET')
		if(result.length > 0){
			const formatedOfferings: offering[] = result.map(formatOfferingResponse)
			return formatedOfferings
		}
		return []
	}

	const onSearch =  (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				if(result.length > 0){
					setOfferings([...offerings, ...result])
					setPage(page + 1)
				}else{
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if(!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if(result.length > 0){
						console.log([...offerings, ...result])
						setOfferings([...offerings, ...result])
						setPage(page + 1)
					}else{
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	const openDeleteProviderOfferingsConfirmationModal = (id:number | null) => {
		dispatch(setDeleteProviderOfferingModalIsOpen(true))
		dispatch(setDeleteProviderOfferingId(id))
	}
	
	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			setFilterBar(undefined)
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
			<ColumnLayout amount={itemsPerColumn}>
				{
					offerings.map((offeringItem: offering, index) => {
						return (
							<OfferingCard
								{...offeringItem}
								key={`Offering-${index}`}
								link={`/ri-business/purchase/provider/offering/${offeringItem.offeringId}`}
								handleDelete={offeringItem.deleteAccess ? () => {
									openDeleteProviderOfferingsConfirmationModal(offeringItem.offeringId)
								} : undefined }
							/>
						)
					})
				}
				{loading ? <OfferingCardSkeleton /> : null}
			</ColumnLayout>
			<CreateProviderOfferingModal offerings={offerings} setOfferings={setOfferings}/>
			<DeleteProviderOfferingModal offerings={offerings} setOfferings={setOfferings}/>
			<ExportProviderOfferingFileModal/>
			<ImportProviderOfferingFileSelectorModal/>
			<ProviderOfferingTablePreviewModal offerings={offerings} setOfferings={setOfferings}/>
		</Pagination>
	)
}

export default ProviderOfferingList