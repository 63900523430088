// styles
import './refundOrderModal.scss'

// react
import {FC, useEffect, useState} from 'react'

// components
import Modal from '../../../../../general/modals/modal/modal'
import Dropdown from '../../../../dropdown/dropdown'
import EditableTable from '../../../../editableTable/editableTable'

//redux
import {useAppDispatch, useAppSelector} from '../../../../../../customHooks/redux'
import { setRefundOrderModalIsOpen } from '../../../../../../redux/general/order/modals'

//network
import {authorizedRequest} from '../../../../../../utils/queries'
import { getWarehousesUrl, singleOrderRefundUrl } from '../../../../../../utils/old_urls/general/orderUrls'
import { companyWarehouseActionsUrl } from '../../../../../../utils/urls/warehouses/warehouseActions/warehouseActions'
import { singleCustomersAddressesUrl } from '../../../../../../utils/urls/sales/customer'

//types
import {dropdownOption, editableTableItem, offering, orderTypes, warehouseResponse} from '../../../../../../types/general/generalTypes'
import { orderDetails } from '../../orderDetails'

//translation
import { useTranslation } from 'react-i18next'

type addWarehouseActionErrors = {
	destination?: string
	origin?: string
	items?: string
}

type refundOrderModalProps = {
	orderDetails: orderDetails
	selectedOfferings: offering[]
	setSelectedOfferings: (value: offering[]) => void
}

const RefundOrderModal: FC<refundOrderModalProps> = ({orderDetails, selectedOfferings, setSelectedOfferings})=> {
	const { t } = useTranslation('', {keyPrefix:'order.orderDetails.modals.refundOrderModal'})

	const [errors, setErrors] = useState<addWarehouseActionErrors>({})
	const [loading, setLoading] = useState(false)
	const [selectedItems, setSelectedItems] = useState<offering[]>([])
	const [selectedWarehouseOriginOption, setSelectedWarehouseOriginOption] = useState<dropdownOption | null>(null)
	const [selectedWarehouseDestinationOption, setSelectedWarehouseDestinationOption] = useState<dropdownOption | null>(null)
	const [ selectedAddressOriginOption, setSelectedAddressOriginOption ] = useState<dropdownOption | null>(null)
	const [ selectedAddressDestinationOption, setSelectedAddressDestinationOption ] = useState<dropdownOption | null>(null)

	const { modalIsOpen, orderId } = useAppSelector((state) => state.orderModals.refundOrderModal)
	const {userCompanyData, language} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1


	const [orderItems, setOrderItems] = useState<editableTableItem[]>([])
	

	const loadOrderItems = async () => {
		if (selectedOfferings.length > 0) {
			return selectedOfferings.map(e => e)
		}
		return []
	} 

	useEffect(()=>{
		const editableItems: editableTableItem[] = selectedOfferings.map((e) => {
			return {
				...e,
				maxQuantity: e.quantity
			}
		})
		setOrderItems(editableItems)
	}, [])

	const loadWarehouseOptions = async () => {
		const { result } = await authorizedRequest(getWarehousesUrl(companyId), 'GET')
		return result.map((warehouse: warehouseResponse) => {
			return {
				title: warehouse.name,
				key: `${warehouse.id}`
			}
		})
	}

	const loadAddressOptions = async (search: string, page: number) => {
		if (orderDetails.counterparty.key) {
			const { result } = await authorizedRequest(singleCustomersAddressesUrl(Number(orderDetails.counterparty.key)) + `?needle=${search}&page=${page}`, 'GET')
	
			return result.map((customer: warehouseResponse) => {
				return {
					title: customer.address,
					key: `${customer.id}`
				}
			})
		}
	}

	const dispatch = useAppDispatch()

	const closeModal = ()=> {
		dispatch(setRefundOrderModalIsOpen(false))
		setSelectedWarehouseOriginOption(null)
		setSelectedWarehouseDestinationOption(null)
		setSelectedItems([])
		setErrors({})
	}


	const checkErrors = () => {
		let hasErrors = false

		if((selectedWarehouseOriginOption === null || selectedWarehouseOriginOption.key.length === 0) && (selectedAddressOriginOption === null || selectedAddressOriginOption.key.length === 0)){
			setErrors(prevErrors => ({...prevErrors, origin: t('pleaseSelectTheOriginWarehouse')}))
			hasErrors = true
		}
		if((selectedWarehouseDestinationOption === null || selectedWarehouseDestinationOption.key.length === 0) && (selectedAddressDestinationOption === null || selectedAddressDestinationOption.key.length === 0)){
			setErrors(prevErrors => ({...prevErrors, destination: t('pleaseSelectDestinationWarehouse')}))
			hasErrors = true
		}
		if(selectedItems.length === 0){
			setErrors(prevErrors => ({...prevErrors, destination: t('pleaseSelectTheItemsYouArePlanningToRefund')}))
			hasErrors = true
		}

		return hasErrors
	}

	const refund = async ()=>{
		if(!loading){
			setErrors({})
			const hasErrors = checkErrors()
			if(hasErrors) return
	
			setLoading(true)
			
			const body = {
				language: language,
				origin_warehouse_id: selectedWarehouseOriginOption ? Number(selectedWarehouseOriginOption.key) : null,
				destination_warehouse_id: selectedWarehouseDestinationOption ? Number(selectedWarehouseDestinationOption.key) : null,
				origin_address_id: selectedAddressOriginOption ? Number(selectedAddressOriginOption.key) : null,
				destination_address_id: selectedAddressDestinationOption ? Number(selectedAddressDestinationOption.key) : null,
				order_items: selectedItems.map(item => {
					return {
						order_item_id: item.orderItemId,
						price: item.price?.amount,
						currency: item.price?.currency,
						quantity: item.quantity
					}
				})
			}
	
			await authorizedRequest(singleOrderRefundUrl(Number(orderId)), 'POST', 'accessToken', body)

			setSelectedOfferings([...selectedOfferings.map((offering) => {
				const selectedOffering = selectedItems.find((item) => item.orderItemId === offering.orderItemId)
				if(selectedOffering){
					return {
						...offering,
						quantity: offering.quantity - selectedOffering.quantity
					}
				}

				return offering
			}).filter((offering) => {
				return offering.quantity > 0
			})])

			closeModal()
			setLoading(false)
		}

	} 

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('refundThisOrder')}
			submitButton={{
				text: t('create'),
				onClick: refund
			}}
		>
			<div className='warehouse-section-container'>
				<div className="warehouse-fields">
					<Dropdown
						placeholder={t('origin')}
						dropdownOptions={[]}
						onSelect={(option) => {
							const optionArray = option.key.split(' ')

							setSelectedWarehouseOriginOption(null)
							setSelectedAddressOriginOption(null)
							if (optionArray[1] == 'warehouse') {
								setSelectedWarehouseOriginOption({key:optionArray[0], title:option.title})
							} else {
								setSelectedAddressOriginOption({key:optionArray[0], title:option.title})
							}
						}}
						selectedOption={selectedAddressOriginOption || selectedWarehouseOriginOption}
						loadOptions={orderDetails?.type === orderTypes.sales ? loadAddressOptions :  loadWarehouseOptions }
						error={errors.origin}
					/>
					<Dropdown
						placeholder={t('destination')}
						dropdownOptions={[]}
						onSelect={(option) => {
							const optionArray = option.key.split(' ')

							setSelectedWarehouseDestinationOption(null)
							setSelectedAddressDestinationOption(null)
							if (optionArray[1] == 'warehouse') {
								setSelectedWarehouseDestinationOption({key:optionArray[0], title:option.title})
							} else {
								setSelectedAddressDestinationOption({key:optionArray[0], title:option.title})
							}
						}}
						selectedOption={selectedWarehouseDestinationOption || selectedAddressDestinationOption}
						loadOptions={orderDetails?.type === orderTypes.sales ?  loadWarehouseOptions : loadAddressOptions}
						error={errors.destination}
					/>
				</div>
				<div className="warehouse-inventory-selection">
					<EditableTable
						items={orderItems}
						setItems={(value) => {
							setOrderItems(value as offering[])
						}}
						loadItems={loadOrderItems}
						selected={{
							items: selectedItems,
							setItems: (value) => {
								setSelectedItems(value as offering[])
							}
						}}
						error={errors.items}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default RefundOrderModal