// style
import './customer.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import CustomerDetails from '../../../components/sales/customer/customerDetails/customerDetails'
import CustomerDocuments from '../../../components/sales/customer/customerDocuments/customerDocuments'
import CustomerOrderList from '../../../components/sales/customer/customerOrderList/customerOrderList'
import CustomerBillsList from '../../../components/sales/customer/customerBillList/customerBillList'
import CustomerAddressesList from '../../../components/sales/customer/customerAddressList/customerAddressList'

// network 
import { authorizedRequest } from '../../../utils/queries'
import { singleCustomerUrl } from '../../../utils/urls/sales/customer'

// types
import { button, customerResponse, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

enum customerSection {
	Details = 'Details',
	Addresses = 'Addresses',
	Documents = 'Documents',
	Orders = 'Orders',
	Chat = 'Chat',
	Bills = 'Bills',
}

const Customer: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customer' })

	const [filterBar, setFilterBar] = useState<filterBar>()
	const [name, setName] = useState<string>('')

	const [activeCustomerSection, setActiveCustomerSection] = useState('Details')

	const { relationshipId } = useParams()
	const navigate = useNavigate()

	const loadData = async () => {
		if (relationshipId) {
			const { result }: { result: customerResponse } = await authorizedRequest(singleCustomerUrl(Number(relationshipId)), 'GET')
			setName(result.name)
		}


	}

	const titleUrls = [
		{
			url: '/ri-business/sales/customer-list',
			title: t('customers')
		},
		{
			url: location.pathname,
			title: name
		}
	]

	const infoPopup = {
		content: [{
			title: 'customer_info_title',
			description: 'customer_info_description',
			link: '/ri-manual/sales/single-customer'
		}],
		type: infoPopupTypes.INFO
	}

	useEffect(() => {
		loadData()
	}, [])

	const customerTabButtons: button[] = [
		{
			active: activeCustomerSection === customerSection.Details,
			text: t('details'),
			onClick: () => setActiveCustomerSection(customerSection.Details),
		},
		{
			active: activeCustomerSection === customerSection.Addresses,
			text: t('addresses'),
			onClick: () => setActiveCustomerSection(customerSection.Addresses),
		},
		{
			active: activeCustomerSection === customerSection.Documents,
			text: t('documents'),
			onClick: () => setActiveCustomerSection(customerSection.Documents),
		},
		{
			active: activeCustomerSection === customerSection.Orders,
			text: t('orders'),
			onClick: () => setActiveCustomerSection(customerSection.Orders),
		},
		{
			active: activeCustomerSection === customerSection.Bills,
			text: t('bills'),
			onClick: () => setActiveCustomerSection(customerSection.Bills),
		},
	]

	const renderTab = () => {
		switch (activeCustomerSection) {
		case customerSection.Details:
			return <CustomerDetails />
		case customerSection.Addresses:
			return <CustomerAddressesList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case customerSection.Documents:
			return <CustomerDocuments setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case customerSection.Orders:
			return <CustomerOrderList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case customerSection.Bills:
			return <CustomerBillsList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={customerTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="customer-container">
				{renderTab()}
			</div>
		</Layout>
	)
}

export default Customer