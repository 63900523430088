// style
import './warehouse.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import WarehouseShipmentList from '../../../components/warehouse/warehouse/warehouseShipment/warehouseShipmentList'
import WarehouseMoving from '../../../components/warehouse/warehouse/warehouseMoving/warehouseMoving'
import WarehouseStats from '../../../components/warehouse/warehouse/warehouseStats/warehouseStats'
import WarehouseDetails from '../../../components/warehouse/warehouse/warehouseDetails/warehouseDetails'
import WarehouseInventoryList from '../../../components/warehouse/warehouse/warehouseInventoryList/warehouseInventoryList'

// translation
import { useTranslation } from 'react-i18next'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

enum warehouseSection {
	Shipments = 'Shipments',
	Moving = 'Moving',
	Statistic = 'Statistic',
	Details = 'Details',
	Inventory = 'Inventory'
}


const Warehouse: FC = () => {
	const [ filterBar, setFilterBar ] = useState<filterBar>()
	const [ activeWarehouseSection, setActiveWarehouseSection ] = useState<string>('Details')
	const [ warehouseName, setWarehouseName ] = useState('')
	const [ editAccess, setEditAccess ] = useState(false)
	const tWarehouse = useTranslation('', {keyPrefix: 'warehouse'}).t
	const { t } = useTranslation('', {keyPrefix: 'warehouse.warehouse'})

	const titleUrls = [
		{
			url: '/ri-business/warehouse/warehouse-list',
			title: tWarehouse('warehouses')
		}, 
		{
			url: location.pathname,
			title: warehouseName
		}
	]

	const infoPopup = {
		content: [{
			title: 'warehouse_info_title',
			description: 'warehouse_info_description',
			link: '/ri-manual/warehouses/single-warehouse'
		}],
		type: infoPopupTypes.INFO
	}
	
	const warehouseTabButtons: button[] = [
		{
			active: activeWarehouseSection === warehouseSection.Details,
			text: t('details'),
			onClick: () => setActiveWarehouseSection(warehouseSection.Details),
		},
		{
			active: activeWarehouseSection === warehouseSection.Inventory,
			text: t('inventory'),
			onClick: () => setActiveWarehouseSection(warehouseSection.Inventory),
		},
		{
			active: activeWarehouseSection === warehouseSection.Shipments,
			text: t('shipments'),
			onClick: () => setActiveWarehouseSection(warehouseSection.Shipments),
		},
		{
			active: activeWarehouseSection === warehouseSection.Moving,
			text: t('movings'),
			onClick: () => setActiveWarehouseSection(warehouseSection.Moving),
		},
	]

	const renderTab = () => {
		switch (activeWarehouseSection) {
		case warehouseSection.Details:
			return <WarehouseDetails setWarehouseName={setWarehouseName} setEditAccess={setEditAccess} editAccess={editAccess} />
		case warehouseSection.Inventory:
			return <WarehouseInventoryList editAccess={editAccess} />
		case warehouseSection.Shipments:
			return <WarehouseShipmentList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		case warehouseSection.Moving:
			return <WarehouseMoving />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={warehouseTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="warehouse-container">
				{
					renderTab()
				}
			</div>
		</Layout>
	)
}

export default Warehouse