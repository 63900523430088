//icons
import { forecastIcon } from '../../../../assets/projects/pipeline/pipelineIcons'

//components
import ListItemCard from '../../../general/listItemCard/listItemCard'

//react
import { FC } from 'react'

// translation
import { useTranslation } from 'react-i18next'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeletePipelineId, setDeletePipelineModalIsOpen } from '../../../../redux/projects/pipelineList/modals'

//types
import { pipeline } from '../../../../types/projects/pipelineTypes'

//other
import { currencyToFormattedString, formatDate } from '../../../../assets/general/generalFunctions'

type pipelineListItemProps = pipeline<Date | undefined>

const PipelineListItem: FC<pipelineListItemProps> = ({id, name, description, numberOfProjects, revenue, earliestDeadline, deleteAccess}) => {

	const {t} = useTranslation('',{keyPrefix:'projects.pipelineList.pipelineListItem'})
	const dispatch = useAppDispatch()
	
	const handleDelete	= (id: number) => {
		dispatch(setDeletePipelineId(id))
		dispatch(setDeletePipelineModalIsOpen(true))
	}

	return (
		<ListItemCard
			title={name}
			description={description}
			data={[
				...revenue.map((item) => {
					return {
						title: t('revenue'),
						value: currencyToFormattedString(item.value, item.currency)
				
					}
				}),
				{
					title: t('projectTotal'),
					value: `${numberOfProjects}`
				},
				{
					title: t('earliestDeadline'),
					value: earliestDeadline ? formatDate(earliestDeadline, true, true) : t('na')
				}
			]}
			footer={{
				viewLink: {
					text: t('viewPipeline'),
					url: `/ri-business/projects/pipeline/${id}`
				},
				deleteFunction: deleteAccess && id > 0 ? () => { handleDelete(id)} : undefined
			}}
		/>
	)
}

export default PipelineListItem