//styles and icons
import './privacyPolicy.scss'

//components
import LanguageSelector from '../../../components/general/languageSelector/languageSelector'

//translation
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
	const { t } = useTranslation('', { keyPrefix: 'termsAndAgreement.privacyPolicy' })

	return (
		<div className='privacy-policy'>
			<div className='privacy-policy-container'>
				<div className='privacy-policy-header'>
					<div className='privacy-policy-title-and-subtitle'>
						<h2 className='privacy-policy-title'>{t('title')}</h2>
						<div className='privacy-policy-subtitle'>{t('subtitle')}</div>
					</div>
					<div className='language-selector'>
						<LanguageSelector/>
					</div>
				</div>
				<div className='privacy-policy-section'>
					<p className='privacy-policy-paragraph'>{t('paragraphs.paragraph1')}</p>
					<p className='privacy-policy-paragraph'>{t('paragraphs.paragraph2')}</p>
					<p className='privacy-policy-paragraph'>{t('paragraphs.paragraph3')}</p>
					<div className='privacy-policy-list-container'>
						<div className='privacy-policy-list'>
							<ol>
								<li>{t('lists.list1.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list1.content.paragraph1')}</p>
							<ul>
								<li>{t('lists.list1.content.unorderedList.item1')}</li>
								<li>{t('lists.list1.content.unorderedList.item2')}</li>
								<li>{t('lists.list1.content.unorderedList.item3')}</li>
								<li>{t('lists.list1.content.unorderedList.item4')}</li>
								<li>{t('lists.list1.content.unorderedList.item5')}</li>
								<li>{t('lists.list1.content.unorderedList.item6')}</li>
								<li>{t('lists.list1.content.unorderedList.item7')}</li>
								<li>{t('lists.list1.content.unorderedList.item8')}</li>
								<li>{t('lists.list1.content.unorderedList.item9')}</li>
							</ul>
							<p className='privacy-policy-paragraph'>{t('lists.list1.content.paragraph2')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list1.content.paragraph3')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list1.content.paragraph4')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={2}>
								<li>{t('lists.list2.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph1')}</p>
							<ul>
								<li>{t('lists.list2.content.unorderedList.item1')}</li>
								<li>{t('lists.list2.content.unorderedList.item2')}</li>
								<li>{t('lists.list2.content.unorderedList.item3')}</li>
								<li>{t('lists.list2.content.unorderedList.item4')}</li>
								<li>{t('lists.list2.content.unorderedList.item5')}</li>
								<li>{t('lists.list2.content.unorderedList.item6')}</li>
								<li>{t('lists.list2.content.unorderedList.item7')}</li>
								<li>{t('lists.list2.content.unorderedList.item8')}</li>
								<li>{t('lists.list2.content.unorderedList.item9')}</li>
								<li>{t('lists.list2.content.unorderedList.item10')}</li>
								<li>{t('lists.list2.content.unorderedList.item11')}</li>
							</ul>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph2')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph3')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph4')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph4')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph5')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list2.content.paragraph6')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={3}>
								<li>{t('lists.list3.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list3.content.paragraph1')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list3.content.paragraph2')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list3.content.paragraph3')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list3.content.paragraph4')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={4}>
								<li>{t('lists.list4.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list4.content.paragraph1')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list4.content.paragraph2')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list4.content.paragraph3')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={5}>
								<li>{t('lists.list5.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph1')}</p>
							<p className='privacy-policy-paragraph'>
								{t('lists.list6.content.paragraph2')}{' '}
								<a href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html' >{t('lists.list5.content.link')}</a>{' '}
								{t('lists.list5.content.paragraph3')}
							</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph4')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph5')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={4}>
								<li>{t('lists.list5.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph1')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph2')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph3')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph4')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list5.content.paragraph5')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={6}>
								<li>{t('lists.list6.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list6.content.paragraph1')}</p>
							<ul>
								<li>{t('lists.list6.content.unorderedList.item1')}</li>
								<li>{t('lists.list6.content.unorderedList.item2')}</li>
								<li>{t('lists.list6.content.unorderedList.item3')}</li>
								<li>{t('lists.list6.content.unorderedList.item4')}</li>
								<li>{t('lists.list6.content.unorderedList.item5')}</li>
							</ul>
							<p className='privacy-policy-paragraph'>{t('lists.list6.content.paragraph2')}</p>
						</div>
						<div className='privacy-policy-list'>
							<ol start={7}>
								<li>{t('lists.list7.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list7.content.paragraph1')}</p>
							<p className='privacy-policy-paragraph'>{t('lists.list7.content.paragraph2')}</p>
							<ul>
								<li>{t('lists.list7.content.unorderedList1.item1')}</li>
								<li>{t('lists.list7.content.unorderedList1.item2')}</li>
								<li>{t('lists.list7.content.unorderedList1.item3')}</li>
							</ul>
							<p className='privacy-policy-paragraph'>{t('lists.list7.content.paragraph3')}</p>
							<ul>
								<li>{t('lists.list7.content.unorderedList2.item1')}</li>
								<li>{t('lists.list7.content.unorderedList2.item2')}</li>
							</ul>
						</div>
						<div className='privacy-policy-list'>
							<ol start={8}>
								<li>{t('lists.list8.title')}</li>
							</ol>
							<p className='privacy-policy-paragraph'>{t('lists.list8.content.paragraph1')}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PrivacyPolicy