import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type providerModalsState = {
	providerOfferings: {
		createProviderOfferingModal: {
			modalIsOpen: boolean
		}
		deleteProviderOfferingModal: {
			modalIsOpen: boolean
			offeringId: number | null
		}
		importProviderOfferingFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
		tableProviderOfferingPreviewModal: {
			modalIsOpen: boolean
		}
		exportProviderOfferingFileModal: {
			modalIsOpen: boolean
		}
	}
	providerOrders: {
		createProviderOrderModal: {
			modalIsOpen: boolean
		}
	}
	providerDocuments: {
		deleteProviderDocumentModal: {
			modalIsOpen: boolean
			documentId: number | null
		},
		importProviderDocumentFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
	},
	providerAddresses: {
		createProviderAddressModal: {
			modalIsOpen: boolean
		}
		deleteProviderAddressModal: {
			modalIsOpen: boolean
			addressId: number | null
		}
	}

}

const initialState: providerModalsState = {
	providerOfferings: {
		createProviderOfferingModal: {
			modalIsOpen: false
		},
		deleteProviderOfferingModal: {
			modalIsOpen: false,
			offeringId: null
		},
		importProviderOfferingFileSelectorModal: {
			modalIsOpen: false,
			file: null
		},
		tableProviderOfferingPreviewModal: {
			modalIsOpen: false
		},
		exportProviderOfferingFileModal: {
			modalIsOpen: false
		}
	},
	providerOrders: {
		createProviderOrderModal: {
			modalIsOpen: false
		}	
	},
	providerDocuments: {
		deleteProviderDocumentModal: {
			modalIsOpen: false,
			documentId: null
		},
		importProviderDocumentFileSelectorModal: {
			modalIsOpen: false,
			file: null
		}
	},
	providerAddresses: {
		createProviderAddressModal: {
			modalIsOpen: false
		},
		deleteProviderAddressModal: {
			modalIsOpen: false,
			addressId: null
		}
	}
}

export const providerModalsSlice = createSlice({
	name: 'providerModalsSlice',
	initialState,
	reducers: {
		setCreateProviderOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOfferings.createProviderOfferingModal.modalIsOpen = action.payload
		},
		setDeleteProviderOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOfferings.deleteProviderOfferingModal.modalIsOpen = action.payload
		},
		setDeleteProviderOfferingId: (state, action: PayloadAction<number | null>) => {
			state.providerOfferings.deleteProviderOfferingModal.offeringId = action.payload
		},
		setImportProviderOfferingFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOfferings.importProviderOfferingFileSelectorModal.modalIsOpen = action.payload
		},
		setImportProviderOfferingFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.providerOfferings.importProviderOfferingFileSelectorModal.file = action.payload
		},
		setProviderOfferingTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOfferings.tableProviderOfferingPreviewModal.modalIsOpen = action.payload
		},
		setExportProviderOfferingFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOfferings.exportProviderOfferingFileModal.modalIsOpen = action.payload
		},

		setCreateProviderOrderModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerOrders.createProviderOrderModal.modalIsOpen = action.payload
		},

		setDeleteProviderDocumentModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerDocuments.deleteProviderDocumentModal.modalIsOpen = action.payload
		},
		setDeleteProviderDocumentId: (state, action: PayloadAction<number | null>) => {
			state.providerDocuments.deleteProviderDocumentModal.documentId = action.payload
		},

		setImportProviderDocumentFileSelectorModalIsOpen : (state, action: PayloadAction<boolean>) => {
			state.providerDocuments.importProviderDocumentFileSelectorModal.modalIsOpen = action.payload
		},
		setImportProviderDocumentFileSelectorFile : (state, action: PayloadAction<file | null>) => {
			state.providerDocuments.importProviderDocumentFileSelectorModal.file = action.payload
		},
		setCreateProviderAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerAddresses.createProviderAddressModal.modalIsOpen = action.payload
		},
		setDeleteProviderAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.providerAddresses.deleteProviderAddressModal.modalIsOpen = action.payload
		},
		setDeleteProviderAddressId: (state, action: PayloadAction<number | null>) => {
			state.providerAddresses.deleteProviderAddressModal.addressId = action.payload
		}
	}
})

export const {
	setCreateProviderOfferingModalIsOpen,
	setDeleteProviderOfferingModalIsOpen,
	setDeleteProviderOfferingId,
	setImportProviderOfferingFileSelectorModalIsOpen,
	setImportProviderOfferingFileSelectorModalFile,
	setProviderOfferingTablePreviewModalIsOpen,
	setExportProviderOfferingFileModalIsOpen,

	setCreateProviderOrderModalIsOpen,

	setDeleteProviderDocumentModalIsOpen,
	setDeleteProviderDocumentId,

	setImportProviderDocumentFileSelectorModalIsOpen,
	setImportProviderDocumentFileSelectorFile,

	setCreateProviderAddressModalIsOpen,
	setDeleteProviderAddressModalIsOpen,
	setDeleteProviderAddressId
} = providerModalsSlice.actions

export default providerModalsSlice.reducer
