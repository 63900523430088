import { messageType, messageActions, messagesSliderEnum } from '../../../../../types/employees/chatTypes'
import { file } from '../../../../../types/general/generalTypes'
import { chatDesktopActionTypes, currentChatActionType, contextMenuType, chatStatusType, chatDesktopState, chatDataType } from './types'



export const initialState: chatDesktopState = {
	chatInfo: {
		sliderIsOpen: false,
		data: {
			chatId: null,
			chatMembers: [],
			name: '', 
			date: new Date(),
			description: '',
			avatar: '',
			group: false,
			ownerId: 0
		},
		status: {
			online: 0,
			offline: 0,
			typing: []
		}
	},
	currentChatAction: {
		action: messageActions.SEND,
		message: null
	},
	contextMenu: null,
	inputBanner:{
		inputBannerIsOpen: false
	},
	chatInputValue: '',
	attachFiles: {
		modalIsOpen: false,
		attachedFiles: []
	},
	messages: {
		messages: [],
		page: 1,
		lastPage: false
	},
	messagesSlider: {
		sliderIsOpen: messagesSliderEnum.closed,
		inputValue: ''
	},
	chatFiles: {
		sliderIsOpen: false,
		chatFiles: []
	},
	videoRecording: {
		isVideoInitialized: false,
		isVideoRecording: false,
		videoBlob: null,
		videoTimer: 0
	},
	forwardMessage: {
		modalIsOpen: false,
		messageId: null
	},
	deleteMessage: {
		modalIsOpen: false,
		messageId: null
	},
	scrollToTheLastMessage: false
}




type chatDesktopAction = 
    { type: chatDesktopActionTypes.setChatInfoSliderIsOpen, payload: boolean } |
    { type: chatDesktopActionTypes.setChatData, payload: chatDataType } |
    { type: chatDesktopActionTypes.setChatStatus, payload: chatStatusType } |
    { type: chatDesktopActionTypes.setCurrentChatAction, payload: currentChatActionType } |
    { type: chatDesktopActionTypes.setContextMenu, payload: contextMenuType } |
	{ type: chatDesktopActionTypes.setInputBannerIsOpen, payload: boolean } |
    { type: chatDesktopActionTypes.setChatInputValue, payload: string } |
	{ type: chatDesktopActionTypes.setAttachModalIsOpen, payload: boolean } |
    { type: chatDesktopActionTypes.setAttachedFiles, payload: file[] } |
    { type: chatDesktopActionTypes.setMessages, payload: messageType[] } |
    { type: chatDesktopActionTypes.setMessagesPage, payload: number } |
    { type: chatDesktopActionTypes.setLastPage, payload: boolean } |
    { type: chatDesktopActionTypes.setMessagesSliderIsOpen, payload: messagesSliderEnum } |
    { type: chatDesktopActionTypes.setMessagesSliderInputValue, payload: string } |
    { type: chatDesktopActionTypes.setChatFilesSliderIsOpen, payload: boolean } |
    { type: chatDesktopActionTypes.setChatFiles, payload: file[] } |
	{ type: chatDesktopActionTypes.setIsVideoInitialized, payload: boolean } |
	{ type: chatDesktopActionTypes.setIsVideoRecording, payload: boolean } |
	{ type: chatDesktopActionTypes.setVideoBlob, payload: Blob | null } |
	{ type: chatDesktopActionTypes.setVideoTimer, payload: number } |
	{ type: chatDesktopActionTypes.setForwardModalIsOpen, payload: boolean } |
	{ type: chatDesktopActionTypes.setForwardMessageId, payload: null | number } |
	{ type: chatDesktopActionTypes.setDeleteModalIsOpen, payload: boolean } |
	{ type: chatDesktopActionTypes.setDeleteMessageId, payload: null | number } |
	{ type: chatDesktopActionTypes.setScrollToTheLastMessage, payload: boolean }

export const chatDesktopReducer = (state: chatDesktopState, action: chatDesktopAction): chatDesktopState => {
	const { type, payload } = action

	switch (type){
	case chatDesktopActionTypes.setChatInfoSliderIsOpen:
		return {
			...state,
			chatInfo: {
				...state.chatInfo,
				sliderIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setChatData: 
		return {
			...state,
			chatInfo: {
				...state.chatInfo,
				data: payload
			}
		}
	case chatDesktopActionTypes.setChatStatus: 
		return {
			...state,
			chatInfo: {
				...state.chatInfo,
				status: payload 
			}
		}
	case chatDesktopActionTypes.setCurrentChatAction: 
		return {
			...state,
			currentChatAction: payload
		}
	case chatDesktopActionTypes.setContextMenu: 
		return {
			...state,
			contextMenu: payload
		}
	case chatDesktopActionTypes.setInputBannerIsOpen:
		return {
			...state,
			inputBanner: {
				...state.inputBanner,
				inputBannerIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setChatInputValue: 
		return {
			...state,
			chatInputValue: payload
		}
	case chatDesktopActionTypes.setAttachModalIsOpen:
		return {
			...state,
			attachFiles: {
				...state.attachFiles,
				modalIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setAttachedFiles:
		return {
			...state,
			attachFiles: {
				...state.attachFiles,
				attachedFiles: payload
			}
		}
	case chatDesktopActionTypes.setMessages:
		return {
			...state,
			messages: {
				...state.messages,
				messages: payload
			}
		}
	case chatDesktopActionTypes.setMessagesPage:
		return {
			...state,
			messages: {
				...state.messages,
				page: payload
			}
		}
	case chatDesktopActionTypes.setLastPage:
		return {
			...state,
			messages: {
				...state.messages,
				lastPage: payload
			}
		}
	case chatDesktopActionTypes.setMessagesSliderIsOpen:
		return {
			...state,
			messagesSlider: {
				...state.messagesSlider,
				sliderIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setMessagesSliderInputValue:
		return {
			...state,
			messagesSlider: {
				...state.messagesSlider,
				inputValue: payload
			}
		}
	case chatDesktopActionTypes.setChatFilesSliderIsOpen:
		return {
			...state,
			chatFiles: {
				...state.chatFiles,
				sliderIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setChatFiles:
		return {
			...state,
			chatFiles: {
				...state.chatFiles,
				chatFiles: payload
			}
		}
	case chatDesktopActionTypes.setIsVideoInitialized:
		return {
			...state,
			videoRecording: {
				...state.videoRecording,
				isVideoInitialized: payload
			}
		}
	case chatDesktopActionTypes.setIsVideoRecording:
		return {
			...state,
			videoRecording: {
				...state.videoRecording,
				isVideoRecording: payload
			}
		}
	case chatDesktopActionTypes.setVideoBlob:
		return {
			...state,
			videoRecording: {
				...state.videoRecording,
				videoBlob: payload
			}
		}
	case chatDesktopActionTypes.setVideoTimer:
		return {
			...state,
			videoRecording: {
				...state.videoRecording,
				videoTimer: payload
			}
		}
	case chatDesktopActionTypes.setForwardModalIsOpen:
		return {
			...state,
			forwardMessage: {
				...state.forwardMessage,
				modalIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setForwardMessageId:
		return {
			...state,
			forwardMessage: {
				...state.forwardMessage,
				messageId: payload
			}
		}
	case chatDesktopActionTypes.setDeleteModalIsOpen:
		return {
			...state,
			deleteMessage: {
				...state.deleteMessage,
				modalIsOpen: payload
			}
		}
	case chatDesktopActionTypes.setDeleteMessageId:
		return {
			...state,
			deleteMessage: {
				...state.deleteMessage,
				messageId: payload
			}
		}
	case chatDesktopActionTypes.setScrollToTheLastMessage:
		return {
			...state,
			scrollToTheLastMessage: payload
		}

	default:
		return state
	}
}