import { FC } from 'react'
import { videoPauseButton, videoPlayButton } from '../../../../assets/employees/chat/chatIcons'

type videoPlayerPlayButtonProps = {
    isPlaying: boolean
}

const VideoPlayerPlayButton: FC<videoPlayerPlayButtonProps> = ({ isPlaying }) => {
	return (
		<div className="video-player-controls-play-button">
			{ isPlaying ? videoPauseButton : videoPlayButton}
		</div>
	)
}

export default VideoPlayerPlayButton