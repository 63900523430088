// styles and icons
import './listItemCard.scss'
import { activeStatus, closedStatus } from '../../../assets/general/generalIcons'

//components
import InfoPopup from '../infoPopup/infoPopup'

// react
import { FC } from 'react'
import { Link } from 'react-router-dom'

// translation
import { useTranslation } from 'react-i18next'

//types
import { error, infoPopupTypes } from '../../../types/general/generalTypes'

type listItemCardProps = {
	title: string
	description: string
	isClosed?: boolean
	errors?: error[]
	data: {
		icon?: JSX.Element
		title: string
		value: string | JSX.Element
	}[]
	footer: {
		viewLink?: {
			text: string
			url: string
		},
		deleteFunction?: () => void
		payFunction?: () => void
	}
}

const ListItemCard: FC<listItemCardProps> = ({title, description, isClosed, errors, data, footer}) => {

	const { t } = useTranslation('', {keyPrefix:'common'})
	
	return (
		<div className='list-item-card'>
			<div>
				<div className="list-item-card-header">
					<p className='list-item-card-header-title'>{title}</p>
					{
						errors && errors.length > 0 ? 
							<InfoPopup 
								content={
									errors.map(error => {
										return {
											title: error.title,
											description: error.description,
											link: error.errorRootLink
										}
									})
								}
								type={infoPopupTypes.ERROR}
							/>
							: null
					}
					{
						isClosed !== undefined ? 
							isClosed ? closedStatus : activeStatus
							: null
					}
				</div>
				<p className='list-item-card-description'>{description}</p>
			</div>
			<div className='list-item-card-data'>
				{data.map((dataElem) => {
					return (
						<div className='list-item-card-data-item'>
							<div className='list-item-card-data-item-title'>{dataElem.icon ? dataElem.icon : null}<p>{dataElem.title}</p></div>
							<p className='list-item-card-data-item-value'>{dataElem.value}</p>
						</div>
					)
				})}
			</div>
			<div className='list-item-card-actions'>
				{ footer.viewLink && <Link to={footer.viewLink.url}>{footer.viewLink.text}</Link> }
				{ footer.payFunction && <button onClick={footer.payFunction}>{t('pay')}</button> }
				{ footer.deleteFunction && <button onClick={footer.deleteFunction}>{t('delete')}</button> }
			</div>
		</div>
	)
}

export default ListItemCard