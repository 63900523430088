import { baseUrl } from '../../network'

export const companyCustomersFiltersUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/customers/filters`
export const companyCustomersUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/customers`

export const singleCustomerUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}`

export const singleCustomerDocumentFiltersUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/documents/filters`
export const singleCustomerDocumentsUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/documents`
export const singleCustomerDocumentUrl = (fileId: number) => baseUrl + `/api/customer/documents/${fileId}`

export const singleCustomerOrderFiltersUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/orders/filters`
export const singleCustomerOrdersUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/orders`

export const singleCustomerBillFiltersUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/bills/filters`
export const singleCustomerBillsOverviewUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/bills/overview`
export const singleCustomerBillsUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/bills`

export const singleCustomersAddressesUrl = (relationshipId: number) => baseUrl + `/api/customer/${relationshipId}/addresses`
export const singleCustomersAddressUrl = (addressId: number) => baseUrl + `/api/customer/address/${addressId}`

export const companyCustomerAddressesUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/customers/addresses`

export const singleCustomerAccountUrl = (relationshipId:number) => baseUrl + `/api/customer/${relationshipId}/accounts`
export const singleCustomerAccountFiltersUrl = (relationshipId:number) => baseUrl + `/api/customer/${relationshipId}/accounts/filters`
export const singleCustomerAccountOverviewUrl = (relationshipId:number) => baseUrl + `/api/customer/${relationshipId}/accounts/overview`
export const companyCustomerAccountsUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/customers/accounts`
