// styles
import './createPositionModal.scss'

// react
import { Dispatch, FC, FormEvent, SetStateAction, useRef, useState } from 'react'

// components
import InputField from '../../../../../inputField/inputField'
import Modal from '../../../../../modals/modal/modal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../../customHooks/redux'
import { setCreatePositionModalIsOpen } from '../../../../../../../redux/general/settings/modals'

// types
import { selectionOption, skillResponse } from '../../../../../../../types/general/generalTypes'
import { position, positionResponse } from '../../../../../../../types/general/settings/companySectionTypes'

// translation
import { useTranslation } from 'react-i18next'

// network
import { authorizedRequest } from '../../../../../../../utils/queries'
import {getSkillsUrl, managePositionsUrl} from '../../../../../../../utils/urls/general/positions'


type createPositionModalProps = {
	positions: position[]
	setPositions: Dispatch<SetStateAction<position[]>>
}

const CreatePositionModal: FC<createPositionModalProps> = ({ positions, setPositions}) => {
	const { modalIsOpen } = useAppSelector(state => state.settingsModals.createPosition)
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)

	const dispatch = useAppDispatch()

	const [name, setName] = useState('')

	const formRef = useRef<HTMLFormElement | null>(null)

	const [ skillSearchRequest, setSkillSearchRequest ] = useState('')
	const [ selectedSkills, setSelectedSkills ] = useState<selectionOption[]>([])

	const {t} = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.createPositionModal'})
	const tSkill = useTranslation('', { keyPrefix: 'general.skills' }).t
	
	const closeModal = ()=> {
		setName('')
		setSkillSearchRequest('')
		setSelectedSkills([])
		dispatch(setCreatePositionModalIsOpen(false))
	}

	const getSkills = async (searchQuery: string, page: number)=> {
		const {result} = await authorizedRequest(getSkillsUrl() + `?needle=${searchQuery}&page=${page}&per_page=10` , 'GET')
		const skillsOptionList: selectionOption[] = result.map((skill: skillResponse) => {
			return {
				id: skill.id,
				text: tSkill(skill.name),
			}
		})
		
		return skillsOptionList
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>)=> {
		e.preventDefault()
		const {result}: {result: positionResponse} = await authorizedRequest(managePositionsUrl(settingsActiveCompanyId!), 'POST', 'accessToken', {
			name: name,
			skills: selectedSkills.map(skill => { return {id: skill.id} })
		})

		const createdPosition: position = result

		setPositions([createdPosition, ...positions])
		closeModal()
	}
	
	return (
		<Modal 
			title={t('createPosition')}
			open={modalIsOpen} 
			closeModal={closeModal} 
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>
			<form className='create-position-form' ref={formRef} onSubmit={handleSubmit}>
				<InputField 
					label={t('positionName')}
					value={name} 
					onChange={(e)=> setName(e.target.value)}
				/>
				<InputField
					type='text'
					label={t('skills')}
					value={skillSearchRequest}
					onChange={(e) => setSkillSearchRequest(e.target.value)}
					selection={{
						loadSelectionOptions: getSkills,
						selectedList: selectedSkills,
						setSelectedList: setSelectedSkills
					}}
				/>
			</form>
		</Modal>
	)
}

export default CreatePositionModal