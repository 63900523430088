//styles and icons
import './chatBarHeader.scss'
import { addChat } from '../../../../../assets/employees/chat/chatIcons'

//react
import { useState, useEffect } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyInboxesUrl } from '../../../../../utils/old_urls/employees/chatsUrls'

//components
import Button from '../../../button/button'
import InputField from '../../../inputField/inputField'
import Dropdown from '../../../dropdown/dropdown'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateGroupChatIsOpen } from '../../../../../redux/employees/chat/modals'
import { setActiveInboxOption, setChatSearchInput } from '../../../../../redux/employees/chat/chatBar'

// translation
import { useTranslation } from 'react-i18next'

//types
import { inboxResponse } from '../../../../../types/employees/chatTypes'

const ChatBarHeader = () => {
	const dispatch = useAppDispatch()

	const { chatSearchInput, activeInboxOption } = useAppSelector((state) => state.chatBar)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const [ showInboxes, setShowInboxes ] = useState(false)

	const {t} = useTranslation('')

	const loadInboxes = async (query: string, page: number) => {
		const { result }: { result: inboxResponse[] } = await authorizedRequest(companyInboxesUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
	
		if(result.length === 0){
			return []
		}
	
		return result.map((inbox: inboxResponse) => {
			return {
				title: inbox.name,
				key: `${inbox.id}`
			}
		})
	}

	useEffect(() => {
		loadInboxes('', 1)
			.then((response) => {
				if(response.length > 0){
					setShowInboxes(true)
				}
			})
	}, [])

	return (
		<div className='chat-bar-header-wrapper'>
			<Button active={true} onClick={() => {dispatch(setCreateGroupChatIsOpen(true))}} text={t('general.chats.chatBar.chatBarHeader.createGroupChat')} icon={addChat} />
			<InputField type='text' placeholder={t('common.search')} onChange={(e) => { dispatch(setChatSearchInput(e.target.value))}} value={chatSearchInput} name='search-chat' />
			{
				showInboxes && <Dropdown
					placeholder={t('inbox')}
					dropdownOptions={[]}
					loadOptions={loadInboxes}
					onSelect={(option) => {
						dispatch(setActiveInboxOption(option.key.length > 0 ? option : undefined))
					}}
					selectedOption={activeInboxOption}
				/>
			}
		</div>
	)
}

export default ChatBarHeader
