//styles
import './filterBarSlider.scss'
import { close } from '../../../../../assets/general/generalIcons'

//componentes
import DoubleInputRange from '../../../doubleInputRange/doubleInputRange'
import Dropdown from '../../../dropdown/dropdown'
import CalendarContainer from '../../../calendarContainer/calendarContainer'

//react
import { FC } from 'react'

//types
import { filters, filterTypes } from '../../../../../types/general/generalTypes'
import type { selectedDropdown } from '../filterBar'

//translation
import { useTranslation } from 'react-i18next'

type filterBarSliderProps = {
	visible: boolean
	notTranslated?: boolean
	setVisible: (value: boolean) => void
	filters: filters[]
	activeFilters: filters[]
	setActiveFilters: (value: filters[]) => void
	selectedOptions: selectedDropdown[]
	setSelectedOptions: (option: selectedDropdown[]) => void
}

const FilterBarSlider: FC<filterBarSliderProps> = ({ visible, setVisible, filters, activeFilters, setActiveFilters, selectedOptions, setSelectedOptions, notTranslated }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.filters' })

	return (
		<div className={`filter-bar-slider ${visible ? 'visible' : ''}`}>
			<div className="filter-bar-slider-header">
				<span onClick={() => {
					setVisible(false)
				}}>
					{close}
				</span>
			</div>
			{
				filters.map(({ filter, key }) => {

					switch (filter.type) {
					case filterTypes.DROPDOWN:
						return (
							<div className="filter-bar-dropdown-container">
								<Dropdown
									{...filter.data}
									label={t(filter.title)}
									placeholder={notTranslated ? filter.data.placeholder : t(filter.data.placeholder)}
									dropdownOptions={filter.data.dropdownOptions.map((data) => {
										return {
											...data,
											title: notTranslated ? data.title : t(data.title)
										}
									})}
									onSelect={(option) => {
										const activeFilter = activeFilters.find((activeFilter) => {
											return activeFilter.key === key
										})
										if (activeFilter) {
											activeFilter.filter.selectedOption = option
											setActiveFilters([...activeFilters])
											const existingSelectedOptionIndex = selectedOptions.findIndex((selectedOption) => {
												return selectedOption.key === key
											})
											console.log(key, selectedOptions)

											if(existingSelectedOptionIndex > -1){
												selectedOptions[existingSelectedOptionIndex].selected = option
												setSelectedOptions([...selectedOptions])
											}else{
												selectedOptions.push({ key: key, selected: option })
												setSelectedOptions([...selectedOptions])
											}

										}
									}}
									selectedOption={selectedOptions.find((option)=> option.key===key)?.selected}
									showAmount={filter.data.showAmount}
									key={`layout-filter-dropdown-options-${filter.data.placeholder}`}
								/>
							</div>
						)
					case filterTypes.CALENDAR: {
						return (
							<div className="filter-bar-calendar">
								<label className="filter-bar-calendar-label">{notTranslated ? filter.title : t(filter.title)}</label>
								<div className={'filter-bar-calendar-container'}>
									<CalendarContainer
										startDate={{
											date: filter.selectedOption.startDate,
											setDate: (value) => {
												const activeFilter = activeFilters.find((activeFilter) => {
													return activeFilter.key === key
												})

												if (activeFilter && activeFilter.filter.type === filterTypes.CALENDAR) {
													activeFilter.filter.selectedOption.startDate = value
													setActiveFilters([...activeFilters])
												}
											}
										}}
										dueDate={{
											date: filter.selectedOption.endDate,
											setDate: (value) => {
												const activeFilter = activeFilters.find((activeFilter) => {
													return activeFilter.key === key
												})

												if (activeFilter && activeFilter.filter.type === filterTypes.CALENDAR) {
													activeFilter.filter.selectedOption.endDate = value
													setActiveFilters([...activeFilters])
												}
											}
										}}
										size='medium'
										popup={false}
									/>
								</div>
							</div>

						)
					}
					case filterTypes.RANGE:
						return (
							<div className="filter-bar-input-range-container">
								<DoubleInputRange
									title={notTranslated ? filter.title : t(filter.title)}
									size='small'
									min={{
										default: filter.data.min,
										value: filter.selectedOption.min,
										setValue: (value) => {
											const activeFilter = activeFilters.find((activeFilter) => {
												return activeFilter.key === key
											})

											if (activeFilter && activeFilter.filter.type === filterTypes.RANGE) {
												activeFilter.filter.selectedOption = { min: value, max: activeFilter.filter.selectedOption.max }
												setActiveFilters([...activeFilters])
											}
										}
									}}
									max={{
										default: filter.data.max,
										value: filter.selectedOption.max,
										setValue: (value) => {
											const activeFilter = activeFilters.find((activeFilter) => {
												return activeFilter.key === key
											})

											if (activeFilter && activeFilter.filter.type === filterTypes.RANGE) {
												activeFilter.filter.selectedOption = { min: activeFilter.filter.selectedOption.min, max: value }
												setActiveFilters([...activeFilters])
											}
										}
									}}

								/>
							</div>
						)
					}


				})
			}
		</div>
	)
}

export default FilterBarSlider