// styles
import './myTasksCalendarDay.scss'

// react
import { FC, ReactNode, useEffect, useState } from 'react'

// components
import TaskPreviewCard from '../taskPreviewCard/taskPreviewCard'

// types
import { task } from '../../../../../types/general/generalTypes'

// others
import { formatDateToHour } from '../../../../../assets/general/generalFunctions'

type myTasksCalendarDayProps = {
  date: Date;
  tasks: task<Date | undefined>[];
};

const convertTo12HourFormat = (hour: number) => {
	if (hour === 0 || hour === 24) {
		return '12am'
	} else if (hour === 12) {
		return '12pm'
	} else if (hour > 12) {
		return `${hour - 12}pm`
	} else {
		return `${hour}am`
	}
}

const hoursCell: ReactNode[] = []
for (let i = 0; i <= 24; i++) {
	hoursCell.push(
		<div key={`hour-${i + 1}`} className="my-tasks-calendar-day-table-cell">
			<p>{convertTo12HourFormat(i)}</p>
			<hr />
		</div>
	)
}

const MyTasksCalendarDay: FC<myTasksCalendarDayProps> = ({ date, tasks }) => {
	const [currentDate, setCurrentDate] = useState(new Date())
	const [filteredTasks, setFilteredTasks] = useState<task<Date | undefined>[]>(
		[]
	)

	useEffect(() => {
		setFilteredTasks([
			...tasks.filter((task) => {
				if (
					task.startDate &&
					task.endDate &&
					(date.getDate() < task.startDate.getDate() ||
					date.getDate() > task.endDate.getDate())
				)
					return false
				return true
			}),
		])

		const timer = setInterval(() => {
			setCurrentDate(new Date())
		}, 6000)

		return () => clearInterval(timer)
	}, [date])

	const convertTimeToNumber = (date: Date) => {
		const time = date.getHours() + date.getMinutes() / 60
		return time
	}

	const getWidth = () => {
		const relevantTasks = tasks.filter((task) => {
			if (!task.startDate || !task.endDate) return true
			if (
				task.startDate.getDate() > date.getDate() ||
				task.endDate!.getDate() < date.getDate()
			)
				return false
			return true
		})
		return relevantTasks.length * 20 + relevantTasks.length * 1.4
	}

	const getTaskHeight = (startDate: Date, endDate: Date) => {

		if (!startDate || !endDate) return '0vw'
		// if (currentDate.getDate() < endDate.getDate()) return '100vw'
		if (currentDate.getDate() > endDate.getDate()) return '0vw'
		const taskTop = 0.485 + convertTimeToNumber(startDate) * 2.47
		const taskBottom = 0.485 + convertTimeToNumber(endDate) * 2.47
		return `${taskBottom - taskTop}vw`
	}

	const getTaskTop = (startDate: Date) => {
		if (!startDate) return '0.485vw'
		// if (currentDate.getDate() !== startDate.getDate()) return '0vw'
		return `${0.485 + convertTimeToNumber(startDate) * 2.47}vw`
	}

	const getTaskLeft = (index: number) => {
		return `${index * 21.4}vw`
	}

	return (
		<div className="my-tasks-calendar-day">
			<hr />
			<div className="my-tasks-calendar-day-table">
				<div className="my-tasks-calendar-day-table-container">
					{hoursCell}
					<div
						className="my-tasks-calendar-day-timer"
						style={{
							top: `${-0.485 + convertTimeToNumber(currentDate) * 2.47}vw`,
						}}
					>
						<div className="my-tasks-calendar-day-timer-time">
							{formatDateToHour(currentDate)}
						</div>
						<hr />
					</div>
					<div className="my-tasks-calendar-day-tasks">
						<div
							className="my-tasks-calendar-day-tasks-container"
							style={{ width: `${getWidth()}vw` }}
						>
							{filteredTasks.map((task, idx) => (
								<div
									key={`task-timeline-item-${task.id}`}
									className="my-tasks-calendar-day-tasks-item"
									style={{
										height: getTaskHeight(
											task.startDate as Date,
											task.endDate as Date
										),
										left: getTaskLeft(idx),
										top: getTaskTop(task.startDate as Date),
									}}
								>
									<TaskPreviewCard {...task} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<hr />
		</div>
	)
}

export default MyTasksCalendarDay
