//styles
import './clock.scss'

//react
import { FC } from 'react'

//components
import ProgressCircle from './progressCircle/progressCircle'
import Button from '../../button/button'

//translation
import {useTranslation} from 'react-i18next'

type clockProps = { 
    timeStamps: {start: number, end: number}[]
	clearShifts: () => void
}

const Clock: FC<clockProps> = ({ timeStamps, clearShifts }) => {
	const {t}=useTranslation('',{keyPrefix:'general.timeSelector'})

	return (
		<div className="clock-container">
			<ProgressCircle stamps={timeStamps} color='var(--accentPrimary)' width='90%' height='90%'/>
			

			<div className="clock">
				<div className="clear-button">
					<Button
						text={t('clear')}
						onClick={() => {
							clearShifts()
						}}
						active={false}
					/>
				</div>
				<div className="number number1"><div>1</div></div>
				<div className="number number2"><div>2</div></div>
				<div className="number number3"><div>3</div></div>
				<div className="number number4"><div>4</div></div>
				<div className="number number5"><div>5</div></div>
				<div className="number number6"><div>6</div></div>
				<div className="number number7"><div>7</div></div>
				<div className="number number8"><div>8</div></div>
				<div className="number number9"><div>9</div></div>
				<div className="number number10"><div>10</div></div>
				<div className="number number11"><div>11</div></div>
				<div className="number number12"><div>12</div></div>
				<div className="number number13"><div>13</div></div>
				<div className="number number14"><div>14</div></div>
				<div className="number number15"><div>15</div></div>
				<div className="number number16"><div>16</div></div>
				<div className="number number17"><div>17</div></div>
				<div className="number number18"><div>18</div></div>
				<div className="number number19"><div>19</div></div>
				<div className="number number20"><div>20</div></div>
				<div className="number number21"><div>21</div></div>
				<div className="number number22"><div>22</div></div>
				<div className="number number23"><div>23</div></div>
				<div className="number number24"><div>00</div></div>
			</div>
		</div>
       
	)     
}
  
  
export default Clock