// types
import { messageType, messageTypes, messageStatus,  audioWave, chatMessagesResponse, chatBarCard, chatResponse, inboxResponse, inbox } from '../../../types/employees/chatTypes'
import {file} from '../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../utils/queries'
import { getChatMessagesUrl, getMessageFilesUrl } from '../../../utils/old_urls/employees/chatsUrls'

//other
import { blobStringToBlob } from '../../general/generalFunctions'


type messageFilesResponse = {
	name: string
	file: string
	type: string
}

export const wavesGenerator = (audioBlob: Blob, container: HTMLDivElement, setWaves: React.Dispatch<React.SetStateAction<audioWave[]>>) => {
	const waves: audioWave[] = []

	audioBlob.arrayBuffer()
		.then((arrayBuffer) => {
			const audioCtx = new AudioContext()
			audioCtx.decodeAudioData(arrayBuffer)
				.then((buffer) => {

					const channelData = buffer.getChannelData(0)

					const containerWidth = container.clientWidth
					const cointainerHeight = container.clientHeight
            
					const wavesLimit = Math.floor(((containerWidth/document.documentElement.clientWidth)*100) / 0.55)


					const step = Math.ceil(channelData.length / wavesLimit)

					for (let i = 0; i < wavesLimit; i++) {
						let min = 1.0
						let max = -1.0
						for (let j = 0; j < step; j++) {
							const value = channelData[i * step + j]
							if (value > max) {
								max = value
							}
							if (value < min) {
								min = value
							}
						}
            
						let waveHeight = (1 + max) * cointainerHeight - (1 + min) * cointainerHeight

						if (waveHeight < 5){
							waveHeight = 5
						}else if(waveHeight > cointainerHeight){
							waveHeight = cointainerHeight
						}

						waves.push({height: waveHeight, filling: 100})
					}

					setWaves([...waves])
				})
		})
}


// get the file list of the message

export const getMessageFiles = async (messageId: number) => {
	const responce = await authorizedRequest(getMessageFilesUrl(messageId), 'GET')
	const files = responce.result
	const fileList: file[] = []

	files.forEach((fileData: messageFilesResponse) => {
		
		fileList.push({
			fileName: fileData.name,
			file: blobStringToBlob(fileData.file, fileData.type),
			fileType: fileData.type
		})
			
	})

	return fileList
}


// get messages function

export const getMessages = async (chatId: number, page: number, amountPerPage: number, query?: string, onlyPinned? : boolean) => {
	const getMessages = await authorizedRequest(getChatMessagesUrl(chatId, page, amountPerPage, query, onlyPinned), 'GET')

	const data: chatMessagesResponse[] = getMessages.result.reverse()
	const messagesData: messageType[] = []

	for(let i=0; i<data.length; i++){
		const message = data[i]
		const messageObj: messageType = formatMessage(message)

		
		if(message.files){
			const getFiles = await getMessageFiles(message.message_id)
			messageObj.file = getFiles
		}

		messagesData.push(messageObj)
	}

	return messagesData

}

export const formatMessage = (response: chatMessagesResponse): messageType => {
	const messageDate = new Date(response.date * 1000)
	const userId = parseInt(localStorage.getItem('userId') || '-1')

	return {
		userId: response.user_id,
		date: messageDate,
		text: response.text,
		file: [],
		messageId: response.message_id,
		edited: response.is_edited,
		pinned: response.is_pinned,
		forwarded: response.forwarded ? formatMessage(response.forwarded) : null,
		replied: response.replied ? formatMessage(response.replied) : null,
		type: response.user_id === userId ? messageTypes.USER : messageTypes.STRANGER,
		status: response.is_read ? messageStatus.READ : messageStatus.SENT
	}
}

export const formatChat = (response: chatResponse): chatBarCard => {
	return {
		avatar: response.avatar,
		chatId: response.chat_id,
		isGroup: response.is_group,
		message: response.last_message ? {
			userId: response.last_message.user_id,
			messageId: response.last_message.message_id,
			status: response.last_message.is_read ? messageStatus.READ : messageStatus.SENT,
			text: response.last_message.text,
			file: response.last_message.files,
		} : null,
		name: response.name,
		unreadMessages: response.un_read_messages,
		userId: response.user_id ? response.user_id : null
	}
}

export const formatInboxResponse = (response: inboxResponse): inbox => {
	return {
		id: response.id,
		description: response.description,
		name: response.name
	}
}

// Function to format time as MM:SS
export const formatTime = (seconds: number) => {
	const minutes = Math.floor(seconds / 60)
	const remainingSeconds = seconds % 60
	const formattedMinutes = (minutes < 10) ? '0' + minutes : minutes
	const formattedSeconds = (remainingSeconds < 10) ? '0' + remainingSeconds : remainingSeconds

	return `${formattedMinutes}:${Math.floor(+formattedSeconds) >= 10 ? Math.floor(+formattedSeconds) : `0${Math.floor(+formattedSeconds)}`}`
}

