//styles and icons
import './label.scss'

//react
import { FC } from 'react'  

// types
import { taskLabel } from '../../../types/general/generalTypes'

type labelProps = taskLabel

const Label:FC<labelProps> = ({text, color}) => {
	return (
		<div className="label" style={{backgroundColor: color }}> 
			<p>{text}</p>
		</div> 
	)

}

export default Label