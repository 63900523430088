// components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import {singleProjectUrl} from '../../../../../utils/urls/projects/project'

//react
import { FC, useState } from 'react'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteProjectModalIsOpen, setDeleteProjectId } from '../../../../../redux/projects/pipeline/modals'

//types
import { dragStage, project } from '../../../../../types/general/generalTypes' 

//translation
import { useTranslation } from 'react-i18next'

type deleteProjectConfirmationModalProps = {
	stagesList: dragStage<project>[]
	setStagesList: (value: dragStage<project>[]) => void
	projects: project[]
	setProjects: (value: project[]) => void
}

const DeleteProjectConfirmationModal: FC<deleteProjectConfirmationModalProps> = ({ stagesList, setStagesList, projects, setProjects }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.pipeline.modals.deleteProjectConfirmationModal' })

	const { modalIsOpen, projectId } = useAppSelector( ( state ) => state.pipelineModals.deleteProjectModal )

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch( setDeleteProjectModalIsOpen(false) )
		dispatch( setDeleteProjectId(null) )
	}

	const deleteProject = () => {
		if(projectId){
			setLoading(true)
			authorizedRequest(singleProjectUrl(projectId), 'DELETE')
				.then( ( response ) => {
					const stageIndex = stagesList.findIndex((item) => {
						return item.items.find((project) => project.id === projectId)
					})

					if(stageIndex > -1){
						stagesList[stageIndex].quantity -= 1
						setStagesList([...stagesList])

						setProjects([...projects.filter((project) => project.id !== projectId)])
					}

					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('deleteThisProject')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteProject }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeleteProjectConfirmationModal
