//components
import DocumentList from '../../../general/documentList/documentList'
import Pagination from '../../../general/pagination/pagination'
import DeleteEmployeeFileConfirmationModal from './modals/deleteEmployeeFileConfirmationModal/deleteEmployeeFileConfirmationModal'
import ImportEmployeeFileFileSelectorModal from './modals/importEmployeeFileFileSelectorModal/importEmployeeFileFileSelectorModal'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

//types
import { filterBar, filters, document } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {singleEmployeeFileFiltersUrl, singleEmployeeFilesUrl} from '../../../../utils/urls/employees/employee'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setDeleteEmployeeFileId, setDeleteEmployeeFileModalIsOpen, setImportEmployeeFileFileSelectorModalIsOpen } from '../../../../redux/general/employee/modals'

//other
import { blobStringToBlob, createFilteringRequest, formatFilters } from '../../../../assets/general/generalFunctions'

//translations
import { useTranslation } from 'react-i18next'


type employeeFilesProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}
type employeeFilesResponse = {
	file: string
	file_name: string
	file_type: string
	id: number
}

const EmployeeFiles: FC<employeeFilesProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.employee.employeeFiles' })
	const { employeeId } = useParams()

	const {userCompanyData} = useAppSelector(state => state.general)
	const dispatch = useAppDispatch()

	const [files, setFiles] = useState<document[]>([])
	const [searchRequest, setSearchRequest] = useState<string>('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)



	const loadFilterBar = async () => {
		const {result} = await authorizedRequest(singleEmployeeFileFiltersUrl(Number(employeeId)), 'GET')
		const formatedFilters: filters[] = formatFilters(result.filters)

		setFilterBar({
			filters: formatedFilters,
			addButton: {
				text: t('add'),
				active: result.create_access,
				onClick: () => {
					dispatch(setImportEmployeeFileFileSelectorModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)
		//need to check singleEmployeeFilesUrl POST on BE
		const { result } = await authorizedRequest(singleEmployeeFilesUrl(Number(employeeId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

		if (result.length > 0) {
			const formatedDocumentsData: document[] = result.map((document: employeeFilesResponse) => {
				return {
					file: blobStringToBlob(document.file, document.file_type),
					fileName: document.file_name,
					fileType: document.file_type,
					id: document.id
				}
			})
			return formatedDocumentsData
		}
		return []
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				setFiles([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setFiles([...files, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleDeleteEmployeeFile = (id: number) => {
		dispatch(setDeleteEmployeeFileId(id))
		dispatch(setDeleteEmployeeFileModalIsOpen(true))
	}

	return (
		<Pagination onLoadMore={handleLoadMore}>
			<DocumentList
				fileList={files.map((files) => ({ ...files }))}
				handleDelete={handleDeleteEmployeeFile}
			/>
			<DeleteEmployeeFileConfirmationModal files={files} setFiles={setFiles}/>
			<ImportEmployeeFileFileSelectorModal employeeFiles={files} setEmployeeFiles={setFiles}/>
		</Pagination>
	)
}

export default EmployeeFiles