// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../customHooks/redux'

const OfferingManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.sales.offering' })
	const { language } = useAppSelector(state => state.general)
	
	const data: manualData[] = [
		{
			title: t('createTitle'),
			text: t('createText')			
		},
		{
			title: t('deleteTitle'),
			text: t('deleteText')			
		}
	]
	
	return (
		<ManualLayout 
			heading={t('offeringManualTitle')}
			data={data}
		/>
	)
}

export default OfferingManual
