import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type pipelineModalsState = {
    createProjectModal: {
        modalIsOpen: boolean
		stageId: number | null
    }
	projectDocuments: {
		deleteProjectDocumentModal: {
			modalIsOpen: boolean
			documentId: number | null
		},
		importProjectDocumentFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
	}
	moveProjectModal:  {
        modalIsOpen: boolean
        projectId: number | null
    }
    deleteProjectModal: {
        modalIsOpen: boolean
        projectId: number | null
    }
    closeProjectModal: {
        modalIsOpen: boolean
        projectId: number | null
		action: 'close' | 'activate'
    }
}

const initialState: pipelineModalsState = {
	createProjectModal: {
		modalIsOpen: false,
		stageId: null
	},
	projectDocuments: {
		deleteProjectDocumentModal: {
			modalIsOpen: false,
			documentId: null
		},
		importProjectDocumentFileSelectorModal: {
			modalIsOpen: false,
			file: null
		}
	},
	moveProjectModal: {
		modalIsOpen: false,
		projectId: null
	},
	deleteProjectModal: {
		modalIsOpen: false,
		projectId: null
	},
	closeProjectModal: {
		modalIsOpen: false,
		projectId: null,
		action: 'close'
	}
}

export const pipelineModalsSlice = createSlice({
	name: 'pipelineModalsReducer',
	initialState,
	reducers: {
		setCreateProjectModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createProjectModal.modalIsOpen = action.payload
		},
		setCreateProjectStageId: (state, action: PayloadAction<number | null>) => {
			state.createProjectModal.stageId = action.payload
		},
		setMoveProjectModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.moveProjectModal.modalIsOpen = action.payload
		},
		setMoveProjectId: (state, action: PayloadAction<number | null>) => {
			state.moveProjectModal.projectId = action.payload
		},
		setDeleteProjectModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteProjectModal.modalIsOpen = action.payload
		},
		setDeleteProjectId: (state, action: PayloadAction<number | null>)=> {
			state.deleteProjectModal.projectId = action.payload
		},
		setCloseProjectModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.closeProjectModal.modalIsOpen = action.payload
		},
		setCloseProjectId: (state, action: PayloadAction<number | null>)=> {
			state.closeProjectModal.projectId = action.payload
		},
		setCloseProjectAction: (state, action: PayloadAction<'close' | 'activate'>)=> {
			state.closeProjectModal.action = action.payload
		},
		setDeleteProjectDocumentModalIsOpen:(state, action: PayloadAction<boolean>) => {
			state.projectDocuments.deleteProjectDocumentModal.modalIsOpen = action.payload
		},
		setDeleteProjectDocumentId: (state, action: PayloadAction<number | null>) => {
			state.projectDocuments.deleteProjectDocumentModal.documentId = action.payload
		},

		setImportProjectDocumentFileSelectorModalIsOpen : (state, action: PayloadAction<boolean>) => {
			state.projectDocuments.importProjectDocumentFileSelectorModal.modalIsOpen = action.payload
		},
		setImportProjectDocumentFileSelectorFile: (state, action: PayloadAction<file | null>) => {
			state.projectDocuments.importProjectDocumentFileSelectorModal.file = action.payload
		},
	}
})

export const {
	setCreateProjectModalIsOpen,
	setCreateProjectStageId,
	setMoveProjectModalIsOpen,
	setMoveProjectId,
	setDeleteProjectModalIsOpen,
	setDeleteProjectId,
	setCloseProjectModalIsOpen,
	setCloseProjectId,
	setDeleteProjectDocumentModalIsOpen,
	setDeleteProjectDocumentId,
	setImportProjectDocumentFileSelectorModalIsOpen,
	setImportProjectDocumentFileSelectorFile,
} = pipelineModalsSlice.actions

export default pipelineModalsSlice.reducer