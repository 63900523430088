//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { averageOrderVolume, averageSalesPerEmployee, marketingCampaignRevenue, newCustomersRatio, productServiceProfitability } from '../../../../utils/urls/sales/saleStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const ProductAndCustomerAnalysis = () => {
	const { t } = useTranslation('', { keyPrefix: 'sales.salesStats.productAndCustomerAnalysisTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadNewCustomersRatio = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(newCustomersRatio(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadAverageSalesPerEmployee = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(averageSalesPerEmployee(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadAverageOrderVolumes = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(averageOrderVolume(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadProductServiceProfitability = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(productServiceProfitability(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadMarketingCampaignRevenue = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(marketingCampaignRevenue(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('new_customers_ratio')} loadData={loadNewCustomersRatio} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('average_sales_per_employee')} loadData={loadAverageSalesPerEmployee} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('average_order_volume')} loadData={loadAverageOrderVolumes} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			{/* <div className='chart-group'>
				<ChartContainer title={t('product_service_profitability')} loadData={loadProductServiceProfitability} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div> */}
			<div className='chart-group'>
				<ChartContainer title={t('marketing_campaign_revenue')} loadData={loadMarketingCampaignRevenue} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default ProductAndCustomerAnalysis