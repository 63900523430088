import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type customerListModalsState = {
	createCustomerModal: {
		modalIsOpen: boolean
	},
	deleteCustomerModal: {
		modalIsOpen: boolean
        relationshipId: number | null
	}
	importCustomerFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    customerTablePreviewModal: {
        modalIsOpen: boolean
    }
	exportCustomerFileModal : {
		modalIsOpen: boolean
	}
}

const initialState: customerListModalsState = {
	createCustomerModal: {
		modalIsOpen: false,
	},
	deleteCustomerModal: {
		modalIsOpen: false,
		relationshipId: null
	},
	importCustomerFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	customerTablePreviewModal: {
		modalIsOpen: false
	},
	exportCustomerFileModal : {
		modalIsOpen: false
	}
}

export const customerListModalsSlice = createSlice({
	name: 'customerListModalsReducer',
	initialState,
	reducers : {
		setCreateCustomerModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createCustomerModal.modalIsOpen = action.payload
		},
		setDeleteCustomerModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteCustomerModal.modalIsOpen = action.payload
		},
		setDeleteCustomerRelationshipId: ( state, action: PayloadAction<number | null>) => {
			state.deleteCustomerModal.relationshipId = action.payload
		},
		setImportCustomerFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importCustomerFileSelectorModal.modalIsOpen = action.payload
		},
		setImportCustomerFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importCustomerFileSelectorModal.file = action.payload
		},
		setCustomerTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.customerTablePreviewModal.modalIsOpen = action.payload
		},
		setExportCustomerFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportCustomerFileModal.modalIsOpen = action.payload
		}
	}
})

export const { 
	setCreateCustomerModalIsOpen,
	setDeleteCustomerModalIsOpen,
	setDeleteCustomerRelationshipId,
	setImportCustomerFileSelectorModalIsOpen,
	setImportCustomerFileSelectorModalFile,
	setCustomerTablePreviewModalIsOpen,
	setExportCustomerFileModalIsOpen,
} = customerListModalsSlice.actions

export default customerListModalsSlice.reducer