//styles
import './inventoryItemImageList.scss'

//react
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//components
import DocumentList from '../../../../general/documentList/documentList'
import ImportInventoryImageSelectorModal from './modals/importInventoryImageSelectorModal/importInventoryImageSelectorModal'
import DeleteInventoryItemImageModal from './modals/deleteInventoryItemImageModal/deleteInventoryItemImageModal'
import FilterBar from '../../../../general/layout/filterBar/filterBar'

//redux
import { useAppDispatch } from '../../../../../customHooks/redux'
import { setImportInventoryItemImageSelectorModalIsOpen, setDeleteInventoryItemImageId, setDeleteInventoryItemImageModalIsOpen } from '../../../../../redux/warehouse/general/modals'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { inventoryItemImagesUrl } from '../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

//types
import { document } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { blobStringToBlob } from '../../../../../assets/general/generalFunctions'

const InventoryItemImageList = () => {
	const dispatch = useAppDispatch()

	const { inventoryItemId } = useParams()

	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryItem.inventoryItemDetails' })

	const [images, setImages] = useState<document[]>([])

	const loadData = async () => {
		const { result }: { result: { id: number, image: string }[] } = await authorizedRequest(inventoryItemImagesUrl(Number(inventoryItemId)), 'GET')

		const formatedDocuments = result.map((file, index) => {
			return {
				id: file.id,
				file: blobStringToBlob(file.image, 'image/*'),
				fileName: `Inventory-image-${index + 1}`,
				fileType: 'image/png'
			}
		})

		setImages(formatedDocuments)
	}

	useEffect(() => {
		loadData()
	}, [])

	return (
		<div className='inventory-item-images'>
			<div className='inventory-item-images-header'>
				<h3 className='inventory-item-images-header-heading'>{t('inventoryItemImages')}</h3>
				<FilterBar
					addButton={{
						text: t('add'),
						active: true,
						onClick: () => dispatch(setImportInventoryItemImageSelectorModalIsOpen(true))
					}}
				/>
			</div>
			<DocumentList
				fileList={images}
				handleDelete={(id) => {
					dispatch(setDeleteInventoryItemImageId(id))
					dispatch(setDeleteInventoryItemImageModalIsOpen(true))
				}}
			/>
			<ImportInventoryImageSelectorModal
				images={images}
				setImages={setImages}
			/>
			<DeleteInventoryItemImageModal
				images={images}
				setImages={setImages}
			/>
		</div>
	)
}

export default InventoryItemImageList