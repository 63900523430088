// styling
import './organizationChart.scss'
import 'reactflow/dist/style.css'

// react
import { FC, useCallback, useEffect, useState } from 'react'

// component
import ReactFlow, { Background, BackgroundVariant, Edge, MarkerType, Node, OnEdgesChange, OnNodesChange, applyEdgeChanges, applyNodeChanges, Controls } from 'reactflow'
import employeeNode from './employeeNode/employeeNode'
import Dagre from '@dagrejs/dagre'

// types
import { employee } from '../../../../types/employees/hierarchyTypes'
import { switchButton, employeeResponse } from '../../../../types/general/generalTypes'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//network
import { companyHierarchyTreeUrl } from '../../../../utils/urls/employees/hierarchy'
import { authorizedRequest } from '../../../../utils/queries'

// other
import { formatEmployeeResponse, vwToPixel } from '../../../../assets/general/generalFunctions'
import Loading from '../../../general/loading/loading'

type organizationChartProps = {
	getEmployeeActions: (employee: employee) => switchButton[]
}

const edgeStyle = () => {
	return {
		style: {
			strokeWidth: vwToPixel(.35),
			stroke: '#1E1B39',
		},
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 14,
			height: 12,
			color: '#1E1B39',

		},
		type: 'smoothstep',
		offset: vwToPixel(0.2)
	}
}


const nodeType = {
	employee: employeeNode
}

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

const generateLayout = (nodes: Node[], edges: Edge[], dagreConfig: { edgesep: number, ranksep: number }) => {
	g.setGraph({ rankdir: 'TB', edgesep: vwToPixel(dagreConfig.edgesep), ranksep: vwToPixel(dagreConfig.ranksep) })

	edges.forEach((edge) => g.setEdge(edge.source, edge.target))
	nodes.forEach((node) => g.setNode(node.id, node.data))

	Dagre.layout(g)

	return {
		nodes: nodes.map((node) => {
			const { x, y } = g.node(node.id)

			return { ...node, position: { x, y } }
		}),
		edges
	}
}

const mapEmployeeResponse = (response: any) => {
	const mapEmployeeTree = (response: any): employee => {
		return {
			...formatEmployeeResponse(response),
			subordinates: response.subordinates.map((employeeResponse: any) => mapEmployeeTree(employeeResponse))
		}
	}

	return mapEmployeeTree(response)

}

const OrganizationChart: FC<organizationChartProps> = ({ getEmployeeActions }) => {
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const [nodes, setNodes] = useState<Node[]>([])
	const [edges, setEdges] = useState<Edge[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const [dagreConfig, setDagreConfig] = useState({ edgesep: 25, ranksep: 15 })
	const [employeeTree, setEmployeeTree] = useState<employee | null>(null)

	const { modalIsOpen: fireEmployeeModalIsOpen } = useAppSelector((state) => state.employeesGeneralModal.fireEmployee)

	const getEmployeeTreeData = () => {
		authorizedRequest(companyHierarchyTreeUrl(companyId), 'GET').then((response) => {
			setLoading(true)
			if (response.result.length === 0) return
			const employeeTreeData = mapEmployeeResponse(response.result[0])
			setEmployeeTree(employeeTreeData)
		}).finally(() => {
			setLoading(false)
		})
	}

	const onNodesChange: OnNodesChange = useCallback((changes) => {
		setNodes((nds) => applyNodeChanges(changes, nds))
	}, [employeeTree])

	const onEdgesChange: OnEdgesChange = useCallback((changes) => {
		setEdges((eds) => applyEdgeChanges(changes, eds))
	}, [employeeTree])


	const generateNodes = (data: employee) => {

		const employeeStack = [data]

		const employeeNode: Node[] = []
		const employeeEdges: Edge[] = []

		while (employeeStack.length !== 0) {

			const currentEmployee = employeeStack.pop()

			if (currentEmployee) {

				// If there's still an employee create a new node
				employeeNode.push({
					id: `employee-${currentEmployee.employeeId}`,
					type: 'employee',
					position: {
						x: 0,
						y: 0
					},
					data: {
						employee: currentEmployee,
						actions: getEmployeeActions(currentEmployee)
					}
				})

			}


			if (currentEmployee?.subordinates) {
				employeeStack.push(...currentEmployee.subordinates)

				// Create a connection between the current employee and all of the subordinates
				currentEmployee.subordinates.forEach(subordinates => {
					employeeEdges.push(
						{
							id: `employee-${currentEmployee.employeeId}-employe-${subordinates.employeeId}`,
							source: `employee-${currentEmployee.employeeId}`,
							target: `employee-${subordinates.employeeId}`,
							...edgeStyle()
						}
					)
				})
			}
		}

		// Set automatic layout
		const layoutedData = generateLayout(employeeNode, employeeEdges, dagreConfig)

		// Update the nodes
		setEdges([...layoutedData.edges])
		setNodes([...layoutedData.nodes])
	}


	// Resize the distance between nodes whenever the screen changes
	const resizeLayout = () => {
		if (employeeTree) {
			generateNodes(employeeTree)
		}
	}

	const updateDagreConfig = () => {
		const width = window.innerWidth
		if (width > 768) {
			setDagreConfig({ edgesep: 25, ranksep: 15 })
		} else if (width > 480) {
			setDagreConfig({ edgesep: 35, ranksep: 25 })
		} else {
			setDagreConfig({ edgesep: 75, ranksep: 55 })
		}
	}

	useEffect(() => {
		if (employeeTree) {
			generateNodes(employeeTree)
		}

		window.addEventListener('resize', resizeLayout)
		return () => {
			window.removeEventListener('resize', resizeLayout)
		}

	}, [employeeTree])

	useEffect(() => {
		if (fireEmployeeModalIsOpen) return

		getEmployeeTreeData()
	}, [fireEmployeeModalIsOpen])
	// initial hierarchy structure generation and dagre confi
	useEffect(() => {
		getEmployeeTreeData()
		updateDagreConfig()

		window.addEventListener('resize', updateDagreConfig)

		return () => {
			window.removeEventListener('resize', updateDagreConfig)
		}
	}, [])

	return (
		<div id='organization-chart-container' className='organization-chart-container'>
			{!loading ? <ReactFlow
				nodes={nodes}
				edges={edges}
				maxZoom={4}
				onEdgesChange={onEdgesChange}
				onNodesChange={onNodesChange}
				nodeTypes={nodeType}
				nodesDraggable={false}
				fitView={true}
				preventScrolling={true}
				zoomOnDoubleClick={false}
			>
				<Controls showZoom={false} position={'top-right'} showInteractive={false} />
				<Background size={1} variant={BackgroundVariant.Cross} />
			</ReactFlow> : <Loading style={{ marginTop: '45vh', marginLeft: '10%' }} />}
		</div>
	)
}

export default OrganizationChart