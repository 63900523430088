// styles
import './hireEmployee.scss'

//react
import { useState, FC, useRef } from 'react'

//components
import InputField from '../../../../general/inputField/inputField'
import Modal from '../../../../general/modals/modal/modal'
import TimeSelector from '../../../../general/timeSelector/timeSelector'
import Dropdown from '../../../../general/dropdown/dropdown'
import TextArea from '../../../../general/textArea/textArea'
import PermissionSelector from '../../permissionSelector/permissionSelector'

//network
import { getCompanyPositionsUrl } from '../../../../../utils/old_urls/general/generalUrls'
import { authorizedRequest } from '../../../../../utils/queries'
import { hierarchyEmployeesUrl, hierarchyHireEmployeeInvitationSendUrl } from '../../../../../utils/urls/employees/hierarchy'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setHireEmployeeModalIsOpen } from '../../../../../redux/employees/general/modals'
import { setHierarchy } from '../../../../../redux/employees/hierarchy/general'

// translation
import { useTranslation } from 'react-i18next'

//types
import { currency, dropdownOption, infoPopup, infoPopupTypes, positionResponse, shifts, switchButton, weekDays } from '../../../../../types/general/generalTypes'
import { employee } from '../../../../../types/employees/hierarchyTypes'

//other
import {
	createDropdownOption,
	formatShiftForBackend
} from '../../../../../assets/general/generalFunctions'
import { getEmployeeHierarchyData } from '../../../../../assets/employees/hierarchy/hierarchyFunctions'

type hireErrors = {
	email?: string
	position?: string
	wage?: string
}

type hireEmployeeProps = {
	getEmployeeActions: (employee: employee) => switchButton[]
}

const HireEmployee: FC<hireEmployeeProps> = ({getEmployeeActions}) => {
	const { t } = useTranslation('', { keyPrefix: 'employees.general.modals.hireEmployee' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const { hireEmployee } = useAppSelector((state) => state.employeesGeneralModal)
	const { userCompanyData, language } = useAppSelector((state) => state.general)
	const { hierarchy } = useAppSelector((state) => state.hierarchyGeneral)

	const dispatch = useAppDispatch()

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)
	const [isLoading, setLoading] = useState<boolean>(false)
	const [position, setPosition] = useState<dropdownOption>()
	const [message, setMessage] = useState('Welcome to company')
	const [email, setEmail] = useState<string>('')
	const [wage, setWage] = useState<string>('')
	const [currencyOption, setCurrencyOption] = useState<dropdownOption>({
		title: tCurrency(currency.UAH),
		key: currency.UAH
	})
	const [errors, setErrors] = useState<hireErrors>({})

	const validEmail = (email: string): boolean => {
		const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/		
		return regex.test(email)
	}

	const positionPopup: infoPopup = {
		content: [
			{
				title: 'createOrPickCompany',
				description: 'createOrPickCompanyDescr',
			}
		],
		type: infoPopupTypes.INFO
	}


	const [shifts, setShifts] = useState<shifts>([
		{
			day: weekDays.mon,
			shift: [],
			active: true
		},
		{
			day: weekDays.tue,
			shift: [],
			active: false
		},
		{
			day: weekDays.wed,
			shift: [],
			active: false
		},
		{
			day: weekDays.thu,
			shift: [],
			active: false
		},
		{
			day: weekDays.fri,
			shift: [],
			active: false
		},
		{
			day: weekDays.sat,
			shift: [],
			active: false
		},
		{
			day: weekDays.sun,
			shift: [],
			active: false
		}
	])

	const [ permissions, setPermissions ] = useState<string[]>(['add_positions', 'edit_positions', 'delete_positions', 'add_labels', 'delete_labels', 'company_settings', 'get_orders', 'add_orders', 'edit_orders', 'delete_orders', 'hire_employees', 'move_employees', 'fire_employees', 'edit_employees', 'employee_statistics', 'add_pipelines', 'edit_pipelines', 'delete_pipelines', 'project_statistics', 'add_projects', 'edit_projects', 'delete_projects', 'add_tasks', 'edit_tasks', 'delete_tasks', 'get_customers', 'add_customers', 'edit_customers', 'delete_customers', 'sales_statistics', 'get_offerings', 'add_offerings', 'edit_offerings', 'delete_offerings', 'get_providers', 'add_providers', 'edit_providers', 'delete_providers', 'purchase_statistics', 'warehouse_statistics', 'get_warehouses', 'add_warehouses', 'edit_warehouses', 'delete_warehouses', 'get_warehouse_actions', 'add_warehouse_actions', 'edit_warehouse_actions', 'delete_warehouse_actions', 'get_inventory_items', 'add_inventory_items', 'edit_inventory_items', 'delete_inventory_items', 'finance_statistics', 'financial_reports', 'get_accounts', 'add_accounts', 'edit_accounts', 'delete_accounts', 'get_transactions', 'add_transactions', 'edit_transactions', 'delete_transactions', 'get_budgets', 'add_budgets', 'edit_budgets', 'delete_budgets', 'get_bills', 'add_bills', 'edit_bills', 'delete_bills'])

	const closeModal = () => {
		dispatch(setHireEmployeeModalIsOpen(false))
		setEmail('')
		setPosition(undefined)
		setWage('')
		setMessage('')
		setErrors({})
	}

	const loadPositionOptions = async (query: string, page: number) => {
		const { result }: { result: positionResponse[] } = await authorizedRequest(getCompanyPositionsUrl(companyId, query, page, 10), 'GET')

		if (result.length > 0) {
			const positions: dropdownOption[] = result.map((position: positionResponse) => {
				return {
					title: position.name,
					key: `${position.id}`
				}
			})
			return positions
		}

		return []

	}

	const checkErrors = () => {
		let hasErrors = false
		if (!validEmail(email) || email.length <= 0) {
			hasErrors = true
			setErrors({ ...errors, email: t('pleaseEnterTheMail') })
		}
		if (!position) {
			hasErrors = true
			setErrors({ ...errors, position: t('pleaseSelectThePosition') })
		}
		if (wage === '') {
			hasErrors = true
			setErrors({ ...errors, wage: t('pleaseEnterTheWage') })
		}
		if (message === '') {
			hasErrors = true
			setErrors({ ...errors, wage: t('pleaseEnterTheMessage') })
		}

		return hasErrors
	}

	const submitHandle = async() => {
		setLoading(true)
		const hasErrors = checkErrors()
		if (hasErrors) return setLoading(false)
		
		let leaderId = hireEmployee.leaderId

		if(hireEmployee.leaderId === undefined){
			const level = hierarchy.findIndex((column) => {
				return column.employees.find((employee) => employee.employeeId === hireEmployee.employeeId)
			})

			leaderId = hierarchy[level-1].employees.find((employee) => employee.active)?.employeeId
		}

		if (userCompanyData && leaderId) {
			const data = {
				email,
				link: 'https://ri-software.com.ua/accept-invitation/register/{TOKEN}',
				employee: {
					wage: Number(wage),
					currency: currencyOption.key,
					position_id: Number(position?.key),
					leader_id: hireEmployee.leaderId || null, 
					employee_id: hireEmployee.employeeId || null,
					company_id: companyId
				},
				shifts: formatShiftForBackend(shifts),
				message,
				navbar: permissions,
				language: language
			}

			const response = await authorizedRequest(hierarchyHireEmployeeInvitationSendUrl, 'POST', 'accessToken', data)

			if (response.result.Token) {
				let hierarchyIndex = -1

				// updating hierarchy
				hierarchy.forEach((column, index) => {

					const employee = column.employees.find((employee) => employee.employeeId === leaderId)

					if(employee){
						hierarchyIndex = index
					}
				})

				if(hierarchyIndex === -1){
					return
				}

				const updatedColumnEmployees = await getEmployeeHierarchyData(hierarchyEmployeesUrl(leaderId), getEmployeeActions)
				const updatedHierarchy = [...hierarchy.map((column, index) => {
					return {
						...column,
						employees: index === hierarchyIndex + 1 ? [...updatedColumnEmployees] : column.employees
					}
				})]

				dispatch(setHierarchy(updatedHierarchy))


			}
		}
		setLoading(false)
		closeModal()
	}

	return (
		<Modal
			closeModal={closeModal}
			open={hireEmployee.modalIsOpen}
			title={t('hireEmployee')}
			submitButton={{
				text: t('hireEmployee'),
				onClick: () => {
					submitHandle()
				}
			}}
			isLoading={isLoading}
		>
			<form className="hire-worker-form" onSubmit={submitHandle} ref={formRef}>
				<div className="hire-worker-content-container">
					<div className="hire-worker-content-input-container">
						<div className="hire-worker-content-field-container">
							<Dropdown
								placeholder={t('position')}
								label={t('position')}
								dropdownOptions={[]}
								loadOptions={loadPositionOptions}
								onSelect={(option) => {
									setErrors({})
									setPosition(option)
								}}
								// popup={positionPopup}
								error={errors.position}
								selectedOption={position}
							/>
						</div>
						<div className="hire-worker-content-field-container">
							<InputField
								type="email"
								value={email}
								label={t('email')}
								onChange={(e) => {
									setErrors({})
									setEmail(e.target.value)
								}}
								error={errors.email}
							/>
						</div>
						<div className="hire-worker-content-field-container">
							<InputField
								type="text"
								value={wage}
								label={t('wage')}
								onChange={(e) => {
									setErrors({})
									setWage(`${e.target.value.replace(/\D/g, '').length > 0 ? parseInt(e.target.value.replace(/\D/g, '')) : ''}`)
								}}
								error={errors.wage}
							/>
						</div>
						<div className="hire-worker-content-field-container">
							<Dropdown
								label={t('currency')}
								placeholder={tCurrency(currencyOption?.key)}
								dropdownOptions={createDropdownOption(tCurrency, currency)}
								onSelect={(option) => {
									setCurrencyOption(option)
								}}
								selectedOption={currencyOption}
							/>
						</div>
						<div className="hire-worker-content-field-container full-size message-textarea">
							<TextArea
								label={t('message')} value={message} setValue={(value) => { setMessage(value) }} />
						</div>
						<div className="hire-worker-content-field-container full-size">
							<TimeSelector shifts={shifts} setShifts={(value) => { setShifts(value) }} />
						</div>
					</div>
					<div className="hire-worker-functions">
						<p className="hire-worker-functions-title">{t('userFunction.title')}</p>
						<PermissionSelector permissions={permissions} setPermissions={setPermissions} />
					</div>
				</div>
			</form>
		</Modal>
	)
}

export default HireEmployee