import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type projectModalsState = {
    exportProjectReport: {
        modalIsOpen: boolean
    }
}

const initialState: projectModalsState = {
	exportProjectReport: {
		modalIsOpen: false
	}
}

export const projectModalsSlice = createSlice({
	name: 'projectModalsReducer',
	initialState,
	reducers: {
		setExportProjectReportModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportProjectReport.modalIsOpen = action.payload
		}
	}
})

export const {
	setExportProjectReportModalIsOpen
} = projectModalsSlice.actions

export default projectModalsSlice.reducer