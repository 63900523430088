//styles
import './inputRange.scss'

//react
import { FC, useState, useEffect, useRef } from 'react'

type inputRangeProps = {
    min: number
    max: number
    value: number
    setValue: (value: number) => void
}

const InputRange: FC<inputRangeProps> = ({ min, max, value, setValue }) => {

	const sldierRef = useRef<HTMLDivElement | null>(null)

	const [ offset, setOffset ] = useState(0)
    
	useEffect(() => {
		setOffset(Math.min(percentOffset(value), max))
	}, [value])

	const percentOffset = (value: number) => {
		return (value-min)/(max-min) * 100
	}

	const handleDragStart = () => {
		// Add event listeners for drag-related events
		document.addEventListener('mousemove', handleDrag)
		document.addEventListener('mouseup', handleDragEnd)
	}

	const handleDragEnd = () => {
		// Remove event listeners for drag-related events
		document.removeEventListener('mousemove', handleDrag)
		document.removeEventListener('mouseup', handleDragEnd)
	}


	const handleDrag = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		console.log(event.target)

		const slider = sldierRef.current
		const minMaxDiff = max - min

		if(slider){
			const sliderRect = slider.getBoundingClientRect()

			const offsetX = Math.min(Math.max(0, event.clientX - sliderRect.left), sliderRect.width)
			const percent = offsetX/sliderRect.width

			setValue(Math.floor(Math.min(percent*minMaxDiff + min, max)))
		}
	}


	return (
		<div className="input-range-slider" ref={sldierRef}>
			<div
				style={{
					background: `linear-gradient(to right, #1E1B39 ${offset}%, #D9D9D9 ${offset}%)`
				}}
				className="input-range-slider-progress"
			></div>
            
			<div
				style={{
					left: `calc(${offset}% - 0.5vw)`
				}}
				className="input-range-slider-ball" 
				onMouseDown={() => {
					handleDragStart()
				}}
			></div>
            
		</div>
	)
}

export default InputRange