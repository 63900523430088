// styles
import './processing.scss'

// react
import {FC} from 'react'

//types
import {process} from '../../../types/types'

type processingProps = {
    content: process[]
}

const Processing: FC<processingProps> = ({content}) => {
	return (
		<div className='processing-wrapper'>
			{content.map((step: process, index: number) => (
				<>
					<div key={index} className="processing-step">
						<div className="step-icon">{step.icon}</div>
						<div className="step-text-wrapper">
							<p className="step-text">{step.text}</p>
						</div>
						<div className="processing-arrow">
							<div className="processing-arrow-dashed"></div>
							<div className="processing-arrow-solid"></div>
						</div>
						<div className="side-arrow-right"></div>
						<div className="side-arrow-left"></div>
					</div>
				</>
			))}
		</div>
	)
}

export default Processing