// styles
import './projectsList.scss'

// react
import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import DashboardCard from '../dashboard/dashboardStage/dashboardCard/dashboardCard'
import ColumnLayout from '../columnLayout/columnLayout'
import Pagination from '../pagination/pagination'
import CounterpartySkeleton from '../skeletons/counterParty/counterPartySkeleton'

// types
import { project, filterBar} from '../../../types/general/generalTypes'

//other
import { getItemsPerColumn } from '../../../assets/general/generalFunctions'

type projectsListProps = {
	setFilterBar: (value: filterBar | undefined) => void
	loadProjects: (stageId: number, searchRequest: string, page: number, showClosed: boolean) => Promise<project[]>
	showClosed?: boolean
}

const ProjectsList: FC<projectsListProps> = ({ showClosed=false, loadProjects, setFilterBar }) => {
	const navigate = useNavigate()
	const [projects, setProjects] = useState<project[]>([])
	const [filteredProjects, setFilteredProjects] = useState<project[]>([])

	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [page, setPage] = useState<number>(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')


	const loadMore = async () => {
		try {
			if (lastPage){
				return
			}

			setLoading(true)

			const projectRes: project[] = await loadProjects(-1, searchQuery, page, showClosed)

			setProjects([...projects, ...projectRes])

			if (projectRes.length<1){
				setLastPage(true)
				return
			}

			setPage(page+1)

		}finally {
			setLoading(false)
		}
	}

	const onSearch = async (query: string) => {
		setLoading(true)
		setPage(1)
		setSearchQuery(query)
		
		const projectRes: project[] = await loadProjects(-1, query, 1, showClosed)
		setLoading(false)
		setPage(2)

		setProjects([...projectRes])
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		setFilterBar({
			onSearch: onSearch
		})

		window.addEventListener('resize', handleResize)

		return () => {
			setFilterBar(undefined)
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		onSearch('')
	}, [showClosed])

	useEffect(() => {
		setFilteredProjects(projects)
	}, [projects])

	return (
		<div className='list-container'>
			<Pagination loadAxis={'vertical'} loading={loading} onLoadMore={loadMore} showLoader={false}>
				<ColumnLayout amount={itemsPerColumn} >
					{filteredProjects.filter(elem => showClosed ? true : !elem.isClosed).map((project) => {
						return (
							<DashboardCard {...project} onClick={() => {
								navigate(`/ri-business/projects/project/${project.id}`)
							}} />
						)
					})}
					{ loading ? <CounterpartySkeleton/> : null }
				</ColumnLayout>
			</Pagination>
		</div>
	)
}

export default ProjectsList