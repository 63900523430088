// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const SinglePipelineManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.projects.singlePipeline' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('createProjectTitle'),
			text: t('createProjectText')			
		},
		{
			title: t('deleteProjectTitle'),
			text: t('deleteProjectText')
		},
		{
			title: t('closeProjectTitle'),
			text: t('closeProjectText')
		},
		{
			title: t('moveProjectTitle'),
			text: t('moveProjectText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('singlePipelineManualTitle')}
			data={data}
		/>
	)
}

export default SinglePipelineManual
