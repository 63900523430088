

export const moveEmployeeButton = <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id="mask0_248_1891" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0">
		<rect x="0.437256" y="0.887207" width="48" height="48" fill="#bbb"/>
	</mask>
	<g mask="url(#mask0_248_1891)">
		<path d="M26.9372 24.837L18.0872 15.987C17.7872 15.687 17.6455 15.337 17.6622 14.937C17.6789 14.537 17.8372 14.187 18.1372 13.887C18.4372 13.587 18.7955 13.437 19.2122 13.437C19.6289 13.437 19.9872 13.587 20.2872 13.887L30.1872 23.787C30.3539 23.9537 30.4705 24.1203 30.5372 24.287C30.6039 24.4537 30.6372 24.637 30.6372 24.837C30.6372 25.037 30.6039 25.2203 30.5372 25.387C30.4705 25.5537 30.3539 25.7203 30.1872 25.887L20.2372 35.837C19.9372 36.137 19.5872 36.2787 19.1872 36.262C18.7872 36.2453 18.4372 36.087 18.1372 35.787C17.8372 35.487 17.6872 35.1287 17.6872 34.712C17.6872 34.2953 17.8372 33.937 18.1372 33.637L26.9372 24.837Z"/>
	</g>
</svg>

export const replaceEmployeeButton = <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8 16C5.76667 16 3.875 15.225 2.325 13.675C0.775 12.125 0 10.2333 0 8C0 5.76667 0.775 3.875 2.325 2.325C3.875 0.775 5.76667 0 8 0C9.15 0 10.25 0.237666 11.3 0.713C12.35 1.18767 13.25 1.86667 14 2.75V1C14 0.716667 14.096 0.479 14.288 0.287C14.4793 0.0956668 14.7167 0 15 0C15.2833 0 15.5207 0.0956668 15.712 0.287C15.904 0.479 16 0.716667 16 1V6C16 6.28333 15.904 6.52067 15.712 6.712C15.5207 6.904 15.2833 7 15 7H10C9.71667 7 9.47933 6.904 9.288 6.712C9.096 6.52067 9 6.28333 9 6C9 5.71667 9.096 5.479 9.288 5.287C9.47933 5.09567 9.71667 5 10 5H13.2C12.6667 4.06667 11.9377 3.33333 11.013 2.8C10.0877 2.26667 9.08333 2 8 2C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8C2 9.66667 2.58333 11.0833 3.75 12.25C4.91667 13.4167 6.33333 14 8 14C9.15 14 10.2127 13.6957 11.188 13.087C12.1627 12.479 12.8917 11.6667 13.375 10.65C13.4583 10.4667 13.596 10.3127 13.788 10.188C13.9793 10.0627 14.175 10 14.375 10C14.7583 10 15.046 10.1333 15.238 10.4C15.4293 10.6667 15.45 10.9667 15.3 11.3C14.6667 12.7167 13.6917 13.854 12.375 14.712C11.0583 15.5707 9.6 16 8 16Z" fill="#2CD751"/>
</svg>

export const sliderPointerIcon = <svg viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10 -4.37114e-07L5 5L-2.18557e-07 0L10 -4.37114e-07Z" fill="#444444" />
</svg>