//styles
import './confirmationModal.scss'

//react
import { FC } from 'react'

//components
import Button from '../../button/button'
import Modal from '../modal/modal'
import CounterpartyCard from '../../counterpartyCard/counterpartyCard'
import ShipmentCard from '../../shipmentCard/shipmentCard'
import BudgetListItem from '../../budgetListItem/budgetListItem'
import OrderCard from '../../orderCard/orderCard'
import Loading from '../../loading/loading'
import AccountListItem from '../../../finance/accountList/accountListItem/accountListItem'
import DashboardCard from '../../dashboard/dashboardStage/dashboardCard/dashboardCard'
import BillListItem from '../../billListItem/billListItem'
import OfferingCard from '../../offeringCard/offeringCard'

//types
import { detailedErrorObject, detailedErrorObjectTypes } from '../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type confirmationModalButton = {
    text: string
    onClickHandler: () => void
}

type confirmationModalProps = {
	title: string
	buttons: confirmationModalButton[]
	isOpen: boolean
	closeModal: () => void
	description?: string
	errorObjects?: detailedErrorObject[]
	loading?: boolean
}

const ConfirmationModal: FC<confirmationModalProps> = ({ title, buttons, isOpen, closeModal, description, errorObjects, loading }) => {

	const {t} = useTranslation('', {keyPrefix:'general.error.errorList'})

	const getErrorObject = (errorObject: detailedErrorObject) => {
		switch(errorObject.type){
		case detailedErrorObjectTypes.EMPLOYEE:
			return <CounterpartyCard {...errorObject.object} link='#'/>
		case detailedErrorObjectTypes.BUDGET:
			return <BudgetListItem {...errorObject.object}/>
		case detailedErrorObjectTypes.SHIPMENT:
			return <ShipmentCard {...errorObject.object} link='#'/>
		case detailedErrorObjectTypes.ORDER:
			return <OrderCard {...errorObject.object} link='#'/>
		case detailedErrorObjectTypes.ACCOUNT:
			return <AccountListItem {...errorObject.object} />
		case detailedErrorObjectTypes.PROJECT:
			return <DashboardCard {...errorObject.object}/>
		case detailedErrorObjectTypes.BILL:
			return <BillListItem {...errorObject.object}/>
		case detailedErrorObjectTypes.TASK:
			return <DashboardCard {...errorObject.object} />
		case detailedErrorObjectTypes.INVENTORY:
			return <OfferingCard {...errorObject.object} link='#'/>
		default:
			return null
		}
	}

	return (
		<Modal
			closeModal={closeModal}
			closeButton={false}
			open={isOpen}
			title={title}
			isLoading={loading}
		>
			<div className="confirmation-modal-container">
				{
					description && 
					<div className="description-container">
						<p className="description-content">
							{description}
						</p>
					</div>
				}
				<>
					{
						errorObjects && errorObjects.length > 0 ?
							<div className="error_objects-container">
								{
									errorObjects.map((errorObject) => {
										return getErrorObject(errorObject)
									})
								}
							</div>
							: errorObjects ? <p className="description-content">{t('nothingCanBeCreated')}</p> : null
					}
					{
						errorObjects ? 
							errorObjects.length > 0 ? 
								<div className="buttons-container">
									{
										buttons.map(({text, onClickHandler}) =>
											<Button active={true} disabled={loading} text={text} onClick={() => {
												onClickHandler()
											}}/>
										)
									}
								</div>
								: null
							: 
							<div className="buttons-container">
								{
									buttons.map(({text, onClickHandler}) =>
										<Button active={true} disabled={loading} text={text} onClick={() => {
											onClickHandler()
										}}/>
									)
								}
							</div>
					}
				</>

			</div>
		</Modal>
	)
}
export default ConfirmationModal