import { dragItem, dragLocation } from '../../../../types/general/generalTypes'

export enum dragActionType {
	setDropInfo,
	setDragInfo,
	setIsDragging
} 
	
export type dragState = {
	dropInfo: dragLocation | null
	dragInfo: dragLocation | null
	dragData: dragItem | null
	isDragging: boolean
}

export type dragAction = 
	{ type: dragActionType.setDragInfo, payload: { dragData: dragItem, dragInfo: dragLocation | null } } |
	{ type: dragActionType.setDropInfo, payload: { dropInfo: dragLocation | null } } | 
	{ type: dragActionType.setIsDragging, payload: boolean } 
