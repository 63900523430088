// styles
import './createWarehouseAction.scss'

//react
import { FC, useState } from 'react'

// components
import Dropdown from '../../dropdown/dropdown'
import EditableTable from '../../editableTable/editableTable'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { companyWarehouseActionLocationsUrl } from '../../../../utils/urls/warehouses/warehouseActions/warehouseActions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// types
import {
	dropdownOption,
	inventoryItem,
	inventoryItemResponse,
	locationResponse,
} from '../../../../types/general/generalTypes'

//other
import { formatInventoryItemResponse } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'
import { companyWarehouseInventoryUrl } from '../../../../utils/urls/warehouses/warehouses/warehouses'

type createWarehouseActionProps = {
	selectedInventoryItems: inventoryItem[]
	setSelectedWarehouseOriginOption: (value: dropdownOption | null) => void
	setSelectedWarehouseDestinationOption: (value: dropdownOption | null) => void
	setSelectedAddressOriginOption: (value: dropdownOption | null) => void
	setSelectedAddressDestinationOption: (value: dropdownOption | null) => void
	setSelectedInventoryItems: (value: inventoryItem[]) => void
	selectedAddressOriginOption: dropdownOption | null
	selectedAddressDestinationOption: dropdownOption | null
	selectedWarehouseDestinationOption: dropdownOption | null
	selectedWarehouseOriginOption: dropdownOption | null
	originError?: string
	destinationError?: string
	itemsError?: string
}

const CreateWarehouseAction: FC<createWarehouseActionProps> = ({ setSelectedWarehouseOriginOption, setSelectedWarehouseDestinationOption, setSelectedAddressOriginOption, setSelectedAddressDestinationOption, selectedInventoryItems, setSelectedInventoryItems, originError, destinationError, itemsError, selectedAddressDestinationOption, selectedAddressOriginOption, selectedWarehouseDestinationOption, selectedWarehouseOriginOption }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.modals.task.createTaskModal' })

	const [inventoryItems, setInventoryItems] = useState<inventoryItem[]>([])

	const { userCompanyData } = useAppSelector((state) => state.general)
	const companyId: number = userCompanyData?.companyId || -1

	const loadLocations = async (query: string, page: number): Promise<dropdownOption[]> => {
		return await authorizedRequest(companyWarehouseActionLocationsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET').then(({ result }) => {

			if (page > 1) return []
			
			return result.map((item: locationResponse) => {
				return {
					key: `${item.id} ${item.type}`,
					title: item.address
				}
			})
		})
	}

	const loadInventoryItems = async (searchQuery: string, page: number, companyId: number) => {
		const { result }: { result: inventoryItemResponse[] } = await authorizedRequest(companyWarehouseInventoryUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')

		if (result && result.length > 0) {
			const itemsData: inventoryItem[] = result.map(formatInventoryItemResponse)
			return itemsData
		} else {
			return []
		}
	}

	return (
		<div className='warehouse-section-container'>
			<div className="warehouse-fields">
				<Dropdown
					label={t('origin')}
					placeholder={t('origin')}
					dropdownOptions={[]}
					onSelect={(option) => {
						const optionArray = option.key.split(' ')

						setSelectedWarehouseOriginOption(null)
						setSelectedAddressOriginOption(null)
						if (optionArray[1] == 'warehouse') {
							setSelectedWarehouseOriginOption({key:optionArray[0], title:option.title})
						} else {
							setSelectedAddressOriginOption({key:optionArray[0], title:option.title})
						}
					}}
					selectedOption={selectedAddressOriginOption || selectedWarehouseOriginOption}
					loadOptions={loadLocations}
					error={originError}
				/>
				<Dropdown
					label={t('destination')}
					placeholder={t('destination')}
					dropdownOptions={[]}
					onSelect={(option) => {
						const optionArray = option.key.split(' ')

						setSelectedWarehouseDestinationOption(null)
						setSelectedAddressDestinationOption(null)
						if (optionArray[1] == 'warehouse') {
							setSelectedWarehouseDestinationOption({key:optionArray[0], title:option.title})
						} else {
							setSelectedAddressDestinationOption({key:optionArray[0], title:option.title})
						}
					}}
					selectedOption={selectedWarehouseDestinationOption || selectedAddressDestinationOption}
					loadOptions={loadLocations}
					error={destinationError}
				/>
			</div>
			<div className="warehouse-inventory-selection">
				<EditableTable
					items={inventoryItems}
					setItems={(value) => {
						setInventoryItems(value as inventoryItem[])
					}}
					loadItems={(request, page) => loadInventoryItems(request, page, companyId)}
					selected={{
						items: selectedInventoryItems,
						setItems: (value) => {
							setSelectedInventoryItems(value as inventoryItem[])
						}
					}}
					error={itemsError}
					limitMaxQuantity={false}
				/>
			</div>
		</div>
	)
}

export default CreateWarehouseAction
