//styles and icons
import './formError.scss'
import { errorAlert } from '../../../assets/general/generalIcons' 

//react
import { FC } from 'react'


export type formErrorProps = {
	errorText: string,
	appear: boolean
}

const FormError: FC<formErrorProps> = ({ errorText, appear }) => {
	return (
		<>
			{appear || errorText !== '' ? (
				<div className="error-container">
					{errorAlert}
					<p>{errorText}</p>
				</div>
			) : null}
		</>
	)
}

export default FormError