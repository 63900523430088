// react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteOfferingInventoryItemId, setDeleteOfferingInventoryItemModalIsOpen } from '../../../../../../redux/sales/offering/modals'

// translation
import { useTranslation } from 'react-i18next'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleOfferingItemUrl } from '../../../../../../utils/urls/sales/offerings'


//types
import { inventoryItem } from '../../../../../../types/general/generalTypes'

type deleteOfferingInventoryItemModalProps = {
	offeringInventoryItems: inventoryItem[]
	setOfferingInventoryItems: (value: inventoryItem[]) => void
}

const DeleteOfferingInventoryItemModal: FC<deleteOfferingInventoryItemModalProps> = ({offeringInventoryItems, setOfferingInventoryItems}) => {	const {modalIsOpen, inventoryItemId} = useAppSelector(state => state.offeringModal.offeringInventoryItemList.deleteOfferingInventoryItemModal)

	const dispatch = useAppDispatch()
	const {t} = useTranslation('', {keyPrefix: 'sales.offering.offeringInventoryItemList.modals.deleteOfferingInventoryItemModal'})

	const [loading, setLoading] = useState<boolean>(false)

	const { offeringId } = useParams()

	const closeModal = () => {
		dispatch(setDeleteOfferingInventoryItemModalIsOpen(false))
		dispatch(setDeleteOfferingInventoryItemId(null))
	}

	const deleteInventoryItem = () => {		
		if(inventoryItemId && offeringId){
			setLoading(true)
			authorizedRequest(singleOfferingItemUrl(Number(inventoryItemId)), 'DELETE', 'accessToken', {
				offering_id: Number(offeringId)
			})
				.then(response => {
					setOfferingInventoryItems(offeringInventoryItems.filter(item => item.inventoryItemId !== inventoryItemId))
					setLoading(false)
					closeModal()
				})	
		}
	}
	return (
		<ConfirmationModal
			title={t('deleteThisInventoryItem')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteInventoryItem }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeleteOfferingInventoryItemModal