//styles
import './report.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import Table from '../table/table'
import Pagination from '../pagination/pagination'
import ExportReportFileModal from './modals/exportReportFileModal/exportReportFileModal'

//network
import { authorizedRequest } from '../../../utils/queries'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import { setExportReportFileModalIsOpen } from '../../../redux/general/modals'

//types
import { filterBar, filters, moneyValue, tableHeader, tableHeaderResponse } from '../../../types/general/generalTypes'

//other
import { createFilteringRequest, currencyToFormattedString, formatFilters } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type reportProps = {
    reportUrl: string
    setFilterBar: (value: filterBar) => void
    total?: boolean
    totalIncome?: boolean
    totalExpenses?: boolean
}

const Report: FC<reportProps> = ({ reportUrl, setFilterBar, total, totalIncome, totalExpenses }) => {

	const { t } = useTranslation('', {keyPrefix: 'general.report'})

	const dispatch = useAppDispatch()

	const [columns, setColumns] = useState<tableHeader[]>([])

	const [data, setData] = useState<Record<string, JSX.Element | string | number>[]>([])

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	
	const [incomeTotal, setIncomeTotal] = useState<moneyValue[]>([])
	const [expenseTotal, setExpenseTotal] = useState<moneyValue[]>([])	
	const [overallTotal, setOverallTotal] = useState<moneyValue[]>([])


	const loadHeader = async () => {

		const { result } = await authorizedRequest(reportUrl + '?type=header', 'GET')

		setColumns([...result.map((item: tableHeaderResponse) => {
			return {
				key: item.key,
				title: item.title,
				translate: item.translate,
				date: item.date
			}
		})])
	} 

	const loadTotals = async (request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result }: { result: {data: moneyValue[], type: string}[] } = await authorizedRequest(reportUrl + `?type=totals&needle=${request}` + filteringRequest, 'GET')

		for(let i=0; i<result.length; i++){
			switch(result[i].type){
			case 'total':
				setOverallTotal([...result[i].data])
				break
			case 'totalIncome':
				setIncomeTotal([...result[i].data])
				break
			case 'totalExpenses':
				setExpenseTotal([...result[i].data])
				break
			default:
				break
			}
            
		}
	}

	const loadFilterBar = async () => {
		const { result: { filters } } = await authorizedRequest(reportUrl + '?type=filters', 'GET')

		const filterResult = filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			exportButton: () => {
				dispatch(setExportReportFileModalIsOpen(true))
			},
			filters: formatedFilters,
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(reportUrl + `?type=data&page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		if (result.length > 0) {
			return result
		}
		return []
	}

	const onSearch = (query: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(query)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadTotals(query, filters)
		loadData(page, query, filters)
			.then((result) => {
				setData([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const loadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setData([...data, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}


	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
		loadHeader()
	}, [])

	return (
		<div className='report-container'>
			<Pagination onLoadMore={loadMore} loading={loading}>
				<Table
					columns={columns}
					data={data}
					showHeader={true}
					t={t}
				/>
			</Pagination>
			{
				totalIncome || totalExpenses || total ?
					<div className="report-total">
						{
							totalIncome &&
                        <div className="report-total-item">
                        	<span>{t('totalIncome')}</span>
                        	<div className="report-total-item-value">
                        		{incomeTotal.map((item, idx) =>
                        			<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
                        		)}
                        	</div>
                        </div>
						}

						{
							totalExpenses &&
                        <div className="report-total-item">
                        	<span>{t('totalExpenses')}</span>
                        	<div className="report-total-item-value">
                        		{expenseTotal.map((item, idx) =>
                        			<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
                        		)}
                        	</div>
                        </div>
						}

						{
							total &&
                        <div className="report-total-item">
                        	<span>{t('total')}</span>
                        	<div className="report-total-item-value">
                        		{overallTotal.map((item, idx) =>
                        			<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
                        		)}
                        	</div>
                        </div>
						}            
					</div>
					: null
			}
			<ExportReportFileModal reportUrl={reportUrl} />
		</div>
	)

}

export default Report