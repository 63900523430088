// styles
import './chatFilesSlider.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import FileItem from '../../../../fileItem/fileItem'

//types
import { file } from '../../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { getChatFilesUrl } from '../../../../../../utils/old_urls/employees/chatsUrls'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//other
import { blobStringToBlob } from '../../../../../../assets/general/generalFunctions'

const ChatFilesSlider: FC = () => {

	const { chatInfo, chatFiles } = useChatDesktop()

	const [ files, setFiles ] = useState<file[]>([])

	useEffect(() => {

		if(chatInfo.data.chatId){

			authorizedRequest(getChatFilesUrl(chatInfo.data.chatId), 'GET')
				.then((response) => {
					const { result } = response

					const filesList: file[] = []

					for(let i=0; i<result.length; i++){
						filesList.push({
							file: blobStringToBlob(result[i].file, result[i].type),
							fileName: result[i].name,
							fileType: result[i].type
						})
					
					}


					setFiles([...filesList])
				})
		}
	}, [chatInfo.data.chatId])

	return (
		<div className={`chat-files-slider${chatFiles.sliderIsOpen ? ' open-chat-files-slider': ''}`} style={{minWidth: chatFiles.sliderIsOpen ? '50%': '0' }}>
			<div className="chat-files-slider-content-container">
				{
					files.map((file, index) => {
						return <FileItem file={file} key={`chat-files-slider-item-${index}`} />
					})
				}
			</div>
		</div>
	)
}

export default ChatFilesSlider