//styles and icons
import './chatAudioPreview.scss'
import { deleteAudioMessageIcon, sendMessageIcon, playAudioIcon, pauseAudioIcon } from '../../../../../../../assets/employees/chat/chatIcons'

//react
import { FC, useEffect, useRef, useState } from 'react'

//components
import AudioWave from '../audioWave/audioWave'
import ChatAudioTimer from '../chatAudioTimer/chatAudioTimer'

//types
import { audioWave } from '../../../../../../../types/employees/chatTypes'

// other
import { wavesGenerator } from '../../../../../../../assets/employees/chat/chatFunctions'

type chatAudioPreviewProps = {
	handleSubmit: () => void,
	setIsRecordingAudio: React.Dispatch<React.SetStateAction<boolean>>,
	audioBlob: Blob,
	setAudioBlob: React.Dispatch<React.SetStateAction<Blob | null>>
  }
  

const ChatAudioPreview: FC<chatAudioPreviewProps> = ({handleSubmit, setIsRecordingAudio, audioBlob, setAudioBlob}) => {
	const audioTagRef = useRef<null | HTMLAudioElement>(null) // audio tag ref
	const audioMessageContainerRef = useRef<null | HTMLDivElement>(null) // audio tag and the waves container ref
    
	const [ waves, setWaves ] = useState<audioWave[]>([]) // array of audio waves 
	const [ time, setTime ] = useState(0) // audio playing timer
	const [ timeInterval, setTimeInterval ] = useState<NodeJS.Timer>() // interval for updating the timer
	const [ isPlaying, setIsPlaying ] = useState(false) // sate of the audio

	useEffect(() => {
		const audioTag = audioTagRef.current
		const audioMessageContainer = audioMessageContainerRef.current

		if(audioTag && audioMessageContainer){

			audioTag.src = URL.createObjectURL(audioBlob)
			audioTag.load()

			wavesGenerator(audioBlob, audioMessageContainer, setWaves)
		}
	}, [])


	useEffect(() => {
		const audioTag = audioTagRef.current

		if(audioTag){
			if(isPlaying){
				audioTag.play()

				setTime(audioTag.currentTime)

				setTimeInterval(setInterval(() => {
					if(audioTag){
						setTime(audioTag.currentTime)
					}
				}, 10))

			}else{
				audioTag.pause()
				clearInterval(timeInterval)
			}
		}
	}, [isPlaying])

	useEffect(() => {
		const audioTag = audioTagRef.current

		if(audioTag){
			if(time === audioTag.duration){
				setIsPlaying(false)
				audioTag.currentTime = 0
			}

			let progressPercentile
			if(isNaN(audioTag.duration) || audioTag.duration === Infinity){
				progressPercentile = 100
			}else{
				progressPercentile = time/audioTag.duration * 100
			}


			if(progressPercentile){
				const activeWaveIndex = Math.floor(waves.length*progressPercentile/100) === waves.length ? waves.length-1 : Math.floor(waves.length*progressPercentile/100)


				if(waves[activeWaveIndex]){

					for(let i=0; i<activeWaveIndex; i++){
						waves[i].filling = activeWaveIndex - i > 1 ? 100 : (activeWaveIndex - i) * 100
					}

					for(let i=Math.ceil(activeWaveIndex); i<waves.length; i++){
						waves[i].filling = 0
					}

					waves[activeWaveIndex].filling = (waves.length*progressPercentile/100 - activeWaveIndex)*100
					setWaves([...waves])
				}
			}
		}
	}, [time])

	const rewind = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

		const rewindPercentile = (e.clientX - e.currentTarget.getBoundingClientRect().left)/e.currentTarget.clientWidth
		const audioTag = audioTagRef.current

		if(audioTag && audioTag.duration && audioTag.duration != Infinity){
			audioTag.currentTime = audioTag.duration * rewindPercentile
			setTime(audioTag.currentTime)
			setIsPlaying(true)
		}
	}

	return (
		<div className='audio-preview-container'>
			<div className="audio-preview-delete-container" onClick={() => {
				setAudioBlob(null)
				setIsRecordingAudio(false)
			}}>
				{deleteAudioMessageIcon}
			</div>
			<div className="audio-preview-player-container">
				<div className="audio-preview-player-controls">
					<div className="audio-preview-player-controls-button" onClick={() => setIsPlaying(!isPlaying)}>
						{!isPlaying ? playAudioIcon : pauseAudioIcon}
					</div>
					<ChatAudioTimer time={time}/>
				</div>
            
				<div className="audio-preview-waves-container" ref={audioMessageContainerRef} onClick={(e) => {rewind(e)}}>
					<audio src="" ref={audioTagRef} style={{display: 'none'}} onLoadedData={() => {
						const audioTag = audioTagRef.current

						if(audioTag){

							const getDuration = () => {
								audioTag.currentTime = 0
								audioTag.removeEventListener('timeupdate', getDuration)
								setTime(audioTag.duration)
							}
                            
							if(audioTag.duration === Infinity){
								audioTag.currentTime = 1e101
								audioTag.addEventListener('timeupdate', getDuration)
							}
						}

					}}></audio>
					{
						waves.map((wave, index) => {
							return <AudioWave key={index} height={wave.height} filling={wave.filling}/>
						})
					}
				</div>
			</div>
			<div className="audio-preview-send-container" onClick={(e) => {
				handleSubmit()
				setAudioBlob(null)
				setIsRecordingAudio(false)
			}}>
				{sendMessageIcon}
			</div>
		</div>
	)
}

export default ChatAudioPreview