import { baseUrl } from '../../network'

//general
export const whoAmIUrl = baseUrl + 'api/whoami'
export const navbarUrl = (companyId: number) => baseUrl + `api/user/navbar/company/${companyId}`
export const updateStatusUrl = baseUrl + 'api/profile/updateStatus'
export const refreshUrl = baseUrl + 'api/refresh'
export const contactsUrl = baseUrl + 'api/contacts'
export const getLabelsUrl = (companyId: number) => baseUrl + `api/company/${companyId}/labels`
export const getCompanyPositionsUrl = (companyId: number, query: string, page: number, perPage: number) => baseUrl + `api/company/${companyId}/positions?needle=${query}&page=${page}&per_page=${perPage}`

//bills
export const createBillUrl =  (companyId: number) => baseUrl + `api/company/${companyId}/bills`
export const deleteBillUrl = (billId: number) => baseUrl + `api/bills/${billId}`

//transactions
export const deleteTransactionUrl = (transactionId: number) => baseUrl + `api/transactions/${transactionId}`
export const createTransactionUrl = (accountId: number) => baseUrl + `api/accounts/${accountId}/transactions`

//positions
export const getUnreadMessagesCountUrl = baseUrl + 'api/chats/messages/unread'

//company phone numbers
export const getPhoneNumbersUrl = (companyId: number) => baseUrl + `api/company/${companyId}/phone`
export const createPhoneNumberUrl = (companyId: number) => baseUrl + `api/company/${companyId}/phone`
export const deletePhoneNumberUrl = (phoneNumberId: number) => baseUrl + `api/company/phone/${phoneNumberId}`

//company emails
export const getEmailUrl = (companyId: number) => baseUrl + `api/company/${companyId}/emails`
export const createEmailUrl = (companyId: number) => baseUrl + `api/company/${companyId}/emails`
export const deleteEmailUrl = (emailId: number) => baseUrl + `api/company/emails/${emailId}`

//company labels
export const createLabelUrl = (companyId: number) => baseUrl + `api/company/${companyId}/label` 
export const deleteLabelUrl = (labelId: number) => baseUrl + `api/company/labels/${labelId}` 

//order
export const deleteOrderUrl = (orderId:number) => baseUrl + `api/orders/${orderId}`
export const closeOrderUrl = (orderId:number) => baseUrl + `api/orders/${orderId}`

