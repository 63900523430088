//components
import Modal from '../../../../../../modals/modal/modal'
import InputField from '../../../../../../inputField/inputField'

//react
import { useState, useRef, FC } from 'react'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../../../customHooks/redux'
import { setCreateEmailModalIsOpen } from '../../../../../../../../redux/general/settings/modals'

//network
import { authorizedRequest } from '../../../../../../../../utils/queries'
import { createEmailUrl } from '../../../../../../../../utils/old_urls/general/generalUrls'

//translation
import { useTranslation } from 'react-i18next'

//types
import { emailResponse, email } from '../../../../../../../../types/general/generalTypes'

type createEmailsModalProps = {
	emails: email[]
	setEmails: ( value: email[] ) => void
}

type formErrors =  {
    email?: string
}

const CreateEmailModal: FC<createEmailsModalProps> = ({ emails, setEmails }) => {

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.settingsModals.createEmail)
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.createEmailModal'})

	const formRef = useRef<HTMLFormElement | null>(null)

	const [ email, setEmail ] = useState('')
	const [ errors, setErrors ] = useState<formErrors>({})

	const closeModal = () => {
		setEmail('')
		setErrors({})
		dispatch(setCreateEmailModalIsOpen(false))
	}

	const checkErrors = () => {
		let result = true
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		if(!emailRegex.test(email) || email === ''){
			setErrors(prevErrors => ({...prevErrors, email: t('pleaseEnterYourEmail')}))
			result = false
		}
		
		return result
	}


	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)=> {
		e.preventDefault()
		if(checkErrors()){
			const {result}: {result: emailResponse} = await authorizedRequest(createEmailUrl(settingsActiveCompanyId!), 'POST', 'accessToken', {
				email: email,
			})
	
			const createdEmail: email = result
	
			setEmails([createdEmail, ...emails])
			closeModal()
		}
	}
	
	return (
		<Modal 
			title={t('createEmail')}
			open={modalIsOpen} 
			closeModal={closeModal} 
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>
			<form className='create-position-form' ref={formRef} onSubmit={handleSubmit}>
				<InputField 
					label={t('email')}
					value={email} 
					onChange={(e)=> {
						setEmail(e.target.value)
					}}
					error={errors.email}
				/>
			</form>
		</Modal>
	)

}

export default CreateEmailModal