import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type settingsModalsState = {
	createPosition: {
		modalIsOpen: boolean
	}
	deletePosition: {
		modalIsOpen: boolean
		positionId: number | null
	}
	createPhoneNumber: {
		modalIsOpen: boolean
	}
	deletePhoneNumber: {
		modalIsOpen: boolean
		phoneNumberId: number | null
	}
	createEmail: {
		modalIsOpen: boolean
	}
	deleteEmail: {
		modalIsOpen: boolean
		emailId: number | null
	}
	editPosition: {
		modalIsOpen: boolean
		positionId: number | null
	}
	createLabel: {
		modalIsOpen: boolean
	}
	deleteLabel: {
		modalIsOpen: boolean
		labelId: number | null
	}
}

const initialState: settingsModalsState = {
	createPosition: {
		modalIsOpen: false
	},
	deletePosition: {
		modalIsOpen: false,
		positionId: null
	},
	createPhoneNumber: {
		modalIsOpen: false
	},
	deletePhoneNumber: {
		modalIsOpen: false,
		phoneNumberId: null
	},
	createEmail: {
		modalIsOpen: false
	},
	deleteEmail: {
		modalIsOpen: false,
		emailId: null
	},
	createLabel: {
		modalIsOpen: false
	},
	deleteLabel: {
		modalIsOpen: false,
		labelId: null
	},
	editPosition: {
		modalIsOpen: false,
		positionId: null
	},
}

export const settingsModalsSlice = createSlice({
	name: 'settingsModalsSlice',
	initialState,
	reducers:{
		setCreatePositionModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createPosition.modalIsOpen = action.payload
		},
		setDeletePositionModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deletePosition.modalIsOpen = action.payload
		},
		setDeletePositionId: (state, action: PayloadAction<number | null>) => {
			state.deletePosition.positionId = action.payload
		},

		setCreatePhoneNumberModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createPhoneNumber.modalIsOpen = action.payload
		},
		setDeletePhoneNumberModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deletePhoneNumber.modalIsOpen = action.payload
		},
		setDeletePhoneNumberId: (state, action: PayloadAction<number| null>) => {
			state.deletePhoneNumber.phoneNumberId = action.payload
		},
		
		setCreateEmailModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createEmail.modalIsOpen = action.payload
		},
		setDeleteEmailModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteEmail.modalIsOpen = action.payload
		},
		setDeleteEmailId: (state, action: PayloadAction<number| null>) => {
			state.deleteEmail.emailId = action.payload
		},
		setCreateLabelModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createLabel.modalIsOpen = action.payload
		},
		setDeleteLabelModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteLabel.modalIsOpen = action.payload
		},
		setDeleteLabelId: (state, action: PayloadAction<number| null>) => {
			state.deleteLabel.labelId = action.payload
		},
		setEditPositionModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.editPosition.modalIsOpen = action.payload
		},
		setEditPositionId: (state, action: PayloadAction<number | null>) => {
			state.editPosition.positionId = action.payload
		},
	}
})

export const {
	setCreatePositionModalIsOpen,
	setDeletePositionModalIsOpen,
	setDeletePositionId,
	setCreatePhoneNumberModalIsOpen,
	setDeletePhoneNumberModalIsOpen,
	setDeletePhoneNumberId,
	setCreateEmailModalIsOpen,
	setDeleteEmailModalIsOpen,
	setDeleteEmailId,
	setEditPositionModalIsOpen,
	setEditPositionId,
	setCreateLabelModalIsOpen,
	setDeleteLabelModalIsOpen,
	setDeleteLabelId
} = settingsModalsSlice.actions

export default settingsModalsSlice.reducer