// react
import { FC } from 'react'

// component
import FileExportModal from '../../../../../general/modals/fileExportModal/fileExportModal'


// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setExportShipmentFileModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

const ExportShipmentFileModal: FC = () => {

	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.exportShipmentFileModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setExportShipmentFileModalIsOpen(false))
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={async() => ''}

		/>
	)
}

export default ExportShipmentFileModal