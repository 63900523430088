// react
import { FC } from 'react'

// component
import FileExportModal from '../../../../general/modals/fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyProvidersUrl } from '../../../../../utils/urls/purchases/provider'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportProviderFileModalIsOpen } from '../../../../../redux/purchase/providersList/modals'


const ExportProviderFileModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.providerListModal.exportProviderFileModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setExportProviderFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(companyProvidersUrl(companyId) + `?export=True&export_type=${exportType}`, 'GET')

		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}	
			exportFileTypes={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportProviderFileModal