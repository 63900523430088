// styles and icons
import './table.scss'

// react
import  { FC } from 'react'

//types
import { tableHeader } from '../../../types/general/generalTypes'
import { TFunction } from 'i18next'
import { formatDate } from '../../../assets/general/generalFunctions'

type tableProps = {
  columns: tableHeader[]
  data: Record<string, JSX.Element | string | number>[]
  showHeader?: boolean
  t?: TFunction
}

const Table: FC<tableProps> = ({ columns, data, showHeader = true, t }) => {

	return (
		<table className='table'>
			{
				showHeader && 
					<thead className='table-head'>
						<tr className='table-head-row'>
							{columns.map((name) => (
								<th key={name.key}>{t ? t(name.title.toString()).toLowerCase() : name.title.toString().toLowerCase()}</th>
							))}
						</tr>
					</thead>
			}
			<tbody className='table-body'>
				{data.map((item, index) => (
					<tr key={`table-body-row-${index}`} className='table-body-row'>
						{columns.map((column) => (
							<td key={column.key + index} >
								{
									column.translate ?
										t ? t(`${item[column.key]}`) : item[column.key] :
										column.date ? formatDate(new Date(Number(item[column.key]) * 1000), true, true) : item[column.key]
								}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default Table
