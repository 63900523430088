// react
import { Dispatch, FC, SetStateAction } from 'react'
import {useParams} from 'react-router-dom'

// component
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { document, file } from '../../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import {singleEmployeeFilesUrl} from '../../../../../../utils/urls/employees/employee'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportEmployeeFileFileSelectorFile, setImportEmployeeFileFileSelectorModalIsOpen } from '../../../../../../redux/general/employee/modals'

//other
import { blobStringToBlob, blobToBase64 } from '../../../../../../assets/general/generalFunctions'

type importProfileDocumentFileSelectorModalProps = {
	setEmployeeFiles: Dispatch<SetStateAction<document[]>>
	employeeFiles: document[]
}

type profileDocumentUploadResponse = {
	id: number
	file: string
	file_type: string
	file_name: string
}

const ImportEmployeeFileFileSelectorModal: FC<importProfileDocumentFileSelectorModalProps> = ({ employeeFiles, setEmployeeFiles }) => {
	const { modalIsOpen } = useAppSelector((state) => state.employeeModals.employeeFiles.importEmployeeFileFileSelectorModal)
	const {userCompanyData} = useAppSelector(state => state.general)
	const { employeeId } = useParams()
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportEmployeeFileFileSelectorModalIsOpen(false))
	}

	const setFiles = async (files: file[]) => {
		if (files.length === 0) return
		const base64File = await blobToBase64(files[0].file)
		const data = {
			'data':
				[
					{
						'file_name': files[0].fileName,
						'file_type': files[0].fileType,
						'files': [base64File]
					}
				]
		}
		console.log('Data', data)
		authorizedRequest(singleEmployeeFilesUrl(Number(employeeId)), 'POST', 'accessToken', data)
			.then((response) => {
				console.log('response', response)
				dispatch(setImportEmployeeFileFileSelectorModalIsOpen(false))
				const responseData: document[] = response.result.map((item: profileDocumentUploadResponse) => {
					return {
						file: blobStringToBlob(item.file, item.file_type),
						fileName: item.file_name,
						fileType: item.file_type,
						id: item.id
					}
				})
				console.log(responseData)
				setEmployeeFiles([...employeeFiles, ...responseData])
			})
		dispatch(setImportEmployeeFileFileSelectorFile(files[0]))
	}

	return (
		<FileSelectorModal
			isOpen={modalIsOpen}
			setIsOpen={closeModal}
			setFiles={(value) => { setFiles(value) }}
			supportedFormats={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xls', mime: 'application/vnd.ms-excel' },
				{ title: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
				{ title: '.pdf', mime: 'application/pdf' },
				{ title: '.jpeg', mime: 'image/jpeg' },
				{ title: '.jpg', mime: 'image/jpg' },
				{ title: '.png', mime: 'image/png' },
				{ title: '.mp4', mime: 'video/mp4' },
				{ title: '.webm', mime: 'video/webm' },
				{ title: '.mp3', mime: 'audio/mpeg' },
				{ title: '.weba', mime: 'audio/webm' },
				{ title: '.ppt', mime: 'application/vnd.ms-powerpoint' },
				{ title: '.zip', mime: 'application/zip' }
			]}
		/>
	)
}

export default ImportEmployeeFileFileSelectorModal