// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setDeleteInventoryItemId, setDeleteInventoryItemModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleInventoryItemUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

//types
import { inventoryItem } from '../../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type deleteInventoryItemModalProps = {
	inventoryItems: inventoryItem[]
	setInventoryItems: (value: inventoryItem[]) => void
}

const DeleteInventoryItemConfirmationModal: FC<deleteInventoryItemModalProps> = ({ inventoryItems, setInventoryItems }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.general.modals.inventoryItemModals.deleteInventoryItemConfirmationModal' })

	const [loading, setLoading] = useState<boolean>(false)

	const { modalIsOpen, inventoryItemId } = useAppSelector(state => state.warehouseGeneralModal.deleteInventoryItemModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setDeleteInventoryItemModalIsOpen(false))
		dispatch(setDeleteInventoryItemId(null))
	}

	const deleteInventoryItem = () => {
		if (inventoryItemId) {
			setLoading(true)
			authorizedRequest(singleInventoryItemUrl(inventoryItemId), 'DELETE')
				.then((response) => {
					console.log(response)
					setInventoryItems([...inventoryItems.filter((item) => item.inventoryItemId !== inventoryItemId)])
					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('deleteThisInventoryItem')}
			isOpen={modalIsOpen}
			closeModal={closeModal}
			buttons={[{ text: t('yes'), onClickHandler: deleteInventoryItem }, { text: t('no'), onClickHandler: closeModal }]}
			loading={loading}
		/>
	)
}

export default DeleteInventoryItemConfirmationModal