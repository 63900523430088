//styles and icons
import './messageCard.scss'
import { audioIcon, fileIcon, groupChatBasicAvatar, imageIcon, videoIcon } from '../../../../../assets/employees/chat/chatIcons'
import { basicUserAvatar } from '../../../../../assets/general/generalIcons'

//components
import Avatar from '../../../avatar/avatar'

//react
import { FC, useEffect, useState } from 'react'

//types
import { messageCard } from '../../../../../types/employees/chatTypes'
import {file} from '../../../../../types/general/generalTypes'

//other
import { getMessageFiles } from '../../../../../assets/employees/chat/chatFunctions'

//translation
import { useTranslation } from 'react-i18next'

const getFileIcon = (files: file[]) =>{
	if(files.length > 1){
		return <div className='anymore-files-container'>{fileIcon}{fileIcon}</div> 
	}
	else if (files.length == 1){
		const fileData: {types: string[], file: JSX.Element}[] = 
		[
			{
				types: ['video/mp4'],
				file: videoIcon
			},
			{
				types: ['audio/mpeg'],
				file: audioIcon
			},
			{
				types: ['image/png', 'image/jpeg', 'image/jpg'],
				file: imageIcon
			},
			{
				types: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint', 'application/zip', 'application/pdf'],
				file: fileIcon	
			}
		]
		const fileItem = fileData.find((elem) => elem.types.indexOf(files[0].fileType) !== -1)?.file

		return fileItem
	}
}



type messageCardProps = {
    avatar: string | null,
    name: string | undefined,
    message: messageCard | null,
	isGroup: boolean
}

const MessageCard: FC<messageCardProps> = ({avatar, name, message, isGroup}) => {
	const { t } = useTranslation('', { keyPrefix:'general.chats.general.messageCard' })

	const [messageFiles, setMessageFiles] = useState<file[]>([])

	useEffect(()=>{
		if(message?.file){
			getMessageFiles(message.messageId)
				.then((result)=>{
					setMessageFiles(result)
				})
		}
	},[])



	return (
		<div className="chat-card-container">
			<div className="chat-card-avatar-container">
				{ avatar ? <Avatar blobAvatar={avatar} /> : isGroup ? groupChatBasicAvatar : basicUserAvatar }			
			</div>
			<div className="chat-card-information-container">
				<p className="chat-card-name">{name}</p>
				<div className="chat-card-message-container">

					{message?.file ? getFileIcon(messageFiles) : null}

					
					<p className="message">
						{
							message?.text ? 
								message.text : 
								messageFiles.length > 1 ? messageFiles.length + t('files') :  messageFiles.length == 1 ? messageFiles[0].fileName : null
						}
					</p>
				</div>
			</div>
		</div>
	)
}

export default MessageCard