// styles and icons
import './loading.scss'
import { loading } from '../../../assets/general/generalIcons'

// react
import { FC } from 'react'

type loadingProps = {
	style?: object
}

const Loading: FC<loadingProps> = ({ style }) => {
	return (
		<div style={style} className="loading-container">
			<div className="loading-icon">
				{loading}
			</div>
		</div>
	)
}

export default Loading