import { baseUrl } from '../../network'

export const companyProviderFiltersUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/providers/filters`
export const companyProvidersUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/providers`

export const singleProviderUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}`

export const singleProviderDocumentFiltersUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/documents/filters`
export const singleProviderDocumentsUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/documents`
export const singleProviderDocumentUrl = (fileId: number) => baseUrl + `/api/provider/documents/${fileId}`

export const singleProviderOfferingFiltersUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/offerings/filters`
export const singleProviderOfferingsUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/offerings`

export const singleProviderOrderFiltersUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/orders/filters`
export const singleProviderOrdersUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/orders`

export const singleProviderBillFiltersUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/bills/filters`
export const singleProviderBillsOverviewUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/bills/overview`
export const singleProviderBillsUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/bills`

export const singleProviderAddressesUrl = (relationshipId: number) => baseUrl + `/api/provider/${relationshipId}/addresses`
export const singleProviderAddressUrl = (addressId: number) => baseUrl + `/api/provider/address/${addressId}`

export const companyProviderOfferingsUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/providers/offerings`

export const singleProviderAccountsUrl = (relationshipId:number)=> baseUrl + `/api/provider/${relationshipId}/accounts`
export const singleProviderAccountFiltersUrl = (relationshipId:number)=> baseUrl + `/api/provider/${relationshipId}/accounts/filters`
export const singleProviderAccountOverviewUrl = (relationshipId:number) => baseUrl + `/api/provider/${relationshipId}/accounts/overview`
export const companyProviderAccountsUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/providers/accounts`
