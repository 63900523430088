// styles
import './providerOffering.scss'

// react
import { useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import ProviderOfferingDetails from '../../../components/purchase/provider/providerOfferingDetails/providerOfferingDetails'

// types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'


enum offeringSection {
	Details = 'Details',
}

const ProviderOffering = () => {
	const [offeringName, setOfferingName] = useState('')
	const [activeOfferingSection, setActiveOfferingSection] = useState<offeringSection>(offeringSection.Details)

	const [providerId, setProviderId] = useState<number>()
	const [providerName, setProviderName] = useState<string>('')

	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerOffering' })

	const titleUrls = [
		{
			url: '/ri-business/purchase/provider-list',
			title: t('providers')
		},
		{
			url: `/ri-business/purchase/provider/${providerId}`,
			title: providerName
		},
		{
			url: location.pathname,
			title: offeringName
		}
	]

	const infoPopup = {
		content: [{
			title: 'offering_info_title',
			description: 'offering_info_description',
			link: '/ri-manual/sales/offering'
		}],
		type: infoPopupTypes.INFO
	}

	const offeringTabButtons: button[] = [
		{
			active: activeOfferingSection === offeringSection.Details,
			text: t('details'),
			onClick: () => setActiveOfferingSection(offeringSection.Details),
		}
	]

	const renderTab = () => {
		switch (activeOfferingSection) {
		case offeringSection.Details:
			return <ProviderOfferingDetails setOfferingName={setOfferingName} setProviderId={setProviderId} setProviderName={setProviderName} />
		default:
			return null
		}

	}
	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={offeringTabButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="product-container">
				{renderTab()}
			</div>
		</Layout>
	)
}

export default ProviderOffering