//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//react
import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleTaskUrl } from '../../../../../utils/urls/projects/task'

//redux
import {
	useAppSelector,
	useAppDispatch,
} from '../../../../../customHooks/redux'
import {
	setCloseTaskModalIsOpen,
	setCloseTaskId,
} from '../../../../../redux/projects/task/modals'

//types
import { detailedErrorObject, task } from '../../../../../types/general/generalTypes'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type closeTaskModalProps = {
	lastOpen: boolean
	navigateBack?: boolean
	tasks?: task<Date | undefined>[]
	setTasks?: (value: task<Date | undefined>[]) => void
}

const CloseTaskModal: FC<closeTaskModalProps> = ({lastOpen, navigateBack = true, tasks, setTasks}) => {
	const { t } = useTranslation('', {
		keyPrefix: 'general.modals.task.closeTaskModal',
	})
	const { modalIsOpen, taskId } = useAppSelector(
		(state) => state.taskModals.closeTaskModal
	)
	const { language } = useAppSelector((state) => state.general)

	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const [buttons, setBusttons] = useState<
    {
		text: string;
		onClickHandler: () => void
			}[]>([])
	const [description, setDescription] = useState<string>()
	const [errorObjects, setErrorObjects] = useState<detailedErrorObject[]>()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (modalIsOpen) {
			setErrorObjects(undefined)
			setDescription(undefined)
			setBusttons([])
			setLoading(true)
			authorizedRequest(singleTaskUrl(Number(taskId)), 'PATCH', 'accessToken', {
				language: language,
			}).then((response) => {
				const { result } = response

				const { description, objects } = result

				setDescription(description ? t(description) : undefined)

				setErrorObjects(description ? objects.map(formatDetailedErrorObjectResponse) : undefined)

				if (result.description !== 'sender_lacking_inventory' && result.description !== 'close_following_tasks_first') {
					// const buttons = lastOpen
					// 	? [
					// 		{
					// 			text: t('taskOnly'),
					// 			onClickHandler: () => handleSubmit(false),
					// 		},
					// 		{
					// 			text: t('taskAndProject'),
					// 			onClickHandler: () => handleSubmit(true),
					// 		},
					// 		{ text: t('cancel'), onClickHandler: closeModal },
					// 	]
					// 	: [
					// 		{ text: t('yes'), onClickHandler: () => handleSubmit(false) },
					// 		{ text: t('no'), onClickHandler: closeModal },
					// 	]
					const buttons = [
						{ text: t('yes'), onClickHandler: () => handleSubmit(false) },
						{ text: t('no'), onClickHandler: closeModal },
					]

					setBusttons([...buttons])
					// setErrorObjects(undefined)
				}
				setLoading(false)
			})
		}
	}, [modalIsOpen])

	const closeModal = () => {
		dispatch(setCloseTaskModalIsOpen(false))
		dispatch(setCloseTaskId(null))
	}

	const handleSubmit = (closeTheProject: boolean) => {
		if (taskId) {
			setLoading(true)
			authorizedRequest(singleTaskUrl(taskId), 'PUT', 'accessToken', {
				task_id: taskId,
				close: {
					is_closed: true,
					close_the_project: closeTheProject,
				},
			}).then(() => {
				if (navigateBack) {
					navigate(-1)
				}

				if(tasks && setTasks){
					setTasks([...tasks.map((task) => {
						if(task.isClosed){
							return task
						}
						
						return {
							...task,
							isClosed: task.id == taskId
						}
					})])
				}
				setLoading(false)
				closeModal()
			})
		}
	}

	return (
		<ConfirmationModal
			title={lastOpen ? t('lastTask') : t('closeThisTask')}
			buttons={buttons}
			isOpen={modalIsOpen}
			closeModal={closeModal}
			description={description}
			errorObjects={errorObjects}
			loading={loading}
		/>
	)
}

export default CloseTaskModal
