// style
import './chartLegendItem.scss'

// component
import InputRadio from '../../../../inputRadio/inputRadio'

// react
import { FC } from 'react'

type chartLegendItemProps = {
	label:string
	color:string
	active?:boolean
	onSelect?: () => void
}

const ChartLegendItem : FC<chartLegendItemProps> = ({color,label, active, onSelect}) => {
	return (
		<div className={`chart-legend-container ${active ? 'selected' : ''}`} onClick={(e)=>{e.stopPropagation(), e.preventDefault(); onSelect && onSelect()}}>
			<InputRadio checked={active || false} label={label} color={color} rounded={true} />
		</div>	
	)
}

export default ChartLegendItem