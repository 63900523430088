//react
import {FC, useEffect, useRef, useState} from 'react'
import InputField from '../../inputField/inputField'
import {editableTableItem} from '../../../../types/general/generalTypes'
import useDebounce from '../../../../customHooks/useDebounce'
import { formatStringAmountIntoNumber } from '../../../../assets/general/generalFunctions'

type editableTableAmountInputProps = {
    value:string
    item:editableTableItem
    amountInputHandle:(value:string,item:editableTableItem)=>void
}

const EditableTableAmountInput:FC<editableTableAmountInputProps> = ({value, item, amountInputHandle})=>{
	const [inputValue, setInputValue]=useState<string>(value)
	const [isFocused, setFocused] = useState<boolean>(false)
	const debounceValue = useDebounce<string>(inputValue, 400)

	useEffect(() => {
		amountInputHandle(debounceValue, item)
	}, [debounceValue])

	useEffect(() => {
		if (isFocused){
			return
		}
		setTimeout(()=>{
			setInputValue(value)
		},500)
	}, [value,isFocused])

	return (
		<InputField
			onChange={(event) => {
				const newValue = event.target.value
				if (/^\d*\.?\d*$/.test(newValue)) {
					// Only allow digits and a single dot

					setInputValue(Number(newValue).toFixed(2))
				} else {
					// If input does not match the pattern, ignore the change
					return
				}
				setInputValue(newValue)
			}}
			onFocus={() => {
				setFocused(true)
			}}
			onBlur={() => {
				setFocused(false)
			}}
			type="string"
			value={isFocused && isNaN(Number(inputValue)) ? formatStringAmountIntoNumber(inputValue).toString() : inputValue}
		/>
	)
}

export default EditableTableAmountInput