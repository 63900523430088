// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileExportModal from '../../../../../general/modals/fileExportModal/fileExportModal'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderOfferingsUrl } from '../../../../../../utils/urls/purchases/provider'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setExportProviderOfferingFileModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

const ExportProviderOfferingFileModal : FC = () => {

	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerOfferings.exportProviderOfferingFileModal)
	const dispatch = useAppDispatch()

	const {relationshipId} = useParams()


	const closeModal = ()=>{
		dispatch(setExportProviderOfferingFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const {result} = await authorizedRequest(singleProviderOfferingsUrl(Number(relationshipId)) + `?export=True&export_type=${exportType}`, 'GET')
		console.log(result)
		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}	
			exportFileTypes={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportProviderOfferingFileModal