import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type offeringListModalsState = {
	createOfferingModal: {
		modalIsOpen: boolean
	}
	deleteOfferingModal: {
		modalIsOpen: boolean
		offeringId: number | null
	}
	importOfferingFileSelectorModal: {
		modalIsOpen: boolean
		file: file | null
	}
	tableOfferingPreviewModal: {
		modalIsOpen: boolean
	}
	exportOfferingFileModal: {
		modalIsOpen: boolean
	}
}

const initialState: offeringListModalsState = {
	createOfferingModal: {
		modalIsOpen: false
	},
	deleteOfferingModal: {
		modalIsOpen: false,
		offeringId: null
	},
	importOfferingFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	tableOfferingPreviewModal: {
		modalIsOpen: false
	},
	exportOfferingFileModal: {
		modalIsOpen: false
	},
}

export const offeringListModalsSlice = createSlice({
	name: 'offeringListModalsSlice',
	initialState,
	reducers : {
		setCreateOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createOfferingModal.modalIsOpen = action.payload
		},
		setDeleteOfferingModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteOfferingModal.modalIsOpen = action.payload
		},
		setDeleteOfferingId: (state, action: PayloadAction<number | null>) => {
			state.deleteOfferingModal.offeringId = action.payload
		},
		setImportOfferingFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importOfferingFileSelectorModal.modalIsOpen = action.payload
		},
		setImportOfferingFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importOfferingFileSelectorModal.file = action.payload
		},
		setTableOfferingPreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.tableOfferingPreviewModal.modalIsOpen = action.payload
		},
		setExportOfferingFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportOfferingFileModal.modalIsOpen = action.payload
		},
	}
})

export const {
	setCreateOfferingModalIsOpen,
	setDeleteOfferingModalIsOpen,
	setDeleteOfferingId,
	setImportOfferingFileSelectorModalIsOpen,
	setImportOfferingFileSelectorModalFile,
	setTableOfferingPreviewModalIsOpen,
	setExportOfferingFileModalIsOpen,
} = offeringListModalsSlice.actions

export default offeringListModalsSlice.reducer