// style
import './account.scss'

// react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import AccountDetails from '../../../components/finance/account/accountDetails/accountDetails'
import AccountTransactionList from '../../../components/finance/account/accountTransactionList/accountTransactionList'

//translation
import { useTranslation } from 'react-i18next'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

enum accountSection {
	AccountTransactionList = 'AccountTransactionList',
	AccountDetails = 'AccountDetails'
}

const Account: FC = () => {
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeAccountSection, setActiveAccountSection] = useState<string>('AccountDetails')
	const [accountName, setAccountName] = useState('')
	const {t} = useTranslation('', {keyPrefix: 'finance.accountList'})

	const { accountId } = useParams()

	const titleUrls = [
		{
			url: '/ri-business/finance/account-list',
			title: t('accounts')
		}, 
		{
			url: location.pathname,
			title: accountName
		}
	]

	const infoPopup = {
		content: [{
			title: 'account_info_title',
			description: 'account_info_description',
			link: '/ri-manual/finance'
		}],
		type: infoPopupTypes.INFO
	}

	const accountTabButtons: button[] = [
		{
			active: activeAccountSection === accountSection.AccountDetails,
			text: t('details'),
			onClick: () => setActiveAccountSection(accountSection.AccountDetails),
		},
		{
			active: activeAccountSection === accountSection.AccountTransactionList,
			text: t('transactions'),
			onClick: () => setActiveAccountSection(accountSection.AccountTransactionList),
		}
	]

	const renderTab = () => {
		switch (activeAccountSection) {
		case accountSection.AccountDetails:
			return <AccountDetails setAccountName={setAccountName} />
		case accountSection.AccountTransactionList:
			return <AccountTransactionList setFilterBar={(filterBar) => { setFilterBar(filterBar) }} />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={accountTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="account-container">
				{
					renderTab()
				}
			</div>

		</Layout>
	)
}

export default Account