// style
import './bill.scss'

// react
import { FC, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import BillDetails from '../../../components/finance/bill/billDetails/billDetails'
import ErrorList from '../../../components/general/errorList/errorList'

// types
import { button, error, infoPopupTypes } from '../../../types/general/generalTypes'

//translation
import {useTranslation} from 'react-i18next'

enum billSection {
	BillDetails = 'BillDetails',
	BillErrorsList = 'BillErrorsList'
}

const Bill: FC = () => {
	const [activeBillSection, setActiveBillSection] = useState<string>('BillDetails')
	const { t } = useTranslation('', {keyPrefix:'finance.bill'})
	const [ billName, setBillName ] = useState('')
	const [ errors, setErrors ] = useState<error[]>([])

	const titleUrls = [
		{
			url: '/ri-business/finance/bill-list',
			title: t('bills')
		}, 
		{
			url: location.pathname,
			title: billName
		}
	]

	const infoPopup = {
		content: [{
			title: 'bill_info_title',
			description: 'bill_info_description',
			link: '/ri-manual/finance'
		}],
		type: infoPopupTypes.INFO
	}

	const accountTabButtons: button[] = [
		{
			active: activeBillSection === billSection.BillDetails,
			text: t('details'),
			onClick: () => setActiveBillSection(billSection.BillDetails),
		},
		{
			active: activeBillSection === billSection.BillErrorsList,
			text: t('errors'),
			onClick: () => setActiveBillSection(billSection.BillErrorsList)
		}
	]

	const renderTab = () => {
		switch (activeBillSection) {
		case billSection.BillDetails:
			return <BillDetails setBillName={setBillName} setErrors={setErrors}/>
		case billSection.BillErrorsList:
			return <ErrorList errors={errors}/>
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={accountTabButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="bill-container">
				{
					renderTab()
				}
			</div>

		</Layout>
	)
}

export default Bill