//styles
import './filterbarSkeleton.scss'

//components
import Loading from '../../loading/loading'


const SkeletonFilterBar = () => {

	return (
		<div className='skeleton-filter-bar-container'>
			<Loading style={{ position: 'relative', top: '0', left: '0' }} />
		</div>
	)
}

export default SkeletonFilterBar