//styles and icons
import './companiesList.scss'
import { navlogo, createCompany } from '../../../../assets/general/generalIcons'

//react
import { useEffect, useState } from 'react'

//components
import Modal from '../modal/modal'
import CounterpartySkeleton from '../../skeletons/counterParty/counterPartySkeleton'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setCompaniesListModalIsOpen, setCreateCompanyModalIsOpen } from '../../../../redux/general/modals'
import { setCompanyDataList, setUserCompanyData } from '../../../../redux/general/general'
import { setSettingsActiveCompanyId } from '../../../../redux/general/settings/general'

//types
import { company, companyResponse } from '../../../../types/general/generalTypes'

//network
import { whoAmIUrl } from '../../../../utils/old_urls/generalUrls'
import { authorizedRequest } from '../../../../utils/queries'

//translaction
import { useTranslation } from 'react-i18next'

const CompaniesList = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.modals.companiesList' })

	const { companiesList } = useAppSelector((state) => state.generalModals)
	const { companiesDataList } = useAppSelector((state) => state.general)
	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.companiesList)
	const [isLoading, setLoading] = useState<boolean>(false)
	const closeModal = () => dispatch(setCompaniesListModalIsOpen(false))

	useEffect(() => {
		if (modalIsOpen && companiesDataList.length < 1) {
			setLoading(true)
			authorizedRequest(whoAmIUrl, 'GET').then((whoAmIResponce) => {
				if (whoAmIResponce?.ok) {
					dispatch(setCompanyDataList([...whoAmIResponce.result.companies.map((company: companyResponse) => {
						return {
							avatar: company.avatar,
							companyId: company.company_id,
							employeeId: company.employee_id,
							name: company.name,
							description: company.description
						}
					})]))
				}
			}).finally(() => {
				setLoading(false)
			})
		}
	}, [modalIsOpen])

	return (
		<Modal
			closeModal={closeModal}
			open={companiesList.modalIsOpen}
			title={t('title')}
		>
			<div className="copanies-list-container">
				{!isLoading ? companiesDataList.map((company: company) => {
					return (
						<div className="company-card" key={`company-card-${company.companyId}`} onClick={() => {
							dispatch(setUserCompanyData({
								companyId: company.companyId,
								companyAvatar: company.avatar,
								companyDescription: company.description,
								companyName: company.name,
								employeeId: company.employeeId
							}))
							dispatch(setSettingsActiveCompanyId(company.companyId))

							window.location.reload()
							closeModal()
						}}>
							<span className="company-avatar">{company.avatar && company.avatar != 'null' ? <img src={company.avatar} /> : navlogo}</span>
							<div className="company-data">
								<p className="company-name">{company.name}</p>
								<p className="company-description">{company.description}</p>
							</div>
						</div>
					)
				}) : [1, 2].map(() => <CounterpartySkeleton />)}
				<div className="add-company-button-container" onClick={() => {
					closeModal()
					dispatch(setCreateCompanyModalIsOpen(true))
				}}>
					{createCompany}
				</div>
			</div>
		</Modal>
	)

}

export default CompaniesList