export const chevronDown = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
	<path d="M7.69338 6.8328C7.3062 7.20536 6.6938 7.20536 6.30662 6.8328L0.993849 1.72058C0.345179 1.09639 0.787014 0 1.68722 0L12.3128 0C13.213 0 13.6548 1.09639 13.0062 1.72058L7.69338 6.8328Z" fill="white"/>
</svg>

export const chevronRight = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
	<path d="M9 6L15 12L9 18" stroke="#444444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const logo = <svg xmlns="http://www.w3.org/2000/svg" width="36" height="41" viewBox="0 0 36 41" fill="none">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.47647V40.4996H5.19989V24.2142H11.4855L20.971 40.4996H27.1994L17.1996 23.8142C20.3615 23.1285 22.7233 21.7381 24.2852 19.6429C25.8852 17.5477 26.6851 15.1287 26.6851 12.3859C26.6851 10.1383 26.1709 8.13838 25.1423 6.38604C24.1138 4.6337 22.5519 3.24325 20.4567 2.2147C18.3996 1.18615 15.8663 0.671875 12.8569 0.671875H5.19989L5.19989 4.9575H12.8569C15.7139 4.9575 17.8282 5.6051 19.1996 6.90032C20.6091 8.19553 21.3138 10.0241 21.3138 12.3859C21.3138 14.7097 20.6091 16.5763 19.1996 17.9858C17.7901 19.3572 15.6759 20.0429 12.8569 20.0429H5.19989L5.19989 9.47647H0Z" fill="white"/>
	<path d="M35.6765 9.80469V40.4898H30.4766V9.80469H35.6765Z" fill="white"/>
	<path d="M30.4531 0.5H35.7102V4.88086H30.4531V0.5Z" fill="white"/>
</svg>

export const bag = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_9_2168)">
		<path d="M12.0006 23.1428C18.0006 23.1428 22.2863 21.0171 22.2863 16.2857C22.2863 11.1428 19.7148 8.53713 14.572 5.9657L16.7971 2.48912C16.8845 2.32141 16.9277 2.13423 16.9227 1.9452C16.9177 1.75618 16.8648 1.57153 16.7687 1.40863C16.6727 1.24574 16.5368 1.10995 16.3738 1.01407C16.2109 0.918189 16.0262 0.865358 15.8371 0.860553H8.56856C8.36692 0.85672 8.16803 0.90768 7.99309 1.008C7.81814 1.10832 7.6737 1.25424 7.57516 1.43019C7.47662 1.60615 7.42769 1.80555 7.43357 2.00713C7.43945 2.20872 7.49993 2.40493 7.60856 2.57484L9.42913 5.9657C4.28627 8.57141 1.71484 11.1771 1.71484 16.32C1.71484 21.0171 6.00056 23.1428 12.0006 23.1428Z" stroke="#1ABCFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M9.42969 6C9.71197 6.42325 10.0944 6.77027 10.5429 7.01029C10.9915 7.2503 11.4924 7.37588 12.0011 7.37588C12.5099 7.37588 13.0107 7.2503 13.4593 7.01029C13.9079 6.77027 14.2903 6.42325 14.5725 6" stroke="#1ABCFE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0_9_2168">
			<rect width="24" height="24" fill="white"/>
		</clipPath>
	</defs>
</svg>

export const clock = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_9_2175)">
		<path d="M12 6V12.8571L16.4571 15.0857" stroke="#0ACF83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M22.8442 14.5714C22.2411 17.1136 20.7638 19.3631 18.6705 20.9267C16.5772 22.4903 14.0011 23.2686 11.3922 23.1255C8.78331 22.9825 6.30766 21.9272 4.39787 20.1441C2.48808 18.361 1.26561 15.9635 0.944088 13.3706C0.622564 10.7776 1.22244 8.15417 2.63888 5.95864C4.05531 3.7631 6.19824 2.13512 8.69316 1.3592C11.1881 0.583278 13.8763 0.70876 16.2881 1.71372C18.6999 2.71867 20.6819 4.53919 21.8876 6.85713" stroke="#0ACF83" stroke-width="2" stroke-linecap="round"/>
		<path d="M23.1431 3.42859V7.7143H18.8574" stroke="#0ACF83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0_9_2175">
			<rect width="24" height="24" fill="white"/>
		</clipPath>
	</defs>
</svg>

export const spark = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_9_2184)">
		<path d="M10.3242 7.45203C9.15571 8.33761 8.05019 9.30337 7.01565 10.3423C2.14022 15.2177 -0.328346 20.6503 1.49908 22.4795C3.32651 24.3069 8.76079 21.8366 13.6345 16.9629C14.6735 15.9278 15.6393 14.8217 16.5248 13.6526" stroke="#6248FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M16.5251 13.6526C19.3999 17.4686 20.5708 21.0583 19.1514 22.4777C17.3222 24.3069 11.8896 21.8366 7.01422 16.9629C2.14222 12.0857 -0.326355 6.65485 1.50107 4.82571C2.9205 3.408 6.51022 4.57886 10.3262 7.452" stroke="#6248FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M9.4668 13.6526C9.4668 13.8799 9.5571 14.0979 9.71785 14.2587C9.87859 14.4194 10.0966 14.5097 10.3239 14.5097C10.5513 14.5097 10.7693 14.4194 10.93 14.2587C11.0908 14.0979 11.1811 13.8799 11.1811 13.6526C11.1811 13.4252 11.0908 13.2072 10.93 13.0465C10.7693 12.8857 10.5513 12.7954 10.3239 12.7954C10.0966 12.7954 9.87859 12.8857 9.71785 13.0465C9.5571 13.2072 9.4668 13.4252 9.4668 13.6526ZM13.7697 6.18857C13.2297 6.09429 13.2297 5.32114 13.7697 5.22857C14.7234 5.06267 15.6061 4.6165 16.3053 3.94695C17.0044 3.2774 17.4884 2.41481 17.6954 1.46914L17.7262 1.32C17.8428 0.788573 18.6005 0.785144 18.7205 1.31486L18.7617 1.488C18.9764 2.42948 19.4646 3.28649 20.1649 3.9514C20.8652 4.6163 21.7463 5.05944 22.6977 5.22514C23.2394 5.31943 23.2394 6.09772 22.6977 6.19029C21.7463 6.35599 20.8652 6.79913 20.1649 7.46403C19.4646 8.12894 18.9764 8.98595 18.7617 9.92743L18.7205 10.1006C18.6005 10.632 17.8428 10.6286 17.7262 10.0971L17.6919 9.948C17.4849 9.00234 17.001 8.13975 16.3018 7.4702C15.6027 6.80065 14.72 6.35448 13.7662 6.18857H13.7697Z" stroke="#6248FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0_9_2184">
			<rect width="24" height="24" fill="white"/>
		</clipPath>
	</defs>
</svg>
