//react
import {FC, useEffect, useState} from 'react'

//components
import InputField from '../inputField/inputField'

//hooks
import useDebounce from '../../../customHooks/useDebounce'

//types
import {floatInput} from '../../../types/general/generalTypes'

//other
import { formatStringAmountIntoNumber } from '../../../assets/general/generalFunctions'

type floatInputProps = floatInput

const FloatInput:FC<floatInputProps> = ({value, amountInputHandle, disabled, name, label, error})=>{
	const [inputValue, setInputValue]=useState<string>(value)
	const [isFocused, setFocused] = useState<boolean>(false)
	
	const debounceValue = useDebounce<string>(inputValue, 500)

	useEffect(() => {
		amountInputHandle(debounceValue)
	}, [debounceValue])

	useEffect(() => {
		if (isFocused){
			return
		}

		setTimeout(() => {
			setInputValue(value)
		}, 500)
	}, [value, isFocused])

	return (
		<InputField
			onChange={(event) => {
				const newValue = event.target.value
				if (/^\d*\.?\d*$/.test(newValue)) {
					// Only allow digits and a single dot

					setInputValue(parseFloat(newValue || '0.0').toFixed(2))
				} else {
					// If input does not match the pattern, ignore the change
					return
				}
				setInputValue(newValue)
			}}
			onFocus={() => {
				setFocused(true)
			}}
			onBlur={() => {
				setFocused(false)
			}}
			name={name}
			label={label}
			disabled={disabled}
			error={error}
			type="string"
			value={isFocused && isNaN(Number(inputValue)) ? formatStringAmountIntoNumber(inputValue).toString() : inputValue}
		/>
	)
}

export default FloatInput