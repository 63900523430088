// images
import settingsHomeEn from '../../../assets/manual/settings/en/settingsHome.png'
import settingsHome2En from '../../../assets/manual/settings/en/settingsHome2.png'
import settingsPhoneEn from '../../../assets/manual/settings/en/settingsAddPhoneNumber.png'
import settingsEmailEn from '../../../assets/manual/settings/en/settingsAddEmail.png'
import settingsLabelEn from '../../../assets/manual/settings/en/settingsAddLable.png'
import settingsPositionEn from '../../../assets/manual/settings/en/settingsCreatePosition.png'
import settingsHomeUa from '../../../assets/manual/settings/en/settingsHome.png'
import settingsHome2Ua from '../../../assets/manual/settings/en/settingsHome2.png'
import settingsPhoneUa from '../../../assets/manual/settings/en/settingsAddPhoneNumber.png'
import settingsEmailUa from '../../../assets/manual/settings/en/settingsAddEmail.png'
import settingsLabelUa from '../../../assets/manual/settings/en/settingsAddLable.png'
import settingsPositionUa from '../../../assets/manual/settings/en/settingsCreatePosition.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'


const SettingsManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.settings' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('settingsTitle'),
			text: t('settingsText')
		},
		{
			title: t('settingsAddPhoneNumberTitle'),
			text: t('settingsAddPhoneNumberText')
		},
		{
			title: t('settingsAddEmailTitle'),
			text: t('settingsAddEmailText')

		},
		{
			title: t('settingsAddLabelTitle'),
			text: t('settingsAddLabelText')
		},
		{
			title: t('settingsAddPositionTitle'),
			text: t('settingsAddPositionText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('generalSettingsTitle')}
			data={data}
		/>
	)
}

export default SettingsManual
