//react
import { FC, useEffect, useState } from 'react'

//components
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal'

//types
import { resolveType, detailedError } from '../../../../types/general/generalTypes'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//other
import { formatDetailedErrorObjectResponse } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'


type resolveErrorModalProps = {
	resolve: resolveType | undefined
	title: string
	closeModal: () => void
	isOpen: boolean
}

const ResolveErrorModal: FC<resolveErrorModalProps> = ({ resolve, title, closeModal, isOpen })=>{
	const { t } = useTranslation('', {keyPrefix:'general.error.errorList'})
	const { language } = useAppSelector((state) => state.general)

	const [ details, setDetails ] = useState<detailedError>()
	const [ loading, setLoading ] = useState(false)

	const buttons = [
		{ text: t('yes'), onClickHandler: () => {
			setLoading(true)
			resolve && resolve('POST', {language})
				.then(() => {
					setLoading(false)
					closeModal()
				})

		} },
		{ text: t('no'), onClickHandler: () => closeModal() }
	]

	const loadErrorDetails = async ()=>{
		if (!resolve) return
		setLoading(true)
		console.log(resolve)

		const { result } = await resolve('GET')
		const { description, objects } = result

		setDetails({
			description: t(description),
			objects: objects.map(formatDetailedErrorObjectResponse)
		})
		setLoading(false)
	}

	useEffect(() => {
		if(isOpen){
			loadErrorDetails()
		}
	}, [resolve, isOpen])

	useEffect(() => {
		if (!isOpen){
			setDetails(undefined)
		}
	}, [isOpen])
	
	return 	<ConfirmationModal
		description={details?.description}
		title={title}
		buttons={buttons}
		closeModal={closeModal}
		isOpen={isOpen}
		loading={loading}
		errorObjects={details?.objects}
	/>
}

export default ResolveErrorModal