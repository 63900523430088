// Define a custom node of employee

// react
import { FC, useEffect, useRef } from 'react'

// react
import { Handle, Position } from 'reactflow'

type trackingNodeProps = {
	isConnectable: boolean
	data: {
		element: React.ReactNode
	}
}

const TreeNode: FC<trackingNodeProps> = ({ isConnectable, data }) => {
	return (
		<div className='tree-node'>
			{/* Arrow Source */}
			<Handle
				type="target"
				position={Position.Top}
				isConnectable={isConnectable}
			/>

			{/* The element to be shown inside the node */}
			{data.element}

			{/* Arrow source */}
			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={isConnectable}
			/>
		</div>
	)
}

export default TreeNode