// styles
import './sectionWithLines.scss'

// react
import {ReactNode,FC} from 'react'

// components
import AnimatedLines from '../animatedLines/animatedLines'

// types
import {Properties} from 'csstype'

type sectionWithLines = {
	children: ReactNode
	background?: string
	sectionHeight?: string
	leftLineStyles?: {
		width: string
		transform:string
		left: string
		top: string
		hidden: boolean
	}
	rightLineStyles?: {
		width: string
		transform:string
		hidden: boolean
		left: string
		top: string
	}
}
const SectionWithLines:FC<sectionWithLines> = ({children, leftLineStyles, rightLineStyles, sectionHeight = '63vw', background = 'linear-gradient(185deg, #DFDFEF 4.09%, #FFF 95.77%)'}) => {
	
	const generateLineStyles = (
		left: string | undefined, 
		top:string | undefined, 
		hidden: boolean | undefined,
		transform: string | undefined) : Properties=> {
		return {
			position: 'absolute',
			left: left || '-15%',
			top: top || '-10%',
			visibility: hidden ? 'hidden' : 'visible',
			transform: transform || 'rotate(20deg)',
			zIndex: 100
		}
	}
	return (
		<div className="centered-section" style={{background: background, position: 'relative', height: sectionHeight}}>
			<div style={
				generateLineStyles(
					leftLineStyles?.left,
					leftLineStyles?.top,
					leftLineStyles?.hidden,
					leftLineStyles?.transform
				)}>
				<AnimatedLines numberOfLines={11} width={leftLineStyles?.width}/>
			</div>
			{children}
			<div style={
				generateLineStyles(
					rightLineStyles?.left || '85%', 
					rightLineStyles?.top || '12%', 
					rightLineStyles?.hidden,
					rightLineStyles?.transform || 'rotateZ(200deg)'
				)}>
				<AnimatedLines numberOfLines={11} width={rightLineStyles?.width}/>
			</div>
		</div>
	)
}

export default SectionWithLines