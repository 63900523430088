import { MouseEventHandler, ReactNode, SVGAttributes } from 'react'
import { budget, budgetResponse } from '../finance/budgetTypes'
import { employee } from '../employees/hierarchyTypes'
import { TFunction } from 'i18next'
import { account, accountResponse } from '../finance/accountTypes'
import { bill, billResponse } from '../finance/general'

export type moneyValue = {
	amount: number
	currency: currency
}

export type companyResponse = {
	avatar: string | null
	id: number
	company_id: number
	employee_id: number
	name: string
	description: string
}

export type company = {
	avatar: string | null
	companyId: number
	employeeId: number
	name: string
	description: string
}

export type button = {
	type?: 'submit' | 'reset' | 'button' | undefined
	text?: string
	active: boolean
	icon?: ReactNode
	inverted?: boolean
	outlined?: boolean
	onClick: () => void
	disabled?: boolean
}

export type dataObject = {
	id: number
	text: string
}

export enum taxationType {
	PE_GROUP_1 = 'PE_GROUP_1',
	PE_GROUP_2 = 'PE_GROUP_2',
	PE_GROUP_3 = 'PE_GROUP_3',
	PE_GROUP_3_VAT = 'PE_GROUP_3_VAT',
	PE_INCOME_TAX_PAYER = 'PE_INCOME_TAX_PAYER',
	LLC_GROUP_3 = 'LLC_GROUP_3',
	LLC_GROUP_3_VAT = 'LLC_GROUP_3_VAT',
	LLC_INCOME_TAX_PAYER = 'LLC_INCOME_TAX_PAYER'
}

export type inputField = {
	type?: string
	placeholder?: string
	name?: string
	popup?: infoPopup
	onSearch?: () => void
	minLength?: number
	value: string
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: React.FocusEvent<any, Element>) => void
	label?: string
	letterCounter?: number
	selection?: {
		setSelectedList: (options: selectionOption[]) => void
		selectedList: selectionOption[]
		loadSelectionOptions: (searchQuery: string, page: number) => Promise<selectionOption[]>
		maxSelected?:number
	}
	error?: string 
	errorInfoList?: string[]
	passwordVisibility?: {
		visible: boolean
		onClick: () => void
	}
  } & React.InputHTMLAttributes<HTMLInputElement>
  
export type floatInput = inputField & {
	value: string
    amountInputHandle: (value:string)=>void
}

export type selectionOption = {
	id: number
	avatar?: string | null
	bg?: string
	color?: string
	text: string
}

export type warehouseResponse = {
	id: number
	name: string
	worth: moneyValue[]
	coords: {
		lat: number
		lng: number
	}
	address: string
	shipment_amount: number
	moving_amount: number
	edit_access: boolean
	delete_access: boolean
}

export type locationResponse = {
	id: number
	address: string
	coords: {
		lng: number
		lat: number
	}
	type: 'warehouse' | 'address'
}

export enum orderTypes {
	sales='sales',
	purchase='purchase'
}

export type orderResponse = {
	counterparty: {
		avatar: string | null
		name: string
		relationship_id: number
	}
	id: number
	start_date: number | null
	end_date: number | null 
	items: offeringResponse[]
	destination: locationResponse | null
	edit_access: boolean
	delete_access: boolean
	close_access: boolean
	type: orderTypes
	project_id: number
	is_closed: boolean
	errors: errorResponse[]
}

export type shipmentResponse = {
	id: number
	start_date: number | null
	end_date: number | null
	status: status
	items: inventoryItemResponse[]
	edit_access?: boolean
	destination?: locationResponse
	origin?: locationResponse
	task_id?: number
	is_closed: boolean
}

export type location = {
	id: number
	address: string
	coords:{
		lng: number
		lat: number
	}
	type: 'warehouse' | 'address'
}
export type order = {
	counterparty: {
		avatar: string | null
		name: string
		relationshipId: number
	}
	id: number
	startDate: Date | null
	endDate: Date | null
	items: offering[]
	editAccess?: boolean
	destination: location | null
	type: orderTypes
	isClosed: boolean
	errors: error[]
}

export type shipment = {
	id: number
	startDate: Date | null
	endDate: Date | null
	status: status
	items: inventoryItem[]
	editAccess?: boolean
	destination?: location
	origin?: location
	isClosed: boolean
	taskId?: number
}

export type navBarSliderCaregory ={
	name: string
	icon: JSX.Element
	subCategories: navBarSliderSubCategory[]
}

export type navBarSliderSubCategory = {
	id: number
	name: string
	slides: subCategorySlide[]
}

export type subCategorySlide = {
	title: string
	image?: string
}

export type subCategory = {
	name: string
	url: string
	key: string | null
}

export type navbarCategory = {
	name: string
	subCategories: subCategory[]
	icon: JSX.Element
}

export type infoPopupNavbarResponse  = {
	key: string,
	info_popup: infoPopup
}

export type infoPopup = {
	content: {
		title: string
		description: string
		link?: string
	}[]
	type: infoPopupTypes
	isInsideReactFlow?:boolean
}

export enum infoPopupTypes {
	ERROR = 'error',
	RECCOMENDATION = 'recommendation',
	INFO = 'info'
}

export type dropdownOption = {
	title: string
	key: string
	avatar?: string | null
}

export type skillResponse = {
	id: number
	name: string
}

export type skill = {
	id: number
	name: string
}

export type positionResponse = {
    id: number
    name: string
    description: string
	skills: skillResponse[]
}

export type position = {
    id: number
    name: string
	skills: skill[]
}

export type phoneNumberResponse = {
	id: number
	number: string
}

export type phoneNumber = {
	id: number
	phoneNumber: string
}

export type emailResponse = {
	id: number
	email: string
}

export type email = {
	id: number
	email: string
}

export type label = {
	id: number
	color: string
	text: string
}

export type dropdown = {
	icon?: SVGAttributes<ReactNode> | null
	disabled?: boolean
	placeholder: string
	dropdownOptions: dropdownOption[]
	defaultSelectedOption?: dropdownOption
	onSelect?: (option: dropdownOption)=> void
	showAmount?:boolean
	loadOptions? : (search:string,page:number) => Promise<dropdownOption[]>
	selectedOption: dropdownOption | null | undefined
	error?: string
	popup?: infoPopup
	label?: string
}

export enum filterTypes {
	DROPDOWN = 'dropdown',
	RANGE = 'range',
	CALENDAR = 'calendar'
}

export type tableHeaderResponse = {
	title: string
	key: string
	translate?: boolean
	date?: boolean
}

export type tableHeader = {
	title: string | JSX.Element
	key: string
	translate?: boolean
	date?: boolean
}

export type filters = {
	filter: {
		type: filterTypes.DROPDOWN
		data: dropdown
		selectedOption: dropdownOption | undefined
		title: string
	} | {
		type: filterTypes.RANGE
		title: string
		data: { min: number, max: number }
		selectedOption: { min: number, max: number }
	} | {
		type: filterTypes.CALENDAR
		title: string
		data: undefined
		selectedOption: { startDate: Date | undefined, endDate: Date | undefined }
	}
	key: string
	notTranslated?: boolean
}

export enum weekDays {
    mon='mon',
    tue='tue',
    wed='wed',
    thu='thu',
    fri='fri',
    sat='sat',
    sun='sun'
}

export type shiftResponse = {
	day: weekDays
	shift: {
		start: string
		end: string
	}
}[]

export type shifts = [
    {
        day: weekDays.mon
        shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.tue
		shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.wed
		shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.thu
		shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.fri
		shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.sat
		shift: {start: number, end: number}[]
        active: boolean
    },
    {
        day: weekDays.sun
		shift: {start: number, end: number}[]
        active: boolean
    }
]

export enum settingFieldContentType {
	CHECKBOX = 'checkbox',
	DROPDOWN = 'dropdown',
	SWITCH = 'switch',
	INPUT = 'input'
}

type settingFieldContentCheckbox = {
	type: settingFieldContentType.CHECKBOX
	label: string
	onClick: () => void
	isChecked: boolean
}
type settingFieldContentDropdown = {
	type: settingFieldContentType.DROPDOWN
	label: string
	dropdownOptions: dropdownOption[]
	icon: SVGAttributes<React.ReactNode> | null

}
type settingFieldContentSwitch = {
	type: settingFieldContentType.SWITCH
	isChecked: boolean
	onClick: () => void
}
type settingFieldContentInput = {
	type: settingFieldContentType.INPUT
	label: string
}


export type settingFieldContent = settingFieldContentCheckbox | settingFieldContentDropdown | settingFieldContentInput | settingFieldContentSwitch

export type switchButton = {
	id: number
	text?: string
	icon?: ReactNode
	onClick?: MouseEventHandler<HTMLDivElement>
}

export type file = {
	file: Blob,
	fileName: string,
	fileType: string,
}

export type document = file & {
	id: number
}

export type review = {
	id: number
	reviewerName: string
	avatar: string | null
	description?: string
	rating: {
		title: string
		value: number
	}[]
	files?: file[]
}

export enum userStatus {
	ONLINE = 'online',
	OFFLINE = 'offline'
}


export enum currency {
	UAH = 'UAH',
	USD = 'USD',
	EUR = 'EUR'
}

export type notificationResponse = {
	id: number
	user_id: number
	type: string
	title: string
	description: string
	is_read: boolean
	date: number
	avatar: string | null
	url: string | null
}

export type notification = {
	id: number
	userId: number
	title: string
	description: string
	type: notificationType
	date: Date
	isRead: boolean
	avatar: string | null
	url: string | null
}


export enum notificationType {
	NEGATIVE = 'negative',
	POSITIVE = 'positive',
	NEUTRAL = 'neutral',
	INFO = 'info'
}

export type chartItemResponse = {
	label: string
	color: string
	data: charItemDataResponse[]
}

export type charItemDataResponse = {
	date: number
	value: number
}

export type chartItem = {
	label: string
	color: string
	data: charItemData[]
}

export type charItemData = {
	date: Date
	value: number
}

export enum chartViewOptions {
	PIE = 'pie',
	LINEAR = 'linear',
	BAR = 'bar'
}

export enum chartDurationOptions {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
	YEARLY = 'yearly'
}

export type legendItem = {
	label: string
	color: string
}


export type filterBar = {
	importButton?: () => void
	exportButton?: () => void
	filters?: filters[]
	addButton?: button
	onSearch?: (searchValue: string, filter: filters[]) => void
	notTranslated?: boolean,
	createAccess?:boolean
}
export enum relationshipTypes {
	CUSTOMER = 'customer',
	PROVIDER = 'provider'
}

export type customerResponse = {
	chat_id: number
	company_id: number
	name: string
	phone: string
	email: string
	avatar: string | null
	description: string
	last_deal_with: number
	created: number
	relationship_id: number
	relationship_type: relationshipTypes
	edit_access: boolean
	delete_access: boolean
	labels: { 
		title: string
		value: string
	}[]
}

export type relationship = counterparty & {
	relationshipId: number
	type: relationshipTypes
}

export type counterparty = {
	name: string
	avatar?: string | null
	placeholder?: JSX.Element
	description?: string
	tags?: {
		title?: string
		value: string
	}[]
}

export type inventoryItem = {
	inventoryItemId: number
	avatar: string | null
	name: string
	description: string 
	costs: moneyValue[]
	cost?: moneyValue
	quantity: number
	maxQuantity?: number
	type: itemTypes.inventory_item
	placeholder?:JSX.Element
	deleteAccess: boolean
}

export type item = inventoryItem | offering

export type offering = {
	items: inventoryItem[]
	quantity: number
	description: string
	name: string
	avatar: string | null
	offeringId: number
	price: moneyValue
	vat: number
	editAccess: boolean
	deleteAccess: boolean
	offering_type: offeringType	
	type: itemTypes.offering
	placeholder?: JSX.Element
	orderItemId? :number
}

export enum itemTypes {
	offering='offering',
	inventory_item = 'inventory_item'
}

export enum offeringType {
	product='product',
	service='service',
}

export type inventoryItemResponse = {
	avatar: string | null
	costs: moneyValue[]
	cost?: moneyValue
	description: string
	inventory_item_id: number
	quantity?: number
	name: string
	edit_access: boolean
	delete_access: boolean
	type: itemTypes.inventory_item
}

// export type orderItemResponse = {
// 	avatar: string | null
// 	name: string
// 	price: {
// 		amount: number
// 		currency: currency
// 	}
// 	quantity: number
// 	orderitem_id:number
// 	offering_id:number
// }

// export type orderItem = {
// 	orderItemId:number
// 	avatar: string | null
// 	name: string
// 	price: {
// 		amount: number
// 		currency: currency
// 	}
// 	quantity: number
// 	type: itemTypes.order_item
// 	offeringId:number
// 	placeholder?:JSX.Element
//  initialQuantity?: number
// }

export type offeringResponse = {
	offering_id: number
	name: string
	description: string
	price: moneyValue
	vat: number
	minimal_amount: number
	company_id: number
	items: inventoryItemResponse[]
	quantity?: number
	avatar: string | null
	images: {image: string}[]
	edit_access: boolean
	delete_access: boolean
	relationship_id?: number
	relationship_name?: string
	offering_type: offeringType
	type: itemTypes.offering
	orderitem_id?: number
}

export type calendarContainer = {
	startDate?: {
		date: Date | undefined
		setDate: (value: Date | undefined) => void
	}
	dueDate?: {
		date: Date | undefined
		setDate: (value: Date | undefined) => void
	}
	size?: 'small' | 'medium' | 'large'
	disabled?: boolean
	popup?: boolean
	label?: string
	title?: string
	hasTime?:boolean
}

export type editableInput = {
	value: string
	setValue: (value: string) => void
	disabled?: boolean
}

export type textArea  = {
	leftAddon?: ReactNode
	rightAddon?: ReactNode
	placeholder?: string
	value: string
	setValue: (value: string)=> void
	handleSubmit?: ()=> void
	attachedFiles?: file[]
	setAttachedFiles?: React.Dispatch<React.SetStateAction<file[]>>
	label?: string
	disabled?: boolean
	minRows?: number
	maxRows?: number
	enterForNewTab?: boolean
}

export type locationSearchInput = {
	setLocation: (value: { lat: number, lng: number} | undefined) => void
	setLoading?: (value: boolean) => void
	setAddress: (value: string) => void
	disabled?: boolean
	error?: string
}

export type editableTableItem = (offering | inventoryItem) & {
	idWithCurrency?: string,
	maxQuantity?: number 
}

export type editableTable = {
	items: editableTableItem[]
	setItems: ( value: editableTableItem[] ) => void
	loadItems: (searchQuery: string, page: number) => Promise<editableTableItem[]>
	selected?: {
		items: editableTableItem[]
		setItems: ( value: editableTableItem[] ) => void
	}
	reload?: boolean
	error?: string
	disabled?: boolean
	updateAmount?:(id: string, item: editableTableItem, selectedItems?: editableTableItem[]) => void
	limitMaxQuantity?: boolean
}


export type infoDetailsTableDataItem = {
	title: string
	fullWidth?: boolean
	data: {
		inputField?: inputField
		floatInput?: floatInput
		dropdown?: dropdown
		textArea?: textArea
		label?: {
			color: string
			bg: string
			text: string
		}
		calendar?: calendarContainer
		locationSearchInput?: locationSearchInput
		editableTable?: editableTable
		shiftSelector?: {
			shifts: shifts
			setShifts: (value: shifts, updateData?: boolean) => void
			disabled?: boolean
		}
	}
}


export enum status {
	open = 'open',
	in_progress ='in_progress',
	completed = 'completed',
	accepted = 'accepted',
	rejected = 'rejected' 
}

export type stageResponse = {
	title: string
	id: number
	quantity: number
	color: string
	create_access: boolean
}

export enum statusColor  {
	open = '#0000ff' ,
	in_progress = '#ffa500',
	completed = '#ffff00',
	accepted= '#008000',
	rejected = '#ff0000',
}

//dashboard

export type dragItem = string | null | dashboardCard

export type dragStage<dragItem> = {
    stageId: number
	stageName: string
	color: string
	quantity: number
	items: dragItem[]
	page: number
	isLoading: boolean
	lastPage: boolean
	createAccess: boolean
}

export type dragLocation = {
    stageId: number
	stage: string
	index: number
}

export type onDropSuccess = (e:React.DragEvent<HTMLDivElement>, dragInfo:dragLocation, dropInfo:dragLocation, dragData:dragItem) => void

export type dashboardAction = {
	slug: string
	label:string
	color:string
	onDrop?: onDropSuccess
}
export type dashboardCard = {
	id:number
	title:string
	counterparty?:{
		avatar: string | null
		name:string
	}
	executors?: executor[]
	labels?: taskLabel[]
	startDate?:Date | null
	endDate?:Date | null
	revenue?: moneyValue[]
	isClosed: boolean
	warehouseAction?: warehouseAction | null
	errors: error[]
}


export type projectResponse = {
	revenue: {
		amount: number
		currency: currency
	}[]
	budget: budgetResponse
	counterparty?: {
		name: string
		avatar: string | null
	}
	order_id: number
	title: string
	description: string
	pipeline_id: number
	pipeline_name: string
	project_id: number
	stage_id: number
	date: number | null
	due_date: number | null
	closed_tasks: number
	active_tasks: number
	edit_access: boolean
	is_closed: boolean
	chat_id: number
	errors: errorResponse[]
}

export type project = {
    id: number
    title: string
    counterparty?: {
		name: string
		avatar: string | null
	}
	description: string
	budget: budget
	date: Date | undefined
	dueDate: Date | undefined
	activeTasks: number
    revenue: moneyValue[]
	errors: error[]
	isClosed: boolean
	stageId: number
}

//tasks

export type task<Date> = {
    id: number 
    title: string
    executors: executor[]
    status: taskStatus
    labels: taskLabel[]
	subtasks: task<Date>[] | []
	project: {
		id: number
		title: string
	}
    description: string
    startDate: Date
    endDate: Date
	isClosed: boolean
	errors: error[]
	warehouseAction: warehouseAction | null
	lastOpen: boolean
	createAccess: boolean
} 

export type taskTree<Date> = {
    id: number 
    title: string
    status: taskStatus
	subtasks: taskTree<Date>[] | []
    description: string
    startDate: Date
    endDate: Date
	closed: boolean
} 

export type warehouseActionResponse = {
	id: number
	origin: locationResponse | null
	destination: locationResponse | null
	task_id: number
	inventory_items: inventoryItemResponse[]
}

export type warehouseAction = {
	id: number
	origin: location | null
	destination: location | null
	taskId: number
	inventoryItems: inventoryItem[]
}

export type taskResponse = {
    id: number
	project:{
		id: number
		title: string
	}
    company_id: number
    date:number
    due_date: number
    title: string
    description: string 
    status: status
	pipeline_id: number
	pipeline_name: string
    task_executors: executorResponse[]
    task_labels: {
		id: number
        color: string
        text: string
    }[]
	skills: {
		id: number
		name: string
	}[]
	parent_task: {
		id: number
		title: string
	}
	subtasks:taskResponse[] | []
	is_closed: boolean
	delete_access: boolean
    close_access: boolean
    create_access: boolean
    edit_access: boolean
	errors: errorResponse[]
	warehouse_action: warehouseActionResponse | null
	last_open: boolean
}

export type taskTreeResponse = {
	id: number
	project_id: number
	project_title: string
    company_id: number
    date: number
    due_date: number
    title: string
    description: string 
    status: status
	parent_task_id: string
	subtasks: taskTreeResponse[] | []
	delete_access: boolean
    close_access: boolean
    edit_access: boolean
	create_access: boolean
	closed: boolean
}


export type taskLabel = {
    id: number
    text:string 
    color:string
}

export type labelResponse = {
	id: number
	color: string
	text: string
}

export type employeeResponse = {
	employee_id: number
	user_id: number
	chat_id: number
	avatar: string
	name: string
	position: {
        name: string
        description: string
    }
    labels: {
        title: string
        value: string
    }[]
	is_owner: boolean
	in_team: boolean
	is_team_leader: boolean
	move_access: boolean
	fire_access: boolean
	edit_access: boolean
	replace_access: boolean
    subordinates?: employeeResponse[]
}


export type executorResponse = {
	id: number
    avatar: string | null
    name: string
    employee_id: number
}

export type executor = {
	id: number
    avatar: string | null
    name: string
    employeeId: number
}

export type taskStatus = {
    color: string,
    text: status
}

export type sortedTasks = [
    {
        stage: string
        tasks: task<Date | undefined>[]
    },
    {
        stage: string
        tasks: task<Date | undefined>[] 
    },
    {
        stage: string
        tasks: task<Date | undefined>[] 
    },
    {
        stage: string
        tasks: task<Date | undefined>[] 
    },
    {
        stage: string
        tasks: task<Date | undefined>[] 
	}

]


export type expandableCheckboxItem = {
	key: string
	title: string
	value?: string
	isChecked: boolean
}

export type expandableCheckbox = {
	mainOption: expandableCheckboxItem
	nestedOptions: expandableCheckboxItem[]
}

export type treeElement = {
	id: number
	element: JSX.Element
	subElements: treeElement[]
}

export type createOrderErrors = {
	counterparty?: string
	destination?: string
	pipeline?: string
}


export type errorResponse = {
	title: string
	description: string
	labels?: {
		title: string
		value: string
	}[]
	resolve_url?: string
	error_root_link?: string
	translate_key?: string
	translate?: boolean
}

export type resolveType = (method?: string, body?: object) => Promise<{result: detailedErrorResponse}>

export type error = {
	title: string
	description: string
	labels?: {
		title: string
		value: string
	}[]
	resolve?: resolveType
	errorRootLink?: string
	translateKey?: string
	translate?: boolean
}

export enum detailedErrorObjectTypes {
	EMPLOYEE='employee',
	SHIPMENT='shipment',
	BUDGET='budget',
	ORDER='order',
	ACCOUNT='account',
	PROJECT='project',
	BILL='bill',
	TASK='task',
	INVENTORY='inventory'
}

type detailedErrorEmployeeObject = {
	object: employee
	type: detailedErrorObjectTypes.EMPLOYEE
}

type detailedErrorShipmentObject = {
	object: shipment
	type: detailedErrorObjectTypes.SHIPMENT
}

type detailedErrorBudgetObject = {
	object: budget
	type: detailedErrorObjectTypes.BUDGET
}

type detailedErrorOrderObject = {
	object: order
	type: detailedErrorObjectTypes.ORDER
}

type detailedErrorAccountObject = {
	object: account
	type: detailedErrorObjectTypes.ACCOUNT
}


type detailedErrorProjectObject = {
	object: project
	type: detailedErrorObjectTypes.PROJECT
}

type detailedErrorBillObject = {
	object: bill
	type: detailedErrorObjectTypes.BILL
}

type detailedErrorTaskObject = {
	object: task<Date | undefined>
	type: detailedErrorObjectTypes.TASK
}

type detailedErrorInventoryObject = {
	object: inventoryItem
	type: detailedErrorObjectTypes.INVENTORY
}

export type detailedErrorObject = detailedErrorEmployeeObject | detailedErrorShipmentObject | detailedErrorBudgetObject | detailedErrorOrderObject | detailedErrorAccountObject | detailedErrorProjectObject | detailedErrorBillObject | detailedErrorTaskObject | detailedErrorInventoryObject

type detailedErrorEmployeeObjectResponse = {
	object: employeeResponse
	type: detailedErrorObjectTypes.EMPLOYEE
}

type detailedErrorShipmentObjectResponse = {
	object: shipmentResponse
	type: detailedErrorObjectTypes.SHIPMENT
}

type detailedErrorBudgetObjectResponse = {
	object: budgetResponse
	type: detailedErrorObjectTypes.BUDGET
}

type detailedErrorOrderObjectResponse = {
	object: orderResponse
	type: detailedErrorObjectTypes.ORDER
}

type detailedErrorAccountObjectResponse = {
	object: accountResponse
	type: detailedErrorObjectTypes.ACCOUNT
}

type detailedErrorProjectObjectResponse = {
	object: projectResponse
	type: detailedErrorObjectTypes.PROJECT
}

type detailedErrorBillObjectResponse = {
	object: billResponse
	type: detailedErrorObjectTypes.BILL
}


type detailedErrorTaskObjectResponse = {
	object: taskResponse
	type: detailedErrorObjectTypes.TASK
}

type detailedErrorInventoryObjectResponse = {
	object: inventoryItemResponse
	type: detailedErrorObjectTypes.INVENTORY
}



export type detailedErrorObjectResponse = detailedErrorEmployeeObjectResponse | detailedErrorShipmentObjectResponse | detailedErrorBudgetObjectResponse | detailedErrorOrderObjectResponse | detailedErrorAccountObjectResponse | detailedErrorProjectObjectResponse | detailedErrorBillObjectResponse | detailedErrorTaskObjectResponse | detailedErrorInventoryObjectResponse

export type detailedErrorResponse = {
	description: string
	objects: detailedErrorObjectResponse[]
}

export type detailedError = {
	description: string
	objects: detailedErrorObject[]
}