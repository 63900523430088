//styles and icons
import './audioWave.scss'

//react
import { FC, useEffect, useState } from 'react'


type audioWaveProps = {
	height: number,
	filling?: number
}  

const AudioWave: FC<audioWaveProps> = ({ height, filling=100}) => {
	// // Define the minimum and maximum heights for the level-item div.
	const minHeight = (5 / document.documentElement.clientWidth) * 100
	const maxHeight = 4

	const [ appear, setAppear ] = useState(false)

	// Convert the given height in pixels to viewport units (vw).
	const pxToVw = (px: number) => {
		const vw = (px / document.documentElement.clientWidth) * 100
		return vw
	}

	useEffect(() => {
		setTimeout(() => {
			setAppear(true)
		}, 1)
	}, [])

	// Clamp the given height within the range of minHeight to maxHeight.
	const clampedHeight = Math.max(Math.min(pxToVw(height), maxHeight), minHeight)
 
	// Return a div with a class of level-item and a dynamic height style based on the given height.
	return <div className={`audio-wave ${appear ? 'audio-wave-appeared' : null}`} style={{ height: `${clampedHeight}vw`, background: `linear-gradient( to right, var(--waveFillingAccent) ${filling}%, var(--waveAccent) ${filling}%)` }} />
}

export default AudioWave
