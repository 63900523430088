//styles
import './filePreviewModal.scss'

// react 
import { FC } from 'react'

// components
import Modal from '../modal/modal'

// redux 
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setFilePreviewModalIsOpen } from '../../../../redux/general/filePreview/modals'
import RestrictFileSizeModal from '../restrictFileSizeModal/restrictFileSizeModal'

const FilePreviewModal: FC = () => {

	const { modalIsOpen, fileUrl } = useAppSelector(state => state.filePreviewModals.filePreview)

	const dispatch = useAppDispatch()
	
	const closeModal = () => dispatch(setFilePreviewModalIsOpen(false))
	
	return (
		<>
			<div className="file-preview-modal-wrapper">

				<Modal
					closeModal={closeModal}
					open = {modalIsOpen}
					closeButton={false}
				>
					<img src = {fileUrl}/>
			
				</Modal>
			</div>
			<RestrictFileSizeModal />
		</>
	)
}

export default FilePreviewModal