// style 
import './employee.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components 
import Layout from '../../../components/general/layout/layout'
import EmployeeDetails from '../../../components/general/employee/employeeDetails/employeeDetails'
import EmployeeFiles from '../../../components/general/employee/employeeFiles/employeeFiles'
import EmployeeTasks from '../../../components/general/employee/employeeTasks/employeeTasks'
import ProjectsList from '../../../components/general/projectsList/projectsList'

// types 
import { button, filterBar, infoPopupTypes, project, projectResponse } from '../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import { singleEmployeeProjectsUrl } from '../../../utils/urls/employees/employee'

//other
import { formatProjectResponse } from '../../../assets/projects/projectsFunctions'

// translation
import { useTranslation } from 'react-i18next'

enum employeeSection {
	EmployeeDetails = 'EmployeeTransactionList',
	EmployeeFiles = 'EmployeeFiles',
	EmployeeProjects = 'EmployeeProjects',
	EmployeeTasks = 'EmployeeTasks',
}

const Employee: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'employees' })

	const { employeeId } = useParams()

	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeEmployeeSection, setActiveEmployeeSection] = useState<string>(employeeSection.EmployeeDetails)
	const [employeeName, setEmployeeName] = useState('')

	const loadProjects = async (stageId: number, searchRequest: string, page: number): Promise<project[]>  => {
		const response = await authorizedRequest(singleEmployeeProjectsUrl(Number(employeeId)) + `?&needle=${searchRequest}&status=${stageId}&page=${page}`, 'GET')
		const { result } = response
		return result.map((project: projectResponse) => formatProjectResponse(project))
		
	}

	const titleUrls = [
		{
			url: '/ri-business/employees/search-employee',
			title: t('employees')
		},
		{
			url: location.pathname,
			title: employeeName
		}
	]

	const infoPopup = {
		content: [{
			title: 'employee_info_title',
			description: 'employee_info_description',
			link: '/ri-manual/employees/search-employee'
		}],
		type: infoPopupTypes.INFO
	}

	const employeeTabButtons: button[] = [
		{
			active: activeEmployeeSection === employeeSection.EmployeeDetails,
			text: t('details'),
			onClick: () => setActiveEmployeeSection(employeeSection.EmployeeDetails),
		},
		{
			active: activeEmployeeSection === employeeSection.EmployeeFiles,
			text: t('files'),
			onClick: () => setActiveEmployeeSection(employeeSection.EmployeeFiles),
		},
		{
			active: activeEmployeeSection === employeeSection.EmployeeProjects,
			text: t('projects'),
			onClick: () => setActiveEmployeeSection(employeeSection.EmployeeProjects),
		},
		{
			active: activeEmployeeSection === employeeSection.EmployeeTasks,
			text: t('tasks'),
			onClick: () => setActiveEmployeeSection(employeeSection.EmployeeTasks),
		},
	]

	const renderTab = () => {
		switch (activeEmployeeSection) {
		case employeeSection.EmployeeDetails:
			return <EmployeeDetails setEmployeeName={setEmployeeName} />
		case employeeSection.EmployeeFiles:
			return <EmployeeFiles setFilterBar={setFilterBar} />
		case employeeSection.EmployeeProjects:
			return <ProjectsList loadProjects={loadProjects} setFilterBar={setFilterBar} />
		case employeeSection.EmployeeTasks:
			return <EmployeeTasks setFilterBar={setFilterBar} />
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={employeeTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className='employee-container'>
				{
					renderTab()
				}
			</div>
		</Layout>
	)
}


export default Employee