// network
import { authorizedRequest } from '../../../utils/queries'

//types
import { employee } from '../../../types/employees/hierarchyTypes'

//other
import { formatEmployeeResponse } from '../../general/generalFunctions'
import { switchButton } from '../../../types/general/generalTypes'

export const getEmployeeHierarchyData = async (url: string, getActions: (employee: employee) => switchButton[]) => {
	const data = await authorizedRequest(url, 'GET')

	const { result } = data

	const hierarchyData: employee[] = []

	for(let i=0; i<result.length; i++){
		const employeeData = formatEmployeeResponse(result[i], getActions)

		if(employeeData){
			hierarchyData.push(employeeData)
		}
	}

	return hierarchyData
}