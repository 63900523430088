//styles
import './employeeDetails.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import PermissionSelector from '../../../employees/general/permissionSelector/permissionSelector'
import Loading from '../../loading/loading'

// types
import { currency, dropdownOption, employeeResponse, infoDetailsTableDataItem, moneyValue, positionResponse, shiftResponse, shifts } from '../../../../types/general/generalTypes'

// network 
import { authorizedRequest } from '../../../../utils/queries'
import { getCompanyPositionsUrl } from '../../../../utils/old_urls/general/generalUrls'
import { singleEmployeeUrl } from '../../../../utils/urls/employees/employee'
import { hierarchyEmployeesLeadersUrl } from '../../../../utils/urls/employees/hierarchy'


//translation
import { useTranslation } from 'react-i18next'

// other
import { formatShiftForBackend, formatShiftResponse, formatStringIntoTwoDigitsFloat } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

type employeeDetailsProps = {
	setEmployeeName: (value: string) => void
}

type details = {
	wage: moneyValue
	position: dropdownOption
	shifts: shifts
}

type employeeDetailsResponse = {
	name: string
	last_name: string
	wage: moneyValue
	position: {
		id: number
		name: string
		description: string
	}
	shifts: shiftResponse
	permissions: string[]
	edit_access: boolean
}

const EmployeeDetails: FC<employeeDetailsProps> = ({ setEmployeeName }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.employee.employeeDetails' })
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })

	const { employeeId } = useParams()
	const navigate = useNavigate()


	const [infoDetailsTable, setInfoDetailsTable] = useState<infoDetailsTableDataItem[]>([])
	const [employeeDetailsEdit, setEmployeeDetalsEdit] = useState<boolean>(false)
	const [editAccess, setEditAccess] = useState(false)
	const [details, setDetails] = useState<details>()

	const amountInputHandle = (rawValue: string) => {
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		details && setDetails({ ...details, wage: { amount: numberValue, currency: details.wage.currency } })
		setEmployeeDetalsEdit(numberValue!==details?.wage.amount)
	}

	const [permissions, setPermissions] = useState<string[]>([])

	const { userCompanyData } = useAppSelector(state => state.general)


	const loadPositionOptions = async (query: string, page: number) => {
		const { result }: { result: positionResponse[] } = await authorizedRequest(getCompanyPositionsUrl(userCompanyData?.companyId || -1, query, page, 10), 'GET')
		if (result.length > 0) {
			const positions: dropdownOption[] = result.map((position: positionResponse) => {
				return {
					title: position.name,
					key: `${position.id}`
				}
			})
			return positions
		}

		return []

	}

	useEffect(() => {
		if (details) {
			setInfoDetailsTable([
				{
					title: t('position'),
					data: {
						dropdown: {
							placeholder: '',
							dropdownOptions: [],
							defaultSelectedOption: details.position,
							loadOptions: loadPositionOptions,
							onSelect: (value) => {
								setDetails({ ...details, position: value })
								setEmployeeDetalsEdit(true)
							},
							selectedOption: { title: details.position.title, key: details.position.key },
							disabled: !editAccess
						}
					}
				},
				{
					title: t('wage'),
					data: {
						floatInput: {
							amountInputHandle: amountInputHandle,
							value: `${details.wage.amount}`,
							disabled: !editAccess
						}
					}
				},
				{
					title: t('currency'),
					data: {
						dropdown: {
							placeholder: '',
							dropdownOptions: [{ key: currency.USD, title: tCurrency(currency.USD) }, { key: currency.EUR, title: tCurrency(currency.EUR) }, { key: currency.UAH, title: tCurrency(currency.UAH) }],
							defaultSelectedOption: { key: details.wage.currency, title: details.wage.currency },
							onSelect: (value) => {
								setDetails({ ...details, wage: { amount: details.wage.amount, currency: value.key as currency } })
								setEmployeeDetalsEdit(true)
							},
							selectedOption: { title: tCurrency(details.wage.currency), key: details.wage.currency },
							disabled: !editAccess
						}
					}
				},
				{
					title: t('shifts'),
					data: {
						shiftSelector: {
							shifts: details.shifts,
							disabled: !editAccess,
							setShifts: (value, update) => {

								setDetails({ ...details, shifts: value })

								if (update) {
									setEmployeeDetalsEdit(update)
								}
							}
						}
					}
				}
			])
		}
	}, [details, editAccess])


	const loadData = async () => {
		if (isNaN(Number(employeeId))) {
			navigate('/ri-business/404')
		}
		const { result }: { result: employeeDetailsResponse } = await authorizedRequest(singleEmployeeUrl(Number(employeeId)), 'GET')
		setEmployeeName(result.name)

		setDetails({
			wage: result.wage,
			position: { key: `${result.position.id}`, title: result.position.name },
			shifts: formatShiftResponse(result.shifts)
		})

		setPermissions(result.permissions)
		setEditAccess(result.edit_access)
	}

	const editData = async () => {

		if (employeeId && details) {
			await authorizedRequest(singleEmployeeUrl(Number(employeeId)), 'PUT', 'accessToken', {
				wage: details.wage.amount,
				shift_data: formatShiftForBackend(details.shifts),
				position_id: Number(details.position.key),
				currency: details.wage.currency,
				permissions: permissions
			})
			setEmployeeDetalsEdit(false)
		}
	}


	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (employeeDetailsEdit) {

			timeout = setTimeout(() => {
				editData()
			}, 500)
		}

		return () => {
			clearTimeout(timeout)
		}
	}, [details, employeeDetailsEdit])


	useEffect(() => {
		loadData()
	}, [])

	return (
		<div className="employee-details">
			<InfoDetailsTable data={infoDetailsTable} />
			{infoDetailsTable.length > 1 && editAccess && <PermissionSelector permissions={permissions} setPermissions={(value) => {
				setEmployeeDetalsEdit(true)
				setPermissions(value)
			}} />}
			{!details && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</div>
	)
}

export default EmployeeDetails