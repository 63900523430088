// react
import { FC } from 'react'

// component
import FileExportModal from '../../fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportAccountFileModalIsOpen } from '../../../../../redux/general/modals'

type exportAccountComponentType = {
	exportAccountUrl: string
}

const ExportAccountFileModal: FC<exportAccountComponentType> = ({ exportAccountUrl }) => {
	const { modalIsOpen } = useAppSelector((state) => state.accontListModal.exportAccountFileModal)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setExportAccountFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(exportAccountUrl + `?export=True&export_type=${exportType}`, 'GET')

		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportAccountFileModal