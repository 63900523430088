//styles
import './dropAreaIndicator.scss'

// context
import useDragAndDrop from '../context/dragAndDropHandlerContext'

// react
import { FC } from 'react'

type dropAreaIndicatorProps = {
    stage: string,
	index: number,
	dropAreaSize?: string 
}

const DropAreaIndicator: FC<dropAreaIndicatorProps> = ({stage,index, dropAreaSize ='8.44vw'}) => {
		
	const { dropInfo } = useDragAndDrop()
	const visible = dropInfo !== null && dropInfo?.stage === stage && dropInfo?.index === index 
	
	return (
		<div className='drop-area-draging-over-card' style={{
			minHeight: visible ? dropAreaSize : '0',
			visibility: visible ? 'initial' : 'collapse',
			margin: visible ? 'inherit' : 'initial'
		}} ></div>
	)
}

export default DropAreaIndicator