// react
import { Dispatch, FC, SetStateAction, useState } from 'react'

//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setDeleteCustomerRelationshipId, setDeleteCustomerModalIsOpen } from '../../../../../redux/sales/customerList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleCustomerUrl } from '../../../../../utils/urls/sales/customer'

// translation
import { useTranslation } from 'react-i18next'

// types
import { customer } from '../../../../../types/sales/customerTypes'


type deleteCustomerModalProps = {
	customers: customer[]
	setCustomers: Dispatch<SetStateAction<customer[]>>
}

const DeleteCustomerModal: FC<deleteCustomerModalProps> = ({customers, setCustomers}) => {
	const { modalIsOpen, relationshipId } = useAppSelector((state) => state.customerListModal.deleteCustomerModal)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const { t } = useTranslation('', {keyPrefix: 'sales.customerList.modals.deleteCustomerModal'})

	const closeModal = () => {
		dispatch(setDeleteCustomerModalIsOpen(false))
		dispatch(setDeleteCustomerRelationshipId(null))
	}

	const handleDeleteCustomer = () => {
		setLoading(true)
		authorizedRequest( singleCustomerUrl(relationshipId!), 'DELETE' )
			.then( ( response ) => {
				const updatedCustomers = customers.filter(customer => customer.id !== relationshipId)
				setCustomers(updatedCustomers)
				setLoading(false)
				closeModal()
			})
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisCustomer')} 
			closeModal={closeModal} 
			isOpen={modalIsOpen}
			buttons={[{text: t('yes'), onClickHandler: handleDeleteCustomer}, {text: t('no'), onClickHandler: closeModal}]}
			loading={loading}
		/>
	)

}

export default DeleteCustomerModal