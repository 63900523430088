//styles and icons
import './chatDesktop.scss'

//react
import { FC, useEffect } from 'react'

//components
import MessageArea from './messageArea/messageArea'
import ChatHeader from './chatHeader/chatHeader'
import ChatInput from './chatInput/chatInput'
import ChatInfoSlider from './sliders/chatInfoSlider/chatInfoSlider'
import MessagesSlider from './sliders/messagesSlider/messagesSlider'
import ChatFilesSlider from './sliders/chatFilesSlider/chatFilesSlider'
import FileMessage from './modals/attachFilesModal/attachFilesModal'
import ForwardMessages from './modals/forwardMessages/forwardMessages'
import DeleteMessageModal from './modals/deleteMessageModal/deleteMessageModal'

//context
import useChatDesktop from './context/chatDesktopContext'

//types
import { chatMember } from '../../../../types/employees/chatTypes'

//network
import { getChatInfoUrl, chatStatusUrl } from '../../../../utils/old_urls/employees/chatsUrls'
import { authorizedRequest } from '../../../../utils/queries'

type chatDesktopProps = {
	chatId: number
}

const ChatDesktop: FC<chatDesktopProps> = ({ chatId }) => {
	

	const { chatInfo, setChatData, setChatStatus, setMessages, setContextMenu } = useChatDesktop()

	const updateStatus = (chatId: number) => {
		authorizedRequest(chatStatusUrl(chatId), 'GET')
			.then((responce) => {
				const { result } = responce
				console.warn(result)
				if(result){
					let online = 0
					let offline = 0
					const typing: string[] = []

					for(let i=0; i<result.length; i++){
						if(result[i].is_online){
							online++
						}else{
							offline++
						}
						

						if(result[i].is_typing){
							const member = chatInfo.data.chatMembers.find((member) => member.userId === result[i].user_id && member.inboxId === result[i].inbox_id)

							if(member){
								typing.push(member.name)
							}
						}
					}
					setChatStatus({online, offline, typing})
				}
			})
	}


	useEffect(() => {
		const closePopUps = () => {
			setContextMenu(null)
		}
		
		window.addEventListener('click', closePopUps)

		return () => {
			window.removeEventListener('click', closePopUps)
		}
	}, [])

	useEffect(() => {
		if (chatId) {
			setMessages([])


			authorizedRequest(getChatInfoUrl(chatId), 'GET')
				.then((responce) => {

					const { result } = responce

					const newChatMembers: chatMember[] = []

					result.participants.forEach((member: {user_id: number | null, inbox_id: number | null, name: string, avatar: string | null}) => {
						newChatMembers.push({
							userId: member.user_id,
							inboxId: member.inbox_id,
							avatar: member.avatar,
							name: member.name
						})
					})

					setChatData({
						name: result.name,
						description: result.description,
						avatar: result.avatar,
						date: new Date(result.date*1000),
						group: result.is_group,
						chatMembers: [...newChatMembers],
						chatId: chatId,
						ownerId: result.owner_id
					})

				})

			
		}
	}, [chatId])

	useEffect(() => {
		if(chatInfo.data.chatMembers.length > 0){
			const interval = setInterval(() => {
				updateStatus(chatId)
			}, 2000)


			return () => {
				clearInterval(interval)
			}
		}
	}, [chatInfo.data.chatMembers])

	return (
		<div className="chat-desktop">
			<ChatHeader/>
			<div className="chat-desktop-container">

				<div className="chat-desktop-message-area-container">
					<MessageArea/>
					<ChatInput/>
				</div>
				<ChatInfoSlider/>
				<ChatFilesSlider/>
				<MessagesSlider/>
			</div>
			<FileMessage/>
			<ForwardMessages/>
			<DeleteMessageModal/>
		</div>
	)
}

export default ChatDesktop
