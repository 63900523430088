//icons
import { forecastIcon } from '../../../../assets/projects/pipeline/pipelineIcons'

//components
import ListItemCard from '../../../general/listItemCard/listItemCard'

//react
import { FC, useEffect, useState } from 'react'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteWarehouseId, setDeleteWarehouseModalIsOpen } from '../../../../redux/warehouse/warehouseList/modals'

//types
import { warehouse } from '../../../../types/warehouse/generalTypes'

//other
import { currencyToFormattedString } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'


type warehouseListItemProps = warehouse

const WarehouseListItem: FC<warehouseListItemProps> = ({id, name, address, shipmentAmount, movingAmount, worth, deleteAccess}) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouseList.warehouseListItem' })

	const dispatch = useAppDispatch()	
	
	const handleDelete	= (id: number) => {
		dispatch(setDeleteWarehouseId(id))
		dispatch(setDeleteWarehouseModalIsOpen(true))
	}


	return (
		<ListItemCard
			title={name}
			description={address}
			data={[
				...worth.map((worth) => {
					return {
						title: t('worth'),
						value: currencyToFormattedString(worth.amount, worth.currency)
				
					}
				}),
				{
					title: t('activeShipments'),
					value: `${shipmentAmount}`
				},
				{
					title: t('activeMovings'),
					value: `${movingAmount}`
				}
			]}
			footer={{
				viewLink: {
					text: t('viewWarehouse'),
					url: `/ri-business/warehouse/warehouse/${id}`
				},
				deleteFunction: deleteAccess ? () => { handleDelete(id) } : undefined
			}}
		/>
	)
}

export default WarehouseListItem