//styles
import './layout.scss'

// react
import {FC, ReactNode} from 'react'

// components
import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import CookiesPopup from '../cookiesPopup/cookiesPopup'

type layoutProps = {
	children: ReactNode
}
const Layout: FC<layoutProps> = ({children}) => {
	return (
		<div className='landing-layout'>
			<Navbar/>
			{children}
			<Footer/>
			<CookiesPopup />
		</div>
	)
}

export default Layout