// styles
import './manualLayout.scss'

// react
import {FC} from 'react'

// components
import Layout from '../layout/layout'
import SectionWithLines from '../sectionWithLines/sectionWithLines'
import ParticlesBg from '../particlesBg/particlesBg'
import ManualItem from '../manualItem/manualItem'
import ScrollAnimation from '../scrollAnimation/scrollAnimation'

//types
import {manualData} from '../../../types/types'

type manualLayoutProps = {
    heading: string,
	data: manualData[]
}

const ManualLayout: FC<manualLayoutProps>  = ({heading, data}) => {
	return (
		<Layout>
			<SectionWithLines
				sectionHeight='50vw'
				leftLineStyles={{width: '30vw',left: '-20%', top: '-45%',hidden: false, transform: 'rotate(50deg)'}}
				rightLineStyles={{hidden: true, width: '60vw',left: '0%', top: '0%', transform:'rotateZ(200deg)',}}
			>
				<div className='manual-layout-header-wrapper'>
					<ScrollAnimation direction='to-bottom'>
						<div className='manual-layout-header'>
							<h1 className='manual-layout-header-title'>{heading}</h1>
						</div>
					</ScrollAnimation>
				</div>
			</SectionWithLines>	
				
			<ScrollAnimation direction='to-bottom' distance={2}>
				<div className='manual-layout-content'>

					{
						data.map(({title, text, images, process, link})=> (
							<ManualItem
								title={title}
								text={text}
								images={images}
								process={process}
								link={link}
							/>
						))
					}

				</div>
			</ScrollAnimation>
			<ParticlesBg/>
		</Layout>
	)
}

export default ManualLayout