// styles and icons
import './createGroupChat.scss'

//react
import { useState } from 'react'

// components
import Modal from '../../../modals/modal/modal'
import InputField from '../../../inputField/inputField'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux' 
import { setCreateGroupChatIsOpen } from '../../../../../redux/employees/chat/modals'

//types
import { selectionOption } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { createGroupChatUrl } from '../../../../../utils/old_urls/employees/chatsUrls'
import { contactsUrl } from '../../../../../utils/old_urls/generalUrls'

// translation
import { useTranslation } from 'react-i18next'

type errorType = {
	name?: string
}

const CreateGroupChat = () => {
	const { modalIsOpen } = useAppSelector((state) => state.chatModal.createGroupChat)
	const dispatch = useAppDispatch()

	const [ groupName, setGroupName ] = useState('')
	const [ searchValue, setSearchValue ] = useState('')

	const [ errors, setErrors ] = useState<errorType>({})
    
	const [ selectedContacts, setSelectedContacts ] = useState<selectionOption[]>([])

	const {t} = useTranslation('', { keyPrefix: 'general.chats.modals.createGroupChatModal' })

	const closeModal = () => {
		dispatch(setCreateGroupChatIsOpen(false))
		setErrors({})
		setGroupName('')
		setSearchValue('')
		setSelectedContacts([])
	}

	const getChats = (query: string, page: number) => {
		return authorizedRequest(contactsUrl + `?page=${page}&per_page=10&needle=${query}`, 'GET')
			.then((response) => {
				//if(page > 1) return []
				const { result } = response
				const { data } = result

				const contacts: selectionOption[] = []

				data.forEach((contact: {
					user_id: number,
					avatar: string | undefined,
					first_name: string,
					last_name: string
				}) => {
					contacts.push({
						id: contact.user_id,
						avatar: contact.avatar,
						text: `${contact.first_name} ${contact.last_name}`,
					})
				})

				return contacts
			})
	}

	const checkErrors = () => {
		let result = true

		if(groupName === ''){
			result = false
			setErrors({...errors, name: t('enterTheNamePlease')})
		}

		return result
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if(checkErrors()){
						if(!selectedContacts.length || !groupName) {
							return 
						}
						authorizedRequest(createGroupChatUrl, 'POST', 'accessToken', {
							name: groupName,
							participants: [...selectedContacts.map((contact) => contact.id)]
						})
						closeModal()
					}
				}
			}}
			title={t('title')}
		>
			<form className="create-group-chat-container">
				<div className="create-group-chat-input-container">
					<InputField
						placeholder=''
						name='name'
						type='text'
						value={groupName}
						label={t('name')}
						onChange={(e) => setGroupName(e.target.value)}
						error={errors.name}
					/>
					<InputField
						placeholder=''
						name='name'
						type='text'
						value={searchValue}
						label={t('participants')}
						onChange={(e) => setSearchValue(e.target.value)}
						selection={{
							selectedList: selectedContacts,
							setSelectedList: setSelectedContacts,
							loadSelectionOptions: getChats
						}}
					/>
				</div>
			</form>

		</Modal>
	)

}

export default CreateGroupChat