// styles
import './videoMessageChatInput.scss'

// react
import React, { FC, useEffect, useRef } from 'react'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

// network
import { chatStatusUrl } from '../../../../../../../utils/old_urls/employees/chatsUrls'
import { authorizedRequest } from '../../../../../../../utils/queries'


type videoMessageChatInputProps = {
	handleSubmit: () => void
}
const VideoMessageChatInput: FC<videoMessageChatInputProps> = ({ handleSubmit }) => {
	const { chatInfo, chatInputValue, setChatInputValue } = useChatDesktop()

	const chatInputRef = useRef<HTMLInputElement>(null)

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			event.currentTarget.innerHTML = ''
			handleSubmit()
		}else if(event.key === 'Enter' && event.shiftKey){
			setChatInputValue(chatInputValue + '\n')
		}
	}

	useEffect(()=>{
		setChatInputValue('')

		return ()=>{
			setChatInputValue('')
		}
	},[])

	useEffect(() => {
		if(chatInfo.data.chatId){
			authorizedRequest(chatStatusUrl(chatInfo.data.chatId), 'PUT')
		}
	}, [chatInputValue])

	return (
		<div className='video-chat-input-container'>
			<div ref={chatInputRef} 
				onKeyDown={handleKeyDown} 
				className='video-chat-input' 
				onInput={(e)=>{
					setChatInputValue(e.currentTarget.innerHTML)
				}}
				contentEditable
			>
			</div>
		</div>
	)
}

export default VideoMessageChatInput