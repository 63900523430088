// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'

import { setImportTransactionFileSelectorModalFile, setImportTransactionFileSelectorModalIsOpen, setTransactionTablePreviewModalIsOpen } from '../../../../../../redux/finance/general/modals'

const ImportTransactionFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.financeGeneralModal.importTransactionFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportTransactionFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportTransactionFileSelectorModalFile(files[0]))
		dispatch(setImportTransactionFileSelectorModalIsOpen(false))
		dispatch(setTransactionTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}			
		/>
	)
}

export default ImportTransactionFileSelectorModal