import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type warehouseListModalsState = {
	createWarehouseModal: {
		modalIsOpen: boolean
	}
	deleteWarehouseModal: {
		modalIsOpen: boolean
		warehouseId: number | null
	}
	importWarehouseFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    warehouseTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportWarehouseFileModal: {
        modalIsOpen: boolean
    }
}

const initialState: warehouseListModalsState = {
	createWarehouseModal: {
		modalIsOpen: false
	},
	deleteWarehouseModal: {
		modalIsOpen: false,
		warehouseId: null
	},
	importWarehouseFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	warehouseTablePreviewModal: {
		modalIsOpen: false
	},
	exportWarehouseFileModal: {
		modalIsOpen: false
	}
}

export const warehouseListModalsSlice = createSlice({
	name: 'warehouseListModalReducer',
	initialState,
	reducers : {
		setCreateWarehouseModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createWarehouseModal.modalIsOpen = action.payload
		},
		setDeleteWarehouseModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteWarehouseModal.modalIsOpen = action.payload
		},
		setDeleteWarehouseId: (state, action: PayloadAction<number | null>) => {
			state.deleteWarehouseModal.warehouseId = action.payload
		},
		setImportWarehouseFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importWarehouseFileSelectorModal.modalIsOpen = action.payload
		},
		setImportWarehouseFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importWarehouseFileSelectorModal.file = action.payload
		},
		setWarehouseTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.warehouseTablePreviewModal.modalIsOpen = action.payload
		},
		setExportWarehouseFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportWarehouseFileModal.modalIsOpen = action.payload
		}
	}
})

export const { setCreateWarehouseModalIsOpen, setDeleteWarehouseModalIsOpen, setDeleteWarehouseId, setImportWarehouseFileSelectorModalIsOpen, setImportWarehouseFileSelectorModalFile, setWarehouseTablePreviewModalIsOpen, setExportWarehouseFileModalIsOpen } = warehouseListModalsSlice.actions

export default warehouseListModalsSlice.reducer