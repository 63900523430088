//styles
import './switchButtons.scss'

// react
import { FC } from 'react'

//types
import { switchButton } from '../../../types/general/generalTypes'

type switchButtonsProps = {
    switchButtons: switchButton[]
    activeSwitchButtonId?: number
    setActiveSwitchButtonId?: (value: number) => void
    size?:'small' | 'medium' | 'large'
    orientation?:'vertical' | 'horizontal'
}

const SwitchButtons: FC<switchButtonsProps> = ({ switchButtons, activeSwitchButtonId, setActiveSwitchButtonId, size, orientation }) => {

	return (
		<div className={`switch-buttons-container ${size ? size : ''} ${orientation ? orientation : ''}`}>
			{
				switchButtons.map((button, index) => {
					return (
						<div 
							className={'switch-button' + ` ${button.id === activeSwitchButtonId ? 'active' : ''}`} 
							onClick={button.onClick ? button.onClick: (e) => {
								e.stopPropagation()
								setActiveSwitchButtonId && setActiveSwitchButtonId(button.id)
							}} 
							key={'switch-button-'+index+'-'+button.text}
						>
							{button.icon ?? button.text}
						</div>
					)
				})
			}

		</div>
	)
}

export default SwitchButtons