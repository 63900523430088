import { baseUrl } from '../../network'

// export const salesAnalysisUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/sales`
// export const growthAnalysisUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/growth`
// export const pricingAnalysisUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/pricing`
// export const marketingROIAnalysisUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/marketing`

export const newCustomersRatio = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/new_customers_ratio`
export const averageSalesPerEmployee = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/sales_per_employee`
export const averageOrderVolume = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/volume`
export const productServiceProfitability = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/profitability`
export const marketingCampaignRevenue = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/marketing_roi`

export const totalSales = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/total_sales`
export const averageSales = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/average_sales`
export const salesGrowthRate = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/sales_growth`
export const salesCycleLength  = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/cycle`

export const topOfferingsByUnitsSold  = (companyId: number) => baseUrl + `/api/company/${companyId}/sales/stats/top_offerings`

