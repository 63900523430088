import './billDate.scss'

//react
import { FC } from 'react'

//translation
import { useTranslation } from 'react-i18next'

type billDateProps = {
	isDue: boolean | null
	daysLeft: number | null
	date: string
}

const BillDate: FC<billDateProps> = ({ isDue, daysLeft, date }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.billListItem.billDate' })

	return (
		<div className='bill-date-container'>
			<p className='date'>{date}</p>
			{isDue != null && daysLeft != null && daysLeft < 0 && <p className='expired'>{t('expired', { days: Math.abs(daysLeft) })}</p>}
			{isDue != null && daysLeft != null && daysLeft >= 0 && daysLeft < 5 && <p className='soon-expired'>{t('soonExpired', { days: Math.abs(daysLeft) === 0 ? 'less than a day' : Math.abs(daysLeft) })}</p>}
		</div>
	)
}

export default BillDate