// react
import { FC } from 'react'

// component
import FileExportModal from '../../../../../general/modals/fileExportModal/fileExportModal'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { inventoryItemsUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setExportInventoryItemFileModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

const ExportInventoryItemFileModal: FC = () => {
	const companyId = parseInt(localStorage.getItem('companyId') || '-1')

	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.exportInventoryItemFileModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setExportInventoryItemFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(inventoryItemsUrl(companyId) + `?export=True&export_type=${exportType}`, 'GET')
		console.log(result)
		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={getFile}

		/>
	)
}

export default ExportInventoryItemFileModal