// styles
import './expandableCheckboxes.scss'

// components
import CheckBox from '../checkBox/checkBox'

// react
import { FC, useEffect, useState } from 'react'
import { expandableCheckbox, expandableCheckboxItem } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type expandableCheboxesProps = {
	option: expandableCheckbox
	onSelect: (option: expandableCheckbox)=> void
}
const ExpandableCheckboxes: FC<expandableCheboxesProps> = ({option, onSelect}) => {
	const {t} = useTranslation('', { keyPrefix:'general.permissions' })

	const handleMainCheckboxClick = ()=> {
		const updatednestedOptions = option.nestedOptions?.map(nestedOption => {
			return {...nestedOption, isChecked: !option.mainOption.isChecked}
		})

		onSelect({
			mainOption: {...option.mainOption, isChecked: !option.mainOption.isChecked},
			nestedOptions: updatednestedOptions
		})
	}

	const handleNestedCheckboxClick = (nestedOption: expandableCheckboxItem, idx: number)=> {
		option.nestedOptions[idx] = {...nestedOption, isChecked: !nestedOption.isChecked}
		const anyChecked = option.nestedOptions.some(nestedOption => nestedOption.isChecked === true)
		if(anyChecked) {
			onSelect({
				mainOption: {...option.mainOption, isChecked: true},
				nestedOptions: [...option.nestedOptions]
			})
		} else {
			onSelect({
				mainOption: {...option.mainOption, isChecked: false},
				nestedOptions: [...option.nestedOptions]
			})
		}



	}

	return (
		<div className='expandable-checkbox'>
			<div className={`expandable-checkbox-main-option ${option.mainOption.isChecked && 'checkbox-main-option-selected'}`}>
				<CheckBox 
					isChecked={option.mainOption.isChecked} 
					onClick={handleMainCheckboxClick}
					label={t(option.mainOption.title)}
				/>
			</div>
			<div  className={`expandable-checkbox-nested-options ${option.mainOption.isChecked && 'nested-options-expanded'}`}>
				<div className="expandable-checkbox-nested-options-container">
					{option.nestedOptions && option.nestedOptions.map((nestedOption,idx) => 
						<div className="checkbox-nested-option" key={`nested-checkbox-${nestedOption.key}`}>
							<CheckBox
								label={t(nestedOption.title)}
								isChecked={nestedOption.isChecked}
								onClick={()=> handleNestedCheckboxClick(nestedOption, idx)}
							/>
						</div>
					)}
				</div>
			</div>
			
		</div>
	)
}

export default ExpandableCheckboxes