//styles
import './shipmentCard.scss'
import { warehouseProduct, activeStatus, closedStatus } from '../../../assets/general/generalIcons'

//react
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

//compoenents
import StatusLabel from '../statusLabel/statusLabel'
import Table from '../table/table'
import Avatar from '../avatar/avatar'

//types
import { shipment } from '../../../types/general/generalTypes'

//other
import { currencyToFormattedString, formatDate, translateDate, translateEnum } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type shipmentCardProps = shipment & {
	link?: string
}

const ShipmentCard:FC<shipmentCardProps> = ({id, taskId, origin, destination, startDate, endDate, status, items, link, isClosed}) => {
	const statusT = useTranslation('', { keyPrefix: 'general.status' }).t
	const { t } = useTranslation('', {keyPrefix:'warehouse.shipmentCard'})

	const navigate = useNavigate()

	const columns = [
		{key: 'products', title: t('products')},
		{key: 'quantity', title: t('quantity')},
		{key: 'cost', title: t('cost')}
	]

	return (
		<div className="shipment-item-container" onClick={() => {
			navigate(link ? link : `/ri-business/projects/task/${taskId}`)
		}}>
			<div className="shipment-item-header">
				<div className="shipment-item-header-info">
					{ origin ? <div className="shipment-item-header-info-text"><p>{t('origin')}:</p><p> {origin?.address}</p></div> : null }
					{ destination ? <div className="shipment-item-header-info-text"><p>{t('destination')}:</p><p> {destination?.address}</p></div> : null }
				</div>
				<div className="shipment-item-header-status">
					<StatusLabel color='#1db954' bg='#E0FAE9' text={translateEnum(statusT, status.toLowerCase())} />
				</div>
				<div className="shipment-item-header-status">
					{
						isClosed ? closedStatus : activeStatus
					}
				</div>
			</div>
			<div className="shipment-item-table-container">
				<Table  
					columns={
						columns
					}
					data={
						items.map((item) => {
							return {
								products: (
									<div className='shipment-item-product-container'>
										<Avatar blobAvatar={item.avatar} placeholder={warehouseProduct}/>
										<p className="shipment-item-product-name">{item.name}</p>
									</div>
								),
								cost: item.cost ? currencyToFormattedString(item.cost.amount, item.cost.currency) : '',
								quantity: `x${item.quantity}`
							}
						}) 
					}
					showHeader={true}
				/>
			</div>
			<div className="shipment-item-footer">
				<div className="shipment-item-footer-date-container">
					{ startDate ? <p className='shipment-item-footer-date'>{formatDate(startDate, true, true)}</p> : null }
					{ endDate ? <p className={`shipment-item-footer-date ${translateDate(endDate) <= new Date() ? t('late' ): ''}`}>{formatDate(endDate, true, true)}</p> : null }
				</div>
			</div>
		</div>
	)
}

export default ShipmentCard