// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileExportModal from '../../../../../general/modals/fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProjectReportUrl } from '../../../../../../utils/urls/projects/project'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setExportProjectReportModalIsOpen } from '../../../../../../redux/projects/project/modals'

const ExportProjectReportModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.projectModals.exportProjectReport)
	const { language } = useAppSelector((state) => state.general)

	const { projectId } = useParams()
	
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setExportProjectReportModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(singleProjectReportUrl(Number(projectId)) + `?export=True&export_type=${exportType}&language=${language}`, 'GET')
        
		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}	
			exportFileTypes={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportProjectReportModal