//styles and icons
import './additionalData.scss'
import { forwardedIcon } from '../../../../../../../assets/employees/chat/chatIcons'

//components
import ReplyBanner from './replyBanner/replyBanner'

//react
import { FC } from 'react'

//context
import useChatDesktop from '../../../context/chatDesktopContext'

//types
import { messageTypes, messageType } from '../../../../../../../types/employees/chatTypes'

//translation
import { useTranslation } from 'react-i18next'

type additionalDataProps = {
    forwarded: messageType | null
    edited: boolean
    type: messageTypes
    replied: messageType | null,
}

const AdditionalData: FC<additionalDataProps> = ({ forwarded, edited, type, replied }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.messageArea.chatMessage.additionalData' })

	const { chatInfo } = useChatDesktop()

	return (
		<>
			{
				forwarded || edited ?
					<div className="chat-message-additional-data-container">
						{forwarded ? <p className={`chat-message-additional-data ${type === messageTypes.USER ? 'user' : 'stranger'}`}>{forwardedIcon}{chatInfo.data.chatMembers.find((member) => forwarded.userId === member.userId)?.name}</p> : null}
						{edited ? <p className='chat-message-additional-data'>{t('edited')}</p> : null}
					</div>
					: null
			}
			{ replied ? <ReplyBanner chatMembers={chatInfo.data.chatMembers} message={replied} /> : null }
		</>
	)
}

export default AdditionalData