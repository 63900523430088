// styles
import './switch.scss'

// react
import { FC } from 'react'

type switchProps = {
	onClick?: () => void;
	isChecked: boolean;
}

const Switch: FC<switchProps> = ({isChecked,onClick}) => {
	return (
		<div className={`switch ${isChecked ? 'on' : 'off'}`} onClick={onClick}>
			<div className="switch-slider">
			</div>
		</div>
	)
}

export default Switch