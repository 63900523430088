import { Dispatch, FC, SetStateAction } from 'react'

import { videoFullScreenButton } from '../../../../assets/employees/chat/chatIcons'  

type videoPlayerFullscreenButtonProps = {
    isFullscreen: boolean,
    setIsFullscreen: Dispatch<SetStateAction<boolean>>
}

const VideoPlayerFullscreenButton: FC<videoPlayerFullscreenButtonProps> = ({ isFullscreen, setIsFullscreen }) => {

	const fullscreenHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
        
		const element = document.documentElement
		if (element.requestFullscreen && !isFullscreen){
			element.requestFullscreen()
			setIsFullscreen(true)
		}else{
			document.exitFullscreen()
			setIsFullscreen(false)
		}
	}

	return (
		<div className="video-player-controls-fullscreen-button" onClick={fullscreenHandler}>
			{videoFullScreenButton}
		</div>
	)
}

export default VideoPlayerFullscreenButton