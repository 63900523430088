//styles
import './permissionSelector.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import ExpandableCheckboxes from '../../../general/expandableCheckboxes/expandableCheckboxes'

//types
import { expandableCheckbox } from '../../../../types/general/generalTypes'

//other
import { formatPermissionForBackend } from '../../../../assets/general/generalFunctions'

type permissionSelectorProps = {
    permissions: string[]
    setPermissions: (value: string[]) => void
}

const PermissionSelector: FC<permissionSelectorProps> = ({ permissions, setPermissions }) => {
	const [permissionOptions, setPermissionOptions] = useState<expandableCheckbox[]>([
		{
			mainOption: {
				title: 'general',
				key: 'general',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'addPositions',
					key: 'add_positions',
					value: 'add_positions',
					isChecked: false
				},
				{
					title: 'editPositions',
					key: 'edit_positions',
					value: 'edit_positions',
					isChecked: false
				},
				{
					title: 'deletePositions',
					key: 'delete_positions',
					value: 'delete_positions',
					isChecked: false
				},
				{
					title: 'addLabels',
					key: 'add_labels',
					value: 'add_labels',
					isChecked: false
				},
				{
					title: 'deleteLabels',
					key: 'delete_labels',
					value: 'delete_labels',
					isChecked: false
				},
				{
					title: 'companySettings',
					key: 'company_settings',
					value: 'company_settings',
					isChecked: false
				},
				{
					title: 'getOrders',
					key: 'get_orders',
					value: 'get_orders',
					isChecked: false
				},
				{
					title: 'addOrders',
					key: 'add_orders',
					value: 'add_orders',
					isChecked: false
				},
				{
					title: 'editOrders',
					key: 'edit_orders',
					value: 'edit_orders',
					isChecked: false
				},
				{
					title: 'deleteOrders',
					key: 'delete_orders',
					value: 'delete_orders',
					isChecked: false
				}
			]
		},
		{
			mainOption: {
				title: 'employees',
				key: 'employees',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'hireEmployees',
					key: 'hire_employees',
					value: 'hire_employees',
					isChecked: false
				},
				{
					title: 'moveEmployees',
					key: 'move_employees',
					value: 'move_employees',
					isChecked: false
				},
				{
					title: 'fireEmployees',
					key: 'fire_employees',
					value: 'fire_employees',
					isChecked: false
				},
				{
					title: 'editEmployees',
					key: 'edit_employees',
					value: 'edit_employees',
					isChecked: false
				},
				{
					title: 'employeeStatistics',
					key: 'employee_statistics',
					value: 'employee_statistics',
					isChecked: false
				}
			]
		},
		{
			mainOption: {
				title: 'projects',
				key: 'projects',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'addPipelines',
					key: 'add_pipelines',
					value: 'add_pipelines',
					isChecked: false
				},
				{
					title: 'editPipelines',
					key: 'edit_pipelines',
					value: 'edit_pipelines',
					isChecked: false
				},
				{
					title: 'deletePipelines',
					key: 'delete_pipelines',
					value: 'delete_pipelines',
					isChecked: false
				},
				{
					title: 'projectStatistics',
					key: 'project_statistics',
					value: 'project_statistics',
					isChecked: false
				},
				{
					title: 'addProjects',
					key: 'add_projects',
					value: 'add_projects',
					isChecked: false
				},
				{
					title: 'editProjects',
					key: 'edit_projects',
					value: 'edit_projects',
					isChecked: false
				},
				{
					title: 'deleteProjects',
					key: 'delete_projects',
					value: 'delete_projects',
					isChecked: false
				},
				{
					title: 'addTasks',
					key: 'add_tasks',
					value: 'add_tasks',
					isChecked: false
				},
				{
					title: 'editTasks',
					key: 'edit_tasks',
					value: 'edit_tasks',
					isChecked: false
				},
				{
					title: 'deleteTasks',
					key: 'delete_tasks',
					value: 'delete_tasks',
					isChecked: false
				}
			]
		},
		{
			mainOption: {
				title: 'sales',
				key: 'sales',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'getCustomers',
					key: 'get_customers',
					value: 'get_customers',
					isChecked: false
				},
				{
					title: 'addCustomers',
					key: 'add_customers',
					value: 'add_customers',
					isChecked: false
				},
				{
					title: 'editCustomers',
					key: 'edit_customers',
					value: 'edit_customers',
					isChecked: false
				},
				{
					title: 'deleteCustomers',
					key: 'delete_customers',
					value: 'delete_customers',
					isChecked: false
				},
				{
					title: 'salesStatistics',
					key: 'sales_statistics',
					value: 'sales_statistics',
					isChecked: false
				},
				{
					title: 'getOfferings',
					key: 'get_offerings',
					value: 'get_offerings',
					isChecked: false
				},
				{
					title: 'addOfferings',
					key: 'add_offerings',
					value: 'add_offerings',
					isChecked: false
				},
				{
					title: 'editOfferings',
					key: 'edit_offerings',
					value: 'edit_offerings',
					isChecked: false
				},
				{
					title: 'deleteOfferings',
					key: 'delete_offerings',
					value: 'delete_offerings',
					isChecked: false
				},
			]
		},
		{
			mainOption: {
				title: 'purchase',
				key: 'purchase',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'getProviders',
					key: 'get_providers',
					value: 'get_providers',
					isChecked: false
				},
				{
					title: 'addProviders',
					key: 'add_providers',
					value: 'add_providers',
					isChecked: false
				},
				{
					title: 'editProviders',
					key: 'edit_providers',
					value: 'edit_providers',
					isChecked: false
				},
				{
					title: 'deleteProviders',
					key: 'delete_providers',
					value: 'delete_providers',
					isChecked: false
				},
				{
					title: 'purchaseStatistics',
					key: 'purchase_statistics',
					value: 'purchase_statistics',
					isChecked: false
				},
			]
		},
		{
			mainOption: {
				title: 'warehouses',
				key: 'warehouses',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'warehousesStatistics',
					key: 'warehouse_statistics',
					value: 'warehouse_statistics',
					isChecked: false
				},
				{
					title: 'getWarehouses',
					key: 'get_warehouses',
					value: 'get_warehouses',
					isChecked: false
				},
				{
					title: 'addWarehouses',
					key: 'add_warehouses',
					value: 'add_warehouses',
					isChecked: false
				},
				{
					title: 'editWarehouses',
					key: 'edit_warehouses',
					value: 'edit_warehouses',
					isChecked: false
				},
				{
					title: 'deleteWarehouses',
					key: 'delete_warehouses',
					value: 'delete_warehouses',
					isChecked: false
				},
				{
					title: 'getWarehouseActions',
					key: 'get_warehouse_actions',
					value: 'get_warehouse_actions',
					isChecked: false
				},
				{
					title: 'addWarehouseActions',
					key: 'add_warehouse_actions',
					value: 'add_warehouse_actions',
					isChecked: false
				},
				{
					title: 'editWarehouseActions',
					key: 'edit_warehouse_actions',
					value: 'edit_warehouse_actions',
					isChecked: false
				},
				{
					title: 'deleteWarehouseActions',
					key: 'delete_warehouse_actions',
					value: 'delete_warehouse_actions',
					isChecked: false
				},
				{
					title: 'getInventoryItems',
					key: 'get_inventory_items',
					value: 'get_inventory_items',
					isChecked: false
				},
				{
					title: 'addInventoryItems',
					key: 'add_inventory_items',
					value: 'add_inventory_items',
					isChecked: false
				},
				{
					title: 'editInventoryItems',
					key: 'edit_inventory_items',
					value: 'edit_inventory_items',
					isChecked: false
				},
				{
					title: 'deleteInventoryItems',
					key: 'delete_inventory_items',
					value: 'delete_inventory_items',
					isChecked: false
				},
			]
		},
		{
			mainOption: {
				title: 'finance',
				key: 'finance',
				isChecked: false
			},
			nestedOptions: [
				{
					title: 'financeStatistics',
					key: 'finance_statistics',
					value: 'finance_statistics',
					isChecked: false
				},
				{
					title: 'reports',
					key: 'financial_reports',
					value: 'financial_reports',
					isChecked: false
				},
				{
					title: 'getAccounts',
					key: 'get_accounts',
					value: 'get_accounts',
					isChecked: false
				},
				{
					title: 'addAccounts',
					key: 'add_accounts',
					value: 'add_accounts',
					isChecked: false
				},
				{
					title: 'editAccounts',
					key: 'edit_accounts',
					value: 'edit_accounts',
					isChecked: false
				},
				{
					title: 'deleteAccounts',
					key: 'delete_accounts',
					value: 'delete_accounts',
					isChecked: false
				},
				{
					title: 'getTransactions',
					key: 'get_transactions',
					value: 'get_transactions',
					isChecked: false
				},
				{
					title: 'addTransactions',
					key: 'add_transactions',
					value: 'add_transactions',
					isChecked: false
				},
				{
					title: 'editTransactions',
					key: 'edit_transactions',
					value: 'edit_transactions',
					isChecked: false
				},
				{
					title: 'deleteTransactions',
					key: 'delete_transactions',
					value: 'delete_transactions',
					isChecked: false
				},
				{
					title: 'getBudgets',
					key: 'get_budgets',
					value: 'get_budgets',
					isChecked: false
				},
				{
					title: 'addBudgets',
					key: 'add_budgets',
					value: 'add_budgets',
					isChecked: false
				},
				{
					title: 'editBudgets',
					key: 'edit_budgets',
					value: 'edit_budgets',
					isChecked: false
				},
				{
					title: 'deleteBudgets',
					key: 'delete_budgets',
					value: 'delete_budgets',
					isChecked: false
				},
				{
					title: 'getBills',
					key: 'get_bills',
					value: 'get_bills',
					isChecked: false
				},
				{
					title: 'addBills',
					key: 'add_bills',
					value: 'add_bills',
					isChecked: false
				},
				{
					title: 'editBills',
					key: 'edit_bills',
					value: 'edit_bills',
					isChecked: false
				},
				{
					title: 'deleteBills',
					key: 'delete_bills',
					value: 'delete_bills',
					isChecked: false
				},
			]
		},
	])

	useEffect(() => {
		const updatedPermissionOptions = permissionOptions.map((permissionOption) => {
			const nested = permissionOption.nestedOptions.map((nestedOption) => {
				return { 
					...nestedOption,
					isChecked: permissions.indexOf(nestedOption.key) > -1
				}
			})

			let someChecked = false
            
			nested.forEach((nestedOption) => {
				if(nestedOption.isChecked){
					someChecked = true
				}
			})

			return {
				mainOption: {
					...permissionOption.mainOption,
					isChecked: someChecked
				},
				nestedOptions: nested
			}
		})

		setPermissionOptions([...updatedPermissionOptions])
	}, [permissions])

	return (
		<div className="functions-checkbox-container">
			{permissionOptions.map((option, idx) => {
				return (
					<ExpandableCheckboxes
						key={`checkbox-${idx}`}
						option={option}
						onSelect={(updatedOptions) => {
							permissionOptions[idx] = updatedOptions
							setPermissions(formatPermissionForBackend(permissionOptions))
						}}
					/>
				)
			})}
		</div>
	)
}

export default PermissionSelector