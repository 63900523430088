// styles
import './doubleInputRange.scss'

//components
import InputField from '../inputField/inputField'

// react
import { FC, useState, useRef, useEffect } from 'react'

//translation
import { useTranslation } from 'react-i18next'

type inputRangeProps = {
	title: string
	size?: 'small' | 'medium'
	min: {
		default: number
		value: number
		setValue: (value: number) => void
	}
	max: {
		default: number
		value: number
		setValue: (value: number) => void
	}
}

const DoubleInputRange : FC<inputRangeProps> = ({ title, size='medium', min, max }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.doubleInputRange' })

	const sldierRef = useRef<HTMLDivElement | null>(null)

	const [ minOffset, setMinOffset ] = useState(0)
	const [ maxOffset, setMaxOffset ] = useState(100)
	const [minValue, setMinValue ] = useState(min.default)
	const [maxValue, setMaxValue ] = useState(max.default)

	// useEffect(() => {
	// 	setMinOffset(Math.min(percentOffset(min.value), maxOffset))
	// }, [min.value])

	useEffect(() => {
		setMinOffset(Math.min(percentOffset(minValue), maxOffset))
	}, [minValue])

	// useEffect(() => {
	// 	setMaxOffset(Math.max(percentOffset(max.value), minOffset))
	// }, [max.value])

	useEffect(() => {
		setMaxOffset(Math.max(percentOffset(maxValue), minOffset))
	}, [maxValue])

	const percentOffset = (value: number) => {
		return (value-min.default)/(max.default-min.default) * 100
	}

	const handleDragStartMin = () => {
		// Add event listeners for drag-related events
		document.addEventListener('mousemove', handleDragMin)
		document.addEventListener('mouseup', handleDragEndMin)
	}

	const handleDragEndMin = () => {
		// Remove event listeners for drag-related events
		document.removeEventListener('mousemove', handleDragMin)
		document.removeEventListener('mouseup', handleDragEndMin)
	}


	const handleDragMin = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const slider = sldierRef.current
		const minMaxDiff = max.default - min.default

		if(slider){
			const sliderRect = slider.getBoundingClientRect()

			const offsetX = Math.min(Math.max(0, event.clientX - sliderRect.left), sliderRect.width)
			const percent = offsetX/sliderRect.width

			// min.setValue(Math.floor(Math.min(percent*minMaxDiff + min.default, max.value)))
			setMinValue(Math.floor(Math.min(percent*minMaxDiff + min.default, max.value)))
		}
	}

	const handleDragStartMax = () => {
		// Add event listeners for drag-related events
		document.addEventListener('mousemove', handleDragMax)
		document.addEventListener('mouseup', handleDragEndMax)
	}

	const handleDragEndMax = () => {
		// Remove event listeners for drag-related events
		document.removeEventListener('mousemove', handleDragMax)
		document.removeEventListener('mouseup', handleDragEndMax)
	}


	const handleDragMax = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const slider = sldierRef.current

		const minMaxDiff = max.default - min.default
		
		if(slider){
			const sliderRect = slider.getBoundingClientRect()

			const offsetX = Math.min(Math.max(0, event.clientX - sliderRect.left), sliderRect.width)

			const percent = offsetX/sliderRect.width
			// max.setValue(Math.floor(Math.max(percent*minMaxDiff + min.default, min.value)))
			setMaxValue(Math.floor(Math.max(percent*minMaxDiff + min.default, min.value)))
		}
	}

	useEffect(()=> {
		const timeout = setTimeout(()=>{
			if(minValue < min.default) {
				setMinValue(min.default)
				min.setValue(min.default)
			}
			else if(minValue > max.value) {
				setMinValue(max.value)
				min.setValue(max.value)
			} else {
				min.setValue(minValue)
			}
		}, 500)

		return ()=> clearTimeout(timeout)
	},[minValue])

	useEffect(()=> {
		const timeout = setTimeout(()=>{
			if(maxValue > max.default) {
				setMaxValue(max.default)
				max.setValue(max.default)
			}
			else if(maxValue < min.value) {
				setMaxValue(min.value)
				max.setValue(min.value)
			} else {
				max.setValue(maxValue)
			}
		}, 500)

		return ()=> clearTimeout(timeout)
	},[maxValue])
	
	return (
		<div className={`input-range ${size}`}>
			<p className="input-range-title">{ title }</p>
			<div className="input-range-values">
				<div className="input-range-values-container">
					<InputField
						type='number'
						onChange={(e) => {
							setMinValue(Math.max(parseInt(e.target.value.toLowerCase().replace(/[a-z]/g,''))))
							// min.setValue(Math.min(Math.max(parseInt(e.target.value.toLowerCase().replace(/[a-z]/g,'')), min.default), max.value))
						}}
						value={`${minValue}`}
						name='range-min-value'
						label={t('min')}
					/>
				</div>
				<div className="input-range-values-container">
					<InputField
						type='number'
						onChange={(e) => {
							setMaxValue(Math.min(parseInt(e.target.value.toLowerCase().replace(/[a-z]/g,''))))
							// max.setValue(Math.max(Math.min(parseInt(e.target.value.toLowerCase().replace(/[a-z]/g,'')), max.default), min.value))
						}}
						value={`${maxValue}`}
						name='range-max-value'
						label={t('max')}
					/>
				</div>
			</div>

			<div className="input-range-slider" ref={sldierRef}>
				<div
					style={{
						background: `linear-gradient(to right, #D9D9D9 ${minOffset}%, #1E1B39 ${minOffset}%, #1E1B39 ${maxOffset}%, #D9D9D9 ${maxOffset}%)`
					}}
					className="input-range-slider-progress"
				></div>
				
				<div
					style={{
						left: `calc(${minOffset}% - 0.5vw)`
					}}
					className="input-range-slider-ball" 
					onMouseDown={() => {
						handleDragStartMin()
					}}
				></div>
				
				<div
					style={{
						left: `calc(${maxOffset}% - 0.5vw)`
					}}
					className="input-range-slider-ball"
					onMouseDown={() => {
						handleDragStartMax()
					}}
				></div>
			</div>
		</div>
	)
}

export default DoubleInputRange