import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { file } from '../../../types/general/generalTypes'

type employeeModalsState = {
	employeeFiles: {
		deleteEmployeeFileModal: {
			modalIsOpen: boolean
			fileId: number | null
		}
		importEmployeeFileFileSelectorModal: {
			modalIsOpen: boolean
			file: file | null
		}
	}
}

const initialState: employeeModalsState = {
	employeeFiles: {
		deleteEmployeeFileModal: {
			modalIsOpen: false,
			fileId: null
		},
		importEmployeeFileFileSelectorModal: {
			modalIsOpen: false,
			file: null
		}
	}
}

export const employeeModalsSlice = createSlice({
	name: 'employeeModalsSlice',
	initialState,
	reducers: {
		setDeleteEmployeeFileModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.employeeFiles.deleteEmployeeFileModal.modalIsOpen = action.payload
		},
		setDeleteEmployeeFileId: ( state, action: PayloadAction<number | null>) => {
			state.employeeFiles.deleteEmployeeFileModal.fileId = action.payload
		},
		setImportEmployeeFileFileSelectorModalIsOpen : (state, action: PayloadAction<boolean>) => {
			state.employeeFiles.importEmployeeFileFileSelectorModal.modalIsOpen = action.payload
		},
		setImportEmployeeFileFileSelectorFile : (state, action: PayloadAction<file | null>) => {
			state.employeeFiles.importEmployeeFileFileSelectorModal.file = action.payload
		},
	}
})

export const { 
	setDeleteEmployeeFileModalIsOpen, 
	setDeleteEmployeeFileId,
	setImportEmployeeFileFileSelectorModalIsOpen,
	setImportEmployeeFileFileSelectorFile
} = employeeModalsSlice.actions

export default employeeModalsSlice.reducer