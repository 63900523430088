import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'

import { videoVolumeButton } from '../../../../assets/employees/chat/chatIcons'

type videoPlayerVolumeControlsProps = {
    isVolumeControlsOpen: boolean,
    videoVolume: number,
    setVideoVolume: Dispatch<SetStateAction<number>>,
	setIsDragging: Dispatch<SetStateAction<boolean>>,
	setIsVolumeControlsOpen: Dispatch<SetStateAction<boolean>>
}

const VideoPlayerVolumeControls: FC<videoPlayerVolumeControlsProps> = ({ isVolumeControlsOpen, setIsVolumeControlsOpen, videoVolume, setVideoVolume, setIsDragging }) => {
	const volumeBarRef = useRef<HTMLDivElement>(null)
	const volumeControlsRef = useRef<HTMLDivElement>(null)

	const [ volumeControlsTimeout, setVolumeControlsTimeout ] = useState<NodeJS.Timer>()
    

	const handleDragStart = () => {
		// Add event listeners for drag-related events
		document.addEventListener('mousemove', handleDrag)
		document.addEventListener('mouseup', handleDragEnd)
		setIsDragging(true)
	}
    
	const handleDrag = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (volumeBarRef.current) {
			const volumeBarRect = volumeBarRef.current.getBoundingClientRect()
			const offsetX = event.clientX - volumeBarRect.left
			const volumePercentage = offsetX / volumeBarRect.width
			const newVolume = Math.max(0, Math.min(1, volumePercentage))
			setVideoVolume(newVolume)
		}
	}
    
	const handleDragEnd = () => {
		// Remove event listeners for drag-related events
		document.removeEventListener('mousemove', handleDrag)
		document.removeEventListener('mouseup', handleDragEnd)
		setTimeout(() => {setIsDragging(false)}, 100)
	}

	const handleMouseMove = (e: MouseEvent) => {
		if(volumeControlsRef.current){
			const { clientX, clientY } = e // Get the current cursor position

			const rect = volumeControlsRef.current.getBoundingClientRect() // Get the block's position and size
		
			const { left, top, width, height } = rect
	
			// Check if the cursor is within the block's boundaries
			const cursorInside = clientX >= left && clientX <= (left + width) &&
								clientY >= top && clientY <= (top + height)
			
			setIsVolumeControlsOpen(cursorInside)
		}
	}

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove)

		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
		}
	}, [])

	return (
		<div className="video-player-controls-volume"
			ref={volumeControlsRef}
			onClick={(e) => {e.stopPropagation()}}
		>
			<div className="video-player-controls-volume-button">
				{ videoVolumeButton }
			</div>
			<div className={`video-player-controls-volume-bar-container ${isVolumeControlsOpen ? 'show-volume-bar' : ''}`} onMouseDown={(e) => {
				e.stopPropagation()
				handleDragStart()
				handleDrag(e)
			}} >
				<div className="video-player-volume-bar" style={{background: `linear-gradient(to right, #376ABE ${videoVolume * 100}%, #fff ${videoVolume * 100}%)`}} ref={volumeBarRef}></div>
			</div>
		</div>
	)
}

export default VideoPlayerVolumeControls