// react
import { useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Efficiency from '../../../components/projects/projectsStats/efficiency/efficiency'

// types
import { button, infoPopupTypes } from '../../../types/general/generalTypes'

//translation
import {useTranslation} from 'react-i18next'
import PerformanceAndEfficiency from '../../../components/projects/projectsStats/performanceAndEfficiency/PerformanceAndEfficiency'

enum projectsStatsSection  {
	Efficiency = 'Efficiency',
	PerformanceAndEfficiency = 'PerformanceAndEfficiency'
}
const ProjectsStats = () => {
	const [activeProjectsStatsSection, setActiveProjectsStatsSection] = useState(projectsStatsSection.Efficiency)
	const {t}=useTranslation('',{keyPrefix:'projects.projectStat'})

	const ProjectsStatsButtons: button[] = [
		{
			active: activeProjectsStatsSection === projectsStatsSection.Efficiency,
			text: t('efficiency'),
			onClick: () => {setActiveProjectsStatsSection(projectsStatsSection.Efficiency)}
		},
		{
			active: activeProjectsStatsSection === projectsStatsSection.PerformanceAndEfficiency,
			text: t('performanceAndEfficiency'),
			onClick: () => {setActiveProjectsStatsSection(projectsStatsSection.PerformanceAndEfficiency)}
		}

	]

	const titleUrls = [
		{
			url: '/ri-business/projects/statistics',
			title: t('projectsStatistics')
		}
	]

	const infoPopup = {
		content: [{
			title: 'projects_stats_info_title',
			description: 'projects_stats_info_description',
			link: '/ri-manual/projects'
		}],
		type: infoPopupTypes.INFO
	}

	const renderTab = () => {
		switch(activeProjectsStatsSection) {
		case projectsStatsSection.Efficiency:
			return <Efficiency/>
		case projectsStatsSection.PerformanceAndEfficiency:
			return <PerformanceAndEfficiency />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Projects Statistics'
			}}
			tabButtons={ProjectsStatsButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			{renderTab()}
		</Layout>
	)
}

export default ProjectsStats