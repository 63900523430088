//styles and icons
import './warehouseList.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import Layout from '../../../components/general/layout/layout'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Pagination from '../../../components/general/pagination/pagination'
import ListSummaryCard from '../../../components/general/listSummaryCard/listSummaryCard'
import WarehouseListItem from '../../../components/warehouse/warehouseList/warehouseListItem/warehouseListItem'
import CreateWarehouseModal from '../../../components/warehouse/warehouseList/modals/createWarehouseModal/createWarehouseModal'
import DeleteWarehouseModal from '../../../components/warehouse/warehouseList/modals/deleteWarehouseModal/deleteWarehouseModal'
import ImportWarehouseFileSelectorModal from '../../../components/warehouse/warehouseList/modals/importWarehouseFileSelectorModal/importWarehouseFileSelectorModal'
import WarehouseTablePreviewModal from '../../../components/warehouse/warehouseList/modals/warehouseTablePreviewModal/warehouseTablePreviewModal'
import ExportWarehouseFileModal from '../../../components/warehouse/warehouseList/modals/exportWarehouseFileModal/exportWarehouseFileModal'
import OverviewCardSkeleton from '../../../components/general/skeletons/overviewCard/overviewCardSkeleton'
import ListCardSkeleton from '../../../components/general/skeletons/listCard/listCardSkeleton'

// types
import {
	currency,
	filterBar,
	filters,
	infoPopupTypes,
	moneyValue,
	warehouseResponse
} from '../../../types/general/generalTypes'
import { warehouse } from '../../../types/warehouse/generalTypes'

//network
import { companyWarehousesUrl, getWarehouseFiltersUrl, getWarehouseOverviewUrl } from '../../../utils/urls/warehouses/warehouses/warehouses'
import { authorizedRequest } from '../../../utils/queries'

//redux
import { setCreateWarehouseModalIsOpen, setExportWarehouseFileModalIsOpen, setImportWarehouseFileSelectorModalIsOpen } from '../../../redux/warehouse/warehouseList/modals'
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'

//other
import { getItemsPerColumn, formatFilters, createFilteringRequest, currencyToFormattedString } from '../../../assets/general/generalFunctions'
import { formatedWarehouses } from '../../../assets/warehouse/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const WarehouseList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouseList' })
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const [warehouseOverview, setWarehouseOverview] = useState<{ title: string, text: string }[]>([])
	const [warehouses, setWarehouses] = useState<warehouse[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/warehouse/warehouse-list',
			title: t('warehouses')
		}
	]

	const infoPopup = {
		content: [{
			title: 'warehouse_list_info_title',
			description: 'warehouse_list_info_description',
			link: '/ri-manual/warehouses/warehouses'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(getWarehouseFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportWarehouseFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportWarehouseFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: 'Add',
					active: true,
					onClick: () => {
						dispatch(setCreateWarehouseModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(companyWarehousesUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

			if (result.length > 0) {
				return formatedWarehouses(result as warehouseResponse[])
			}
			return []
		} finally {
			setLoading(false)
		}
	}

	const loadOverview = async () => {
		try {
			setOverviewLoading(true)
			const { result } = await authorizedRequest(getWarehouseOverviewUrl(companyId), 'GET')

			setWarehouseOverview([
				{
					title: 'totalAmount',
					text: result.total_warehouses
				},
				...result.worth.map((amount: moneyValue) => {
					return {
						title: 'totalWorth',
						text: currencyToFormattedString(amount.amount, amount.currency)
					}
				})
			])
		} finally {
			setOverviewLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setWarehouses([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setWarehouses([...warehouses, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Warehouse List'
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="warehouse-list">
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn} >
						{
							isOverviewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
								title={t('warehouseOverview')}
								data={warehouseOverview.map(overview => {
									return {
										...overview,
										title: t(overview.title)
									}
								})}
							/>
						}
						{
							warehouses.map((item) => {
								return (
									<WarehouseListItem key={`warehous-item-${item.id}`} {...item} />
								)
							})
						}
						{loading ? <ListCardSkeleton /> : null}
					</ColumnLayout>
				</Pagination>
			</div>
			<ImportWarehouseFileSelectorModal />
			<WarehouseTablePreviewModal warehouses={warehouses} setWarehouses={setWarehouses} />
			<ExportWarehouseFileModal />
			<CreateWarehouseModal warehouses={warehouses} setWarehouses={setWarehouses} />
			<DeleteWarehouseModal warehouses={warehouses} setWarehouses={setWarehouses} />
		</Layout>
	)

}

export default WarehouseList