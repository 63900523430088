// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setAccountTablePreviewModalIsOpen, setImportAccountFileSelectorModalIsOpen, setImportAccountFileSelectorModalFile } from '../../../../../redux/general/modals'

//types
import { currency } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'

//translation
import { useTranslation } from 'react-i18next'

type accountTablePreviewModalProps = {
	createAccountUrl: string
}

const AccountTablePreviewModal: FC<accountTablePreviewModalProps> = ({ createAccountUrl }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.accountList.modals.accountTablePreviewModal' })

	const { file } = useAppSelector((state) => state.accontListModal.importAccountFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.accontListModal.accountTablePreviewModal)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setAccountTablePreviewModalIsOpen(false))
		dispatch(setImportAccountFileSelectorModalIsOpen(false))
		dispatch(setImportAccountFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => {
				authorizedRequest(createAccountUrl, 'POST', 'accessToken', {
					data: tableData.data.map((item) => {

						return {
							name: item.name,
							bank: item.bank,
							balance: parseInt(item.balance),
							account_number: item.account_number,
							currency: item.currency,
							description: item.description
						}
					})
				}).then((response) => {
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('accountName'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('bank'),
					key: 'bank',
					default: '',
					type: String
				},
				{
					title: t('balance'),
					key: 'balance',
					default: '0',
					type: Number
				},
				{
					title: t('currency'),
					key: 'currency',
					default: currency.UAH,
					type: currency
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('accountNumber'),
					key: 'account_number',
					default: '',
					type: String
				}
			]}
		/>
	)
}

export default AccountTablePreviewModal