import { baseUrl } from '../../network'  

export const chatMessageUrl = (chatId: number) =>  baseUrl + `api/chats/${chatId}/messages`
export const chatInfoById = (chatId: number) => baseUrl + `api/chats/get/chat/${chatId}`
export const sendChatMessageUrl = (chatId: string, chatMessage: string) => baseUrl + chatId + chatMessage // we do not have an endpoint for sending messages
export const getChatListUrl = baseUrl + 'api/chats'
export const companyInboxesUrl = (companyId: number) => baseUrl + `api/company/${companyId}/inboxes`

export const chatStatusUrl = (chatId: number) => baseUrl + `api/chats/${chatId}/status`
export const forwardMessageUrl = (messageId: number) => baseUrl + `api/chats/messages/${messageId}/forward`
export const createGroupChatUrl = baseUrl + 'api/chats'
export const getMessageFilesUrl = (messageId: number) => baseUrl + `api/chats/messages/${messageId}/files`
export const getChatFilesUrl = (chatId: number) => baseUrl + `api/chats/${chatId}/files`
export const editMessageUrl = (chatId:number) => baseUrl + `api/chats/${chatId}/messages`
export const getLiveChatUrl = (chatId:number) => baseUrl + `api/chats/${chatId}/live`

export const getChatMessagesUrl = (chatId: number, page: number, perPage: number, query?: string, onlyPinned?: boolean) => baseUrl + `api/chats/${chatId}/messages?page=${page}&per_page=${perPage}${query ? `&query=${query}` : ''}${onlyPinned ? '&pin_only=true' : ''}`
export const deleteChatMessageUrl = (chatId: number) => baseUrl + `api/chats/${chatId}/messages`
export const setPinnedMessageUrl = (messageId: number) => baseUrl + `api/chats/message/${messageId}/pin`
export const getChatInfoUrl = (chatId: number, page?: number, per_page?: number, query?:string) => baseUrl + `api/chats/${chatId}${page || per_page || query ? '?' : ''}${query ? `query=${query}&` : ''}${page ? `page=${page}&` : ''}${per_page ? `per_page=${per_page}` : ''}`
export const createPrivatChatUrl = baseUrl + 'api/chats/private'