//react
import { FC, RefObject, useEffect, useRef, useState } from 'react'

//components
import Avatar from '../../avatar/avatar'

//hooks
import { useHover } from '../../../../customHooks/useHover'

//types
import { dropdownOption } from '../../../../types/general/generalTypes'

type DropdownOptionProps = {
	option: dropdownOption
	handleOptionClick: (option: dropdownOption) => void
	parentContainer: RefObject<HTMLDivElement> | null
}

const DropdownOption: FC<DropdownOptionProps> = ({ option, handleOptionClick, parentContainer }) => {
	const [hovered, eventHandlers] = useHover()
	const optionRef = useRef<HTMLDivElement>(null)
	const [isOverflowing, setOverflowing] = useState<boolean>(false)

	useEffect(() => {
		if (!parentContainer?.current || !optionRef?.current) {
			return
		}
		const parentRect = parentContainer.current.clientWidth
		const childRect = optionRef.current.scrollWidth

		if (childRect > parentRect) {
			setOverflowing(true)
		} else {
			setOverflowing(false)
		}
	}, [parentContainer, optionRef])

	return (
		option.key ?
			<div
				ref={optionRef}
				style={!isOverflowing ? { width: '100%' } : {
					transform: hovered && optionRef.current && parentContainer?.current ?
						`translateX(-${((optionRef.current.scrollWidth - parentContainer.current.clientWidth))}px)` :
						'translateX(0)',
					transition: `all ${(optionRef.current && hovered ? optionRef.current.clientWidth : 150) / 100}s ease-out`
				}}
				{...eventHandlers as object}
				className={`dropdown-option${isOverflowing ? ' overflowing' : ''}`}
				onClick={(e) => {
					e.stopPropagation()
					handleOptionClick(option)
				}}
			>
				{option.avatar !== undefined && <Avatar blobAvatar={option.avatar} />}
				<p className="dropdown-option-text">
					{option.title}
				</p>
			</div>
			: <div
				ref={optionRef}
				style={!isOverflowing ? { width: '100%' } : {
					transform: hovered && optionRef.current && parentContainer?.current ?
						`translateX(-${((optionRef.current.scrollWidth - parentContainer.current.clientWidth))}px)` :
						'translateX(0)',
					transition: `all ${(optionRef.current && hovered ? optionRef.current.clientWidth : 150) / 100}s ease-out`,
				}}
				{...eventHandlers as object}
				className={`dropdown-option ${isOverflowing ? ' overflowing' : ''}`}
			>
				<p className="dropdown-option-text">
					{option.title}
				</p>
			</div>
	)
}

export default DropdownOption
