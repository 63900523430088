// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportShipmentFileSelectorModalFile, setImportShipmentFileSelectorModalIsOpen, setShipmentTablePreviewModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

// other 
import { formatDate } from '../../../../../../assets/general/generalFunctions'
import { status } from '../../../../../../types/general/generalTypes'

const ShipmentTablePreviewModal: FC = () => {

	const { file } = useAppSelector((state) => state.warehouseGeneralModal.importShipmentFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.shipmentTablePreviewModal)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportShipmentFileSelectorModalIsOpen(false))
		dispatch(setShipmentTablePreviewModalIsOpen(false))
		dispatch(setImportShipmentFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => { console.log(tableData) }}
			requiredColumns={[
				{
					title: 'Date(day/month/year)',
					key: 'start_date',
					default: formatDate(new Date(), false, true),
					type: Date
				},
				{
					title: 'Due Date(day/month/year)',
					key: 'end_date',
					default: formatDate(new Date(), false, true),
					type: Date

				},
				{
					title: 'Status',
					key: 'status',
					default: status.open,
					type: status
				}
			]}
		/>
	)
}

export default ShipmentTablePreviewModal