// styles and icons
import './customerList.scss'
import { chat, deleteIcon } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import DeleteCustomerModal from '../../../components/sales/customerList/modals/deleteCustomerModal/deleteCustomerModal'
import CreateCustomerModal from '../../../components/sales/customerList/modals/createCustomerModal/createCustomerModal'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Pagination from '../../../components/general/pagination/pagination'
import CounterpartyCard from '../../../components/general/counterpartyCard/counterpartyCard'
import ImportCustomerFileSelectorModal from '../../../components/sales/customerList/modals/importCustomerFileSelectorModal/importCustomerFileSelectorModal'
import CustomerTablePreviewModal from '../../../components/sales/customerList/modals/customerTablePreviewModal/customerTablePreviewModal'
import ExportCustomerFileModal from '../../../components/sales/customerList/modals/exportCustomerFileModal/exportCustomerFileModal'
import CounterpartySkeleton from '../../../components/general/skeletons/counterParty/counterPartySkeleton'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateCustomerModalIsOpen, setDeleteCustomerRelationshipId, setDeleteCustomerModalIsOpen, setExportCustomerFileModalIsOpen, setImportCustomerFileSelectorModalIsOpen } from '../../../redux/sales/customerList/modals'

// network
import { authorizedRequest } from '../../../utils/queries'
import { companyCustomersFiltersUrl, companyCustomersUrl } from '../../../utils/urls/sales/customer'

// types
import { filterBar, filters, customerResponse, infoPopupTypes } from '../../../types/general/generalTypes'
import { customer } from '../../../types/sales/customerTypes'

// translation
import { useTranslation } from 'react-i18next'

// other
import { createFilteringRequest, formatFilters, formatTags, getItemsPerColumn } from '../../../assets/general/generalFunctions'

//hooks
import { useNavigate } from 'react-router-dom'

const CustomerList: FC = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })
	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [customers, setCustomers] = useState<customer[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const { t } = useTranslation('', { keyPrefix: 'sales.customerList' })
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const titleUrls = [
		{
			url: '/ri-business/sales/customer-list',
			title: t('customers')
		}
	]

	const infoPopup = {
		content: [{
			title: 'customer_list_info_title',
			description: 'customer_list_info_description',
			link: '/ri-manual/sales/customers'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyCustomersFiltersUrl(companyId), 'GET')
			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportCustomerFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportCustomerFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreateCustomerModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}


	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyCustomersUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedCustomers: customer[] = result.map((customer: customerResponse) => {
					console.log(customer)
					return {
						id: customer.relationship_id,
						chatId: customer.chat_id,
						description: customer.description,
						name: customer.name,
						avatar: customer.avatar,
						labels: customer.labels,
						deleteAccess: customer.delete_access
					}
				})
				return formatedCustomers
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setCustomers([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setCustomers([...customers, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='customers' >

				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={false}>

					<ColumnLayout amount={itemsPerColumn}>
						{customers.map(customer =>
							(<CounterpartyCard
								key={`customer-${customer.id}`}
								link={`/ri-business/sales/customer/${customer.id}`}
								tags={customer.labels.map(e => ({ ...e, value: formatTags(e.value, tCurrency) }))}
								actions={customer.deleteAccess ? [
									{
										id: 2, icon: deleteIcon, onClick: () => {
											dispatch(setDeleteCustomerRelationshipId(customer.id))
											dispatch(setDeleteCustomerModalIsOpen(true))
										}
									}] : []}
								{...customer}
							/>)
						)}
						{loading ? <CounterpartySkeleton /> : null}

					</ColumnLayout>
				</Pagination>
			</div>
			<CreateCustomerModal customers={customers} setCustomers={setCustomers} />
			<DeleteCustomerModal customers={customers} setCustomers={setCustomers} />
			<ImportCustomerFileSelectorModal />
			<CustomerTablePreviewModal customers={customers} setCustomers={setCustomers} />
			<ExportCustomerFileModal />
		</Layout>
	)
}

export default CustomerList