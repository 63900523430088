// react
import{ FC, ReactNode } from 'react'

// components
import DropAreaIndicator from '../dropAreaIndicator/dropAreaIndicator'

// types
import { dragItem } from '../../../../types/general/generalTypes'

// context
import useDragAndDrop from '../context/dragAndDropHandlerContext'

type draggableProps = {
	index: number
	stageId: number
	stage: string
	dropAreaSize?: string 
	dragData: dragItem
	onDragStart?: (dragData:dragItem) => void
	className?: string
	children: ReactNode
}

const Draggable :FC<draggableProps>= ({index, stageId, stage, dropAreaSize, onDragStart ,dragData, className, children}) => {

	const { setIsDragging,setDragInfo } = useDragAndDrop()

	return (
		<>
			<div 
				id={`draggable-${stage.replaceAll(' ','-')}-${index}`}
				className={(className ?? '') + ` drag-item-${stage.replaceAll(' ','-')}`}
				onDragStart={()=>{
					onDragStart && onDragStart(dragData)
					setDragInfo(dragData, {stageId, index, stage})
					setIsDragging(true)
				}}
				onDragEnd={()=>{
					setIsDragging(false)
				}}
				draggable
			>
				{children}
			</div>
			<DropAreaIndicator stage={stage} index={index} dropAreaSize={dropAreaSize}/>
		</>
	)
}

export default Draggable