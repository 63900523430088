// react
import { FC, ReactNode, createContext, useContext, useReducer } from 'react'

// types
import { dragItem, dragLocation } from '../../../../types/general/generalTypes'
import { dragAndDropReducer, initialState } from './dragAndDropHandlerReducer'
import { dragActionType, dragState } from './type'

// context
type dragAndDropHandlerContextType = {
	setDragInfo: (dragData:dragItem | null, dragInfo:dragLocation | null) => void
	setDropInfo: (dropInfo:dragLocation | null) => void
	setIsDragging: (isDragging:boolean) => void
} & dragState

const initialContextData = {
	setDragInfo: () => null,
	setDropInfo: () => null,
	setIsDragging: () => null,
	dropInfo: null,
	dragInfo: null,
	dragData: null,
	isDragging: false
}

export const DragContext = createContext<dragAndDropHandlerContextType>(initialContextData)

type dragAndDropHandlerProviderProps = {
	children?: ReactNode
}

/*
* Container to be used at the top level when using any of the component for dragging functionality
* It provides a context to consume so that any descendants of this component will be able to access currentlyDragging data and make necessary changes
*/
export const DragAndDropHandlerProvider : FC<dragAndDropHandlerProviderProps> = ({ children }) => {

	
	const [state,dispatch] = useReducer(dragAndDropReducer,initialState) 

	const setDragInfo = (dragData:dragItem | null, dragInfo:dragLocation | null)=>{
		dispatch({
			type:dragActionType.setDragInfo,
			payload:{dragData:dragData,dragInfo:dragInfo}
		})
	}
	
	const setDropInfo = (dropInfo: dragLocation | null)=>{
		dispatch({
			type:dragActionType.setDropInfo,
			payload:{dropInfo:dropInfo}
		})
	}
	
	const setIsDragging = (isDragging:boolean) =>{
		dispatch({
			type:dragActionType.setIsDragging,
			payload:isDragging
		})
	}

	
	return (
		<DragContext.Provider value={{...state,setDragInfo,setDropInfo,setIsDragging}}>
			{children}
		</DragContext.Provider>
	)
}


const useDragAndDrop = () => {
	
	const dragAndDropContext = useContext(DragContext)

	if(dragAndDropContext === undefined){
		throw new Error('Drag and drop context must be used within a dragAndDropProvider')
	}

	return dragAndDropContext
}

export default useDragAndDrop