import { baseUrl } from '../../network'

export const pipelinesGetFiltersResourceUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/pipelines/filters`
export const pipelinesManagePipelineResourceUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/pipelines`
export const pipelinesModifyPipelineResourceUrl = (pipelineId: number)=> baseUrl + `/api/pipelines/${pipelineId}`
export const getPipelinesOverviewResourceUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/pipelines/overview`
export const pipelinesGetProjectsResourceUrl = (pipelineId: number)=> baseUrl + `/api/pipelines/${pipelineId}/projects`
export const projectsManagePipelineStageUrl = (pipelineId: number)=> baseUrl + `/api/pipelines/${pipelineId}/stages`
export const projectsModifyPipelineStageUrl = (stageId: number)=> baseUrl + `/api/pipelines/stages/${stageId}`
export const singelPipelineTasks = (pipelineId:number) => baseUrl + `/api/pipelines/${pipelineId}/tasks`
export const singelPipelineTaskCalendar = (pipelineId:number) => baseUrl + `/api/pipelines/${pipelineId}/tasks/calendar`
export const pipelineSkeleton = (pipelineId:number) => baseUrl + `/api/pipelines/${pipelineId}/skeleton`
export const pipelineTasksSkeleton = (pipelineId:number) => baseUrl + `/api/pipelines/${pipelineId}/tasks/skeleton`
