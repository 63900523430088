// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileTableParserModal from '../../../../../general/modals/fileTableParserModal/fileTableParserModal'

//types
import { currency, offering } from '../../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportProviderOfferingFileSelectorModalFile, setImportProviderOfferingFileSelectorModalIsOpen, setProviderOfferingTablePreviewModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderOfferingsUrl } from '../../../../../../utils/urls/purchases/provider'

//translation
import { useTranslation } from 'react-i18next'
import { formatOfferingResponse } from '../../../../../../assets/general/generalFunctions'

type providerOfferingTablePreviewModalProps = {
	offerings: offering[]
	setOfferings: (value: offering[]) => void
}

const ProviderOfferingTablePreviewModal : FC<providerOfferingTablePreviewModalProps> = ({ offerings, setOfferings }) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerOfferingList.providerOfferingTablePreviewModal' })

	const { relationshipId } = useParams()

	const { file } = useAppSelector((state) => state.providerModal.providerOfferings.importProviderOfferingFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerOfferings.tableProviderOfferingPreviewModal)
	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setImportProviderOfferingFileSelectorModalIsOpen(false))
		dispatch(setProviderOfferingTablePreviewModalIsOpen(false))
		dispatch(setImportProviderOfferingFileSelectorModalFile(null))
	}

	

	return (
		<FileTableParserModal
			file={file}	
			open={modalIsOpen}
			closeModal={closeModal}	
			onTableProcessed={(tableData) => {
				authorizedRequest(singleProviderOfferingsUrl(Number(relationshipId)), 'POST', 'accessToken', {
					data: tableData.data.map(( item ) => {
						return {
							name: item.name,
							price: item.price,
							vat: item.vat,
							currency: item.currency,
							description: item.description,
							create_inventory_item: Boolean(item.create_inventory_item === 'true'),
							create_offering: Boolean(item.create_sales_offering === 'true'),
							offering_price: item.sales_offering_price,
							offering_vat: item.sales_offering_vat,
							offering_currency: item.sales_offering_currency
						}
					})
				}).then(({result})=> {
					if(result.length > 0) {
						const formatedOfferrings: offering[] = result.map(formatOfferingResponse)
						setOfferings([...formatedOfferrings, ...offerings])
					}
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('priceWithVat'),
					key: 'price',
					default: '0',
					type: Number
				},
				{
					title: t('vat'),
					key: 'vat',
					default: '0',
					type: Number
				},
				{
					title: t('currency'),
					key: 'currency',
					default: 'UAH',
					type: currency
				},
				{
					title: t('createInventoryItem'),
					key: 'create_inventory_item',
					default: '',
					type: Boolean
				},
				{
					title: t('createSalesOffering'),
					key: 'create_sales_offering',
					default: '',
					type: Boolean
				},
				{
					title: t('salesOfferingPriceWithVat'),
					key: 'sales_offering_price',
					default: '0',
					type: Number
				},
				{
					title: t('vat'),
					key: 'sales_offering_vat',
					default: '0',
					type: Number
				},
				{
					title: t('salesOfferingCurrency'),
					key: 'sales_offering_currency',
					default: 'UAH',
					type: currency
				}
			]}
		/>
	)
}

export default ProviderOfferingTablePreviewModal