//styles
import './orderCard.scss'
import { activeStatus, closedStatus, navlogo, productOffer } from '../../../assets/general/generalIcons'

//react
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

//compoenents
import Avatar from '../avatar/avatar'
import Table from '../table/table'
import InfoPopup from '../infoPopup/infoPopup'

//types
import { order, currency, offering, infoPopupTypes } from '../../../types/general/generalTypes'

//other
import { formatDate, currencyToFormattedString, translateDate } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type orderCardProps = order & {
	isProvider?: boolean
	link?: string
}

const OrderCard: FC<orderCardProps> = ({ id, counterparty, startDate, endDate, isClosed, isProvider, items, errors, link }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.orderCard' })

	const navigate = useNavigate()

	const columns = [
		{ key: 'products', title: t('products') },
		{ key: 'quantity', title: t('quantity') },
		{ key: 'price', title: t('price') }
	]

	const getPrice = (item: offering) => {
		return currencyToFormattedString(item.price.amount, item.price.currency)
	}

	const getTotal = (items: offering[]) => {
		const total = [
			{ amount: 0, currency: currency.USD },
			{ amount: 0, currency: currency.EUR },
			{ amount: 0, currency: currency.UAH }
		]

		items.forEach((item) => {
			if (item.price.currency == currency.USD) total[0].amount += item.price.amount * item.quantity
			if (item.price.currency == currency.EUR) total[1].amount += item.price.amount * item.quantity
			if (item.price.currency == currency.UAH) total[2].amount += item.price.amount * item.quantity
		})
		return total

	}

	return (
		<div className="order-item-container" onClick={() => {
			navigate(link ? link : `/ri-business/order/${id}`)
		}}>
			<div className="order-item-header">
				<div className="order-item-header-info">
					<div className="order-item-header-avatar">
						<Avatar blobAvatar={counterparty.avatar} placeholder={isProvider ? navlogo : undefined} />
					</div>
					<p className="order-item-header-name-text">{counterparty.name}</p>
				</div>

				<div className="order-item-header-status">
					{
						isClosed ? closedStatus : activeStatus
					}
					{errors.length > 0 ?
						<InfoPopup
							content={errors.map((error) => {
								return {
									title: error.title,
									description: error.description,
									link: error.errorRootLink
								}
							})}
							type={infoPopupTypes.ERROR}
						/>
						: null}
				</div>
			</div>
			<div className="order-item-table-container">
				<Table
					columns={
						columns
					}
					data={
						items.map((item) => {
							return {
								products: (
									<div className='order-item-product-container'>
										<Avatar blobAvatar={item.avatar} placeholder={productOffer} />
										<p className="order-item-product-name">{item.name}</p>
									</div>
								),
								quantity: `x${item.quantity}`,
								price: getPrice(item)
							}
						})
					}
					showHeader={true}
				/>
			</div>
			<div className="order-item-footer">
				<div className="order-item-footer-date-container">
					{startDate ? <p className='order-item-footer-date'>{formatDate(startDate, true, true)}</p> : null}
					{endDate ? <p className={`order-item-footer-date ${translateDate(endDate) <= new Date() ? t('late') : ''}`}>{formatDate(endDate, true, true)}</p> : null}
				</div>
				<div className="order-item-footer-worth">
					<p className="order-item-footer-title">{t('total')}</p>
					<div className="order-item-footer-text-container">
						{getTotal(items).map((item) => <p className="order-item-footer-text">{currencyToFormattedString(item.amount, item.currency)}</p>)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrderCard