// styles
import './companyDataSettings.scss'

//components
import AvatarPicker from '../../../avatarPicker/avatarPicker'
import InputField from '../../../inputField/inputField'
import Dropdown from '../../../dropdown/dropdown'
import PhoneNumberList from './phoneNumberList/phoneNumberList'
import EmailList from './emailList/emailList'
import LabelList from './labelList/labelList'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { getCompanySettingsUrl } from '../../../../../utils/old_urls/general/settingsUrls'
import { getEmailUrl, getPhoneNumbersUrl, getLabelsUrl } from '../../../../../utils/old_urls/general/generalUrls'
import { manageCompanySettingsUrl } from '../../../../../utils/urls/general/company/company'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCompanies, setUserCompanyData } from '../../../../../redux/general/general'

//react
import { useEffect, useState } from 'react'

//types
import {
	company,
	email,
	label,
	labelResponse,
	phoneNumber,
	selectionOption,
	taxationType,
	dropdownOption
} from '../../../../../types/general/generalTypes'


//translation
import { useTranslation } from 'react-i18next'

//other
import {
	blobStringToBase64,
	createDropdownOption,
	formatPhoneNumberResponse
} from '../../../../../assets/general/generalFunctions'

const CompanyDataSettings = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.settings.companySettingsSection.companyDataSettings' })

	const { userCompanyData, companies } = useAppSelector((state) => state.general)
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)

	const companyId: number = userCompanyData?.companyId || -1
	const activeCompany = settingsActiveCompanyId || -1

	const [ name, setName ] = useState('')
	const [ emails, setEmails ] = useState<email[]>([])
	const [ labels, setLabels ] = useState<label[]>([])
	const [ phoneNumbers, setPhoneNumbers ] = useState<phoneNumber[]>([])
	const [selectedTaxationTypeOption, setSelectedTaxationTypeOption] = useState<dropdownOption | null>(null)
	const [ editSettings, setEditSettings ] = useState<boolean>(false)
	const [ companyAvatar, setCompanyAvatar] = useState(localStorage.getItem('companyAvatar') || null)
	
	const dispatch = useAppDispatch()

	const loadSettings = async () => {
		const response = await authorizedRequest(getCompanySettingsUrl(activeCompany), 'GET')

		const { result } = response

		setName(result.name)

		setSelectedTaxationTypeOption(result.taxationType)
		setCompanyAvatar(result.avatar)

		return result
	}

	const loadEmails = async () => {
		const response = await authorizedRequest(getEmailUrl(activeCompany), 'GET')

		const { result } = response
		setEmails(result)

		return result

	}
	const loadPhoneNumbers = async () => {
		const response = await authorizedRequest(getPhoneNumbersUrl(activeCompany), 'GET')

		const { result } = response
		setPhoneNumbers(result.map(formatPhoneNumberResponse))

		return result

	}

	const getLabels = async () => {
		const { result }: {result: labelResponse[]} = await authorizedRequest(getLabelsUrl(activeCompany), 'GET')

		const labelsOptionList: label[] = result.map(label => {
			return {
				id: label.id,
				text: label.text,
				color: label.color
			}
		})
		setLabels(labelsOptionList)
		return labelsOptionList
	}

	useEffect(() => {
		loadSettings()
		loadEmails()
		loadPhoneNumbers()
		getLabels()
	},[activeCompany])

	const editData = async () => {

		const { result } = await authorizedRequest(getCompanySettingsUrl(activeCompany), 'PUT', 'accessToken', {
			name: name,
			emails: emails,
			phone_numbers: phoneNumbers,
			taxation_type: selectedTaxationTypeOption,
		})
		
		const pars: company = {
			avatar: result.avatar,
			companyId: result.company_id,
			employeeId: result.employee_id,
			name: result.name,
			description: result.description
		}
		
		dispatch(setCompanies(companies.map(el => el.companyId === pars.companyId ? pars : el)))
		
		if (companyId === settingsActiveCompanyId) {
			dispatch(setUserCompanyData({...userCompanyData!, companyName: pars.name}))
		}
	}

	const submitCompanyAvatar = (avatar: string | null) => {
		if (avatar) {
			blobStringToBase64(avatar,'image/png')
				.then((updateAvatar) => {
					authorizedRequest(manageCompanySettingsUrl(activeCompany), 'PUT', 'accessToken', {
						avatar: updateAvatar,
						phone_numbers: phoneNumbers,
						emails: emails
					})
						.then(({ result }) => {
							setCompanyAvatar(result.avatar)
							if (companyId === settingsActiveCompanyId) {
								dispatch(setUserCompanyData({...userCompanyData!, companyAvatar: result.avatar }))
							}
						})
				})
		}
	}

	useEffect(() => {
		let newTimer: NodeJS.Timeout

		if (editSettings) {
			newTimer = setTimeout(() => {
				editData()
				setEditSettings(false)
			}, 500)
		}

		return () => clearTimeout(newTimer)
	}, [name])

	return (
		<div className="company-data-settings">
			<AvatarPicker
				currenctAvatar={companyAvatar}
				submitAvatar={submitCompanyAvatar}
			/>
			<InputField
				label={t('name')}
				value={name}
				onChange={(e) => {
					setName(e.target.value)
					setEditSettings(true)
				}}
			/>

			<PhoneNumberList phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
			<EmailList emails={emails} setEmails={setEmails} />

			<LabelList labels={labels} setLabels={setLabels} />
		</div>
	)
}

export default CompanyDataSettings