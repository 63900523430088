
// react
import { FC, useState } from 'react'

// components
import Report from '../../../components/general/report/report'
import Layout from '../../../components/general/layout/layout'

// translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../customHooks/redux'

//network
import {
	companyBillsExpensesReportUrl, companyBillsIncomesReportUrl,
	companyEmployeeExpensesReportUrl
} from '../../../utils/urls/finance/financialReports/financialReports'

// types
import { button, filterBar, infoPopupTypes } from '../../../types/general/generalTypes'

enum financialReportsSection {
	employeeExpenses = 'employeeExpenses',
	expenseReport = 'expensesReport',
	incomeReport = 'incomesReport',
}

const FinancialReports: FC = () => {
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeSection, setActiveSection] = useState<string>('employeeExpenses')
	const {t} = useTranslation('', {keyPrefix: 'finance.financialReports'})

	const {userCompanyData} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1

	const titleUrls = [
		{
			url: '/ri-business/finance/financial-reports',
			title: t('financialReports')
		}
	]

	const infoPopup = {
		content: [{
			title: 'budget_info_title',
			description: 'budget_info_description',
			link: '/ri-manual/finance'
		}],
		type: infoPopupTypes.INFO
	}

	const accountTabButtons: button[] = [
		{
			active: activeSection === financialReportsSection.employeeExpenses,
			text: t('employeeExpenses'),
			onClick: () => setActiveSection(financialReportsSection.employeeExpenses)
		},
		{
			active: activeSection === financialReportsSection.expenseReport,
			text: t('billsExpenseReport'),
			onClick: () => setActiveSection(financialReportsSection.expenseReport)
		},
		{
			active: activeSection === financialReportsSection.incomeReport,
			text: t('billsIncomeReport'),
			onClick: () => setActiveSection(financialReportsSection.incomeReport)
		},
	]

	const renderTab = () => {
		switch (activeSection) {
		case financialReportsSection.employeeExpenses: 
			return <Report key={'employeeReport'} reportUrl={companyEmployeeExpensesReportUrl(companyId)} setFilterBar={setFilterBar} total />
		case financialReportsSection.expenseReport:
			return <Report key={'billExpenseReport'} reportUrl={companyBillsExpensesReportUrl(companyId)} setFilterBar={setFilterBar} total />
		case financialReportsSection.incomeReport:
			return <Report key={'billIncomeReport'} reportUrl={companyBillsIncomesReportUrl(companyId)} setFilterBar={setFilterBar} total />
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true		
			}}
			tabButtons={accountTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className="account-container">
				{
					renderTab()
				}
			</div>

		</Layout>
	)
}

export default FinancialReports