//styles and icons
import './login.scss'

//react
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

//components
import CheckBox from '../../../components/general/checkBox/checkBox'
import InputField from '../../../components/general/inputField/inputField'
import Button from '../../../components/general/button/button'
import AuthenticationHeader from '../../../components/authentication/general/authenticationHeader/authenticationHeader'
import Loading from '../../../components/general/loading/loading'
import LanguageSelector from '../../../components/general/languageSelector/languageSelector'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import { setUserCompanyData } from '../../../redux/general/general'

//network
import { whoAmIUrl } from '../../../utils/old_urls/generalUrls'
import { loginUrl } from '../../../utils/old_urls/loginUrls'
import { unauthorizedRequest, authorizedRequest } from '../../../utils/queries'
import { hierarchyHireEmployeeInvitationConfirmUrl } from '../../../utils/urls/employees/hierarchy'

//translation
import { useTranslation } from 'react-i18next'

// other
import { blobStringToBase64 } from '../../../assets/general/generalFunctions'

type formErrors = {
	email?: string
	password?: string
}

type whoAmIResponse = {
	avatar: string | null
	user_id: number
	companies: {
		company_id: number
		employee_id: number
		avatar: string | null
		name: string
	}[]
}

const Login = () => {
	const { t } = useTranslation('', { keyPrefix: 'authentication.login' })

	const dispatch = useAppDispatch()

	const [loading, setLoading] = useState(false)

	const [errors, setErrors] = useState<formErrors>({})
	const [isVisible, setIsVisible] = useState(false)
	const [isChecked, setIsChecked] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const navigate = useNavigate()

	const { token } = useParams()

	const getUserDataFromToken = async () => {
		const { result } = await unauthorizedRequest(hierarchyHireEmployeeInvitationConfirmUrl + `?token=${token}`, 'GET')
		setEmail(result)
	}

	useEffect(() => {
		if (token) {
			getUserDataFromToken()
		}
	}, [token])

	const checkErrors = () => {
		let result = true

		if (email === '') {
			setErrors(prevErrors => ({ ...prevErrors, email: t('pleaseEnterYourEmail') }))
			result = false
		}
		if (password === '') {
			setErrors(prevErrors => ({ ...prevErrors, password: t('pleaseEnterYourPassword') }))
			result = false
		}

		return result
	}

	const homePage = async () => {
		try {
			const response = await authorizedRequest(whoAmIUrl, 'GET')
			const { result }: { result: whoAmIResponse } = response

			console.log(result)

			if (result.avatar) {
				const avatar = await blobStringToBase64(result.avatar, 'image/png')
				console.log(avatar)
				localStorage.setItem('avatar', `${avatar}`)
			} else {
				localStorage.setItem('avatar', '')
			}

			localStorage.setItem('userId', `${result.user_id}`)

			if (result.companies.length > 0) {
				dispatch(
					setUserCompanyData({
						companyId: result.companies[0].company_id,
						employeeId: result.companies[0].employee_id,
						companyAvatar: result.companies[0].avatar,
						companyName: result.companies[0].name,
						companyDescription: '',
					})
				)
			} else {
				dispatch(setUserCompanyData(undefined))
			}

			navigate('/ri-business')
		} finally {
			setLoading(false)
		}
	}


	// login method
	const onSubmit = async () => {

		checkErrors()

		setLoading(true)
		try {
			const responce = await unauthorizedRequest(loginUrl, 'POST', { email, password })
			if (responce.ok) {

				new Promise<void>((resolveOuter) => {
					localStorage.setItem('accessToken', responce.result.access_token)
					localStorage.setItem('refreshToken', responce.result.refresh_token)
					resolveOuter()
				})
				if (token) {
					authorizedRequest(hierarchyHireEmployeeInvitationConfirmUrl, 'POST', 'accessToken', { 'token': token }).then(async (acceptInvitationResponce) => {
						if (acceptInvitationResponce.ok) {
							await homePage()
						} else if (acceptInvitationResponce === 400) {
							setErrors({ email: '', password: t('noSuchInvitationToken') })
						} else if (acceptInvitationResponce === 401) {
							setErrors({ email: '', password: t('theTokenIsExpiredOrAlreadyUsed') })

						} else if (acceptInvitationResponce === 402) {
							setErrors({ email: '', password: t('thisIsNotForCurrentUser') })
						}
					})
				} else {
					await homePage()
				}
			} else if (responce === 400 || responce === 401) {
				setErrors({ email: t('wrongEmailOrPassword'), password: t('wrongEmailOrPassword') })
			}
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		setErrors({})
	}, [email, password])

	return (
		<div className="login">
			<div className="login-container">
				<AuthenticationHeader
					title={t('signIn')}
					subtitle={{
						text: t('dontHaveAnAccountYet'),
						link: {
							text: t('register'),
							url: token ? `/accept-invitation/register/${token}` : '/register'
						}
					}}
				/>
				<form onSubmit={(e) => {
					e.preventDefault()
					onSubmit()
				}} className="login-form">
					<div className="form-controls">
						<InputField
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							placeholder={t('email')}
							error={errors.email}
							disabled={token ? true : false}
						/>
						<InputField
							type={isVisible ? 'text' : 'password'}
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							placeholder={t('password')}
							error={errors.password}
							passwordVisibility={{
								onClick: () => {
									setIsVisible(!isVisible)
								},
								visible: isVisible
							}}
						/>
					</div>
					<div className="login-options">
						<CheckBox
							isChecked={isChecked}
							onClick={() => setIsChecked(!isChecked)}
							label={t('rememberMe')}
						/>
						<Link to="/reset-password-email" className="login-forget">{t('forgotPassword')}</Link>
					</div>
					<Button type='submit' active={true} text={t('signIn')} onClick={onSubmit} />
				</form>
				{loading ? <Loading /> : null}
			</div>
			<div className='login-container-langueage'>
				<LanguageSelector />
			</div>
		</div>
	)
}

export default Login
