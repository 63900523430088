//styles and icons
import './profile.scss'
import { basicUserAvatar } from '../../../assets/general/generalIcons'

//react
import { useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import AvatarPicker from '../../../components/general/avatarPicker/avatarPicker'
import InputField from '../../../components/general/inputField/inputField'
import CalendarContainer from '../../../components/general/calendarContainer/calendarContainer'
import Dropdown from '../../../components/general/dropdown/dropdown'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setBirthday, setGender } from '../../../redux/general/profile/general'

//network
import { authorizedRequest } from '../../../utils/queries'
import { updateAvatarUrl } from '../../../utils/old_urls/general/profileUrls'
import { profileEditResourceUrl } from '../../../utils/urls/general/profile/profile'

//translation
import { useTranslation } from 'react-i18next'

// other
import { blobStringToBase64, createDropdownOption } from '../../../assets/general/generalFunctions'

enum genders {
	male='male',
	female='female',
}

const Profile = () => {
	const { firstName, lastName, email, phoneNumber, gender, birthday } = useAppSelector((state) => state.profileGeneral)

	const [avatar, setAvatar] = useState(localStorage.getItem('avatar') || null)

	const { t } = useTranslation('', { keyPrefix: 'general.profile' })
	const dispatch = useAppDispatch()

	const handlerGender = (gender: string) => {
		authorizedRequest(profileEditResourceUrl, 'PUT', 'accessToken', {
			gender
		})
			.then(({ result }) => {
				dispatch(setGender(result.gender))
			})
	}

	const handlerBirthday = (birthday: Date | undefined) => {
		authorizedRequest(profileEditResourceUrl, 'PUT', 'accessToken', {
			birthday
		})
			.then(({ result }) => {
				dispatch(setBirthday(result.birthday))
			})
	}

	const submitAvatar = (avatar: string | null) => {
		authorizedRequest(updateAvatarUrl, 'PUT', 'accessToken', {
			avatar: avatar
		}).then(({ result }) => {
			blobStringToBase64(result.avatar!, 'image/png')
				.then((updatedAvatar) => {
					localStorage.setItem('avatar', `${updatedAvatar}`)
					setAvatar(updatedAvatar)
				})
		})
	}

	return (
		<Layout
			header={{
				avatar: false,
			}}
		>
			<div className="profile-wrapper">
				<div className="profile-container">
					<AvatarPicker currenctAvatar={avatar} submitAvatar={submitAvatar} defaultAvatar={basicUserAvatar}/>

					<div className="profile-user-data">
						<InputField type="text" value={`${firstName} ${lastName}`} label={t('name')} disabled={true} />
						<InputField type="text" value={email} label={t('email')} disabled={true} />
						<InputField type="text" value={phoneNumber} label={t('phoneNumber')} disabled={true} />
						<CalendarContainer
							label={t('birthday')}
							popup={true}
							disabled={!!birthday}
							title='birthDate'
							startDate={{
								date: birthday,
								setDate: (value) => {
									handlerBirthday(value)
								}
							}}
						/>
						<Dropdown
							label={t('gender')}
							disabled={!!gender}
							placeholder={gender ? t('chooseYourGender') : gender}
							dropdownOptions={createDropdownOption(t, genders)}
							onSelect={({ key }) => handlerGender(key)}
							
							selectedOption={{
								key: gender,
								title: t(gender),
							}}
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Profile
