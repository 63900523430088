//styles
import './calendarContainer.scss'

//react
import { FC, useEffect, useState } from 'react'

// translation
import { useTranslation } from 'react-i18next'

//types 
import { calendarContainer } from '../../../types/general/generalTypes'

//components
import Calendar from '../calendar/calendar'
import InputField from '../inputField/inputField'
import InputRadio from '../inputRadio/inputRadio'
import TimeInput from '../timeSelector/timeInput/timeInput'

//other
import { formatDate } from '../../../assets/general/generalFunctions'

type calendarContainerProps = calendarContainer

enum dateOptions {
	START = 'start',
	DUE = 'due'
}

const getDecimalHoursFromDate = (date: Date) => {
	const hours = date.getHours()
	const minutes = date.getMinutes()

	const decimalMinutes = minutes / 60
	const decimalHours = hours + decimalMinutes

	return decimalHours
}

const CalendarContainer: FC<calendarContainerProps> = ({ startDate, dueDate, size, disabled, popup, label, title, hasTime=false  }) => {

	const { t } = useTranslation('', {keyPrefix: 'general.calendarContainer'})
	const tTime = useTranslation('', {keyPrefix:'general.timeSelector'}).t
	
	const [activeDateOption, setActiveDateOption] = useState<dateOptions>(startDate ? dateOptions.START : dateOptions.DUE)

	const [startActiveDate, setStartActiveDate] = useState<Date | undefined>(startDate?.date)
	const [dueActiveDate, setDueActiveDate] = useState<Date | undefined>(dueDate?.date)

	const [activeDate, setActiveDate] = useState<Date | undefined>(activeDateOption === dateOptions.START ? startActiveDate : dueActiveDate)

	const [ hidden, setHidden ] = useState(popup)  

	const [startTime, setStartTime] = useState(startDate?.date ? getDecimalHoursFromDate(startDate.date) : 0)
	const [dueTime, setDueTime] = useState(dueDate?.date ? getDecimalHoursFromDate(dueDate.date) : 0)
	
	const addHoursToDate = (date: Date, hours: number) => {
		const d = new Date(date)
		d.setTime(d.getTime() + hours * 3_600_000)
		return d
	}

	useEffect(() => {
		if (activeDateOption === dateOptions.START && startActiveDate) {
			const startDateWithTime = addHoursToDate(new Date(startActiveDate.getFullYear(), startActiveDate.getMonth(), startActiveDate.getDate(), 0, 0, 0, 0), startTime)
			hasTime ? startDate?.setDate(startDateWithTime) : startDate?.setDate(startActiveDate)
		}
		
		if (activeDateOption === dateOptions.DUE && dueActiveDate) {
			const endDateWithTime = addHoursToDate(new Date(dueActiveDate.getFullYear(), dueActiveDate.getMonth(), dueActiveDate.getDate(), 0, 0, 0, 0), dueTime)
			hasTime ? dueDate?.setDate(endDateWithTime) : dueDate?.setDate(dueActiveDate)
		}
	}, [startActiveDate, dueActiveDate, startTime, dueTime])


	useEffect(() => {
		if (activeDateOption === dateOptions.START) {
			setActiveDate(startDate?.date)
		} else if (activeDateOption === dateOptions.DUE) {
			setActiveDate(dueDate?.date)
		}
	}, [activeDateOption, startDate?.date, dueDate?.date])

	useEffect(() => {
		const handleClick = () => {
			if(popup){
				setHidden(true)
			}
		}
		
		window.addEventListener('click', handleClick)

		return () => {
			window.removeEventListener('click', handleClick)
		}
	}, [])

	return (
		<div className="calendar-wrapper">
			{label ? <p className="calendar-label">{ label }</p> : null}
			<div className={`calendar-container ${size ? size : ''} ${hidden ? 'hidden' : ''} ${disabled ? 'disabled' : ''}`} onClick={(e) => { e.stopPropagation() }}>
				<div className="calendar-input-container">
					<div className="calendar-input-fields-container">
						{startDate ?
							<div className="calendar-input-field-wrapper">
								<div className="calendar-input-field-box" onClick={() => {
									setActiveDateOption(dateOptions.START)
									if(popup && !disabled) {
										setHidden(false)
									}
								}}>
								</div>
								<div className="calendar-input-field">
									<InputRadio checked={activeDateOption === dateOptions.START} />
									<InputField type='text' name='startDate' value={startDate.date ? formatDate(startDate.date, false, false) : title ? t(`${title}`) : t('startDate')} disabled={true} />
								</div>
							</div>
							: null}
						{dueDate ?
							<div className="calendar-input-field-wrapper">
								<div className="calendar-input-field-box" onClick={() => {
									setActiveDateOption(dateOptions.DUE)
									if(popup && !disabled) {
										setHidden(false)
									}
								}}></div>
								<div className="calendar-input-field">
									<InputRadio checked={activeDateOption === dateOptions.DUE} />
									<InputField type='text' name='endDate' value={dueDate.date ? formatDate(dueDate.date, false, false) : t('dueDate')} disabled={true} />
								</div>
							</div>
							: null}
					</div>
					{hasTime ? <div className="calendar-input-time-container">
						{ startDate ?
							<div onClick={() => {
								setActiveDateOption(dateOptions.START)
								if(popup && !disabled) {
									setHidden(false)
								}
							}}>
								<TimeInput setValue={ (value) => {setStartTime(value)}} value={startTime} placeholder={tTime('startTime')} />
							</div>
							: null }
						{ dueDate ? 
							<div onClick={() => {
								setActiveDateOption(dateOptions.DUE)
								if(popup && !disabled) {
									setHidden(false)
								}
							}}>
								<TimeInput setValue={ (value) => {setDueTime(value)}} value={dueTime} placeholder={tTime('dueTime')}/> 
							</div>	
							: null }
					</div> : null}
					
				</div>

				<Calendar
					calendarDate={activeDate}
					onSelectDate={(date) => {
						if (activeDateOption === dateOptions.START) {
							setStartActiveDate(date)
						} else {
							setDueActiveDate(date)
						}
					}}
					disabled={disabled}
				/>

			</div>
		</div>
		
	)
}

export default CalendarContainer