// styles and icons
import './editableTable.scss'
import { chevronLeft, chevronRight, errorAlert, listIcon, warehouseProduct } from '../../../assets/general/generalIcons'
import { cartIcon } from '../../../assets/purchase/orders/ordersIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import Pagination from '../pagination/pagination'
import Table from '../table/table'
import Avatar from '../avatar/avatar'
import InputField from '../inputField/inputField'
import CheckBox from '../checkBox/checkBox'
import SwitchButtons from '../switchButtons/switchButtons'
import EditableTableAmountInput from './editableTableAmountInput/editableTableAmountInput'

// types
import {
	currency,
	editableTable,
	editableTableItem,
	inventoryItem,
	itemTypes,
	offering,
	switchButton
} from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { getIdWithCurrency } from '../../../assets/general/generalFunctions'

//other
import { formatStringAmountIntoNumber } from '../../../assets/general/generalFunctions'

type editableTableProps = editableTable & {
	noCost?: boolean
}

type tableColumnData = {
	id: number | string
	class: string
	choosen: JSX.Element
	name: JSX.Element
	quantity: JSX.Element
	price?: JSX.Element
	cost?: JSX.Element
}

const EditableTable: FC<editableTableProps> = ({ items, setItems, loadItems, selected, reload, error, disabled, noCost, updateAmount, limitMaxQuantity=true}) => {
	const { t } = useTranslation('', { keyPrefix: 'general.editableTable' })

	const [tableData, setTableData] = useState<tableColumnData[]>([])
	const [activeTabId, setActiveTabId] = useState<number>(disabled ? 1 : 0)

	const tabs: switchButton[] = disabled ?
		[
			{
				id: 1,
				icon: cartIcon,
				onClick: () => {
					setActiveTabId(1)
				}
			}
		] : [
			{
				id: 0,
				icon: listIcon,
				onClick: () => {
					setActiveTabId(0)
				}
			},
			{
				id: 1,
				icon: cartIcon,
				onClick: () => {
					setActiveTabId(1)
				}
			}
		]

	const [page, setPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const formatAmount = (number: number): string => {
		const parts = number.toString().split('.')
		const integerPart = parts[0]
		const decimalPart = parts.length > 1 ? parts[1] : '00'

		let formattedIntegerPart = ''
		for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
			if (j > 0 && j % 3 === 0) {
				formattedIntegerPart = ',' + formattedIntegerPart
			}
			formattedIntegerPart = integerPart[i] + formattedIntegerPart
		}

		return formattedIntegerPart + '.' + (decimalPart.length === 1 ? decimalPart.concat('0') : decimalPart)
	}

	const formatCurrencySign = (currency: currency): string => {
		const currencyMap = {
			USD: '$',
			UAH: '₴',
			EUR: '€'
		}

		return currencyMap[currency]
	}

	const changeQuantity = (changingItem: editableTableItem, quantity: number) => {
		if (limitMaxQuantity && changingItem.maxQuantity) {
			quantity = Math.min(quantity, changingItem.maxQuantity)
		}

		if (selected) {
			const selectedItemIndex = selected.items.findIndex((item) => {
				return getIdWithCurrency(item) === getIdWithCurrency(changingItem)
			})

			if (selectedItemIndex > -1) {
				selected.items[selectedItemIndex].quantity = quantity
				selected.setItems([...selected.items])
			} else {
				changingItem.quantity = quantity
				selected.setItems([...selected.items, changingItem])
			}
		}

		const itemIndex = items.findIndex((item) => {
			return getIdWithCurrency(item) === getIdWithCurrency(changingItem)
		})

		if (itemIndex > -1) {
			items[itemIndex].quantity = quantity
			setItems([...items])
		}
	}


	const updateSelectedItem = (item: editableTableItem, add: boolean) => {
		if (add) {
			changeQuantity(item, 1)
		} else {
			const updatedItems = selected?.items.filter(selectedItem => {
				return getIdWithCurrency(selectedItem) !== getIdWithCurrency(item)
			})
			selected?.setItems(updatedItems as editableTableItem[])
		}
	}

	const amountInputHandle = (rawValue: string, item: editableTableItem) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const value = formatStringAmountIntoNumber(rawValue)
		const amount = isNaN(parseFloat(value)) ? 0 : Number(parseFloat(value).toFixed(2))

		let alteredItem: editableTableItem | null = null
		let oldAmount = 0

		let id = '-1'
		
		let updatedItems: editableTableItem[] = []

		if (items[0]?.type === itemTypes.inventory_item) {
			updatedItems = (selected?.items as (inventoryItem)[]).map((itemMap) => {
				if (getIdWithCurrency(itemMap) !== getIdWithCurrency(item) || itemMap.cost === undefined) {
					return itemMap
				}
				alteredItem = {
					...itemMap,
					cost: { 
						amount: amount, 
						currency: itemMap.cost.currency
					},
					idWithCurrency: getIdWithCurrency(itemMap),
				}
				
				id = getIdWithCurrency(item)
				oldAmount = itemMap.cost.amount | 0
				return alteredItem
			}) as editableTableItem[]

		} else {
			updatedItems = (selected?.items as offering[]).map((itemMap) => {
				if (getIdWithCurrency(itemMap) !== getIdWithCurrency(item)) {
					return itemMap
				}
				alteredItem = {
					...itemMap,
					price: { 
						currency: itemMap.price.currency,
						amount: amount
					},
					idWithCurrency: getIdWithCurrency(itemMap)
				}

				id = getIdWithCurrency(item)
				oldAmount = itemMap.price.amount

				return alteredItem
			}) as editableTableItem[]
		}

		if (!!updateAmount && !!alteredItem && amount !== oldAmount) {
			updateAmount(id, alteredItem, selected?.items)
			selected?.setItems([...updatedItems])
		}
	}
	
	const loadTableData = (activeTabId: number) => {
		let data: tableColumnData[]
		
		if (selected) {

			const list = activeTabId === 0 ? items : selected.items
			
			data = list.map(item => {
				const selectedItem = selected.items.find((selectedItem) => {
					return getIdWithCurrency(selectedItem) === getIdWithCurrency(item) && selectedItem.type === item.type
				})

				const isSelectedItem = selectedItem !== undefined
				let value: string
				let currencySign: string

				if (item.type === itemTypes.offering) {
					value = formatAmount(item.price.amount)


					currencySign = formatCurrencySign(item.price.currency)
				} else {
					if (!item.cost) {
						value = ''
						currencySign = ''
					} else {
						value = formatAmount(item.cost.amount)
						currencySign = formatCurrencySign((item.cost?.currency || currency.USD))
					}
				}

				const data = ({
					id: getIdWithCurrency(item),
					class: isSelectedItem ? 'selected' : '',
					choosen: !disabled ?
						<div className="editable-table-checkbox">
							<CheckBox
								isChecked={isSelectedItem}
								onClick={() => {
									if (!disabled) {
										updateSelectedItem(item, !isSelectedItem)
									}
								}}
							/>
						</div> : null,
					name:
						<div className='editable-table-name'>
							<div className='product-avatar'><Avatar blobAvatar={item.avatar} placeholder={item.placeholder ? item.placeholder : warehouseProduct} /></div>
							<p>{item.name}</p>
						</div>,
					quantity:
						<div className='editable-table-quantity'>
							{/* Decrease */}
							{
								!disabled ?
									<p onClick={() => {
										if (!disabled) {
											changeQuantity(item, Math.max(selectedItem?.quantity ? selectedItem.quantity - 1 : item.quantity - 1, 0))
										}
									}} className='quantity-control'>{chevronLeft}</p>
									: null
							}

							{/* Amount */}
							<div className="product-amount">
								<p>x</p>
								<input
									type="text"
									inputMode='numeric'
									value={selectedItem?.quantity ? selectedItem.quantity : item.quantity}
									style={{ width: Math.max(Math.min(item.quantity.toString().length, 3) - 0.5, 1.5) + 'vw' }}
									// this cuts numbers with 2+ digits
									onChange={(e) => {
										if (!disabled) {
											const value = e.target.value
											if (isNaN(Number(value))) return
											changeQuantity(item, Math.max(Number(value), 0))
										}
									}} />
							</div>

							{/* Increase */}
							{
								!disabled ?
									<p onClick={() => {
										if (!disabled) {
											changeQuantity(item, selectedItem?.quantity ? selectedItem.quantity + 1 : item.quantity + 1)
											// (item.type===itemTypes.offering && item.maxQuantity) ? changeQuantity(item, Math.min(item.quantity + 1, item.maxQuantity)) : changeQuantity(item, selectedItem?.quantity ? selectedItem.quantity + 1 : item.quantity + 1)
										}
									}} className='quantity-control'>{chevronRight}</p>
									: null
							}

						</div>,
					price:
						value && updateAmount && activeTabId === 1 ?
							<p className='editable-table-price'>
								<p className='editable-table-price-currency'>{currencySign}</p>
								<EditableTableAmountInput
									item={item}
									amountInputHandle={amountInputHandle}
									value={value}
								/>
							</p> :
							<p className='editable-table-price'>
								<p className='editable-table-price-currency'>{currencySign}</p>
								<p>{value}</p>
							</p>
				})

				return data
			}) as tableColumnData[]
		} else {
			const itemsWithMaxValue = items.map((item)=>{
				const maxValue = item.maxQuantity ? Math.max(item.quantity, item.maxQuantity) : item.quantity 
				return {
					...item,
					maxQuantity: maxValue
				}
			}) 
	

			data = itemsWithMaxValue.map(item => {
				let value: string
				let currencySign: string

				if (item.type === itemTypes.offering) {
					value = formatAmount(item.price.amount)
					currencySign = formatCurrencySign(item.price.currency)
				} else {
					if (!item.cost) {
						value = ''
						currencySign = ''
					} else {
						value = formatAmount(item.cost.amount)
						currencySign = formatCurrencySign((item.cost?.currency || currency.USD))
					}
				}

				const data = ({
					id: getIdWithCurrency(item),
					name:
						<div className='editable-table-name'>
							<div className='product-avatar'><Avatar blobAvatar={item.avatar} placeholder={item.placeholder ? item.placeholder : warehouseProduct} /></div>
							<p>{item.name}</p>
						</div>,
					quantity:
						<div className='editable-table-quantity'>
							{/* Decrease */}
							{
								!disabled ?
									<p onClick={() => {
										if (!disabled) {
											changeQuantity(item, Math.max(item.quantity - 1, 0))
										}
									}} className='quantity-control'>{chevronLeft}</p>
									: null
							}

							{/* Amount */}
							<div className="product-amount">
								<p>x</p>
								<input
									type="text"
									inputMode='numeric'
									value={item.quantity}
									style={{ width: Math.max(Math.min(item.quantity.toString().length, 3) - 0.5, 1) + 'vw' }}
									disabled={disabled}
									onChange={(e) => {
										if (!disabled) {
											const value = e.target.value
											if (isNaN(Number(value))) return
											changeQuantity(item, Math.max(Number(value), 0))
										}
									}} />
							</div>

							{/* Increase */}
							{
								!disabled ?
									<p onClick={() => {
										if (!disabled) {
											changeQuantity(item, item.quantity + 1)
										}
									}} className='quantity-control'>{chevronRight}</p>
									: null
							}

						</div>,
					price: value && updateAmount ?
						<p className='editable-table-price'>
							<p className='editable-table-price-currency'>{currencySign}</p>
							<EditableTableAmountInput
								item={item}
								amountInputHandle={amountInputHandle}
								value={value}
							/>
						</p> :
						<p className='editable-table-price'>
							<p className='editable-table-price-currency'>{currencySign}</p>
							<p>{value}</p>
						</p>
				})


				return data
			}) as tableColumnData[]
		}

		return data
	}

	const tableColumn: {
		key: string
		title: string | JSX.Element
	}[] = []

	
	if (selected && !disabled) {
		tableColumn.push({ key: 'choosen', title: t('choosen') })
		tableColumn.push({ key: 'name', title: t('name') })
		tableColumn.push({ key: 'quantity', title: t('quantity') })
		if (!noCost) tableColumn.push(items.find((item) => item.type === itemTypes.offering) ? { key: 'price', title: t('price') } : { key: 'price', title: t('cost') })
		
	} else {
		tableColumn.push({ key: 'name', title: t('name') })
		tableColumn.push({ key: 'quantity', title: t('quantity') })
		if (!noCost) tableColumn.push(items.find((item) => item.type === itemTypes.offering) ? { key: 'price', title: t('price') } : { key: 'price', title: t('cost') })
	}


	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadItems(searchQuery, page)
				.then((result) => {
					setItems([...items, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}).finally(() => {
					setLoading(false)
				})
		}
	}

	const onSearch = (searchQuery: string) => {

		setPage(1)
		setLastPage(false)
		setSearchQuery(searchQuery)

		loadItems(searchQuery, 1)
			.then((items) => {
				setItems(items.map(e => ({ ...e, maxQuantity: e.quantity })))
				if (items.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	useEffect(() => {
		onSearch('')
	}, [])

	useEffect(() => {
		if (reload) {
			onSearch('')
			selected?.setItems([])
		}
	}, [reload])

	useEffect(() => {
		setTableData(loadTableData(activeTabId))
	}, [items, activeTabId, selected])

	return (
		<div className='editable-table'>
			<div className={`editable-table-header ${error ? 'error' : ''}`}>
				<InputField type='text' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onSearch={() => { onSearch(searchQuery) }} />
				{selected ? <SwitchButtons switchButtons={tabs} activeSwitchButtonId={activeTabId} /> : null}
			</div>
			<Pagination
				onLoadMore={handleLoadMore}
				onlyLoadOn='bottom'
				loading={loading}
			>
				<Table
					columns={tableColumn}
					data={tableData}
				/>
				{error &&
					<div className='error-text'>
						{errorAlert}
						<p>{error}</p>
					</div>
				}
			</Pagination>
		</div>
	)
}

export default EditableTable