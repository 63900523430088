import { warehouseResponse } from '../../../types/general/generalTypes'
import { warehouse } from '../../../types/warehouse/generalTypes'

export const formatedWarehouses = (result: warehouseResponse[]): warehouse[] => {
	return result.map((warehouse) => {
		return {
			id: warehouse.id,
			name: warehouse.name,
			coords: {
				lat: warehouse.coords.lat,
				lng: warehouse.coords.lng
			},
			address: warehouse.address,
			shipmentAmount: warehouse.shipment_amount,
			movingAmount: warehouse.moving_amount,
			worth: warehouse.worth,
			editAccess: warehouse.edit_access,
			deleteAccess: warehouse.delete_access
		}
	})
}
