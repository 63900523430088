// styles
import './homeHeroSection.scss'

// react
import { useNavigate } from 'react-router'

// components
import SectionWithLines from '../../general/sectionWithLines/sectionWithLines'
import ScrollAnimation from '../../general/scrollAnimation/scrollAnimation'
import Button from '../../../../../components/general/button/button'
import Title from '../../general/title/title'
import Subtitle from '../../general/subtitle/subtitle'

// custom hook
import useScreenSize from '../../../customHooks/useScreenSize'

//translation
import { useTranslation } from 'react-i18next'

const HomeHeroSection = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.home.heroSection' })

	const navigate = useNavigate()
	const {screenWidth} = useScreenSize()

	return (
		<SectionWithLines 
			leftLineStyles={
				screenWidth < 768 ? {width: '30vw',left: '-23%', top: '-11%', transform: 'rotateZ(20deg)', hidden: false}: undefined}
			rightLineStyles={
				screenWidth < 768 ? {width: '30vw',left: '95%', top: '5%', transform: 'rotateZ(200deg)', hidden: false}: undefined}
		>
			<div className="home-hero">
				<ScrollAnimation direction='to-bottom'>
					<div className="hero-content">
						<Title>
							{t('let')} <span className='text-cyan'>{t('RISoftware')}</span> {t('takeYour')} <span className='text-blue'>{t('business')}</span> {t('toADifferentLevel')} 
						</Title>
						<div className='hero-content-actions'>
							<div className='hero-content-actions-button1'>
								<Button active={true} text={t('createAnAccountForFree')} onClick={()=> navigate('/register')}/>
							</div>
							<div className='hero-content-actions-button2'>
								<Button active={true} outlined={true} text={t('learnMore')} onClick={()=> navigate('/product-description')}/>
							</div>
						</div>
						<Subtitle>{t('noCreditCardRequired')}</Subtitle>
					</div>
				</ScrollAnimation>
			</div>
		</SectionWithLines>
	)
}

export default HomeHeroSection