//styles and icons
import './chatBarCard.scss'

//components
import MessageCard from '../../general/messageCard/messageCard'

//react
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

//types
import { chatBarCard } from '../../../../../types/employees/chatTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { createPrivatChatUrl } from '../../../../../utils/old_urls/employees/chatsUrls'

type chatBarCardProps = chatBarCard & {
	chatsList: chatBarCard[]
	setChatsList: (value: chatBarCard[]) => void
}

const ChatBarCard: FC<chatBarCardProps> = ({chatId, message, avatar, isGroup, name, unreadMessages, chatsList, userId, setChatsList}) => {
	const navigate = useNavigate()

	const openChat = () => {
		if(chatId){
			navigate(`/ri-business/chats/${chatId}`)
		}else{
			authorizedRequest(createPrivatChatUrl, 'POST', 'accessToken', {
				name: name,
				member_user_id: userId
			}).then((response)=>{
				const { result } = response

				const currentChat = chatsList.find((chat: chatBarCard) => chat.userId === userId) as chatBarCard
			
				currentChat.userId = null
				currentChat.chatId = result.chat_id
				
				setChatsList([...chatsList])
				navigate(`/ri-business/chats/${result.chat_id}`)
			})
		}
	}

	return (
		<div className="chat-card-wrapper" onClick={() => openChat()}>
			<MessageCard avatar={avatar} name={name} message={message} isGroup={isGroup}/>
			{
				unreadMessages ? 
					<div className="unread-messages-container">
						<p className="unread-messages">{unreadMessages}</p> 
					</div>
					:
					null
			}
		</div>
	)
}

export default ChatBarCard
