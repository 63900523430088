//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { inventoryTurnover } from '../../../../utils/urls/warehouses/warehouseStats/warehouseStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const InventoryManagementMetrics = () => {
	const { t } = useTranslation('', { keyPrefix: 'sales.warehouseStats.inventoryManagementMetricsTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadInventoryTurnover = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(inventoryTurnover(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('inventor_turnover')} loadData={loadInventoryTurnover} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>
	)
}

export default InventoryManagementMetrics