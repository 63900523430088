//components
import ListItemCard from '../../../general/listItemCard/listItemCard'

//react
import { FC } from 'react'

// translation
import { useTranslation } from 'react-i18next'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteAccountId, setDeleteAccountModalIsOpen } from '../../../../redux/general/modals'

//types
import { account } from '../../../../types/finance/accountTypes'


//other
import { currencyToFormattedString, formatDate } from '../../../../assets/general/generalFunctions'

type accountListItemProps = account & {
	deleteAccess: boolean
}

const AccountListItem: FC<accountListItemProps> = ({ id, name, description, balance, accountNumber, bank, lastAction, deleteAccess }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.accountList.accountListItem' })

	const dispatch = useAppDispatch()
	const handleDelete = async (id: number) => {
		dispatch(setDeleteAccountId(id))
		dispatch(setDeleteAccountModalIsOpen(true))
	}

	return (
		<ListItemCard
			title={name}
			description={description}
			data={[
				{
					title: t('bankName'),
					value: bank
				},
				{
					title: t('accountNumber'),
					value: accountNumber.toString()
				},
				{
					title: t('lastAction'),
					value: lastAction ? formatDate(lastAction, true, true) : 'N/A'
				},
				...balance.map((balanceValue) => {
					return {
						title:  t('balance'),
						value: currencyToFormattedString(balanceValue.amount, balanceValue.currency)
					}
				})
			]}
			footer={{
				viewLink: {
					text: t('viewAccount'),
					url: `/ri-business/finance/account/${id}`
				},
				deleteFunction: deleteAccess ? () => { handleDelete(id) } : undefined
			}}
		/>
	)
}

export default AccountListItem