import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { file } from '../../types/general/generalTypes'

type generalModalsState = {
    companiesList: {
        modalIsOpen: boolean
    }
    createCompany: {
        modalIsOpen: boolean
    }
	attachFiles: {
		modalIsOpen: boolean
	}
	createTaskModal: {
		modalIsOpen: boolean,
		stageId: number | undefined
	}
	createBillModal: {
        modalIsOpen: boolean
    }
    deleteBillModal: {
        modalIsOpen: boolean
        billId: number | null
    },
	payBillModal: {
		modalIsOpen: boolean
		billId: number | null
	},
    importBillFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    billTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportBillFileModal: {
        modalIsOpen: boolean
    }
	exportReportFileModal: {
		modalIsOpen: boolean
	}
	createAccountModal: {
        modalIsOpen: boolean
    }
    deleteAccountModal: {
        modalIsOpen: boolean
        accountId: number | null
    }
    importAccountFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    accountTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportAccountFileModal: {
        modalIsOpen: boolean
	}
	restrictUserModal: {
		modalIsOpen: boolean
	}
}

const initialState: generalModalsState = {
	companiesList: {
		modalIsOpen: false
	},
	createCompany: {
		modalIsOpen: false
	},
	attachFiles: {
		modalIsOpen: false
	},
	createTaskModal: {
		modalIsOpen: false,
		stageId: undefined
	},

	createBillModal: {
		modalIsOpen: false
	},
	deleteBillModal: {
		modalIsOpen: false,
		billId: null
	},
	payBillModal: {
		modalIsOpen: false,
		billId: null,
	},
	importBillFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	billTablePreviewModal: {
		modalIsOpen: false
	},
	exportBillFileModal: {
		modalIsOpen: false
	},
	exportReportFileModal: {
		modalIsOpen: false
	},
	createAccountModal: {
		modalIsOpen: false
	},
	deleteAccountModal: {
		modalIsOpen: false,
		accountId: null
	},
	importAccountFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	accountTablePreviewModal: {
		modalIsOpen: false
	},
	exportAccountFileModal: {
		modalIsOpen: false
	},
	restrictUserModal: {
		modalIsOpen: false
	}
}

export const generalModalsSlice = createSlice({
	name: 'generalModalsSlice',
	initialState,
	reducers: {
		setCompaniesListModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.companiesList.modalIsOpen = action.payload
		},
		setCreateCompanyModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createCompany.modalIsOpen = action.payload
		},
		setAttachFilesModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.attachFiles.modalIsOpen = action.payload
		},
		setCreateTaskModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createTaskModal.modalIsOpen = action.payload
		},
		setCreateTaskModalStageId: (state, action: PayloadAction<number | undefined>) => {
			state.createTaskModal.stageId = action.payload
		},

		setCreateBillModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createBillModal.modalIsOpen = action.payload
		},
		setDeleteBillModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteBillModal.modalIsOpen = action.payload
		},
		setDeleteBillId: ( state, action: PayloadAction<number | null>) => {
			state.deleteBillModal.billId = action.payload
		},
		setImportBillFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importBillFileSelectorModal.modalIsOpen = action.payload
		},
		setImportBillFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importBillFileSelectorModal.file = action.payload
		},
		setBillTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.billTablePreviewModal.modalIsOpen = action.payload
		},
		setExportBillFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportBillFileModal.modalIsOpen = action.payload
		},
		setExportReportFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportReportFileModal.modalIsOpen = action.payload
		},
		setCreateAccountModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createAccountModal.modalIsOpen = action.payload
		},
		setDeleteAccountModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteAccountModal.modalIsOpen = action.payload
		},
		setDeleteAccountId: (state, action: PayloadAction<number | null>) => {
			state.deleteAccountModal.accountId = action.payload
		},
		setImportAccountFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importAccountFileSelectorModal.modalIsOpen = action.payload
		},
		setImportAccountFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importAccountFileSelectorModal.file = action.payload
		},
		setAccountTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.accountTablePreviewModal.modalIsOpen = action.payload
		},
		setExportAccountFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportAccountFileModal.modalIsOpen = action.payload
		},
		setPayBillModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.payBillModal.modalIsOpen = action.payload
		},
		setPayBillId: ( state, action: PayloadAction<number | null>) => {
			state.payBillModal.billId = action.payload
		},
		setRestrictModalOpen: (state, action: PayloadAction<boolean>) => {
			state.restrictUserModal.modalIsOpen = action.payload
		}
	}
})

export const {
	setCompaniesListModalIsOpen,
	setCreateCompanyModalIsOpen,
	setAttachFilesModalIsOpen,
	setCreateTaskModalIsOpen,
	setCreateTaskModalStageId,
	setCreateAccountModalIsOpen,
	setDeleteAccountModalIsOpen,
	setDeleteAccountId,
	setImportAccountFileSelectorModalIsOpen,
	setImportAccountFileSelectorModalFile,
	setAccountTablePreviewModalIsOpen,
	setExportAccountFileModalIsOpen,
	setCreateBillModalIsOpen,
	setDeleteBillModalIsOpen,
	setDeleteBillId,
	setImportBillFileSelectorModalIsOpen,
	setImportBillFileSelectorModalFile,
	setExportReportFileModalIsOpen,
	setBillTablePreviewModalIsOpen,
	setExportBillFileModalIsOpen,
	setPayBillModalIsOpen,
	setPayBillId,
	setRestrictModalOpen,

} = generalModalsSlice.actions

export default generalModalsSlice.reducer