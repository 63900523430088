//styles and icons
import './extensionBanner.scss'
import { close } from '../../../../../../assets/general/generalIcons' 

//components 
import ExtensionFilesContainer from './extensionFilesContainer/extensionFilesContainer'

//react
import { FC, useEffect, useState } from 'react'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { messageActions } from '../../../../../../types/employees/chatTypes'

type extensionBannerProps = {
	text: string
	sender: string
}

const ExtensionBanner: FC<extensionBannerProps> = ({ text, sender }) => {
  
	const { setContextMenu, currentChatAction, setCurrentChatAction, attachFiles, setAttachedFiles } = useChatDesktop()

	const closeBanner = () => {
		setContextMenu(null)
		setCurrentChatAction({ action: messageActions.SEND, message: null })
		setAttachedFiles([])
	}

	const [ style, setStyle ] = useState<{
		height: string,
		padding: string
	}>({
		height: '0',
		padding: '0'
	})

	useEffect(() => {

		let height = 0
		let padding = '0'


		if(currentChatAction.action === messageActions.REPLY || currentChatAction.action === messageActions.EDIT ){
			height += 6.7 // 6.7 - needed height for edit/reply message banner
			padding = '0.78vw 1.17vw'
		}

		if(attachFiles.attachedFiles.length > 0){
			height += 11.9 // 11.9 needed height for file banner
			padding = '0.78vw 1.17vw'
		}

		setStyle({
			height: `${height}vw`,
			padding: padding
		})

	}, [currentChatAction, attachFiles.attachedFiles])

	return (
		<div className={'extension-banner'}
			style={style}>
			<div className="extension-banner-container">
				<div className="extension-banner-items-container">
					{
						attachFiles.attachedFiles.length > 0 ? <ExtensionFilesContainer /> : null
					}
				
					{
						currentChatAction.action === messageActions.REPLY || currentChatAction.action === messageActions.EDIT ?
							(	
								<div className="extension-banner-sender-info">
									<div className="extension-banner-sender">{sender}</div>
									<div className="extension-banner-sender-message"><p>{text}</p></div>
								</div>	
							) : null
					}
				</div>
				<div className="extension-banner-close-icon" onClick={closeBanner}>{close}</div>
			</div>
		</div>
	)
}

export default ExtensionBanner
