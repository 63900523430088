//icons
import { forecastIcon } from '../../../assets/projects/pipeline/pipelineIcons'

//components
import ListItemCard from '../listItemCard/listItemCard'

//react
import { FC } from 'react'

// translation
import { useTranslation } from 'react-i18next'

//types
import { budget } from '../../../types/finance/budgetTypes'

//redux
import {useAppDispatch} from '../../../customHooks/redux'
import {setDeleteBudgetId, setDeleteBudgetModalIsOpen} from '../../../redux/finance/budgetList/modals'

//other
import { currencyToFormattedString } from '../../../assets/general/generalFunctions'

type budgetListItemProps = budget & {
	deleteAccess: boolean
}

const BudgetListItem: FC<budgetListItemProps> = ({id, title, description, values, deleteAccess, errors, isClosed }) => {
	
	const { t } = useTranslation('',{keyPrefix:'finance.budgetList.budgetListItem'})

	const dispatch = useAppDispatch()

	const handleDelete	= async (id: number) => {
		dispatch(setDeleteBudgetModalIsOpen(true))
		dispatch(setDeleteBudgetId(id))
	}

	return (
		<ListItemCard
			title={title}
			description={description}
			errors={errors}
			isClosed={isClosed}
			data={[
				...values.map(({amount, currency})=>{
					return {
						title: t('total'),
						value: currencyToFormattedString(amount, currency)
					}
				})
			]}
			footer={{
				viewLink: {
					text: t('viewBudget'),
					url: `/ri-business/finance/budget/${id}`
				},
				deleteFunction: deleteAccess ? () => { handleDelete( id ) } : undefined
			}}
		/>
	)
}

export default BudgetListItem
