// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setBudgetTablePreviewModalIsOpen, setImportBudgetFileSelectorModalFile, setImportBudgetFileSelectorModalIsOpen } from '../../../../../redux/finance/budgetList/modals'

//types
import { currency } from '../../../../../types/general/generalTypes'
import { expenseCategory } from '../../../../../types/finance/general'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyBudgetsUrl } from '../../../../../utils/urls/finance/budget/budget'

//translation
import { useTranslation } from 'react-i18next'

const BudgetTablePreviewModal: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.budgetList.budgetListItem' })

	const { file } = useAppSelector((state) => state.budgetListModal.importBudgetFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.budgetListModal.budgetTablePreviewModal)

	const dispatch = useAppDispatch()

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const closeModal = () => {
		dispatch(setBudgetTablePreviewModalIsOpen(false))
		dispatch(setImportBudgetFileSelectorModalIsOpen(false))
		dispatch(setImportBudgetFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => {
				authorizedRequest(companyBudgetsUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map((item) => {
						return {
							company_id: companyId,
							title: item.title,
							amount: parseInt(item.amount),
							currency: currency.UAH
						}
					})
				})
			}}
			requiredColumns={[
				{
					title: t('title'),
					key: 'title',
					default: '',
					type: String
				},
				{
					title: t('total'),
					key: 'amount',
					default: '0',
					type: Number
				},
				{
					title: t('currency'),
					key: 'currency',
					default: currency.UAH,
					type: currency
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('category'),
					key: 'budget_category',
					default: expenseCategory.miscellaneous_expenses,
					type: expenseCategory
				}
			]}
		/>
	)
}

export default BudgetTablePreviewModal