// styles and icons
import './textArea.scss'

// react
import { FC, KeyboardEvent, useEffect, useRef } from 'react'

//components
import AttachedFilesPreview from './attachedFilesPreview/attachedFilesPreview'

//types
import { textArea } from '../../../types/general/generalTypes'

type textAreaProps = textArea


const TextArea: FC<textAreaProps> = ({
	leftAddon,
	rightAddon,
	placeholder,
	value,
	setValue,
	handleSubmit,
	attachedFiles,
	setAttachedFiles,
	label,
	disabled,
	minRows,
	maxRows,
	enterForNewTab=true
	// leftAddon, rightAddon, placeholder, value, setValue, handleSubmit, enterForNewTab=true
}) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey && !enterForNewTab) {
			event.preventDefault()
			handleSubmit && handleSubmit()
			if (textAreaRef.current){
				textAreaRef.current.rows = 1
			}
		}else if(event.key === 'Enter' && event.shiftKey){
			setValue(value)
		}
		if (event.key === 'Enter'&& enterForNewTab && !event.shiftKey){
			setValue(value + ' ' + '</br>')
		}
	}

	useEffect(()=> {
		if(textAreaRef.current && value === '') {
			textAreaRef.current.style.height = 'initial'
		}
		if(textAreaRef.current && value !== '') {
			textAreaRef.current.style.height = 'auto'
			textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
		}
	},[value])

	return (
		<div className="text-area">
			{label ? <p className="textarea-label">{label}</p> : null}
			{attachedFiles && setAttachedFiles ? <AttachedFilesPreview attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles} /> : null}
			<div className={`text-area-container ${attachedFiles && attachedFiles.length > 0 ? 'text-area-container-files-preview' : ''}`}>
				{leftAddon && <div className="text-area-left-addon">{leftAddon}</div>}
				<textarea
					ref={textAreaRef}
					rows={1}
					placeholder={placeholder}
					onKeyDown={handleKeyDown}
					className='text-input'
					value={value}
					onChange={(e)=>{
						setValue(e.target.value)
					}}
					disabled={disabled}
				>
				</textarea>
				{leftAddon && <div className="text-area-right-addon">{rightAddon}</div>}
			</div>
		</div>
	)
}

export default TextArea