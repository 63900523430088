// react
import {FC, useState} from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import CreateWarehouseAction from '../../../../general/warehouseAction/createWarehouseAction/createWarehouseAction'

//redux
import {useAppDispatch, useAppSelector} from '../../../../../customHooks/redux'
import {setCreateWarehouseActionModalIsOpen} from '../../../../../redux/projects/task/modals'

//network
import {authorizedRequest} from '../../../../../utils/queries'
import { companyWarehouseActionsUrl } from '../../../../../utils/urls/warehouses/warehouseActions/warehouseActions'

//types
import {dropdownOption, inventoryItem} from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type addWarehouseActionErrors = {
	destination?: string
	origin?: string
	items?: string
}

type createWarehouseActionProps = {
	loadData: () => void
}

const CreateWarehouseActionModal: FC<createWarehouseActionProps> = ({ loadData })=> {
	const { t } = useTranslation('', {keyPrefix:'general.modals.task.addWarehouseActionModal'})

	const [errors, setErrors] = useState<addWarehouseActionErrors>({})
	const [selectedItems, setSelectedItems] = useState<inventoryItem[]>([])
	const [selectedWarehouseOriginOption, setSelectedWarehouseOriginOption] = useState<dropdownOption | null>(null)
	const [selectedWarehouseDestinationOption, setSelectedWarehouseDestinationOption] = useState<dropdownOption | null>(null)
	const [ selectedAddressOriginOption, setSelectedAddressOriginOption ] = useState<dropdownOption | null>(null)
	const [ selectedAddressDestinationOption, setSelectedAddressDestinationOption ] = useState<dropdownOption | null>(null)

	const { modalIsOpen, taskId } = useAppSelector((state) => state.taskModals.createWarehouseActionModal)
	const {userCompanyData} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()


	const closeModal = ()=> {
		dispatch(setCreateWarehouseActionModalIsOpen(false))
		setSelectedWarehouseOriginOption(null)
		setSelectedWarehouseDestinationOption(null)
		setSelectedItems([])
		setErrors({})
	}

	const checkErrors = () => {
		let hasErrors = false

		if(selectedWarehouseOriginOption === undefined && selectedAddressOriginOption === undefined){
			setErrors(prevErrors => ({...prevErrors, origin: t('pleaseSelectTheOriginWarehouse')}))
			hasErrors = true
		}
		if(selectedWarehouseDestinationOption === undefined && selectedAddressDestinationOption === undefined){
			setErrors(prevErrors => ({...prevErrors, destination: t('pleaseSelectDestinationWarehouse')}))
			hasErrors = true
		}
		if(selectedItems.length === 0){
			setErrors(prevErrors => ({...prevErrors, destination: t('pleaseSelectTheItemsYouArePlanningToShip')}))
			hasErrors = true
		}

		return hasErrors
	}

	const addWarehouseAction = async ()=>{
		const hasErrors = checkErrors()
		if(hasErrors) return

		const body = {
			task_id: taskId,
			origin_warehouse_id: selectedWarehouseOriginOption ? Number(selectedWarehouseOriginOption.key) : null,
			destination_warehouse_id: selectedWarehouseDestinationOption ? Number(selectedWarehouseDestinationOption.key) : null,
			origin_address_id: selectedAddressOriginOption ? Number(selectedAddressOriginOption.key) : null,
			destination_address_id: selectedAddressDestinationOption ? Number(selectedAddressDestinationOption.key) : null,
			inventory_items: selectedItems.map(item => {
				return {
					item_id: item.inventoryItemId,
					cost: item.cost?.amount,
					currency: item.cost?.currency,
					quantity: item.quantity
				}
			})
		}

		await authorizedRequest(companyWarehouseActionsUrl(companyId), 'POST', 'accessToken', body)
		loadData()
		closeModal()

	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('addWarehouseAction')}
			submitButton={{
				text: t('create'),
				onClick: addWarehouseAction
			}}
		>
			<CreateWarehouseAction
				selectedWarehouseOriginOption={selectedWarehouseOriginOption}
				selectedAddressOriginOption={selectedAddressOriginOption}
				selectedWarehouseDestinationOption={selectedWarehouseDestinationOption}
				selectedAddressDestinationOption={selectedAddressDestinationOption}
				selectedInventoryItems={selectedItems}
				setSelectedWarehouseOriginOption={setSelectedWarehouseOriginOption}
				setSelectedWarehouseDestinationOption={setSelectedWarehouseDestinationOption}
				setSelectedAddressOriginOption={setSelectedAddressOriginOption}
				setSelectedAddressDestinationOption={setSelectedAddressDestinationOption}
				setSelectedInventoryItems={setSelectedItems}
				originError={errors.origin}
				destinationError={errors.destination}
				itemsError={errors.items}
			/>
		</Modal>
	)
}

export default CreateWarehouseActionModal