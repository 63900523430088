//styles
import './offeringCardSkeleton.scss'

//components
import Loading from '../../loading/loading'

const OfferingCardSkeleton = () => {
	return (
		<div className='offering-card-skeleton-container'>
			<Loading style={{ position: 'relative', top: '0', left: '0' }} />
		</div>
	)
}

export default OfferingCardSkeleton