// react
import { Dispatch, FC, SetStateAction } from 'react'

// component
import FileTableParserModal from '../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setImportOfferingFileSelectorModalIsOpen, setTableOfferingPreviewModalIsOpen, setImportOfferingFileSelectorModalFile } from '../../../../../redux/sales/offeringList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyOfferingsUrl } from '../../../../../utils/urls/sales/offerings'

// types
import { currency, offering } from '../../../../../types/general/generalTypes'
import { formatOfferingResponse } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next' 

type offeringTablePreviewModalProps = {
	offerings: offering[]
	setOfferings: Dispatch<SetStateAction<offering[]>>
}

const OfferingTablePreviewModal : FC<offeringTablePreviewModalProps> = ({offerings, setOfferings}) => {
	const { t } = useTranslation('', { keyPrefix: 'sales.offeringList.modals.offeringTablePreviewModal' })

	const { file } = useAppSelector((state) => state.offeringListModal.importOfferingFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.offeringListModal.tableOfferingPreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setImportOfferingFileSelectorModalIsOpen(false))
		dispatch(setTableOfferingPreviewModalIsOpen(false))
		dispatch(setImportOfferingFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}	
			open={modalIsOpen}
			closeModal={closeModal}	
			onTableProcessed={(tableData) => {
				authorizedRequest(companyOfferingsUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map(( item ) => {
						return {
							name: item.name, 
							price: item.price,
							vat: item.vat,
							currency: item.currency,
							description: item.description,
							items: []
						}
					})
				}).then(({result})=> {
					if(result.length > 0) {
						const formatedOfferrings: offering[] = result.map(formatOfferingResponse)
						setOfferings([...formatedOfferrings, ...offerings])
					}
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('price'),
					key: 'price',
					default: '0',
					type: Number
				},
				{
					title: t('vat'),
					key: 'vat',
					default: '0',
					type: Number
				},
				{
					title: t('currency'),
					key: 'currency',
					default: 'UAH',
					type: currency
				}
			]}
		/>
	)
}

export default OfferingTablePreviewModal