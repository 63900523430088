// Style
import './calendar.scss'

// React
import { useState, FC, useEffect } from 'react'

// Icons
import { arrowIcon } from '../../../assets/general/generalIcons'

//other
import { useTranslation } from 'react-i18next'

type calendarProps = {
	onSelectDate: (date: Date) => void
	calendarDate?: Date
	disabled?: boolean
}

const Calendar: FC<calendarProps> = ({ onSelectDate, calendarDate, disabled }) => {
	const [currentDate, setCurrentDate] = useState(new Date())
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(calendarDate)

	const { t } = useTranslation('', {keyPrefix: 'general.calendar'})

	useEffect(() => {
		setSelectedDate(calendarDate)
	}, [calendarDate])


	const handlePrevMonth = () => {
		setCurrentDate(
			(prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1)
		)
	}

	const handleNextMonth = () => {
		setCurrentDate(
			(prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1)
		)
	}

	const renderCalendarDays = () => {
		const daysInMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		).getDate()

		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		).getDay()

		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		).getDay()

		const daysInPrevMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			0
		).getDate()

		// Calculate the number of days to display from the previous month in the first row
		const daysInPrevMonthToDisplay = firstDayOfMonth

		// Calculate the number of days to display from the next month in the last row
		const daysInNextMonthToDisplay = 6 - lastDayOfMonth

		// Calculate the total number of days in the first and last rows combined
		const totalDaysInFirstAndLastRows =
			daysInPrevMonthToDisplay + daysInNextMonthToDisplay

		const allDays = Array.from({ length: daysInMonth + totalDaysInFirstAndLastRows }, (_, index) => {
			let day
			let date
			let isToday = false
			let isCurrentMonth = false

			if (index < daysInPrevMonthToDisplay) {
				// Dates from the previous month
				day = daysInPrevMonth - (daysInPrevMonthToDisplay - index) + 1
				date = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() - 1,
					day
				)
			} else if (
				index >= daysInPrevMonthToDisplay &&
				index < daysInPrevMonthToDisplay + daysInMonth
			) {
				// Dates from the current month
				day = index - daysInPrevMonthToDisplay + 1
				date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
				isToday = date.toDateString() === new Date().toDateString()
				isCurrentMonth = true
			} else {
				// Dates from the next month
				day = index - (daysInPrevMonthToDisplay + daysInMonth) + 1
				date = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					day
				)
			}
			return { day, isToday, date, isCurrentMonth }
		})

		return (
			<div className="day-name-and-month-container">
				{renderDayNames()}
				<div className="day-container">
					{allDays.map(({ day, isToday, date, isCurrentMonth }, index) => (
						<p
							key={`day-container-${index}`}
							className={`day ${isToday && !selectedDate ? 'today' : ''} ${selectedDate &&
								date.toDateString() === selectedDate.toDateString()
								? 'selected'
								: ''
							} ${!isCurrentMonth ? 'other-month' : ''}`}
							onClick={() => {
								handleDateClick(date)
							}}
						>
							{day > 0 && day <= daysInMonth + totalDaysInFirstAndLastRows
								? day
								: ''}
						</p>
					))}
				</div>
			</div>
		)
	}

	const renderDayNames = () => {
		const daysOfWeek = [t('su'), t('mo'), t('tu'), t('we'), t('th'), t('fr'), t('sa')]
		return (
			<div className="day-names">
				{daysOfWeek.map((day) => (
					<p key={day} className="day-name">
						{day}
					</p>
				))}
			</div>
		)
	}

	const handleDateClick = (date: Date) => {
		if(!disabled){
			setSelectedDate(date)
			onSelectDate(date)
		}
	}

	return (
		<div className="calendar">
			<div className="year-container">
				<div className="month-left-arrow left-arrow" onClick={() => {
					handlePrevMonth()
				}}>
					{arrowIcon}
				</div>
				<div className="years">
					{t(currentDate.toLocaleString('EN', { month: 'long' }))}{' '}
					{currentDate.getFullYear()}
				</div>
				<div className="month-right-arrow right-arrow" onClick={() => {
					handleNextMonth()
				}}>
					{arrowIcon}
				</div>
			</div>

			<div className="month-container">
				<div className="days-container">{renderCalendarDays()}</div>
			</div>
		</div>
	)
}

export default Calendar