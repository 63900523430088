// images
import providerDetailsEn from '../../../../assets/manual/purchase/en/providerDetails.png'
import providerDetailsDocumentsEn from '../../../../assets/manual/purchase/en/providerDetailsDocument.png'
import providerDetailsDocumentsAddEn from '../../../../assets/manual/purchase/en/providerDetailsDocumentAdd.png'
import providerDetailsOfferingsEn from '../../../../assets/manual/purchase/en/providerDetailsOfferings.png'
import providerDetailsOfferingsAddEn from '../../../../assets/manual/purchase/en/providerDetailsOfferingsaAdd.png'
import providerDetailsOrdersEn from '../../../../assets/manual/purchase/en/providerDetailsOrders.png'
import providerDetailsOrdersCreateEn from '../../../../assets/manual/purchase/en/providerDetailsOrderCreate.png'
import providerDetailsBillsEn from '../../../../assets/manual/purchase/en/providerDetailsBillsList.png'
import providerDetailsBillsCreateEn from '../../../../assets/manual/purchase/en/providerDetailsBillCreate.png'
import providerDetailsUa from '../../../../assets/manual/purchase/ua/providerDetails.png'
import providerDetailsDocumentsUa from '../../../../assets/manual/purchase/ua/providerDetailsDocument.png'
import providerDetailsDocumentsAddUa from '../../../../assets/manual/purchase/ua/providerDetailsDocumentAdd.png'
import providerDetailsOfferingsUa from '../../../../assets/manual/purchase/ua/providerDetailsOfferings.png'
import providerDetailsOfferingsAddUa from '../../../../assets/manual/purchase/ua/providerDetailsOfferingsaAdd.png'
import providerDetailsOrdersUa from '../../../../assets/manual/purchase/ua/providerDetailsOrders.png'
import providerDetailsOrdersCreateUa from '../../../../assets/manual/purchase/ua/providerDetailsOrderCreate.png'
import providerDetailsBillsUa from '../../../../assets/manual/purchase/ua/providerDetailsBillsList.png'
import providerDetailsBillsCreateUa from '../../../../assets/manual/purchase/ua/providerDetailsBillCreate.png'

// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const ProviderManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.purchase.providers' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('createProverTitle'),
			text: t('createProverText'),			
		},
		{
			title: t('deleteProverTitle'),
			text: t('deleteProverText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('providersManualTitle')}
			data={data}
		/>
	)
}

export default ProviderManual