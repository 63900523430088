//styles
import './taskCalendarDateItem.scss'

//react
import { FC } from 'react'

//translation
import {useTranslation} from 'react-i18next'

type taskCalendarDateItemType = {
	year: number,
	month: number,
	days: number[]
}


const TaskCalendarDateItem : FC<taskCalendarDateItemType> = ({year, month, days}) => {
	const {t}=useTranslation('',{keyPrefix:'general.calendar'})
	const {t:trans}=useTranslation('',{keyPrefix:'general.timeSelector.days'})
	const getDayOfTheWeek = (day: number, month: number, year: number) => {
		const date = new Date(year, month, day )
		return date.toLocaleString('EN', {weekday: 'short'})
	}

	const getMonthAndYear = ()=> {
		const date = new Date(year, month-1)
		return date.toLocaleString('EN', {month: 'long', year: '2-digit'})
	}
	return(
		<div className='timeline-calendar-table-date-item'>
			<div className='timeline-calendar-table-month-container'><p>{t(getMonthAndYear().split(' ')[0])} {getMonthAndYear().split(' ')[1]}</p></div>
			<div className='timeline-calendar-table-days-container'>
				{
					days.map((day: number)=>(
						<div className='timeline-calendar-table-day-item'>
							<div className='timeline-calendar-table-day-head'>
								<p className='timeline-calendar-table-day'>{trans(getDayOfTheWeek(day, month, year).toLowerCase())}</p>
								<p className='timeline-calendar-table-day-date'>{day}</p>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default TaskCalendarDateItem

