import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'

type videoPlayerProgressbarProps = {
    progress: number,
    setCurrentTime: Dispatch<SetStateAction<number>>,
    duration: number,
    setIsPlaying: Dispatch<SetStateAction<boolean>>,
    video: HTMLVideoElement | null,
	setIsDragging: Dispatch<SetStateAction<boolean>>
}

const VideoPlayerProgressbar: FC<videoPlayerProgressbarProps> = ({ progress, setCurrentTime, duration, setIsPlaying, video, setIsDragging }) => {
	const progressBarRef = useRef<HTMLDivElement>(null)

	const handleDragStart = () => {
		document.addEventListener('mousemove', handleDrag)
		document.addEventListener('mouseup', handleDragEnd)
		setIsPlaying(false)
		setIsDragging(true)
	}
    
	const handleDrag = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (progressBarRef.current && video) {
			const progressBarRect = progressBarRef.current.getBoundingClientRect()
			const offsetX = event.clientX - progressBarRect.left
			const progressPercentage = offsetX / progressBarRect.width
			const newProgress = Math.max(0, Math.min(1, progressPercentage))
			video.currentTime = (newProgress*duration)
			setCurrentTime(video.currentTime)
		}
	}
    
	const handleDragEnd = () => {
		document.removeEventListener('mousemove', handleDrag)
		document.removeEventListener('mouseup', handleDragEnd)
		setIsPlaying(true)

		setTimeout(() => {setIsDragging(false)}, 100)
	}
    
    
	return (
		<div className="video-player-controls-progressbar" style={{ background: `linear-gradient(to right, #376ABE ${progress}%, #fff ${progress}%)`}} onMouseDown={(e) => {handleDrag(e); handleDragStart()}} ref={progressBarRef} onClick={(e) => e.stopPropagation()}></div>
	)
}

export default VideoPlayerProgressbar