// react 
import { Dispatch, FC, SetStateAction, useState } from 'react'

// components
import CreateOrderModal from '../../../../general/modals/createOrderModal/createOrderModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateSalesOrderModalIsOpen } from '../../../../../redux/sales/salesOrderList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { createSalesOrderUrl } from '../../../../../utils/old_urls/sales/salesOrderListUrls'
import { companyCustomersUrl } from '../../../../../utils/urls/sales/customer'
import { companyOfferingsUrl } from '../../../../../utils/urls/sales/offerings'
import { pipelinesManagePipelineResourceUrl } from '../../../../../utils/urls/projects/pipelines'

// types
import {
	offering,
	dropdownOption,
	order,
	customerResponse,
	createOrderErrors, infoPopupTypes
} from '../../../../../types/general/generalTypes'

import { pipelineResponse } from '../../../../../types/projects/pipelineTypes'

// other
import { formatOfferingResponse, formatOrderResponse } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'
import { productOffer } from '../../../../../assets/general/generalIcons'

type createSalesOrderProps = {
	orders: order[]
	setOrders: Dispatch<SetStateAction<order[]>>
}
const CreateSalesOrderModal: FC<createSalesOrderProps> = ({ orders, setOrders }) => {
	const {t}=useTranslation('',{keyPrefix:'sales.createSalesOrderModal'})
	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.salesOrderListModal.createSalesOrderModal)

	const { userCompanyData, language } = useAppSelector((state) => state.general)

          
	const companyId: number = userCompanyData?.companyId || -1
	const [selectedCounterpartyOption, setSelectedCounterpartyOption] = useState<dropdownOption | null>(null)
	const [selectedPipelineOption, setSelectedPipelineOption] = useState<dropdownOption | null>(null)
	const [errors, setErrors] = useState<createOrderErrors>({})
	
	const pipelinePopup = {
		content: [
			{
				title: 'create_pipeline_info_title',
				description: 'create_pipeline_info_description',
				link: '/ri-business/projects/pipelines',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const counterpartyPopup = {
		content: [
			{
				title: 'create_counterparty_info_title',
				description: 'create_counterparty_info_description',
				link: '/ri-business/sales/customer-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const closeModal = () => {
		dispatch(setCreateSalesOrderModalIsOpen(false))
		setSelectedCounterpartyOption(null)
		setSelectedPipelineOption(null)
		setErrors({})
	}

	const loadProducts = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(companyOfferingsUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if (result && result.length > 0) {
			const productsData: offering[] = result.map(formatOfferingResponse)


			return productsData.map(e => ({ ...e, placeholder: productOffer }))
		}
		return []

	}

	const loadCounterpartyOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(companyCustomersUrl(companyId) + `?needle=${search}&page=${page}&per_page=10`, 'GET')

		return result.map((customer: customerResponse) => {
			return {
				title: customer.name,
				avatar: customer.avatar,
				key: `${customer.relationship_id}`
			}
		})
	}


	const loadPipelinesOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId) + `?page=${page}&per_page=${10}&needle=${search}`, 'GET')

		return result.map((pipeline: pipelineResponse) => {
			return {
				title: pipeline.name,
				key: `${pipeline.id}`
			}
		})
	}

	const checkErrors = () => {
		let hasErrors = false
		if (!selectedCounterpartyOption) {
			hasErrors = true
			setErrors({ ...errors, counterparty: 'pleaseSelectCounterparty' })
		}

		if (!selectedPipelineOption) {
			hasErrors = true
			setErrors({ ...errors, pipeline: 'pleaseSelectPipeline' })
		}

		return hasErrors
	}

	const addOrder = async (item: offering[]) => {
		const hasErrors = checkErrors()
		console.log(hasErrors)
		if (hasErrors) return

		if (selectedCounterpartyOption && selectedPipelineOption) {

			const offerings = item.map((offering) => {
				return {
					offering_id: offering.offeringId,
					offering_quantity: offering.quantity
				}
			})

			const body = {
				language: language,
				offerings: offerings,
				address_id: null,
				warehouse_id: null,
				pipeline_id: Number(selectedPipelineOption.key),
			}


			const { result } = await authorizedRequest(createSalesOrderUrl(Number(selectedCounterpartyOption.key)), 'POST', 'accessToken', body)
			if (result) {
				const formatedOrder: order = formatOrderResponse(result)
				setOrders([formatedOrder, ...orders])
			}
			closeModal()
		}
	}

	return (
		<CreateOrderModal
			isOpen={modalIsOpen}
			closeModal={closeModal}
			loadItems={loadProducts}
			createOrder={addOrder}
			hasErrors={checkErrors}
			relationshipId={Number(selectedCounterpartyOption?.key)}
			dropdowns={[
				{
					popup: counterpartyPopup,
					label: t('counterparty'),
					loadOptions: loadCounterpartyOptions,
					selectedOption: selectedCounterpartyOption,
					onSelect: ({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedCounterpartyOption(null)
						setSelectedCounterpartyOption({ key, title, avatar })
					},
					dropdownOptions: [],
					placeholder: 'selectCounterparty',
					error: errors.counterparty ? errors.counterparty : undefined
				},
				{
					popup: pipelinePopup,
					label: t('pipeline'),
					loadOptions: loadPipelinesOptions,
					selectedOption: selectedPipelineOption,
					onSelect: ({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedPipelineOption(null)
						setSelectedPipelineOption({ key, title, avatar })
					},
					dropdownOptions: [],
					placeholder: 'selectPipeline',
					error: errors.pipeline ? errors.pipeline : undefined
				}
			]}
		/>
	)
}

export default CreateSalesOrderModal