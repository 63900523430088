// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'

// types
import { currency, error, infoDetailsTableDataItem, moneyValue } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { companyBudgetsUrl, singleBudgetUrl } from '../../../../utils/urls/finance/budget/budget'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//other
import { formatErrorResponse, formatStringIntoTwoDigitsFloat } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type budgetDetailsProps = {
	setErrors: (value: error[]) => void
	setBudgetName: (value: string) => void
}

type details = {
	title: string
	description: string
	values: moneyValue[],
	editAccess: boolean
}

const BudgetDetails: FC<budgetDetailsProps> = ({ setErrors, setBudgetName }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.budget.budgetDetails' })

	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [details, setDetails] = useState<details>({
		title: '',
		description: '',
		values: [{
			amount: 0,
			currency: currency.UAH
		},
		{
			amount: 0,
			currency: currency.USD
		},
		{
			amount: 0,
			currency: currency.EUR
		}],
		editAccess: false,
	})

	const amountInputHandleUAH = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.values]
		updatedValues[0] = { ...updatedValues[0], amount: numberValue }
		setDetails({ ...details, values: updatedValues })
		setEditDetails(JSON.stringify(details.values)!==JSON.stringify(updatedValues))
	}
	const amountInputHandleUSD = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.values]
		updatedValues[1] = { ...updatedValues[1], amount: numberValue }
		setDetails({ ...details, values: updatedValues })
		setEditDetails(JSON.stringify(details.values)!==JSON.stringify(updatedValues))
	}
	const amountInputHandleEUR = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		const updatedValues = [...details.values]
		updatedValues[2] = { ...updatedValues[2], amount: numberValue }
		setDetails({ ...details, values: updatedValues })
		setEditDetails(JSON.stringify(details.values)!==JSON.stringify(updatedValues))
	}


	const { budgetId } = useParams()
	const navigate = useNavigate()

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const infoDetailsTableDataItems: infoDetailsTableDataItem[] = [
		{
			title: t('title'), data: {
				inputField: {
					type: 'text',
					value: details.title,
					onChange: (e) => {
						setDetails({ ...details, title: e.target.value })
						setEditDetails(true)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('description'), data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(details.description!==value)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: `${t('total')} UAH`, data: {
				floatInput: {
					amountInputHandle: amountInputHandleUAH,
					value: `${details.values[0].amount}`,
					disabled: !details.editAccess
				}
			},
		},
		{
			title: `${t('total')} USD`, data: {
				floatInput: {
					amountInputHandle: amountInputHandleUSD,
					value: `${details.values[1].amount}`,
					disabled: !details.editAccess
				}
			},
		},
		{
			title: `${t('total')} EUR`, data: {
				floatInput: {
					amountInputHandle: amountInputHandleEUR,
					value: `${details.values[2].amount}`,
					disabled: !details.editAccess
				}
			},
		},
	]

	const loadData = async () => {
		const response = await authorizedRequest(singleBudgetUrl(Number(budgetId)), 'GET')

		if (response === 403) navigate('/ri-business/403')
		const result = response.result

		setBudgetName(result.title)
		setErrors(result.errors.map(formatErrorResponse))
		setDetails({
			title: result.title,
			description: result.description,
			values: result.values,
			editAccess: result.edit_access,
		})
	}

	const editData = async () => {
		if (Number(budgetId) && editDetails) {
			await authorizedRequest(singleBudgetUrl(Number(budgetId)), 'PUT', 'accessToken', {
				budget_id: budgetId,
				title: details.title,
				description: details.description,
				amount_uah: details.values[0].amount,
				amount_usd: details.values[1].amount,
				amount_eur: details.values[2].amount
			})
		}

		setEditDetails(false)
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (editDetails) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTableDataItems} />
			{!details && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default BudgetDetails
