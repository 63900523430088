import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { file } from '../../../types/general/generalTypes'

type budgetListModalsState = {
    createBudgetModal: {
        modalIsOpen: boolean
    }
    deleteBudgetModal: {
        modalIsOpen: boolean
        budgetId: number | null
    }
    importBudgetFileSelectorModal: {
        modalIsOpen: boolean
        file: file | null
    }
    budgetTablePreviewModal: {
        modalIsOpen: boolean
    }
    exportBudgetFileModal: {
        modalIsOpen: boolean
    }
}

const initialState: budgetListModalsState = {
	createBudgetModal: {
		modalIsOpen: false
	},
	deleteBudgetModal: {
		modalIsOpen: false,
		budgetId: null
	},
	importBudgetFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	budgetTablePreviewModal: {
		modalIsOpen: false
	},
	exportBudgetFileModal: {
		modalIsOpen: false
	}
}

export const budgetListModalsSlice = createSlice({
	name: 'budgetListModalsSlice',
	initialState,
	reducers : {
		setCreateBudgetModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createBudgetModal.modalIsOpen = action.payload
		},
		setDeleteBudgetModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteBudgetModal.modalIsOpen = action.payload
		},
		setDeleteBudgetId: ( state, action: PayloadAction<number | null>) => {
			state.deleteBudgetModal.budgetId = action.payload
		},
		setImportBudgetFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importBudgetFileSelectorModal.modalIsOpen = action.payload
		},
		setImportBudgetFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importBudgetFileSelectorModal.file = action.payload
		},
		setBudgetTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.budgetTablePreviewModal.modalIsOpen = action.payload
		},
		setExportBudgetFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportBudgetFileModal.modalIsOpen = action.payload
		},
   
	}
})

export const {
	setCreateBudgetModalIsOpen,
	setDeleteBudgetModalIsOpen,
	setDeleteBudgetId,
	setImportBudgetFileSelectorModalIsOpen,
	setImportBudgetFileSelectorModalFile,
	setBudgetTablePreviewModalIsOpen,
	setExportBudgetFileModalIsOpen
} = budgetListModalsSlice.actions

export default budgetListModalsSlice.reducer
