// images
import CreateBillEn from '../../../../assets/manual/finance/en/createBillEn.png'
import CreateBillUa from '../../../../assets/manual/finance/ua/createBillUa.png'


// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const BillManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.finance.bills' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('createBillTitle'),
			text: t('createBillText')
		},
		{
			title: t('deleteBillTitle'),
			text: t('deleteBillText')
		},
		{
			title: t('payBillTitle'),
			text: t('payBillText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('billManualTitle')}
			data={data}
		/>
	)
}

export default BillManual
