//react
import { Dispatch, FC, SetStateAction, useState } from 'react'

// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleOfferingUrl } from '../../../../../../utils/urls/sales/offerings'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteProviderOfferingId, setDeleteProviderOfferingModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

//types
import { offering } from '../../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'


type deleteProviderOfferingModalProps = {
	offerings: offering[]
	setOfferings: (value: offering[]) => void
}


const DeleteProviderOfferingModal: FC<deleteProviderOfferingModalProps> = ({ offerings, setOfferings }) => {

	const { modalIsOpen, offeringId } = useAppSelector( state  => state.providerModal.providerOfferings.deleteProviderOfferingModal )

	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerOfferingList.deleteProviderOfferingConfirmationModal' })

	const [loading, setLoading] = useState<boolean>(false)


	const closeModal = (): void => {
		dispatch(setDeleteProviderOfferingModalIsOpen(false))
		dispatch(setDeleteProviderOfferingId(null))
	}

	const deleteOffering = () => {
		setLoading(true)
		authorizedRequest( singleOfferingUrl(offeringId!), 'DELETE' )
			.then( ( {result} ) => {
				const updatedOfferings = offerings.filter(offering => offering.offeringId !== offeringId)
				setOfferings(updatedOfferings)
				setLoading(false)
				closeModal()
				console.log(result)
			})
	}


	return (
		<ConfirmationModal 
			title={t('deleteThisOffering')} 
			isOpen={modalIsOpen} 
			buttons={[{text: t('yes'), onClickHandler: deleteOffering}, {text: t('no'), onClickHandler: closeModal}]} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteProviderOfferingModal