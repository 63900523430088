// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'

// types
import { infoDetailsTableDataItem } from '../../../../types/general/generalTypes'
import { providerResponse } from '../../../../types/purchase/providerTypes'

// network 
import { authorizedRequest } from '../../../../utils/queries'
import { singleProviderUrl } from '../../../../utils/urls/purchases/provider'

//translation
import { useTranslation } from 'react-i18next'

type providerDetailsProps = {
	setProviderName: (value: string) => void
}

type details = {
	name: string
	email: string
	phoneNumber: string
	description: string
	editAccess: boolean
}

const ProviderDetails: FC<providerDetailsProps> = ({ setProviderName }) => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.provider.providerDetails' })

	const navigate = useNavigate()
	const { relationshipId } = useParams()
	const [details, setDetails] = useState<details>({
		name: '',
		email: '',
		phoneNumber: '',
		description: '',
		editAccess: false
	})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const infoDetailsTableData: infoDetailsTableDataItem[] =
		[
			{
				title: t('name'),
				data: {
					inputField: {
						type: 'text',
						value: details.name,
						onChange: (e) => {
							setDetails({ ...details, name: e.target.value })
							setProviderName(e.target.value)
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('email'),
				data: {
					inputField: {
						type: 'text',
						value: details.email,
						onChange: (e) => {
							setDetails({ ...details, email: e.target.value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('phoneNumber'),
				data: {
					inputField: {
						type: 'text',
						value: details.phoneNumber,
						onChange: (e) => {
							setDetails({ ...details, phoneNumber: e.target.value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
			{
				title: t('description'),
				data: {
					textArea: {
						value: details.description,
						setValue: (value) => {
							setDetails({ ...details, description: value })
							setEditDetails(true)
						},
						disabled: !details.editAccess
					}
				}
			},
		]

	const loadData = async () => {
		if (isNaN(Number(relationshipId))) {
			navigate(-1)
		}
		try {
			setLoading(true)
			const { result }: { result: providerResponse } = await authorizedRequest(singleProviderUrl(Number(relationshipId)), 'GET')

			if (result === undefined) {
				navigate(-1)
			}

			setProviderName(result.name)
			setDetails({
				name: result.name,
				phoneNumber: result.phone,
				email: result.email,
				description: result.description,
				editAccess: result.edit_access
			})
		} finally {
			setLoading(false)
		}

	}

	const editData = async () => {
		if (Number(relationshipId)) {
			await authorizedRequest(singleProviderUrl(Number(relationshipId)), 'PUT', 'accessToken', {
				name: details.name,
				email: details.email,
				phone: details.phoneNumber,
				description: details.description,
			})
		}
	}

	useEffect(() => {

		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}

	}, [details])


	useEffect(() => {
		loadData()
	}, [])

	return <><InfoDetailsTable data={infoDetailsTableData} />{loading && <Loading style={{ top: '40vh', left: '50vw' }} />}</>
}

export default ProviderDetails