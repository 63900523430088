// react
import { FC } from 'react'

// component
import FileExportModal from '../../../../general/modals/fileExportModal/fileExportModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyWarehousesUrl } from '../../../../../utils/urls/warehouses/warehouses/warehouses'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setExportWarehouseFileModalIsOpen } from '../../../../../redux/warehouse/warehouseList/modals'

const ExportWarehouseFileModal: FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.warehouseListModal.exportWarehouseFileModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setExportWarehouseFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const { result } = await authorizedRequest(companyWarehousesUrl(companyId) + `?export=True&export_type=${exportType}`, 'GET')

		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportWarehouseFileModal