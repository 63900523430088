//styles
import './timeSelector.scss'
import { plusIcon } from '../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState } from 'react'

//components
import Clock from './clock/clock'
import SwitchButtons from '../switchButtons/switchButtons'
import TimeInput from './timeInput/timeInput'
import Button from '../button/button'

//types
import { shifts } from '../../../types/general/generalTypes'

//translation
import {useTranslation} from 'react-i18next'

type timeSelectorProps = {
	shifts: shifts
	setShifts: (value: shifts, updateData?: boolean) => void
}

const TimeSelector: FC<timeSelectorProps> = ({ shifts, setShifts }) => {

	const [ activeShiftDay, setActiveShiftDay ] = useState<{start: number, end: number}[]>([])
	const [ activeShiftDayIndex, setActiveShiftDayIndex ] = useState<number>()

	const [start, setStart] = useState(0)
	const [end, setEnd] = useState(0)

	const {t}=useTranslation('',{keyPrefix:'general.timeSelector.days'})
	const tTime = useTranslation('', {keyPrefix:'general.timeSelector'}).t

	useEffect(() => {
		const activeDayIndex = shifts.findIndex((shift) => {
			return shift.active
		})

		if(activeDayIndex > -1){
			setActiveShiftDayIndex(activeDayIndex)
			setActiveShiftDay([...shifts[activeDayIndex].shift])
		
		} else {
			setActiveShiftDayIndex(0)
			setActiveShiftDay([...shifts[0].shift])
		}

	}, [shifts])

	useEffect(() => {
		if(activeShiftDayIndex !== undefined){
			for(let i=0; i<shifts.length; i++){
				shifts[i].active = false
			}

			shifts[activeShiftDayIndex].active = true

			setShifts([...shifts])
		}

	}, [activeShiftDayIndex])

	return (
		<div className="shift-selector">
			<div className="shift-selector-days">
				{
					<SwitchButtons
						activeSwitchButtonId={activeShiftDayIndex}
						setActiveSwitchButtonId={(value) => { setActiveShiftDayIndex(value) }}
						switchButtons={
							shifts.map((shift, index) => {
								return (
									{
										id: index,
										text: t(shift.day)
									}
								)
							})
						}
					/>
				}
			</div>
			<div className="shift-selector-time">
				<div className="shift-selector-time-dial">
					<Clock
						timeStamps={activeShiftDay}
						clearShifts={()=> {
							const activeDayIndex = shifts.findIndex((shift) => {
								return shift.active
							})
							shifts[activeDayIndex!].shift = []
							setShifts([...shifts], true)
						}}
					/>
				</div>
				<div className="shift-selector-time-input">
					<div className="shift-selector-time-input-container">
						<TimeInput setValue={ (value) => {setStart(value)}} value={start} placeholder={tTime('startTime')} />
						<TimeInput setValue={ (value) => {setEnd(value)}} value={end} placeholder={tTime('endTime')}/>
					</div>
					<Button active={true} icon={plusIcon} onClick={() => {
						if(start > end) return

						const activeDayIndex = shifts.findIndex((shift) => {
							return shift.active
						})
						
						if(activeDayIndex > -1){
							shifts[activeDayIndex].shift.push({start: start, end: end})

							setShifts([...shifts], true)
						}
						
					}} />
				</div>
			</div>
		</div>
	)
}

export default TimeSelector