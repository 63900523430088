//styles
import './taskPreviewCard.scss'

//react
import { FC } from 'react'

//types
import { task } from '../../../../../types/general/generalTypes'

//other
import {
	formatDate,
	hexToRgb,
} from '../../../../../assets/general/generalFunctions'

type taskPreviewCardProps = task<Date | undefined>

const TaskPreviewCard: FC<taskPreviewCardProps> = ({
	title,
	description,
	startDate,
	endDate,
	status
}) => {
	const rgb = hexToRgb(status.color)

	return (
		<div
			className={`task-preview-card-container ${
				!(startDate && endDate) ? 'hiddenTask' : ''
			}`}
			style={{
				color: status.color,
				background: `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.2)`,
				borderLeft: `0.69vw solid ${status.color}`,
			}}
		>
			<p className="task-preview-card-title">{title}</p>
			<p className="task-preview-description">{description}</p>
			<p className="task-preview-time">
				{startDate && endDate
					? `${formatDate(startDate, true, true)} - ${formatDate(endDate, true, true)}`
					: ''}
			</p>
		</div>
	)
}

export default TaskPreviewCard
