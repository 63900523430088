//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { averagePurchaseAmount, procureToReceiveTime, purchasePriceDifference, totalROIPurchases } from '../../../../utils/urls/purchases/providerStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const ShoppingEfficiency = () => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.purchaseStats.shoppingEfficiencyTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadAveragePurchaseAmount = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(averagePurchaseAmount(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadPurchasePriceDifference = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(purchasePriceDifference(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadProcureToReceiveTime = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(procureToReceiveTime(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadTotalROIPurchases = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(totalROIPurchases(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('average_purchase_amount')} loadData={loadAveragePurchaseAmount} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('purchase_price_difference')} loadData={loadPurchasePriceDifference} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('procure_to_receive_time_data')} loadData={loadProcureToReceiveTime} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('total_roi_purchases')} loadData={loadTotalROIPurchases} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default ShoppingEfficiency