//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { revenueAnalysis, operatingProfitAnalysis, costOfOperationsAnalysis, grossProfitAnalysis, netIncomeAnalysis, operatingExpensesAnalysis } from '../../../../utils/urls/finance/financeStats/financeStats'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const RevenueAnalysis = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.financeStats.financialPerfomanceMetrics' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadRevenueStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(revenueAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadOperatingProfitAnalysisStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(operatingProfitAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadCostOfOperationsAnalysisStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(costOfOperationsAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadGrossProfitAnalysisStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(grossProfitAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)

	const loadNetIncomeAnalysisStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(netIncomeAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadOperatingExpensesAnalysisStatistics = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => formatGraphResponse(operatingExpensesAnalysis(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('revenue')} loadData={loadRevenueStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('costOfOperations')} loadData={loadCostOfOperationsAnalysisStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('operatingExpenses')} loadData={loadOperatingExpensesAnalysisStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('grossProfit')} loadData={loadGrossProfitAnalysisStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('operatingProfit')} loadData={loadOperatingProfitAnalysisStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('netIncome')} loadData={loadNetIncomeAnalysisStatistics} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default RevenueAnalysis