//react
import { useEffect, useState } from 'react'

//components
import ConfirmationModal from '../../../../modals/confirmationModal/confirmationModal'

//context
import useChatDesktop from '../../context/chatDesktopContext'

//types
import { messageTypes } from '../../../../../../types/employees/chatTypes'

//nextwork
import { authorizedRequest } from '../../../../../../utils/queries'
import { deleteChatMessageUrl } from '../../../../../../utils/old_urls/employees/chatsUrls'

//translation
import { useTranslation } from 'react-i18next'

const DeleteMessageModal = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.modals.deleteMessageModal' })

	const { deleteMessage, setDeleteModalIsOpen, setDeletedMessageId, messages, chatInfo } = useChatDesktop()

	const [ title, setTitle ]= useState('')
	const [ confirmationButtons, setConfirmationButtons ] = useState<{text: string, onClickHandler: () => void}[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const deleteMessageHandler = (messageId: number, forMe: boolean) => {
		if(chatInfo.data.chatId){
			setLoading(true)
			authorizedRequest(deleteChatMessageUrl(chatInfo.data.chatId), 'DELETE', 'accessToken', {
				message_ids: [messageId],
				delete_for_me: forMe
			})
				.then((response) => {

					const messageElem = document.querySelector(`.chat-message[data-message-id="${deleteMessage.messageId}"]`)?.closest('.chat-message-container')

					if(messageElem){
						messageElem.remove()
					}

					setLoading(false)
					setDeleteModalIsOpen(false)
					setDeletedMessageId(null)
					
				})
		}
	}

	useEffect(() => {
		const messageObj = messages.messages.find((message) => message.messageId === deleteMessage.messageId)

		if(messageObj && deleteMessage.messageId){
			if(messageObj.type === messageTypes.USER){
				setTitle(t('selectAnOption'))
				setConfirmationButtons([
					{
						text: t('forMe'),
						onClickHandler: () => {
							deleteMessageHandler(messageObj.messageId, true)
						}
					},
					{
						text: t('forEveryone'),
						onClickHandler: () => {
							deleteMessageHandler(messageObj.messageId, false)
						
						}
					},
					{
						text: t('cancel'),
						onClickHandler: () => {
							setDeleteModalIsOpen(false)
							setDeletedMessageId(null)
						}
					}
				])
			}else{
				setTitle(t('deleteForYou'))
				setConfirmationButtons([
					{
						text: t('yes'),
						onClickHandler: () => {
							deleteMessageHandler(messageObj.messageId, true)
						}
					},
					{
						text: t('cancel'),
						onClickHandler: () => {
							setDeleteModalIsOpen(false)
							setDeletedMessageId(null)
						}
					}
				])
			}
		}

	}, [deleteMessage.modalIsOpen])


	const closeModal = () => {
		setDeleteModalIsOpen(false)
		setDeletedMessageId(null)
	}

	return (
		<ConfirmationModal
			title={title}
			closeModal={closeModal}
			isOpen={deleteMessage.modalIsOpen}
			buttons={confirmationButtons}
			loading={loading}
		/>
	)
}

export default DeleteMessageModal