// react
import { FC, useEffect, useRef } from 'react'

type trackingNodeProps = {
	isConnectable: boolean
	data: {
		element: React.ReactNode
	}
	addNode?: ({ width, height, rank }: { width: number, height: number, rank: string }) => void
	rank?: string
}

const MockNode: FC<trackingNodeProps> = ({ data, addNode, rank }) => {
	const ref = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (ref.current && addNode) {
			addNode({ width: ref.current.clientWidth, height: ref.current.clientHeight, rank: rank || '' })
		}
	}, [ref.current])
	return (
		<div ref={ref} className='tree-node'>
			{data.element}
		</div>
	)
}

export default MockNode