// react
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import OrderDetails from '../../../components/general/order/orderDetails/orderDetails'
import OrderTracking from '../../../components/general/order/orderTracking/orderTracking'

// types
import { button, error } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'
import ErrorList from '../../../components/general/errorList/errorList'

const Order: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'order' })

	const [ activeOrderSection, setActiveOrderSection ] = useState('details')
	const [ projectId, setProjectId ] = useState<number>()

	const [ errors, setErrors ] = useState<error[]>([])

	const navigate = useNavigate()

	const orderId = parseInt(useParams().orderId || '-1')

	const orderDetailTabButton: button[] = [
		{
			active: 'details' === activeOrderSection,
			text: t('details'),
			onClick: () => {setActiveOrderSection('details')}
		},
		{
			active: 'tracking' === activeOrderSection,
			text: t('tracking'),
			onClick: () => {setActiveOrderSection('tracking')}
		},
		{
			active: 'errors' === activeOrderSection,
			text: t('errors'),
			onClick: () => {setActiveOrderSection('errors')}
		},
		{
			active: 'project' === activeOrderSection,
			text: t('project'),
			onClick: () => { navigate(`/ri-business/projects/project/${projectId}`) }
		}
	]

	return (
		<Layout header={{
			avatar: true,
			title: t('title', { count: orderId })
		}} tabButtons={orderDetailTabButton} >
			{activeOrderSection === 'details' ? <OrderDetails setErrors={setErrors} setProjectId={(value) => {setProjectId(value)}} /> : null}
			{activeOrderSection === 'tracking' ? <OrderTracking projectId={projectId} /> : null}
			{activeOrderSection === 'errors' ? <ErrorList errors={errors} /> : null}
		</Layout>
	)
}

export default Order