//styles and icons
import './chatHeader.scss'
import { basicUserAvatar, close } from '../../../../../assets/general/generalIcons'
import { groupChatBasicAvatar, chatSearchSvg } from '../../../../../assets/employees/chat/chatIcons'
import {  } from '../../../../../assets/employees/chat/chatIcons'

//react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

//components
import Avatar from '../../../avatar/avatar'
import StatusCircle from '../../../statusCircle/statusCircle'
import Loading from '../../../loading/loading'

//context
import useChatDesktop from '../context/chatDesktopContext'

//types
import { messagesSliderEnum } from '../../../../../types/employees/chatTypes'
import { userStatus } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

const ChatHeader: FC = () => {

	const { chatInfo, setChatInfoSliderIsOpen, messagesSlider, setMessagesSliderIsOpen, setMessagesSliderInputValue, setChatFilesSliderIsOpen } = useChatDesktop()
	
	const { t } = useTranslation('', { keyPrefix: 'general.chats.chatDesktop.chatHeader' })

	const { chatId } = useParams()
	const { projectId } = useParams()

	const openSearchMassageSlider = (e: React.MouseEvent<HTMLDivElement>) =>{
		e.stopPropagation()
		setChatInfoSliderIsOpen(false)
		setChatFilesSliderIsOpen(false)
		setMessagesSliderIsOpen(messagesSliderEnum.searchedMessages)
	}

	const closeMessageSlider  = () => {
		setMessagesSliderIsOpen(messagesSliderEnum.closed)
		setMessagesSliderInputValue('')
	}

	const toggleChatInfoSlider = () =>{
		setChatInfoSliderIsOpen(!chatInfo.sliderIsOpen)
		setChatFilesSliderIsOpen(false)
		closeMessageSlider()
	}


	return (
		<div className="chat-header-container" onClick={(e) => {
			toggleChatInfoSlider()
		}}>
			{
				(chatInfo.data.chatId === +chatId! || projectId) ?
					<div className="chat-info-container">
						<div className="chat-avatar">
							{ chatInfo.data.avatar ? <Avatar blobAvatar={chatInfo.data.avatar} /> : chatInfo.data.group ? groupChatBasicAvatar : basicUserAvatar }
						</div>
						<div className='chat-name-container'>
							<p className='name'>{chatInfo.data.name}</p>
							<div className='status-container'>

								{
									chatInfo.status.typing.length === 0 ?
										!chatInfo.data.group ?
											<>
												<div className="status-circle-container">
													<StatusCircle status={chatInfo.status.online > 0 ? userStatus.ONLINE : userStatus.OFFLINE} />
												</div>
												<p>{chatInfo.status.online > 0 ? t('online') : t('offline')}</p>
											</>
											: 
											<>
												<p>{`${chatInfo.data.chatMembers.length} ${t('members')}.`} {chatInfo.status.online > 0 ? `${chatInfo.status.online} ${t('online')}` : null}</p>
											</>
										:
										<div className='typing-animation-wrapper'>
											<div className='typing-animation-container'>
												<div className='typing-animation'></div>
											</div>
											{chatInfo.status.typing.map((member) => <p>{member}</p>)}
										</div>
								}
							</div>
						</div>
					</div> : <div> <Loading/> </div>
			}
			<div className="additional-tools">
				
				<div className='search-massage-input-container' style={{width: messagesSlider.sliderIsOpen === messagesSliderEnum.closed ? '0' : undefined , padding: messagesSlider.sliderIsOpen === messagesSliderEnum.closed ? '0': undefined}}>
					<input type="text" placeholder={t('common.search')} value={messagesSlider.inputValue} onChange={(e) => { 
						setMessagesSliderInputValue(e.target.value)
					}} className='chat-bar-header-input' onClick={(e)=>{e.stopPropagation()}} />
					<div className='search-massage-input-cross' onClick={(e) => {
						e.stopPropagation()
						closeMessageSlider()
					}}>{close}</div>
				</div>
				<div className="search-massage-button" onClick={(e)=>{openSearchMassageSlider(e)}} style={{
					width: messagesSlider.sliderIsOpen !== messagesSliderEnum.closed ? '0vw' : undefined
				}}>{chatSearchSvg}</div> 
			</div>
		</div>
	)
}

export default ChatHeader
