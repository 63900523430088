//styles and icons
import './navBarSlider.scss'
import {
	employee,
	project,
	sale,
	purchase,
	finance,
	warehouse,
	chat,
	bell,
	navlogo,
} from '../../../../../assets/general/generalIcons'

//react
import { useState, useEffect, FC, useRef } from 'react'

//components
import NavBarSliderItem from './navBarSliderItem/navBarSliderItem'
import NavBarSlide from './navBarSlide/navBarSlide'
import NavBarBurger from '../../../../../components/general/layout/navBar/navBarBurger/navBarBurger'
import Title from '../title/title'
import Subtitle from '../subtitle/subtitle'

//types
import { navBarSliderCaregory, navBarSliderSubCategory, navbarCategory } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type navBarSliderProps = {
	title?: string
	subtitle?: string
}

const NavBarSlider: FC<navBarSliderProps> = ({ title, subtitle }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })

	const [navData, setNavData] = useState<navBarSliderCaregory[]>([])
	const [activeCategory, setActiveCategory] = useState<number>(0)
	const [mobile, setMobile] = useState(screen.width <= 768)
	const [mobileNavbBarOpen, setMobileNavBarOpen] = useState(false)

	const [sliderHeight, setSliderHeight] = useState(0)
	const navBarRef = useRef<HTMLDivElement>(null)

	const handleResize = () => {
		setMobile(screen.width <= 768)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	const [selectedSubCategory, setSelectedSubCategory] = useState<navBarSliderSubCategory>({
		id: 1,
		name: 'hierarchy',
		slides: [
			{ title: t('hierarchy1') },
			{ title: t('hierarchy2') },
			{ title: t('hierarchy3') }
		]
	})

	const navbar: navBarSliderCaregory[] =[
		{
			name: 'employees',
			icon: employee,
			subCategories: [
				{
					id: 1,
					name: 'hierarchy',
					slides: [
						{ title: t('hierarchy1') },
						{ title: t('hierarchy2') },
						{ title: t('hierarchy3') }
					]
				},
				{
					id: 2,
					name: 'searchEmployees',
					slides: [
						{ title: t('searchEmployees1') },
						{ title: t('searchEmployees2') },
						{ title: t('searchEmployees3') },
						{ title: t('searchEmployees4') },
						{ title: t('searchEmployees5') }
					]
				},
				{
					id: 3,
					name: 'statistics',
					slides: [
						{ title: t('statisticsEmployees1') },
						{ title: t('statisticsEmployees2') },
						{ title: t('statisticsEmployees3') },
						{ title: t('statisticsEmployees4') },
						{ title: t('statisticsEmployees5') },
						{ title: t('statisticsEmployees6') },
						{ title: t('statisticsEmployees7') },
						{ title: t('statisticsEmployees8') },
						{ title: t('statisticsEmployees9') }
					]
				}
			]
		},
		{
			name: 'projects',
			icon: project,
			subCategories: [
				{
					id: 4,
					name: 'pipelines',
					slides: [
						{ title: t('pipelines1') },
						{ title: t('pipelines2') },
						{ title: t('pipelines3') },
						{ title: t('pipelines4') },
						{ title: t('pipelines5') },
						{ title: t('pipelines6') },
						{ title: t('pipelines7') },
						{ title: t('pipelines8') }
					]
				},
				{
					id: 5,
					name: 'myTasks',
					slides: [
						{ title: t('myTasks1') },
						{ title: t('myTasks2') }
					]
				},
				{
					id: 6,
					name: 'statistics',
					slides: [
						{ title: t('statisticsProjects1') },
						{ title: t('statisticsProjects2') },
						{ title: t('statisticsProjects3') },
						{ title: t('statisticsProjects4') },
						{ title: t('statisticsProjects5') },
						{ title: t('statisticsProjects6') }
					]
				}
			]
		},
		{
			name: 'purchases',
			icon: purchase,
			subCategories: [
				{
					id: 7,
					name: 'providers',
					slides: [
						{ title: t('providers1') },
						{ title: t('providers2') },
						{ title: t('providers3') }
					]
				},
				{
					id: 8,
					name: 'purchaseOrders',
					slides: [
						{ title: t('purchaseOrders1') },
						{ title: t('purchaseOrders2') },
						{ title: t('purchaseOrders3') }
					]
				},
				{
					id: 9,
					name: 'statistics',
					slides: [
						{ title: t('statisticsPurchases1') },
						{ title: t('statisticsPurchases2') },
						{ title: t('statisticsPurchases3') },
						{ title: t('statisticsPurchases4') },
						{ title: t('statisticsPurchases5') },
						{ title: t('statisticsPurchases6') },
						{ title: t('statisticsPurchases7') },
						{ title: t('statisticsPurchases8') },
						{ title: t('statisticsPurchases9') },
						{ title: t('statisticsPurchases10') },
						{ title: t('statisticsPurchases11') }
					]
				}
			]
		},
		{
			name: 'sales',
			icon: sale,
			subCategories: [
				{
					id: 10,
					name: 'customers',
					slides: [
						{ title: t('customers1') },
						{ title: t('customers2') },
						{ title: t('customers3') }
					]
				},
				{
					id: 11,
					name: 'salesOrders',
					slides: [
						{ title: t('salesOrders1') },
						{ title: t('salesOrders2') },
						{ title: t('salesOrders3') }
					]
				},
				{
					id: 12,
					name: 'offerings',
					slides: [
						{ title: t('offerings1') },
						{ title: t('offerings2') },
						{ title: t('offerings3') }
					]
				},
				{
					id: 13,
					name: 'statistics',
					slides: [
						{ title: t('statisticsSales1') },
						{ title: t('statisticsSales2') },
						{ title: t('statisticsSales3') },
						{ title: t('statisticsSales4') },
						{ title: t('statisticsSales5') },
						{ title: t('statisticsSales6') },
						{ title: t('statisticsSales7') },
						{ title: t('statisticsSales8') },
						{ title: t('statisticsSales9') },
						{ title: t('statisticsSales10') },
						{ title: t('statisticsSales11') },
						{ title: t('statisticsSales12') }
					]
				}
			]
		},
		{
			name: 'finance',
			icon: finance,
			subCategories: [
				{
					id: 14,
					name: 'accounts',
					slides: [
						{ title: t('accounts1') },
						{ title: t('accounts2') }
					]
				},
				{
					id: 15,
					name: 'transactions',
					slides: [
						{ title: t('transactions1') }
					]
				},
				{
					id: 16,
					name: 'bills',
					slides: [
						{ title: t('bills1') },
						{ title: t('bills2') }
					]
				},
				{
					id: 17,
					name: 'budgets',
					slides: [
						{ title: t('budgets1') }
					]
				},
				{
					id: 18,
					name: 'reports',
					slides: [
						{ title: t('reports1') },
						{ title: t('reports2') },
						{ title: t('reports3') }
					]
				},
				{
					id: 19,
					name: 'statistics',
					slides: [
						{ title: t('statisticsFinance1') },
						{ title: t('statisticsFinance2') },
						{ title: t('statisticsFinance3') },
						{ title: t('statisticsFinance4') },
						{ title: t('statisticsFinance5') },
						{ title: t('statisticsFinance6') },
						{ title: t('statisticsFinance7') },
						{ title: t('statisticsFinance8') },
						{ title: t('statisticsFinance9') },
						{ title: t('statisticsFinance10') },
						{ title: t('statisticsFinance11') },
						{ title: t('statisticsFinance12') },
						{ title: t('statisticsFinance13') }
					]
				}
			]
		},
		{
			name: 'warehouses',
			icon: warehouse,
			subCategories: [
				{
					id: 20,
					name: 'warehouseList',
					slides: [
						{ title: t('warehouseList1') },
						{ title: t('warehouseList2') },
						{ title: t('warehouseList3') }
					]
				},
				{
					id: 21,
					name: 'inventory',
					slides: [
						{ title: t('inventory1') },
						{ title: t('inventory2') }
					]
				},
				{
					id: 22,
					name: 'shipments',
					slides: [
						{ title: t('shipments1') }
					]
				},
				{
					id: 23,
					name: 'movingList',
					slides: [
						{ title: t('movingList1') }
					]
				},
				{
					id: 24,
					name: 'statistics',
					slides: [
						{ title: t('statisticsWarehouses1') },
						{ title: t('statisticsWarehouses2') },
						{ title: t('statisticsWarehouses3') },
						{ title: t('statisticsWarehouses4') },
						{ title: t('statisticsWarehouses5') },
						{ title: t('statisticsWarehouses6') }
					]
				}
			]
		}
	]


	const chats: navBarSliderSubCategory = 
		{
			name: 'chats',
			id: 25,
			slides: [
				{title: t('chat1')},
				{title: t('chat2')},
				{title: t('chat3')}
			]
		}

	const notifications: navBarSliderSubCategory = 
		{
			name: 'notifications',
			id: 26,
			slides: [
				{title: t('notifications1')}
			]
		}

	const handleNavItemClick = (subCategory: navBarSliderSubCategory)=>{
		setSelectedSubCategory(subCategory)
		setActiveCategory(0)
	}

	useEffect(()=>{
		if (!mobileNavbBarOpen) {
			setSliderHeight(0)
		}else if (navBarRef.current) {
			setSliderHeight(navBarRef.current.clientHeight)
		}
	}, [mobileNavbBarOpen, activeCategory, selectedSubCategory])

	// console.log(sliderHeight)

	useEffect(() => {
		setNavData(navbar)
	}, [])

	return (
		<div className="navbar-slider-wrapper">
			{ title && <Title>{title}</Title> }
			{ subtitle && <Subtitle>{subtitle}</Subtitle> }
			<div className={`navbar-slider ${mobile ? 'mobile' : ''} ${mobileNavbBarOpen ? 'open' : ''}`}>
				{mobile && <NavBarBurger open={mobileNavbBarOpen} setOpen={(value) => { setMobileNavBarOpen(value) }} />}
				<div className="navbar-wrapper" ref={navBarRef}>
					<div className="navbar-container">
						<div className="navbar-header">
							<span className="navbar-company-avatar-container">{navlogo}</span>
							<p className="navbar-head-text">{t('yourCompany')}</p>
						</div>
						<div className="navbar-content">
							{navData.map((item, i) => {
								return (
									<NavBarSliderItem
										key={i}
										icon={item.icon}
										name={item.name}
										subCategories={item.subCategories}
										index={i + 1}
										activeCategory={activeCategory}
										setActiveCategory={setActiveCategory}
										selectedSubCategory={selectedSubCategory}
										setSelectedSubCategory={setSelectedSubCategory}
									/>
								)
							})}
						</div>
					</div>
					<div className="navbar-bottom" >
						<div className="misc-nav">
							<div className={`nav-button ${selectedSubCategory.name == 'chats'  ? 'selected' : ''}`} 
								onClick={()=>handleNavItemClick(chats)}>
								<div className="icon">
									{chat}
								</div>
								{t('chats')}
							</div>
							<div className={`nav-button ${selectedSubCategory.name == 'notifications'  ? 'selected' : ''}`}  
								onClick={()=> handleNavItemClick(notifications)}>
								<div className="icon">
									{bell}
								</div>
								{t('notifications')}
							</div>
						</div>
					</div>
				</div>
				<NavBarSlide slides={selectedSubCategory.slides!} sliderHeight={sliderHeight}/>
			</div>
		</div>
	)
}

export default NavBarSlider
