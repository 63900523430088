//styles
import './emailList.scss'
import { deleteIcon } from '../../../../../../assets/general/generalIcons'

//react
import { useState, useEffect, FC } from 'react'

//components
import ColumnLayout from '../../../../columnLayout/columnLayout'
import CounterpartyCard from '../../../../counterpartyCard/counterpartyCard'
import FilterBar from '../../../../layout/filterBar/filterBar'
import CreateEmailModal from './modals/createEmailModal/createEmailModal'
import DeleteEmailModal from './modals/deleteEmailModal/deleteEmailModal'

//redux
import { useAppDispatch } from '../../../../../../customHooks/redux'
import { setCreateEmailModalIsOpen, setDeleteEmailId, setDeleteEmailModalIsOpen } from '../../../../../../redux/general/settings/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { getItemsPerColumn } from '../../../../../../assets/general/generalFunctions'

//types
import { email } from '../../../../../../types/general/generalTypes'

type emailListProps = {
    emails: email[]
	setEmails: ( value: email[] ) => void
}

const EmailList: FC<emailListProps> = ({ emails, setEmails }) => {
	const [ itemsPerColumn, setItemsPerColumn ] = useState(getItemsPerColumn(3))

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection'})

	const dispatch = useAppDispatch()

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<div className="email-list">
			<h2 className='email-heading'>{t('emailList')}</h2>
			<FilterBar 
				addButton={{
					text: t('add'),
					active: true,
					onClick: ()=> {
						dispatch(setCreateEmailModalIsOpen(true))
					}
				}}
			/>
			<ColumnLayout amount={itemsPerColumn}>
				{emails.map(email => 
					<CounterpartyCard 
						name={email.email} 
						actions={[{id: 1, icon: deleteIcon, onClick: () => {
							dispatch(setDeleteEmailModalIsOpen(true))
							dispatch(setDeleteEmailId(email.id))
						}}]}
					/>
				)}
			</ColumnLayout>
			<CreateEmailModal emails={emails} setEmails={setEmails} />
			<DeleteEmailModal emails={emails} setEmails={setEmails} />
		</div>
	)

}

export default EmailList