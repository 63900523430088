//styles
import './orderItemSkeleton.scss'

//components
import Loading from '../../loading/loading'

const OrderItemSkeleton = () => {
	return (
		<div className="order-item-skeleton-container">
			<Loading style={{ position: 'relative', top: '0', left: '0' }} />
		</div>
	)
}

export default OrderItemSkeleton