// react 
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useParams } from 'react-router'

// components
import CreateOrderModal from '../../../../../general/modals/createOrderModal/createOrderModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setCreateProviderOrderModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { createPurchaseOrderUrl } from '../../../../../../utils/old_urls/purchase/generalUrls'
import { singleProviderOfferingsUrl } from '../../../../../../utils/urls/purchases/provider'
import { pipelinesManagePipelineResourceUrl } from '../../../../../../utils/urls/projects/pipelines'
import { companyWarehousesUrl } from '../../../../../../utils/urls/warehouses/warehouses/warehouses'

// types
import {
	createOrderErrors,
	dropdownOption,
	infoPopupTypes,
	offering,
	order,
	warehouseResponse
} from '../../../../../../types/general/generalTypes'
import { pipelineResponse } from '../../../../../../types/projects/pipelineTypes'

// other
import { formatOfferingResponse, formatOrderResponse } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'


type createProviderOrderModalProps  = {
	orders: order[]
	setOrders: Dispatch<SetStateAction<order[]>>
}

const CreateProviderOrderModal: FC<createProviderOrderModalProps> = ({orders, setOrders}) => {
	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerOrders.createProviderOrderModal)
	const { userCompanyData, language } = useAppSelector((state) => state.general)

	const { t } = useTranslation('', { keyPrefix: 'general.modals.createOrderModal' })

	const companyId: number = userCompanyData?.companyId || -1

	const {relationshipId} = useParams()

	const [selectedPipelineOption, setSelectedPipelineOption] = useState<dropdownOption | null>(null)
	const [selectedDestinationOption, setSelectedDestinationOption] = useState<dropdownOption | null>(null)

	const [errors, setErrors] = useState<createOrderErrors>({})

	const closeModal = () => {
		dispatch(setCreateProviderOrderModalIsOpen(false))
	}

	const destinationPopup = {
		content: [
			{
				title: 'create_destination_info_title',
				description: 'create_destination_info_description',
				link: '/ri-business/warehouse/warehouse-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const pipelinePopup = {
		content: [
			{
				title: 'create_pipeline_info_title',
				description: 'create_pipeline_info_description',
				link: '/ri-business/projects/pipelines',
			}
		],
		type: infoPopupTypes.INFO,
	}


	const loadOfferings = async (searchQuery: string, page: number) => {
		const {result} = await authorizedRequest(singleProviderOfferingsUrl(Number(relationshipId)) + `?needle=${searchQuery}&page=${page}`, 'GET')

		if(result && result.length > 0) {
			const offeringData: offering[] = result.map(formatOfferingResponse)
			return offeringData
		}
		return []
	}

	const loadDestinationOptions = async () => {
		const { result } = await authorizedRequest(companyWarehousesUrl(companyId), 'GET')

		return result.map((warehouse: warehouseResponse) => {
			return {
				title: warehouse.name,
				key: `${warehouse.id}`
			}
		})
	}

	const loadPipelinesOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId) + `?page=${page}&per_page=${10}&needle=${search}`, 'GET')

		return result.map((pipeline: pipelineResponse) => {
			return {
				title: pipeline.name,
				key: `${pipeline.id}`
			}
		})
	}

	const checkErrors = () => {
		let hasErrors = false

		if (!selectedPipelineOption) {
			hasErrors = true
			setErrors({ ...errors, pipeline: 'pleaseSelectPipeline' })
		}


		if(!selectedDestinationOption) {
			hasErrors = true
			setErrors({...errors, destination: 'pleaseSelectDestination'})
		}
		return hasErrors
	}

	const addOrder = async (item: offering[]) => {

		const hasErrors = checkErrors()
		if (hasErrors) return

		if (selectedPipelineOption && relationshipId && selectedDestinationOption) {

			const offerings = item.map((offering) => {
				return {
					offering_id: offering.offeringId,
					offering_quantity: offering.quantity
				}
			})

			const body = {
				language: language,
				offerings: offerings,
				address_id: null,
				warehouse_id: Number(selectedDestinationOption.key),
				pipeline_id: Number(selectedPipelineOption.key)
			}


			const {result} = await authorizedRequest(createPurchaseOrderUrl(Number(relationshipId)), 'POST', 'accessToken', body)
			if (result) {
				const formatedOrder: order = formatOrderResponse(result)
				setOrders([formatedOrder, ...orders])
			}
			closeModal()
		}
	}

	return (
		<CreateOrderModal 
			isOpen={modalIsOpen} 
			closeModal={closeModal}
			loadItems={loadOfferings} 
			createOrder={addOrder}
			hasErrors={checkErrors}
			relationshipId={Number(relationshipId)}
			dropdowns={[
				{
					label: t('destination'),
					popup: destinationPopup,
					loadOptions: loadDestinationOptions,
					selectedOption: selectedDestinationOption,
					onSelect:({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedDestinationOption(null)
						setSelectedDestinationOption({ key, title, avatar })
					},
					dropdownOptions:[],
					placeholder: 'selectDestination',
					error: errors.destination ? errors.destination : undefined
				},
				{
					popup: pipelinePopup,
					label: t('pipeline'),
					loadOptions: loadPipelinesOptions,
					selectedOption: selectedPipelineOption,
					onSelect:({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedPipelineOption(null)
						setSelectedPipelineOption({ key, title, avatar })
					},
					dropdownOptions:[],
					placeholder: 'selectPipeline',
					error: errors.pipeline ? errors.pipeline : undefined
				}
			]}
		/>
	)
}

export default CreateProviderOrderModal