// react
import { FC, useState } from 'react'

// components
import ConfirmationModal from '../../../../../../modals/confirmationModal/confirmationModal'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../../../../customHooks/redux'
import { setDeletePhoneNumberId, setDeletePhoneNumberModalIsOpen } from '../../../../../../../../redux/general/settings/modals'

// translation
import { useTranslation } from 'react-i18next'

// network
import { authorizedRequest } from '../../../../../../../../utils/queries' 
import { deletePhoneNumberUrl } from '../../../../../../../../utils/old_urls/general/generalUrls'

//types
import { phoneNumber } from '../../../../../../../../types/general/generalTypes'

type deletePhoneNumberModalProps = {
	phoneNumbers: phoneNumber[]
	setPhoneNumbers: (value: phoneNumber[]) => void
}
const DeletePhoneNumberModal: FC<deletePhoneNumberModalProps> = ({phoneNumbers, setPhoneNumbers}) => {
	const {modalIsOpen, phoneNumberId} = useAppSelector(state => state.settingsModals.deletePhoneNumber)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()
	const {t} = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.deletePhoneNumberModal'})

	const closeModal = () => {
		dispatch(setDeletePhoneNumberModalIsOpen(false))
		dispatch(setDeletePhoneNumberId(null))
	}

	const deletePosition = () => {
		setLoading(true)
		authorizedRequest(deletePhoneNumberUrl(phoneNumberId!), 'DELETE')
			.then(response => {
				setPhoneNumbers(phoneNumbers.filter(number => number.id !== phoneNumberId))
				setLoading(false)
				closeModal()
			})	
	}
	return (
		<ConfirmationModal
			title={t('title')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deletePosition }, { text: t('no'), onClickHandler: closeModal } ]}
			loading={loading}
		/>
	)
}

export default DeletePhoneNumberModal