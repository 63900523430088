// styles
import './providerList.scss'
import { chat, deleteIcon, navlogo } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// network
import { authorizedRequest } from '../../../utils/queries'
import { companyProviderFiltersUrl, companyProvidersUrl } from '../../../utils/urls/purchases/provider'

// types
import { provider, providerResponse } from '../../../types/purchase/providerTypes'
import { filterBar, filters, infoPopupTypes } from '../../../types/general/generalTypes'

// components
import Layout from '../../../components/general/layout/layout'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Pagination from '../../../components/general/pagination/pagination'
import CounterpartyCard from '../../../components/general/counterpartyCard/counterpartyCard'
import CreateProviderModal from '../../../components/purchase/providerList/modals/createProviderModal/createProviderModal'
import DeleteProviderConfirmationModal from '../../../components/purchase/providerList/modals/deleteProviderConfirmationModal/deleteProviderConfirmationModal'
import ImportProviderFileSelectorModal from '../../../components/purchase/providerList/modals/importProviderFileSelectorModal/importProviderFileSelectorModal'
import ProviderTablePreviewModal from '../../../components/purchase/providerList/modals/providerTablePreviewModal/providerTablePreviewModal'
import ExportProviderFileModal from '../../../components/purchase/providerList/modals/exportProviderFileModal/exportProviderFileModal'
import CounterpartySkeleton from '../../../components/general/skeletons/counterParty/counterPartySkeleton'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateProviderModalIsOpen, setDeleteProviderModalIsOpen, setDeleteProviderRelationshipId, setImportProviderFileSelectorModalIsOpen, setExportProviderFileModalIsOpen } from '../../../redux/purchase/providersList/modals'

//other
import { createFilteringRequest, formatFilters, formatTags, getItemsPerColumn } from '../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

//hooks
import { useNavigate } from 'react-router-dom'

const ProviderList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.providerList' })
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })
	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [providerList, setProviderList] = useState<provider[]>([])
	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const titleUrls = [
		{
			url: '/ri-business/purchase/provider-list',
			title: t('providers')
		}
	]

	const infoPopup = {
		content: [{
			title: 'provider_list_info_title',
			description: 'provider_list_info_description',
			link: '/ri-manual/purchases'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyProviderFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access
			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportProviderFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportProviderFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreateProviderModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})

		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyProvidersUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedProviders: provider[] = result.map((provider: providerResponse) => {
					return {
						id: provider.relationship_id,
						relationshipId: provider.relationship_id,
						description: provider.description,
						name: provider.name,
						avatar: provider.avatar,
						labels: provider.labels,
						deleteAccess: provider.delete_access
					}
				})
				return formatedProviders
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setProviderList([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const deleteProvider = (provider: number) => {
		dispatch(setDeleteProviderModalIsOpen(true))
		dispatch(setDeleteProviderRelationshipId(provider))
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setProviderList([...providerList, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}).finally(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Layout
			header={{
				avatar: true
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="providers-list">
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn}>
						{
							providerList.map(provider => {
								return (
									<CounterpartyCard
										key={`provider-${provider.id}`}
										{...provider}
										placeholder={navlogo}
										link={`/ri-business/purchase/provider/${provider.id}`}
										tags={provider.labels.map(e => ({ ...e, value: formatTags(e.value, tCurrency) }))}
										actions={provider.deleteAccess ? [
											{ 
												id: 2, icon: deleteIcon, onClick: () => deleteProvider(provider.relationshipId!)
											}]: []}
									/>
								)
							})
						}
						{
							loading && <CounterpartySkeleton />
						}
					</ColumnLayout>
				</Pagination>

			</div>

			<CreateProviderModal providerList={providerList} setProviderList={setProviderList} />
			<DeleteProviderConfirmationModal providerList={providerList} setProviderList={setProviderList} />
			<ImportProviderFileSelectorModal />
			<ProviderTablePreviewModal providerList={providerList} setProviderList={setProviderList} />
			<ExportProviderFileModal />
		</Layout>
	)
}

export default ProviderList