// images
import accounts1En from '../../../../assets/manual/finance/en/accaountListEn.png'
import accounts2En from '../../../../assets/manual/finance/en/addNewAccountEn.png'
import accounts3En from '../../../../assets/manual/finance/en/accountsDetailsEn.png'
import accounts1Ua from '../../../../assets/manual/finance/ua/accaountListUa.png'
import accounts2Ua from '../../../../assets/manual/finance/ua/addNewAccountUa.png'
import accounts3Ua from '../../../../assets/manual/finance/ua/accountsDetailsUa.png'
import transactions1En from '../../../../assets/manual/finance/en/transactionsEn.png'
import transactions3En from '../../../../assets/manual/finance/en/addNewTransactionEn.png'
import transactions1Ua from '../../../../assets/manual/finance/ua/transactionsUa.png'
import transactions3Ua from '../../../../assets/manual/finance/ua/addNewTransactionUa.png'


// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

const AccountManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.finance.accounts' })
	const { language } = useAppSelector(state => state.general)
	const data: manualData[] = [
		{
			title: t('accountsCreateTitle'),
			text: t('accountsCreateText')
		},
		{
			title: t('accountsDetailsTitle'),
			text: t('accountsDetailsText')
		},
		{
			title: t('accountsTransactionTitle'),
			text: t('accountsTransactionText')
		},
		{
			title: t('accountsCreateTransactionTitle'),
			text: t('accountsCreateTransactionText')
		},
	]
	
	return (
		<ManualLayout 
			heading={t('title')}
			data={data}
		/>
	)
}

export default AccountManual
