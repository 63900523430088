//components
import ChartContainer from '../../../general/chart/chartContainer/chartContainer'

// network
import { averageProjectProfitability,projectProductivity } from '../../../../utils/urls/projects/projectStat'

//types
import { chartViewOptions, chartDurationOptions } from '../../../../types/general/generalTypes'

// other
import { formatGraphResponse } from '../../../../assets/general/generalFunctions'

// redux
import { useAppSelector } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'

const PerformanceAndEfficiency = () => {
	const { t } = useTranslation('', { keyPrefix: 'projects.projectStat.performanceAndEfficiencyTab' })

	const { userCompanyData } = useAppSelector(state => state.general)

	const loadProjectProductivity = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(projectProductivity(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
	
	const loadAverageProjectProfitability = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => 
		formatGraphResponse(averageProjectProfitability(userCompanyData?.companyId || -1), startDate, endDate, duration, t)
			
	return (
		<div className='statistics-container'>
			<div className='chart-group'>
				<ChartContainer title={t('projectProductivity')} loadData={loadProjectProductivity} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
			<div className='chart-group'>
				<ChartContainer title={t('average_profitability')} loadData={loadAverageProjectProfitability} 
					viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
			</div>
		</div>

	)
}

export default PerformanceAndEfficiency