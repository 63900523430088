// styles and icons
import './transactionList.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import Table from '../../../components/general/table/table'
import ChartContainer from '../../../components/general/chart/chartContainer/chartContainer'
import TransactionTablePreviewModal from '../../../components/finance/general/modals/transaction/transactionTablePreviewModal/transactionTablePreviewModal'
import ExportTransactionFileModal from '../../../components/finance/general/modals/transaction/exportTransactionFileModal/exportTransactionFileModal'
import ImportTransactionFileSelectorModal from '../../../components/finance/general/modals/transaction/importTransactionFileSelectorModal/importTransactionFileSelectorModal'
import CreateTransactionModal from '../../../components/finance/general/modals/transaction/createTransactionModal/createTransactionModal'

// network
import { authorizedRequest } from '../../../utils/queries'
import { singleAccountTransactionFiltersUrl } from '../../../utils/urls/finance/account/account'
import { companyTransactionFiltersUrl, companyTransactionOverviewUrl, companyTransactionsUrl } from '../../../utils/urls/finance/transactions/transaction'

// types
import {
	filterBar,
	filters,
	chartViewOptions,
	chartDurationOptions,
	infoPopupTypes
} from '../../../types/general/generalTypes'
import { transaction, transactionResponse } from '../../../types/finance/general'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setExportTransactionFileModalIsOpen, setCreateTransactionModalIsOpen, setImportTransactionFileSelectorModalIsOpen, setDeleteTransactionId, setDeleteTransactionModalIsOpen } from '../../../redux/finance/general/modals'

// other
import { formatFilters, createFilteringRequest, formatGraphResponse } from '../../../assets/general/generalFunctions'
import { formatTransactionTableData } from '../../../assets/finance/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const TransactionList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.transactionList' })
	const tTransactionType = useTranslation('', { keyPrefix: 'general.tansactionTypes' }).t
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const [transactions, setTransactions] = useState<transaction[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/finance/transaction-list',
			title: t('transactions')
		}
	]

	const infoPopup = {
		content: [{
			title: 'transaction_list_info_title',
			description: 'transaction_list_info_description',
			link: '/ri-manual/finance/transactions'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyTransactionFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportTransactionFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportTransactionFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => dispatch(setCreateTransactionModalIsOpen(true))
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(companyTransactionsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

		if (result.length > 0) {
			const formatedTransactions: transaction[] = result.map((transaction: transactionResponse) => {
				return {
					id: transaction.id,
					accountId: transaction.account_id,
					amount: transaction.amount,
					currency: transaction.currency,
					transactionType: transaction.transaction_type,
					description: transaction.transaction_type,
					date: new Date(transaction.date * 1000)
				}
			})

			return formatedTransactions
		}

		return []

	}

	const loadOverview = async (startDate: Date, endDate: Date, duration: chartDurationOptions) => {
		return formatGraphResponse(companyTransactionOverviewUrl(companyId), startDate, endDate, duration, tTransactionType)
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				setTransactions([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			}).finally(() => {
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setTransactions([...transactions, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}).finally(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])

	const handleDelete = (transactionId: number) => {
		dispatch(setDeleteTransactionId(transactionId))
		dispatch(setDeleteTransactionModalIsOpen(true))
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='transaction-list'>
				<div className='transaction-list-chart'>
					<div className='chart-group'>
						<ChartContainer title={t('transactions')} loadData={loadOverview} viewOptions={[chartViewOptions.BAR, chartViewOptions.PIE, chartViewOptions.LINEAR]} className='chart-full-width' />
					</div>
				</div>
				<div className='transaction-list-table'>
					<Pagination onLoadMore={handleLoadMore} showLoader={false}>
						<Table
							columns={[
								{ key: 'icon', title: '' },
								{ key: 'description', title: t('description') },
								{ key: 'amount', title: t('total') },
								{ key: 'date', title: t('date') },
								{ key: 'transaction_type', title: t('type') }
							]}
							data={formatTransactionTableData(transactions, handleDelete)}
						/>
					</Pagination>
				</div>
			</div>
			<CreateTransactionModal transactions={transactions} setTransactions={setTransactions} />
			<TransactionTablePreviewModal />
			<ImportTransactionFileSelectorModal />
			<ExportTransactionFileModal />
		</Layout>
	)
}

export default TransactionList