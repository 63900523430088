import { baseUrl } from '../../network'

export const taskFinanceKPIResourceUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/tasks/finance`
export const projectCostAndBudgetStatisticsUrl = (projectId: number)=> baseUrl + `/api/projects/${projectId}/cost`
export const employeeServiceStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/service`
export const employeeProductivityStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/productivity`
export const employeeFinanceStatisticsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/employees/finance`
export const costAndBudgetAnalysisUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}/project/cost`

export const projectProductivity = (companyId: number)=> baseUrl + `/api/company/${companyId}/project/productivity`
export const averageProjectProfitability = (companyId: number)=> baseUrl + `/api/company/${companyId}/project/profitability`
export const successFailureDynamics = (companyId: number)=> baseUrl + `/api/company/${companyId}/project/success_failure`
export const efficiencyDynamics = (companyId: number)=> baseUrl + `/api/company/${companyId}/project/efficiency`

