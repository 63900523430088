import { baseUrl } from '../../network'

export const singleEmployeeUrl = (employeeId: number) => baseUrl + `/api/employee/${employeeId}`

export const singleEmployeeProjectFiltersUrl = (employeeId: number) => baseUrl + `/api/employee/${employeeId}/projects/filters`
export const singleEmployeeProjectsUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/projects`

export const singleEmployeeTaskFiltersUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/tasks/filters`
export const singleEmployeeTasksUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/tasks`
export const singleEmployeeTaskCalendarUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/tasks/calendar`

export const singleEmployeeFileFiltersUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/files/filters`
export const singleEmployeeFilesUrl = (employeeId: number)=> baseUrl + `/api/employee/${employeeId}/files`
export const singleEmployeeFileUrl = (fileId: number)=> baseUrl + `/api/employee/file/${fileId}`
