//react
import { FC, useState } from 'react'
import {useNavigate} from 'react-router'

//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

//redux
import {useAppDispatch, useAppSelector} from '../../../../../customHooks/redux'
import {setResignEmployeeModalIsOpen} from '../../../../../redux/employees/general/modals'
import {setUserCompanyData} from '../../../../../redux/general/general'

//network
import {hierarchyResignUrl} from '../../../../../utils/urls/employees/hierarchy'
import {authorizedRequest} from '../../../../../utils/queries'

//translation
import { useTranslation } from 'react-i18next'

const ResignEmployee: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'employees.general.modals.resignEmployee' })

	const { resignEmployee } = useAppSelector((state) => state.employeesGeneralModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const [loading, setLoading] = useState<boolean>(false)
	
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const companyId: number = userCompanyData?.companyId || -1
    
	const closeModal = () => {
		dispatch(setResignEmployeeModalIsOpen(false))
	}

	const fireWorker = async () => {
		setLoading(true)
		await authorizedRequest(hierarchyResignUrl(companyId), 'DELETE')

		setLoading(false)
		dispatch(setUserCompanyData(undefined))
		navigate('/ri-business')
	}
    
	return (
		<ConfirmationModal 
			title={t('resignThisEmployee')} 
			buttons={[{ text: t('yes'), onClickHandler: fireWorker }, { text: t('no'), onClickHandler: closeModal }]}
			isOpen={resignEmployee.modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default ResignEmployee
