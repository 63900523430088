import Loading from '../../loading/loading'
import './counterPartySkeleton.scss'

const CounterpartySkeleton = () => {

	return (
		<div className='counterparty-skeleton'>
			<Loading style={{ position: 'relative', top: '0' }} />
		</div>
	)
}

export default CounterpartySkeleton