//styles
import './pipelineStageEditor.scss'
import { close } from '../../../../../../assets/general/generalIcons'

//react 
import { Dispatch, FC, KeyboardEvent, SetStateAction } from 'react'

//components
import DropStageArea from '../../../../../general/dragAndDropHandler/dropStageArea/dropStage'
import Draggable from '../../../../../general/dragAndDropHandler/draggable/draggable'
import InputField from '../../../../../general/inputField/inputField'
import Button from '../../../../../general/button/button'

//context
import { DragAndDropHandlerProvider } from '../../../../../general/dragAndDropHandler/context/dragAndDropHandlerContext'

//types
import { dragLocation } from '../../../../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type pipelineStagesEditorProps = {
    stages: string[],
    setStages: Dispatch<SetStateAction<string[]>>
}

const PipelineStageEditor: FC<pipelineStagesEditorProps> = ({ stages, setStages }) => {
	const { t } = useTranslation('', {keyPrefix: 'projects.pipelineList.modals.createPipelineModal.pipelineStageEditor'}) 
	const moveStage = (dragInfo: dragLocation, dropInfo: dragLocation) => {
		
		setStages((prevStages)=> {
			let updatedStages = [...prevStages]
			const dragItem = prevStages[dragInfo.index]

			if(dragInfo.index === dropInfo.index) {
				return prevStages
			} else if(dragInfo.index > dropInfo.index) {
				updatedStages = updatedStages.filter((stage,index)=> dragInfo.index !== index)
				updatedStages.splice(dropInfo.index +1 , 0, dragItem)
			} else {
				updatedStages = updatedStages.filter((stage,index)=> dragInfo.index !== index)
				updatedStages.splice(dropInfo.index , 0, dragItem)
			}

			return updatedStages
		})

	}

	const handleStagesInputKeyPress = (e: KeyboardEvent<HTMLInputElement>, idx: number)=> {
		if(e.key === 'Enter' && stages[idx+1] === undefined) setStages([...stages, ''])
		if(e.key === 'Backspace' && stages[idx] === '') {
			const updatedStages = stages.filter((stage, index)=> index !== idx)
			setStages(updatedStages)
		}
		if(e.key === 'Delete') {
			const updatedStages = stages.filter((stage, index)=> index !== idx)
			setStages(updatedStages)
		}
	}

	return (
		<div className="pipeline-stages-editor-container">
			<DragAndDropHandlerProvider>
				<div>
					<p className='pipeline-stage-editor-title'>{t('pipelineStages')}</p>
					<DropStageArea dropAreaSize='4.44vw' stageId={-1} stage={String(1)} onDrop={moveStage} className='pipeline-stages-container'>
						{stages.map((stage,idx)=> (

							<Draggable key={`stage-${idx+1}`} dropAreaSize='4.44vw' index={idx} stageId={-1} stage={String(1)} dragData={stage === '' ? null : stage}>
								<div className="pipeline-stage">
									<InputField 
										id={`stage-input-${idx}`}
										name={`stage-${idx+1}`} 
										autoFocus
										placeholder={`${t('stage')} ${idx+1}`}
										type='text' 
										value={stage} 
										onChange={(e)=> {
											stages[idx] = e.target.value
											setStages([...stages])
										}}
										onKeyDown={(e)=> {
											handleStagesInputKeyPress(e, idx)
										}}
									/>
									<div className="pipeline-stage-remove" onClick={()=> {
										const updatedStages = stages.filter((stage, index)=> index !== idx)
										setStages(updatedStages)
									}}>{close}</div>
								</div>
							</Draggable>

						))} 
					</DropStageArea>
				</div>
			</DragAndDropHandlerProvider>

			<Button text={t('addAStage')} active={false} onClick={()=> {setStages([...stages, ''])}}/>
		</div>
	)
}

export default PipelineStageEditor