// style
import './chartLegend.scss'

//components
import ChartLegendItem from './chartLegendItem/chartLegendItem'

// react
import { FC } from 'react'

//types
import { legendItem } from '../../../../../types/general/generalTypes'

type chartLegendProps = {
	legend: legendItem[]
	activeLegends: legendItem[]
	toggleLegend: (legendItem: legendItem, remove: boolean) => void
}

const ChartLegend : FC<chartLegendProps> = ({ legend, activeLegends, toggleLegend}) => {
	return (
		<div className="chart-legend">
			{legend?.map((legend, index)=>{
				const handleClick = (legendItem: legendItem, isActive: boolean) => {
					toggleLegend(legendItem, isActive)
				}

				const isActive = activeLegends?.findIndex((activeLegend) => activeLegend.label === legend.label) !== -1

				return (
					<ChartLegendItem {...legend} key={'chart-legend-'+index} active={isActive} onSelect={()=>{handleClick(legend, isActive)}}/>
				)
			})}
		</div>
	)
}

export default ChartLegend