//reaact
import { FC, useEffect, useState } from 'react'

//others
import { Node } from 'reactflow'

//components
import MockNode from '../treeNode/mockNode'

type nodesContainerProps = {
	nodes: Node[]
	handleNodeDimsUpdate: (nodeDims: { width: number, height: number, rank: string }[]) => void
}

const NodesContainer: FC<nodesContainerProps> = ({ nodes, handleNodeDimsUpdate }) => {
	const [nodesDims, setDims] = useState<{ width: number, height: number, rank: string }[]>([])

	const addNode = ({ width, height, rank }: { width: number, height: number, rank: string }) => {

		setDims((prev) => [...prev, { width, height, rank }])
	}

	useEffect(() => {
		if (nodesDims.length > 0 && nodesDims.length == nodes.length) {
			handleNodeDimsUpdate(nodesDims)
		}
	}, [nodesDims])

	return (
		<div>
			{nodes.map((e) => {
				return <MockNode key={e.id} rank={e.id} addNode={addNode} isConnectable={true} data={e.data} />
			})}
		</div>
	)
}

export default NodesContainer