//react
import { FC, useState } from 'react'

//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//types
import {budget} from '../../../../../types/finance/budgetTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import {deleteBudgetUrl} from '../../../../../utils/old_urls/finance/budgetListUrls'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import {setDeleteBudgetId, setDeleteBudgetModalIsOpen} from '../../../../../redux/finance/budgetList/modals'

//translations
import { useTranslation } from 'react-i18next'

type deleteBudgetConfirmationModalProps = {
    budgets: budget[]
    setBudgets: (value: budget[]) => void
}

const DeleteBudgetConfirmationModal: FC<deleteBudgetConfirmationModalProps> = ({budgets, setBudgets}) => {
	const { t } = useTranslation('', {keyPrefix:'general.modals.budget.deleteBudgetModal'})

	const { modalIsOpen, budgetId } = useAppSelector((state) => state.budgetListModal.deleteBudgetModal)
	
	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteBudgetModalIsOpen(false))
		dispatch(setDeleteBudgetId(null))
	}

	const handleSubmit = (): void => {
		if(budgetId){
			setLoading(true)
			authorizedRequest(deleteBudgetUrl(budgetId), 'DELETE')
				.then(() => {
					setBudgets([...budgets.filter((budget) => budget.id !== budgetId)])

					setLoading(false)
					closeModal()
				})
		}
	}
    
	return (
		<ConfirmationModal 
			title={t('title')} 
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteBudgetConfirmationModal
