// styles
import './avatarStack.scss'

// react
import React, { FC } from 'react'

// component
import Avatar from '../avatar/avatar'

type avatarStack = {
	avatars: (string | null)[]
	maxAvatar?: number
}

const AvatarStack: FC<avatarStack> = ({avatars, maxAvatar = 3}) => {
	return (
		<div className="avatar-stack">
			{avatars?.slice(0,maxAvatar).map((avatar,index:number) => {
				return (
					<div className="avatar" key={'avatar-'+index}>
						<Avatar blobAvatar={avatar}/>
					</div>
				)
			})}
			{avatars.length > maxAvatar && (
				<p>+{avatars.length - maxAvatar}</p>
			)}
		</div>
	)
}

export default AvatarStack