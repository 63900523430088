import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { company as generalCompany } from '../../types/general/generalTypes'

type generalState = {
    language: 'ua' | 'en'
	companies: generalCompany[],
	userCompanyData: {
		companyId: number
		employeeId: number
		companyAvatar: string | null 
		companyName: string
		companyDescription: string
	} | undefined
	companiesDataList:generalCompany[]
}
type company = {
	companyId: number
	employeeId: number
	companyAvatar: string | null 
	companyName: string
	companyDescription: string
}

const initialState: generalState = {
	language: (localStorage.getItem('lang') as 'ua' | 'en') || 'ua',
	companies: [],
	companiesDataList:[],
	userCompanyData: parseInt(localStorage.getItem('companyId') || '-1') > -1 ? 
		{
			companyId:  parseInt(localStorage.getItem('companyId') || '-1'),
			employeeId:  parseInt(localStorage.getItem('employeeId') || '-1'),
			companyAvatar: localStorage.getItem('companyAvatar') || '',
			companyName: localStorage.getItem('companyName') || '',
			companyDescription: localStorage.getItem('companyDescription') || ''
		}
		: undefined
}

export const generalSlice = createSlice({
	name: 'generalReducer',
	initialState,
	reducers: {
		setLanguage: (state, action: PayloadAction<'ua' | 'en'>) => {
			state.language = action.payload
		},
		setUserCompanyData: (state, action: PayloadAction<company| undefined>) => {
			state.userCompanyData = action.payload
		},
		setCompanies: (state, action: PayloadAction<generalCompany[]>) => {
			state.companies = action.payload
		},
		setCompanyDataList: (state, action:PayloadAction<generalCompany[]>)=>{
			state.companiesDataList = action.payload
		}
	}
})

export const { setLanguage, setUserCompanyData, setCompanies,setCompanyDataList } = generalSlice.actions

export default generalSlice.reducer