
import { dashboardIcon, listIcon, calendar } from '../../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import MyTasksCalendar from '../../../projects/myTasks/myTasksCalendar/myTasksCalendar'
import SwitchButtons from '../../../general/switchButtons/switchButtons'
import TaskDashboard from '../../../general/taskDashboard/taskDashboard'
import TaskList from '../../../projects/general/taskList/taskList'
import CheckBox from '../../checkBox/checkBox'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

// types
import { filterBar, stageResponse, switchButton, task, taskResponse } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {singleEmployeeTaskCalendarUrl, singleEmployeeTasksUrl} from '../../../../utils/urls/employees/employee'

// other
import { formatStageResponse, formatTaskResponse } from '../../../../assets/projects/projectsFunctions'

// translation
import { useTranslation } from 'react-i18next'

type employeeTasksProps = {
	setFilterBar: (value: filterBar | undefined) => void
}

const EmployeeTasks: FC<employeeTasksProps> = ({ setFilterBar }) => { 
	const { employeeId } = useParams()

	const [ activeToolBarIconId, setActiveToolBarIcon ] = useState(0)

	const [showClosed, setShowClosed] = useState(false)
	const { t } = useTranslation('', { keyPrefix: 'projects.myTasks' })

	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		}
	]


	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singleEmployeeTasksUrl(Number(employeeId)) + `?needle=${searchRequest}&page=${page}&status=${stageId}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(singleEmployeeTasksUrl(Number(employeeId)) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadTaskCalendar = async () => {
		const response = await authorizedRequest(singleEmployeeTaskCalendarUrl(Number(employeeId)), 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}


	const renderToolbar = (activeId: number, showClosedTasks:boolean)=> {
		switch(activeId) {
		case 0:
			return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks}/>
		case 1:
			return <TaskList showClosed={showClosedTasks} loadTasks={loadTasks} setFilterBar={setFilterBar}/>
		case 2:
			return <MyTasksCalendar showClosed={showClosedTasks} loadTasks={loadTaskCalendar} />
		}
	}

	return (
		<div className='my-tasks'>
			<div className="my-tasks-tool-bar">
				<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium' />
				<CheckBox label={t('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev=>!prev)} />
			</div>
			{renderToolbar(activeToolBarIconId, showClosed)}
		</div>
	)
}

export default EmployeeTasks