// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileExportModal from '../../../../../general/modals/fileExportModal/fileExportModal'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleAccountTransactionsUrl } from '../../../../../../utils/urls/finance/account/account'
import { companyTransactionFiltersUrl } from '../../../../../../utils/urls/finance/transactions/transaction'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setExportTransactionFileModalIsOpen } from '../../../../../../redux/finance/general/modals'




const ExportTransactionFileModal: FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.financeGeneralModal.exportTransactionFileModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const { accountId } = useParams()

	const closeModal = () => {
		dispatch(setExportTransactionFileModalIsOpen(false))
	}

	const getFile = async (exportType: string) => {
		const url = accountId ? singleAccountTransactionsUrl(Number(accountId)) : companyTransactionFiltersUrl(companyId)
		const { result } = await authorizedRequest(url + `?export=True&export_type=${exportType}`, 'GET')

		return result.file
	}

	return (
		<FileExportModal
			open={modalIsOpen}
			closeModal={closeModal}
			exportFileTypes={[
				{ title: 'csv', mime: 'text/csv' },
				{ title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
			]}
			getFileBlobString={getFile}
		/>
	)
}

export default ExportTransactionFileModal