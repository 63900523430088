//react
import { FC, useEffect } from 'react'

//components
import FileSelectorModal from '../../../../modals/fileSelectorModal/fileSelectorModal'

//context
import useChatDesktop from '../../context/chatDesktopContext'

const FileMessage: FC = () => {

	const { attachFiles, setAttachedFiles, setAttachModalIsOpen } = useChatDesktop()


	useEffect(() => {
		if(attachFiles.attachedFiles && attachFiles.attachedFiles.length >= 1){
			setAttachModalIsOpen(false)
		}
	}, [attachFiles.attachedFiles])

	return <FileSelectorModal
		isOpen={attachFiles.modalIsOpen}
		setIsOpen={(value) => {setAttachModalIsOpen(value)}}
		setFiles={(newFiles) => { 
			setAttachedFiles([...attachFiles.attachedFiles, ...newFiles])
		}} 
	/>
}

export default FileMessage